import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { saveAs } from 'file-saver';

export interface EwayBillAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

export const defaultConfig: EwayBillAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};

class EwayBillService {
  static apiConfig: EwayBillAPIConfig;

  static getEwayBills() {
    return http.get(ApiConstants.URL.EWAY_BILL.EWAY_BILLS).catch((err: any) => {
      console.error('Error fetching eway bills detail: ', err);
      return Promise.reject(err);
    });
  }
  //https://api-dev.deskera.xyz/v1/invoices/in/eway/download?salesInvoiceCode=0000001
  //https://api-dev.deskera.xyz/v1/invoices/in/eway/download/salesInvoiceCode=0000001

  static getEwayExport(salesInvoiceCode: any) {
    let params = '';
    salesInvoiceCode.forEach((salesInvoiceCodes: any) => {
      params += 'salesInvoiceCode=' + salesInvoiceCodes.salesInvoiceCode + '&';
    });
    params = params.slice(0, -1);
    return http
      .get(`${ApiConstants.URL.EWAY_BILL.EXPORT_EWAY_BILLS}?${params}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          console.log(response, JSON.stringify(response), 'll');
          saveAs(response, 'EWayBill.json');
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((err: any) => {
        console.error('Error fetching eway bills detail: ', err);
        return Promise.reject(err);
      });
  }

  static searchEwayBills(transactionType: string, ewayBillStatus: string) {
    return http
      .get(
        ApiConstants.URL.EWAY_BILL.SEARCH_EWAY_BILLS +
          `?transactionType=${transactionType}&ewayBillStatus=${ewayBillStatus}&sortDir=DESC`
      )
      .catch((err: any) => {
        console.error('Error fetching eway bills detail: ', err);
        return Promise.reject(err);
      });
  }

  static getInvoicesOnContacts(country: string, code: string) {
    return http
      .get(ApiConstants.URL.EWAY_BILL.GET_INVOICE_DATA(country, code))
      .catch((err: any) => {
        console.error('Error fetching eway bills detail: ', err);
        return Promise.reject(err);
      });
  }

  static getTransporterList() {
    return http
      .get(ApiConstants.URL.EWAY_BILL.GET_TRANSPORTERS)
      .catch((err: any) => {
        console.error('Error fetching eway bills detail: ', err);
        return Promise.reject(err);
      });
  }

  static saveEwayBill(payload: any) {
    return http
      .post(ApiConstants.URL.EWAY_BILL.EWAY_BILLS, payload)
      .catch((err: any) => {
        console.error('Error saving eway bills: ', err);
        return Promise.reject(err);
      });
  }

  static generateEwayBill(payload: any) {
    return http
      .post(ApiConstants.URL.EWAY_BILL.GENERATE_EWB, payload)
      .catch((err: any) => {
        console.error('Error saving eway bills: ', err);
        return Promise.reject(err);
      });
  }

  static generateEwayBillBulk(payload: any) {
    return http
      .post(ApiConstants.URL.EWAY_BILL.GENERATE_EWB_BULK, payload)
      .catch((err: any) => {
        console.error('Error saving eway bills: ', err);
        return Promise.reject(err);
      });
  }
  static generateEwayBillBulkStockTransfer(payload: any) {
    return http
      .post(ApiConstants.URL.STOCK_MANAGEMENT.GENERATE_WAY_BILL, payload)
      .catch((err: any) => {
        console.error('Error saving eway bills: ', err);
        return Promise.reject(err);
      });
  }

  static saveManageTransporter(payload: any) {
    return http
      .post(ApiConstants.URL.EWAY_BILL.SAVE_TRANSPORTERS, payload)
      .catch((err: any) => {
        console.error('Error saving transpoerter: ', err);
        return Promise.reject(err);
      });
  }

  static updateManageTransporter(payload: any, id: number) {
    return http
      .put(ApiConstants.URL.EWAY_BILL.UPDATE_TRANSPORTERS(id), payload)
      .catch((err: any) => {
        console.error('Error updating transpoerter: ', err);
        return Promise.reject(err);
      });
  }

  static deleteManageTransporter(id: number) {
    return http
      .delete(ApiConstants.URL.EWAY_BILL.DELETE_TRANSPORTERS(id))
      .catch((err: any) => {
        console.error('Error deleting transpoerter: ', err);
        return Promise.reject(err);
      });
  }

  static updateEwayBill(payload: any) {
    return http
      .put(ApiConstants.URL.EWAY_BILL.UPDATE_EWAY_BILL, payload)
      .catch((err: any) => {
        console.error('Error saving eway bills: ', err);
        return Promise.reject(err);
      });
  }

  static cancelEwayBill(data: string) {
    const config = {
      headers: {
        'Content-Type': 'text/plain'
      }
    };

    return http
      .put(ApiConstants.URL.EWAY_BILL.CANCEL_EWAY_BILLS, data, config)
      .catch((err: any) => {
        console.error('Error cancelling eway bills: ', err);
        return Promise.reject(err);
      });
  }

  static getEWayAuthToken(request: any) {
    return http
      .post(ApiConstants.URL.EWAY_BILL.AUTH_TOKEN, request)
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static loadEWBReportsData() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string =
      ApiConstants.URL.EWAY_BILL.REPORTS +
      `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}`;

    return http.get(finalEndpoint).catch((err: any) => {
      console.error('Error fetching eway bills detail: ', err);
      return Promise.reject(err);
    });
  }
}

export default EwayBillService;
