import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';

import supplierQuote from '../../Services/SupplierQuotes';

import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchSupplierQuote = createAsyncThunk(
  'supplierQuote',
  async () => {
    const response = await supplierQuote.getSupplierQuoteByPage();
    return response;
  }
);

export const SupplierQuotesSlice = createSlice({
  name: 'supplierQuotes',
  initialState,
  reducers: {
    SupplierQuoteColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.SUPPLIER_QUOTE
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToSupplierQuoteColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateSupplierQuoteDetails: (state, action: PayloadAction<any>) => {
      const supplierQuoteDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const supplierQuoteIndex: number = content.findIndex(
        (contact: any) => contact.id === supplierQuoteDetails.id
      );
      if (supplierQuoteIndex >= 0) {
        content[supplierQuoteIndex] = supplierQuoteDetails;
        state.data['content'] = [...content];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierQuote.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchSupplierQuote.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToSupplierQuoteColumnConfig,
  SupplierQuoteColumnConfig,
  updateColumnConfig,
  updateSupplierQuoteDetails
} = SupplierQuotesSlice.actions;

export const selectSupplierQuote = (state: RootState) =>
  state.supplierQuote.data;

export const selectSupplierQuoteLoadingStatus = (state: RootState) =>
  state.supplierQuote.gridContentLoadingStatus;

export const selectSupplierQuoteColumnConfig = (state: RootState) =>
  state.supplierQuote.columnConfig;

export const selectSupplierQuoteColumnConfigTableId = (state: RootState) =>
  state.supplierQuote.configTableId;

export default SupplierQuotesSlice.reducer;
