import { useEffect, useState } from 'react';
import {
  SupplierQuote,
  SupplierQuoteInitialState,
  SupplierQuoteProps,
  SupplierQuotebody
} from '../../Models/SupplierQuote';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import {
  deleteDrafts,
  draftTableId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDrafts,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchRequestForQuotations } from '../../Redux/Slices/RequestForQuotationSlice';
import { fetchSupplierQuote as fetchSupplierQuote } from '../../Redux/Slices/SupplierQuotes';
import {
  setItemsFromSupplierQuoteItems,
  putData,
  getPayloadForSupplierQuotes
} from './SupplierQuoteHelper';
import Utility, { deepClone } from '../../Utility/Utility';
import { useHistory } from 'react-router-dom';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import SupplierQuoteForm from '../../SharedComponents/DocumentForm/SupplierQuoteForm';
import { Document } from '../../Models/Document';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { showAlert } from 'deskera-ui-library';
import SupplierQuoteService from '../../Services/SupplierQuotes';
const NewSupplierQuotes: React.FC<SupplierQuoteProps> = (props) => {
  let history = useHistory();
  const dispatch = useAppDispatch();

  const [squote, setSquote] = useState<SupplierQuote>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromSupplierQuoteItems(props.populateFormData)
      : { ...SupplierQuoteInitialState }
  );

  const [body, setbody] = useState<SupplierQuote>();

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setSquote({ ...props.populateFormData });
    }
  }, [props.populateFormData]);
  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          onSaveAsDraft(false);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_ORDER,
        data: () => {
          updateOrderDetails();
        }
      });
    }

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_ORDER,
        data: (closeDoc: boolean) => {
          createOrder(closeDoc);
        }
      });
    }
  };

  const replaceURLToModuleURL = () => {
    //createOrder(false);
    if (squote.rfqToSQuote === true) {
      dispatch(removeDraft(props.draftData.id));
      history.replace(PAGE_ROUTES.RFQ);
    } else {
      dispatch(removeDraft(props.draftData.id));
      history.replace(PAGE_ROUTES.SUPPLIER_QUOTE);
    }
  };

  const onSaveAsDraft = (isApprovalRequired: boolean) => {
    replaceURLToModuleURL();
  };
  
  const updateOrderDetails = () => {
    replaceURLToModuleURL();
  };
  const isDocValid = (docToValidate: any) => {
    // if (!Utility.isEmpty(docToValidate.purchaseRequestCode)) {
    //   const itemProduct = docToValidate?.purchaseOrderItems?.filter(
    //     (item: any) => {
    //       return item.selected === true;
    //     }
    //   );

    //   if (Utility.isEmpty(itemProduct)) {
    //     showAlert('Error', 'Please select at least one Line Item');
    //     return false;
    //   }
    // }
    let productQuantityArray: any = [];
    let productPriceArray: any = [];
    docToValidate?.purchaseOrderItems?.map((item: any) => {
      productQuantityArray.push(item?.productQuantity);
    });
    let validateQuantity: boolean;
    productQuantityArray.includes(0) || productQuantityArray.includes(undefined)
      ? (validateQuantity = true)
      : (validateQuantity = false);

    docToValidate?.purchaseOrderItems?.map((item: any) => {
      productPriceArray.push(item?.unitPrice);
    });
    let validatePrice: boolean;
    productPriceArray.includes(0) || productPriceArray.includes(undefined)
      ? (validatePrice = true)
      : (validatePrice = false);

    if (validatePrice) {
      showAlert('Error', 'Product Price must be greater than 0');
      return false;
    }
    if (validateQuantity) {
      showAlert('Error', 'Product Quantity must be greater than 0');
      return false;
    }

    if (!Utility.isEmpty(docToValidate.purchaseRequestCode)) {
      const items = docToValidate.purchaseOrderItems.map(() => {});
    }
    if (Utility.isEmpty(docToValidate.contact)) {
      showAlert('Data Fields Error', 'Contact Must not be Empty');
      return false;
    }
    if (Utility.isEmpty(docToValidate.purchaseOrderItems)) {
      showAlert(
        'Data Fields Error',
        'Please add at least one line item or if added please remove blank item line'
      );
      return false;
    }
    return true;
  };

  const createOrder = (isApprovalRequired: boolean) => {
    if (isDocValid(body)) {
      const payload = getPayloadForSupplierQuotes(body);
      SupplierQuoteService.createSupplierQuotes(payload).then(
        (res: any) => {
          replaceURLToModuleURL();
          dispatch(fetchRequestForQuotations());
          dispatch(fetchSupplierQuote());

          let result = res;

          if (!Utility.isEmpty(result)) {
            let checkRfqToSpq: boolean = false;
            result.linkedDocuments.forEach((data: any) => {
              if (data.documentType === 'PURCHASE_REQUEST_FOR_QUOTES') {
                checkRfqToSpq = true;
                return;
              }
            });

            if (checkRfqToSpq) {
              const buttons = [
                {
                  title: 'Ok',
                  className: 'bg-button, border-m',
                  onClick: () => {}
                },
                {
                  title: 'Goto Supplier Quotes',
                  className: ' bg-blue text-white ml-r',
                  onClick: () => {
                    RouteManager.navigateToPage(PAGE_ROUTES.SUPPLIER_QUOTE);
                  }
                }
              ];

              showAlert(
                'Quote converted!',
                'Request Quote has been converted to Supplier Quote Order successfully.',
                buttons
              );
            }
          }
        },
        (err) => {
          replaceURLToModuleURL();
          console.error('Error while creating Order: ', err);
        }
      );

      // isDocValid();
    }
  };
  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };
  const handleOrderUpdate = (doc: any) => {
    const supplierAddress = doc?.contact?.shippingAddress[0];
    let linkedDoc = [];
    if (doc.rfqToSQuote) {
      linkedDoc.push(doc?.linkedDocuments?.[0]);
      linkedDoc.push({
        documentCode: doc.purchaseRequestForQuotesCode,
        documentSequenceCode: 'PRFQI-' + doc.purchaseRequestForQuotesCode,
        documentType: 'PURCHASE_REQUEST_FOR_QUOTES'
      });
    }
    let tmpBody: SupplierQuote | any = {
      ...body,
      currencyCode: doc.currencyCode,
      deliveryDate: doc.receiveByDate,
      documentDate: doc.documentDate,
      validTillDate: doc.validTillDate,
      dueDate: doc.validTillDate,
      status: 'OPEN',
      memo: doc.memo,
      shipByDate: doc.shipByDate,
      shipFrom: supplierAddress,
      shipTo: doc.shipTo,
      billTo: doc.billTo,
      linkedDocuments: linkedDoc,
      purchaseOrderItems: doc.items,
      totalAmount: 0,
      receiveByDate: doc.receiveByDate,
      orderType: 'Inventory',
      vendorCode: doc.contact?.code,
      contact: doc.contact,
      unitPriceGstInclusive: doc.unitPriceGstInclusive,
      taxSystem: doc.taxSystem,
      purchaseRequestCode: doc.purchaseRequestCode,
      purchaseRequestSequenceCode: doc.purchaseRequestSequenceCode,
      purchaseRequestForQuotesCode: doc.purchaseRequestForQuotesCode,
      rfqToSQuote: doc.rfqToSQuote
    };
    setbody(tmpBody);
  };

  return (
    <SupplierQuoteForm
      permissionKeys={PERMISSIONS_BY_MODULE.SUPPLIER_QUOTE}
      documentMode={props.documentMode ? props.documentMode : DOCUMENT_MODE.NEW}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      booksDocument={squote}
      id={squote.id}
      RfqToSQuote={squote.rfqToSQuote}
      onDocumentUpdate={(x: Document) => handleOrderUpdate(x)}
    />
  );
};

export default NewSupplierQuotes;
