import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { BulkDeleteResponse } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface RequisitionAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  CustomField?: any;
}

export const defaultConfig: RequisitionAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};
class RequisitionService {
  static apiConfig: RequisitionAPIConfig;
  static abortController: any = null;

  static getOrdersByPage() {
    Utility.cancelRequest(RequisitionService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    // const country = Utility.getTenantSpecificApiCode(
    //   COMPLIANCE_SPECIFIC_FIELD_NAME.REQUISITION
    // );

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }${this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;

    const finalEndpoint: string =
      ApiConstants.URL.REQUISITION.REQUISITION + queryString; //+ country;

    RequisitionService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: RequisitionService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  //just had change this api
  static createOrder(payload: any, country: string) {
    return http2
      .post(`${ApiConstants.URL.REQUISITION.REQUISITION}${country}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchOrderDetails(purchaseRequestCode: string) {
    return http
      .get(
        `${ApiConstants.URL.REQUISITION.UPDATE_REQUISITION(
          purchaseRequestCode
        )}`
      )
      .catch((err: any) => {
        console.error('Error fetching requisition: ', err);
        return Promise.reject(err);
      });
  }

  // static fetchRequisitionOrderDetails(orderCode: any) {
  //   console.log(orderCode);
  //   return http
  //     .get(`${ApiConstants.URL.REQUISITION.UPDATE_REQUISITION(orderCode)}`)
  //     .catch((err: any) => {
  //       console.error('Error fetching requisition: ', err);
  //       return Promise.reject(err);
  //     });
  // }

  static updateOrder(payload: any, country: string) {
    const orderId: string = payload.id;
    return http2
      .put(
        `${ApiConstants.URL.REQUISITION.UPDATE_REQUISITION(orderId)}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating order: ', err);
        return Promise.reject(err);
      });
  }

  static deleteOrder(id: number) {
    return http2
      .delete(`${ApiConstants.URL.REQUISITION.DELETE_REQUISITION(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(
      ApiConstants.URL.REQUISITION.BULK_DELETE_REQUISITION,
      config
    );
  }
  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.PURCHASE_REQUEST_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default RequisitionService;
