import { DKButton, DKLabel, DKLine } from 'deskera-ui-library';
import welcome_image from '../Assets/Welcome_User.png';

interface WelcomePopupProps {
  tenantInfo: any;
  onShowWalkthrough: () => void;
  onSkipWalkthrough: () => void;
}
export default function WelcomePopup(props: WelcomePopupProps) {
  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 450,
          minHeight: 300,
          width: '90%',
          maxHeight: '90%',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        <div
          className="column parent-width align-items-center justify-content-center"
          style={{ paddingBottom: 70 }}
        >
          <img src={welcome_image} alt="" style={{ width: '100%' }} />
          <DKLabel
            text={`Welcome aboard ${props.tenantInfo?.name}!`}
            className="fw-m fs-xl mt-xl"
          />
          <DKLine className="bg-blue mt-xl" style={{ height: 4, width: 50 }} />
          <DKLabel
            text={`We are excited to have you as a part of the Deskera Books family. Before you start using Deskera Books, we want to show you where to find what and how to navigate Deskera Books.`}
            className="p-r mt-r text-align-center"
          />
          <DKButton
            title="Show Me Around"
            className="mt-xl bg-button text-white"
            onClick={() => props.onShowWalkthrough()}
          />
          <DKButton
            title="No thanks, I'll explore it."
            className="mt-l text-blue"
            onClick={() => props.onSkipWalkthrough()}
          />
        </div>
      </div>
    </div>
  );
}
