import { useState } from 'react';
import { DKLabel, DKLine, DKButton } from 'deskera-ui-library';
import ic_send_black from '../../Assets/Icons/ic_send_black.png';
import AIAnimation from './AIAnimation.js';
import './ai.scss';

export default function AIPopup(props) {
  const [query, setQuery] = useState('');
  const defaultText = 'Enter command...';

  return (
    <div
      className={
        'bg-white border-radius-r shadow-m column justify-content-center align-items-center p-0 popout ' +
        (props.className || '')
      }
      style={{ maxWidth: 300, paddingTop: 20, ...(props.style || {}) }}
    >
      <AIAnimation width={60} height={60} />
      <DKLabel text="Deskera AI" className="fw-m mt-r fs-m" />
      <DKLabel text="How can I help?" className="mt-s text-gray mb-r" />
      <DKLine className="mt-m" style={{ paddingTop: 1 }} />
      <div className="row justify-content-between p-r">
        <input
          className="ai-command-input outline-none border-none bg-transparent app-font parent-width border-box"
          placeholder={defaultText}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <DKButton
          icon={ic_send_black}
          className="p-0"
          onClick={(e) => {
            props.onSendRequest?.(query || defaultText);
          }}
        />
      </div>
    </div>
  );
}
