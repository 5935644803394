import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  PAYMENT_STATUS,
  TAX_SYSTEM
} from '../Constants/Constant';
import { Document } from './Document';
import { DocumentItem } from './DocumentItem';

export interface Quote extends Document {
  documentType: DOC_TYPE;
  quotationCode?: string;
  customerOrderNumber?: string;
  quotationItemDtoList?: DocumentItem[];
  fulfillmentStatus?: FULFILLMENT_STATUS;
  fulfillmentType?: string;
  paymentStatus?: PAYMENT_STATUS;
  backOrder?: boolean;
  linkedSalesInvoices?: any[];
  linkedSalesOrders?: any[];
  processedInPPS?: boolean;
  reservedStock?: boolean;
  shipByDate?: string;
  autoFulfill?: Boolean;
  fulfillmentComplete?: Boolean;
}

export const QuoteInitialState: Quote = {
  contactCode: '',
  currency: '',
  currencyCode: '',
  status: DOCUMENT_STATUS.OPEN,
  sequenceFormat: '',
  quotationCode: '',
  documentType: DOC_TYPE.QUOTE,
  shipFrom: {},
  shipTo: {},
  billTo: {},
  exchangeRate: 1,
  documentDate: '',
  validTillDate: '',
  dueDate: '',
  paymentStatus: PAYMENT_STATUS.PENDING,
  fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
  sourceFulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
  items: [],
  quotationItemDtoList: [],
  fulfillmentDate: '',
  memo: '',
  contact: null,
  unitPriceGstInclusive: false,
  rcmApplicable: false,
  reorder: false,
  previousExchangeRate: 1,
  gstExchangeRate: 1,
  totalAmount: 0,
  roundOffAmountInDocumentCurrency: 0,
  totalAmountInBaseCurrency: 0,
  dueAmount: 0,
  attachments: [],
  attachmentIds: [],
  supportedFeatures: [],
  gstin: '',
  placeOfSupply: '',
  customerType: 'NA',
  vendorType: 'NA',
  gstTreatment: 'REGISTERED_BUSINESS_REGULAR',
  customField: null,
  paymentInformation: null,
  knockoffNonContraInfo: [],
  knockoffContraInfo: [],
  taxSystem: TAX_SYSTEM.DEFAULT,
  tcsRateId: undefined,
  tcsAmount: 0,
  totalTdsAmount: 0,
  vatPaidAtCustomSA: false,
  rcmApplicableSA: false,
  backOrder: false,
  isDocumentTouched: false,
  additionalCharges: {
    globalDiscount: {
      amount: 0,
      isPercent: false,
      isSubTotalOnly: false,
      percent: 0
    },
    additionalChargeAmount: null,
    additionalChargeTaxAmount: null,
    additionalChargesDetails: []
  }
};
