import { INPUT_TYPE } from 'deskera-ui-library';
import { COLUMN_CODE } from '../../Constants/TableConstants';
import { getContactURL } from '../ImportExport/EximConstants';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';

export const CF_INPUT_TYPE: any = {
  TEXT: INPUT_TYPE.TEXT,
  NUMBER: INPUT_TYPE.NUMBER,
  DROPDOWN: INPUT_TYPE.DROPDOWN,
  DATE: INPUT_TYPE.DATE
};

export const getCFColumnKey = (customField: any) => {
  return `${customField.id}_${customField.shortName}`;
};

export const getCFColumnConfig = (customField: any) => {
  const key = getCFColumnKey(customField);

  const column: any = {
    id: key,
    key: key,
    name: customField.label,
    type: CF_INPUT_TYPE[customField.fieldType],
    width: 150,
    systemField: true,
    required: customField.mandatory,
    editable: true,
    hidden: false,
    uiVisible: true,
    customField: {
      id: customField.id,
      code: customField.code,
      label: customField.label,
      module: customField.modules[0],
      shortName: customField.shortName,
      defaultValue: customField.defaultValue
    }
  };

  if (CF_INPUT_TYPE[customField.fieldType] === INPUT_TYPE.NUMBER) {
    column.textAlign = 'right';
  } else {
    column.textAlign = 'left';
  }

  if (CF_INPUT_TYPE[customField.fieldType] === INPUT_TYPE.DROPDOWN) {
    column.dropdownConfig = {
      title: `Select ${customField.label}`,
      canEdit: false,
      canDelete: false,
      data: getCFOptions(customField.attributes),
      renderer: (index: number, option: any) => {
        return option.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    };

    column.formatter = ({ value, rowIndex, rowData }: any) => {
      return value?.name ?? value;
    };
  }

  return column;
};

export const getCFOptions = (attributes: any[]) => {
  if (Array.isArray(attributes)) {
    return attributes.map((item: any) => {
      return {
        id: item.value,
        name: item.value
      };
    });
  }

  return [];
};

export const getCFValue = (customField: any, value: any) => {
  return {
    id: customField.id,
    code: customField.code,
    label: customField.label,
    module: customField.module,
    shortName: customField.shortName,
    value: value
  };
};

export const ADD_PREFERRED_VENDOR_COLUMN_CONFIG = [
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_CODE,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_CODE,
    name: 'Supplier',
    type: INPUT_TYPE.DROPDOWN,
    width: 250,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    dropdownConfig: {
      title: 'Select Contact',
      allowSearch: true,
      searchableKey: 'name',
      style: {
        minWidth: 250
      },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: (searchText: string) => {
          return `${getContactURL()}&search=${searchText}`;
        },
        dataParser: (response: any) => {
          if (Array.isArray(response?.content)) {
            return response.content.map((contact: any) => {
              return {
                code: contact.code,
                name: contact.name
              };
            });
          }
          return [];
        },
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, contact: any) => {
        return contact.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    formatter: ({ value, rowIndex, rowData }: any) => {
      return typeof value === 'object' ? value.name : value;
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.LEAD_TIME,
    key: COLUMN_CODE.PREFERRED_VENDOR.LEAD_TIME,
    name: 'Lead Time (in days)',
    type: INPUT_TYPE.NUMBER,
    width: 180,
    systemField: true,
    required: false,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NAME,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NAME,
    name: 'Supplier Part Name',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    required: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_DESCRIPTION,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_DESCRIPTION,
    name: 'Supplier Product Description',
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: true,
    required: false,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NO,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NO,
    name: 'Supplier Part No',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    required: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.LAST_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.LAST_PRICE,
    name: 'Last Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.AVG_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.AVG_PRICE,
    name: 'Avg Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PRICE,
    name: 'Supplier Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.NOTES,
    key: COLUMN_CODE.PREFERRED_VENDOR.NOTES,
    name: 'Note',
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: true,
    required: false,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  }
];

export const PRODUCT_BY_VENDOR_COLUMN_CONFIG = [
  {
    id: COLUMN_CODE.PRODUCT_BY_VENDOR.PRODUCT_CODE,
    key: COLUMN_CODE.PRODUCT_BY_VENDOR.PRODUCT_CODE,
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PRODUCT_BY_VENDOR.PRODUCT_NAME,
    key: COLUMN_CODE.PRODUCT_BY_VENDOR.PRODUCT_NAME,
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NO,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NO,
    name: 'Supplier Part No',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    required: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NAME,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PART_NAME,
    name: 'Supplier Part Name',
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    required: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_DESCRIPTION,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_DESCRIPTION,
    name: 'Supplier Description',
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.LAST_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.LAST_PRICE,
    name: 'Last Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.AVG_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.AVG_PRICE,
    name: 'Avg Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PRICE,
    key: COLUMN_CODE.PREFERRED_VENDOR.SUPPLIER_PRICE,
    name: 'Supplier Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    formatter: ({ value, rowIndex, rowData }: any) => {
      return DocumentConfigUtility.amountFormatter(value, rowData.currency);
    }
  },
  {
    id: COLUMN_CODE.PREFERRED_VENDOR.LEAD_TIME,
    key: COLUMN_CODE.PREFERRED_VENDOR.LEAD_TIME,
    name: 'Lead Time (in days)',
    type: INPUT_TYPE.NUMBER,
    width: 180,
    systemField: true,
    required: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  }
];
