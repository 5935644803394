import { DKListPicker, TOAST_TYPE, showToast } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ReportGridHolder from '../../Components/Reports/Financial/ReportGridHolder';
import {
  BOOKS_DATE_FORMAT,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import DateFormatService from '../../Services/DateFormat';
import NewReportService, { NewReportConfig } from '../../Services/DebitReport';
import Utility from '../../Utility/Utility';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';

export default function FulfillmentTable(props: any) {
  const [reportFilterState, setReportFilterState] = useState<any>();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState<any>(null);
  const [gridRowData, setGridRowData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>();
  const [realTableData, setRealTableData] = useState<any>();
  const [reloadOptions, setReloadOptions] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [module, setModule] = useState<any>(props.module);
  const [itemData, setItemData] = useState<any>(props.itemData);
  const [rowRackBinData, setRowRackBinData] = useState<any>({});
  const [isRRBEnabled, setIsRRBEnabled] = useState(false);
  const [customFields, setCustomFields] = useState<any>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const [cfColumnConfig, setCfColumnConfig] = useState<any>([]);
  const [customFieldColumns, setCustomFieldColumns] = useState<any>([]);
  var widthValue = 50;

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let copyRowRackBinData = { ...rowRackBinData };
      let rrbEnabled = isRRBEnabled;
      tenantInfo?.additionalSettings?.ROW_RACK_BIN?.forEach((element: any) => {
        rrbEnabled = rrbEnabled || element.enable;
        if (element.name === ROW_RACK_BIN_CONSTANT.ROW) {
          copyRowRackBinData.rowData = element;
        }
        if (element.name === ROW_RACK_BIN_CONSTANT.RACK) {
          copyRowRackBinData.rackData = element;
        }
        if (element.name === ROW_RACK_BIN_CONSTANT.BIN) {
          copyRowRackBinData.binData = element;
        }
      });
      setRowRackBinData(copyRowRackBinData);
      setIsRRBEnabled(rrbEnabled);
    }
  }, [tenantInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      //setGridRowData(props.populateFormData.content)
      setTableData(props.populateFormData.content);
    }
  }, [props]);

  useEffect(() => {
    if (!Utility.isEmpty(reportFilterState)) loadGstrOne();
  }, [reportFilterState]);

  useEffect(() => {
    setCustomFields(batchSerialCFfromStore?.content);
  }, [batchSerialCFfromStore]);

  useEffect(() => {
    if (!Utility.isEmpty(customFields)) {
      const cfFieldsSet: any = new Set(
        customFields
          ?.filter((cfData: any) => cfData.status === 'ACTIVE')
          ?.map((cf: any) => cf.label)
      );
      const cfList: any = customFields?.filter(
        (cfData: any) => cfData.status === 'ACTIVE'
      );

      let copyColumnConfigs = cfColumnConfig;
      if (Utility.isEmpty(copyColumnConfigs)) {
        cfList.forEach((cfield: any, index: number) => {
          copyColumnConfigs.push({
            name: cfield.label,
            type: cfield.fieldType.toLowerCase(),
            index: copyColumnConfigs.length + index + 1,
            options: null,
            required: false,
            width: 180,
            editable: false,
            hidden: false,
            uiVisible: true,
            systemField: false,
            columnCode: cfield.label,
            key: cfield.label,
            textAlign: 'left',
            allowFilter: false
          });
        });
      }
      setCfColumnConfig(copyColumnConfigs);
      setCustomFieldColumns(Array.from(cfFieldsSet));
    }
  }, [customFields]);

  useEffect(() => {
    if (!Utility.isEmpty(tableData)) {
      setRealTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (!Utility.isEmpty(realTableData)) setGridRowData(getRowData());
  }, [realTableData]);

  const getFormattedAmount = (amount: number) => {
    if (amount === 0) {
      return '';
    } else {
      return Utility.getLongAmoutBlockForGrid(tenantInfo.currency, amount + '');
    }
  };
  const loadGstrOne = async () => {
    // NewReportService.getB2BDetails().then((res) => {
    //   setTableData(res);
    // });
    const config: NewReportConfig = {
      ...NewReportService.apiConfig,
      Page: 0,
      SheetName: 'AT',
      SheetGroupBy: 'GSTIN',
      StartDate: DateFormatService.getDateStrFromDate(
        reportFilterState.StartDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      EndDate: DateFormatService.getDateStrFromDate(
        reportFilterState.EndDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    try {
      NewReportService.apiConfig = config;
      let res = NewReportService.getB2BDetails().then((data: any) =>
        setTableData(data.gstr1AdvanceTaxLiabilityDtos)
      );

      return res;
    } catch (err) {
      console.error('Error loading products: ', err);
    }
  };

  const getRowData = () => {
    let rowData: any[] = [];
    realTableData &&
      realTableData.forEach((row: any) => {
        let batchQty = Utility.roundingOff(
          module === 'SELL' ? row.batchSizeFulfilled : row.batchSize,
          QTY_ROUNDOFF_PRECISION
        );
        rowData.push({
          ['batch']: row.serialBatchNumber,
          ['mdate']: Utility.isEmpty(row.manufacturingDate)
            ? ''
            : DateFormatService.getFormattedDateString(
                row.manufacturingDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          ['edate']: Utility.isEmpty(row.expiryDate)
            ? ''
            : DateFormatService.getFormattedDateString(
                row.expiryDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          ['fqty']: itemData?.documentUOMSchemaDefinition
            ? Utility.getUomQuantity(
                batchQty,
                itemData.documentUOMSchemaDefinition
              )
            : batchQty,
          ['cost']: Utility.roundOffToTenantDecimalScale(row.acquiredCost),
          warehouseName: row?.warehouseName ?? '',
          rowName: row?.rowName ?? '',
          rackName: row?.rackName ?? '',
          binName: row?.binName ?? '',
          ...getCustomFieldData(row?.customField ?? [], customFieldColumns)
        });
      });
    return rowData;
  };

  const getCustomFieldData = (cfdata: any, cfFields: String[]) => {
    const cfList: any = {};
    if (!Utility.isEmpty(cfdata) && !Utility.isEmpty(cfFields)) {
      let activeCFFields = customFields?.filter(
        (field: any) => field.status === 'ACTIVE'
      );
      cfdata?.forEach((cf: any) => {
        let cfField = activeCFFields.find(
          (field: any) => field.code === cf.code
        );
        if (Utility.isNotEmpty(cfField)) {
          const label: any = cf?.label;
          const shortName: any = cf?.shortName;
          if (label && cfFields.includes(label)) {
            cfList[label] = cf.value;
          } else if (shortName && cfFields.includes(shortName)) {
            cfList[shortName] = cf.value;
          }
        }
      });
    }
    return cfList;
  };

  let defaultColumns: any = [
    {
      name: 'Batch Number',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'batch',
      key: 'batch'
    },
    {
      name: 'Manufactured Date',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'mdate',
      key: 'mdate'
    },
    {
      name: 'Expiry Date',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'edate',
      key: 'edate'
    },
    {
      name: module === 'SELL' ? 'Fulfilled Quantity' : 'Received Quantity',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'fqty',
      key: 'fqty'
    },
    {
      name: 'Acquired Cost',
      type: 'text',
      index: 0,
      options: null,
      required: true,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'cost',
      key: 'cost'
    }
  ];

  if (props.isSerial) {
    defaultColumns = [
      {
        name: 'Serial Number',
        type: 'text',
        index: 3,
        options: null,
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'batch',
        key: 'batch'
      },
      {
        name: 'Acquired Cost',
        type: 'text',
        textAlign: 'right',
        index: 0,
        options: null,
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'cost',
        key: 'cost'
      }
    ];
  }

  let rrbColumns: any[] = [
    {
      name: 'Warehouse',
      type: 'text',
      textAlign: 'left',
      index: 0,
      options: null,
      required: true,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'warehouseName',
      key: 'warehouseName'
    }
  ];

  if (isRRBEnabled) {
    if (rowRackBinData?.rowData?.enable) {
      widthValue = widthValue + 50 / 3;
      rrbColumns.push({
        name: rowRackBinData?.rowData?.label,
        type: 'text',
        textAlign: 'left',
        index: 0,
        options: null,
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'rowName',
        key: 'rowName'
      });
    }
    if (rowRackBinData?.rackData?.enable) {
      widthValue = widthValue + 50 / 3;
      rrbColumns.push({
        name: rowRackBinData?.rackData?.label,
        type: 'text',
        textAlign: 'left',
        index: 0,
        options: null,
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'rackName',
        key: 'rackName'
      });
    }
    if (rowRackBinData?.binData?.enable) {
      widthValue = widthValue + 50 / 3;
      rrbColumns.push({
        name: rowRackBinData?.binData?.label,
        type: 'text',
        textAlign: 'left',
        index: 0,
        options: null,
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        systemField: true,
        columnCode: 'binName',
        key: 'binName'
      });
    }
  }

  if (props.isSerail) {
    defaultColumns = [...defaultColumns, ...rrbColumns];
  } else {
    defaultColumns.splice(1, 0, ...rrbColumns);
  }

  const exportDocument = (type: string) => {
    const config: NewReportConfig = {
      ...NewReportService.apiConfig,
      Page: 0,
      Limit: 10,
      Search: '',
      StartDate: DateFormatService.getDateStrFromDate(
        reportFilterState.StartDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      EndDate: DateFormatService.getDateStrFromDate(
        reportFilterState.EndDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      )
    };
    NewReportService.apiConfig = config;
    NewReportService.exportGstr1Sections(type, 'AT')
      .then((res) => {
        setShowExport(false);
      })
      .catch((err) => {
        showToast('Could Not Export Report', TOAST_TYPE.FAILURE);
      });
  };

  const showOptions = () => {
    return (
      <DKListPicker
        data={[`Excel`]}
        className="mr-l mt-3 shadow-m absolute right-3 z-10"
        style={{ width: 82 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            exportDocument('XLS');
          }
        }}
        onClose={() => {
          setShowExport(false);
        }}
      />
    );
  };

  return (
    <div className="column parent-width text-13 text-align-left">
      <div className="column parent-width">
        <div className="column parent-width position-relative px-3">
          <ReportGridHolder
            needColumnIcons={true}
            columnConfig={defaultColumns.concat(cfColumnConfig)}
            rows={gridRowData}
            needActionColumn={true}
            width={props.width ? props.width : '100%'}
            needTrailingColumn={false}
            totalPageCount={props.totalPageCount || 0}
            currentPage={props.currentPage || 0}
            onPaginationClick={(pageNo: number) =>
              props?.onPaginationClick?.(pageNo)
            }
            isNoDataAbsolute={true}
          />
          {reportData?.trialBalanceDetailDto?.length === 0 && (
            <div className="position-absolute parent-width top-40 text-align-center fs-r fw-b text-gray">
              No records to display
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
