import { DKDataGrid } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  APPROVAL_STATUS_LIST,
  BOOKS_DATE_FORMAT,
  DOC_TYPE
} from '../Constants/Constant';
import { CallBackPayloadType } from '../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { selectInvitedUsersList } from '../Redux/Slices/PermissionsSlice';

import { Store } from '../Redux/Store';
import DateFormatService from '../Services/DateFormat';
import Utility from '../Utility/Utility';

export interface ApprovalHistoryProps {
  documentDetails: any;
  approvalHistoryDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isClosed: (val: boolean) => void;
}

let defaultColumns = [
  {
    name: 'Transaction Number',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'transactionNumber',
    key: 'transactionNumber'
  },
  {
    name: 'Transaction Date',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'transactionDate',
    key: 'transactionDate'
  },
  {
    name: 'Level',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'level',
    key: 'level'
  },
  {
    name: 'Level Name',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 215,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'levelLabel',
    key: 'levelLabel'
  },
  {
    name: 'Approved/Rejected By',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'approvedBy',
    key: 'approvedBy'
  },
  {
    name: 'Approved/Rejected Date',
    type: 'text',
    index: 4,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'approvedDate',
    key: 'approvedDate'
  },
  {
    name: 'Status',
    type: 'text',
    index: 5,
    options: null,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    key: 'status'
  },
  {
    name: 'User',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'user',
    key: 'user'
  },
  {
    name: 'Remarks',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'remarks',
    key: 'remarks'
  }
];
const ApprovalHistory: React.FC<ApprovalHistoryProps> = (props) => {
  const [gridRowData, setGridRowData] = useState<any[]>([]);
  const store = Store;
  const dispatch = useAppDispatch();
  const appSelector = useAppSelector;
  const [columns, setColumns] = useState<any[]>(defaultColumns);

  // const allUsersList = appSelector(usersList);
  const allUsersList = appSelector(selectInvitedUsersList);

  useEffect(() => {
    if (!Utility.isEmpty(props?.approvalHistoryDetails?.approvalHistory))
      setGridRowData(getRowData());
  }, []);

  useEffect(() => {
    if (props?.documentType !== DOC_TYPE.SALES_ORDER) {
      const soOnlyColsKeys = [
        'remarks',
        'user',
        'transactionNumber',
        'transactionDate'
      ];
      const cols = [...defaultColumns];
      const filteredColumns = cols.filter(
        (ele) => !soOnlyColsKeys.includes(ele.columnCode)
      );
      setColumns(filteredColumns);
    }
  }, [props?.documentType]);
  const getRowData = () => {
    let rowData: any[] = [];
    // let users = store.getState().authInfo.usersListShortInfo.data['content'];
    // let users: any = await dispatch(fetchAllUsersList());
    let users: any = allUsersList;
    props?.approvalHistoryDetails?.approvalHistory &&
      props?.approvalHistoryDetails?.approvalHistory.forEach((row: any) => {
        let approvedBy = row.approvedBy || row.approvedByEmail;
        const user = users.find((ele: any) => ele.email === approvedBy);
        let transactionDate = null;
        if (props.documentType === DOC_TYPE.SALES_ORDER) {
          transactionDate = DateFormatService.getDateStrFromDate(
            props?.documentDetails?.salesOrderDate
          );
        }
        let approvedDate = row.approvedDate;
        let status = row.status;
        if (
          props.documentType === DOC_TYPE.STOCK_ADJUSTMENT ||
          props.documentType === DOC_TYPE.STOCK_REQUEST ||
          props.documentType === DOC_TYPE.STOCK_TRANSFER
        ) {
          approvedDate = DateFormatService.getDateStrFromDate(
            new Date(row.approvedAt),
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
          status = row.approvalStatus;
        }
        const userName = user?.firstName + ' ' + user?.lastName;
        rowData.push({
          level: row.level,
          levelLabel: Utility.isEmpty(
            getLevelName(props?.documentDetails, row.level)
          )
            ? `Level ` + row.level
            : getLevelName(props?.documentDetails, row.level),
          status: APPROVAL_STATUS_LIST[status],
          approvedDate: DateFormatService.getFormattedDateString(
            approvedDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          user: props?.approvalHistoryDetails?.approvalRequiredFor,
          remarks: row.Remarks,
          transactionNumber: props?.documentDetails?.documentSequenceCode,
          transactionDate: transactionDate ? transactionDate : '',
          approvedBy: Utility.isNotEmpty(userName) ? userName : 'NA'
        });
      });
    return rowData;
  };

  const getLevelName = (doc: any, currentLevel: any) => {
    let requiredFor = Utility.isEmpty(
      props?.approvalHistoryDetails?.approvalRequiredFor
    )
      ? ''
      : props?.approvalHistoryDetails?.approvalRequiredFor;

    let approvalConditions = Store.getState().automation?.data;
    let currentApproverCondition: any = [];
    let flag: any = false;
    let level: any = '';

    let userSpecificList =
      !Utility.isEmpty(approvalConditions) &&
      approvalConditions.filter((ele: any) => {
        if (props.documentType === DOC_TYPE.REQUISITION) {
          return ele.fields[0].field_type === props?.documentType;
        } else {
          return (
            ele.fields[0].field_type === props?.documentType &&
            ele.fields[0].approvalFor.split(',').includes(requiredFor)
          );
        }
      });

    if (Utility.isEmpty(userSpecificList)) {
      level = '';
    } else {
      currentApproverCondition = userSpecificList[0];
      for (let index = 0; index < userSpecificList.length; index++) {
        flag = Utility.isConditionApproverApply([userSpecificList[index]], doc);
        if (flag) {
          currentApproverCondition = userSpecificList[index];
          break;
        }
      }
    }
    let currentLevelNode =
      currentApproverCondition?.fields?.[0]?.multiApprovalDetails?.filter(
        (ele: any) => ele.level === currentLevel
      );

    // if (status === APPROVAL_STATUS_LIST.PENDING_FOR_APPROVAL) {
    if (Utility.isEmpty(props?.approvalHistoryDetails?.approvalHistory)) {
      if (Utility.isEmpty(currentLevelNode)) {
        level = '';
      } else {
        level = currentLevelNode[0].levelLabel;
      }
    } else {
      if (Utility.isEmpty(currentLevelNode)) {
        level = '';
      } else {
        level = currentLevelNode[0].levelLabel;
      }
    }
    // }
    return level;
  };

  return (
    <div className="mt-10 px-2">
      <div className="column parent-width flex-1">
        <div className="column parent-width mb-s position-relative flex-1 p-3 align-items-center">
          <DKDataGrid
            needShadow={false}
            needBorder={true}
            needColumnIcons={false}
            needTrailingColumn={false}
            allowBulkOperation={false}
            allowColumnSort={false}
            allowColumnAdd={false}
            allowColumnEdit={false}
            allowRowEdit={true}
            currentPage={1}
            totalPageCount={1}
            title={''}
            // width={730}
            columns={columns}
            rows={gridRowData}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalHistory;
