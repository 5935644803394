import { DKButton, DKLabel } from 'deskera-ui-library';
import AIAnimation from './AIAnimation.js';
import './ai.scss';

export default function AINotification(props) {
  const defaultNotification = `You are falling short of Shoes (P-0000065) for SI-0000161. Do you want to raise a purchase order?`;

  const onSuccess = (e) => {
    // call API
    props.onSuccess?.();
    props.onClose();
  };

  return (
    <div
      className={
        'bg-white border-radius-r shadow-m column justify-content-center align-items-center p-0 popout ' +
        (props.className || '')
      }
      style={{ maxWidth: 300, paddingTop: 20, ...(props.style || {}) }}
    >
      <AIAnimation width={60} height={60} />
      <DKLabel text="Deskera AI" className="fw-m mt-r fs-m" />
      <DKLabel
        text={props.message || defaultNotification}
        className="mt-m width-80"
      />
      {/* <DKLine className="mt-l" style={{ paddingTop: 1 }} /> */}
      <div className="row justify-content-center m-v-l" style={{ gap: 8 }}>
        <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={(e) => props.onClose()}
        />
        <DKButton
          title={props.successTitle || 'Create PO'}
          className="bg-button text-white"
          onClick={onSuccess}
        />
      </div>
    </div>
  );
}
