import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  API_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_TYPE,
  DOC_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchBatchTrackingProducts,
  selectBatchTrackingProduct,
  selectBatchTrackingProductsLoadingStatus
} from '../../../Redux/Slices/BatchTrackingSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../Utility/Utility';

import { addYears, parseISO } from 'date-fns';
import { selectAdvancedTrackingData } from '../../../Redux/Slices/AdvancedTrackingDataSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../Services/DateFormat';
import WarehouseService from '../../../Services/Warehouse';
import {
  fetchSerialTrackingProductsJWO,
  selectSerialTrackingProductJWO
} from '../../../Redux/Slices/SerialTrackingProductJWOSlice';

const JWOStockTransferAdvancedBatchTrackingPopup: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(props.itemDetails);

  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [moduleName, setModuleName] = useState(props.module);
  const productWarehouse = useAppSelector(selectBatchTrackingProduct);
  const productInventoryWarehouse = props?.selectedWarehouseProductsData;
  const [localWarehouse, setLocalWarehouse] = useState<any[]>(productWarehouse);
  const [availableBatchData, setAvailableBatchData] = useState<any[]>([]);
  const [availableBatchDataNonFiltered, setAvailableBatchDataNonFiltered] =
    useState<any[]>([]);
  const [destinationBatchData, setDestinationBatchData] = useState<any[]>([]);
  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [sourceRRB, setSourceRRB] = useState<any>({
    srcRowCode: null,
    srcRackCode: null,
    srcBinCode: null
  });
  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [destinationRRB, setDestinationRRB] = useState<any>({
    destRowCode: null,
    destRackCode: null,
    destBinCode: null
  });
  const allWarehouseData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState(
    props.defaultProductWarehouse
  );

  const tenantInfo = useAppSelector(activeTenantInfo);
  const getRecordsForGrid = (newSerialData: any) => {
    [...newSerialData].forEach((s) => {
      s.manufacturingDate = DateFormatService.getFormattedDateString(
        s?.manufacturingDate,
        tenantInfo.dateFormat,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      s.expiryDate = DateFormatService.getFormattedDateString(
        s?.expiryDate,
        tenantInfo.dateFormat,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      s.invalidFields = [];
    });
    return newSerialData;
  };
  const initialGridRows = [
    {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: 0,
      batchSizeFulfilled: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    }
  ];
  const [gridData, setGridData] = useState<any>(
    props.itemDetails?.serialData && props.itemDetails?.serialData?.length !== 0
      ? getRecordsForGrid(props.itemDetails.serialData)
      : initialGridRows
  );

  const dispatch = useAppDispatch();
  const loadingBatchData = useAppSelector(
    selectBatchTrackingProductsLoadingStatus
  );
  const [stockTransferItem, setStockTransferItem] = useState(
    props.stockTransferData
  );
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
  const [jwoWarehouseFetched, setJwoWarehouseFetched] = useState<any>();

  const [stockTransferDocumentSeqCodes, setStockTransferDocumentSeqCodes] =
    useState(item?.stockTransferDocumentSeqCodes || []);

  const selectProductJWOList = useAppSelector(selectSerialTrackingProductJWO);

  useEffect(() => {
    loadJWOWH();
    let code = props.itemDetails.pid
      ? props.itemDetails.pid
      : props.itemDetails.productId;
    dispatch(
      fetchBatchTrackingProducts({
        productCode: code,
        enableQCWarehouse: false,
        includeRejectedWarehouse: true
      })
    );
    dispatch(fetchSerialTrackingProductsJWO(stockTransferDocumentSeqCodes));
    let initialGridData: any = [];
    let serialData: any[] = [];
    let result: any[] = [];
    if (moduleName === MODULE_TYPE.SELL) {
      result = localWarehouse.find(
        (warehouse: any) => warehouse.primary
      )?.advancedTrackingMeta;
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled < serial.batchSize
      );
      if (!Utility.isEmpty(props.targetWarehouse)) {
        if (!Utility.isEmpty(productInventoryWarehouse)) {
          if (productInventoryWarehouse) {
            let warehouseIdWithInventory: any[] = [];
            productInventoryWarehouse?.forEach((piw: any) => {
              if (
                piw.productAvailableQuantity &&
                piw.productAvailableQuantity[item.pid] &&
                piw.productAvailableQuantity[item.pid] > 0
              ) {
                //get list of warehouse where product is available
                warehouseIdWithInventory.push(piw.id);
              }
            });
            if (warehouseIdWithInventory.length > 0) {
              //remove target warehouse from list
              let x = warehouseIdWithInventory.filter(
                (w: any) => w.id !== props.targetWarehouse.id
              );
              if (x && x.length > 0) {
                //set target warehouse if there are inventory in other warehouse
                let tmp = productInventoryWarehouse?.filter(
                  (w: any) => w.id === props.targetWarehouse.id
                );
                if (tmp && tmp.length > 0) {
                  setDestinationWarehouse(tmp[0]);
                  setReadOnlyTarget(true);
                }
              }
            }
          }
        }
      }
      if (
        !Utility.isEmpty(
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
        )
      ) {
        initialGridData =
          props.itemDetails?.serialData &&
          props.itemDetails?.serialData?.length !== 0
            ? getRecordsForGrid(props.itemDetails?.serialData)
            : initialGridRows;
      } else {
        initialGridData = initialGridRows;
      }
    }

    setGridData(initialGridData);
  }, []);

  const loadJWOWH = async () => {
    let findJWOWarehouse = allWarehouseData?.content?.find(
      (warehouse: any) => warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
    );

    if (Utility.isEmpty(findJWOWarehouse)) {
      const jwoWH = await WarehouseService.getJWOWarehouses();
      if (Utility.isNotEmpty(jwoWH)) {
        setJwoWarehouseFetched(jwoWH);
      }
    }
  };

  useEffect(() => {
    let initialGridData: any = [];
    let serialData: any[] = [];
    let updatedData: any[] = [];
    let result: any[] = [];

    if (
      !Utility.isEmpty(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
      )
    ) {
      let srcWh = getActiveWarehouses()?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcWarehouseCode
      );
      let destWh = getDestinationWarehouse()?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destWarehouseCode
      );

      result = localWarehouse.find(
        (warehouse: any) => warehouse.code == srcWh.code
      )?.advancedTrackingMeta;
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled < serial.batchSize
      );
      let filteredRacksDataForSourceWarehouse: any[] =
        srcWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRowCode
      ) {
        filteredRacksDataForSourceWarehouse = srcWh?.warehouseRackInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredBinDataForSourceWarehouse: any[] =
        srcWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRackCode
      ) {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rackCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
        );
      } else {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredRacksDataForDestinationWarehouse: any[] =
        destWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRowCode
      ) {
        filteredRacksDataForDestinationWarehouse =
          destWh?.warehouseRackInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      let filteredBinDataForDestinationWarehouse: any[] =
        destWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRackCode
      ) {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rackCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode
          );
      } else {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      srcWh = {
        ...srcWh,
        srcRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRowCode ?? null,
        srcRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRackCode ?? null,
        srcBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcBinCode ?? null,
        filteredRackInfos: filteredRacksDataForSourceWarehouse,
        filteredBinInfos: filteredBinDataForSourceWarehouse
      };
      destWh = {
        ...destWh,
        destRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRowCode ?? null,
        destRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRackCode ?? null,
        destBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destBinCode ?? null,
        filteredRackInfos: filteredRacksDataForDestinationWarehouse,
        filteredBinInfos: filteredBinDataForDestinationWarehouse
      };
      setSourceWarehouse(srcWh);
      setDestinationWarehouse(destWh);
      setSourceRRB({
        ...srcWh,
        srcRowCode: srcWh?.srcRowCode ?? null,
        srcRackCode: srcWh?.srcRackCode ?? null,
        srcBinCode: srcWh?.srcBinCode ?? null
      });
      setDestinationRRB({
        ...destWh,
        destRowCode: destWh?.destRowCode ?? null,
        destRackCode: destWh?.destRackCode ?? null,
        destBinCode: destWh?.destBinCode ?? null
      });

      initialGridData = updatedData;
      let totalItem = updatedData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );
      setTotalAllocatedItem(totalItem);
      setAvailableBatchData(serialData);
      setAvailableBatchDataNonFiltered(serialData);

      let destBatches = productWarehouse?.find(
        (warehouse: any) => warehouse.code === destWh.code
      )?.advancedTrackingMeta;
      if (!Utility.isEmpty(destBatches)) {
        setDestinationBatchData(destBatches);
      } else {
        setDestinationBatchData([]);
      }
      // setGridData(initialGridData);
    }
  }, [props]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: item
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    let warehouseTempData =
      props.hasMultilpleWarehouseData &&
      props.hasMultilpleWarehouseData.length > 0
        ? props.hasMultilpleWarehouseData
        : [
            {
              warehouseCode: defaultProductWarehouse,
              availableQty: 0,
              quantity: 0
            }
          ];

    setWarehouseData(warehouseTempData);
  }, [defaultProductWarehouse, defaultProductWarehouse]);

  useEffect(() => {
    if (!Utility.isEmpty(productWarehouse)) {
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        getJWDestinationWarehouse();
      }
    }
  }, [productWarehouse]);

  useEffect(() => {
    if (
      !Utility.isEmpty(selectProductJWOList) &&
      (!props.itemDetails?.serialData ||
        (props.itemDetails?.serialData &&
          props.itemDetails?.serialData?.length === 0))
    ) {
      const modifyResponseForGridData = selectProductJWOList
        .filter(
          (product: any) => product.productVariantCode === item.productCode
        )
        .map((product: any) => {
          return {
            ...product,
            warehouseCode: product.serialBatchNumber,

            invalidFields: [],
            rowDetails: product
          };
        });
      setGridData([...modifyResponseForGridData]);
    }
  }, [selectProductJWOList]);

  const onRowUpdate = (val: any) => {
    const key = val['columnKey'];
    const lineItem = val['rowIndex'];
    const item = val['rowData'];

    let tmpData = [...gridData];

    let invalidFields: any[] = tmpData[val['rowIndex']]['invalidFields'];
    if (key === 'warehouseCode') {
      let warehouseData;
      let result;
      let batchData;
      if (moduleName === MODULE_TYPE.SELL) {
        warehouseData = productWarehouse;
        result = warehouseData.find(
          (warehouse: any) => warehouse.code === item.warehouseCode.code
        )?.advancedTrackingMeta;

        batchData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
      }
      tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
        (e) => e !== 'warehouseCode'
      );
      setAvailableBatchData(batchData);
    } else if (key === 'serialBatchNumber') {
      if (moduleName === MODULE_TYPE.SELL) {
        let duplicateData: any[] = tmpData.filter((sr: any) => {
          return (
            sr.serialBatchNumber.serialBatchNumber ===
            item.serialBatchNumber.serialBatchNumber
          );
        });
        if (duplicateData && duplicateData.length > 1) {
          tmpData[val['rowIndex']]['invalidFields'] = ['serialBatchNumber'];
        } else {
          tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
            (e) => e !== 'serialBatchNumber'
          );
        }
      }
      tmpData[val['rowIndex']]['batchSize'] =
        item.serialBatchNumber.batchSize -
        item.serialBatchNumber.reservedQuantity -
        item.serialBatchNumber.batchSizeFulfilled;
      tmpData[val['rowIndex']]['manufacturingDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.manufacturingDate
          : tmpData[val['rowIndex']]['manufacturingDate'];
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : tmpData[val['rowIndex']]['expiryDate'];
      tmpData[val['rowIndex']]['editable'] = false;
      tmpData[val['rowIndex']]['destinationBatch'] =
        item.serialBatchNumber?.serialBatchNumber || item.serialBatchNumber;
    } else if (key === 'batchSizeFulfilled') {
      let totalItem = 0;
      if (moduleName === MODULE_TYPE.SELL) {
        if (
          parseFloat(item['batchSizeFulfilled']) > parseFloat(item['batchSize'])
        ) {
          invalidFields.push('batchSizeFulfilled');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter(
            (e) => e !== 'batchSizeFulfilled'
          );
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
        totalItem = gridData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
          0
        );
        setTotalAllocatedItem(totalItem);
      }
    } else if (key === 'batchSize') {
      let totalItem = 0;
      if (moduleName === MODULE_TYPE.BUY) {
        if (parseFloat(item['batchSize']) > parseFloat(pendingQuantity)) {
          invalidFields.push('batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter((e) => e !== 'batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
        totalItem = gridData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSize),
          0
        );
      }
      setTotalAllocatedItem(totalItem);
    } else if (key === 'manufacturingDate') {
      let isoDate = tmpData[val['rowIndex']]['expiryDate']
        ? addYears(
            parseISO(tmpData[val['rowIndex']]['expiryDate']),
            1
          ).toISOString()
        : addYears(
            parseISO(tmpData[val['rowIndex']]['manufacturingDate']),
            1
          ).toISOString();
      tmpData[val['rowIndex']]['manufacturingDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.manufacturingDate
          : tmpData[val['rowIndex']]['manufacturingDate'];
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : isoDate;
    } else if (key === 'expiryDate') {
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : tmpData[val['rowIndex']]['expiryDate'];
    } else if (key === 'destinationBatch') {
      tmpData[val['rowIndex']]['destinationBatch'] = item.destinationBatch
        ?.serialBatchNumber
        ? item.destinationBatch?.serialBatchNumber
        : item.destinationBatch;
      tmpData[val['rowIndex']]['editable'] = false;
      tmpData[val['rowIndex']]['invalidFields'] = tmpData[val['rowIndex']][
        'invalidFields'
      ]?.filter((e: any) => e !== 'destinationBatch');
    } else if (key === 'transferQuantity') {
      tmpData[val['rowIndex']]['transferQuantity'] = isNaN(
        item?.transferQuantity
      )
        ? 0
        : Utility.roundingOff(item.transferQuantity, QTY_ROUNDOFF_PRECISION);
    }
    setGridData(tmpData);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...gridData];
    rows.splice(rowIndex, 1);
    let totalItem = 0;
    if (moduleName === MODULE_TYPE.SELL) {
      totalItem = rows.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );
    }
    setTotalAllocatedItem(totalItem);
    setGridData(rows);
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        title={''}
        width={'100%'}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        columns={getDataGridColumns()}
        rows={gridData}
      />
    );
  };

  const getDataGridColumns = () => {
    let columns: any = [];
    if (moduleName === MODULE_TYPE.SELL) {
      columns = [
        {
          key: 'serialBatchNumber',
          name: 'Source Batch No.',
          type: INPUT_TYPE.TEXT,
          textAlign: 'left',
          width: 145,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        },
        {
          key: 'destinationBatch',
          name: 'Destination Batch No.',
          type: INPUT_TYPE.TEXT,
          textAlign: 'left',
          width: 145,
          systemField: true,
          editable: false,
          hidden: true,
          uiVisible: true,
          formatter: (obj: any, index: any) => {
            return Utility.isObject(obj?.value.serialBatchNumber)
              ? obj?.value?.serialBatchNumber?.serialBatchNumber
              : obj?.value?.serialBatchNumber || obj?.value || '';
          },
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={
                  obj?.value?.serialBatchNumber ||
                  obj?.value?.serialBatchNumber?.serialBatchNumber ||
                  obj.value
                }
              />
            );
          },
          dropdownConfig: {
            data: destinationBatchData,
            renderer: (obj: any) => {
              return Utility.isObject(obj?.serialBatchNumber)
                ? obj?.serialBatchNumber?.serialBatchNumber
                : obj?.serialBatchNumber || obj.value;
            }
          }
        },
        {
          key: 'manufacturingDate',
          name: 'Manufactured Date',
          textAlign: 'center',
          type: INPUT_TYPE.TEXT,
          width: 160,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={`${DateFormatService.getFormattedDateString(
                  obj.value,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}`}
              />
            );
          }
        },

        {
          key: 'expiryDate',
          name: 'Expiry Date',
          type: INPUT_TYPE.TEXT,
          width: 120,
          textAlign: 'center',
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={`${DateFormatService.getFormattedDateString(
                  obj.value,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}`}
              />
            );
          }
        },
        {
          key: 'availableBatchSizeForGr',
          name: 'Available Qty',
          type: INPUT_TYPE.NUMBER,
          textAlign: 'right',
          width: 115,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        },
        {
          key: 'transferQuantity',
          name: 'Allocated Qty',
          textAlign: 'left',
          type: INPUT_TYPE.NUMBER,
          width: 110,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true
        },

        {
          id: 'action',
          key: 'action',
          name: '',
          type: INPUT_TYPE.BUTTON,
          width: 70,
          options:
            gridData && gridData.length > 1
              ? [
                  {
                    icon: DKIcons.ic_delete,
                    className: ' p-0',
                    onClick: (data: any) => onDelete(data)
                  }
                ]
              : []
        }
      ];
    }
    return columns.filter((col: any) => !col.hidden);
  };

  const getActiveWarehouses = () => {
    if (loadingBatchData !== API_STATUS.LOADING) {
      return productWarehouse && productWarehouse.length > 0
        ? productWarehouse
        : [];
    } else {
      return [];
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto gap-2">
          <DKLabel
            text={t('JOB_WORK_OUT.JWO_ALLOCATE_BATCH_TRACT')}
            className="fw-m fs-l"
          />
          {loadingBatchData === API_STATUS.LOADING && <DKSpinner />}
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />

          <DKButton
            title={t('DRAFTS.SAVE')}
            className={'bg-button text-white mr-r'}
            onClick={() => {
              onStockTransferSave();
            }}
          />
        </div>
      </div>
    );
  };

  const validateDetail = () => {
    let sourceBatchEmpty = gridData.filter(
      (item: any) => item.serialBatchNumber === ''
    );
    if (sourceBatchEmpty && sourceBatchEmpty.length > 0) {
      showAlert('Error', t('JOB_WORK_OUT.SELECT_SOURCE_BATCH_NO'));
      return false;
    }

    let transferQuantityEmpty = gridData.filter(
      (item: any) =>
        !item.transferQuantity ||
        (item.transferQuantity ? Number(item.transferQuantity) : 0) < 0
    );
    if (transferQuantityEmpty && transferQuantityEmpty.length > 0) {
      showAlert('Error', t('JOB_WORK_OUT.ERROR_TRANSFER_QTY'));
      return false;
    }

    if (
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) !== getTransferQuantity()
    ) {
      showAlert('Error', t('JOB_WORK_OUT.ERROR_TRANSFER_REQ_QTY'));
      return false;
    }

    let extraTransferQuantityThenAvailableQtyRows = gridData.filter(
      (data: any) => +data.transferQuantity > +data.batchSize
    );

    if (extraTransferQuantityThenAvailableQtyRows.length) {
      showAlert('Error', t('JOB_WORK_OUT.ERROR_TRANSFER_AVA_QTY'));
      return false;
    }
    let duplicateSerial = gridData.map((data: any) => {
      return data.invalidFields;
    });
    let hasError = false;
    if (duplicateSerial && duplicateSerial.length > 0) {
      duplicateSerial.forEach((data: any) => {
        if (data.includes('serialBatchNumber')) {
          hasError = true;
        }
      });
    }
    if (hasError) {
      showAlert('Error', t('JOB_WORK_OUT.DUPLICATE_BATCH_NO'));
      return false;
    }

    return true;
  };

  const getTransferQuantity = () => {
    let x = gridData.map((data: any) => Number(data.transferQuantity));
    let total = x.reduce((x: any, a: any) => x + a, 0);
    return isNaN(total)
      ? 0
      : Utility.roundingOff(total, QTY_ROUNDOFF_PRECISION);
  };

  const onStockTransferSave = () => {
    let advancedData: any[] = [];
    let advancedDetailData: any[] = [];
    gridData?.forEach((data: any) => {
      let d = {
        qtyToTransfer: data.transferQuantity,
        sourceSerialBatchNumber: data.serialBatchNumber,
        manufacturingDate: DateFormatService.getFormattedDateString(
          data?.manufacturingDate,
          tenantInfo.dateFormat,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getFormattedDateString(
          data?.expiryDate,
          tenantInfo.dateFormat,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        destSerialBatchNumber:
          data?.serialBatchNumber || data?.destinationBatch,
        qtyAvailable: data.batchSize
      };
      const details = {
        batchSize: data.batchSize,
        serialBatchNumber: data.serialBatchNumber,
        warehouseCode:
          data.code || data.warehouseCode || data.rowDetails.warehouseCode,
        productVariantCode: data.productVariantCode,
        rawMaterialQtyToConsume: data.transferQuantity,
        transferQuantity: data.transferQuantity,
        manufacturingDate: d.manufacturingDate,
        expiryDate: d.expiryDate
      };
      advancedData.push(d);
      advancedDetailData.push(details);
    });
    if (validateDetail()) {
      let totalTransferQty = 0;
      totalTransferQty = getTransferQuantity();
      let warehouseDetail = {
        destBinCode: destinationRRB.destBinCode ?? null,
        destRackCode: destinationRRB.destRackCode ?? null,
        destRowCode: destinationRRB.destRowCode ?? null,
        srcBinCode: sourceRRB?.srcBinCode ?? null,
        srcRackCode: sourceRRB?.srcRackCode ?? null,
        srcRowCode: sourceRRB?.srcRowCode ?? null,
        srcWarehouseCode: sourceWarehouse.code,
        destWarehouseCode: destinationWarehouse.code,
        quantity: totalTransferQty,
        advancedTrackingType: TRACKING_TYPE.BATCH,
        advancedTrackingData: advancedData,
        productCode: item?.documentSequenceCode
      };
      let data: any = {};
      data['stockTransferWarehouseInventoryData'] = warehouseDetail;
      data['serialData'] = advancedDetailData;
      props.onSave(data);
    }
  };

  const getDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = allWarehouseData?.content?.filter(
        (warehouse: any) =>
          warehouse.warehouseType !== 'REJECTED' && warehouse.active
      );

      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;

        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      return warehouseList && warehouseList.length > 0 ? warehouseList : [];
    } else {
      return [];
    }
  };

  const getJWDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = productInventoryWarehouse?.filter(
        (warehouse: any) =>
          warehouse.id !==
          (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '')
      );
      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;
        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      if (warehouseList && warehouseList.length > 0) {
        if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          let jwoWarehouse = warehouseList?.find(
            (warehouse: any) =>
              warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
          );
          if (!Utility.isEmpty(jwoWarehouse)) {
            setDestinationWarehouse(jwoWarehouse);
          }
        }
      }
    }
  };

  const getTitleAndValueField = (
    title: any,
    value: any,
    isRightAlign = false
  ) => {
    return (
      <div
        className={`column parent-width gap-1 ${
          isRightAlign ? ' align-items-end' : ''
        }`}
      >
        <DKLabel text={title} className={`fs-r font-medium text-blue `} />
        <DKLabel text={value} className={`fs-r `} />
      </div>
    );
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <>
          <div className="row justify-content-between align-items-start">
            {getTitleAndValueField(
              t('JOB_WORK_OUT.PRODUCT_NAME'),
              item?.itemName
            )}
            {getTitleAndValueField(
              t('JOB_WORK_OUT.PRODUCT_CODE'),
              item?.documentSequenceCode
            )}

            {!isNaN(item.requiredQuantity) &&
              getTitleAndValueField(
                t('JOB_WORK_OUT.REQ_QTY'),
                item.requiredQuantity,
                true
              )}
            {getTitleAndValueField(
              t('JOB_WORK_OUT.ALLOCATED_QTY'),
              getTransferQuantity(),
              true
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1100,
          width: '100%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-size p-5">
          {getHeaderSection()}
          <div className="column mt-3 parent-width">
            {getBatchTrackingGrid()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JWOStockTransferAdvancedBatchTrackingPopup;
