import { INVOICE_NOW_TYPES } from "../../Invoices/InvoiceHelper";

export const showLinkedDocumentSection = (docs: any, isEditMode: any) => {
  return docs?.length > 0 && isEditMode;
};


export const getInvoiceNowStatusCNDN = (invoice: any) => {
  if (invoice) {
    if (
      invoice.einvoiceInfoIndia &&
      (invoice.einvoiceInfoIndia.error ||
        invoice.einvoiceInfoIndia.errorDetails)
    ) {
      return INVOICE_NOW_TYPES.FAILED;
    } else if (
      invoice.cancelledEInvoiceInfo &&
      invoice.cancelledEInvoiceInfo !== null &&
      invoice.cancelledEInvoiceInfo.status === '0' &&
      (invoice.cancelledEInvoiceInfo.error ||
        invoice.cancelledEInvoiceInfo.errorDetails)
    ) {
      return INVOICE_NOW_TYPES.FAILED;
    } else if (
      invoice.einvoiceInfoIndia !== null &&
      invoice.cancelledEInvoiceInfo === null &&
      invoice.einvoiceInfoIndia.status === '1'
    ) {
      return INVOICE_NOW_TYPES.GENERATED;
    } else if (!invoice.einvoiceInfoIndia) {
      return INVOICE_NOW_TYPES.PENDING;
    } else if (
      invoice.cancelledEInvoiceInfo &&
      invoice.cancelledEInvoiceInfo.status === '1'
    ) {
      return INVOICE_NOW_TYPES.CANCELLED;
    }
  }
};