import {
  showLoader,
  removeLoader,
  showAlert,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKInput,
  DKDataGrid
} from 'deskera-ui-library';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE
} from '../../Constants/Constant';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchProductInventoryByID } from '../../Redux/Slices/WarehouseProductSlice';
import {
  ReceivedGoodState,
  ReceiveGoodsPayload
} from '../../Models/ReceivedGoods';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized
} from '../../Utility/Utility';
import {
  fetchBills,
  selectBillsColumnConfig,
  selectBillsColumnConfigTableId
} from '../../Redux/Slices/BillsSlice';
import {
  fetchOrders,
  selectPurchaseOrdersColumnConfig,
  selectPurchaseOrdersColumnConfigTableId
} from '../../Redux/Slices/PurchaseOrdersSlice';
import DateFormatService from '../../Services/DateFormat';
import { map } from 'lodash';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  fetchWorkOuts,
  selectWorkOutsColumnConfig,
  selectWorkOutsColumnConfigTableId
} from '../../Redux/Slices/WorkOutSlice';
import useQCConfirm from '../../Hooks/useQCConfirm';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { STOCK_ADJUSTMENT_ACTION_TYPE } from '../../Components/StockManagement/StockAdjustment/StockAdjustmentConstant';
import ic_bom_allocate_red from '../../Assets/Icons/ic_bom_allocate_red.svg';
import ic_bom_allocate_green from '../../Assets/Icons/ic_bom_allocate_green.svg';
import FixedAssetRGDetails from './ReceiveGoodAssetDetails';
import FixedAssetService from '../../Services/FixedAsset';

const ReceivedGoodsForAsset: React.FC<any> = (props) => {
  const currentDate = new Date();

  const [assetRowsDetails, setAssetRowsDetails] = useState(
    !Utility.isEmpty(props?.documentDetails?.purchaseOrderAssets)
      ? props?.documentDetails?.purchaseOrderAssets
      : !Utility.isEmpty(props?.documentDetails?.purchaseInvoiceAssets)
      ? props?.documentDetails?.purchaseInvoiceAssets
      : {}
  );

  const [receivedGoodDate, setreceivedGoodDate] = useState<Date>(currentDate);
  const [documentDetails, setDocumentDetails] = useState(props.documentDetails);
  const [docType, setDocType] = useState(props.documentType);
  const [displayError, setDisplayError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');
  const columnConfigForPO = useAppSelector(selectPurchaseOrdersColumnConfig);
  const jobWorkOutColumnConfig = useAppSelector(selectWorkOutsColumnConfig);
  const columnConfigForBill = useAppSelector(selectBillsColumnConfig);
  const PoConfigTableId = useAppSelector(
    selectPurchaseOrdersColumnConfigTableId
  );
  const jobWorkOutConfigTableId = useAppSelector(
    selectWorkOutsColumnConfigTableId
  );
  const BillConfigTableId = useAppSelector(selectBillsColumnConfigTableId);
  let module = MODULES_NAME.ORDER;
  let columnConfig = columnConfigForPO;
  let columnConfigTableId = PoConfigTableId;
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [receivedGoodItems, setReceivedGoodItems] = useState<
    ReceivedGoodState['items']
  >(
    documentDetails.purchaseInvoiceProducts ||
      documentDetails.purchaseOrderItems ||
      documentDetails.jobWorkOutOrderItems
  );
  const dispatch = useAppDispatch();
  const [rgState, setRgState] = useState<any>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [currentRowData, SetCurrentRowData] = useState<any>({});
  const [showRedBarcode, setShowRedBarcode] = useState<any>(true);

  const { qcConfirm } = useQCConfirm();

  type returnFunction = (index: number) => any;
  if (props.documentDetails.documentType === 'PURCHASE_INVOICE') {
    module = MODULES_NAME.BILL;
    columnConfig = columnConfigForBill;
    columnConfigTableId = BillConfigTableId;
  }

  if (props.documentDetails.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
    module = MODULES_NAME.JOB_WORK_OUT;
    columnConfig = jobWorkOutColumnConfig;
    columnConfigTableId = jobWorkOutConfigTableId;
  }

  const columnConfigForGrid: any = [
    {
      id: 'assetGroupName',
      key: 'assetGroupName',
      name: 'Asset Group',
      type: INPUT_TYPE.TEXT,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'quantity',
      key: 'quantity',
      name: 'Remaining Quantity',
      type: INPUT_TYPE.NUMBER,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'rqty',
      key: 'rqty',
      name: 'Received Quantity',
      type: INPUT_TYPE.NUMBER,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'action',
      key: 'action',
      name: '',
      type: INPUT_TYPE.BUTTON,
      width: 80,
      options: [
        {
          icon: ic_bom_allocate_red,
          className: 'p-0 padding-top-2px',
          onClick: (data: any) => {}
        }
      ]
    }
  ];

  useEffect(() => {
    // dispatch(fetchCommonProductInventory());
    // let ids = map(receivedGoodItems, 'productCode');
    // loadProductInventoryById(ids);
    let stateArray: any = [];
    if (props.documentType === DOC_TYPE.ORDER) {
      documentDetails.purchaseOrderAssets.forEach((row: any) => {
        let rowObj: any = { ...row, rqty: 0 };
        stateArray.push(rowObj);
      });
      setRgState([...stateArray]);
    } else {
      documentDetails.purchaseInvoiceAssets.forEach((row: any) => {
        let rowObj: any = { ...row, rqty: 0 };
        stateArray.push(rowObj);
      });
      setRgState([...stateArray]);
    }
  }, []);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.RECEIVED_GOODS,
        data: () => {
          saveReceivedGoods();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
      // await dispatch(fetchProductInventory());
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const receivedGoodsPayload = (): ReceiveGoodsPayload => {
    let updatedReceivedGoodsItems: any[] = receivedGoodItems.filter(
      (item: any) => item.receiptQuantity > 0
    );
    const receiptDate = DateFormatService.getDateStrFromDate(
      receivedGoodDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    updatedReceivedGoodsItems = updatedReceivedGoodsItems.map((item) => {
      item.receiptDate = receiptDate;
      return item;
    });

    const receivedGoodsPayload = {
      ...documentDetails,
      receiptDate: receiptDate,
      documentCode:
        documentDetails.purchaseInvoiceCode ||
        documentDetails.poCode ||
        documentDetails.jwoCode,
      documentType: docType,
      items: updatedReceivedGoodsItems
    };

    const payload = new ReceiveGoodsPayload(receivedGoodsPayload);
    return payload;
  };

  const validation = (rowState: any) => {
    let allZero = true;
    rowState.forEach((row: any, index: number) => {
      if (row.rqty !== 0) {
        allZero = false;
      }
      if (row.rqty > assetRowsDetails?.[index]?.quantity) {
        setErrorMessageText(
          'Required Quantity cannot be greater than receiving quantity'
        );
        setDisplayError(true);
        return false;
      }
    });
    if (allZero) {
      setErrorMessageText(
        'Atleast one row should have quantity greater than 0'
      );
      setDisplayError(true);
      return false;
    }
    return true;
  };

  const saveReceivedGoods = async () => {
    if (!validation(rgState)) {
      return;
    }
    let Payload = receivedGoodsPayload();

    if (!Utility.isEmpty(Payload.receiptDate)) {
      const docDate = DateFormatService.getDateFromStr(
        Payload.receiptDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Receive goods date',
          'GOODS_RECEIPT'
        )
      ) {
        return;
      }
      if (!Utility.checkClosingDate(docDate, 'Receive goods date')) {
        return;
      }
    }
    showLoader();
    let itemPayload: any = [];
    rgState.forEach((row: any, index: number) => {
      let assetDetails: any[] = [];
      row.assetDetails?.forEach((asset: any) => {
        assetDetails.push({
          assetGroupId: row.assetGroupId,
          id: asset?.id,
          serialNumber: asset?.serialNumber,
          description: asset?.description,
          warehouseCode: asset?.warehouse?.code
        });
      });
      if (row.isSaved) {
        itemPayload.push({
          documentItemCode:
            props.documentType === DOC_TYPE.ORDER
              ? row.purchaseOrderItemCode
              : row.invoiceItemCode,
          receiptQuantity: row.rqty,
          assetGroupId: row.assetGroupId,
          quantityRequired: assetRowsDetails?.[index]?.quantity,
          pendingQuantity: assetRowsDetails?.[index]?.quantity - row.rqty,
          receiptDate: DateFormatService.getDateStrFromDate(
            receivedGoodDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          purchaseOrderItemCode:
            props.documentType === DOC_TYPE.ORDER
              ? row.purchaseOrderItemCode
              : row.invoiceItemCode,
          assetDetails: assetDetails
        });
      }
    });
    let payload: any = {
      documentCode: documentDetails.documentCode,
      // documentType: documentDetails.documentType,
      documentType: docType,
      documentSequenceCode: documentDetails.documentSequenceCode,
      items: itemPayload,
      receiptDate: DateFormatService.getDateStrFromDate(
        receivedGoodDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      warehouseCode: null
    };
    FixedAssetService.receiveGoods(payload)
      .then((res: any) => {
        if (props.passingInteraction) {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
          });
        }
        if (props.passingInteraction) {
          props.passingInteraction({
            type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP_RECEIVE_GOODS_SUCCESS
          });
        }
        let grSucessMessage = `Received Goods of ${
          res?.asset_goods_receipt_code
        } is saved successfully${
          payload.isQcEnabled
            ? `, please go through the quality check list.`
            : `.`
        }`;

        let buttons = [
          {
            title: 'Ok',
            className: `${
              payload.isQcEnabled ? `bg-gray2` : `bg-blue text-white`
            } border-m`,
            onClick: () => {}
          }
        ];

        if (payload.isQcEnabled) {
          buttons.push({
            title: 'Go to Quality Check',
            className: 'bg-blue text-white ml-r',
            onClick: () => {
              RouteManager.navigateToPage(PAGE_ROUTES.QUALITY_CHECK);
            }
          });
        }

        showAlert('Success!', grSucessMessage, buttons);

        if (docType === DOC_TYPE.BILL) {
          dispatch(fetchBills());
        }
        if (docType === DOC_TYPE.ORDER) {
          dispatch(fetchOrders());
        }
        removeLoader();
      })

      .catch((err: any) => {
        removeLoader();
        console.error(err);
      });
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (!Utility.checkClosingDate(newDate, 'Receive goods date')) {
        return;
      }
      if (
        !Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Receive goods date',
          'GOODS_RECEIPT'
        )
      ) {
        return;
      }
      callback(newDate);
    } else {
      callback(new Date(receivedGoodDate));
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };
  const getCalendarView = (selectedDate: any, onSelect: any) => {
    return (
      <DKInput
        className="parent-width"
        title="Received Date"
        value={selectedDate}
        type={INPUT_TYPE.DATE}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        onChange={(value: any) => {
          onSelect(value);
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        datePickerConfig={{
          isDateRange: false
        }}
      />
    );
  };
  const showErrorMessage = () => {
    if (displayError) {
      return (
        <div
          className="bottom-0 rounded bg-red-100 p-2"
          style={{
            width: '100%',
            position: 'relative',
            top: '50px',
            marginBottom: '15px'
          }}
        >
          <span>{errorMessageText}</span>
        </div>
      );
    }
  };

  const getButtonsForRow = (row: any) => {
    let buttons: any[] = [];
    buttons.push({
      type: STOCK_ADJUSTMENT_ACTION_TYPE.ALLOCATE,
      icon: row.isSaved ? ic_bom_allocate_green : ic_bom_allocate_red,
      className: 'p-v-0 text-blue underline grid-action-link-h-padding',
      onClick: ({ rowIndex, rowData }: any) => {
        if (row.isSaved) {
          rowData.assetDetails.forEach((asset: any, index: any) => {
            rowData.assetDetails[index].depreciationStartDate =
              DateFormatService.getDateStrFromDate(
                new Date(asset.depreciationStartDate),
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              );
            rowData.assetDetails[index].purchaseDate =
              DateFormatService.getDateStrFromDate(
                new Date(asset.purchaseDate),
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              );
            rowData.assetDetails[index].installationDate =
              DateFormatService.getDateStrFromDate(
                new Date(asset.installationDate),
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              );
            if (!Utility.isEmpty(asset.warrantyEndDate)) {
              rowData.assetDetails[index].warrantyEndDate =
                DateFormatService.getDateStrFromDate(
                  new Date(asset.warrantyEndDate),
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                );
            }
            if (!Utility.isEmpty(asset.warrantyStartDate)) {
              rowData.assetDetails[index].warrantyStartDate =
                DateFormatService.getDateStrFromDate(
                  new Date(asset.warrantyStartDate),
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                );
            }
          });
        }
        SetCurrentRowData({ rowIndex, rowData });
        setShowDetails(true);
      }
    });
    return buttons;
  };

  const getRowData = () => {
    let rowData: any[] = [];
    rgState.forEach((row: any, index: any) => {
      let obj: any = {
        ...row,
        assetGroupName: row.assetGroupName,
        quantity: row.quantity,
        rqty: row.rqty,
        rowButtons: getButtonsForRow(row)
      };
      rowData.push(obj);
    });

    return rowData;
  };

  const rowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let updatedState: any = [...rgState];
    updatedState[rowIndex].rqty = Number(rowData.rqty);
    setRgState([...updatedState]);
  };

  return (
    <div className="column p-4">
      <div className="row width-auto" style={{ width: 150 }}>
        {receivedGoodDate &&
          getCalendarView(receivedGoodDate, (newDate: any) =>
            validateAndUpdateDate(
              newDate,
              DateFormatService.getDateFromStr(
                tenantInfo.bookBeginningStartDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              ),
              setreceivedGoodDate,
              'Receive Goods date cannot be before the book beginning date: ' +
                tenantInfo.bookBeginningStartDate
            )
          )}
      </div>{' '}
      <div className="parent-width mb-6">{showErrorMessage()}</div>
      <div className="parent-width">
        <DKDataGrid
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={true}
          columns={columnConfigForGrid}
          rows={getRowData()}
          onRowUpdate={rowUpdate}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowBulkOperation={false}
          onRowClick={() => {}}
        />
      </div>
      {showDetails && !Utility.isEmpty(currentRowData) && (
        <FixedAssetRGDetails
          documentType={props.documentType}
          documentCode={documentDetails.documentCode}
          documentDate={new Date()}
          booksDocument={documentDetails}
          lineItemData={currentRowData}
          currency={'USD'}
          onSave={(rowIndex: number, assetDetails: any) => {
            let updatedState = [...rgState];
            updatedState[rowIndex] = {
              ...updatedState[rowIndex],
              assetDetails: assetDetails,
              rqty: assetDetails.length,
              quantity:
                assetRowsDetails[rowIndex]?.quantity - assetDetails?.length,
              isSaved: true
            };
            setDisplayError(false);
            setRgState([...updatedState]);
            setShowRedBarcode(false);
            setShowDetails(false);
          }}
          isReadOnly={false}
          onClose={() => setShowDetails(false)}
        />
      )}
    </div>
  );
};

export default ReceivedGoodsForAsset;
