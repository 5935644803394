import { WALKTHROUGH_EVENT } from '../../Constants/Enum';

export interface Walkthrough {
  title: string;
  msg: string;
  tooltipInCenter: any;
  tooltipDirection: string;
  arrowPos: string;
  events: string[];
  navigateToPage?: string;
  lookupClassName?: string;
  btnTitle?: string;
  scrollToElement?: boolean;
}

export const DASHBOARD_WALKTHROUGH: Walkthrough[] = [
  {
    title: 'Create new document',
    msg: "You can open form for creating any document from here.\nLet's open the Invoice form",
    tooltipInCenter: false,
    tooltipDirection: 'left',
    arrowPos: 'top',
    events: [WALKTHROUGH_EVENT.OPEN_ADD_NEW_MENU],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-1',
    btnTitle: 'Next'
  },
  {
    title: 'Invoice form',
    msg: "This is the form for creating a new Invoice. Let's walk through it.",
    tooltipInCenter: true,
    tooltipDirection: '',
    arrowPos: '',
    events: [WALKTHROUGH_EVENT.OPEN_DOC_FORM],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-2',
    btnTitle: 'Next'
  },
  {
    title: 'Add/Create contact',
    msg: 'You can select existing or add a new contact by clicking here.',
    tooltipInCenter: false,
    tooltipDirection: 'right',
    arrowPos: '',
    events: [WALKTHROUGH_EVENT.OPEN_DOC_CONTACT_LIST],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-3',
    btnTitle: 'Next'
  },
  {
    title: 'Select/Add sequence number',
    msg: 'You can select document sequence number by clicking here.',
    tooltipInCenter: false,
    tooltipDirection: 'left',
    arrowPos: '',
    events: [WALKTHROUGH_EVENT.CLOSE_DOC_CONTACT_LIST],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-4',
    btnTitle: 'Next'
  },
  {
    title: 'Update document date',
    msg: 'You can select document date by clicking here.',
    tooltipInCenter: false,
    tooltipDirection: 'left',
    arrowPos: '',
    events: [WALKTHROUGH_EVENT.OPEN_DOC_DATE],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-5',
    btnTitle: 'Next'
  },
  {
    title: 'Add new item',
    msg: 'You can add new line items by clicking here.',
    tooltipInCenter: false,
    tooltipDirection: 'bottom',
    arrowPos: 'left',
    events: [],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-6',
    btnTitle: 'Next'
  },
  {
    title: 'Document total',
    msg: 'This is the total of the document after applying all the taxes and discounts.',
    tooltipInCenter: false,
    tooltipDirection: 'left',
    arrowPos: 'bottom',
    events: [],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-7',
    btnTitle: 'Next',
    scrollToElement: true
  },
  {
    title: 'Finished Tour',
    msg: 'You have finished the tour.',
    tooltipInCenter: true,
    tooltipDirection: '',
    arrowPos: '',
    events: [WALKTHROUGH_EVENT.CLOSE_DOC_FORM],
    navigateToPage: '',
    lookupClassName: 'walkthrough-step-8',
    btnTitle: "Let's Begin"
  }
];
