export const AUDIT_LOG_CONSTANT: any = {
  incoming_qty: 'Incoming Quantity',
  document_seq_code: 'Document No',
  outgoing_qty: 'Outgoing Quantity',
  sales_order_code: 'Document No',
  document_code: 'Document No',
  document_dt: 'Document Date',
  contact_code: 'Contact Code',
  account_code: 'Account Code',
  tax_code: 'Tax Code',
  name: 'Name',
  description: 'Description',
  category: 'Category',
  effective_start_dt: 'Effective Start Date',
  active_flg: 'Active',
  type: 'Type',
  receipt_status: 'Receipt Status',
  status: 'Status',
  due_amount: 'Due Amount',
  payment_status: 'Payment Status',
  due_amount_in_base_currency: 'Due Amount In Base Currency',
  total_amount_in_base_currency: 'Total Amount In Base Currency',
  additional_charges: 'Additional Changes',
  total_amount: 'Total Amount',
  code: 'Code',
  amount: 'Amount',
  amount_due: 'Amount Due',
  memo: 'Memo',
  opening_balance: 'Opening Balance',
  opening_balance_in_account_currency: 'Opening Balance In Account Currency',
  prod_variant_code: 'Product Variant Code',
  purchase_price: 'Purchase price',
  sales_price: 'Sales Price',
  purchase_tax_code: 'Purchase Tax Code',
  sales_tax_code: 'Sales Tax Code',
  reorder_vendor_code: 'Record Vendor Code',
  sequence: 'Sequence',
  je_date: 'Journal Entry Date',
  is_je_re_entered: 'Journal Entry Updated',
  fulfillment_dt: 'Fulfillment Date',
  valid_till_dt: 'Valid Till Date',
  shipby_dt: 'Ship by Date'
};

export const FIELDS_TO_IGNOR = [
  'tenant_id',
  'created_dt',
  'created_dte',
  'created_by',
  'updated_dte',
  'updated_by',
  'location_id',
  'updated_dt',
  'work_order_code'
];

// Module wise ignor fields
// key = module name;
// Value = fields name that need to ignor
export const MODULE_FIELDS_TO_IGNOR: any = {
  ALL: FIELDS_TO_IGNOR,
  JOURNAL_ENTRY: ['id'],
  DIMENSION: ['shortName','code']
};
export const KEYS_TO_REPLACE:any = {
  DIMENSION: {
    status: 'Visibility',
    defaultOption: 'Set as default'
  }
}
export const detailJSONIgnorKeys:any = {
  DIMENSION:['code']
}