import { useEffect, useState } from 'react';
import { DKButton } from 'deskera-ui-library';
import { APPROVAL_STATUS, POPUP_TYPE } from '../../Constants/Constant';
import PopupWrapper from '../../SharedComponents/PopupWrapper';

interface ApprovalOrReject {
  data: any;
  actionData: any;
  setShowApprovalOrRejectPopup: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const ApprovalOrReject: React.FC<ApprovalOrReject> = (props) => {
  const [remarksData, setRemarksData] = useState('');
  const status =
    props.data.title === 'Approve'
      ? APPROVAL_STATUS.APPROVED
      : APPROVAL_STATUS.REJECTED;
  const handleAction = () => {
    props.setShowApprovalOrRejectPopup(false);
    if (props.actionData.isBulkAction) {
      props.actionData.action(props.actionData.selection, remarksData);
      return;
    }
    props.actionData.action(props.data.actionDetails, status, remarksData);
    props.setShowApprovalOrRejectPopup(false);
  };
  const styleForButton =
    props.data.title === 'Approve'
      ? 'mr-r bg-button text-white'
      : 'mr-r bg-red text-white';
  return (
    <PopupWrapper
      clickAction={() => {
        props.setShowApprovalOrRejectPopup(false);
      }}
      type={POPUP_TYPE.POPUP}
      title={props.title}
      height={'auto'}
      width={'30%'}
      btnList={[]}
      overflowVisible={true}
    >
      <div>
        <textarea
          className="my-2 resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(250, 250, 250)',
            border: '1px dashed rgb(200, 200, 200)'
          }}
          placeholder={'Remarks'}
          onChange={(e) => {
            setRemarksData(e.target.value);
          }}
          value={remarksData}
        />
        <div>
          <div className="row mt-xl justify-content-between pl-16 pr-16 ">
            <DKButton
              title={props.data.title}
              className={styleForButton}
              onClick={handleAction}
            />
            <DKButton
              title="Cancel"
              className="mr-r bg-button text-white"
              onClick={() => {
                props.setShowApprovalOrRejectPopup(false);
              }}
            />
          </div>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default ApprovalOrReject;
