import { DKButton, DKIcons, DKLabel } from 'deskera-ui-library';
import React, { useRef, useState } from 'react';
import {
  DOCUMENT_MODE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { getFormattedAddressObj } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import EditableComponent from '../../../SharedComponents/EditableComponent';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import Utility from '../../../Utility/Utility';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import AddContact from '../AddContact';

interface ContactDetailLeftPanelProps {
  contactDetails: any;
  onUpdateData?: (data: any) => void;
}

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

const popupBtnConfig: BtnType[] = [
  {
    title: 'Cancel',
    class: 'border-m mr-s',
    clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
  },
  {
    title: 'Save',
    class: 'bg-app text-white mr-ss',
    clickAction: POPUP_CLICK_TYPE.UPDATE_CONTACT
  }
];

const popupBtnConfigForCreate: BtnType[] = [
  {
    title: 'Cancel',
    class: 'border-m mr-s',
    clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
  },
  {
    title: 'Save',
    class: 'bg-app text-white mr-ss',
    clickAction: POPUP_CLICK_TYPE.CREATE_CONTACT
  }
];

const popupBtnConfigForView: BtnType[] = [
  {
    title: 'Close',
    class: 'border-m mr-s',
    clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
  }
];

const ContactDetailLeftPanel: React.FC<ContactDetailLeftPanelProps> = (
  props: any
) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isActionBtnDisabled, setIsActionBtnDisabled] =
    useState<boolean>(false);
  const addContactRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [contactMode, setContactMode] = useState<DOCUMENT_MODE>(
    DOCUMENT_MODE.EDIT
  );
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showTransactionLog, setShowTransactionLog] = useState(false);
  const [transactionData, setTransactionData] = useState<any>();

  const valueChange = (event: any, value: any, refresh: any) => {};

  const getAmountBlock = (amount: number) => {
    const tenantCurrency = tenantInfo.currency;
    return Utility.getAmoutBlockForGrid(tenantCurrency, amount + '');
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        setContactMode(DOCUMENT_MODE.EDIT);
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT:
        addContactRef.current?.storeCallbacksRef.updateContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_CONTACT_REMAINDER_STATUS:
        addContactRef.current?.storeCallbacksRef.updateContactRemainderStatus();
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        addContactRef.current?.storeCallbacksRef.copyContact();
        break;
      case POPUP_CLICK_TYPE.UPDATE_INVOICE_REMINDER:
        addContactRef.current?.storeCallbacksRef.updateInvoiceReminder();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT:
        addContactRef.current.storeCallbacksRef.updateContact =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        addContactRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        setContactMode(DOCUMENT_MODE.NEW);
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CALLBACKS_TYPE.OPEN_GLOBAL_REMAINDER_POPUP:
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_INVOICE_REMINDER:
        addContactRef.current.storeCallbacksRef.updateInvoiceReminder =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_CONTACT_REMAINDER_STATUS:
        addContactRef.current.storeCallbacksRef.updateContactRemainderStatus =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS:
        setIsActionBtnDisabled(true);

        break;
    }
  };

  const editPopUp = () => {
    const contactModeForEdit = GranularPermissionsHelper.hasPermissionFor(
      PERMISSIONS_BY_MODULE.CONTACTS.EDIT
    )
      ? DOCUMENT_MODE.EDIT
      : DOCUMENT_MODE.VIEW;
    setContactMode(contactModeForEdit);
    setShowPopup(true);
  };

  const getStatus = (statusValue: any) => {
    let contactStatus = [
      {
        id: 'INACTIVE',
        name: 'Inactive',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-6'
      }
    ];

    let statusObject = contactStatus.find(
      (status: any) => status.id === statusValue
    );

    return (
      <DKLabel
        className={`mt-s fs-m  bg-gray1 border-m border-radius-s`}
        style={{ padding: '2px 6px' }}
        text={statusObject?.name}
      />
    );
  };

  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', ';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };

  const getDefaultBillingAddress = () => {
    let defaultAddress = props.contactDetails?.billingAddress?.find(
      (address: any) => address.preferred
    );
    return getFormattedAddress(defaultAddress) || 'NA';
  };

  const getDefaultShippingAddress = () => {
    let defaultAddress = props.contactDetails?.shippingAddress?.find(
      (address: any) => address.preferred
    );
    return getFormattedAddress(defaultAddress) || 'NA';
  };

  const renderSelectedFields = () => {
    return (
      <>
        <div className="column m-v-l">
          <DKLabel className="text-gray" text={`Email`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal', overflowWrap: 'anywhere' }}
              text={
                props.contactDetails?.emailId
                  ? props.contactDetails?.emailId
                  : 'NA'
              }
            />
          </div>
        </div>
        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Phone Number`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={
                props.contactDetails?.contactNumber
                  ? props.contactDetails?.contactNumber
                  : 'NA'
              }
            />
          </div>
        </div>
        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Auto-Numbering Format`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={
                props.contactDetails?.documentSequenceCode
                  ? props.contactDetails?.documentSequenceCode
                  : 'NA'
              }
            />
          </div>
        </div>
        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Currency`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={
                props.contactDetails?.currencyCode
                  ? props.contactDetails?.currencyCode
                  : 'NA'
              }
            />
          </div>
        </div>
        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Status`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            {getStatus(props.contactDetails?.status)}
          </div>
        </div>
        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Type`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={
                props.contactDetails?.vendor && props.contactDetails?.customer
                  ? 'Customer, Vendor'
                  : props.contactDetails?.vendor
                  ? 'Vendor'
                  : props.contactDetails?.customer
                  ? 'Customer'
                  : '-'
              }
            />
          </div>
        </div>
        {/* <div className="column mb-xl">
          <DKLabel className="text-gray" text={`They Owe You`} />
          <DKLabel
            className="mt-s"
            style={{ fontWeight: 'normal' }}
            text={getAmountBlock(props.contactDetails?.theyOweYou || 0)}
          />
        </div>

        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`You Owe Them`} />
          <DKLabel
            className="mt-s"
            style={{ fontWeight: 'normal' }}
            text={getAmountBlock(props.contactDetails?.youOweThem || 0)}
          />
        </div> */}

        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Billing Address`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={getDefaultBillingAddress()}
            />
          </div>
        </div>

        <div className="column mb-xl">
          <DKLabel className="text-gray" text={`Shipping Address`} />
          <div
            className="row parent-width cursor-hand"
            onClick={() => {
              editPopUp();
            }}
          >
            <DKLabel
              className="mt-s"
              style={{ fontWeight: 'normal' }}
              text={getDefaultShippingAddress()}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="column bg-white margin-empty-mobile mobile-parent-width mobile-height-auto mobile-max-width-100 parent-height hide-scroll-bar "
      style={{ overflowY: 'auto' }}
    >
      <div className="column parent-width border-box p-l parent-height mobile-height-auto position-relative">
        <div className="row justify-content-between">
          <DKButton
            icon={DKIcons.ic_arrow_left}
            className="mr-ss"
            style={{ paddingLeft: 0 }}
            onClick={() => {
              RouteManager.navigateToPage(PAGE_ROUTES.CONTACTS);
            }}
          />
          <DKLabel
            text={props.contactDetails?.name ? props.contactDetails?.name : '-'}
            style={{ overflowWrap: 'anywhere' }}
            className={`row max-width-90 fw-m text-blue fs-m contact-name`}
          />
          <DKButton
            style={{ paddingRight: 0, borderColor: '#000000' }}
            icon={DKIcons.ic_edit}
            onClick={() => {
              editPopUp();
            }}
          />
        </div>
        {renderSelectedFields()}
        <div className="parent-width border-box dk-input-holder">
          <EditableComponent
            contactId={props.contactId}
            fields={props.contactDetails?.customField || []}
            onChange={valueChange}
            canEdit={props.canEdit}
            onClickValue={() => {
              editPopUp();
            }}
          />
        </div>
        <div className="column mb-m">
          <DKButton
            className="underline text-blue"
            title={'More'}
            style={{ paddingLeft: 0, paddingBottom: 24 }}
            onClick={() => {
              editPopUp();
            }}
          />
        </div>
      </div>
      {showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={
            contactMode === DOCUMENT_MODE.EDIT
              ? 'Contact Details'
              : contactMode === DOCUMENT_MODE.VIEW
              ? 'View Contact'
              : 'Add Contact'
          }
          btnList={
            contactMode === DOCUMENT_MODE.EDIT
              ? popupBtnConfig
              : contactMode === DOCUMENT_MODE.VIEW
              ? popupBtnConfigForView
              : popupBtnConfigForCreate
          }
          width={!isDesktop ? '95%' : '40%'}
          minWidth={!isDesktop ? '' : '550px'}
          height={'95%'}
          isActionBtnDisabled={isActionBtnDisabled}
          disableClickOutside={true}
          readOnly={contactMode === DOCUMENT_MODE.VIEW}
        >
          <AddContact
            contactMode={contactMode}
            populateFormData={
              contactMode === DOCUMENT_MODE.NEW ? null : props.contactDetails
            }
            onSuccess={(data: any) => props.onUpdateData(data)}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default ContactDetailLeftPanel;
