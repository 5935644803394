import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface RequestForQuotationAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  CustomField?: any;
}

export const defaultConfig: RequestForQuotationAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};
class RequestForQuotationService {
  static apiConfig: RequestForQuotationAPIConfig;
  static abortController: any = null;

  static getRfqByPage() {
    Utility.cancelRequest(RequestForQuotationService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    const finalEndpoint: string = ApiConstants.URL.RFQ.FETCH_RFQ + queryString;

    RequestForQuotationService.abortController =
      Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: RequestForQuotationService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static createRfq(payload: any) {
    return http2
      .post(`${ApiConstants.URL.RFQ.CREATE_RFQ}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchRfqDetailsById(rfqCode: string) {
    return http
      .get(`${ApiConstants.URL.RFQ.FETCH_RFQ_BY_ID(rfqCode)}`)
      .catch((err: any) => {
        console.error('Error fetching request for quotation: ', err);
        return Promise.reject(err);
      });
  }

  static deleteRfq(id: number) {
    return http2
      .delete(`${ApiConstants.URL.RFQ.DELETE_RFQ(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.PURCHASE_REQUEST_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }
}

export default RequestForQuotationService;
