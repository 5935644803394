import { useEffect, useState } from 'react';
import {
  DKDataGrid,
  DKButton,
  DKLabel,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { useAppSelector } from '../../Redux/Hooks';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized
} from '../../Utility/Utility';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectPurchaseTax } from '../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE,
  TAX_SYSTEM
} from '../../Constants/Constant';
import { getTenantTaxSystem } from './NewDocumentHelper';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { ConfigUtility } from '../../Utility/ConfigUtility';
import { selectWarehouse } from '../../Redux/Slices/WarehouseSlice';
import WarehouseService from '../../Services/Warehouse';
import FixedAssetService from '../../Services/FixedAsset';
import { subDays } from 'date-fns';
import { DraftTypes } from '../../Models/Drafts';

const DEPRECIATION_METHODS = [
  { name: 'No Depreciation', value: 'NO_DEPRECIATION' },
  { name: 'Straight Line', value: 'STRAIGHT_LINE' },
  { name: 'Instant Asset Write-off', value: 'INSTANT_ASSET_WRITE_OFF' }
];

const DEPRECIATION_CONVENTIONS = [
  { name: 'Full Month', value: 'FULL_MONTH' },
  { name: 'Actual Date', value: 'ACTUAL_DATE' }
];

let initialState = {
  assetGroupId: null,
  name: '',
  openingAsset: false,
  serialNumber: '',
  purchaseDate: '',
  installationDate: '',
  purchasePrice: 0,
  description: '',
  depreciationStartDate: '',
  discount: 0,
  discountInPercent: false,
  residualValue: '',
  depreciationThreshold: '',
  depreciationMethod: 'NO_DEPRECIATION',
  depreciationConvention: 'FULL_MONTH',
  depreciationRate: null,
  effectiveLife: null,
  currency: '',
  sequenceFormat: '',
  profitLossAccountCode: '',
  salesIncomeAccountCode: '',
  warrantyStartDate: '',
  warrantyEndDate: '',
  warehouse: '',
  tax: null
};

export default function FixedAssetDetails(props: any) {
  const tenantDetails = useAppSelector(activeTenantInfo);
  const accountsData = useAppSelector(selectedAccounts);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const warehousesData = useAppSelector(selectWarehouse);
  const tenantInfo = useAppSelector(activeTenantInfo);

  const [documentDate] = useState<Date>(props.documentDate);
  const [lineItem] = useState<any>(props.lineItemData?.rowData);
  const [assetDetails, setAssetDetails] = useState<any[]>([]);
  const [columnConfig, setColumnConfig] = useState(AssetDetailsColumnConfig);
  const [allowEdit, setAllowEdit] = useState<boolean>(true);
  useEffect(() => {
    if (props.draftType === DraftTypes.READONLY) {
      setAllowEdit(false);
    }

    if (
      props.documentStatus === DOCUMENT_MODE.VIEW ||
      props.documentStatus === DOCUMENT_MODE.EDIT
    ) {
      if (lineItem && lineItem.assetGroupId !== undefined) {
        let payload = {
          documentType:
            props.booksDocument.documentType === DOC_TYPE.FA_BILL
              ? DOC_TYPE.BILL
              : DOC_TYPE.ORDER,
          documentCode: props.booksDocument.documentCode,
          assetGroupId: lineItem.assetGroupId,
          documentItemCode: lineItem.invoiceItemCode
            ? lineItem.invoiceItemCode
            : lineItem.purchaseOrderItemCode,
          linkedDocumentCode:
            props.booksDocument?.linkedDocuments?.[0]?.documentCode ||
            props.booksDocument?.linkedPurchaseInvoices?.[0]
              ?.purchaseInvoiceCode ||
            '',
          lineNumber: lineItem.lineNumber
        };
        FixedAssetService.getAssetDetails(payload)
          .then((res: any) => {
            let tmpAssetDetails: any[] = [];
            if (res && res.length > 0) {
              res.forEach((element: any) => {
                let tmp = {
                  id: element.id,
                  name: element.name,
                  description: element.description,
                  purchaseDate: element.purchaseDate
                    ? DateFormatService.getDateFromStr(
                        element.purchaseDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : '',
                  installationDate: element.installationDate
                    ? DateFormatService.getDateFromStr(
                        element.installationDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : '',
                  purchasePrice: element.purchasePrice,
                  discount: element.discount,
                  tax: element.taxCode ? mapPurchaseTax(element.taxCode) : {},
                  totalAmount: element.totalAmount,
                  serialNumber: element.serialNumber,
                  warehouse: element.warehouse
                    ? mapWarehouse(element.warehouse)
                    : {},
                  profitLossAccountCode: element.salesIncomeAccountCode
                    ? mapAccounts(element.profitLossAccountCode)
                    : {},
                  salesIncomeAccountCode: element.salesIncomeAccountCode
                    ? mapAccounts(element.salesIncomeAccountCode)
                    : {},
                  depreciationStartDate: element.depreciationStartDate
                    ? DateFormatService.getDateFromStr(
                        element.depreciationStartDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : '',
                  depreciationThreshold: element.depreciationThreshold,
                  residualValue: element.residualValue,
                  depreciationMethod: element.depreciationMethod
                    ? mapDepreciationMethod(element.depreciationMethod)
                    : {},
                  depreciationConvention: element.depreciationConvention
                    ? mapDepreciationConvention(element.depreciationConvention)
                    : {},
                  depreciationRate: element.depreciationRate,
                  effectiveLife: element.effectiveLife,
                  warrantyStartDate: element.warrantyStartDate
                    ? DateFormatService.getDateFromStr(
                        element.warrantyStartDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : '',
                  warrantyEndDate: element.warrantyEndDate
                    ? DateFormatService.getDateFromStr(
                        element.warrantyEndDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )
                    : ''
                };
                tmpAssetDetails.push(tmp);
              });
              setAllowEdit(false);
              setAssetDetails(tmpAssetDetails);
            }
          })
          .catch((err: any) => {});
      }
    }
  }, []);

  const mapPurchaseTax = (code: any) => {
    let x = purchaseTaxes.filter((res: any) => res.code === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };
  const mapWarehouse = (code: any) => {
    let x = warehousesData?.content.filter((res: any) => res.code === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };
  const mapAccounts = (code: any) => {
    let x = accountsData?.content.filter((res: any) => res.code === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };
  const mapDepreciationMethod = (code: any) => {
    let x = DEPRECIATION_METHODS.filter((res: any) => res.value === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };
  const mapDepreciationConvention = (code: any) => {
    let x = DEPRECIATION_CONVENTIONS.filter((res: any) => res.value === code);
    if (x.length > 0) {
      return x[0];
    }
    return {};
  };

  useEffect(() => {
    if (!Utility.isEmpty(lineItem?.assetDetails)) {
      let details = [];
      for (let i = 0; i < lineItem.assetDetails?.length; i++) {
        const total = calculateInitialLineLevelTotal(
          lineItem,
          lineItem.assetDetails?.length
        );
        const assetDetail = lineItem.assetDetails[i];
        details.push({
          ...assetDetail,
          totalAmount: Utility.roundOffToTenantDecimalScale(total),
          currency: props.currency,

          depreciationConvention:
            typeof assetDetail?.depreciationConvention === 'string'
              ? DEPRECIATION_CONVENTIONS.find(
                  (x: any) => x.value === assetDetail?.depreciationConvention
                )
              : assetDetail?.depreciationConvention,
          depreciationMethod:
            typeof assetDetail?.depreciationMethod === 'string'
              ? DEPRECIATION_METHODS.find(
                  (x: any) => x.value === assetDetail?.depreciationMethod
                )
              : assetDetail?.depreciationMethod,
          profitLossAccountCode:
            typeof assetDetail?.profitLossAccountCode === 'string'
              ? accountsData?.content?.find(
                  (item: any) =>
                    item.code === assetDetail?.profitLossAccountCode
                )
              : assetDetail?.profitLossAccountCode,
          salesIncomeAccountCode:
            typeof assetDetail?.salesIncomeAccountCode === 'string'
              ? accountsData?.content?.find(
                  (item: any) =>
                    item.code === assetDetail?.salesIncomeAccountCode
                )
              : assetDetail?.salesIncomeAccountCode,
          warehouse:
            typeof assetDetail?.warehouse === 'string'
              ? warehousesData?.content?.find(
                  (item: any) => item.code === assetDetail?.warehouse
                )
              : assetDetail?.warehouse
        });
      }
      setAssetDetails([...details]);
    } else {
      let details = [];
      for (let i = 0; i < lineItem.productQuantity; i++) {
        const total = calculateInitialLineLevelTotal(
          lineItem,
          lineItem.productQuantity
        );
        if (lineItem.assetGroupResponse) {
          lineItem.fixedAssetGroup = lineItem.assetGroupResponse
        }
        details.push({
          ...initialState,
          assetGroupId: lineItem?.fixedAssetGroup?.id,
          serialNumber: lineItem?.fixedAssetGroup?.id,
          purchasePrice: lineItem?.unitPrice,
          discount: Utility.roundOffToTenantDecimalScale(
            lineItem?.discount / lineItem.productQuantity
          ),
          totalAmount: Number(Utility.roundOffToTenantDecimalScale(total)),
          tax: lineItem?.tax,
          currency: props.currency,
          depreciationConvention: lineItem?.fixedAssetGroup
            ?.depreciationConvention
            ? DEPRECIATION_CONVENTIONS.find(
                (x: any) =>
                  x.value === lineItem?.fixedAssetGroup?.depreciationConvention
              )
            : 'FULL_MONTH',
          depreciationMethod: lineItem?.fixedAssetGroup?.depreciationMethod
            ? DEPRECIATION_METHODS.find(
                (x: any) =>
                  x.value === lineItem?.fixedAssetGroup?.depreciationMethod
              )
            : 'STRAIGHT_LINE',
          depreciationRate: lineItem?.fixedAssetGroup?.depreciationRate,
          profitLossAccountCode: accountsData?.content?.find(
            (item: any) => item.code === 'AC-0000030'
          ),
          salesIncomeAccountCode: accountsData?.content?.find(
            (item: any) => item.code === 'AC-0000036'
          ),
          warehouse: warehousesData?.content?.find(
            (item: any) => item.code === 'WH-0000001'
          ),
          purchaseDate: new Date().toISOString(),
          installationDate: new Date().toISOString(),
          residualValue: '0',
          warrantyStartDate: new Date().toISOString(),
          warrantyEndDate: new Date(
            new Date().setFullYear(new Date().getFullYear() + 2)
          ).toISOString(),
          depreciationStartDate: new Date().toISOString()
        });
      }
      let tmpDiscount = 0;
      details.forEach((item: any, index: any) => {
        if (index === details.length - 1) {
          item.discount = Utility.roundOffToTenantDecimalScale(
            lineItem.discount - tmpDiscount
          );
          if (tenantInfo.country === COUNTRY_CODES.IN) {
            if (lineItem.tax === undefined) {
              props.onClose()
              showAlert("Please select tax", "Check line item " + lineItem.lineNumber);
              return
            }
            item.totalAmount = Utility.roundOffToTenantDecimalScale(
              (item.purchasePrice - item.discount) *
                (lineItem.tax.percent / 100) +
                item.purchasePrice -
                item.discount
            );
          } else {
            item.totalAmount = Utility.roundOffToTenantDecimalScale(
              item.purchasePrice - item.discount
            );
          }
        }
        tmpDiscount = tmpDiscount + item.discount;
      });
      setAssetDetails([...details]);
    }
  }, [lineItem]);

  useEffect(() => {
    updateConfig();
  }, [lineItem, assetDetails]);

  useEffect(() => {}, [assetDetails]);

  const calculateInitialLineLevelTotal = (detail: any, quantity: any) => {
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = detail?.tax?.percent || 0;
      let discount =
        Utility.roundOffToTenantDecimalScale(detail?.discount / quantity) || 0;
      const priceAfterDiscount = price - discount;
      const total =
        priceAfterDiscount + priceAfterDiscount * (taxPercent / 100);
      return Utility.roundOffToTenantDecimalScale(total);
    } else {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      let discount =
        Utility.roundOffToTenantDecimalScale(detail?.discount / quantity) || 0;
      const priceAfterDiscount = price - discount;
      //   const total = priceAfterDiscount;
      return Number(Utility.roundOffToTenantDecimalScale(priceAfterDiscount));
    }
  };
  const calculateLineLevelTotal = (detail: any) => {
    if (tenantInfo.country === COUNTRY_CODES.IN) {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = detail?.tax?.percent || 0;
      let discount = detail?.discount || 0;
      const priceAfterDiscount = price - discount;
      const total =
        priceAfterDiscount + priceAfterDiscount * (taxPercent / 100);
      return Utility.roundOffToTenantDecimalScale(total);
    } else {
      const price = detail?.purchasePrice || detail?.unitPrice || 0;
      const taxPercent = detail?.tax?.percent || 0;
      let discount = detail?.discount || 0;
      const priceAfterDiscount = price - discount;
      return Number(Utility.roundOffToTenantDecimalScale(priceAfterDiscount));
    }
  };

  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'name':
          conf.editable = allowEdit;
          break;
        case 'description':
          conf.editable = allowEdit;
          break;
        case 'purchaseDate':
          conf.editable = allowEdit;
          break;
        case 'installationDate':
          conf.editable = allowEdit;
          break;
        case 'purchasePrice':
          conf.editable = allowEdit;
          break;
        case 'discount':
          conf.editable = allowEdit;
          break;
        case 'tax':
          let taxData = purchaseTaxes;
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                documentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                documentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                documentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });
          conf.hidden = getTenantTaxSystem() === TAX_SYSTEM.US;
          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                props.documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(data, props.documentType);
          break;
        case 'serialNumber':
          conf.editable = allowEdit;
          break;
        case 'warehouse':
          conf.editable = allowEdit;
          conf.dropdownConfig.data = [];
          conf.formatter = (obj: any) => {
            return obj?.value?.name;
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (searchValue: any) => {
            WarehouseService.apiConfig = {
              Limit: 25,
              Page: 0,
              SortDir: 'desc',
              Sort: 'code',
              SKIP_REQUEST_INTERCEPTOR: true,
              SearchTerm: searchValue,
              Query: 'active=true'
            };
            return WarehouseService.getWarehouseEndPoint();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) => {
            return data?.content;
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                className="text-align-left parent-width white-space-nowrap"
                text={obj?.name}
              />
            );
          };
          break;
        case 'profitLossAccountCode':
          conf.editable = allowEdit;
          let data = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(data);
          let structuredData = ConfigUtility.structuredAccountsArr;
          conf.dropdownConfig.data = structuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const query = 'status=ACTIVE';
            return DocumentConfigManager.getAccountURL(search, query);
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            let filtered = response?.content?.filter(
              (acc: any) => acc.status === 'ACTIVE'
            );

            if (Utility.isEmpty(filtered)) {
              return Utility.isEmpty(accountsData?.content)
                ? []
                : accountsData?.content?.filter(
                    (item: any) => item.status === 'ACTIVE'
                  );
            }
            return filtered;
          };
          break;
        case 'salesIncomeAccountCode':
          conf.editable = allowEdit;
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          conf.dropdownConfig.data = Utility.isEmpty(accountsData?.content)
            ? []
            : accountsData?.content?.filter(
                (item: any) => item.accountGroup === 'Expenses'
              );
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const query = 'status=ACTIVE&accountGroup=Expenses';
            return DocumentConfigManager.getAccountURL(search, query);
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            let filtered = response?.content?.filter(
              (acc: any) =>
                acc.status === 'ACTIVE' && acc.accountGroup === 'Expenses'
            );

            if (Utility.isEmpty(filtered)) {
              return Utility.isEmpty(accountsData?.content)
                ? []
                : accountsData?.content?.filter(
                    (item: any) => item.accountGroup === 'Expenses'
                  );
            }
            return filtered;
          };
          break;
        case 'depreciationStartDate':
          conf.editable = allowEdit;
          break;
        case 'depreciationThreshold':
          conf.editable = allowEdit;
          break;
        case 'residualValue':
          conf.editable = allowEdit;
          break;
        case 'depreciationMethod':
          conf.editable = allowEdit;
          break;
        case 'depreciationConvention':
          conf.editable = allowEdit;
          break;
        case 'depreciationRate':
          conf.editable = allowEdit;
          break;
        case 'warrantyStartDate':
          conf.editable = allowEdit;
          break;
        case 'warrantyEndDate':
          conf.editable = allowEdit;
          break;
        case 'effectiveLife':
          conf.editable = allowEdit;
          break;

        default:
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    // callback: any,
    fieldText: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `${fieldText} should not be less than back date : ${formatedDate}.`
          );
          return false;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        return true;
        // setBooksDocument((prevState: any) => {
        //   return {
        //     ...prevState,
        //     isDocumentTouched: true
        //   };
        // });
      } else {
        return false;
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      return true;
      //   return newDate
      //   setBooksDocument((prevState: any) => {
      //     return {
      //       ...prevState,
      //       isDocumentTouched: true
      //     };
      //   });
    }
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    fieldText: any,
    // callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      let closeDateFY: Date = Utility.getCloseDateFY();
      const tenantCloseDateFY = tenantInfo.fyClosingPeriodEndDate;
      if (tenantCloseDateFY && closeDateFY.getTime() > newDate.getTime()) {
        showAlert(
          'Invalid Date',
          `${fieldText} should not before financial year close date`
        );
        return false;
      }
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          //   setDocumentDate,
          fieldText,
          `${fieldText} should be in active date range`
        );
        return false;
      }
      return true;
      //   setBooksDocument((prevState: any) => {
      //     return {
      //       ...prevState,
      //       isDocumentTouched: true
      //     };
      //   });
    } else {
      return false;
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const isBeforeBillsDate = (fieldDate: any) => {
    let documentDate = DateFormatService.getDateFromStr(
      props.booksDocument.documentDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (fieldDate < documentDate) {
      return true;
    }
    return false;
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let rows: any = [...assetDetails];
    let selectedRow = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];

    selectedRow.invalidFields = selectedRow.invalidFields
      ? [...selectedRow.invalidFields].filter((field) => field !== columnKey)
      : [];

    switch (columnKey) {
      case 'purchaseDate':
        let isValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Purchase Date',
          'Error',
          false
        );
        if (!isValid) {
          showAlert(
            'Error',
            'Purchase Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
          // return
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Purchase Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            rows[rowIndex]['installationDate'] = dataToUpdate;
            rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
            rows[rowIndex]['warrantyStartDate'] = dataToUpdate;
          }
        }

        break;
      case 'installationDate':
        let isInstallValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Installation Date',
          'Error',
          false
        );
        if (!isInstallValid) {
          showAlert(
            'Error',
            'Installation Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Installation Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
          }
        }
        break;
      case 'depreciationStartDate':
        let isDepreciationStartDateValid = validateAndUpdateDate(
          new Date(dataToUpdate),
          DateFormatService.getDateFromStr(
            tenantInfo.bookBeginningStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          ),
          'Depreciation Date',
          'Error',
          false
        );
        if (!isDepreciationStartDateValid) {
          showAlert(
            'Error',
            'Depreciation Start Date cannot be before books beginning date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (dataToUpdate < rows[rowIndex]['installationDate']) {
            showAlert(
              'Error',
              'Depreciation Start Date cannot be before installation date.'
            );
            rows[rowIndex][columnKey] = null;
          } else {
            if (isBeforeBillsDate(new Date(dataToUpdate))) {
              showAlert(
                'Error',
                'Depreciation Start Date cannot be before Bill date.'
              );
              rows[rowIndex][columnKey] = null;
            } else {
              rows[rowIndex][columnKey] = dataToUpdate;
              rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
            }
          }
        }
        break;
      case 'warrantyStartDate':
        if (dataToUpdate < rows[rowIndex]['installationDate']) {
          showAlert(
            'Error',
            'Warranty Start Date cannot be before installation date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert(
              'Error',
              'Warranty Start Date cannot be before Bill date.'
            );
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
            // rows[rowIndex]['depreciationStartDate'] = dataToUpdate;
          }
        }
        break;
      case 'warrantyEndDate':
        if (dataToUpdate < rows[rowIndex]['warrantyStartDate']) {
          showAlert(
            'Error',
            'Warranty end date cannot be before warranty start date.'
          );
          rows[rowIndex][columnKey] = null;
        } else {
          if (isBeforeBillsDate(new Date(dataToUpdate))) {
            showAlert('Error', 'Warranty End Date cannot be before Bill date.');
            rows[rowIndex][columnKey] = null;
          } else {
            rows[rowIndex][columnKey] = dataToUpdate;
          }
          rows[rowIndex][columnKey] = dataToUpdate;
        }
        break;
      case 'depreciationMethod':
        rows[rowIndex].nonEditableColumns = [];
        const method = dataToUpdate.value;
        if (
          method === 'NO_DEPRECIATION' ||
          method === 'INSTANT_ASSET_WRITE_OFF'
        ) {
          if (method === 'NO_DEPRECIATION') {
            rows[rowIndex]?.nonEditableColumns?.push('depreciationThreshold');
            rows[rowIndex]?.nonEditableColumns?.push('residualValue');
          }
          rows[rowIndex]?.nonEditableColumns?.push('depreciationConvention');
          rows[rowIndex]?.nonEditableColumns?.push('depreciationRate');
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        break;

      case 'purchasePrice':
      case 'discount':
        rows[rowIndex][columnKey] =
          Utility.roundOffToTenantDecimalScale(dataToUpdate);
        if (
          dataToUpdate < 0 ||
          (dataToUpdate as number) > rows[rowIndex]['purchasePrice']
        ) {
          selectedRow.invalidFields.push('discount');
        } else {
          const total = calculateLineLevelTotal(rows[rowIndex]);
          rows[rowIndex]['totalAmount'] =
            Utility.roundOffToTenantDecimalScale(total);
        }
        break;
      case 'tax':
        rows[rowIndex][columnKey] = dataToUpdate;
        const total = calculateLineLevelTotal(rows[rowIndex]);
        rows[rowIndex]['totalAmount'] = total;
        break;

      default:
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
    }

    setAssetDetails(rows);
  };

  const validateForm = () => {
    let error = false;
    let calculatedTotal = assetDetails
      .map((res: any) => {
        return res.totalAmount;
      })
      .reduce((a: any, b: any) => {
        return a + b;
      });

    for (let i = 0; i < assetDetails.length; i++) {
      if (
        Utility.isEmpty(assetDetails[i].name) ||
        !assetDetails[i]?.name?.trim()
      ) {
        showAlert('Error!', 'Missing Asset Name');
        error = true;
        return false;
      }

      if (
        assetDetails[i].purchaseDate === null ||
        assetDetails[i].purchaseDate === undefined ||
        assetDetails[i].purchaseDate === ''
      ) {
        if (Utility.isEmpty(assetDetails[i].purchaseDate)) {
          showAlert('Error!', 'Missing Purchase Date');
          error = true;
          return false;
        }
      }

      if (
        assetDetails[i].installationDate === null ||
        assetDetails[i].installationDate === undefined ||
        assetDetails[i].installationDate === ''
      ) {
        if (Utility.isEmpty(assetDetails[i].installationDate)) {
          showAlert('Error!', 'Missing Purchase Date');
          error = true;
          return false;
        }
      }

      if (
        assetDetails[i].purchasePrice <= 0 ||
        assetDetails[i].purchasePrice === null ||
        assetDetails[i].purchasePrice === undefined
      ) {
        showAlert('Error!', 'Missing Purchase Price');
        error = true;
        return false;
      }
      //   if (Utility.isEmpty(assetDetails[i].serialNumber)) {
      //     showAlert('Error!', 'Missing Serial Number');
      //     error = true;
      //     return false;
      //   }
      //   if (Utility.isEmpty(assetDetails[i].warehouse)) {
      //     showAlert('Error!', 'Missing Warehouse');
      //     error = true;
      //     return false;
      //   }

      if (Utility.isEmpty(assetDetails[i].profitLossAccountCode)) {
        showAlert('Error!', 'Missing P/L Account');
        error = true;
        return false;
      }
      if (Utility.isEmpty(assetDetails[i].salesIncomeAccountCode)) {
        showAlert('Error!', 'Missing Income Account');
        error = true;
        return false;
      }
      if (Utility.isEmpty(assetDetails[i].depreciationMethod)) {
        showAlert('Error!', 'Missing Depreciation Method');
        error = true;
        return false;
      }
      if (Utility.isEmpty(assetDetails[i].depreciationConvention)) {
        showAlert('Error!', 'Missing Depreciation Convention');
        error = true;
        return false;
      }
      //   if (Utility.isEmpty(assetDetails[i].depreciationStartDate)) {
      //     showAlert('Error!', 'Missing Depreciation Start Date');
      //     error = true;
      //     return false;
      //   }
      //   if (Utility.isEmpty(assetDetails[i].depreciationMethod)) {
      //     showAlert('Error!', 'Missing Depreciation Method');
      //     error = true;
      //     return false;
      //   }
    }

    if (!error) {
      if (tenantInfo.country === COUNTRY_CODES.US) {
        if (
          Utility.roundOffToTenantDecimalScale(Number(calculatedTotal)) !==
          Utility.roundOffToTenantDecimalScale(Number(lineItem.total))
        ) {
          showAlert(
            'Error!',
            'Total Amount does not match with Asset Group amount'
          );
          error = true;
        }
      } else {
        if (
          Utility.roundOffToTenantDecimalScale(Number(calculatedTotal)) !==
          Utility.roundOffToTenantDecimalScale(Number(lineItem.amount))
        ) {
          showAlert(
            'Error!',
            'Total Amount does not match with Asset Group amount'
          );
          error = true;
        }
      }
    }

    return !error;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel text="Fixed Asset Details" className="fw-m fs-l" />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white"
            onClick={() => {
              if (validateForm()) {
                if (props.onSave) {
                  props.onSave(props.lineItemData?.rowIndex, assetDetails);
                }
              }
            }}
          />
        </div>
      </div>
    );
  };

  // Datagrid and columns
  const getDataGrid = () => (
    <DKDataGrid
      needShadow={false}
      needColumnIcons={false}
      needBorder={true}
      needTrailingColumn={false}
      allowBulkOperation={false}
      allowColumnSort={false}
      filterData={[]}
      allowColumnDelete={false}
      allowRowEdit={true}
      allowColumnEdit={false}
      allowFilter={false}
      allowColumnAdd={false}
      allowBottomRowAdd={false}
      allowSearch={false}
      allowShare={false}
      rows={[...assetDetails]}
      columns={columnConfig}
      onRowUpdate={onRowUpdate}
      dateFormat={convertBooksDateFormatToUILibraryFormat(
        tenantDetails.dateFormat
      )}
    />
  );

  const getBody = () => {
    return (
      <div
        className="column parent-width parent-height p-r flex-1 overflow-y-auto"
        style={{ pointerEvents: 'auto' }}
      >
        {getDataGrid()}
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          minWidth: '100%',
          minHeight: 500,
          maxHeight: '90%',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        <div className="row" style={{ pointerEvents: 'auto' }}>
          {getHeader()}
        </div>
        {/* <div
          className=" parent-height p-r flex-1 overflow-y-auto"
          style={{
            pointerEvents: 'auto'
          }}
        > */}
        {getBody()}

        {/* </div> */}
      </div>
    </div>
  );
}

const AssetDetailsColumnConfig = [
  {
    id: 'name',
    key: 'name',
    name: 'Asset Name',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'purchaseDate',
    key: 'purchaseDate',
    name: 'Purchase Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'installationDate',
    key: 'installationDate',
    name: 'Installation Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'purchasePrice',
    key: 'purchasePrice',
    name: 'Price',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'discount',
    key: 'discount',
    name: 'Discount',
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'tax',
    key: 'tax',
    name: 'Tax',
    type: INPUT_TYPE.DROPDOWN,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    renderer: DocumentConfigUtility.faTaxRenderer,
    dropdownConfig: {
      title: 'Select Tax',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: DocumentConfigUtility.taxOptionRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'totalAmount',
    key: 'totalAmount',
    name: 'Amount',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'serialNumber',
    key: 'serialNumber',
    name: 'Serial No.',
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'warehouse',
    key: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'profitLossAccountCode',
    key: 'profitLossAccountCode',
    name: 'Profit/Loss Account',
    type: INPUT_TYPE.DROPDOWN,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value.name;
    },
    dropdownConfig: {
      title: 'Select Account',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 268 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'salesIncomeAccountCode',
    key: 'salesIncomeAccountCode',
    name: 'Purchase/Expense Account',
    type: INPUT_TYPE.DROPDOWN,
    width: 220,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value.name;
    },
    dropdownConfig: {
      title: 'Select Account',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 268 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'depreciationStartDate',
    key: 'depreciationStartDate',
    name: 'Depreciation Start Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 180,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'depreciationThreshold',
    key: 'depreciationThreshold',
    name: 'Depreciation Threshold',
    type: INPUT_TYPE.NUMBER,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'residualValue',
    key: 'residualValue',
    name: 'Residual Value',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'depreciationMethod',
    key: 'depreciationMethod',
    name: 'Depreciation Method',
    type: INPUT_TYPE.DROPDOWN,
    width: 168,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value?.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 169 },
      className: 'shadow-m width-auto',
      data: DEPRECIATION_METHODS,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      renderer: (index: number, obj: any) => {
        return obj ? obj.name : '';
      }
    }
  },
  {
    id: 'depreciationConvention',
    key: 'depreciationConvention',
    name: 'Depreciation Convention',
    type: INPUT_TYPE.DROPDOWN,
    width: 190,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (obj: any) => {
      return obj.value?.name;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 190 },
      className: 'shadow-m width-auto',
      data: DEPRECIATION_CONVENTIONS,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      renderer: (index: number, obj: any) => {
        return obj.name;
      }
    }
  },
  {
    id: 'depreciationRate',
    key: 'depreciationRate',
    name: 'Depreciation Rate',
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'effectiveLife',
    key: 'effectiveLife',
    name: 'Asset Life',
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'warrantyStartDate',
    key: 'warrantyStartDate',
    name: 'Warranty Start Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 160,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'warrantyEndDate',
    key: 'warrantyEndDate',
    name: 'Warranty End Date',
    type: INPUT_TYPE.DATE,
    required: false,
    width: 160,
    editable: true,
    hidden: false,
    uiVisible: true
  }
];
