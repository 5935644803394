import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  RECEIVED_GOODS_STATUS,
  DOCUMENT_MODE
} from '../../Constants/Constant';
import format from 'date-fns/format';
import { RequisitionOrder } from '../../Models/RequisitionOrder';
import { SupplierQuote } from '../../Models/SupplierQuote';

import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const setItemsFromSupplierQuoteItems = (doc: any) => {
  const document = {
    ...doc,
    items: doc.supplierQuotesItems?.map((item: any) => {
      return {
        ...item,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive
      };
    }),
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.SUPPLIER_QUOTE,
    documentDate: doc.purchaseRequestForQuotesDate,
    validTillDate: doc.shipByDate,
    fulfillmentDate: doc.shipByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  return document;
};

export const getUpdatedSupplierQuotesObject = (props: SupplierQuote) => {
  let updatedObj: any = {
    ...props,
    billTo: props.billTo,
    shipTo: props.shipTo,
    documentType: DOC_TYPE.SUPPLIER_QUOTE,
    currencyCode: Utility.getValue(props.currency, props.currencyCode),
    documentCode: Utility.getValue(
      props.documentCode,
      props.supplierQuotesCode
    ),
    vendorCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    contactCode: Utility.getValue(
      props.vendorCode,
      props.contactCode,
      props.contact?.code
    ),
    buyType: Utility.getValue(props.buyType, props.orderType),
    orderType: Utility.getValue(props.buyType, props.orderType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    placeOfSupply: props.placeOfSupply,
    reorder: props.reorder || false,
    dropShip: props.dropShip ? props.dropShip : false,
    backOrder: props.backOrder ? props.backOrder : false,
    linkedPurchaseInvoices: props.linkedPurchaseInvoices,
    dueDate: Utility.getValue(props.dueDate, props.validTillDate),
    orderDueDate: Utility.getValue(props.dueDate, props.validTillDate),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    validTillDate: Utility.getValue(props.dueDate, props.validTillDate),
    receiveGoodsStatus:
      props.receiveGoodsStatus ||
      props.receiptStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,
    currency: Utility.getValue(props.currency, props.currencyCode),

    items: props.supplierQuoteItems?.map((item) => {
      return {
        ...item,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    }),
    hasPartialBill:
      props.linkedPurchaseInvoices && props.linkedPurchaseInvoices.length > 0
  };

  return updatedObj;
};

let allData: any[] = [];
export const getPayloadForSupplierQuotes = (props: any) => {
  function amount(item: any) {
    return item.totalAmount;
  }

  function sum(prev: any, next: any) {
    return prev + next;
  }
  function taxAmount(item: any) {
    return item.taxAmount;
  }

  const totalAmount = props.purchaseOrderItems.map(amount).reduce(sum);
  const totalTax = props.purchaseOrderItems.map(taxAmount).reduce(sum);

  let linkedData = [];
  if (props.rfqToSQuote) {
    linkedData = props?.linkedDocuments;
  } else if (!props?.rfqToSQuote && props?.purchaseRequestCode) {
    linkedData.push({
      documentCode: props.purchaseRequestCode,
      documentSequenceCode: props.purchaseRequestSequenceCode,
      documentType: 'PURCHASE_REQUEST'
    });
  }

  let payload = {
    currency: props.currencyCode,
    supplierQuotesDate: props.documentDate,
    supplierQuotesItems: props.purchaseOrderItems,
    shipByDate: props.shipByDate,
    receiveByDate: props.receiveByDate,
    shipFrom: props.shipFrom,
    billFrom: props.shipFrom,
    shipTo: props.shipTo,
    billTo: props.billTo,
    memo: props.memo,
    taxAmount: totalTax,
    totalAmount: totalAmount,
    unitPriceGstInclusive: props.unitPriceGstInclusive,
    vendorCode: props.vendorCode,
    contact: props.contact,
    linkedDocuments: linkedData
  };

  return payload;
};

export const getPayloadForPublicSupplierQuotes = (props: any) => {
  function amount(item: any) {
    return item.totalAmount;
  }

  function sum(prev: any, next: any) {
    return prev + next;
  }
  function taxAmount(item: any) {
    return item.taxAmount;
  }

  const totalAmount = props.items.map(amount).reduce(sum);
  const totalTax = props.items.map(taxAmount).reduce(sum);

  let linkedData = [];

  if (props.purchaseRequestForQuotesCode) {
    linkedData = props?.linkedDocuments;
    linkedData.push({
      documentCode: props.purchaseRequestForQuotesCode,
      documentSequenceCode: 'PRFQI-' + props.purchaseRequestForQuotesCode,
      documentType: 'PURCHASE_REQUEST_FOR_QUOTES'
    });
  }

  let payload = {
    currency: props.currencyCode,
    supplierQuotesDate: props.purchaseRequestForQuotesDate,
    supplierQuotesItems: props.items,
    shipByDate: props.shipByDate,
    receiveByDate: props.receiveByDate,
    shipFrom: props.shipFrom,
    billFrom: props.shipFrom,
    shipTo: props.shipTo,
    billTo: props.billTo,
    memo: props.memo,
    taxAmount: totalTax,
    totalAmount: totalAmount,
    unitPriceGstInclusive: props.unitPriceGstInclusive,
    vendorCode: props.vendorCode,
    contact: props.contact,
    linkedDocuments: linkedData
  };

  return payload;
};
export const putData = (payload: any) => {
  allData.push(payload);
};
export const getData = () => {
  return allData;
};
