import { INPUT_TYPE } from 'deskera-ui-library';
import {
  AMORTIZATION_METHODS,
  AMORTIZATION_SCHEDULE_STATUS,
  AMORTIZATION_TERM_SOURCE,
  AMORTIZATION_TYPES,
  EXPENSE_ALLOCATION_MODE,
  GSTR2B_CATEGORY,
  MODULES_NAME
} from '../Constants/Constant';
import { localizedText } from '../Services/Localization/Localization';
import { getCapitalized } from '../Utility/Utility';

export const CUSTOM_FIELDS_LIST_COLS: any[] = [
  {
    name: 'Field Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'label',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'label',
    id: 'label'
  },
  {
    name: 'Field Type',
    type: INPUT_TYPE.SELECT,
    index: 1,
    options: [
      {
        id: 'TEXT',
        name: 'Text',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'NUMBER',
        name: 'Number',
        color: 'data-grid-badge-color-8'
      },
      {
        id: 'DATE',
        name: 'Date',
        color: 'data-grid-badge-color-9'
      },
      {
        id: 'DROPDOWN',
        name: 'Dropdown',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'USER',
        name: 'User',
        color: 'data-grid-badge-color-7'
      }
    ],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fieldType',
    allowColumnSort: true,
    systemField: true,
    key: 'fieldType',
    id: 'fieldType'
  },
  {
    name: 'Sequence Number',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'code',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'code',
    id: 'code',
    textAlign: 'right'
  },
  {
    name: 'Availability',
    type: INPUT_TYPE.MULTI_SELECT,
    index: 3,
    options: [
      {
        id: MODULES_NAME.CONTACT,
        name: 'Contact',
        color: 'data-grid-badge-color-1'
      },
      {
        id: MODULES_NAME.CONTACT_ADDRESS,
        name: 'Contact Address',
        color: 'data-grid-badge-color-1'
      },
      {
        id: MODULES_NAME.PRODUCT,
        name: 'Product',
        color: 'data-grid-badge-color-2'
      },
      {
        id: MODULES_NAME.ACCOUNT,
        name: 'Account',
        color: 'data-grid-badge-color-3'
      },
      {
        id: MODULES_NAME.JOURNAL,
        name: 'Journal',
        color: 'data-grid-badge-color-4'
      },
      {
        id: MODULES_NAME.EXPENSE,
        name: 'Expense',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.DEPOSIT,
        name: 'Deposit',
        color: 'data-grid-badge-color-6'
      },
      {
        id: MODULES_NAME.DEBITNOTE,
        name: 'Debit Note',
        color: 'data-grid-badge-color-7'
      },
      {
        id: MODULES_NAME.CREDITNOTE,
        name: 'Credit Note',
        color: 'data-grid-badge-color-8'
      },
      {
        id: MODULES_NAME.QUOTATION,
        name: `${getCapitalized(localizedText('quotation'))}`,
        color: 'data-grid-badge-color-9'
      },
      {
        id: MODULES_NAME.SALESORDER,
        name: 'Sales Order',
        color: 'data-grid-badge-color-3'
      },
      {
        id: MODULES_NAME.INVOICE,
        name: 'Invoice',
        color: 'data-grid-badge-color-6'
      },
      {
        id: MODULES_NAME.ORDER,
        name: 'Order',
        color: 'data-grid-badge-color-10'
      },
      {
        id: MODULES_NAME.BILL,
        name: 'Bill',
        color: 'data-grid-badge-color-1'
      },
      {
        id: MODULES_NAME.BOM_ASSEMBLY,
        name: 'BOM Assembly',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.STOCK_ADJUSTMENT,
        name: 'Stock Adjustment',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.STOCK_TRANSFER,
        name: 'Stock Transfer',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.FIXEDASSET,
        name: 'Fixed Asset',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.MRP_OPERATION,
        name: 'Operations',
        color: 'data-grid-badge-color-6'
      },
      {
        id: MODULES_NAME.MRP_OPERATOR,
        name: 'Operators',
        color: 'data-grid-badge-color-7'
      },
      {
        id: MODULES_NAME.MRP_WORK_ORDER,
        name: 'Work Orders',
        color: 'data-grid-badge-color-8'
      },
      {
        id: MODULES_NAME.JOB_WORK_OUT,
        name: 'Job Work Out',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.PICK_PACK_SHIP,
        name: 'PPS',
        color: 'data-grid-badge-color-7'
      },
      {
        id: MODULES_NAME.ESTIMATE,
        name: 'Estimate',
        color: 'data-grid-badge-color-9'
      },
      {
        id: MODULES_NAME.REQUISITION,
        name: 'Requisition',
        color: 'data-grid-badge-color-10'
      },
      {
        id: MODULES_NAME.SECURITY_GATE_ENTRY,
        name: 'Security Gate Entry',
        color: 'data-grid-badge-color-5'
      },
      {
        id: MODULES_NAME.STOCK_ISSUE,
        name: 'Stock Issue',
        color: 'data-grid-badge-color-10'
      },
      {
        id: MODULES_NAME.REQUEST_FOR_QUOTATION,
        name: 'Request For Quotation',
        color: 'data-grid-badge-color-10'
      },
      {
        id: MODULES_NAME.MRP_MACHINE,
        name: 'Machine',
        color: 'data-grid-badge-color-9'
      },
      {
        id: MODULES_NAME.BATCH_SERIAL,
        name: 'Batch/Serial',
        color: 'data-grid-badge-color-7'
      }
    ],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'modules',
    key: 'modules',
    id: 'modules'
  },
  {
    name: 'Visiblity',
    type: INPUT_TYPE.SELECT,
    allowColumnSort: true,
    systemField: true,
    index: 4,
    options: [
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'INACTIVE',
        name: 'In Active',
        color: 'data-grid-badge-color-4'
      }
    ],
    required: false,
    width: 140,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  }
];

export const LOCATION_LIST_COLS: any[] = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    key: 'name',
    id: 'name'
  },
  {
    name: 'Title',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'title',
    key: 'title',
    id: 'title'
  },
  {
    name: 'Address',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'address',
    key: 'address',
    id: 'address'
  },
  {
    name: 'Email',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    width: 210,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'email',
    key: 'email',
    id: 'email'
  },
  {
    name: 'Phone Number',
    type: INPUT_TYPE.TEXT,
    index: 4,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'phone',
    key: 'phone',
    id: 'phone'
  }
];

export const CLIENT_COLS: any[] = [
  {
    name: 'Organisation',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'organizationName',
    key: 'organizationName',
    id: 'organizationName'
  },
  {
    name: 'User',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fullName',
    key: 'fullName',
    id: 'fullName'
  },
  {
    name: 'Subscription Type',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [
      {
        id: 'BOOK_KEEPER_PAYS',
        name: 'BookKeeper Pays',
        color: 'data-grid-badge-color-8'
      },
      {
        id: 'CLIENT_PAYS',
        name: 'Client Pays',
        color: 'data-grid-badge-color-9'
      }
    ],
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'bookkeeperPays',
    key: 'bookkeeperPays',
    id: 'bookkeeperPays'
  },
  {
    name: 'Email',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    width: 280,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'email',
    key: 'email',
    id: 'email'
  },
  {
    name: 'Contact',
    type: INPUT_TYPE.TEXT,
    index: 4,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contact',
    key: 'contact',
    id: 'contact'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 5,
    options: [
      {
        id: 'INVITED',
        name: 'Invited',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-7'
      },
      {
        id: 'INACTIVE',
        name: 'Inactive',
        color: 'data-grid-badge-color-10'
      }
    ],
    required: false,
    width: 110,
    editable: false,
    allowAddOption: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  }
];

export const TEAM_COLS: any[] = [
  {
    name: 'User',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fullName',
    key: 'fullName',
    id: 'fullName'
  },
  {
    name: 'Email',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 280,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'email',
    key: 'email',
    id: 'email'
  },
  {
    name: 'Contact Number',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contact',
    key: 'contact',
    id: 'contact'
  },
  {
    name: 'Type',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'type',
    key: 'type',
    id: 'type'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 5,
    options: [
      {
        id: 'INVITED',
        name: 'Invited',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-7'
      },
      {
        id: 'INACTIVE',
        name: 'Inactive',
        color: 'data-grid-badge-color-4'
      }
    ],
    required: false,
    width: 110,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  }
];

export const SALES_OR_SERVICE_COLS: any[] = [
  {
    name: 'Invoice No',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'billNo',
    key: 'billNo',
    id: 'billNo'
  },
  {
    name: 'Description of Taxable Goods',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'goodsOrServiceDescription',
    key: 'goodsOrServiceDescription',
    id: 'goodsOrServiceDescription'
  },
  {
    name: 'Customer Tariff Code/ Service Code',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'tariffOrServiceCode',
    key: 'tariffOrServiceCode',
    id: 'tariffOrServiceCode',
    textAlign: 'right'
  },
  {
    name: 'Value of Taxable Goods Sold(Including Value of Debit Note)/Value of Work Performed',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'valueOfGoodsSold',
    key: 'valueOfGoodsSold',
    id: 'valueOfGoodsSold',
    textAlign: 'right'
  },
  {
    name: 'Value of Goods Own Used / Dispossed Value of Free Services',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'valueOfGoodsForOwnUse',
    key: 'valueOfGoodsForOwnUse',
    id: 'valueOfGoodsForOwnUse',
    textAlign: 'right'
  },
  {
    name: 'Value of Taxable Service (Including Value of Debit Note)',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'valueOfTaxableService',
    key: 'valueOfTaxableService',
    id: 'valueOfTaxableService',
    textAlign: 'right'
  }
];

export const GSTR2B_DOWNLOAD_COLUMNS: any[] = [
  {
    name: 'Return Period',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'returnPeriod',
    key: 'returnPeriod',
    id: 'returnPeriod',
    systemField: true
  },
  {
    name: 'Download Status',
    type: INPUT_TYPE.SELECT,
    index: 1,
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'downloadStatus',
    key: 'downloadStatus',
    id: 'downloadStatus',
    options: [
      {
        id: 'DOWNLOADED',
        name: 'Downloaded',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'READY_TO_UPLOAD',
        name: 'Ready to Upload',
        color: 'data-grid-badge-color-4'
      }
    ]
  },
  {
    name: 'Last Activity at',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    textAlign: 'left',
    width: 400,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'lastActivity',
    key: 'lastActivity',
    id: 'lastActivity',
    systemField: true
  },
  {
    id: 'action',
    key: 'action',
    name: 'Action',
    type: INPUT_TYPE.BUTTON,
    width: 150,
    options: []
  }
];

export const GSTR_2B_OVERVIEW = [
  {
    name: 'Type',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'type',
    key: 'type',
    id: 'type'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    textAlign: 'left',
    width: 580,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description'
  },
  {
    name: 'No. Of Docs',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    textAlign: 'right',
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'noOfDocs',
    key: 'noOfDocs',
    id: 'noOfDocs'
  }
];

export const REVENUE_CATEGORY_LIST_COLS: any[] = [
  {
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'documentSeqCode',
    id: 'documentSeqCode'
  },
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    allowColumnSort: true,
    systemField: true,
    key: 'name',
    id: 'name'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'description',
    textAlign: 'left'
  },
  {
    name: 'Fair Value Formula',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fairValueFormula',
    allowColumnSort: true,
    systemField: true,
    allowFilter: true,
    key: 'fairValueFormula',
    textAlign: 'left',
    id: 'fairValueFormula'
  }
  //   {
  //     name: 'Fair Value Range Checking Policy',
  //     type: INPUT_TYPE.TEXT,
  //     index: 2,
  //     options: [],
  //     required: false,
  //     width: 170,
  //     editable: false,
  //     hidden: false,
  //     uiVisible: true,
  //     columnCode: 'fairValueRangeCheckingPolicy',
  //     allowColumnSort: true,
  //     systemField: true,
  //     allowFilter: false,
  //     key: 'fairValueRangeCheckingPolicy',
  //     textAlign: 'left'
  //   }
];

export const FAIR_VALUE_COLUMN_CONFIG: any[] = [
  {
    name: 'ID',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'documentSeqCode'
  },
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    allowColumnSort: true,
    systemField: true,
    key: 'name',
    id: 'name'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'description',
    textAlign: 'left'
  },
  {
    name: 'Fair Value Formula',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fairValueFormula',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'fairValueFormula',
    textAlign: 'left'
  },

  {
    name: 'Status',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'INACTIVE',
        name: 'Inactive',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-6'
      }
    ],
    required: true,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'status'
  }
];

export const REV_REC_COLUMN_CONFIG: any[] = [
  {
    name: 'ID',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'documentSeqCode'
  },
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    allowColumnSort: true,
    allowFilter: false,
    systemField: true,
    key: 'name'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'description',
    textAlign: 'left'
  },
  {
    name: 'Recognition Method',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'recognitionMethod',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'recognitionMethod',
    textAlign: 'left'
  },
  {
    name: 'Initial Amount (%)',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'initialAmount',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'initialAmount',
    textAlign: 'left'
  },
  {
    name: 'Term in Months',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'termMonths',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'termMonths',
    textAlign: 'left'
  },
  {
    name: 'Amount Source',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'amountSource',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'amountSource',
    textAlign: 'left'
  },
  {
    name: 'Start Date Source',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revRecStartDateSource',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revRecStartDateSource',
    textAlign: 'left'
  },
  {
    name: 'End Date Source',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revRecEndDateSource',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revRecEndDateSource',
    textAlign: 'left'
  },
  {
    name: 'Re-Forecast Method',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'reForecastMethod',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'reForecastMethod',
    textAlign: 'left'
  }
];

export const REVENUE_ARRANGEMENT_COL_CONFIG: any[] = [
  {
    name: 'Code',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'code',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'code'
  },
  {
    name: 'Document Date',
    type: INPUT_TYPE.DATE,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentDate',
    allowColumnSort: true,
    systemField: true,
    key: 'documentDate',
    id: 'documentDate'
  },
  {
    name: 'Linked Document Type',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'linkedDocType',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'linkedDocType',
    textAlign: 'left'
  },
  {
    name: 'Linked Document Code',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'linkedDocSeqCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'linkedDocSeqCode',
    textAlign: 'left'
  },
  {
    name: 'Customer Name',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactName',
    allowColumnSort: false,
    systemField: true,
    allowFilter: false,
    key: 'contactName',
    textAlign: 'left'
  },
  {
    name: 'Customer Code',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'contactCode',
    textAlign: 'left'
  },
  {
    name: 'Status',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'NOT_STARTED',
        name: 'Not Started',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'IN_PROGRESS',
        name: 'In Progress',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'ON_HOLD',
        name: 'On Hold',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'COMPLETE',
        name: 'Complete',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'PLAN_FAILED',
        name: 'Plan Failed',
        color: 'data-grid-badge-color-6'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'status'
  },
  {
    name: 'Total Document value',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalDocValueInBase',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'totalDocValueInBase',
    textAlign: 'right'
  },
  {
    name: 'Total Revenue',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalRevValueInBase',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'totalRevValueInBase',
    textAlign: 'right'
  }
];
export const REVENUE_PLANS_COL_CONFIG: any[] = [
  {
    name: 'Customer',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactName',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'contactName'
  },
  {
    name: 'Source',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'source',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'source'
  },
  {
    name: 'Revenue Plan Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revPlanNo',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revPlanNo'
  },
  {
    name: 'Revenue Plan Type',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'type',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'type'
  },
  {
    name: 'Revenue Recognition Rule',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revRecRuleName',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revRecRuleName'
  },
  {
    name: 'Revenue Recognition Start Date',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revRecStartDate',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revRecStartDate'
  },
  {
    name: 'Revenue Recognition End Date',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'revRecEndDate',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'revRecEndDate'
  },
  {
    name: 'Item',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'item',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'item'
  },
  {
    name: 'Plan Total',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'amount',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    textAlign: 'right',
    key: 'amount'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'status'
  },
  {
    name: 'Amount (Recognized)',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'recognizedAmount',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    textAlign: 'right',
    key: 'recognizedAmount'
  },
  {
    name: 'Remaining',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'remainingAmount',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    textAlign: 'right',
    key: 'remainingAmount'
  },
  {
    name: 'Amount (Amortized)',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'amortizedAmount',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    textAlign: 'right',
    key: 'amortizedAmount'
  },
  {
    name: 'Cost Remaining',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'costRemaining',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    textAlign: 'right',
    key: 'costRemaining'
  }
];

export const AMORTIZATION_TEMPLATES_COLS: any[] = [
  {
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentSeqCode',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'documentSeqCode'
  },
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'name'
  },
  {
    name: 'Term Source',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: Object.entries(AMORTIZATION_TERM_SOURCE).map(([k, v]) => ({
      id: k,
      name: v
    })),
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'termSource',
    allowColumnSort: true,
    systemField: false,
    key: 'termSource',
    id: 'termSource',
    textAlign: 'left'
  },
  {
    name: 'Method',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: Object.entries(AMORTIZATION_METHODS).map(([k, v]) => ({
      id: k,
      name: v
    })),
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'amortizationMethod',
    allowColumnSort: true,
    systemField: false,
    key: 'amortizationMethod',
    id: 'amortizationMethod',
    textAlign: 'left'
  },
  {
    name: 'Type',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: Object.entries(AMORTIZATION_TYPES).map(([k, v]) => ({
      id: k,
      name: v
    })),
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'type',
    allowColumnSort: true,
    systemField: false,
    id: 'type',
    key: 'type',
    textAlign: 'left'
  }
];

export const AMORTIZATION_SCHEDULES_COLS: any[] = [
  {
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'code',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'code'
  },
  {
    name: 'Type',
    type: INPUT_TYPE.SELECT,
    index: 1,
    options: Object.entries(AMORTIZATION_TYPES).map(([k, v]) => ({
      id: k,
      name: v
    })),
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'type',
    allowColumnSort: true,
    systemField: false,
    key: 'type',
    id: 'type'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 1,
    options: Object.entries(AMORTIZATION_SCHEDULE_STATUS).map(([k, v]) => ({
      id: k,
      name: v
    })),
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    allowColumnSort: true,
    systemField: false,
    key: 'status',
    id: 'status'
  },
  {
    name: 'Purchase Invoice Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'purchaseInvoiceCode',
    allowColumnSort: true,
    systemField: false,
    key: 'purchaseInvoiceCode',
    id: 'documentSeqCode'
  },
  {
    name: 'Template Name',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'templateName',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'templateName',
    id: 'templateName'
  },
  {
    name: 'Amount',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalAmount',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'totalAmount'
  },
  {
    name: 'Remaining Deferred Balance',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'remainingDeferredBalance',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'remainingDeferredBalance'
  },
  {
    name: 'Total Amortized',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalAmortize',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'totalAmortize'
  },
  {
    name: 'Supplier invoice number',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'supplierInvoiceNo',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'supplierInvoiceNo'
  },
  {
    name: 'Transaction Date',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'transactionDate',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'transactionDate'
  },
  {
    name: 'Vendor name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactName',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'contactName'
  },
  {
    name: 'Start Date',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'startDate',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'startDate'
  },
  {
    name: 'End Date',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'endDate',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'endDate'
  }
];

export const EXPENSE_ALLOCATION_TEMPLATES_COLS: any[] = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    allowColumnSort: true,
    systemField: false,
    allowFilter: false,
    key: 'name'
  },
  {
    name: 'Allocation Mode',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'allocationMode',
    allowColumnSort: true,
    systemField: false,
    key: 'allocationMode',
    id: 'allocationMode',
    textAlign: 'left'
  },
  {
    name: 'Status',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'INACTIVE',
        name: 'Inactive',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'ACTIVE',
        name: 'Active',
        color: 'data-grid-badge-color-6'
      }
    ],
    required: true,
    width: 100,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    key: 'status',
    id: 'status',
    allowAddOption: false,
    allowColumnSort: false
  },
  {
    name: 'Remind Forever',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'remindForever',
    allowColumnSort: true,
    systemField: false,
    key: 'remindForever',
    id: 'remindForever',
    textAlign: 'left'
  },
  {
    name: 'Number Of Remaining',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'numberRemaining',
    allowColumnSort: true,
    systemField: false,
    key: 'numberRemaining',
    id: 'numberRemaining',
    textAlign: 'right'
  }
];

export const UPDATE_REVENUE_ARRANGEMENT_COL_CONFIG: any[] = [
  {
    name: 'Submission Id ',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentCode',
    key: 'documentCode',
    id: 'documentCode',
    allowColumnSort: false
  },

  {
    name: 'Process Type',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'processType',
    key: 'processType',
    id: 'processType',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Status Complete',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'OPEN',
        name: 'Open ',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'RUNNING',
        name: 'Running',
        color: ' data-grid-badge-color-5'
      },
      {
        id: 'ON_HOLD',
        name: 'On Hold',
        color: ' data-grid-badge-color-2 '
      },
      {
        id: 'COMPLETED',
        name: 'Completed',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'EXPIRED',
        name: 'Expired',
        color: 'data-grid-badge-color-5'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'status'
  },
  {
    name: 'Percent Complete',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'percentCompleteColumn',
    key: 'percentCompleteColumn',
    id: 'percentCompleteColumn',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Date Created',
    type: 'date',
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'dateCreated',
    key: 'dateCreated',
    id: 'dateCreated',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Created By',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'createdBy',
    key: 'createdBy',
    id: 'createdBy',
    textAlign: 'right',
    allowColumnSort: false
  }
];

export const PROCEED_REVENUE_ARRANGEMENT_COL_CONFIG: any[] = [
  {
    name: 'ID',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 190,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'code',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'code'
  },
  {
    name: 'Document Date',
    type: INPUT_TYPE.DATE,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'documentDate',
    allowColumnSort: true,
    systemField: true,
    key: 'documentDate',
    id: 'documentDate'
  },
  {
    name: 'Linked Document Type',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'linkedDocType',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'linkedDocType',
    textAlign: 'left'
  },
  {
    name: 'Linked Document Code',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'linkedDocSeqCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'linkedDocSeqCode',
    textAlign: 'left'
  },
  {
    name: 'Customer Name',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactName',
    allowColumnSort: false,
    systemField: true,
    allowFilter: false,
    key: 'contactName',
    textAlign: 'left'
  },
  {
    name: 'Customer Code',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactCode',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'contactCode',
    textAlign: 'left'
  },
  {
    name: 'Job Status',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'NOT_STARTED',
        name: 'Not Started',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'IN_PROGRESS',
        name: 'In Progress',
        color: 'data-grid-badge-color-2'
      },
      {
        id: 'ON_HOLD',
        name: 'On Hold',
        color: 'data-grid-badge-color-4'
      },
      {
        id: 'COMPLETED',
        name: 'Completed',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'FAILED',
        name: 'WFailed',
        color: 'data-grid-badge-color-5'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'jobStatus',
    id: 'jobStatus',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'jobStatus'
  },
  {
    name: 'Total Document value',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalDocValueInBase',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'totalDocValueInBase',
    textAlign: 'right'
  },
  {
    name: 'Total Revenue',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalRevValueInBase',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'totalRevValueInBase',
    textAlign: 'right'
  },
  {
    name: 'Message',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'errors',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'errors',
    textAlign: 'left'
  }
];

export const RECLASSIFICATION_JE_COL_CONFIG: any[] = [
  {
    name: 'Submission Id ',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSeqCode',
    key: 'documentSeqCode',
    id: 'documentSeqCode',
    allowColumnSort: true
  },

  {
    name: 'Process Type',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'processType',
    key: 'processType',
    id: 'processType',
    textAlign: 'right',
    allowColumnSort: false
  },

  {
    name: 'Percent Complete',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'percentCompleteColumn',
    key: 'percentCompleteColumn',
    id: 'percentCompleteColumn',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Date Created',
    type: 'date',
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'createdDate',
    key: 'createdDate',
    id: 'createdDate',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Created By',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'createdBy',
    key: 'createdBy',
    id: 'createdBy',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Status Complete',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'OPEN',
        name: 'Open ',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'RUNNING',
        name: 'Running',
        color: ' data-grid-badge-color-5'
      },
      {
        id: 'ON_HOLD',
        name: 'On Hold',
        color: ' data-grid-badge-color-2 '
      },
      {
        id: 'COMPLETE',
        name: 'Complete',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'EXPIRED',
        name: 'Expired',
        color: 'data-grid-badge-color-5'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'status'
  }
];

export const PROCESSED_RECLASSIFICATION_JE: any[] = [
  {
    name: 'JE Type ',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'jeType',
    key: 'jeType',
    id: 'jeType',
    allowColumnSort: false
  },

  {
    name: 'Journal Entry',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'jeCode',
    key: 'jeCode',
    id: 'jeCode',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Rev Arrangement Code',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'revenueArrangementCode',
    key: 'revenueArrangementCode',
    id: 'revenueArrangementCode',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Document Code',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentCode',
    key: 'documentCode',
    id: 'documentCode',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Document Type',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentType',
    key: 'documentType',
    id: 'documentType',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Posting period',
    type: 'text',
    index: 3,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'postingPeriod',
    key: 'postingPeriod',
    id: 'postingPeriod',
    textAlign: 'right',
    allowColumnSort: false
  },
  {
    name: 'Status Complete',
    type: 'select',
    index: 5,
    options: [
      {
        id: 'OPEN',
        name: 'Open ',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'RUNNING',
        name: 'Running',
        color: ' data-grid-badge-color-5'
      },
      {
        id: 'ON_HOLD',
        name: 'On Hold',
        color: ' data-grid-badge-color-2 '
      },
      {
        id: 'COMPLETE',
        name: 'Complete',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'EXPIRED',
        name: 'Expired',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'FAILED',
        name: 'Failed',
        color: 'data-grid-badge-color-10'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'status',
    id: 'status',
    datasource: [],
    allowAddOption: false,
    allowColumnSort: false,
    key: 'status'
  },
  {
    name: 'Message',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'errors',
    allowColumnSort: true,
    systemField: true,
    allowFilter: false,
    key: 'errors',
    textAlign: 'left'
  }
];

export const USA_SALES_TAX_DETAILS_COL = [
  {
    name: 'State/City Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'jurisName',
    key: 'jurisName',
    id: 'jurisName'
  },
  {
    name: 'State/City Code',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'jurisCode',
    key: 'jurisCode',
    id: 'jurisCode'
  },
  {
    name: 'Invoice Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    id: 'documentSequenceCode'
  },
  {
    name: 'Contact',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'contact',
    key: 'contact',
    id: 'contact'
  },
  {
    name: 'Tax Type',
    type: INPUT_TYPE.SELECT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'taxType',
    key: 'taxType',
    id: 'taxType'
  },
  {
    name: 'Reason for Exempt',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'exemptReason',
    key: 'exemptReason',
    id: 'exemptReason'
  },
  {
    name: 'Invoice Date',
    type: INPUT_TYPE.DATE,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'documentDate',
    key: 'documentDate',
    id: 'documentDate'
  },
  {
    name: 'Due Date',
    type: INPUT_TYPE.DATE,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'dueDate',
    key: 'dueDate',
    id: 'dueDate'
  },
  {
    name: 'Taxable Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'taxable',
    key: 'taxable',
    id: 'taxable'
  },
  {
    name: 'Taxable Amount In Base',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'taxableInBase',
    key: 'taxableInBase',
    id: 'taxableInBase'
  },
  {
    name: 'Exempt Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'totalExempt',
    key: 'totalExempt',
    id: 'totalExempt'
  },
  {
    name: 'Exempt Amount In Base',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'totalExemptInBase',
    key: 'totalExemptInBase',
    id: 'totalExemptInBase'
  },
  {
    name: 'Tax Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'taxAmount',
    key: 'taxAmount',
    id: 'taxAmount'
  },
  {
    name: 'Tax Amount In Base',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'taxAmountInBase',
    key: 'taxAmountInBase',
    id: 'taxAmountInBase'
  },
  {
    name: 'Total Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'totalAmount',
    key: 'totalAmount',
    id: 'totalAmount'
  },
  {
    name: 'Total Amount In Base',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'totalAmountInBase',
    key: 'totalAmountInBase',
    id: 'totalAmountInBase'
  },
  {
    name: 'Due Amount',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'dueAmount',
    key: 'dueAmount',
    id: 'dueAmount'
  },
  {
    name: 'Due Amount In Base',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    textAlign: 'right',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'dueAmountInBase',
    key: 'dueAmountInBase',
    id: 'dueAmountInBase'
  },
  {
    name: 'Fulfillment',
    type: INPUT_TYPE.SELECT,
    index: 0,
    options: [
      {
        id: 'FULLY_FULFILLED',
        name: 'Delivered',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'PARTIAL_FULFILLED',
        name: 'Partially Delivered',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'UNFULFILLED',
        name: 'Not Delivered',
        color: 'data-grid-badge-color-10'
      },
      {
        id: 'PENDING_FOR_APPROVAL',
        name: 'Pending Approval',
        color: 'data-grid-badge-color-8'
      }
    ],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'fulfillmentStatus',
    key: 'fulfillmentStatus',
    id: 'fulfillmentStatus'
  },
  {
    name: 'Payment',
    type: INPUT_TYPE.SELECT,
    index: 0,
    options: [
      {
        id: 'RECEIVED',
        name: 'Received',
        color: 'data-grid-badge-color-6'
      },
      {
        id: 'PARTIAL',
        name: 'Partial',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'PENDING',
        name: 'Pending',
        color: 'data-grid-badge-color-10'
      }
    ],
    required: false,
    textAlign: 'left',
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'paymentStatus',
    key: 'paymentStatus',
    id: 'paymentStatus'
  },
  {
    name: 'Customer Order Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'customerOrderNumber',
    key: 'customerOrderNumber',
    id: 'customerOrderNumber'
  },
  {
    name: 'Bill To',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 220,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'billTo',
    key: 'billTo',
    id: 'billTo'
  },
  {
    name: 'Ship To',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 220,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'shipTo',
    key: 'shipTo',
    id: 'shipTo'
  },
  {
    name: 'Created By',
    type: INPUT_TYPE.SELECT,
    index: 0,
    options: [],
    required: false,
    textAlign: 'left',
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowFilter: false,
    columnCode: 'createdBy',
    key: 'createdBy',
    id: 'createdBy'
  }
];
