
import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';

export interface GenerateBankFileBillAPIConfig {
  Limit?: number;
  Page?: number;
  Sort?: string;
  SortDir?: string;
  Query?: any;
  QueryParam?: any;
  SearchTerm?: string;
}

const defaultConfig: GenerateBankFileBillAPIConfig = {
  Page: 0,
  Limit: 25,
  Sort: 'purchaseInvoiceDate',
  SortDir: 'DESC'
};

class GenerateBankFileBillService {
  static apiConfig: GenerateBankFileBillAPIConfig;

  static getBankFileBills() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const params: any = {
      limit: this.apiConfig.Limit,
      page: this.apiConfig.Page,
      sort: this.apiConfig.Sort,
      sortDir: this.apiConfig.SortDir,
      query: this.apiConfig.Query
    };

    if (this.apiConfig.SearchTerm) {
      params.search = this.apiConfig.SearchTerm;
    }

    return http.get(ApiConstants.URL.INVOICES.GET_BANK_FILE_BILLS, { params: params }).catch((err: any) => {
      console.error('Error loading bank file bills: ', err);
      return Promise.reject(err);
    });
  }

  static generateBankFile(body: any) {
    return http.post(ApiConstants.URL.INVOICES.GENERATE_BANK_FILE, body);
  }
}

export default GenerateBankFileBillService;
