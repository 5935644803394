import React, { Component } from "react";
import "../../index.css";
import "../../CommonStyles/FontStyle.css";
import { EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from '../../Manager/DocumentManager';
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getCapitalized, getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry, getPageHeight, getVW } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
export default class DocumentTemplate19 extends Component {
    arabicHeaderWidth = '55%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30)  }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {this.getHeaderSection()}
                    {ComponentManager.addVerticalSpace(60)}
                    {this.getDocumentDetailsListSection()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(30)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'top'} />
                    {ComponentManager.addVerticalSpace(30)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(30)}
                    {this.getTableSection()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(30)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} customFieldPosition={'bottom'} />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                    {this.getFooterSection()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    /////// Header Section ///////////
    getHeaderSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'space-between', width: '100%', alignItems: 'flex-start' }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), {position: "relative"})}
                <div className="ColumnDiv" style={{ width: '50%', marginLeft: getVW(10)}}>
                    <div className='RowDiv'
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                            width: Utility.getIsArabicLang() ? '75%' : '100%',
                        }}>
                        {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                paddingBottom: getVW(5),
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                            }}>
                                {getLocalisedText(Utility.getCompanyName(this.state.data))}
                        </text>}
                    </div>
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '75%',
                            overflow: 'hidden',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getCompanyAddress(this.state.data))}}>
                    </div>}
                </div>
                <div className="ColumnDiv" style={{ width: '50%' }}>
                    {this.state.data.showClientBillToAddress && this.getBillingAddress()}
                </div>
            </div>
        )
    }

    getBillingAddress() {
        return <>
            <text className={"docFontStyleBold RowReverseDiv" + getFontSizeClass()}
                style={{
                    paddingBottom: getVW(5),
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }}>
                    {getLocalisedText(this.state.data.billToTitle).toUpperCase()}
            </text>
            <div className={"docFontStyle" + getFontSizeClass()}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    textAlign: 'right',
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.billTo) }, this.state.data.printScale)
                }} dangerouslySetInnerHTML={{__html:getLocalisedText(Utility.getClientBillToAddress(this.state.data))}}>
            </div>
        </>;
    }

    /////// Document Details Section ///////////
    getDocumentDetailsListSection() {
        return (
            <div className="RowDiv" style={{ justifyContent: 'space-between', width: '100%' }}>
                <div className="ColumnDiv" style={{ width: '100%' }}>
                    <div className='RowDiv'
                        style={{
                            flexDirection: Utility.getIsArabicLang() ? 'row-reverse' : 'row',
                            width: Utility.getIsArabicLang() ? this.arabicHeaderWidth: '100%'
                        }}>
                        <text className={"docFontStyleBold" + getFontSizeClass()}
                            style={{
                                color: this.state.data.themeColor,
                                ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xxLarge }, this.state.data.printScale)
                            }}>
                            {getCapitalized(getLocalisedText(this.state.data.documentType))}
                        </text>
                    </div>
                    <div style={{ width: '55%' }}>
                        {this.getDocumentDetailsSection()}
                    </div>

                </div>
                {
                    Utility.getIsContainsTotalSection(this.state.data) &&
                    <div className="RowReverseDiv" style={{ width: '100%', }}>
                        <div style={{ backgroundColor: this.state.data.themeColor, width: '60%', paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)), paddingBottom: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)), paddingRight: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)), borderRadius: getVW(Utility.convertToPrintScale(3, this.state.data.printScale)) }}>
                            {ComponentManager.getTitleAndValueColumn(
                                getLocalisedText('total') + " " + getLocalisedText('amount'),
                                Utility.getTotalValue(this.state.data),
                                'white',
                                'white',
                                "flex-end",
                                FONT_SIZE.regular,
                                FONT_SIZE.xLarge,
                                "bold",
                                0,
                                Utility.getIsSaudiArabiaCountry(this.state.data) ? 'rtl' : 'ltr',
                                this.state.data.printScale
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            var color = undefined

            if(index === documentDetailList.length - 1) {
                color = this.state.data.themeColor
            }
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getRowValueSection(
                element.label,
                value,
                color,
                color,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    /////// Table Section ///////////
    getTableSection() {
        return (<div className="RowDiv">
            {this.getEditableDocumentTable()}
        </div>)
    }

    /////// Footer Section ///////////
    getFooterSection() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} className="RowDiv" style={{ bottom: 0, width: '100%', alignItems: 'flex-start' }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    this.state.data.termsAndCondition,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(20)}
                {(this.state.data.showNotes && this.state.data.notes) && ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.state.data.notes,
                    "100%",
                    undefined,
                    undefined,
                    undefined,
                    getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div>
        )
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        style.headerBackgroundColor = "transparent";
        style.headerBorderColor = "transparent";
        style.itemBorderColor = "transparent";
        style.footerBackgroundColor = "transparent";
        style.footerBorderColor = "transparent";
        return style;
    }
    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;

    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '35%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}                        
                </div>
            </div>
        );
    }
}
