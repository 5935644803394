import ApiConstants from '../Constants/ApiConstants';
import http from './Interceptor';

export interface FulfillmentApprovalAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
  StartDate?: string;
  EndDate?: string;
  format?: 'XLS' | '';
  SalesPerson?: number;
}

export const defaultConfig: FulfillmentApprovalAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '&sort=code&sortDir=DESC',
  QuerySummary: '',
  StartDate: '',
  EndDate: '',
  format: '',
  SalesPerson: 0
};

class FulfillmentApprovalService {
  static apiConfig: FulfillmentApprovalAPIConfig = defaultConfig
  private static async fetchGoodsReceivedNotesReport(endpoint: string, config: FulfillmentApprovalAPIConfig, options: any = {}) {
    const searchParams = new URLSearchParams({
      limit: config.Limit?.toString() ?? "25",
      page: config.Page?.toString() ?? "0",
      search: config.SearchTerm ?? "",
      query: config.Query ?? "",
      fetchAttachmentDetails:"true"
    });
    if (config.format) {
      searchParams.append("format", config.format)
    }
    let salesPersonQuery = config.SalesPerson
      ? '&salesperson=' + config.SalesPerson
      : '';

    const finalEndpoint: string =
      endpoint +
      '?' +
      searchParams.toString() +
      config.QueryParam.toString() +
      salesPersonQuery;

    return http
      .get(`${finalEndpoint}`, options)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getFulfillmentApprovalData() {
    const url = ApiConstants.URL.FULFILLMENT.GET_ALL_PENDING_FULFILLMENTS;
    return this.fetchGoodsReceivedNotesReport(url, this.apiConfig, {}).then((response: any) => {
      return Promise.resolve(response);
    }).catch((error: any) => {
      return Promise.reject(error);
    });;
  }

  static approveRejectGoodsReceipt(data:any) {
    const finalEndpoint: string =
      ApiConstants.URL.GOODS_RECEIPT.APPROVE_REJECT;
    try {
      return http.put(`${finalEndpoint}`, data).then((response:any) => {
        return Promise.resolve(response);
      });
    } catch (err) {
      console.error('Error approving/rejecting goods receipt: ', err);
      return Promise.reject(err);
    }
  }

  static approveRejectFulfillment(data:any) {
    const finalEndpoint: string =
      ApiConstants.URL.FULFILLMENT.APPROVE_REJECT;
    try {
      return http.put(`${finalEndpoint}`, data).then((response:any) => {
        return Promise.resolve(response);
      });
    } catch (err) {
      console.error('Error approving/rejecting fulfillment: ', err);
      return Promise.reject(err);
    }
  }
}

export default FulfillmentApprovalService;