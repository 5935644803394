import React, { useEffect, useState } from 'react';
import AIAnimation from './AIAnimation';
import AIPopup from './AIPopup';
import AINotification from './AINotification';

export default function AIButton(props) {
  const [showAIPopup, setShowAIPopup] = useState(false);
  const [showAINotification, setShowAINotification] = useState(false);

  const onMouseUp = (e) => {
    const isClickedInsidePopoutContainer = e.target.closest?.(
      '.ai-popout-container'
    );

    if (!isClickedInsidePopoutContainer) {
      setShowAIPopup(false);
      setShowAINotification(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);

    return () => document.removeEventListener('mouseup', onMouseUp);
  }, []);

  useEffect(() => {
    props.notification &&
      setTimeout(() => {
        setShowAIPopup(false);
        setShowAINotification(true);
      }, 30000);
  }, [props.notification]);

  return (
    <div className={'ai-popout-container position-relative ' + props.className}>
      <div
        className="cursor-pointer row text-wrap-none dk-button-hover"
        onClick={() => {
          setShowAIPopup(true);
          setShowAINotification(false);
        }}
      >
        <AIAnimation
          height={props.buttonHeight || 32}
          width={props.buttonWidth || 32}
        />
      </div>
      {showAIPopup && (
        <AIPopup
          className="position-absolute z-index-3"
          style={{
            top: props.alignLeft ? '100%' : 0,
            [props.alignLeft ? 'right' : 'left']: 0,
            width: 250,
            transformOrigin: props.alignLeft ? 'right top' : 'left top'
          }}
          placeholder={props.placeholder}
          onSendRequest={(command) => {
            setShowAIPopup(false);
            props.onTrigger?.(command);
          }}
        />
      )}
      {showAINotification && (
        <AINotification
          className="position-absolute z-index-3"
          style={{
            top: props.alignLeft ? '100%' : 0,
            [props.alignLeft ? 'right' : 'left']: 0,
            width: 250
          }}
          onClose={() => setShowAINotification(false)}
        />
      )}
    </div>
  );
}
