import React, { useEffect, useState } from 'react';
import { DKLabel } from 'deskera-ui-library';
import { CustomFieldsHolder } from '../../../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { DOCUMENT_MODE, MODULES_NAME } from '../../../../../Constants/Constant';
import { useAppSelector } from '../../../../../Redux/Hooks';
import {
  selectWorkOutsColumnConfig,
  selectWorkOutsColumnConfigTableId
} from '../../../../../Redux/Slices/WorkOutSlice';
import Utility from '../../../../../Utility/Utility';

interface IWOCustomFieldProps {
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  customFields: any[];
  onCFUpdates: (cfList: any[]) => void;
  currentActiveTabIndex: number;
}

const WOCustomField: React.FC<IWOCustomFieldProps> = (props) => {
  const columnConfig = useAppSelector(selectWorkOutsColumnConfig);
  const columnConfigTableId = useAppSelector(selectWorkOutsColumnConfigTableId);

  const [localCFList, setLocalCFList] = useState(props.customFields ?? []);

  useEffect(() => {
    setLocalCFList(props?.customFields);
  }, [props?.customFields]);

  return (
    <div
      className={`column parent-width border-m border-radius-m p-l bg-white`}
      style={{ minHeight: 80, width: props?.isEditMode ? '49.5%' : '100%' }}
    >
      <DKLabel text="Custom Fields" className="fw-m text-app-color" />
      {/* {Utility.isEmpty(props.customFields) ? (
        <DKLabel
          text={`No custom fields found for this work order`}
          className={`mt-r text-gray mx-auto`}
        />
      ) : null} */}
      <CustomFieldsHolder
        moduleName={MODULES_NAME.MRP_WORK_ORDER}
        documentMode={
          props.isReadOnlyMode
            ? DOCUMENT_MODE.VIEW
            : props.isEditMode
            ? DOCUMENT_MODE.EDIT
            : DOCUMENT_MODE.NEW
        }
        columnConfig={columnConfig}
        columnConfigTableId={columnConfigTableId ?? ''}
        onUpdate={props.onCFUpdates}
        customFieldsList={localCFList}
        valueToTriggerLocalChange={props?.currentActiveTabIndex}
      />
    </div>
  );
};

export default WOCustomField;
