import ApiConstants from '../Constants/ApiConstants';
import { BOOKS_DATE_FORMAT } from '../Constants/Constant';
import { AccountingPayload } from '../Models/AccountingForm';
import Utility from '../Utility/Utility';
import DateFormatService from './DateFormat';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface ExpenseAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  DocType?: any;
  QueryParam?: any;
  Sort?: any;
  SortDir?: any;
  SalesPerson?: number;
}

const defaultConfig: ExpenseAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  DocType: '',
  QueryParam: '',
  SalesPerson: 0
};
class ExpenseService {
  static apiConfig: ExpenseAPIConfig = defaultConfig;
  static abortController: any = null;

  static getExpenseByPage() {
    Utility.cancelRequest(ExpenseService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let queryString: string = `?query=${this.apiConfig.Query}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}${
      this.apiConfig.DocType ? `&docType=${this.apiConfig.DocType}` : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    if (this.apiConfig.Sort) {
      queryString += `&sort=${this.apiConfig.Sort}`;
    }
    if (this.apiConfig.SortDir) {
      queryString += `&sortDir=${this.apiConfig.SortDir}`;
    }
    if (this.apiConfig.SalesPerson) {
      queryString = queryString + '&salesperson=' + this.apiConfig.SalesPerson;
    }
    const finalEndpoint: string =
      ApiConstants.URL.EXPENSE.FETCH_EXPENSE + queryString;

    ExpenseService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: ExpenseService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getExpenseByCode(code: any) {
    let url = `${ApiConstants.URL.BASE}payments/make/expenses?query=&search=${code}&limit=25&page=0`;
    return http
      .get(url)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static updateExpense(payload: any) {
    return http
      .post(`${ApiConstants.URL.EXPENSE.UPDATE_EXPENSE}`, payload)
      .catch((err: any) => {
        console.error('Error updating expense: ', err);
        return Promise.reject(err);
      });
  }

  static createDirectExpense(payload: AccountingPayload) {
    return http
      .post(`${ApiConstants.URL.EXPENSE.UPDATE_EXPENSE}`, payload)
      .catch((err: any) => {
        console.error('Error updating expense: ', err);
        return Promise.reject(err);
      });
  }

  static deleteExpense(expenseCode: string) {
    return http
      .delete(`${ApiConstants.URL.EXPENSE.NEW_DELETE_EXPENSE(expenseCode)}`)
      .catch((err: any) => {
        console.error('Error updating expense: ', err);
        return Promise.reject(err);
      });
  }

  static deleteMakePayment(paymentCode: string, documentCode: string) {
    return http
      .delete(
        `${ApiConstants.URL.PAYMENT.NEW_DELETE_BILL_PAYMENT_RECORD(
          paymentCode,
          documentCode
        )}`
      )
      .catch((err: any) => {
        console.error('Error deleting make payment: ', err);
        return Promise.reject(err);
      });
  }

  static exportExpense(query: string) {
    const finalEndpoint: string =
      ApiConstants.URL.EXPENSE.EXPORT_EXPENSE + (query ? query : '');
    return http
      .get(`${finalEndpoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static setPrintCheckStatus(payload: any) {
    return http2
      .post(`${ApiConstants.URL.PRINT_CHECK.SET_PRINT_STATUS}`, payload)
      .catch((err: any) => {
        console.error('Error updating print status: ', err);
        return Promise.reject(err);
      });
  }
  static genrateCheckNo(payload: any) {
    return http2
      .post(`${ApiConstants.URL.PRINT_CHECK.GENERATE_CHECK_NUMBER}`, payload)
      .catch((err: any) => {
        console.error('Error genrating check no: ', err);
        return Promise.reject(err);
      });
  }
  static getPrintCheckList(filter: any) {
    let query = `?query=paymentType=CHEQUE,documentDate>=${DateFormatService.getDateStrFromDate(
      filter.startDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    )},documentDate<=${DateFormatService.getDateStrFromDate(
      filter.endDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    )}`;
    if (!Utility.isEmpty(filter.docType)) {
      let type = filter.docType === 'All' ? '' : filter.docType;
      query += `,makePaymentItemList.documentType=${filter.docType}`;
    }
    if (!Utility.isEmpty(filter.contact)) {
      query += `,contactCode=${filter.contact}`;
    }
    if (!Utility.isEmpty(filter.startCheckNo)) {
      query += `,referenceNumber~${filter.startCheckNo}`;
    }
    if (!Utility.isEmpty(filter.selectedAccount)) {
      query += `,accountCodePayFrom=${filter.selectedAccount}`;
    }
    if (!Utility.isEmpty(filter.status)) {
      query += `,printStatus=${filter.status}`;
    }

    query += `&limit=50&page=${this.apiConfig.Page}&isBulkPayment=true`;

    if (!Utility.isEmpty(filter.sort) && !Utility.isEmpty(filter.sortDir)) {
      query += `&sort=${filter.sort}&sortDir=${filter.sortDir}`;
    }

    const finalEndpoint: string =
      ApiConstants.URL.PRINT_CHECK.GET_PRINT_CHECK + (query ? query : '');
    return http2
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default ExpenseService;
