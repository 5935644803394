import { useState } from 'react';
import { DKLabel, DKButton, DKIcon, DKIcons } from 'deskera-ui-library';
import { DOCUMENT_MODE } from '../../Constants/Constant';
import RouteManager from '../../Managers/RouteManager';
import { useAppDispatch } from '../../Redux/Hooks';
import { removeDraft } from '../../Redux/Slices/DraftsSlice';
import DocumentActionMenu from './DocumentActionMenu';
import { DynamicPopupWrapper } from '../PopupWrapper';

interface DocumentAlertProps {
  showAlert: boolean;
  title: string;
  message: string;
  booksDocument: any;
  draftData: any;
  documentMode: DOCUMENT_MODE;
  pageRoute: string;
  onClose?: () => void;
}

export default function DocumentAlert(props: DocumentAlertProps) {
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(props.showAlert);

  const getHeader = () => {
    return (
      <div className="row position-relative pop-header-drag-handle">
        <DKLabel text={props.title} className="alert-title text-align-center" />
        <DKIcon
          src={DKIcons.ic_close}
          className="ic-s opacity-60 cursor-pointer position-absolute right-0 top-0"
          onClick={() => {
            setShowAlert(false);
            dispatch(removeDraft(props.draftData.id));
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="alert-message">
        <div className="mb-xl">{props.message}</div>
        <DocumentActionMenu
          booksDocument={{ ...props.booksDocument }}
          draftId={props.draftData.id}
          position="bottom"
          draftType={props.documentMode}
          closeMenuContainer={() => {
            setShowAlert(false);
            if (props.onClose) {
              props.onClose();
            }
          }}
          // className="justify-content-center"
        />
        <div className="alert-button-row" style={{ marginTop: 24 }}>
          <DKButton
            title="Goto list"
            className="bg-button text-white border-none alert-button"
            onClick={() => {
              setShowAlert(false);
              if (window.location.pathname !== props.pageRoute) {
                dispatch(removeDraft(props.draftData.id));
                RouteManager.navigateToPage(props.pageRoute);
              } else {
                dispatch(removeDraft(props.draftData.id));
              }
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
        </div>
      </div>
    );
  };

  return showAlert ? (
      <DynamicPopupWrapper>
        <div className="transparent-background" style={{ zIndex: 499 }}>
          <div
            className="popup-window overflow-hidden alert"
            style={{
              maxWidth: 700,
              width: 'auto',
              maxHeight: '95%',

              padding: 18,
              position: 'fixed',
              borderRadius: 6,
              zIndex: 500
            }}
          >
            {getHeader()}
            {getBody()}
          </div>
        </div>
      </DynamicPopupWrapper>
  ) : null;
}
