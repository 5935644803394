import ApiConstants from "../Constants/ApiConstants";
import http from './Interceptor';


export interface GoodsReceivedApprovalAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
  StartDate?: string;
  EndDate?: string;
  SalesPerson?: number;
}

export const defaultConfig: GoodsReceivedApprovalAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '&sort=code&sortDir=DESC',
  QuerySummary: '',
  StartDate: '',
  EndDate: '',
  SalesPerson: 0
};

class GoodsReceiptApprovalService {

  static apiConfig: GoodsReceivedApprovalAPIConfig = defaultConfig
    private static async fetchGoodsReceivedApprovalSearch(endpoint: string, config: GoodsReceivedApprovalAPIConfig, options: any = {}) {
        const searchParams = new URLSearchParams({
            limit: config.Limit?.toString() ?? "25",
            page: config.Page?.toString() ?? "0",
            search: config.SearchTerm ?? "",
            query: config.Query ?? "",
            fetchAttachmentDetails:"true",

        });
        let finalEndpoint: string = endpoint + '?' + searchParams.toString() + config.QueryParam.toString();
        if (this.apiConfig.SalesPerson) {
          finalEndpoint =
            finalEndpoint + '&salesperson=' + this.apiConfig.SalesPerson;
        }
        return http.get(`${finalEndpoint}`, options).then((response: any) => {
            return Promise.resolve(response);
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    }

  static getGoodsReceivedNotesData() 
  {
      const url = ApiConstants.URL.GOODS_RECEIPT.APPROVAL_SEARCH;
      return this.fetchGoodsReceivedApprovalSearch(url, this.apiConfig, {}).then((response: any) => {
        return Promise.resolve(response);
      }).catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deletePendingReceivedGoods(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.RECEIVED_GOODS.DELETE_PENDING_RECEIVED_GOODS(
        data.sequenceNoGR
      );
    try {
      return http.delete(`${finalEndpoint}`).then((response:any) => {
        return Promise.resolve(response);
      });
    } catch (err) {
      console.error('Error deleting received goods: ', err);
      return Promise.reject(err);
    }
  }

  static approveRejectGoodsReceipt(data:any) {
    const finalEndpoint: string =
      ApiConstants.URL.GOODS_RECEIPT.APPROVE_REJECT;
    try {
      return http.put(`${finalEndpoint}`, data).then((response:any) => {
        return Promise.resolve(response);
      });
    } catch (err) {
      console.error('Error approving/rejecting goods receipt: ', err);
      return Promise.reject(err);
    }
  }
}

export default GoodsReceiptApprovalService;