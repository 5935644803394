import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import { EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getPageHeight, getVW, getIsJournalEntry, getCapitalized, getIsProductDocumentForBuySell, getIsQuotation, getIsInvoice, getIsSalesOrder } from "../../Utilities/Utility";
import { getLocalisedText } from '../../Translate/LanguageManager';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import AddressParser, { AddressType } from "../../Helper/AddressParser";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import { HTMLTag } from "../../Utilities/HTMLTag";
import DKIndiaTaxBreakdown from "../../DKUILibrary/DKIndiaTaxBreakdown";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import CustomFieldManager from "../../Manager/CustomFieldManager";

export default class IndiaTemplate13 extends Component {//id: 62 based on stock transfer - India Template 12
    spacingRatio = 0.25
    paddingLeft = getVW(Utility.convertToPrintScale(5, this.props.data.printScale))

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        // Subtracting top and bottom border height from min page height
        let minHeight = getPageHeight(this.state.data.printScale);
        if (minHeight && minHeight.includes("vw")) {
            minHeight = `${Number(minHeight.replace("vw", "")) - Number(getVW(2).replace("vw", ""))}vw`;
        }

        return (
            <div id={HTMLTag.TEMPLATE_HOLDER} className='DocumentHolder ColumnDiv parent-width'
                style={{
                    justifyContent: 'space-between',
                    minHeight: minHeight,
                    border: '1px solid black'
                }}>
                <div id={HTMLTag.TEMPLATE_CONTENT} className='ColumnDiv parent-width' style={{ minHeight: minHeight }}>
                    <div id={HTMLTag.TEMPLATE_HEADER} className='parent-width'>
                        {this.getCompanySection()}
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                        {this.getDocumentTypeSection()}
                        <table style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            margin: 'auto'
                        }}>
                            <tbody>
                                {this.getShipToAndDetailsSection()}
                                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                            </tbody>
                        </table>
                        {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_ABOVE} className='parent-width'>

                        <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isShowGrid />
                        {
                            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                            <>
                                <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                    dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
                            </>
                        }
                    </div>
                    <div id={HTMLTag.TEMPLATE_LINE_TABLE} className='parent-width'>
                        {this.getProductTable()}
                        {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(30 * this.spacingRatio)}
                        {this.getEditableDocumentTable()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_LT_BELOW} className='parent-width'>
                        {this.getAmountInWordsSection()}
                        {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                        {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                        {/* <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isShowGrid /> */}
                        {this.getCustomFields('bottom')}
                        {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(15 * this.spacingRatio)}
                        {this.getTermsAndConditionOrNotesSection()}
                        {getIsProductDocumentForBuySell(this.state.data) &&
                            Utility.getIsShowHsnSacTaxTable() &&
                            <DKIndiaTaxBreakdown
                                data={this.state.data}
                            />
                        }
                        {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                        <div id={HTMLTag.SIGNATURE} className="parent-width break-inside-avoid">
                            {this.state.data.showSignature && ComponentManager.addVerticalSpace(Utility.convertToPrintScale(30, this.state.data.printScale) * this.spacingRatio)}
                            {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                        </div>
                        {this.getCompanyBottomSection()}
                    </div>
                    <div id={HTMLTag.TEMPLATE_FOOTER} className='parent-width mt-auto'>
                        {Utility.getIsShowApprovalSection() && <DKApprovalSection data={{ ...this.state.data, companyName: '' }} style={this.getTableStyle()} isThemeColor />}
                    </div>
                </div>
                {Utility.getGeneratedVisibility() && ComponentManager.getGenerateDocumentSection(this.state.data, this.spacingRatio, getVW(10 * this.spacingRatio), getVW(10 * this.spacingRatio))}
            </div>
        );
    }

    getCompanySection() {
        let emailString = ''
        const email = this.state.data.fromObj?.emailAddress ?? ''

        if (email !== '') {
            emailString = getLocalisedText('email') + ': ' + email
        }

        let phoneNumberString = ''
        const phone = this.state.data.fromObj?.contactNumber ?? ''
        if (phone !== '') {
            phoneNumberString = ' ' + getLocalisedText('phone') + ': ' + phone
        }

        return <div className="RowDiv"
            style={{
                justifyContent: 'center',
                // paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale))
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, paddingLeft: getVW(Utility.convertToPrintScale(5, this.state.data.printScale)), paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(70, this.state.data.printScale), { position: 'relative' })}
                {ComponentManager.addVerticalSpace(10 * this.spacingRatio)}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 4, paddingTop: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)) }}>
                <div className="ColumnDiv" style={{ justifyContent: "center", alignItems: "flex-end", alignSelf: 'baseline', width: '100%' }}>
                    {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                        style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                        }}>
                        {getLocalisedText(Utility.getCompanyName(this.state.data))}
                    </text>}
                    {this.state.data.showCompanyName && ComponentManager.addVerticalSpace(5 * this.spacingRatio)}
                    {this.state.data.showFrom && <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            color: '#000000',
                            textAlign: 'center',
                            width: '100%',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: Utility.getOwnerType(this.state.data, AddressType.from) }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText(AddressParser.getRequisitonCustomAddressString(this.state.data.fromObj)) }}>
                    </div>}
                    {/* <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            marginTop: 0,
                            color: '#000000',
                            textAlign: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: getLocalisedText((getIsOrder(this.state.data.type) || getIsBill(this.state.data.type)) ? this.state.data.billOrderCompanyStateNameCode : this.state.data.companyStateNameAndCode,) }}>
                    </div> */}
                    <div className={"docFontStyle" + getFontSizeClass()}
                        style={{
                            width: '100%',
                            marginTop: 0,
                            color: '#000000',
                            textAlign: 'center',
                            ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular, ownerType: undefined }, this.state.data.printScale)
                        }}
                        dangerouslySetInnerHTML={{ __html: emailString + phoneNumberString }}>
                    </div>
                </div>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }}>

            </div>
        </div>
    }

    getDocumentTypeSection() {
        let receiptStatusDiv = undefined
        let receiptStatus = this.state.data.receiptStatus ?? undefined

        if (receiptStatus && receiptStatus === 'FULLY_RECEIVED') {
            receiptStatusDiv = <div className="RowDiv">
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: 'rgb(107,0,1)',
                        // paddingTop: getVW(Utility.convertToPrintScale(20, this.state.data.printScale)),
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText('completed'))}
                </text>
            </div>
        }

        return <div className="RowDiv"
            style={{
                justifyContent: 'center',
            }}>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, paddingLeft: this.paddingLeft }}>
                {receiptStatusDiv}
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'center', flex: 4 }}>
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
            </div>
            <div className="ColumnDiv" style={{ alignItems: 'left', flex: 1, padding: getVW(20 * this.spacingRatio) }}>
            </div>
        </div>
    }

    getShipToAndDetailsSection() {
        return <tr>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {this.state.data.showClientShipToAddress && <div className="ColumnDiv parent-width" style={{
                    paddingLeft: this.paddingLeft
                }}>
                    {ComponentManager.getDescriptionSection(
                        this.state.data.shipToTitle,
                        Utility.getClientShipToAddress(this.state.data),
                        "100%",
                        this.state.data.themeColor,
                        false,
                        '#000000',
                        getVW(0),
                        Utility.getOwnerType(this.state.data, AddressType.shipTo),
                        this.state.data.printScale
                    )}
                    {ComponentManager.getDescriptionSection(
                        "",
                        this.state.data.shipToAddressStateCode,
                        "100%",
                        this.state.data.themeColor,
                        false,
                        undefined,
                        0,
                        undefined,
                        this.state.data.printScale
                    )}
                </div>}
                {this.getShipToDetail()}
            </td>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {this.getDocumentDetailsSection()}
            </td>
        </tr>
    }

    getShipToDetail() {
        let additionalDetails = [
            // { label: getLocalisedText('email'), key: 'email' },
            // { label: getLocalisedText('phone'), key: 'phone' },
            // { label: getLocalisedText('state_code'), key: 'stateCode' },
            // { label: getLocalisedText('GSTIN No.'), key: 'gstin' },
            // { label: getLocalisedText('Contact Person'), key: 'contactPerson' },
            { label: getLocalisedText('bill_to'), key: 'billToName' },
            { label: this.state.data.billToTitle, key: 'billToAddress' },
        ]

        if (!this.state.data.showClientBillToAddress) {
            additionalDetails = additionalDetails.filter(x => x.key !== 'billToName' && x.key !== 'billToAddress')
        }

        let companyDetailsSection = additionalDetails.map(element => {
            let newEle = { ...element }
            newEle.value = this.getShipToDetailValue(element.key)
            return this.getRowValueSection(newEle.label, newEle.value)
        });

        return (
            <div className="ColumnDiv" style={{
                flex: 2,
                alignSelf: 'baseline',
            }}>
                <div className="ColumnDiv" style={{
                    paddingLeft: this.paddingLeft,
                }}>
                    {companyDetailsSection}
                </div>
            </div>
        )
    }


    getIsSalesDocument() {
        return getIsQuotation(this.state.data.type) || getIsInvoice(this.state.data.type) || getIsSalesOrder(this.state.data.type)
    }

    getShipToDetailValue(key) {
        let value = ''
        switch (key) {
            case 'email':
                value = this.state.data.clientShipToAddressObj?.emailAddress ?? ''
                break;
            case 'phone':
                value = this.state.data.clientShipToAddressObj?.contactNumber ?? ''
                break;
            case 'stateCode':
                let state = this.state.data.clientShipToAddressObj?.state ?? ''
                const stateCode = this.state.data.clientShipToAddressObj?.stateCode ?? ''

                if (state !== '') {
                    state = ' State: ' + state
                }
                value = stateCode + state
                break;
            case 'gstin':
                value = this.state.data.clientShipToAddressObj?.gstin ?? ''
                break;
            case 'billToName':
                if (this.getIsSalesDocument()) {
                    value = this.state.data.billToName ?? ''
                }
                else {
                    if (TemplateSettingsManager.getIsSwapAddress()) {
                        value = this.state.data.billToName ?? ''
                    }
                    else {
                        value = this.state.data.tenantInfo.name ?? ''
                    }
                }
                break;
            case 'billToAddress':
                value = Utility.getClientBillToAddress(this.state.data)
                break;
            default:
                break;
        }

        return value
    }

    getDocumentDetailsSection() {
        const documentDetailList = Utility.getDocumentDetailsList(this.state.data)

        const selectedDetailList = documentDetailList.filter(x => x.isSelected).sort((a, b) => a.index - b.index)

        let selectedDetailListSection = undefined
        if (selectedDetailList.length > 0) {
            selectedDetailListSection = selectedDetailList.map(element => {
                return this.getSingleDetailsSection(element)
            });
        }

        if (selectedDetailListSection) {
            return (
                <div className="ColumnDiv" style={{
                    flex: 2,
                    alignSelf: 'baseline',
                }}>
                    <div className="ColumnDiv" style={{
                        paddingLeft: this.paddingLeft,
                    }}>
                        {selectedDetailListSection}
                    </div>
                </div>
            )
        }
    }

    getDocumentDetailsValue(element) {
        var value = ''
        if (element.key !== undefined) {
            value = this.state.data[element.key]
        }
        else if (element.customFieldValue !== undefined) {
            value = element.customFieldValue
        }

        return value
    }

    getRowValueSection(title, value) {
        return <div className="RowDiv">
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                {getLocalisedText(title)}
            </text>
            <text className={"docFontStyle" + getFontSizeClass()}
                style={{
                    alignSelf: 'baseline',
                    color: this.state.data.themeColor,
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }}>
                : &nbsp;
            </text>
            <text className={"docFontStyleBold" + getFontSizeClass()}
                style={{
                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                }} dangerouslySetInnerHTML={{ __html: value }}>
            </text>
        </div>
    }

    getLeftAndRightDetailsSection(left, right) {
        return <div className="RowDiv">
            {left ?
                <div className="RowDiv" style={{
                    flex: 3,
                    paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                }}>
                    {this.getRowValueSection(
                        left.label,
                        this.getDocumentDetailsValue(left)
                    )}
                </div>
                : <></>
            }
            {right ?
                <div className="RowDiv" style={{
                    flex: 2,
                    paddingLeft: getVW(Utility.convertToPrintScale(10, this.state.data.printScale)),
                }}>
                    {this.getRowValueSection(
                        right.label,
                        this.getDocumentDetailsValue(right)
                    )}
                </div>
                : <></>
            }
        </div>
    }

    getSingleDetailsSection(item) {
        return <div className="RowDiv">
            {item ?
                this.getRowValueSection(
                    item.label,
                    this.getDocumentDetailsValue(item)
                ) : <></>
            }
        </div>
    }

    getPlaceOfSupplyInfoSection() {
        const placeOfSupply = this.getRowValueSection(getLocalisedText('place_of_supply'), this.state.data.placeOfSupply)

        return this.getTableColumnTd(placeOfSupply, <></>)
    }

    getSourceOfSupplyInfoSection() {
        let sourceOfSupply = Utility.getIsShowSourceOfSupply(this.state.data) && this.getRowValueSection(getLocalisedText('source_of_supply'), this.state.data.sourceOfSupply)
        let destinationOfSupply = Utility.getIsShowDestinationOfSupply(this.state.data) && this.getRowValueSection(getLocalisedText('destination_of_supply'), this.state.data.destinationOfSupply)

        if (sourceOfSupply) {
            sourceOfSupply = <div style={{ paddingLeft: this.paddingLeft }}>
                {sourceOfSupply}
            </div>
        }
        if (destinationOfSupply) {
            destinationOfSupply = <div style={{ paddingLeft: this.paddingLeft }}>
                {destinationOfSupply}
            </div>
        }

        if (sourceOfSupply) {
            return this.getTableColumnTd(sourceOfSupply, destinationOfSupply)
        }
        else if (destinationOfSupply) {
            return this.getTableColumnTd(destinationOfSupply)
        }

        return <></>
    }

    getAmountInWordsSection() {
        const amountInWords = this.state.data.amountInWords ?? undefined
        if (amountInWords) {
            return <div className="parent-width" style={{
                border: '1px solid black',

            }}>
                <div style={{
                    paddingLeft: this.paddingLeft
                }}>
                    {this.getRowValueSection(getLocalisedText('amount_in_words'), amountInWords)}
                </div>
            </div>
        }
    }

    getNotesValue() {
        let notes = ''
        if (TemplateSettingsManager.getRemoteTemplateUID() === undefined || TemplateSettingsManager.getRemoteTemplateUID() === null) {
            notes = 'notes_placeholder_text'
        }
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text !== 'notes_placeholder_text') {
            notes = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.notes.text
        }
        return getLocalisedText(notes)
    }

    getTermsAndConditionOrNotesSection() {
        const termsAndConditionSection = (this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) ?
            <div className="RowDiv"
                style={{
                    width: '-webkit-fill-available',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    paddingLeft: this.paddingLeft
                }}>
                {ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                    getLocalisedText(this.state.data.termsAndCondition),
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                )}
            </div> : undefined

        const notesSection = (this.state.data.showNotes && this.getNotesValue()) ?
            <div className="RowDiv"
                style={{
                    width: '-webkit-fill-available',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    paddingLeft: this.paddingLeft
                }}>
                {ComponentManager.getTermsAndConditionOrNotesSection(
                    Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                    this.getNotesValue(),
                    "100%",
                    this.state.data.themeColor,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale,
                    Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                )}
            </div> : undefined

        // const remarks = <div className="RowDiv"
        //     style={{
        //         width: '-webkit-fill-available',
        //         paddingTop: getVW(5 * this.spacingRatio),
        //         paddingBottom: getVW(5 * this.spacingRatio),
        //         paddingLeft: this.paddingLeft
        //     }}>
        //     {ComponentManager.getTermsAndConditionOrNotesSection(
        //         getLocalisedText('remarks'),
        //         this.state.data.remarks ?? '',
        //         "100%",
        //         this.state.data.themeColor,
        //         undefined,
        //         undefined,
        //         undefined,
        //         this.state.data.printScale,
        //         undefined,
        //     )}
        // </div> 

        if (notesSection === undefined && termsAndConditionSection === undefined) {
            return undefined
        }

        return (
            <table
                id={HTMLTag.TERMS_NOTE_PREFIX}
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                    border: '1px solid',
                    paddingTop: getVW(5 * this.spacingRatio),
                    paddingBottom: getVW(5 * this.spacingRatio),
                    width: '100%'
                }}
            >
                <tbody>
                    {notesSection &&
                        <tr>
                            <td style={{ width: '50%', verticalAlign: 'top' }}>
                                {notesSection}
                            </td>
                        </tr>
                    }
                    {termsAndConditionSection &&
                        <tr>
                            <td style={{ width: '50%', verticalAlign: 'top' }}>
                                {termsAndConditionSection}
                            </td>
                        </tr>
                    }
                    {/* <tr>
                        {remarks}
                    </tr> */}
                </tbody>
            </table>
        )
    }

    getCompanyBottomSection() {
        return <div className="RowDiv" style={{
            flex: 2,
            alignSelf: 'baseline',
            justifyContent: 'space-between'
        }}>
            <div className="ColumnDiv">
                {this.getCompanyAdditionalDetail()}
            </div>
            <div className="ColumnDiv"
                style={{
                    alignSelf: 'baseline'
                }}>
                {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        paddingRight: this.paddingLeft,
                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.regular }, this.state.data.printScale)
                    }}>
                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                </text>}
            </div>
        </div>
    }

    getCompanyAdditionalDetail() {
        let additionalDetails = [
            { label: getLocalisedText('GSTIN No.'), key: 'gstin' },
            { label: getLocalisedText('STATE'), key: 'state' },
            { label: getLocalisedText('PAN No.'), key: 'panNo' },
            { label: getLocalisedText('CIN No.'), key: 'cinNo' },
        ]

        let companyDetailsSection = additionalDetails.map(element => {
            let newEle = { ...element }
            newEle.value = this.getCompanyAdditionalDetailValue(element.key)
            return this.getRowValueSection(newEle.label, newEle.value)
        });

        return (
            <div className="ColumnDiv" style={{
                flex: 2,
                alignSelf: 'baseline',
            }}>
                <div className="ColumnDiv" style={{
                    paddingLeft: this.paddingLeft,
                }}>
                    {companyDetailsSection}
                </div>
            </div>
        )
    }

    getCompanyAdditionalDetailValue(key) {
        let value = ''
        switch (key) {
            case 'gstin':
                value = this.state.data.fromObj?.gstin ?? ''
                break;
            case 'state':
                const state = this.state.data.fromObj?.state ?? ''
                let stateCode = this.state.data.fromObj?.stateCode ?? ''

                if (stateCode !== '') {
                    stateCode = ' STATE CODE: ' + stateCode
                }
                value = state + stateCode
                break;
            case 'panNo':
                value = this.state.data.fromObj?.pan ?? ''
                break;
            case 'cinNo':
                value = this.state.data.fromObj?.cin ?? ''
                break;
            default:
                break;
        }

        return value
    }

    getTableColumnTd(left, right) {
        return <tr>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {left}
            </td>
            <td style={{ border: '1px solid', width: '50%', verticalAlign: 'top' }}>
                {right}
            </td>
        </tr>
    }

    getProductTable() {
        if (Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = this.state.data.themeColor;
        style.footerTextColor = this.state.data.themeColor;
        // style.headerBorderColor = "#BBBABA";
        // style.itemBorderColor = "#BBBABA";
        style.headerBorderColor = "#000000";
        style.itemBorderColor = "#000000";
        style.footerBackgroundColor = "white";
        // style.footerBorderColor = "#BBBABA";
        style.footerBorderColor = "#000000";
        style.headerBackgroundColor = "transparent";
        style.noPadding = true
        return style;
    }


    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 3
        style.columnWidths = Utility.getIsArabicLang() ? ['33%', '33%', '33%'] : ['33%', '33%', '33%']
        return style;
    }

    getExchangeRateTable(tableStyle, data) {
        const style = {
            ...tableStyle,
            paddingTop: 4,
            paddingBottom: 4,
            paddingLeft: 6,
            paddingRight: 13,
        };

        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(style, data)}
                </div>
            </div>
        );
    }
    getCustomFields(customFieldPosition) {
        let customFieldList = []
        let renderCustomField = undefined;
        if (customFieldPosition) {
            customFieldList = CustomFieldManager.getSelectedGlobalCustomFieldList(this.props.data, customFieldPosition)
            if(customFieldList && customFieldList.length !== 0) {
                renderCustomField = customFieldList.map(element => {
                    return this.getCustomFieldDetailsSection(element, false)
                });
                renderCustomField = <div className=""
                style={{
                    paddingLeft: this.paddingLeft,
                    paddingTop: getVW(Utility.convertToPrintScale(5, this.state.data.printScale))
                }}>{renderCustomField}</div>
            }            
        }
        return renderCustomField
    }
    getCustomFieldDetailsSection(item, isLeftItem) {
        return ComponentManager.getRowValueSectionCustom(
            item?.label,
            this.getCustomFieldValue(item),
            isLeftItem ? "50%" : "20%",
            this.state.data?.themeColor,
            undefined,
            ":",
            getVW(Utility.convertToPrintScale(0, this.state.data?.printScale)),
            this.state.data?.printScale
        )
    }
    getCustomFieldValue(item) {
        if(this.state.data?.customFields) {
            const customFieldDetail = this.state.data.customFields.find((field)=>field.id === item?.id);
            if(customFieldDetail) {
                return customFieldDetail.value
            }
        } else {
            return ''
        }
    }
}
