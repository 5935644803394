import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_STATUS } from '../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../Constants/TableConstants';
import { TableManager } from '../../Managers/TableManager';
import { ConfigPayload, GridState } from '../../Models/ReduxStore';
import { IColumn } from '../../Models/Table';
import RequestForQuotationService from '../../Services/RequestForQuotation';

import { deepClone } from '../../Utility/Utility';
import { RootState } from '../Store';

const initialState: GridState = {
  data: {} as any,
  columnConfig: [],
  configTableId: '',
  status: API_STATUS.IDLE,
  refreshCallback: []
};

export const fetchRequestForQuotations = createAsyncThunk(
  'requestForQuotation',
  async () => {
    const response = await RequestForQuotationService.getRfqByPage();
    return response;
  }
);

export const RequestForQuotationSlice = createSlice({
  name: 'Request For Quotation',
  initialState,
  reducers: {
    requestForQuotationColumnConfig: (
      state,
      action: PayloadAction<ConfigPayload>
    ) => {
      const tableId = action.payload.tableId;
      const configs = action.payload.config;
      let configsToStore: any[] = [];
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
      state.configTableId = tableId;

      TableManager.updateColumnConfig(
        configsToStore,
        REMOTE_CONFIG_TABLES.REQUEST_FOR_QUOTATION
      );
    },
    updateColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      state.columnConfig = [...action.payload];
    },
    addColumnToRequestForQuotationColumnConfig: (
      state,
      action: PayloadAction<IColumn>
    ) => {
      state.columnConfig = [...state.columnConfig, action.payload];
    },
    updateRequestForQuotationDetails: (state, action: PayloadAction<any>) => {
      const rfqDetails = action.payload;
      const pageDetails: any = deepClone(state.data);
      const content: any[] = pageDetails.content;
      const rfqIndex: number = content.findIndex(
        (contact: any) => contact.id === rfqDetails.id
      );
      if (rfqIndex >= 0) {
        content[rfqIndex] = rfqDetails;
        state.data['content'] = [...content];
      }
    },
    updateRefreshCallback: (state, action: PayloadAction<any>) => {
      state.refreshCallback = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestForQuotations.pending, (state) => {
        state.gridContentLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(fetchRequestForQuotations.fulfilled, (state, action) => {
        state.gridContentLoadingStatus = API_STATUS.IDLE;
        state.data = action.payload;
      });
  }
});

export const {
  addColumnToRequestForQuotationColumnConfig,
  requestForQuotationColumnConfig,
  updateColumnConfig,
  updateRequestForQuotationDetails,
  updateRefreshCallback
} = RequestForQuotationSlice.actions;

export const selectRequestForQuotation = (state: RootState) =>
  state.RequestForQuotation.data;

export const selectRequestForQuotationLoadingStatus = (state: RootState) =>
  state.RequestForQuotation.gridContentLoadingStatus;

export const selectRequestForQuotationColumnConfig = (state: RootState) =>
  state.RequestForQuotation.columnConfig;

export const selectRequestForQuotationColumnConfigTableId = (
  state: RootState
) => state.RequestForQuotation.configTableId;

export const selectRefreshCallbackPO = (state: RootState) =>
  state.RequestForQuotation.refreshCallback;

export default RequestForQuotationSlice.reducer;
