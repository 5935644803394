import { TableColumnPopupType } from "../Constants/Constants";
import TemplateSettingsManager from "../Manager/TemplateSettingsManager";
import { getIsAccountModule, getIsBOMModule, getIsExpenseBill, getIsFulfillment, getIsGoodsReceipt, getIsInspectionReport, getIsJobWorkOutOrder, getIsJournalEntry, getIsMakePayment, getIsPackList, getIsPickList, getIsQCDocument, getIsQCInspection, getIsReceivePayment, getIsSOACustomer, getIsSOAVendor, getIsSalesReturn, getIsStockIssue, getIsStockRequest, getIsWorkOrder } from "../Utilities/Utility";

export const TableColumnAlignmentType = {
    center: 'center',
    left: 'left',
    right: "right",
    top: 'top',
    bottom: 'bottom',
    default: 'default',
    middle: 'middle',
}

export default class TableColumnAlignmentParser {

    static getTableColumnVerticalAlignment() {
        var tableColumnVerticalAlignmentList = []
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style

        if (style !== undefined && style !== null) {
            if (style.table !== undefined && style !== null) {
                if (style.table.tableColumnVerticalAlignmentList !== undefined && style.table.tableColumnVerticalAlignmentList !== null) {
                    tableColumnVerticalAlignmentList = style.table.tableColumnVerticalAlignmentList

                    if (tableColumnVerticalAlignmentList.length > 0) {

                        tableColumnVerticalAlignmentList.forEach(element => {
                            if(element.alignment === TableColumnAlignmentType.default) {
                                element.alignment = undefined
                            }
                        });

                    }
                }
            }
        }

        return tableColumnVerticalAlignmentList
    }

    static getTableColumnAlignment() {
        var tableColumnAlignmentList = []
        var style = TemplateSettingsManager.defaultTemplateSettings.templateInfo.style

        if(style !== undefined && style !== null) {
            if (style.table !== undefined && style !== null) {
                if (style.table.tableColumnAlignmentList !== undefined && style.table.tableColumnAlignmentList !== null) {
                    tableColumnAlignmentList = style.table.tableColumnAlignmentList
                }
            }
        }

        return tableColumnAlignmentList
    }

    static getDefaultTableColumnAlignmentListByDocument(documentData) {
        var tableColumnAlignmentList = this.getDefaultAlignmentForProduct()
        if (getIsAccountModule(undefined, documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForAccount()
        }
        else if (getIsExpenseBill(documentData.type, documentData.lineItems)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForAccount()
        }
        else if (getIsMakePayment(documentData.type) || getIsReceivePayment(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPayment()
        }
        else if (getIsJournalEntry(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForJournalEntry()
        }
        else if (getIsFulfillment(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForFulfilment()
        }
        else if (getIsGoodsReceipt(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForGoodsReceipt()
        }
        else if (getIsPackList(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPackingList()
        }
        else if (getIsPickList(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForPickingList()
        }
        else if (getIsQCDocument(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForQCDocument()
        }
        else if (getIsQCInspection(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForQCInspection()
        }
        else if (getIsInspectionReport(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForInspectionReport()
        }
        else if (getIsSalesReturn(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForSalesOrder()
        }
        else if (getIsJobWorkOutOrder(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForJobWorkOutOrder()
        }
        else if (getIsWorkOrder(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForWorkOrder()
        }
        else if (getIsBOMModule(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForBOM()
        }
        else if (getIsStockRequest(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForStockRequest()
        }
        else if (getIsStockIssue(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForStockIssue()
        }
        else if (getIsSOACustomer(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForSOACustomer()
        }
        else if (getIsSOAVendor(documentData.type)) {
            tableColumnAlignmentList = this.getDefaultAlignmentForSOAVendor()
        }

        return tableColumnAlignmentList
    }

    static getDefaultAlignmentForProduct() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.quantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityWithUom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.serialNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.batchNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.manufacturingDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.expiryDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unitPrice, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.amount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.productTax, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productDiscount, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.hsn, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.availableQuantity, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForAccount() {
        return [
            { type: TableColumnPopupType.accountName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.amount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.tax, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.taxAmount, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.paymentAmount, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForFulfilment() {
        return [
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.serialNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.batchNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.manufacturingDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.expiryDate, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.requiredQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.committedQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseCode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseName, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForGoodsReceipt() {
        return [
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.requiredQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.receivedQuantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseCode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.warehouseName, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForJournalEntry() {
        return [
            { type: TableColumnPopupType.date, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.journalEntryNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.contactCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.contactName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.accountCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.accountName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.credit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.debit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.productCustomField, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForPackingList() {
        return [
            { type: TableColumnPopupType.id, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.barcode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unit, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.packedQuantity, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForPayment() {
        return [
            { type: TableColumnPopupType.snNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.invoiceNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.billNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.paymentMedium, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.paymentMade, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForPickingList() {
        return [
            { type: TableColumnPopupType.invoiceOrQuotation, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.barcode, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityToPick, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityRequiredForOrder, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.picked, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForQCDocument() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.parameterName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.parameterValue, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForQCInspection() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.parameterName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.parameterValue, alignment: TableColumnAlignmentType.left },
        ]
    }

    static getDefaultAlignmentForInspectionReport() {
        return [
        ]
    }

    static getDefaultAlignmentForSalesOrder() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.fulfilledQty, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.returnedQty, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForJobWorkOutOrder() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            // { type: TableColumnPopupType.kindOfPacking, alignment: TableColumnAlignmentType.left },
            // { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            // { type: TableColumnPopupType.dueOn, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityWithUom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unitPrice, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.productDiscount, alignment: TableColumnAlignmentType.center },
            // { type: TableColumnPopupType.rate, alignment: TableColumnAlignmentType.right },
            // { type: TableColumnPopupType.per, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.amount, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForWorkOrder() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.quantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.orderPrepCompleted, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForBOM() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.uom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.quantityWithUom, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.unitPrice, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.cost, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForStockRequest() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.quantity, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.targetWarehouseName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.targetWarehouseCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.selectedRow, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.selectedRack, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.selectedBin, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.requestedQty, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForStockIssue() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.code, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.description, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.productNameWithDescription, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.requiredQty, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.issuedQty, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.sourceWarehouseName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.sourceWarehouseCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.targetWarehouseName, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.targetWarehouseCode, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.serialBatch, alignment: TableColumnAlignmentType.center },
        ]
    }

    static getDefaultAlignmentForSOACustomer() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.documentDate, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.documentNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.particular, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.documentType, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.credit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.debit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.amountInBase, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.balanceInBase, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.currency, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.memo, alignment: TableColumnAlignmentType.right },
        ]
    }

    static getDefaultAlignmentForSOAVendor() {
        return [
            { type: TableColumnPopupType.lineNumber, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.documentDate, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.documentNumber, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.particular, alignment: TableColumnAlignmentType.left },
            { type: TableColumnPopupType.documentType, alignment: TableColumnAlignmentType.center },
            { type: TableColumnPopupType.credit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.debit, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.amountInBase, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.balanceInBase, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.currency, alignment: TableColumnAlignmentType.right },
            { type: TableColumnPopupType.memo, alignment: TableColumnAlignmentType.right },
        ]
    }

}
