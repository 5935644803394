import {
  BOOKS_DATE_FORMAT,
  COLORS as GraphColors
} from '../Constants/Constant';
import DateFormatService from '../Services/DateFormat';

export interface StockTransferItems {
  productName: string;
  productVariantCode: string;
  documentSequenceCode?: string;
  documentUom: number;
  documentUOMSchemaDefinition: any;
  quantity: number;
  requiredQuantity?: number;
  availableQuantity: number;
  stockTransferWarehouseInventoryData: any;
  type: any;
  product: any;
  rowButtons: any;
  dispatchedQty?: any;
  originalRequiredQuantity? :any;
}
export interface StockTransfer {
  notes: string;
  transferDate?: any;
  stockTransferItems: StockTransferItems[];
  customField: null | any;
  transferCost: number;
  stockTransferDocumentSeqCodes?:any;
}

export const StockTransferInitialState: StockTransfer = {
  notes: '',
  transferDate: DateFormatService.getDateStrFromDate(
    new Date(),
    BOOKS_DATE_FORMAT['YYYY-MM-DD']
  ),
  transferCost: 0,
  stockTransferItems: [
    {
      productName: '',
      productVariantCode: '',
      documentUom: 0,
      documentUOMSchemaDefinition: {},
      quantity: 0,
      requiredQuantity: 0,
      availableQuantity: 0,
      stockTransferWarehouseInventoryData: '',
      type: null,
      product: null,
      rowButtons: []
    }
  ],
  customField: null
};
