import {
  DueDateItem,
  SettingItem
} from '../Components/Contacts/InvoiceRemider';
import ApiConstants from '../Constants/ApiConstants';
import { ERROR } from '../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import {
  PaymentRecordDeletePayload,
  PaymentRecordPayload
} from '../Models/Agency';
import { BulkDeleteResponse } from '../Models/Interfaces';
import { Invoice } from '../Models/Invoice';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import { ReminderConfiguration, ReminderSetting } from '../Models/ReduxStore';

export interface InvoiceAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  QuerySummary?: string;
  Duration?: string;
  Sort?: string;
  SortDir?: string;
  SalesPerson?: number;
  WithFilter?: any;
  WithoutFilter?: any;
}

export const defaultConfig: InvoiceAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: '',
  QuerySummary: '',
  Duration: '',
  SalesPerson: 0,
  WithFilter: '',
  Sort: 'salesInvoiceDate',
  SortDir: 'DESC',
  WithoutFilter: ''
};
class InvoiceService {
  static apiConfig: InvoiceAPIConfig;
  static uomApiConfig: InvoiceAPIConfig = {
    SearchTerm: '',
    Limit: 500,
    Page: 0,
    Query: '',
    QueryParam: '',
    QuerySummary: '',
    Duration: ''
  };
  static abortController: any = null;

  static getInvoiceSummary() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    var queryString: string = `?search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }`;
    queryString = queryString + '&' + 'query=module=SALES_INVOICE';
    if (!Utility.isEmpty(this.apiConfig.QuerySummary)) {
      queryString = queryString + '&' + this.apiConfig.QuerySummary;
    }
    queryString = queryString + '&' + 'eType=IC';
    if (this.apiConfig.SalesPerson) {
      queryString = queryString + '&salesperson=' + this.apiConfig.SalesPerson;
    }
    const finalEndpoint: string =
      ApiConstants.URL.INVOICES.INVOICE_SUMMARY + queryString;
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getInvoicesByPage() {
    Utility.cancelRequest(InvoiceService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );

    let queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&query=${
      !Utility.isEmpty(this.apiConfig.Query) ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}${
      this.apiConfig.SalesPerson && this.apiConfig.SalesPerson !== 0
        ? '&salesperson=' + this.apiConfig.SalesPerson
        : ''
    }`;

    if (!Utility.isEmpty(this.apiConfig?.Duration)) {
      queryString += `&duration=${this.apiConfig.Duration}`;
    }
    const finalEndpoint: string =
      ApiConstants.URL.INVOICES.FETCH_INVOICES + country + queryString;

    InvoiceService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: InvoiceService.abortController.signal
      })
      .then(
        (response: any) => {
          return !Utility.cancelRequestMessage(response)
            ? Promise.resolve(response)
            : {};
        },
        (error: any) => {
          return !Utility.cancelRequestMessage(error)
            ? Promise.reject(ERROR)
            : {};
        }
      )
      .catch((error: any) => {
        return !Utility.cancelRequestMessage(error)
          ? Promise.reject(ERROR)
          : {};
      });
  }

  static salesInvoiceEndpoint = () => {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    let finalEndpoint = 'invoices/sales';
    let query = '';
    query += `?limit=${this.apiConfig.Limit}&search=${
      this.apiConfig.SearchTerm
        ? encodeURIComponent(this.apiConfig.SearchTerm)
        : ''
    }&page=${this.apiConfig.Page}`;
    finalEndpoint = finalEndpoint + query;

    return finalEndpoint;
  };

  static getInvoices(query: string, limit: number, searchTerm: string) {
    const finalEndpoint =
      ApiConstants.URL.INVOICES.FETCH_INVOICES +
      `?query=${query}&limit=${limit}&search=${searchTerm}`;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error creating bill: ', err);
      return Promise.reject(err);
    });
  }

  static getInvoicesWithCountry(
    query: string,
    limit: number,
    searchTerm: string
  ) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    const finalEndpoint =
      ApiConstants.URL.INVOICES.FETCH_INVOICES +
      country +
      `?query=${query}&limit=${limit}&search=${searchTerm}`;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error creating bill: ', err);
      return Promise.reject(err);
    });
  }
  static createInvoice(payload: any, useCountryCode = true) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http2
      .post(
        `${ApiConstants.URL.INVOICES.CREATE_INVOICE}${
          useCountryCode ? country : ''
        }`,
        payload
      )
      .catch((err: any) => {
        console.error('Error creating invoice: ', err);
        return Promise.reject(err);
      });
  }

  static updateInvoice(payload: Invoice, invoiceId: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http2
      .put(
        ApiConstants.URL.INVOICES.UPDATE_INVOICE +
          `${country}` +
          `/${invoiceId}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating invoice: ', err);
        return Promise.reject(err);
      });
  }
  static getPurchaseInvoiceByCode(invoiceCode: string) {
    return http
      .get(
        ApiConstants.URL.INVOICES.FETCH_PURCHASE_INVOICE +
          `/code/${invoiceCode}/details`
      )
      .catch((err: any) => {
        console.error('Error fetching invoice details: ', err);
        return Promise.reject(err);
      });
  }

  static getPurchaseInvoiceWithCountryAndCode(invoiceCode: string) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http
      .get(
        ApiConstants.URL.INVOICES.GET_PURCHASE_INVOICE_WITH_COUNTRY_AND_CODE(
          country,
          invoiceCode
        )
      )
      .catch((err: any) => {
        console.error('Error fetching invoice details: ', err);
        return Promise.reject(err);
      });
  }
  static getInvoiceByCode(invoiceCode: string): Promise<any> {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http
      .get(
        ApiConstants.URL.INVOICES.FETCH_INVOICE +
          `${country}` +
          `/code/${invoiceCode}/details`
      )
      .catch((err: any) => {
        console.error('Error fetching invoice details: ', err);
        return Promise.reject(err);
      });
  }

  static getInvoiceByContactCode(contactCode: string) {
    const queryString: string =
      ApiConstants.URL.INVOICES.INVOICES +
      contactCode +
      `?limit=${this.apiConfig.Limit}&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}&query=active=true,status=OPEN`;
    return http.get(queryString).catch((err: any) => {
      console.error('Error fetching invoice details: ', err);
      return Promise.reject(err);
    });
  }

  static getUOMs(updatedLimit?: number) {
    let queryEndPoint = `?page=${this.uomApiConfig.Page}&limit=${
      updatedLimit ? updatedLimit : this.uomApiConfig.Limit
    }&search=${
      this.uomApiConfig.SearchTerm
        ? encodeURIComponent(this.uomApiConfig.SearchTerm)
        : ''
    }`;
    if (this.uomApiConfig.Sort && this.uomApiConfig.SortDir) {
      queryEndPoint += `&sort=${this.uomApiConfig.Sort}&sortDir=${this.uomApiConfig.SortDir}`;
    }
    const endPoint = ApiConstants.URL.PRODUCT.FETCH_UOM + queryEndPoint;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static postUom(payload: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.POST_UOM;
    return http2.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static editUom(payload: any, id: number) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.EDIT_UOM(id);
    return http2.put(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static deleteUom(id: number) {
    try {
      return http.delete(`${ApiConstants.URL.PRODUCT.DELETE_UOM(id)}`);
    } catch (err) {
      console.error('Error deleting COA: ', err);
      return Promise.reject(err);
    }
  }

  static getUomSchema() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let queryEndPoint = `?page=${this.apiConfig.Page}&limit=${this.apiConfig.Limit}&search=${this.apiConfig.SearchTerm}`;
    if (
      this.apiConfig.Sort &&
      this.apiConfig.SortDir &&
      this.apiConfig.Sort !== 'salesInvoiceDate'
    ) {
      queryEndPoint += `&sort=${this.apiConfig.Sort}&sortDir=${this.apiConfig.SortDir}`;
    }

    const endPoint = ApiConstants.URL.PRODUCT.FETCH_UOM_SCHEMA + queryEndPoint;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static postUomSchema(payload: any) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.POST_UOM_SCHEMA;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static editUomSchema(payload: any, id: number) {
    const finalEndPoint = ApiConstants.URL.PRODUCT.EDIT_UOM_SCHEMA(id);
    return http.put(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  static deleteUomSchema(id: number) {
    try {
      return http.delete(`${ApiConstants.URL.PRODUCT.DELETE_UOM_SCHEMA(id)}`);
    } catch (err) {
      console.error('Error deleting COA: ', err);
      return Promise.reject(err);
    }
  }
  static getTaxes() {
    const queryParams = '?query=status=ACTIVE&limit=100';
    const endPoint = ApiConstants.URL.INVOICES.FETCH_TAXES + queryParams;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static deleteInvoice(id: number) {
    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE
    );
    return http2
      .delete(`${ApiConstants.URL.INVOICES.DELETE_INVOICE(id, country)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteInvoiceByCode(invoiceCode: any) {
    return http2
      .delete(
        `${ApiConstants.URL.INVOICES.DELETE_INVOICE_BY_CODE(invoiceCode)}`
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteInvoicePaymentRecord(paymentCode: any, salesInvoiceCode: any) {
    return http
      .delete(
        `${ApiConstants.URL.INVOICES.DELETE_INVOICE_PAYMENT_RECORD(
          salesInvoiceCode,
          paymentCode
        )}`
      )
      .catch((err: any) => {
        console.error('Error deleting Payment Record: ', err);
        return Promise.reject(err);
      });
  }

  static deleteInvoiceCreditNotePaymentRecord(code: any, invoicecode: any) {
    return http
      .delete(
        `${ApiConstants.URL.INVOICES.DELETE_CREDIT_NOTE_PAYMENT_RECORD(
          code,
          invoicecode
        )}`
      )
      .catch((err: any) => {
        console.error('Error deleting Payment Record: ', err);
        return Promise.reject(err);
      });
  }

  static getInvoiceReminderConfiguration() {
    const endPoint = ApiConstants.URL.INVOICES.REMINDER_CONFIGURATION;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getInvoiceReminderSettings() {
    const endPoint = ApiConstants.URL.INVOICES.REMINDER_SETTINGS;
    return http
      .get(endPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateInvoiceReminderConfiguration(payload: DueDateItem[]) {
    return http
      .put<DueDateItem[], ReminderConfiguration[]>(
        ApiConstants.URL.INVOICES.UPDATE_INVOICE_CONFIGURATION,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating invoice reminder Configuration: ', err);
        return Promise.reject(err);
      });
  }

  static updateInvoiceReminderSettings(payload: SettingItem) {
    return http
      .put<SettingItem, ReminderSetting>(
        ApiConstants.URL.INVOICES.UPDATE_INVOICE_SETTINGS,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating invoice reminder Setting: ', err);
        return Promise.reject(err);
      });
  }
  static updateSalesInvoice(payload: Invoice, invoiceId: number) {
    return http
      .put(ApiConstants.URL.INVOICES.UPDATE_INVOICE + `/${invoiceId}`, payload)
      .catch((err: any) => {
        console.error('Error updating invoice: ', err);
        return Promise.reject(err);
      });
  }

  static getInvoiceToMatch(isSales: boolean, amount: any, currency: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?limit=${2}&page=${0}&query=active=true,status=OPEN,dueAmount=${amount},currency=${currency}`;
    const finalEndpoint: string =
      (isSales
        ? ApiConstants.URL.INVOICES.FETCH_INVOICES
        : ApiConstants.URL.BILLS.FETCH_BILLS) + queryString;

    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static fetchPaymentConfiguredOptions(currencyCode: string) {
    return http
      .get(
        ApiConstants.URL.INVOICES.FETCH_CONFIGURED_PAYMENT_OPTIONS +
          `?documentCurrency=${currencyCode}`
      )
      .catch((err: any) => {
        console.error('Error fetching invoice payment configurations: ', err);
        return Promise.reject(err);
      });
  }

  static getInvoiceDetailsByCode(invoiceCode: string) {
    return http
      .get(
        ApiConstants.URL.INVOICES.FETCH_INVOICE + `/code/${invoiceCode}/details`
      )
      .catch((err: any) => {
        console.error('Error fetching invoice details: ', err);
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(ApiConstants.URL.INVOICES.BULK_DELETE, config);
  }

  static getContactInvoiceAmountDue(contactCode: string): Promise<any[]> {
    const finalEndPoint = ApiConstants.URL.INVOICES.CONTACT_INVOICE_AMOUNT_DUE;
    return http2.post(`${finalEndPoint}`, [contactCode]);
  }

  static sendTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.SALES_INVOICE_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }
  static sendBulkTriggerOnApproval(payload: any) {
    const finalEndPoint = ApiConstants.URL.WORKFLOW.SALES_INVOICE_BULK_TRIGGER;
    return http.post(`${finalEndPoint}`, payload).catch((error: any) => {
      return Promise.reject(error);
    });
  }
  static createPaymentRecord(payload: PaymentRecordPayload) {
    return http2.post(ApiConstants.URL.INVOICES.RECORD_PAYMENT, payload);
  }

  static deletePaymentRecord(id: number, payload: PaymentRecordDeletePayload) {
    return http2.delete(ApiConstants.URL.INVOICES.DELETE_PAYMENT(id), {
      data: payload
    });
  }

  static deactivateRecurring(type: any, code: any) {
    return http2
      .delete(
        ApiConstants.URL.INVOICES.DEACTIVATE_RECURRING_INVOICE(type, code)
      )
      .catch((err: any) => {
        console.error('Error deactivating document: ', err);
        return Promise.reject(err);
      });
  }

  static updateMemo(memo: any, code: any) {
    let payload = { code: code, memo: memo };
    return http2
      .put(ApiConstants.URL.INVOICES.UPDATE_MEMO, payload)
      .catch((err: any) => {
        console.error('Error updating invoice: ', err);
        return Promise.reject(err);
      });
  }
  static deleteOBInvoice(id: number) {
    return http
      .delete(`${ApiConstants.URL.INVOICES.DELETE_OB_INVOICE(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static async cancelEwayBill(id: string) {
    const data = { id };
    return http
      .post(ApiConstants.URL.EWAY_BILL.IN_CANCEL_EWAY_BILL, data, {
        timeout: 1000 * 50
      })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        // if (
        //   (error.code =
        //     ApiConstants.CONNECTION_ABORT && error.message.includes('timeout'))
        // ) {
        //   showAlert(
        //     'Error!',
        //     'Cancel E way bill request are in progress but running slow. Please retry again after sometime in case E way bill wont get cancelled.'
        //   );
        // }
        return Promise.reject(error);
      });
  }
}

export default InvoiceService;
