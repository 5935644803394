import React, { useEffect, useState } from 'react';
import { showAlert } from 'deskera-ui-library';
import { Quote, QuoteInitialState } from '../../Models/Quote';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import QuotationService from '../../Services/Quotation';
import {
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  setDraftActionAvailibility,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  POPUP_CALLBACKS_TYPE,
  DOC_TYPE,
  DOCUMENT_MODE,
  DOC_PATH_WITH_ID_REGEX,
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  PRODUCT_TYPE,
  LABELS
} from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import { setItemsFromQuotationItemDtoList } from './QuoteHelper';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import AuthService from '../../Services/Auth';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import NumberFormatService from '../../Services/NumberFormat';
import { DraftTypes } from '../../Models/Drafts';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { CREDIT_LIMIT_TYPE } from '../../Constants/Enum';
import DateFormatService from '../../Services/DateFormat';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { useHistory } from 'react-router-dom';
import RateAnalysisService from '../../Services/RateAnalysis';
import { localizedText } from '../../Services/Localization/Localization';
import ApiConstants from '../../Constants/ApiConstants';

export default function NewQuote(props: any) {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    !Utility.isEmpty(props.draftData)
      ? props.draftData.isCenterAlign
      : props.isCenterAlign
  );

  const [quote, setQuote] = useState<Quote>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromQuotationItemDtoList(props.populateFormData)
      : QuoteInitialState
  );

  const [updatedQuote, setUpdatedQuote] = useState<Quote>();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const dispatch = useAppDispatch();
  let history = useHistory();

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_QUOTE,
        data: (closeDoc: boolean) => {
          createQuote(closeDoc);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: onSaveAsDraft
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_QUOTE,
        data: () => {
          updateQuote();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setQuote(setItemsFromQuotationItemDtoList({ ...props.populateFormData }));
    }
  }, [props.populateFormData]);

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );
    loadApprovalConditionList();
  }, []);

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );
  }, [props]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = () => {
    let payload = { ...updatedQuote };
    payload['approvalStatus'] = payload['approvalStatus']
      ? payload['approvalStatus']
      : APPROVAL_STATUS['NOT_REQUIRED'];
    payload['createdUserName'] = AuthService.getUserName();

    // here we explicitly remove few keys, which is not needed to be saved in component list feature
    payload = removeUnwantedPayloadKeysForDocument(payload);

    setButtonStatus(true);
    props.formData(payload);

    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.QUOTES)
    ) {
      history.replace(PAGE_ROUTES.QUOTES);
    }
  };

  const closeDraftPopup = () => {
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.quotationItemDtoList)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const label = Utility.isUSorg() ? 'Estimate Date' : 'Quote Date';
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          label,
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, label)) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.quotationItemDtoList.length; i++) {
      const item = docToValidate.quotationItemDtoList[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.quotationItemDtoList?.length
      ? docToValidate?.quotationItemDtoList?.reduce(
          (total: number, item: any) => {
            return total + item?.totalWithDiscount;
          },
          0
        )
      : 0;
    if (totalBeforeTax < 0) {
      showAlert(
        'Invalid amount!',
        `${getCapitalized(
          localizedText('quote')
        )} amount can not be less than 0.`
      );
      return false;
    }
    // Check for -ve total amount ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'quotationItemDtoList'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (docToValidate.reservedStock) {
      const isStockInvalid = docToValidate.quotationItemDtoList.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert(
          'Error!',
          'Reserve Stock details are not added for one or more products'
        );
        return false;
      }
    }
    // Validation for reserved stock ends

    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const showCreditLimitAlert = (
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false
  ) => {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setButtonStatus(false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            makeCreateAPICall(payload, closeOnCreate);
          }
        }
      });
    }

    const baseCurrencyCode = tenantInfo.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current estimate)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  };

  const updateQuoteItemListForOptional = (payload: any) => {
    payload.quotationItemDtoList = (payload.quotationItemDtoList || []).map(
      (item: any) => ({
        ...item,
        optional: item.optional ?? false
      })
    );
    return payload;
  };

  // Create Quote
  const createQuote = async (closeOnCreate: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedQuote);
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        quotationItemDtoList: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;

    payload = updateQuoteItemListForOptional(payload);
    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      payload?.duplicate
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setButtonStatus(false);
        return;
      }
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.quotationItemDtoList.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'quotationItemDtoList'
        );
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    payload = removeUnwantedPayloadKeysForDocument(payload);
    payload['approvalStatus'] = APPROVAL_STATUS['NOT_REQUIRED'];

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.draftsTableId}/record/${props.draftData?.data?.id}`;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate
      );
    } else {
      makeCreateAPICall(payload, closeOnCreate);
    }
  };

  const updateForm = (
    docResp: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = true
  ) => {
    QuotationService.getQuoteByCode(docResp.quotationCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.QUOTE,
          items: [...doc.quotationItemDtoList],
          documentDate: payload.documentDate,
          fulfillmentDate: payload.fulfillmentDate,
          validTillDate: payload.validTillDate
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData?.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          dispatch(removeDraft(props.draftData.id));
          if (!isUpdate) {
            getDocumentAlert(
              `${Utility.isUSorg() ? 'Estimate' : 'Quote'} created!`,
              `${
                Utility.isUSorg() ? 'Estimate' : 'Quote'
              } has been created successfully.`,
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.QUOTES
            );
            setButtonStatus(false);
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setButtonStatus(false);
      }
    );
  };

  const makeCreateAPICall = (payload: any, closeDoc: boolean) => {
    QuotationService.createQuote(payload).then(
      (response: any) => {
        if (props.draftData) {
          if (closeDoc) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
          }
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            RateAnalysisService.updateRateAnalysis({
              documentCode: response.quotationCode,
              documentType: 'QUOTATION',
              productPriceMethod:
                tenantInfo?.additionalSettings?.RATE_ANALYSIS
                  ?.PRODUCT_PRICE_METHOD,
              isDraft: props?.draftData.draftType == 'draft' ? true : false,
              draftId: props?.draftData.id,
              tableId: draftsTableId
            })
              .then(() => {})
              .catch((err: any) => {
                console.error('Error updating rate analysis: ', err);
              });
          }
          if (response.draft) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
            setButtonStatus(false);

            const buttons = [
              {
                title: 'Ok',
                className: 'bg-button, border-m',
                onClick: () => {}
              },
              {
                title: `Goto ${Utility.isUSorg() ? 'Estimates' : 'Quotations'}`,
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.QUOTES);
                }
              }
            ];
            showAlert(
              `${
                Utility.isUSorg() ? 'Estimates' : 'Quotations'
              } sent for approval!`,
              'Document has been created successfully.',
              buttons
            );
          } else {
            updateForm(response, payload, false);
          }
        } else {
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
        dispatch(fetchQuotes());
        replaceURLToModuleURL();
      },
      (err) => {
        console.error('Error while creating quote: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();

        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
        dispatch(fetchQuotes());
      }
    );
  };

  const updateQuote = async (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedQuote);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        quotationItemDtoList: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    const approvalRequiredFor = AuthService.getUserEmail();
    let approvalRequired = await Utility.isApprovalRequired(
      payload,
      approvalRequiredFor
    );
    const isApprovalLockEnabled =
      tenantInfo.additionalSettings?.QUOTE_APPROVAL_LOCK_ENABED;
    if (
      approvalRequired &&
      payload.approvalStatus === APPROVAL_STATUS.REJECTED
    ) {
      payload.approvalStatus = APPROVAL_STATUS.PENDING_FOR_APPROVAL;
      payload.locked = true;

      if (tenantInfo?.additionalSettings?.QUOTE_APPROVAL_RESTART_ON_REJECT) {
        payload.multiApprovalDetails = {
          ...payload.multiApprovalDetails,
          currentLevel: 1,
          approverUsers: {},
          rejectedUsers: {}
        };
      }
    } else if (isApprovalLockEnabled && approvalRequired) {
      payload = {
        ...payload,
        locked: true,
        approvalStatus: APPROVAL_STATUS.PENDING_FOR_APPROVAL,
        multiApprovalDetails: {
          currentLevel: 1,
          approverUsers: {},
          rejectedUsers: {},
          approvalHistory: payload?.multiApprovalDetails?.approvalHistory || [],
          approvalRequiredFor: approvalRequiredFor
        }
      };
    }

    delete payload?.items;
    delete payload?.contactDto;

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }
    // payload = {
    //   ...payload,
    //   quotationItemDtoList: payload.quotationItemDtoList.map((ele:any)=> {
    //     return {
    //       ...ele,
    //       pendingQuantity:0
    //     }
    //   })
    // };

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.quotationItemDtoList.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'quotationItemDtoList'
        );
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate
      );
    } else {
      makeUpdateAPICall(payload, closeOnUpdate);
    }
  };

  const makeUpdateAPICall = (payload: any, closeOnUpdate: boolean) => {
    payload.quotationItemDtoList.map((quotationItem: any) => {
      quotationItem.pendingQuantity = quotationItem.productQuantity;
      quotationItem.pendingQtyToConvert = quotationItem.productQuantity;
    });

    payload = removeUnwantedPayloadKeysForDocument(payload);

    QuotationService.updateQuote(payload, updatedQuote?.id as number).then(
      (response) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
            replaceURLToModuleURL();
          }
          setButtonStatus(false);
          updateForm(response, payload, true, closeOnUpdate);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.QUOTES
          })
        );
        dispatch(fetchQuotes());
        setButtonStatus(false);

        if (payload.approvalStatus === APPROVAL_STATUS.PENDING_FOR_APPROVAL) {
          sendTriggerOnApproval(payload.documentSequenceCode, payload, true);
        }
      },
      (err) => {
        console.error('Error while updating quote: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleDocumentUpdate = (doc: Document) => {
    registerInteractions();
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    let quoteWithUpdate = {
      ...updatedQuote,
      ...doc
    };

    const extraKeys = {
      quotationItemDtoList: doc.items,
      shipByDate: doc.fulfillmentDate,
      currency: doc.currency,
      currencyCode: doc.currencyCode,
      documentType: DOC_TYPE.QUOTE,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedQuote?.id
          : undefined,
      documentCode:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedQuote?.quotationCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    quoteWithUpdate = {
      ...quoteWithUpdate,
      ...extraKeys
    };
    setUpdatedQuote(quoteWithUpdate);
    // console.log('Quote updated: ', quoteWithUpdate);
  };
  const sendTriggerOnApproval = (
    draftCode: string,
    payload: any,
    isUpdateCall?: boolean
  ) => {
    let emails = Utility.getApproverEmail(payload);

    let payloadObj = {
      contactCode: payload.contactCode,
      contactName: payload.contact?.name || '',
      documentSeqCode: draftCode,
      totalAmount: NumberFormatService.getNumber(payload.totalAmount || 0),
      userName: AuthService.getUserName(),
      currency: payload.currency,
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: [], // not sending approval history for sending emails on rejection flow as well..
      opName: isUpdateCall ? 'u' : 'c',
      documentLink: `${
        Utility.isMRPWithURLCheck()
          ? ApiConstants.URL.APP_MRP_URL
          : ApiConstants.URL.APP_ERP_URL
      }${PAGE_ROUTES.QUOTES.replace('/', '')}/${payload.quotationCode || ''}`
    };
    QuotationService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  return (
    <NewDocument2
      permissionKeys={PERMISSIONS_BY_MODULE.QUOTATION}
      booksDocument={quote}
      documentMode={props.documentMode}
      draftData={props.draftData}
      draftType={props.draftData.draftType}
      canValidate={props.draftData.canValidate}
      onDocumentUpdate={(x: Document) => handleDocumentUpdate(x)}
      isCenterAlign={isCenterAlign}
      updateDocWithoutClosing={() => updateQuote(false)}
      auditLogView={props.auditLogView}
    />
  );
}
