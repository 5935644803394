import {
  DKButton,
  DKDataGrid,
  DKLabel,
  DKSpinner,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useCallback, useEffect, useState } from 'react';
import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  WIP_CONSUMPTION_TYPES
} from '../../../Constants/Constant';
import { IWorkOrder } from '../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import Utility, { deepClone } from '../../../Utility/Utility';
import ConsumptionPopup from './ConsumptionPopup';
import ProductionPopup from './ProductionPopup';
import ConsumeProduceHelper from '../WorkOrder/InnerComponents/ConsumptionProduction/ConsumeProduceHelper';
import { useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';

interface IConsumeAndProduceSummaryPopupProps {
  workOrderData?: IWorkOrder;
  consumptionSummary: any;
  refreshConsumptionSummary: () => void;
  onCancel: () => void;
}

const ConsumeAndProduceSummaryPopup = (
  props: IConsumeAndProduceSummaryPopupProps
) => {
  //common states
  const [updating, setUpdating] = useState(false);

  const [consumptionLineItems, setConsumptionLineItems] = useState<any[]>([]);
  const [showConsumptionPopup, setShowConsumptionPopup] = useState(false);
  const [showProductionPopup, setShowProductionPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);

  const getColumnConfig = () => {
    let columnConfig = deepClone(ConsumeProduceHelper.CONSUMPTION_COLUMNS);

    columnConfig.forEach((conf: any) => {
      switch (conf.key) {
        case 'productName':
          const getName = (obj: any) => {
            return (
              <div
                className="row"
                style={{
                  marginLeft: obj?.rowData?.level * 5
                }}
              >
                <DKLabel
                  text={obj?.rowData?.productName ?? ''}
                  className={`${
                    obj?.rowData?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
                      ? 'fw-b'
                      : ''
                  }`}
                />

                {/* for substitute only */}
                {obj?.rowData?.level === 2 && (
                  <div className="data-grid-badge-color-8 border-radius-l p-h-s ml-s p-v-xs">
                    Subs
                  </div>
                )}
              </div>
            );
          };

          conf.renderer = (obj: any) => {
            return getName(obj);
          };
          return;
        case 'type':
          conf.renderer = (obj: any) => {
            let type = ConsumeProduceHelper.getTypeOfProduct(
              obj?.rowData?.type
            );
            return (
              <div
                className={`row align-items-center justify-content-center ${ConsumeProduceHelper.getTypeClassOfProduct(
                  obj?.rowData?.type
                )} border-radius-s p-v-xs`}
              >
                {type}
              </div>
            );
          };
          return;

        default:
          break;
      }
    });

    columnConfig.push({
      id: 'action',
      key: 'action',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: 160,
      options: []
    } as any);

    return columnConfig;
  };

  const updateConsumptionDetails = useCallback(() => {
    setUpdating(true);
    Promise.resolve(props.consumptionSummary)
      .then((res: any) => {
        setUpdating(false);
        if (!props.workOrderData) return;

        const foundFG = res?.find((item: any) => {
          return (
            item.productCode === props.workOrderData?.productCode &&
            item.wipProcessTypes === WIP_CONSUMPTION_TYPES.PRODUCTION
          );
        });

        const foundFGForScrapByProductSum = res
          ?.filter((item: any) => {
            return (
              item.wipProcessTypes === WIP_CONSUMPTION_TYPES.SCRAP ||
              item.wipProcessTypes === WIP_CONSUMPTION_TYPES.CO_PRODUCT
            );
          })
          .reduce((acc: any, obj: any) => {
            return Number(acc) + Number(obj?.quantity ?? 0);
          }, 0);

        let consumptionRows: any[] = [
          {
            productName: props.workOrderData.productName,
            productCode: props.workOrderData.productCode,
            productCodeToDisplayOnly:
              props.workOrderData?.product?.documentSequenceCode,
            requiredQty: props.workOrderData.manufactureQuantity,
            allocatedQty: 0,
            uom: Utility.getUOMForStockUOMId(
              props.workOrderData?.product?.stockUom
            )?.name,
            stockUom: props.workOrderData?.product?.stockUom,
            documentUOMSchemaDefinition:
              props.workOrderData?.product?.documentUOMSchemaDefinition,
            type: props.workOrderData?.product?.type,
            productType: props.workOrderData?.product?.type,
            level: 0,
            consumedQty: 0,
            producedQty: foundFG?.uomQuantity ?? foundFG?.quantity ?? 0,
            wastageQty: foundFGForScrapByProductSum,
            advancedTracking: props.workOrderData?.product?.advancedTracking,
            isSubstitute: false
          }
        ];
        if (props.workOrderData.workOrderItems) {
          props.workOrderData.workOrderItems.forEach((item: any) => {
            if (item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE) {
              consumptionRows.push({
                productName: item.itemName.name,
                productCode: item.itemName.productId,
                productCodeToDisplayOnly: item.itemName.documentSequenceCode,
                requiredQty: item.requiredQty,
                allocatedQty: ConsumeProduceHelper.getAllocatedQty(item),
                uom: Utility.getUOMForStockUOMId(item.stockUom)?.name,
                stockUom: item.stockUom,
                documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
                type: item?.produceProductType,
                productType: item.itemName?.type,
                level: 1,
                consumedQty:
                  item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
                    ? ConsumeProduceHelper.getConsumedQty(item, res ?? [])
                    : 0,
                producedQty:
                  item?.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE
                    ? ConsumeProduceHelper.getProducedQty(item, res ?? [])
                    : 0,
                wastageQty: ConsumeProduceHelper.getWastageQuantity(res, item),
                advancedTracking: item?.itemName?.advancedTracking,
                isSubstitute: false,
                bomProductSubstitutesDetails:
                  item?.bomProductSubstitutesDetails,
                workOrderItemCode: item?.workOrderItemCode
              });
            }

            // for substitute
            const foundSubsForSameParent = res?.find((resObj: any) => {
              return resObj.parentProductCode === item?.itemName?.productId;
            });
            if (
              ConsumeProduceHelper.isSubstituteAllotted(
                item.bomProductSubstitutesDetails
              ) &&
              item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
            ) {
              item.bomProductSubstitutesDetails?.forEach((item2: any) => {
                if (item2?.warehouseInventoryData) {
                  consumptionRows.push({
                    productName: item2.productName,
                    productCode: item2.productId,
                    productCodeToDisplayOnly: item2?.productDocumentSeqCode,
                    requiredQty: item.requiredQty,
                    allocatedQty:
                      ConsumeProduceHelper.getSubstituteAllocatedQty(
                        item,
                        item2.productId
                      ),
                    uom: Utility.getUOMForStockUOMId(item2.stockUom)?.name,
                    stockUom: item2.stockUom,
                    documentUOMSchemaDefinition:
                      item2?.documentUOMSchemaDefinition,
                    type: item?.produceProductType,
                    productType: item2?.type,
                    level: 2,
                    consumedQty: !Utility.isEmpty(foundSubsForSameParent)
                      ? ConsumeProduceHelper.getSubstituteConsumedQty(
                          item2,
                          res ?? []
                        )
                      : 0,
                    producedQty: 0,
                    wastageQty: !Utility.isEmpty(foundSubsForSameParent)
                      ? ConsumeProduceHelper.getSubstituteWastageQty(
                          item2,
                          res ?? []
                        )
                      : 0,
                    advancedTracking: item2?.advancedTracking,
                    isSubstitute: true,
                    bomProductSubstitutesDetails:
                      item?.bomProductSubstitutesDetails,
                    substituteParentProductCode: item.itemName.productId
                  });
                }
              });
            }
          });
        }
        setConsumptionLineItems(consumptionRows);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  }, [props.consumptionSummary, props.workOrderData]);

  useEffect(() => {
    updateConsumptionDetails();
  }, [updateConsumptionDetails]);

  const getRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    if (
      (lineItem.type === PRODUCT_TYPE.BILL_OF_MATERIALS ||
        lineItem.type !== PRODUCE_PRODUCT_TYPE.NONE) &&
      !lineItem?.isSubstitute &&
      !tenantInfo?.additionalSettings?.LINK_INVENTORY_JOB_CARDS
    ) {
      btns.push({
        title: 'Production',
        className: `p-0 underline text-blue`,
        onClick: (data: any) => {
          setSelectedProduct(data?.rowData);
          setShowProductionPopup(!showProductionPopup);
        }
      });
    }
    if (
      lineItem.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
      lineItem.type === PRODUCE_PRODUCT_TYPE.NONE &&
      !tenantInfo?.additionalSettings?.LINK_INVENTORY_JOB_CARDS
    ) {
      btns.push({
        title: 'Consumption',
        className: `p-0 ${
          Number(lineItem.allocatedQty) !== 0
            ? 'text-blue underline'
            : 'text-gray'
        }`,
        style: {
          cursor:
            Number(lineItem.allocatedQty) !== 0 ? 'pointer' : 'not-allowed'
        },
        onClick: (data: any) => {
          if (Number(lineItem.allocatedQty) !== 0) {
            setSelectedProduct(consumptionLineItems[data?.rowIndex]);
            setShowConsumptionPopup(!showConsumptionPopup);
          }
        }
      });
    }

    return btns;
  };

  const getUpdatedRows = () => {
    return (consumptionLineItems || []).map((lineItem: any) => {
      lineItem = deepClone(lineItem);
      return {
        ...lineItem,
        requiredQty: NumberFormatService.getNumber(lineItem?.requiredQty),
        allocatedQty: NumberFormatService.getNumber(lineItem?.allocatedQty),
        consumedQty: NumberFormatService.getNumber(lineItem?.consumedQty ?? 0),
        producedQty: NumberFormatService.getNumber(lineItem?.producedQty ?? 0),
        wastageQty: NumberFormatService.getNumber(lineItem?.wastageQty),
        rowButtons: getRowButtons(lineItem),
        rowContextMenu: null //getRowContextMenu(lineItem)
      };
    });
  };

  //////////////////////////// Component Methods End ///////////////////////////////////

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-tl rounded-tr">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={`WIP Consumption and Production Summary`}
            className="fw-m fs-l"
          />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Go Back'}
            className="bg-white border-m"
            onClick={() => props.onCancel()}
          />
          {/* <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {}}
          /> */}
        </div>
      </div>
    );
  };

  const getConsumptionGridView = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={getUpdatedRows()}
        columns={getColumnConfig()}
        onRowUpdate={() => {}}
        onRowClick={() => {}}
      />
    );
  };

  const getConsumptionPopupView = () => {
    return (
      <ConsumptionPopup
        onCancel={() => {
          setShowConsumptionPopup(false);
        }}
        onSuccess={() => {
          setShowConsumptionPopup(false);
          props.refreshConsumptionSummary?.();
        }}
        selectedProduct={selectedProduct}
        workOrderItems={props.workOrderData}
      />
    );
  };

  const getProductionPopupView = () => {
    return (
      <ProductionPopup
        onCancel={() => {
          setShowProductionPopup(false);
        }}
        onSuccess={() => {
          setShowProductionPopup(false);
          props.refreshConsumptionSummary?.();
        }}
        selectedProduct={selectedProduct}
        workOrderItems={props.workOrderData}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: '98%',
            width: 1300,
            maxHeight: '93%',
            padding: 0,
            overflow: 'visible'
          }}
        >
          {getHeader()}
          <div
            className="parent-width p-h-xl overflow-y-auto show-scroll-bar"
            style={{ paddingTop: 12 }}
          >
            {/* {getFGNameView()} */}
            {getConsumptionGridView()}
          </div>
        </div>
        {showConsumptionPopup && getConsumptionPopupView()}
        {showProductionPopup && getProductionPopupView()}
      </div>
    </DynamicPopupWrapper>
  );
};

export default ConsumeAndProduceSummaryPopup;
