import { INPUT_TYPE, DKIcons } from 'deskera-ui-library';
import { BOOKS_DATE_FORMAT, STATUS_TYPE } from '../../../Constants/Constant';
import { MORE_ACTION_NAMES } from '../../../Constants/Enum';
import { JE_TYPES } from '../../../Models/JournalEntry';
import DateFormatService from '../../../Services/DateFormat';
import Utility, { getCapitalized } from '../../../Utility/Utility';
import { isTablet } from 'react-device-detect';
import { isTabletView } from '../../../Utility/ViewportSizeUtils';
import { enableMultiViewJournalEntryMethod } from '../../../SharedComponents/MultiViewJournalEntry/enableMultiViewJounalEntry';

export const getColumnConfigType = (type: any) => {
  const UPDATED_INPUT_TYPE = {
    ...INPUT_TYPE,
    USER: 'user'
  };
  switch (type?.toLowerCase()) {
    case UPDATED_INPUT_TYPE.TEXT.toLowerCase():
      return UPDATED_INPUT_TYPE.TEXT.toLowerCase();
    case UPDATED_INPUT_TYPE.NUMBER.toLowerCase():
      return UPDATED_INPUT_TYPE.NUMBER.toLowerCase();
    case UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase():
      return UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase();
    case UPDATED_INPUT_TYPE.DATE.toLowerCase():
      return UPDATED_INPUT_TYPE.DATE.toLowerCase();
    case UPDATED_INPUT_TYPE.USER.toLowerCase():
      return UPDATED_INPUT_TYPE.DROPDOWN.toLowerCase();

    default:
      return UPDATED_INPUT_TYPE.TEXT.toLowerCase();
  }
};

export const getNewItem = (item: any, filteredCF: any) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.label,
    type: getColumnConfigType(filteredCF?.fieldType),
    width: 100,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      data: !Utility.isEmpty(filteredCF?.attributes)
        ? filteredCF?.attributes?.filter(
            (attr: any) => attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
          )
        : [],
      renderer: (index: any, obj: any) => {
        return obj.value;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  };

  return newItem;
};

export const getNewColumn = (item: any) => {
  const newItem = {
    id: item.id,
    key: item.id,
    name: item.system ? getCapitalized(item.label?.toLowerCase()) : item.label,
    type: getColumnConfigType(item?.fieldType),
    width: 100,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    isCustomField: true, //IMP,
    code: item?.code, //IMP
    allowAddOption: false,
    formatter: (obj: any) => {
      return Utility.isObject(obj?.value) ? obj?.value?.value : obj?.value;
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: 'name',
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      data: !Utility.isEmpty(item?.attributes)
        ? item?.attributes?.filter(
            (attr: any) => attr.status?.toUpperCase() === STATUS_TYPE.ACTIVE
          )
        : [],
      renderer: (index: any, obj: any) => {
        return obj.value;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  };

  return newItem;
};

export const getActionsForIndex = (index: any, list: any, permission: any) => {
  let item: any = list[index];
  let actions: any = [];
  let res = Utility.validationForClosedDate({
    ...item,
    jeDate: DateFormatService.getFormattedDateString(
      item.jeDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD'],
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    )
  });

  if (
    permission.hasUpdateAccess &&
    (item.type === JE_TYPES.BANK_TRANSFER ||
      item.type === JE_TYPES.NORMAL_JE ||
      item.type === JE_TYPES.BOOK_SPECIFIC_JE ||
      item.type === JE_TYPES.PARTY_JE) &&
    !res.isDisable &&
    item?.isReverseJe !== true &&
    Utility.isEmpty(item.reversalJeCode)
  ) {
    actions.push(MORE_ACTION_NAMES.EDIT);
  }

  if (
    permission.hasDeleteAccess &&
    (item.type === JE_TYPES.BANK_TRANSFER ||
      item.type === JE_TYPES.NORMAL_JE ||
      item.type === JE_TYPES.BOOK_SPECIFIC_JE ||
      item.type === JE_TYPES.PARTY_JE) &&
    !res.isDisable
  ) {
    actions.push(MORE_ACTION_NAMES.DELETE);
  }

  if (permission.hasPrintAccess) {
    actions.push(MORE_ACTION_NAMES.PRINT);
  }

  if (!isTabletView() && permission.hasDesignAccess) {
    actions.push(MORE_ACTION_NAMES.DESIGN);
  }

  if (permission.hasRecurringAccess && item.type === JE_TYPES.NORMAL_JE) {
    actions.push(MORE_ACTION_NAMES.SET_RECURRING);
  }

  actions.push(MORE_ACTION_NAMES.VIEW_CUSTOM_FIELDS);
  enableMultiViewJournalEntryMethod(() => {
    actions.push(MORE_ACTION_NAMES.VIEW_JOURNAL_ENTRY);
  });
  return actions;
};

export const getActionsIconsForIndex = (
  index: any,
  list: any,
  permission: any
) => {
  let item: any = list[index];
  let icons: any = [];
  let res = Utility.validationForClosedDate({
    ...item,
    jeDate: DateFormatService.getFormattedDateString(
      item.jeDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD'],
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    )
  });

  if (
    permission.hasUpdateAccess &&
    (item.type === JE_TYPES.BANK_TRANSFER ||
      item.type === JE_TYPES.NORMAL_JE ||
      item.type === JE_TYPES.BOOK_SPECIFIC_JE ||
      item.type === JE_TYPES.PARTY_JE) &&
    !res.isDisable &&
    item?.isReverseJe !== true &&
    Utility.isEmpty(item.reversalJeCode)
  ) {
    icons.push(DKIcons.ic_edit);
  }

  if (
    permission.hasDeleteAccess &&
    (item.type === JE_TYPES.BANK_TRANSFER ||
      item.type === JE_TYPES.NORMAL_JE ||
      item.type === JE_TYPES.BOOK_SPECIFIC_JE ||
      item.type === JE_TYPES.PARTY_JE) &&
    !res.isDisable
  ) {
    icons.push(DKIcons.ic_delete);
  }

  if (permission.hasPrintAccess) {
    icons.push(DKIcons.ic_printer);
  }

  if (permission.hasDesignAccess) {
    icons.push(DKIcons.ic_paint);
  }

  if (permission.hasRecurringAccess && item.type === JE_TYPES.NORMAL_JE) {
    icons.push(DKIcons.ic_repeat);
  }

  icons.push(DKIcons.ic_more);
  icons.push(DKIcons.ic_document);
  return icons;
};
