import { TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class GoodsReceiptTableItem {
    lineNumber: string;
    code: string;
    name: string;
    description: string;
    uom: string;
    orderNo: string
    requiredQuantity: string;
    receivedQuantity: string;
    warehouseCode: string;
    warehouseName: string;
    productCustomField: string;
    parentDocumentCode: string;
    parentDocumentDate: string;
    shortExcessRejectedQty: string;
    acceptedQty: string;
    unitPrice: string;
    basicAmt: string;
    taxBreakdown: [];
    amount: string;

    currency: string;
    rawItemValue: any;
    constructor(goodsReceiptItem: any, data: any, currency: string) {
        this.lineNumber = goodsReceiptItem.lineNumber ?? ''
        this.code = goodsReceiptItem.code ? goodsReceiptItem.code : ''
        this.orderNo = goodsReceiptItem.orderNo ? goodsReceiptItem.orderNo : ''
        this.name = this.getProductName(data, goodsReceiptItem)
        this.description = goodsReceiptItem.description
        this.uom = goodsReceiptItem.uom
        this.requiredQuantity = goodsReceiptItem.requiredQuantity ? goodsReceiptItem.requiredQuantity.toString(): '0'
        this.receivedQuantity = goodsReceiptItem.receivedQuantity ? goodsReceiptItem.receivedQuantity.toString() : '0'
        this.warehouseCode = goodsReceiptItem.warehouseCode
        this.warehouseName = goodsReceiptItem.warehouseName
        this.parentDocumentCode = goodsReceiptItem.parentDocumentCode;
        this.parentDocumentDate = goodsReceiptItem.parentDocumentDate;
        this.shortExcessRejectedQty = goodsReceiptItem.shortExcessRejectedQty
        this.acceptedQty = goodsReceiptItem.acceptedQty
        this.unitPrice = goodsReceiptItem.unitPrice
        this.basicAmt = goodsReceiptItem.basicAmt
        this.taxBreakdown = goodsReceiptItem.taxBreakdown
        this.amount = goodsReceiptItem.amount
        this.productCustomField = ''
        this.currency = currency
        this.rawItemValue = goodsReceiptItem
    }

    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }


    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    val += element.label + ': <b>' + field.value + '</b>\n'
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            val += element.label + ': <b>' + field.value + '</b>\n'
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getCharges() {
        const charges: string[] = [];
        if (Array.isArray(this.taxBreakdown)) {
            this.taxBreakdown.forEach((tax: any) => {
                if (!Utility.isEmpty(tax.amount) && tax.amount !== 0) {
                    charges.push(`${getLocalisedText(tax.name)} @ ${tax.rate}%  ${Utility.toCurrencyFormat(tax.amount, this.currency)}`)
                }
            });
        }

        return charges.join("\n")
    }

    getAmount() {
        let amount = 0;
        if (!Utility.isEmpty(this.basicAmt)) {
            amount += parseFloat(this.basicAmt)
        }

        if (Array.isArray(this.taxBreakdown)) {
            this.taxBreakdown.forEach((tax: any) => {
                if (!Utility.isEmpty(tax.amount)) {
                    amount += tax.amount
                }
            });
        }

        return amount
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.lineNumber:
                return this.lineNumber
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.orderNo:
                return this.orderNo ? this.orderNo : ''
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.description:
                return getLocalisedText(this.description)
            case TableColumnPopupType.uom:
                return this.uom ? this.uom : ''
            case TableColumnPopupType.requiredQuantity:
                return this.requiredQuantity ? this.requiredQuantity : ''
            case TableColumnPopupType.receivedQuantity:
                return this.receivedQuantity ? this.receivedQuantity : ''
            case TableColumnPopupType.warehouseCode:
                return this.warehouseCode ? this.warehouseCode : ''
            case TableColumnPopupType.warehouseName:
                return this.warehouseName ? this.warehouseName : ''
            case TableColumnPopupType.POBillNoDate:
                let POBillNoDate = "";
                if (!Utility.isEmpty(this.parentDocumentCode)) {
                    POBillNoDate += `${this.parentDocumentCode}\n`
                }
                if (!Utility.isEmpty(this.parentDocumentDate)) {
                    POBillNoDate += `${this.parentDocumentDate}`
                }
                return POBillNoDate
            case TableColumnPopupType.shortExcessRejectedQty:
                return this.shortExcessRejectedQty
            case TableColumnPopupType.acceptedQty:
                return this.acceptedQty
            case TableColumnPopupType.unitPrice:
                return this.unitPrice ? Utility.toCurrencyFormat(this.unitPrice, this.currency) : ""
            case TableColumnPopupType.basicAmt:
                return this.basicAmt ? Utility.toCurrencyFormat(this.basicAmt, this.currency) : ""
            case TableColumnPopupType.charges:
                return this.getCharges()
            case TableColumnPopupType.amount:
                return Utility.toCurrencyFormat(this.getAmount(), this.currency)
            case TableColumnPopupType.productCustomField:
                if (element.code !== undefined) {
                    var value = ''
                    var field = this.getProductCustomFieldByCode(element.code)
                    if (field !== undefined) {
                        value = field.value
                    }
                    return value
                }
                return this.getCombineProductCustomField()
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            getLocalisedText(this.code),
            this.orderNo,
            getLocalisedText(this.name),
            getLocalisedText(this.description),
            this.uom,
            this.requiredQuantity,
            this.receivedQuantity,
            this.warehouseCode,
            this.warehouseName
        ]
    }
}
