import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GranularPermissionsHelper, parseAllAvailAbleModulePermissions } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { API_STATUS, APP_NAME, MRP_APP_NAME } from '../../Constants/Constant';
import { APPS, MRP_ROLES_TO_FILTER } from '../../Constants/Permission';
import AuthService from '../../Services/Auth';
import PermissionService from '../../Services/Permission';
import TenantService from '../../Services/Tenant';
import Utility from '../../Utility/Utility';

const initialState: any = {
  userData: {} as any,
  rolesData: {} as any,
  usersRolesData: {} as any
};

export const fetchPermissionMetadata = createAsyncThunk(
  'permissionMetadata',
  async () => {
    let apps = [...APPS];
    if (!Utility.isMRP()) {
      apps = apps?.filter((app: any) => app !== MRP_APP_NAME);
    }
    const response = await PermissionService.getPermissionMetadata(apps);
    return response;
  }
);

export const fetchTenantsUserPermissionInfo = createAsyncThunk(
  'tenantsPermissionsUsers',
  async () => {
    let payload = [AuthService.getUserID()];
    const response = await TenantService.getPermissions(payload);
    return response;
  }
);

export const fetchAllUsersList = createAsyncThunk('allUsersList', async () => {
  const response = await TenantService.getAllUsers();
  return response;
});

export const fetchListofInvitedUsers = createAsyncThunk(
  'invitedUsersList',
  async () => {
    const response = await PermissionService.getListofInvitedUsers();
    return response;
  }
);

export const fetchAllUserRoles = createAsyncThunk('usersRoles', async () => {
  const response = await PermissionService.getAllUserRoles();
  return response;
});

export const deleteRole = createAsyncThunk(
  'deleteRole',
  async (roleId: any) => {
    const response = await PermissionService.deleteRole(roleId);
    return response;
  }
);

export const updateUserPermission = createAsyncThunk(
  'updateUserPermission',
  async (payload: any) => {
    const response = await PermissionService.updateUserPermission(payload);
    return response;
  }
);

export const deleteUser = createAsyncThunk(
  'deleteUser',
  async (userId: any) => {
    const response = await PermissionService.deleteUser(userId);
    return response;
  }
);

export const createRole = createAsyncThunk('createRole', async (data: any) => {
  const response = await PermissionService.createRole(data);
  return response;
});

export const PermissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionMetadata.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchPermissionMetadata.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        const newMetadata = GranularPermissionsHelper.getSequencedMetadata(
          action.payload
        );
        state.allAvailablePermissions = parseAllAvailAbleModulePermissions(newMetadata);
        state.permissionMetadata = newMetadata;
      })
      .addCase(fetchTenantsUserPermissionInfo.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchTenantsUserPermissionInfo.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        GranularPermissionsHelper.permissionInfo = action.payload; //keeping a copy for operation in metaDAta parser
        state.permissionInfo = action.payload;
      })
      .addCase(fetchListofInvitedUsers.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchListofInvitedUsers.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.invitedUsersList = action.payload;
      })
      .addCase(fetchAllUsersList.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllUsersList.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.allUsersList = action.payload;
      })
      .addCase(fetchAllUserRoles.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAllUserRoles.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        state.usersRolesData = action.payload;
      });
  }
});

export const selectUserRolesData = (state: any) =>
  state.permissions.usersRolesData;

export const selectCurrentUsersRole = (state: any) =>
  state.permissions.currentUsersRole || {};

export const selectRoleById = (state: any, id: string) =>
  state.permissions.roles.find((role: any) => role._id === id);

export const selectCurrentUsersPermission = (state: any) =>
  state.permissions?.currentUsersRole?.permissions?.data || [];

export const selectPermissionsByModule = (state: any, module: string) => {
  return (
    state.permissions?.currentUserModuleWisePermissions?.[
      module.toLowerCase()
    ] || {}
  );
};

export const selectGranularPermissions = (state: any) => {
  return state.permissions.permissions.data;
};

export const selectUsersList = (state: any) => {
  let invitedUserList = state.permissions.invitedUsersList?.invites || [];
  let userData = state.permissions.allUsersList?.content || [];
  const invitedUsersOnly = invitedUserList?.filter((user: any) => {
    return user.status === 'SENT';
  });
  const newList: any[] = [...invitedUsersOnly, ...userData];
  return newList;
};

export const selectInvitedUsersList = (state: any) => {
  return state.permissions.invitedUsersList?.invites || [];
};

export const selectRoles = (state: any) => {
  return (
    state.permissions?.permissionMetadata?.find(
      (module: any) => module.appName === APP_NAME
    )?.roleGroupDto || []
  );
};

//** This selector selects EPR as well as MRP Roles */
export const selectAllRoles = (state: any) => {
  const roles:any[] = [];
  state.permissions?.permissionMetadata?.forEach((app: any) => {
    if (app.appName === APP_NAME) {
      roles.push(...(app.roleGroupDto || []));
    }
    if (app.appName === MRP_APP_NAME && Utility.isMRP()) {
      const mrpRoles = app.roleGroupDto?.filter((role: any) => !MRP_ROLES_TO_FILTER.includes(role.shortCode));
      roles.push(...(mrpRoles || []));
    }
  });
  return roles;
}

export const selectPermissionMetadata = (state: any) => {
  return state.permissions.permissionMetadata;
};

export const selectPermissionInfo = (state: any) => {
  return state.permissions.permissionInfo;
};

export const {} = PermissionsSlice.actions;

export default PermissionsSlice.reducer;
