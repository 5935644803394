import { useEffect, useRef, useState } from 'react';
import {
  getCFColumnConfig,
  getCFColumnKey,
  PRODUCT_BY_VENDOR_COLUMN_CONFIG
} from './PreferredVendorHelper';
import DataGridHolder from '../../SharedComponents/DataGridHolder';
import { showAlert } from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';
import {
  COLUMN_CODE,
  TABLE_DISPLAY_NAME,
  TABLES
} from '../../Constants/TableConstants';
import SideBarService from '../../Services/sidebar';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Contacts/Details';
import { IState, UpdateCallBacksRefType } from '../../Models/Interfaces';
import { isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import ProductService from '../../Services/Product';
import {
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../Constants/Constant';
import CustomFieldService from '../../Services/CustomField';
import Utility from '../../Utility/Utility';

function ProductsByVendor({ contact, isPopupView, passingInteraction }: any) {
  const initialState: IState = {
    columnData: [],
    filter: [],
    rowData: [],
    originalData: []
  };

  const COLUMN = COLUMN_CODE.PRODUCT_BY_VENDOR;

  const { t } = useTranslation();

  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const [columns, setColumns] = useState<any[]>(
    PRODUCT_BY_VENDOR_COLUMN_CONFIG
  );
  const [rowData, setRowData] = useState<any[]>([]);
  const [preferredVendors, setPreferredVendors] = useState(initialState);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    getPreferredVendorCustomFields();
    getProductsByVendor();

    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
      setPreferredVendors(initialState);
    };
  }, []);

  useEffect(() => {
    const passingData = {
      columnData: columns,
      filter: [],
      rowData: rowData,
      originalData: rowData
    };

    setPreferredVendors(passingData);
  }, [columns, rowData]);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const getPreferredVendorCustomFields = async () => {
    const customColumns: any[] = [];
    try {
      const params = {
        status: STATUS_TYPE.ACTIVE,
        module: MODULES_NAME.PREFERRED_VENDOR,
        limit: 1000
      };
      const response = await CustomFieldService.getCustomFields(params, true);

      if (!Utility.isEmpty(response) && Array.isArray(response.content)) {
        response.content.forEach((customField: any) => {
          const column = getCFColumnConfig(customField);
          customColumns.push(column);
        });
      }
    } catch (error) {
      showAlert(
        `${t('PREFERRED_VENDOR.ERROR')}!`,
        t('PREFERRED_VENDOR.CF_FETCH_FAILED')
      );
    }

    if (customColumns.length > 0) {
      setColumns([...columns, ...customColumns]);
    }
  };

  const getProductsByVendor = async () => {
    setUpdating(true);
    try {
      const response = await ProductService.getProductsByVendor([contact.code]);
      if (Array.isArray(response)) {
        const rowData = getRowData(response);
        setRowData(rowData);
      } else {
        setRowData([]);
      }
    } catch (error) {
      setRowData([]);

      showAlert(
        `${t('PREFERRED_VENDOR.ERROR')}!`,
        t('PREFERRED_VENDOR.PRODUCTS_FETCH_FAILED')
      );
    }
    setUpdating(false);
  };

  const getRowData = (products: any[] = []) => {
    const rowData: any[] = [];
    products.forEach((product) => {
      const customFields: any = {};

      if (Array.isArray(product.customField)) {
        product.customField.forEach((customField: any) => {
          customFields[getCFColumnKey(customField)] = customField.value;
        });
      }

      const row = {
        ...product,
        ...customFields,
        currency: contact.currencyCode,
        [COLUMN.PRODUCT_CODE]: product.productDocSeqCode,
        [COLUMN.PRODUCT_NAME]: product.productName,
        [COLUMN.SUPPLIER_PART_NO]: product.supplierPartNumber,
        [COLUMN.SUPPLIER_PART_NAME]: product.supplierPartName,
        [COLUMN.SUPPLIER_DESCRIPTION]: product.supplierDescription,
        [COLUMN.LAST_PRICE]: product.lastPrice,
        [COLUMN.AVG_PRICE]: product.avgPrice,
        [COLUMN.SUPPLIER_PRICE]: product.supplierPrice,
        [COLUMN.LEAD_TIME]: product.leadTime
      };

      rowData.push(row);
    });

    return rowData;
  };
  const onRowSelect = (data: any) => {
    if (isPopupView && passingInteraction) {
      passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CONFIRM_PREFERRED_VENDOR_PRODUCT,
        data: data
      });
    }
  };

  return (
    <div className="column parent-width flex-1 parent-height">
      <div className="column parent-width mb-s position-relative flex-1">
        <DataGridHolder
          headerButtons={[]}
          tableName={TABLES.PRODUCTS_BY_VENDOR}
          displayTableName={TABLE_DISPLAY_NAME[TABLES.PRODUCTS_BY_VENDOR]}
          gridIcon="📦"
          needBoldTheme={isDesktop}
          allowRowEdit={false}
          allowColumnEdit={false}
          allowRowAdd={false}
          allowColumnAdd={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowSearch={false}
          allowFilter={false}
          updating={updating}
          filter={[]}
          refresh={true}
          allowDataExport={false}
          gridData={preferredVendors}
          actionColumnWidth={150}
          allowBulkOperation={isPopupView ? true : false}
          width={
            isPopupView
              ? '100%'
              : SideBarService.getContainerWidth() - CONTACT_LEFT_PANEL_WIDTH
          }
          extraWidth={CONTACT_LEFT_PANEL_WIDTH}
          showHeader={!isPopupView}
          showContextMenu={!isPopupView}
          onRowSelect={onRowSelect}
        />
      </div>
    </div>
  );
}

export default ProductsByVendor;
