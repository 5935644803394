import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  RECEIVED_GOODS_STATUS
} from '../../Constants/Constant';
import { Bill } from '../../Models/Bill';
import { FABill } from '../../Models/FABill';
import {
  defaultAdditionalChargesObject,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const billEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Bill Date',
    action: 'billDate'
  },
  {
    hidden: false,
    text: 'Bill Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Due Date',
    action: 'dueDate'
  },
  {
    hidden: false,
    text: 'Due Amount',
    action: 'dueAmount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Bill Number',
    action: 'BillNo'
  }
];

export const setItemsFromPurchaseInvoiceItems = (doc: any) => {
  const document = {
    ...doc,
    items: doc.purchaseInvoiceProducts
      ? doc.purchaseInvoiceProducts?.map((item: any) => {
          return {
            ...item,
            taxSystem: getTenantTaxSystem(),
            unitPriceGstInclusive: doc.unitPriceGstInclusive,
            documentSequenceCode: item?.product?.documentSequenceCode
          };
        })
      : [],
    contact: !Utility.isEmpty(doc.contactDto) ? doc.contactDto : doc.contact,
    documentType: DOC_TYPE.BILL,
    documentDate: doc.purchaseInvoiceDate,
    validTillDate: doc.purchaseInvoiceDueDate,
    fulfillmentDate: doc.receiveByDate,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject },
    buyerReferenceNumber: doc?.poNumber?.split(':')[1] ?? ''
  };
  return document;
};

export const getUpdatedBillObject = (props: Bill) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.BILL,
    currencyCode: props.currency,
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseInvoiceCode
    ),
    receivedComplete: props.receivedComplete || false,
    documentDate: Utility.getValue(
      props.documentDate,
      props.purchaseInvoiceDate
    ),
    validTillDate: Utility.getValue(
      props.validTillDate,
      props.purchaseInvoiceDueDate
    ),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    currency: props.currency,
    receiveGoodsStatus:
      props.receiptStatus ||
      props.receiveGoodsStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,

    buyType: Utility.getValue(props.buyType, props.purchaseInvoiceType),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    gstin: props.gstin,
    dropShip: props.dropShip ? props.dropShip : false,
    placeOfSupply: props.placeOfSupply,
    isCreditable: props.isCreditable,
    isPartialBill: props.isPartialBill || props.isPartialInvoice,
    taxInvoiceNo: props.taxInvoiceNo,
    orderReference: props?.poNumber,
    customerType: props.customerType,
    vendorType: props.vendorType,
    openingInvoice: props.openingInvoice,
    customField: props.customField,
    paymentStatus: props.paymentStatus,
    whtRate: props.whtRate && props.whtRate !== null ? props.whtRate : 0,
    tdsInfoIndia: props.tdsInfoIndia,
    tdsProcessedFlag: props.tdsProcessedFlag,
    items: props.purchaseInvoiceProducts?.map((item) => {
      return {
        ...item,
        documentSequenceCode: item?.product?.documentSequenceCode,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    })
  };
  return updatedObj;
};
export const getUpdatedFABillObject = (props: FABill) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.FA_BILL,
    currencyCode: props.currency,
    documentCode: Utility.getValue(
      props.documentCode,
      props.purchaseInvoiceCode
    ),
    orderReference: props?.poNumber,
    receivedComplete: props.receivedComplete || false,
    documentDate: Utility.getValue(
      props.documentDate,
      props.purchaseInvoiceDate
    ),
    validTillDate: Utility.getValue(
      props.validTillDate,
      props.purchaseInvoiceDueDate
    ),
    fulfillmentDate: Utility.getValue(
      props.fulfillmentDate,
      props.receiveByDate
    ),
    currency: props.currency,
    receiveGoodsStatus:
      props.receiptStatus ||
      props.receiveGoodsStatus ||
      RECEIVED_GOODS_STATUS.NOT_RECEIVED,

    buyType: 'ASSET',
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    gstin: props.gstin,
    dropShip: props.dropShip ? props.dropShip : false,
    placeOfSupply: props.placeOfSupply,
    isCreditable: props.isCreditable,
    isPartialBill: props.isPartialBill || props.isPartialInvoice,
    taxInvoiceNo: props.taxInvoiceNo,
    customerType: props.customerType,
    vendorType: props.vendorType,
    openingInvoice: props.openingInvoice,
    customField: props.customField,
    paymentStatus: props.paymentStatus,
    whtRate: props.whtRate && props.whtRate !== null ? props.whtRate : 0,
    tdsInfoIndia: props.tdsInfoIndia,
    tdsProcessedFlag: props.tdsProcessedFlag,
    items: props.purchaseInvoiceAssets?.map((item: any) => {
      item.fixedAssetGroup = {
        id: item.assetGroupId,
        name: item.assetGroupName
      };
      //revisit
      if (item.taxDetails.length > 0) {
        item.tax = {
          id: item.taxDetails[0].taxId,
          name: item.taxDetails[0].taxName,
          taxCode: item.taxDetails[0].taxCode,
          percent: item.taxDetails[0].taxRate,
          code: item.taxDetails[0].taxSeqCode,
          type: item.taxDetails[0].taxType ? item.taxDetails[0].taxType : 'BOTH'
        };
      }

      item.productQuantity = item.quantity;
      return {
        ...item,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.purchaseOrderItemCode,
          item.purchaseInvoiceItemCode
        )
      };
    })
  };
  return updatedObj;
};
