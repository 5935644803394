import { DOC_TYPE, RECORD_SAVED_EVENT_DOC_TYPE } from "../../Constants/Constant";

export const commonCustomEvent = {
  on: (ev: string, cb: (data: any) => void, useDirectCallback?: boolean) => {
    if (useDirectCallback) {
      document.addEventListener(ev, cb);
    } else {
      document.addEventListener(ev, (data: any) => cb(data.detail));
    }
  },
  dispatch: (ev: string, data: any) => {
    document.dispatchEvent(new CustomEvent(ev, { detail: data }));
  },
  remove: (ev: string, cb: (data: any) => void) => {
    document.removeEventListener(ev, cb);
  }
};

export const COMMON_EVENTS = {
  ADD_NEW_REC: 'addNewRec',
  NAVIGATION_TRIGGERED: 'onNavigationTriggered',
  SUBSCRIPTION_CHECK: 'onAuthorizationCheckStateChanged',
  ERROR_OCCURRED: 'onErrorOccurred',
  RECORD_SAVED: 'onRecordSaved',
  RUN_REPORT: 'runReport'
};

export interface IRecordSavedEventData { 
  id: string | null,
  type: RECORD_SAVED_EVENT_DOC_TYPE,
  linkedDocId?: string,
  linkedDocType: DOC_TYPE | RECORD_SAVED_EVENT_DOC_TYPE,
  isEdit: boolean
};