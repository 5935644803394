import {
  DKButton,
  DKCheckMark,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiConstants from '../../Constants/ApiConstants';
import {
  ADDITIONAL_CHARGES_TYPE,
  COUNTRY_CODES,
  IMPORT_MODULE,
  INPUT_VIEW_DIRECTION,
  STATUS_TYPE,
  TAX_TYPES
} from '../../Constants/Constant';
import { ACCOUNT_GROUPS, PRODUCT_OFFERING_TYPE } from '../../Constants/Enum';
import { UCQ_OPTIONS } from '../../Constants/StaticData';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  appendAccountInStore,
  selectedAccounts
} from '../../Redux/Slices/AccountsSlice';
import { fetchAllAdditionalCharge } from '../../Redux/Slices/AdditionalChargesSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  appendHSNInStore,
  appendSACInStore,
  selectHSNCodes,
  selectSacCodes,
  selectTaxes
} from '../../Redux/Slices/CommonDataSlice';
import AccountsService, { AccountAPIConfig } from '../../Services/Accounts';
import AdditionalChargesService from '../../Services/AdditionalCharges';
import NumberFormatService from '../../Services/NumberFormat';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import Utility from '../../Utility/Utility';
import { selectImportCustomFormats } from '../../Redux/Slices/ImportSlice';

export default function AddAdditionalCharges(props: any) {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectedAccounts);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const taxes = useAppSelector(selectTaxes);
  const hsnCodes = useAppSelector(selectHSNCodes);
  const sacCodes = useAppSelector(selectSacCodes);
  const { t, i18n } = useTranslation();
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [formData, setFormData] = useState<any>(
    Utility.isEmpty(props.object)
      ? {
          offeringType: 'GOODS',
          applyTo: 'BOTH',
          chargeApplicableOn: 'TOTAL',
          percentageValue: 0,
          chargeValue: 0,
          description: '',
          incomeAccountCode: '',
          expenseAccountCode: '',
          indiaProperties: {
            HSN: '',
            UQC: '',
            SAC: ''
          },
          isPercent: false,
          isTaxable: false,
          name: '',
          purchaseTaxCode: '',
          salesTaxCode: '',
          includeInReport: true
        }
      : props.object
  );

  const [canValidate, setCanValidate] = useState(false);
  const [accountsList, setAccountsList] = useState<any[]>([]);
  const docKeys = [
    { key: 'SUBTOTAL', value: 'Subtotal' },
    { key: 'TOTAL', value: 'Total' }
  ];
  const [isFocused, setIsFocused] = useState<boolean>();

  const customImportFormats = useAppSelector(selectImportCustomFormats);
  const [allowZeroValueAdditionaCharge, setAllowZeroValueAdditionaCharge] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setFormData(null);
    };
  }, []);

  useEffect(() => {
    let filtered = accounts?.content?.filter(
      (acc: any) => acc.status === STATUS_TYPE.ACTIVE
    );
    setAccountsList(filtered);
    return () => {};
  }, [accounts]);

  useEffect(() => {
    if (Array.isArray(customImportFormats) && customImportFormats.length > 0) {
      const idx = customImportFormats.findIndex((cf) => cf.module === IMPORT_MODULE.BILL || cf.module === IMPORT_MODULE.INVOICE);
      setAllowZeroValueAdditionaCharge(idx !== -1);
    }
  }, [customImportFormats]);

  const isIndia = () => {
    return tenantInfo?.country.toLowerCase() == 'in';
  };

  const validateForm = () => {
    if (Utility.isEmpty(formData?.name?.trim())) {
      return false;
    }
    if (Utility.isEmpty(formData.applyTo)) {
      return false;
    }
    if (formData?.isPercent) {
      if (
        formData?.percentageValue === '' ||
        parseFloat(formData?.percentageValue) > 100 ||
        parseFloat(formData?.percentageValue) < 0
      ) {
        return false;
      }
      if (formData?.percentageValue > 100) {
        showAlert('Error', 'Percentage value cannot exceed 100.');
        return false;
      }
    } else {
      if (!props.isDiscount && allowZeroValueAdditionaCharge) {
        if (formData?.chargeValue === '') {
          return false;
        }  
      } else {
        if (formData?.chargeValue === '' || parseFloat(formData?.chargeValue) === 0) {
          return false;
        }
      }
      if (props.isItemDiscount && props.isDiscount) {
        if (parseFloat(formData?.chargeValue) < 0) {
          return false;
        }
      }
    }

    if (props.isDiscount) {
      if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
        if (
          Utility.isEmpty(formData?.expenseAccountCode) ||
          Utility.isEmpty(formData?.incomeAccountCode)
        ) {
          return false;
        }
      } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
        if (Utility.isEmpty(formData?.expenseAccountCode)) {
          return false;
        }
      } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
        if (Utility.isEmpty(formData?.incomeAccountCode)) {
          return false;
        }
      }
    } else {
      if (isIndia()) {
        if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
          if (
            Utility.isEmpty(formData?.incomeAccountCode) ||
            Utility.isEmpty(formData?.expenseAccountCode)
          ) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
          if (Utility.isEmpty(formData?.incomeAccountCode)) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
          if (Utility.isEmpty(formData?.expenseAccountCode)) {
            return false;
          }
        }
        if (formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS) {
          if (Utility.isEmpty(formData?.indiaProperties.HSN)) {
            return false;
          }
        }
        if (formData?.offeringType === PRODUCT_OFFERING_TYPE.SERVICES) {
          if (Utility.isEmpty(formData?.indiaProperties.SAC)) {
            return false;
          }
        }
        if (Utility.isEmpty(formData?.indiaProperties.UQC)) {
          return false;
        }
        if (formData?.isTaxable) {
          if (
            Utility.isEmpty(formData?.purchaseTaxCode) ||
            Utility.isEmpty(formData?.salesTaxCode)
          ) {
            return false;
          }
        }
      } else if (Utility.isUSorg()) {
        if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
          if (
            Utility.isEmpty(formData?.incomeAccountCode) ||
            Utility.isEmpty(formData?.expenseAccountCode)
          ) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
          if (Utility.isEmpty(formData?.incomeAccountCode)) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
          if (Utility.isEmpty(formData?.expenseAccountCode)) {
            return false;
          }
        }
      } else if (Utility.isSGOrg()) {
        if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
          if (
            Utility.isEmpty(formData?.incomeAccountCode) ||
            Utility.isEmpty(formData?.expenseAccountCode) ||
            Utility.isEmpty(formData?.salesTaxCode) ||
            Utility.isEmpty(formData?.purchaseTaxCode)
          ) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
          if (
            Utility.isEmpty(formData?.incomeAccountCode) ||
            Utility.isEmpty(formData?.salesTaxCode)
          ) {
            return false;
          }
        } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
          if (
            Utility.isEmpty(formData?.expenseAccountCode) ||
            Utility.isEmpty(formData?.purchaseTaxCode)
          ) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const getFormData = () => {
    if (props.isDiscount) {
      let data: any = {
        name: formData.name,
        description: formData.description,
        applyTo: formData.applyTo,
        percentageValue: formData.percentageValue,
        expenseAccountCode: formData.expenseAccountCode,
        incomeAccountCode: formData.incomeAccountCode,
        chargeApplicableOn: formData.chargeApplicableOn,
        isPercent: formData.isPercent,
        chargeValue: formData.chargeValue,
        includeInReport: true,
        active: true,
        isDiscount: true
      };

      if (props.isItemDiscount) {
        data = { ...data, isItemDiscount: true };
      }

      if (formData?.id) {
        data.id = formData.id;
      }

      return data;
    } else {
      return formData;
    }
  };

  const updateData = () => {
    setCanValidate(true);
    if (!validateForm()) {
      return;
    }
    const formData = getFormData();
    setApiCallInProgress(true);
    AdditionalChargesService.updateAdditionalCharge(formData)
      .then((res: any) => {
        setApiCallInProgress(false);
        dispatch(fetchAllAdditionalCharge());
        if (props.onUpdated) {
          props.onUpdated(res);
        }

        props.onClose();
      })
      .catch((err: any) => {
        console.log(
          err,
          `err creating ${!props.isDiscount ? 'Additional charge' : 'discount'}`
        );
        setApiCallInProgress(false);
      });
  };
  const createData = () => {
    setCanValidate(true);
    if (!validateForm()) {
      return;
    }
    const formData = getFormData();
    setApiCallInProgress(true);
    AdditionalChargesService.createAdditionalCharge(formData)
      .then((res: any) => {
        setApiCallInProgress(false);
        dispatch(fetchAllAdditionalCharge());
        props.onCreated(res);
        props.onClose();
      })
      .catch((err: any) => {
        console.log(
          err,
          `err creating ${!props.isDiscount ? 'Additional charge' : 'discount'}`
        );
        setApiCallInProgress(false);
      });
  };
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text={
              formData?.id
                ? `Update ${
                    !props.isDiscount ? 'Additional Charge' : 'Discount'
                  }`
                : `New ${!props.isDiscount ? 'Additional Charge' : 'Discount'}`
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              props.onClose();
            }}
          />
          <div
            className={`row ${
              apiCallInProgress ? 'border-radius-m border-m' : ''
            }`}
          >
            <DKButton
              title={formData?.id ? 'Update' : 'Save'}
              onClick={() => (formData?.id ? updateData() : createData())}
              className={`${
                apiCallInProgress
                  ? 'border-radius-none text-gray'
                  : 'bg-app text-white'
              }`}
            />
            {apiCallInProgress && (
              <DKSpinner iconClassName="ic-s" className="column pl-0 pr-s" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const getAccountObjectForCode = (code: string) => {
    let account = accountsList?.find((item: any) => code === item.code);
    if (Utility.isEmpty(account)) {
      return null;
    } else {
      return account;
    }
  };

  const getAccountSelectionField = (
    title: string,
    key: string,
    accountGroupsToFilter: ACCOUNT_GROUPS[],
    required = false
  ) => {
    let value = Utility.isEmpty(formData) ? null : formData[key];
    return (
      <DKInput
        title={title}
        required={required}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={getAccountObjectForCode(value)}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(account: any) => {
          dispatch(appendAccountInStore(account));
          setFormData({ ...formData, [key]: account.code });
        }}
        readOnly={false}
        dropdownConfig={{
          style: { minWidth: 230 },
          className: 'shadow-m',
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          canEdit: false,
          canDelete: false,
          data: Utility.isEmpty(accountsList)
            ? []
            : accountsList?.filter((item: any) =>
                accountGroupsToFilter.includes(item.accountGroup)
              ),
          renderer: (index: any, account: any) => {
            return account.name;
          },
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              const config: AccountAPIConfig = {
                ...AccountsService.apiConfig,
                Page: 0,
                SearchTerm: searchValue,
                Limit: Utility.isEmpty(searchValue) ? 50 : 10,
                Query: `status=ACTIVE`
              };
              AccountsService.apiConfig = config;
              return (
                ApiConstants.URL.BASE + AccountsService.getAccountEndPoint()
              );
            },
            dataParser: (response: any) => {
              let filtered = props.isDiscount
                ? response?.content?.filter(
                    (acc: any) => acc.status === 'ACTIVE'
                  ) || []
                : response?.content?.filter(
                    (acc: any) =>
                      acc.status === 'ACTIVE' &&
                      accountGroupsToFilter.includes(acc.accountGroup)
                  ) || [];
              return filtered;
            },
            debounceTime: 300
          }
        }}
      />
    );
  };

  const getSelectedHSNSACCode = () => {
    if (formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS) {
      let result = hsnCodes?.find(
        (item: any) => item?.code === formData?.indiaProperties?.HSN
      );
      if (Utility.isEmpty(result)) {
        return null;
      } else {
        return result;
      }
    } else if (formData?.offeringType === PRODUCT_OFFERING_TYPE.SERVICES) {
      let result = sacCodes?.find(
        (item: any) => item?.code === formData?.indiaProperties?.SAC
      );
      if (Utility.isEmpty(result)) {
        return null;
      } else {
        return result;
      }
    }
    return;
  };

  const setTaxCodeValueInFormData = (code: string) => {
    if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
      setFormData({
        ...formData,
        purchaseTaxCode: code,
        salesTaxCode: code
      });
    } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
      setFormData({
        ...formData,
        salesTaxCode: code
      });
    } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
      setFormData({
        ...formData,
        purchaseTaxCode: code
      });
    }
  };

  const addHSNORSACInListIfNotExist = (object: any) => {
    if (formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS) {
      dispatch(appendHSNInStore(object));
    } else if (formData?.offeringType === PRODUCT_OFFERING_TYPE.SERVICES) {
      dispatch(appendSACInStore(object));
    }
  };

  const getHSNSACCodeField = (title: string, data: any[]) => {
    return (
      <DKInput
        title={title}
        required={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={getSelectedHSNSACCode()}
        formatter={(obj: any) => {
          return obj.code;
        }}
        onChange={(obj: any) => {
          addHSNORSACInListIfNotExist(obj);
          let taxObject = taxes?.find(
            (item: any) => item.percent === obj.gstRate
          );
          if (formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS) {
            setFormData({
              ...formData,
              indiaProperties: { ...formData?.indiaProperties, HSN: obj.code },
              purchaseTaxCode: taxObject.code,
              salesTaxCode: taxObject.code
            });
          } else if (
            formData?.offeringType === PRODUCT_OFFERING_TYPE.SERVICES
          ) {
            setFormData({
              ...formData,
              indiaProperties: { ...formData?.indiaProperties, SAC: obj.code },
              purchaseTaxCode: taxObject.code,
              salesTaxCode: taxObject.code
            });
          }
        }}
        readOnly={false}
        options={data}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'code',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              if (formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS) {
                return (
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.ACCOUNTS.HSN +
                  `?search=${searchValue}&limit=50`
                );
              } else {
                return (
                  ApiConstants.URL.BASE +
                  ApiConstants.URL.ACCOUNTS.SAC +
                  `?search=${searchValue}&limit=50`
                );
              }
            },
            dataParser: (response: any) => {
              return response?.content || [];
            },
            debounceTime: 300
          },
          data: Utility.isEmpty(data) ? [] : data,
          renderer: (index: any, obj: any) => {
            return obj.code;
          }
        }}
      />
    );
  };

  const getSelectedTaxValueForCode = () => {
    let code = '';
    if (
      formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
      formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
    ) {
      code = formData?.salesTaxCode;
    } else if (
      formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
      formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
    ) {
      code = formData?.purchaseTaxCode;
    }
    let taxObject = taxes?.find((item: any) => item.code === code);
    if (Utility.isEmpty(taxObject)) {
      return null;
    } else {
      return taxObject;
    }
  };

  const getTaxField = () => {
    return (
      <DKInput
        title={'Tax Rate'}
        required={formData?.isTaxable}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={getSelectedTaxValueForCode()}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(tax: any) => {
          setTaxCodeValueInFormData(tax.code);
        }}
        readOnly={false}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'name',
          data: Utility.isEmpty(taxes)
            ? []
            : taxes.filter(
                (item: any) => item.status.toLowerCase() === STATUS_TYPE.active
              ),
          renderer: (index: any, tax: any) => {
            return tax.name;
          }
        }}
      />
    );
  };

  const getSelectedUQC = (uqc: string) => {
    let uqcObj = UCQ_OPTIONS.find((item: any) => item.key === uqc);
    if (Utility.isEmpty(uqcObj)) {
      return null;
    } else {
      return uqcObj;
    }
  };

  const getUQCField = () => {
    return (
      <DKInput
        title={'UQC'}
        required={true}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={getSelectedUQC(formData?.indiaProperties?.UQC)}
        formatter={(obj: any) => {
          return obj.key;
        }}
        onChange={(uqc: any) => {
          let indiaProp = { ...formData?.indiaProperties };
          indiaProp.UQC = uqc.key;
          setFormData({ ...formData, indiaProperties: indiaProp });
        }}
        readOnly={false}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'value',
          data: UCQ_OPTIONS,
          renderer: (index: any, uqc: any) => {
            return uqc.key;
          }
        }}
      />
    );
  };

  const getApplyToSection = () => {
    return (
      <div
        className="row mt-6 p-h-m border-radius-s"
        style={{
          border:
            canValidate && Utility.isEmpty(formData?.applyTo)
              ? '1px solid rgba(201, 34, 45, 0.7)'
              : '',
          paddingTop: 6.5,
          paddingBottom: 6.5
        }}
      >
        <DKLabel text="Apply to" />
        <DKCheckMark
          title="SELL"
          isSelected={
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL ||
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH
          }
          onClick={() => {
            if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
              setFormData({
                ...formData,
                applyTo: '',
                incomeAccountCode: '',
                salesTaxCode: ''
              });
            } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.BOTH
              });
            } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.BUY,
                incomeAccountCode: '',
                salesTaxCode: ''
              });
            } else {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.SELL
              });
            }
          }}
          className="ml-l"
        />
        <DKCheckMark
          title="BUY"
          isSelected={
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY ||
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH
          }
          onClick={() => {
            if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
              setFormData({
                ...formData,
                applyTo: '',
                expenseAccountCode: '',
                purchaseTaxCode: ''
              });
            } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.BOTH
              });
            } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.SELL,
                expenseAccountCode: '',
                purchaseTaxCode: ''
              });
            } else {
              setFormData({
                ...formData,
                applyTo: ADDITIONAL_CHARGES_TYPE.BUY
              });
            }
          }}
          className="ml-l"
        />
      </div>
    );
  };

  const getDescriptionField = () => {
    return (
      <DKInput
        title="Description"
        onChange={(text: any) => {
          setFormData({ ...formData, description: text });
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={formData?.description}
        className=""
        required={false}
        type={INPUT_TYPE.LONG_TEXT}
      />
    );
  };

  const getCheckMark = (title: string, key: string, cssClasses: string) => {
    let selected = Utility.isEmpty(formData) ? false : formData[key];
    return (
      <DKCheckMark
        title={title}
        isSelected={selected}
        onClick={() => {
          let checked = Utility.isEmpty(formData) ? false : formData[key];
          const updatedFormData = { ...formData };
          if (key === 'isPercent' && Utility.isEmptyValue(updatedFormData['percentageValue'])) {
            updatedFormData['percentageValue'] = updatedFormData['chargeValue'];
          }
          setFormData({ ...updatedFormData, [key]: !checked });
        }}
        className={cssClasses}
      />
    );
  };

  const getChargeApplicableOnField = () => {
    return (
      <DKInput
        value={{
          value: Utility.convertInTitleCase(formData?.chargeApplicableOn),
          key: formData?.chargeApplicableOn
        }}
        onChange={(obj: any) => {
          setFormData({ ...formData, chargeApplicableOn: obj.key });
        }}
        style={{ width: 100 }}
        required={true}
        canValidate={canValidate}
        type={INPUT_TYPE.DROPDOWN}
        formatter={(obj: any) => {
          return obj.value;
        }}
        readOnly={false}
        dropdownConfig={{
          data: docKeys,
          renderer: (index: any, applicableOn: any) => {
            return applicableOn.value;
          }
        }}
      />
    );
  };

  const getValueField = (
    <div className="row row-responsive gap-4">
      <div className={`column parent-width`}>
        <div
          className={`row gap-2 align-items-end ${
            formData?.isPercent ? 'justify-content-evenly' : ''
          }`}
        >
          <DKInput
            title={formData?.isPercent ? 'Value(%)' : 'Value'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            value={
              formData?.isPercent
                ? isFocused
                  ? formData?.percentageValue
                  : NumberFormatService.getNumber(formData?.percentageValue)
                : isFocused
                ? formData?.chargeValue
                : NumberFormatService.getNumber(formData?.chargeValue)
            }
            type={INPUT_TYPE.NUMBER}
            canValidate={canValidate}
            required={true}
            errorMessage={' '}
            validator={(value: string) => {
              if (formData?.isPercent) {
                if (
                  parseFloat(formData?.percentageValue) <= 100 &&
                  parseFloat(formData?.percentageValue) >= 0
                ) {
                  return true;
                }
                return false;
              } else {
                if (!props.isDiscount && allowZeroValueAdditionaCharge) {
                  if (
                    parseFloat(formData?.chargeValue) === null ||
                    parseFloat(formData?.chargeValue) === undefined ||
                    value === ''
                  ) {
                    return false;
                  }
                } else {
                  if (
                    parseFloat(formData?.chargeValue) === 0 ||
                    parseFloat(formData?.chargeValue) === null ||
                    parseFloat(formData?.chargeValue) === undefined ||
                    value === ''
                  ) {
                    return false;
                  }
                }
                if (props.isItemDiscount && props.isDiscount) {
                  if (parseFloat(formData?.chargeValue) < 0) {
                    return false;
                  }
                }
              }
              return true;
            }}
            onChange={(text: number) => {
              if (formData?.isPercent) {
                setFormData({ ...formData, percentageValue: text });
              } else {
                setFormData({ ...formData, chargeValue: text });
              }
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
              if (formData?.isPercent) {
                setFormData({
                  ...formData,
                  percentageValue: Utility.roundingOff(
                    formData?.percentageValue,
                    tenantInfo.decimalScale
                  )
                });
              } else {
                setFormData({
                  ...formData,
                  chargeValue: Utility.roundingOff(
                    formData?.chargeValue,
                    tenantInfo.decimalScale
                  )
                });
              }
            }}
          />
          {formData?.isPercent && !props.isItemDiscount && (
            <DKLabel className="mb-2" text={`of`} />
          )}
          {formData?.isPercent && !props.isItemDiscount && (
            <div style={{ minWidth: 100 }}>{getChargeApplicableOnField()}</div>
          )}
        </div>
        {getCheckMark('Is percentage', 'isPercent', 'mt-2')}
      </div>
      {!isIndia() && <div className="row parent-width"></div>}
    </div>
  );

  const getSelectedTaxObject = (code: string) => {
    let taxObject = taxes?.find((item: any) => item.code === code);
    if (Utility.isEmpty(taxObject)) {
      return null;
    } else {
      return taxObject;
    }
  };

  const getTaxSelectionField = (
    title: string,
    data: any[],
    onChange: any,
    value: any
  ) => {
    return (
      <DKInput
        title={title}
        canValidate={canValidate}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        type={INPUT_TYPE.DROPDOWN}
        value={getSelectedTaxObject(value)}
        formatter={(obj: any) => {
          return obj.name;
        }}
        onChange={(tax: any) => {
          onChange(tax);
        }}
        readOnly={false}
        dropdownConfig={{
          allowSearch: true,
          searchableKey: 'name',
          data: data.filter(
            (item: any) => item.status.toLowerCase() === STATUS_TYPE.active
          ),
          renderer: (index: any, tax: any) => {
            return tax.name;
          }
        }}
      />
    );
  };

  const getSGORGAccountsField = (
    <div className="column parent-width gap-4">
      {getValueField}
      {(formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY ||
        formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) && (
        <div className="row row-responsive gap-4">
          {getAccountSelectionField(
            'Expense Account',
            'expenseAccountCode',
            [ACCOUNT_GROUPS.EXPENSES],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
          )}
          {getTaxSelectionField(
            'Purchase Tax',
            taxes
              ? tenantInfo.country === COUNTRY_CODES.SG
                ? Utility.getCurrentYearTaxes(
                    taxes.filter(
                      (item: any) =>
                        item.type === TAX_TYPES.PURCHASE ||
                        item.type === TAX_TYPES.BOTH
                    )
                  )
                : taxes.filter(
                    (item: any) =>
                      item.type === TAX_TYPES.PURCHASE ||
                      item.type === TAX_TYPES.BOTH
                  )
              : [],
            (tax: any) => {
              setFormData({ ...formData, purchaseTaxCode: tax.code });
            },
            formData?.purchaseTaxCode
          )}
        </div>
      )}
      {(formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL ||
        formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) && (
        <div className="row row-responsive gap-4">
          {getAccountSelectionField(
            'Income Account',
            'incomeAccountCode',
            [ACCOUNT_GROUPS.INCOME, ACCOUNT_GROUPS.OTHER_INCOME],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
          )}
          {getTaxSelectionField(
            'Sales Tax',
            taxes
              ? tenantInfo.country === COUNTRY_CODES.SG
                ? Utility.getCurrentYearTaxes(
                    taxes.filter(
                      (item: any) =>
                        item.type === TAX_TYPES.SALES ||
                        item.type === TAX_TYPES.BOTH
                    )
                  )
                : taxes.filter(
                    (item: any) =>
                      item.type === TAX_TYPES.SALES ||
                      item.type === TAX_TYPES.BOTH
                  )
              : [],
            (tax: any) => {
              setFormData({ ...formData, salesTaxCode: tax.code });
            },
            formData?.salesTaxCode
          )}
        </div>
      )}
    </div>
  );

  const getUSORGAccountsField = (
    <div className="column parent-width gap-4">
      {getValueField}
      <div className="row row-responsive gap-4">
        {getAccountSelectionField(
          'Expense Account',
          'expenseAccountCode',
          [ACCOUNT_GROUPS.EXPENSES],
          formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
        )}
        {getAccountSelectionField(
          'Income Account',
          'incomeAccountCode',
          [ACCOUNT_GROUPS.INCOME, ACCOUNT_GROUPS.OTHER_INCOME],
          formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
        )}
      </div>
    </div>
  );

  const getDiscountAccountsField = (
    <div className="column parent-width gap-4">
      {getValueField}
      <div className="row row-responsive gap-4">
        {(formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL ||
          formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) &&
          getAccountSelectionField(
            'Discount Given',
            'expenseAccountCode',
            [ACCOUNT_GROUPS.OTHER_EXPENSES],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
          )}
        {(formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY ||
          formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) &&
          getAccountSelectionField(
            'Discount Received',
            'incomeAccountCode',
            [ACCOUNT_GROUPS.OTHER_INCOME],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
          )}
      </div>
    </div>
  );

  const getOfferingTypeField = () => {
    return (
      <div className="row justify-content-between mt-r ">
        <DKLabel text="Additional Charge Type" className="fw-m fs-m" />
        <div
          className="row width-auto bg-gray1 border-radius-s border-m p-h-s p-v-xs"
          style={{ gap: 10 }}
        >
          <DKButton
            title={t(`PRODUCTS.DIALOG.PRODUCT.GOODS`)}
            className={
              formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            onClick={() => {
              setFormData({
                ...formData,
                offeringType: PRODUCT_OFFERING_TYPE.GOODS,
                indiaProperties: {
                  ...formData?.indiaProperties,
                  HSN: '',
                  SAC: ''
                }
              });
            }}
          />
          <DKButton
            className={
              formData?.offeringType === PRODUCT_OFFERING_TYPE.SERVICES
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            title={t(`PRODUCTS.DIALOG.PRODUCT.SERVICES`)}
            onClick={() => {
              setFormData({
                ...formData,
                offeringType: PRODUCT_OFFERING_TYPE.SERVICES,
                indiaProperties: {
                  ...formData?.indiaProperties,
                  HSN: '',
                  SAC: ''
                }
              });
            }}
          />
        </div>
      </div>
    );
  };

  const getNameField = () => {
    return (
      <DKInput
        title="Name"
        onChange={(text: any) => {
          setFormData({ ...formData, name: text });
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        value={formData?.name}
        canValidate={canValidate}
      />
    );
  };

  const getAccountsFieldsIndia = () => {
    if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH) {
      return (
        <div className="row row-responsive gap-4">
          {getAccountSelectionField(
            'Income Account',
            'incomeAccountCode',
            [ACCOUNT_GROUPS.INCOME, ACCOUNT_GROUPS.OTHER_INCOME],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
          )}
          {getAccountSelectionField(
            'Expense Account',
            'expenseAccountCode',
            [ACCOUNT_GROUPS.EXPENSES],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
          )}
        </div>
      );
    } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL) {
      return (
        <div className="row row-responsive pr-s" style={{ width: '50%' }}>
          {getAccountSelectionField(
            'Income Account',
            'incomeAccountCode',
            [ACCOUNT_GROUPS.INCOME, ACCOUNT_GROUPS.OTHER_INCOME],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.SELL
          )}
        </div>
      );
    } else if (formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY) {
      return (
        <div className="row row-responsive pr-s" style={{ width: '50%' }}>
          {getAccountSelectionField(
            'Expense Account',
            'expenseAccountCode',
            [ACCOUNT_GROUPS.EXPENSES],
            formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BOTH ||
              formData?.applyTo === ADDITIONAL_CHARGES_TYPE.BUY
          )}
        </div>
      );
    }
  };

  const indiaView = () => {
    return (
      <div className="column parent-width gap-4">
        <div className="row row-responsive gap-4 align-items-start">
          {getValueField}
          {formData?.offeringType === PRODUCT_OFFERING_TYPE.GOODS
            ? getHSNSACCodeField('HSN Code', hsnCodes)
            : getHSNSACCodeField('SAC Code', sacCodes)}
        </div>
        {
          <div className="row row-responsive gap-4">
            {getUQCField()}
            {getTaxField()}
          </div>
        }
        {getAccountsFieldsIndia()}
      </div>
    );
  };

  return (
    <DynamicPopupWrapper>
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 570,
          width: '90%',
          // height: "85%",
          maxHeight: '95%',
          height: Utility.isUSorg() ? 680 : 680,
          padding: 0,
          paddingBottom: 10
        }}
      >
        {getHeader()}
        <div className="column p-4 parent-size gap-4 overflow-scroll hide-scroll-bar">
          {!props.isDiscount && getOfferingTypeField()}
          <div className="row row-responsive gap-4 align-items-center">
            <div className="row">{getNameField()}</div>
            <div className="row">{getApplyToSection()}</div>
          </div>
          {!props.isDiscount && isIndia() && indiaView()}
          {!props.isDiscount && Utility.isUSorg() && getUSORGAccountsField}
          {!props.isDiscount && Utility.isSGOrg() && getSGORGAccountsField}
          {props.isDiscount && getDiscountAccountsField}
          {getDescriptionField()}
          {!props.isDiscount && getCheckMark('Is Taxable', 'isTaxable', '')}
          {!props.isDiscount &&
            getCheckMark('Include in sales report', 'includeInReport', '')}
        </div>
      </div>
      </div>
    </DynamicPopupWrapper>
  );
}
