import {
  DKIcon,
  DKIcons,
  DKLabel,
  Toggle,
  DKInput,
  INPUT_TYPE,
  DKSpinner,
  DKButton
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  INPUT_VIEW_DIRECTION,
  NEW_SETTINGS_SECTION,
  NUMBER_FORMAT_LIST,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import { TableManager } from '../../../Managers/TableManager';
import {
  AddressType,
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import {
  OrganisationFormPayload,
  OrganisationProfileState
} from '../../../Models/OrganisationProfile';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  activeTenantInfo,
  activeTenantInfoForBookkeeper,
  fetchContactInfo,
  fetchCurrentTenantInfo,
  selectContactInfo,
  userInfo,
  fetchCurrentTenantInfoWithoutCountryCode,
  usersList
} from '../../../Redux/Slices/AuthSlice';
import { addBillColumnConfig } from '../../../Redux/Slices/BillsSlice';
import {
  fetchCurrencyWithExchangeRate,
  setSelectedSetting
} from '../../../Redux/Slices/CommonDataSlice';
import { selectLiabilities } from '../../../Redux/Slices/ContactsSlice';
import { addInvoiceColumnConfig } from '../../../Redux/Slices/InvoicesSlice';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import { addProductColumnConfig } from '../../../Redux/Slices/ProductsSlice';
import { addOrderColumnConfig } from '../../../Redux/Slices/PurchaseOrdersSlice';
import { addQuoteColumnConfig } from '../../../Redux/Slices/QuotesSlice';
import DateFormatService from '../../../Services/DateFormat';
import TableService from '../../../Services/Table';
import TenantService from '../../../Services/Tenant';
import { getFormattedAddressObj } from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import {
  deepClone,
  formatNPWPNumber,
  getCapitalized
} from '../../../Utility/Utility';
import Utility from '../../../Utility/Utility';
import OrganisationProfileForm from './OrganisationProfileForm';
import { ORGANISATION_FORM_TAB } from '../../../Constants/Enum';
import { addSalesOrderColumnConfig } from '../../../Redux/Slices/SalesOrderSlice';
import { localizedText } from '../../../Services/Localization/Localization';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import { fetchStandardWHTax } from '../../../Redux/Slices/WHTSlice';
import { UK_VAT_SCHEME } from '../../../Constants/StaticData';
import {
  appendAccountInStore,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import AccountsService from '../../../Services/Accounts';
import { addStockAdjustmentColumnConfig } from '../../../Redux/Slices/StockAdjustmentSlice';
import { addStockTransferColumnConfig } from '../../../Redux/Slices/StockTransferSlice';
import SettingsDetailCard from '../SettingsDetailCard';
import SettingsDetailsCardRow from '../SettingsDetailsCardRow';
import { requisitionColumnConfig } from '../../../Redux/Slices/RequisitionSlice';

const OrganisationProfile: React.FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateOrganisationProfile: 'click' }
  };
  const appSelector = useAppSelector;
  const tenantDetails = appSelector(activeTenantInfo);
  const userDetailsList: any[] = appSelector(userInfo);
  const payableAccounts = useAppSelector(selectLiabilities);
  // few information is missing in `v1/tenants/in/details` api thats why using the data from another api `v1/tenants/details`
  const alternateTenantDetails = useAppSelector(activeTenantInfoForBookkeeper);
  const allUsersList = appSelector(usersList);
  const systemDimensionsData = useAppSelector(selectDimensions);
  const accountList = useAppSelector(selectedAccounts);

  const [organisationFormPopup, setOrganisationFormPopup] = useState({
    showPopup: false,
    activeTab: ORGANISATION_FORM_TAB.GENERAL_INFO
  });
  const organisationProfileRef =
    useRef<UpdateCallBacksRefType>(refInitialState);
  const [organisationProfile, setOrganisationProfile] =
    useState<any>(undefined);
  const [isActionBtnDisabled, setIsActionBtnDisabled] =
    useState<boolean>(false);
  const contactInfo = useAppSelector(selectContactInfo);
  // states and constants for department and classes
  const [categoryDataLoading, setCategoryDataLoading] = useState(false);
  const locations = [
    'Business',
    'Department',
    'Division',
    'Location',
    'Property',
    'Store',
    'Territory'
  ];
  const classes = [
    {
      key: 'TRANSACTION',
      value: 'One to entire transaction'
    },
    {
      key: 'ROW',
      value: 'One to each row in transaction'
    }
  ];

  const popupConfigForOrganisationProfile: BtnType[] = [
    {
      title: t(`SETTINGS.ORGANIZATION_PROFILE.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.UPDATE_ORGANISATION
    }
  ];

  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    // RouteManager.setPresenter({ props });
    AppManager.handleWindowResizeListener(onWindowResize, true);
    dispatch(fetchContactInfo());
    dispatch(fetchStandardWHTax());
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const getUpdatedState = (): OrganisationProfileState => {
    return {
      orgName: tenantDetails.name,
      bookBeginningStartDate: tenantDetails.bookBeginningStartDate,
      country: tenantDetails.country,
      dateFormat: tenantDetails.dateFormat,
      businessType: tenantDetails?.additionalSettings?.BUSINESS_TYPE?.label
        ? tenantDetails?.additionalSettings?.BUSINESS_TYPE?.label
        : '',
      financialStartDate: tenantDetails.financialStartDate,
      currency: tenantDetails.currency,
      decimalScale: tenantDetails.decimalScale,
      orgPetName: (tenantDetails?.name?.length > 0 &&
        tenantDetails.name[0]) as string,
      multicurrencyEnabled: tenantDetails.multicurrencyEnabled,
      shippingAddress: tenantDetails.billingAddresses,
      billingAddress: tenantDetails.shippingAddresses,
      numberFormat: '',
      firstName: '',
      firstNameInitials: '',
      usersList: [],
      locationLabel: '',
      classLabel: '',

      // IND Compliance
      tanNumber: { hidden: true, value: tenantDetails.tanNumberIndia },
      panNumber: { hidden: true, value: tenantDetails.panIndia },
      cinNumber: { hidden: true, value: tenantDetails.cinIndia },
      interestRate: {
        hidden: true,
        value: tenantDetails.tdsPaymentInterestRateIndia
      },
      tcsRegistered: { hidden: true, value: tenantDetails.tcsEnabled },
      eInvoiceEnabled: { hidden: true, value: tenantDetails.indiaEinvoice },
      gstEFilingEnabled: { hidden: true, value: tenantDetails.indiaGstr },
      gstRegistered: { hidden: true, value: tenantDetails.gstRegistered },
      gstIn: { hidden: true, value: tenantDetails.gstin },
      gstRegsiteredDate: {
        hidden: true,
        value: tenantDetails.gstRegistrationDate
      },
      sezOverseaTrading: {
        hidden: true,
        value: tenantDetails.sezOrImportExport
      },
      overSeaServices: {
        hidden: true,
        value: tenantDetails.trackDigitalServices
      },
      reverseCharge: { hidden: true, value: tenantDetails.enableReverseCharge },
      comositeScheme: {
        hidden: true,
        value: tenantDetails.registeredToCompositionScheme
      },
      comositeSchemeType: {
        hidden: true,
        value: tenantDetails.compositionSchemeType
      },

      // SG Compliance
      gstNo: { hidden: true, value: tenantDetails.gstNo },
      uenNumber: { hidden: true, value: tenantDetails.uenNo },
      optInInvoiceNow: { hidden: true, value: tenantDetails.peppolOptIn },
      peppolId: { hidden: true, value: tenantDetails.peppolId },
      vendorId: { hidden: true, value: tenantDetails.vendorId },

      // USA Compliance
      taxIdNumber: { hidden: true, value: tenantDetails.taxIdNumber },
      collectTaxes: { hidden: true, value: tenantDetails.collectTaxes },
      taxStates: { hidden: true, value: tenantDetails.taxStates },

      // INDONESIA Compliance
      npwpNumber: { hidden: true, value: tenantDetails.npwpNumber },
      activateExcise: { hidden: true, value: tenantDetails.activateExcise },

      // MALAYSIA Compliance
      businessRegistrationNumber: {
        hidden: true,
        value: tenantDetails.businessRegistrationNo
      },
      salesTaxRegistrationNumber: {
        hidden: true,
        value: tenantDetails.salesTaxRegistrationNo
      },
      serviceTaxRegistrationNumber: {
        hidden: true,
        value: tenantDetails.serviceTaxRegistrationNo
      },
      exemptionNumber: { hidden: true, value: tenantDetails.exemptionNo },
      expiryDate: { hidden: true, value: tenantDetails.expiryDate },
      isBusinessTaxExempt: { hidden: true, value: tenantDetails.exemption },
      isExemptCertificate: {
        hidden: true,
        value: tenantDetails.exemptionCerificate
      },

      // UAE Compliance
      vatNumber: { hidden: true, value: tenantDetails.vatNoUAE },
      isBusinessDesignatedZone: {
        hidden: true,
        value: tenantDetails.isDesignatedZone
      },
      rcmAccount: { hidden: true, value: tenantDetails.rcmAccount },
      contactOutsideUAE: { hidden: true, value: tenantDetails.outsideContact },
      isExciseActivatedUAE: {
        hidden: true,
        value: tenantDetails.exciseApplicable
      },

      // PHILIPPINES Compliance
      vatNumberPH: { hidden: true, value: tenantDetails.vatNoPhilippines },

      // Canada Compliance
      gstNumber: { hidden: true, value: tenantDetails.gstNumber },
      additionalSettings: tenantDetails?.additionalSettings,
      businessNumber: { hidden: true, value: tenantDetails.canadianBusinessNo },

      // UK Compliance
      ukBusinessRegistrationNumber: {
        hidden: true,
        value: tenantDetails?.businessRegistrationNoUK
      },
      vatIdentificationNumber: {
        hidden: true,
        value: tenantDetails?.vatIdentificationNumberUK
      },
      vatScheme: { hidden: true, value: tenantDetails?.vatSchemeUK },
      currentFlatRate: {
        hidden: tenantDetails?.vatSchemeUK === UK_VAT_SCHEME[0].code,
        value: tenantDetails?.flatRatePercentageListUK?.[0]?.flatRatePercentage
      }
    };
  };

  useEffect(() => {
    const updatedState = organisationProfile
      ? { ...organisationProfile }
      : getUpdatedState();
    const { content } = allUsersList;
    updatedState.usersList = content;

    let numberFormat: string = '';
    NUMBER_FORMAT_LIST.forEach((format) => {
      if (format.id === tenantDetails.numberFormat) {
        numberFormat = format.value;
      }
    });
    updatedState.numberFormat = numberFormat;
    const userId: number = tenantDetails.userId;
    const userDetails = userDetailsList.find(
      (userDetail) => userDetail.id === userId
    );
    if (userDetails) {
      updatedState.firstName = userDetails.firstName;
      updatedState.firstNameInitials = userDetails.firstName[0];
    }

    if (tenantDetails?.country === COUNTRY_CODES.IN) {
      /* INDIAN COMPLIANCE FIELDS */
      updatedState.tanNumber = {
        hidden: false,
        value: tenantDetails.tanNumberIndia
      };
      updatedState.interestRate = {
        hidden: false,
        value: tenantDetails.tdsPaymentInterestRateIndia
      };
      updatedState.panNumber = {
        hidden: false,
        value: tenantDetails?.panIndia
      };
      updatedState.cinNumber = {
        hidden: false,
        value: tenantDetails?.cinIndia
      };
      updatedState.tcsRegistered = {
        hidden: false,
        value: tenantDetails.tcsEnabled
      };
      updatedState.eInvoiceEnabled = {
        hidden: false,
        value: tenantDetails.indiaEinvoice
      };
      updatedState.gstEFilingEnabled = {
        hidden: false,
        value: tenantDetails.indiaGstr
      };
      updatedState.gstRegistered = {
        hidden: false,
        value: tenantDetails.gstRegistered
      };
      updatedState.gstIn = {
        hidden: true,
        value: tenantDetails.gstin
      };
      updatedState.gstRegsiteredDate = {
        hidden: true,
        value: tenantDetails.gstRegistrationDate
      };
      updatedState.sezOverseaTrading = {
        hidden: true,
        value: tenantDetails.sezOrImportExport
      };
      updatedState.overSeaServices = {
        hidden: true,
        value: tenantDetails.trackDigitalServices
      };
      updatedState.reverseCharge = {
        hidden: true,
        value: tenantDetails.enableReverseCharge
      };
      updatedState.comositeScheme = {
        hidden: true,
        value: tenantDetails.registeredToCompositionScheme
      };
      updatedState.comositeSchemeType = {
        hidden: true,
        value: tenantDetails.compositionSchemeType
      };
      if (tenantDetails.gstRegistered) {
        updatedState.gstIn.hidden = false;
        updatedState.gstRegsiteredDate.hidden = false;
        updatedState.sezOverseaTrading.hidden = false;
        updatedState.overSeaServices.hidden = false;
        updatedState.reverseCharge.hidden = false;
        updatedState.comositeScheme.hidden = false;

        if (tenantDetails.registeredToCompositionScheme) {
          updatedState.comositeSchemeType.hidden = false;
        }
      }
    } else if (tenantDetails?.country === COUNTRY_CODES.SG) {
      /* SG Compliance FIELDS */
      updatedState.gstNo = { hidden: false, value: tenantDetails.gstNo };
      updatedState.uenNumber = { hidden: false, value: tenantDetails.uenNo };
      updatedState.optInInvoiceNow = {
        hidden: false,
        value: tenantDetails.peppolOptIn
      };
      updatedState.peppolId = { hidden: true, value: tenantDetails.peppolId };
      updatedState.vendorId = { hidden: true, value: tenantDetails.vendorId };
      if (tenantDetails.peppolOptIn) {
        updatedState.peppolId.hidden = false;
        updatedState.vendorId.hidden = false;
      }
    } else if (tenantDetails?.country === COUNTRY_CODES.US) {
      /* USA COMPLIANCE FIELDS */
      updatedState.taxIdNumber = {
        hidden: false,
        value: tenantDetails.gstNo
      };
      updatedState.collectTaxes = {
        hidden: false,
        value: true
      };
      updatedState.taxStates = {
        hidden: true,
        value: tenantDetails.nexusStates
      };

      if (tenantDetails.complianceEnabled) {
        updatedState.collectTaxes.value = false;
        updatedState.taxStates.hidden = false;
        if (!tenantDetails.gstNo) {
          updatedState.taxStates.hidden = true;
        }
      }
    } else if (tenantDetails?.country === COUNTRY_CODES.ID) {
      /* INDONESIA COMPLIANCE FIELDS */
      updatedState.npwpNumber = {
        hidden: false,
        value: formatNPWPNumber(tenantDetails.npwpNo)
      };
      updatedState.activateExcise = {
        hidden: false,
        value: tenantDetails.exciseApplicable
      };
    } else if (tenantDetails?.country === COUNTRY_CODES.MY) {
      /* MALAYSIA COMPLIANCE FIELDS */
      updatedState.businessRegistrationNumber = {
        hidden: false,
        value: tenantDetails.businessRegistrationNo
      };
      updatedState.salesTaxRegistrationNumber = {
        hidden: false,
        value: tenantDetails.salesTaxRegistrationNo
      };
      updatedState.serviceTaxRegistrationNumber = {
        hidden: false,
        value: tenantDetails.serviceTaxRegistrationNo
      };
      updatedState.isBusinessTaxExempt = {
        hidden: false,
        value: tenantDetails.exemption
      };

      updatedState.exemptionNumber = {
        hidden: true,
        value: tenantDetails.exemptionNo
      };
      updatedState.expiryDate = {
        hidden: true,
        value: tenantDetails.expiryDate
      };
      updatedState.isExemptCertificate = {
        hidden: true,
        value: tenantDetails.exemptionCerificate
      };

      updatedState.gstNumber = {
        hidden: false,
        value: alternateTenantDetails?.gstNo
          ? alternateTenantDetails?.gstNo
          : ''
      };

      updatedState.peppolId = {
        hidden: false,
        value: alternateTenantDetails?.peppolId
          ? alternateTenantDetails?.peppolId
          : ''
      };

      updatedState.eInvoiceEnable = {
        hidden: false,
        value: alternateTenantDetails?.peppolOptIn
          ? alternateTenantDetails?.peppolOptIn
          : ''
      };
      if (tenantDetails.exemption) {
        updatedState.exemptionNumber.hidden = false;
        updatedState.expiryDate.hidden = false;
        updatedState.isExemptCertificate.hidden = false;
      }
    } else if (tenantDetails?.country === COUNTRY_CODES.AE) {
      /* UAE COMPLIANCE FIELDS */
      updatedState.vatNumber = {
        hidden: false,
        value: tenantDetails.vatNoUAE
      };
      updatedState.isBusinessDesignatedZone = {
        hidden: false,
        value: tenantDetails.isDesignatedZone
      };
      updatedState.rcmAccount = {
        hidden: true,
        value: tenantDetails.rcmAccount
      };
      updatedState.contactOutsideUAE = {
        hidden: false,
        value: tenantDetails.outsideContact
      };

      updatedState.isExciseActivatedUAE = {
        hidden: false,
        value: tenantDetails.exciseApplicable
      };

      if (tenantDetails.outsideContact) {
        payableAccounts.forEach((account: any) => {
          if (
            account.status === STATUS_TYPE.ACTIVE &&
            account.code === tenantDetails.rcmAccount
          ) {
            updatedState.rcmAccount.value = account.name;
            updatedState.rcmAccount.hidden = false;
          }
        });
      }
    } else if (tenantDetails?.country === COUNTRY_CODES.PH) {
      /* PHILIPPINES COMPLIANCE FIELDS */
      updatedState.vatNumberPH = {
        hidden: false,
        value: tenantDetails.vatNoPhilippines
      };
    } else if (tenantDetails?.country === COUNTRY_CODES.CA) {
      /* PHILIPPINES COMPLIANCE FIELDS */
      updatedState.gstNumber = {
        hidden: false,
        value: tenantDetails.gstNumber
      };

      updatedState.businessNumber = {
        hidden: false,
        value: tenantDetails.canadianBusinessNo
      };
    } else if (tenantDetails?.country === COUNTRY_CODES.UK) {
      /* UK Compliance FIELDS */
      updatedState.ukBusinessRegistrationNumber = {
        hidden: false,
        value: tenantDetails?.businessRegistrationNoUK
      };
      updatedState.vatIdentificationNumber = {
        hidden: false,
        value: tenantDetails?.vatIdentificationNumberUK
      };
      updatedState.vatScheme = {
        hidden: false,
        value: tenantDetails?.vatSchemeUK
      };

      let flatRatePercentageListUKByStdDateFormat =
        tenantDetails?.flatRatePercentageListUK?.map((itemRate: any) => {
          return {
            ...itemRate,
            applyFrom: DateFormatService.getDateFromStr(
              itemRate?.applyFrom,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          };
        });

      // Get Max date
      const maxDate = flatRatePercentageListUKByStdDateFormat
        ? new Date(
            Math.max(
              ...flatRatePercentageListUKByStdDateFormat?.map(
                (element: any) => {
                  return new Date(element?.applyFrom);
                }
              )
            )
          )
        : null;

      const maxDateObj = flatRatePercentageListUKByStdDateFormat?.find(
        (itemRatePer: any) => {
          return itemRatePer.applyFrom === maxDate;
        }
      );

      const flatRatePercentageForLatestDate =
        maxDateObj?.applyFrom ??
        tenantDetails?.flatRatePercentageListUK?.[0]?.flatRatePercentage;
      updatedState.currentFlatRate = {
        hidden: tenantDetails?.vatSchemeUK === UK_VAT_SCHEME[0].code,
        value: flatRatePercentageForLatestDate
      };
    } else if (tenantDetails?.country === COUNTRY_CODES.IL) {
      /* Israel Compliance FIELDS */

      // ilDeduction field state
      updatedState.ilDeductionFileId = {
        hidden: false,
        value: tenantDetails?.ilDeductionFileId
      };

      // ilEngagement type state
      updatedState.ilEngagementType = {
        hidden: false,
        value: tenantDetails?.ilEngagementType
      };
      //ilIncomeTaxNo state
      updatedState.ilIncomeTaxNo = {
        hidden: false,
        value: tenantDetails?.ilIncomeTaxNo
      };
      //ilLegal Name state
      updatedState.ilLegalName = {
        hidden: false,
        value: tenantDetails?.ilLegalName
      };
      //ilVatCode state
      updatedState.ilVatCode = {
        hidden: false,
        value: tenantDetails?.ilVatCode
      };
      //ilNationalInsuranceNo state
      updatedState.ilNationalInsuranceNo = {
        hidden: false,
        value: tenantDetails?.ilNationalInsuranceNo
      };
      //ilParentSubsidiaryName State
      updatedState.ilParentSubsidiaryName = {
        hidden: false,
        value: tenantDetails?.ilParentSubsidiaryName
      };
      //ilReturnEmail State
      updatedState.ilReturnEmail = {
        hidden: false,
        value: tenantDetails?.ilReturnEmail
      };
      //ilVatRegistrationNo State
      updatedState.ilVatRegistrationNo = {
        hidden: false,
        value: tenantDetails?.ilVatRegistrationNo
      };
      // Withholding tax account state
      const accList: any[] =
        !Utility.isEmpty(accountList) && accountList?.content?.length
          ? accountList?.content
          : [];
      let ilWithholdingTaxAccountData = null;
      if (!Utility.isEmpty(accList)) {
        let tempilWithholdingTaxAccountData = accList.find(
          (account: any) =>
            account.accountCode === tenantDetails?.ilWithholdingTaxAccount
        );
        ilWithholdingTaxAccountData = !Utility.isEmpty(
          tempilWithholdingTaxAccountData
        )
          ? tempilWithholdingTaxAccountData?.name
          : null;
      }
      updatedState.ilWithholdingTaxAccount = {
        hidden: false,
        value: ilWithholdingTaxAccountData
      };
    }

    updatedState.billingAddress = tenantDetails.billingAddresses;
    updatedState.shippingAddress = tenantDetails.shippingAddresses;
    updatedState.dateFormat = tenantDetails.dateFormat;
    updatedState.businessType = tenantDetails?.additionalSettings?.BUSINESS_TYPE
      ?.label
      ? tenantDetails?.additionalSettings?.BUSINESS_TYPE?.label
      : '';
    updatedState.decimalScale = tenantDetails.decimalScale;
    updatedState.multicurrencyEnabled = tenantDetails.multicurrencyEnabled;
    updatedState.currency = tenantDetails.currency;
    updatedState.country = tenantDetails.country;
    updatedState.financialStartDate = tenantDetails.financialStartDate;
    updatedState.bookBeginningStartDate = tenantDetails.bookBeginningStartDate;
    updatedState.additionalSettings = tenantDetails?.additionalSettings;
    updatedState.locationLabel =
      tenantDetails?.additionalSettings?.LOCATION?.locationLabel;
    updatedState.classLabel =
      tenantDetails?.additionalSettings?.CLASS?.assignClasses?.toUpperCase() ===
      classes[0]?.key
        ? classes[0]?.value
        : classes[1]?.value;
    setOrganisationProfile({ ...updatedState });
    setTimeout(() => {
      if (
        tenantDetails.country === COUNTRY_CODES.IL &&
        Utility.isEmptyObject(updatedState?.ilWithholdingTaxAccount?.value)
      ) {
        getAndSetWHTAccount();
      }
    }, 0);
    return () => {
      // cleanup
    };
  }, [tenantDetails]);

  const getFormattedAddress = (address: any) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    return (
      <>
        {contactName !== '' && <div>{contactName}</div>}
        {line1 !== '' && <div>{line1}</div>}
        {line2 !== '' && <div>{line2}</div>}
        {cityAndState !== '' && (
          <div className="whitespace-pre-wrap">{cityAndState}</div>
        )}
        {countryAndPostalCode !== '' && <div>{countryAndPostalCode}</div>}
      </>
    );
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setOrganisationFormPopup((prevState) => ({
          ...prevState,
          showPopup: false
        }));
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CLICK_TYPE.UPDATE_ORGANISATION:
        organisationProfileRef.current?.storeCallbacksRef.updateOrganisationProfile();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setOrganisationFormPopup((prevState) => ({
          ...prevState,
          showPopup: false
        }));
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION:
        organisationProfileRef.current.storeCallbacksRef.updateOrganisationProfile =
          passingData.data;
        if (COUNTRY_CODES.MY === tenantDetails?.country) {
          dispatch(fetchCurrentTenantInfoWithoutCountryCode());
        }
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS:
        setIsActionBtnDisabled(true);
        break;
      case POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS:
        setIsActionBtnDisabled(false);
        break;
    }
  };

  const updateOrgSetting = (details: any, updateConfigs = false) => {
    const addressInfoData: AddressType = {
      contactName: tenantDetails?.address?.contactName,
      address1: tenantDetails?.address?.address1,
      postalCode: tenantDetails?.address?.postalCode,
      city: tenantDetails?.address?.city,
      country: tenantDetails?.address?.country,
      state: tenantDetails?.address?.state,
      // @ts-ignore
      preferred: tenantDetails?.address?.preferred
    };

    const activeBillingAddressIndex: number =
      tenantDetails?.billingAddresses?.findIndex(
        (address: any) => address.preferred
      ) | 0;
    const activeShippingAddressIndex: number =
      tenantDetails?.shippingAddresses?.findIndex(
        (address: any) => address.preferred
      ) | 0;

    const billingAddresses = tenantDetails?.billingAddresses?.map(
      (addressData: any, index: any) => {
        const contactName: string = addressData?.contactName;
        const address: string = addressData?.address1;
        const address2: string = addressData?.address2;
        const postalCode: string = addressData.postalCode;
        return {
          ...Utility.generateAddress(
            contactName,
            address,
            address2,
            addressData.city.toString(),
            addressData.state.toString(),
            addressData.country.toString(),
            postalCode,
            '',
            '',
            ''
          ),
          preferred: index === activeBillingAddressIndex
        };
      }
    );

    const shippingAddresses = tenantDetails?.shippingAddresses?.map(
      (addressData: any, index: any) => {
        const contactName: string = addressData?.contactName;
        const address: string = addressData.address1;
        const address2: string = addressData.address2;
        const postalCode: string = addressData.postalCode;
        return {
          ...Utility.generateAddress(
            contactName,
            address,
            address2,
            addressData.city.toString(),
            addressData.state.toString(),
            addressData.country.toString(),
            postalCode,
            '',
            '',
            ''
          ),
          preferred: index === activeShippingAddressIndex
        };
      }
    );

    let payload: OrganisationFormPayload = {
      address: addressInfoData,
      billingAddresses,
      shippingAddresses,
      // shippingAddresses: [addressInfoData],
      // billingAddresses: [addressInfoData],
      id: tenantDetails?.id,
      currency: tenantDetails?.currency,
      dateFormat: tenantDetails?.dateFormat,
      decimalScale: tenantDetails?.decimalScale,
      multicurrency: tenantDetails?.multicurrencyEnabled,
      multicurrencyEnabled: tenantDetails?.multicurrencyEnabled,
      name: tenantDetails?.name,
      numberFormat: tenantDetails?.numberFormat,
      timezone: tenantDetails?.timezone,
      additionalSettings: {
        CLASS: {
          trackClasses: details.isTrackedClassesClicked,
          mandatory: details.isMandatoryClassClicked,
          warnOnClassNotSelected: true,
          assignClasses:
            details?.classLabel?.toLowerCase() ===
            classes[0]?.value?.toLowerCase()
              ? classes[0]?.key
              : classes[1]?.key,
          classCFId: tenantDetails?.additionalSettings?.CLASS?.classCFId
        },
        LOCATION: {
          trackLocation: details?.isTrackedLocationsClicked,
          mandatory: details.isMandatoryLocationClicked,
          locationLabel: details?.locationLabel,
          locationCFId:
            tenantDetails?.additionalSettings?.LOCATION?.locationCFId
        }
      }
    };

    const multicurrencyToActivateList: string[] = [tenantDetails?.currency];
    if (multicurrencyToActivateList.length > 1) {
      payload.currencyCodesToActivate = multicurrencyToActivateList;
      payload.multicurrency = true;
      payload.multicurrencyEnabled = true;
    }

    /* INDIAN COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.IN) {
      payload = {
        ...payload,
        tanNumberIndia: tenantDetails?.tanNumberIndia,
        panIndia: tenantDetails?.panIndia,
        cinIndia: tenantDetails?.cinIndia,
        tcsEnabled: tenantDetails?.tcsEnabled,
        tdsPaymentInterestRateIndia: tenantDetails?.tdsPaymentInterestRateIndia,
        gstRegistered: tenantDetails?.gstRegistered
      };
      // if GST-Registered is checked
      if (tenantDetails?.gstRegistered) {
        payload.trackDigitalServices = tenantDetails?.trackDigitalServices;
        payload.enableReverseCharge = tenantDetails?.enableReverseCharge;
        payload.gstin = tenantDetails?.gstin;
        payload.gstRegistrationDate = tenantDetails?.gstRegistrationDate;

        payload.registeredToCompositionScheme =
          tenantDetails?.registeredToCompositionScheme;
        payload.compositionSchemeType = tenantDetails?.compositionSchemeType;

        payload.sezOrImportExport = tenantDetails?.sezOrImportExport;
        payload.registeredToCompositionScheme =
          tenantDetails?.registeredToCompositionScheme;
      }
      payload.indiaEinvoice = tenantDetails?.indiaEinvoice;
      payload.indiaGstr = tenantDetails?.indiaGstr;
      payload.indiaGstrUsername = tenantDetails?.indiaGstrUsername;
    }

    /* SINGAPORE COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.SG) {
      payload.peppolOptIn = tenantDetails?.peppolOptIn;
      payload.uenNo = tenantDetails?.uenNo;
      payload.gstNo = tenantDetails?.gstNo;
      payload.peppolId = tenantDetails?.peppolId;
      payload.vendorId = tenantDetails?.vendorId;
    }

    /* USA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.US) {
      payload.complianceEnabled = tenantDetails?.complianceEnabled;
      payload.gstNo = tenantDetails?.gstNo;
      payload.nexusStates = tenantDetails?.nexusStates;
    }

    /* INDONESIA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.ID) {
      payload.exciseApplicable = tenantDetails?.exciseApplicable;
      payload.npwpNo = tenantDetails?.npwpNo;
      payload.exciseAccount = tenantDetails?.exciseAccount;
    }

    /* MALAYSIA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.MY) {
      payload.businessRegistrationNo = tenantDetails?.businessRegistrationNo;
      payload.salesTaxRegistrationNo = tenantDetails?.salesTaxRegistrationNo;
      payload.serviceTaxRegistrationNo =
        tenantDetails?.serviceTaxRegistrationNo;
      payload.exemption = tenantDetails?.exemption;
      payload.exemptionNo = tenantDetails?.exemptionNo;
      payload.expiryDate = tenantDetails?.expiryDate;
      payload.exemptionCerificate = tenantDetails?.exemptionCerificate;
      payload.exemptionCriteria = tenantDetails?.exemptionCriteria;
    }

    /* UAE COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.AE) {
      payload.vatNoUAE = tenantDetails?.vatNoUAE;
      payload.outsideContact = tenantDetails?.outsideContact;
      payload.exciseApplicable = tenantDetails?.exciseApplicable;
      payload.isDesignatedZone = tenantDetails?.isDesignatedZone;
      if (tenantDetails?.outsideContact) {
        payload.rcmAccount = tenantDetails?.rcmAccount;
      }
      if (tenantDetails?.exciseApplicable) {
        payload.exciseAccount = tenantDetails?.exciseAccount;
      }
    }

    /* PHILIPPINES COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.PH) {
      payload.vatNoPhilippines = tenantDetails?.vatNoPhilippines;
    }

    /* CANADA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.CA) {
      payload.canadianBusinessNo = tenantDetails?.canadianBusinessNo;
      payload.gstNumber = tenantDetails?.gstNumber;
    }

    setCategoryDataLoading(true);
    TenantService.updateOrgSetting(payload)
      .then((res: any) => {
        if (updateConfigs && systemDimensionsData?.content?.length <= 2) {
          updateColumnConfigs();
        }
        dispatch(fetchCurrentTenantInfo());
        dispatch(fetchCurrencyWithExchangeRate());
        dispatch(fetchCategoryDimensions());
        setCategoryDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCategoryDataLoading(false);
      });
  };

  const updateColumnConfigs = () => {
    TableService.getAllViews().then(
      (data) => {
        setupColumConfigs(data);
        TableManager.populateViewMap(
          data.filter((data) => !data.default && data.columnsMetaData)
        );
      },
      (err) => {
        console.error('Error updating column configs: ', err);
      }
    );
  };

  const setupColumConfigs = (configs: any[]) => {
    configs.forEach((config) => {
      const columnsMetaData = config?.columnsMetaData;
      const tableId = config._id;
      switch (config.name) {
        case REMOTE_CONFIG_TABLES.PRODUCT:
          dispatch(
            addProductColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVOICE:
          dispatch(
            addInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.SALES_ORDER:
          dispatch(
            addSalesOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BILL:
          dispatch(addBillColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          dispatch(addOrderColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.QUOTATION:
          dispatch(addQuoteColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.STOCK_ADJUSTMENT:
          dispatch(
            addStockAdjustmentColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.STOCK_TRANSFER:
          dispatch(
            addStockTransferColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION:
          dispatch(
            requisitionColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
      }
    });
  };

  const classLabelInput = () => {
    return (
      <DKInput
        value={organisationProfile?.classLabel}
        title={''}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        formatter={(obj: any) => {
          return obj;
        }}
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        canValidate={false}
        renderer={(obj: string) => {
          return <DKLabel className="pr-s" text={obj} />;
        }}
        onChange={(value: any) => {
          const updatedState = organisationProfile;
          updatedState.classLabel = value?.value;
          setOrganisationProfile({ ...updatedState });
          updateOrgSetting({
            isTrackedClassesClicked:
              tenantDetails?.additionalSettings?.CLASS?.trackClasses,
            isTrackedLocationsClicked:
              tenantDetails?.additionalSettings?.LOCATION?.trackLocation,
            locationLabel: organisationProfile?.locationLabel,
            classLabel: value?.value,
            isMandatoryClassClicked:
              tenantDetails?.additionalSettings?.CLASS?.mandatory,
            isMandatoryLocationClicked:
              tenantDetails?.additionalSettings?.LOCATION?.mandatory
          });
        }}
        dropdownConfig={{
          allowSearch: false,
          searchableKey: null,
          data: classes,
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj.value} />;
          }
        }}
      />
    );
  };

  const locationLabelInput = () => {
    return (
      <DKInput
        value={organisationProfile?.locationLabel}
        title={''}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        formatter={(obj: any) => {
          return obj;
        }}
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        canValidate={false}
        renderer={(obj: string) => {
          return <DKLabel className="pr-s" text={obj} />;
        }}
        onChange={(value: any) => {
          const updatedState = organisationProfile;
          updatedState.locationLabel = value;
          setOrganisationProfile({ ...updatedState });
          updateOrgSetting({
            isTrackedClassesClicked:
              tenantDetails?.additionalSettings?.CLASS?.trackClasses,
            isTrackedLocationsClicked:
              tenantDetails?.additionalSettings?.LOCATION?.trackLocation,
            locationLabel: value,
            classLabel: organisationProfile?.classLabel,
            isMandatoryClassClicked:
              tenantDetails?.additionalSettings?.CLASS?.mandatory,
            isMandatoryLocationClicked:
              tenantDetails?.additionalSettings?.LOCATION?.mandatory
          });
        }}
        dropdownConfig={{
          allowSearch: false,
          searchableKey: null,
          data: locations,
          renderer: (index: any, obj: any) => {
            return <DKLabel text={obj} />;
          }
        }}
      />
    );
  };

  const getRoundingMethodLabel = (roundingMethod: string) => {
    switch (roundingMethod) {
      case 'ROUND_OFF':
        return 'Round Off';
      case 'ROUND_UP':
        return 'Round Up';
      case 'ROUND_DOWN':
        return 'Round Down';
      default:
        return 'Round Off';
    }
  };

  const getAndSetWHTAccount = () => {
    AccountsService.fetchAccountsByAccountCode([
      tenantDetails.ilWithholdingTaxAccount
    ])
      .then((res) => {
        if (res?.length) {
          const account = res[0];
          dispatch(appendAccountInStore(account));
          const copyOfOrganisationProfile = deepClone(organisationProfile);
          if (copyOfOrganisationProfile.ilWithholdingTaxAccount) {
            copyOfOrganisationProfile.ilWithholdingTaxAccount.value =
              account?.name;
          }
          setOrganisationProfile(copyOfOrganisationProfile);
        }
      })
      .catch((err) => {});
  };

  const getOrgGeneralInfo = () => {
    return (
      <SettingsDetailCard
        key="SettingsDetailsCard-1"
        title={organisationProfile?.orgName}
        editCallback={() =>
          setOrganisationFormPopup({
            activeTab: ORGANISATION_FORM_TAB.GENERAL_INFO,
            showPopup: true
          })
        }
        className="mb-l"
      >
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-1'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.CREATED`)}
          detailValue={
            alternateTenantDetails &&
            alternateTenantDetails?.tenantCreatedDate &&
            DateFormatService.getFormattedDateString(
              alternateTenantDetails?.tenantCreatedDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-2'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.TAX_RESIDENCY`)}
          detailValue={organisationProfile.country}
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-3'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.DATE_FORMAT`)}
          detailValue={organisationProfile.dateFormat}
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-4'}
          detailKey="Business Type"
          detailValue={
            !Utility.isEmpty(organisationProfile.businessType)
              ? organisationProfile.businessType
              : 'NA'
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-5'}
          detailKey="Email"
          detailValue={
            !Utility.isEmpty(contactInfo.email) ? contactInfo.email : 'NA'
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-6'}
          detailKey="Phone"
          detailValue={
            !Utility.isEmpty(contactInfo.phone) ? contactInfo.phone : 'NA'
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-7'}
          detailKey="Fax"
          detailValue={
            !Utility.isEmpty(contactInfo.fax) ? contactInfo.fax : 'NA'
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsGeneralRow-8'}
          detailKey="Website"
          detailValue={
            !Utility.isEmpty(contactInfo.website) ? contactInfo.website : 'NA'
          }
        />
      </SettingsDetailCard>
    );
  };

  const getOrgFinanceInfo = () => {
    return (
      <SettingsDetailCard
        key="SettingsDetailsCard-2"
        title={t(`SETTINGS.ORGANIZATION_PROFILE.FINANCE_SETTINGS`)}
        editCallback={() =>
          setOrganisationFormPopup({
            activeTab: ORGANISATION_FORM_TAB.GENERAL_INFO,
            showPopup: true
          })
        }
        className="mb-l"
      >
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-1'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.FINANCIAL_START_DATE`)}
          detailValue={
            organisationProfile.financialStartDate &&
            DateFormatService.getFormattedDateString(
              organisationProfile.financialStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-2'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.BOOK_BEGINNING_DATE`)}
          detailValue={
            organisationProfile?.bookBeginningStartDate &&
            DateFormatService.getFormattedDateString(
              organisationProfile.bookBeginningStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-3'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.REPORTING_CURRENCY`)}
          detailValue={organisationProfile.currency}
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-4'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.NO_OF_DECIMAN_PLACES`)}
          detailValue={organisationProfile.decimalScale}
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-5'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.NUMBER_FORMAT`)}
          detailValue={organisationProfile.numberFormat}
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-6'}
          detailKey="Multi-Currency"
          detailValue={
            organisationProfile?.multicurrencyEnabled ? 'Enabled' : 'Disabled'
          }
          className="mb-r"
        />
        <SettingsDetailsCardRow
          key={'SettingsDetailsFinanceRow-7'}
          detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.AUTO_ROUND_OFF`)}
          detailValue={
            !!organisationProfile?.additionalSettings?.ROUND_OFF?.autoRoundOff
              ? 'Enabled'
              : 'Disabled'
          }
          className="mb-r"
        />
        {(organisationProfile.additionalSettings &&
        organisationProfile.additionalSettings.ROUND_OFF &&
        organisationProfile.additionalSettings.ROUND_OFF.autoRoundOff
          ? organisationProfile.additionalSettings.ROUND_OFF.autoRoundOff
          : false) && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsFinanceRow-8'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.ROUNDING_METHOD`)}
            detailValue={getRoundingMethodLabel(
              organisationProfile.additionalSettings.ROUND_OFF.roundingMethod
            )}
          />
        )}
      </SettingsDetailCard>
    );
  };

  const getIndiaComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.gstRegistered.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-1'}
            detailKey={'GST registered'}
            detailValue={
              !!organisationProfile.gstRegistered.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.gstIn.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-2'}
            detailKey="GSTIN"
            detailValue={
              organisationProfile.gstIn.value
                ? organisationProfile.gstIn.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.gstRegsiteredDate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-3'}
            detailKey="GST Registered On"
            detailValue={
              organisationProfile.gstRegsiteredDate.value
                ? DateFormatService.getFormattedDateString(
                    organisationProfile.gstRegsiteredDate.value.toString(),
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.gstEFilingEnabled.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-4'}
            detailKey={'GST e-Filing'}
            detailValue={
              !!organisationProfile.gstEFilingEnabled.value
                ? 'Enabled'
                : 'Disabled'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.tcsRegistered.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-5'}
            detailKey={'TCS registered'}
            detailValue={
              !!organisationProfile.tcsRegistered.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.sezOverseaTrading.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-6'}
            detailKey="Involved in SEZ/Overseas Trading?"
            detailValue={
              organisationProfile.sezOverseaTrading.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.overSeaServices.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-7'}
            detailKey="Services for overseas customer"
            detailValue={
              organisationProfile.overSeaServices.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.reverseCharge.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-8'}
            detailKey="Enable Reverse Charge in Sales"
            detailValue={organisationProfile.reverseCharge.value ? 'Yes' : 'No'}
            className="mb-r"
          />
        )}
        {!organisationProfile.comositeScheme.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-9'}
            detailKey="Registered for Composite Scheme"
            detailValue={
              organisationProfile.comositeScheme.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.comositeSchemeType.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-10'}
            detailKey="Composition Scheme Type"
            detailValue={
              organisationProfile.comositeSchemeType.value
                ? organisationProfile.comositeSchemeType.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.eInvoiceEnabled.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-11'}
            detailKey={'e-Invoicing'}
            detailValue={
              !!organisationProfile.eInvoiceEnabled.value
                ? 'Enabled'
                : 'Disabled'
            }
            className="mb-r"
          />
        )}
        {/* TDS details start */}
        <DKLabel className="fw-m mt-s mb-r" text="TDS Details" />
        {!organisationProfile.tanNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-12'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.TAN_NUMBER`)}
            detailValue={
              organisationProfile.tanNumber.value
                ? organisationProfile.tanNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.interestRate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-13'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.INTEREST_RATE`)}
            detailValue={
              organisationProfile.interestRate.value
                ? organisationProfile.interestRate.value
                : 0
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.panNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-14'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.PAN_NUMBER`)}
            detailValue={
              organisationProfile.panNumber.value
                ? organisationProfile.panNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.cinNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndiaComplianceRow-15'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.CIN_NUMBER`)}
            detailValue={
              organisationProfile.cinNumber.value
                ? organisationProfile.cinNumber.value
                : '-'
            }
          />
        )}
        {/* TDS details end */}
      </>
    );
  };

  const getUKComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.ukBusinessRegistrationNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUKComplianceRow-1'}
            detailKey={'Business Registration No'}
            detailValue={
              organisationProfile.ukBusinessRegistrationNumber.value
                ? organisationProfile.ukBusinessRegistrationNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.vatIdentificationNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUKComplianceRow-2'}
            detailKey={'VAT Identification Number'}
            detailValue={
              organisationProfile.vatIdentificationNumber.value
                ? organisationProfile.vatIdentificationNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.vatScheme.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUKComplianceRow-3'}
            detailKey={'VAT Scheme'}
            detailValue={
              organisationProfile.vatScheme.value
                ? organisationProfile.vatScheme.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.currentFlatRate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUKComplianceRow-4'}
            detailKey={'Current Flat Rate'}
            detailValue={
              organisationProfile.currentFlatRate.value
                ? organisationProfile.currentFlatRate.value
                : '-'
            }
            className="mb-r"
          />
        )}
      </>
    );
  };

  const getSGComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.gstNo.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsSGComplianceRow-1'}
            detailKey={'GST No'}
            detailValue={
              organisationProfile.gstNo.value
                ? organisationProfile.gstNo.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.uenNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsSGComplianceRow-2'}
            detailKey={'UEN'}
            detailValue={
              organisationProfile.uenNumber.value
                ? organisationProfile.uenNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.peppolId.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsSGComplianceRow-3'}
            detailKey={'PEPPOL ID'}
            detailValue={
              organisationProfile.peppolId.value
                ? organisationProfile.peppolId.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.optInInvoiceNow.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsSGComplianceRow-4'}
            detailKey={'InvoiceNow'}
            detailValue={
              !!organisationProfile.optInInvoiceNow.value
                ? 'Enabled'
                : 'Disabled'
            }
            className="mb-r"
          />
        )}
      </>
    );
  };

  const getUSAComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.taxIdNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUSAComplianceRow-1'}
            detailKey={'EIN'}
            detailValue={
              organisationProfile.taxIdNumber.value
                ? organisationProfile.taxIdNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.taxStates.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUSAComplianceRow-2'}
            detailKey={'Tax Collection States'}
            detailValue={
              organisationProfile.taxStates.value
                ? organisationProfile.taxStates.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.collectTaxes.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUSAComplianceRow-3'}
            detailKey={"I don't collect taxes"}
            detailValue={
              <Toggle
                onChange={() => {}}
                isOn={!!organisationProfile.collectTaxes.value}
                className="cursor-auto box-content"
              />
            }
            className="mb-r"
          />
        )}
      </>
    );
  };

  const getIndonesiaComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.npwpNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIndonesiaComplianceRow-1'}
            detailKey={'NPWP No'}
            detailValue={
              organisationProfile.npwpNumber.value
                ? organisationProfile.npwpNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {/* activateExcise is not implemented yet */}
      </>
    );
  };

  const getMYComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.businessRegistrationNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-1'}
            detailKey={'Business Registration No'}
            detailValue={
              organisationProfile.businessRegistrationNumber.value
                ? organisationProfile.businessRegistrationNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.salesTaxRegistrationNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-2'}
            detailKey={'Sales Tax Registration No'}
            detailValue={
              organisationProfile.salesTaxRegistrationNumber.value
                ? organisationProfile.salesTaxRegistrationNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.serviceTaxRegistrationNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-3'}
            detailKey={'Service Tax Registration No'}
            detailValue={
              organisationProfile.serviceTaxRegistrationNumber.value
                ? organisationProfile.serviceTaxRegistrationNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.exemptionNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-4'}
            detailKey={'Exemption No'}
            detailValue={
              organisationProfile.exemptionNumber.value
                ? organisationProfile.exemptionNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.expiryDate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-5'}
            detailKey={'Expiry Date'}
            detailValue={
              organisationProfile.expiryDate.value
                ? organisationProfile.expiryDate.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.expiryDate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-6'}
            detailKey={'Business Registered for Exemption?'}
            detailValue={
              <Toggle
                onChange={() => {}}
                isOn={!!organisationProfile.isBusinessTaxExempt.value}
                className="cursor-auto box-content"
              />
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.isExemptCertificate.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-7'}
            detailKey={'Is Exemption Certificate available?'}
            detailValue={
              <Toggle
                onChange={() => {}}
                isOn={!!organisationProfile.isExemptCertificate.value}
                className="cursor-auto box-content"
              />
            }
          />
        )}
      </>
    );
  };

  const getMYNewComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.gstNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-1'}
            detailKey={'Gst No'}
            detailValue={
              organisationProfile.gstNumber.value
                ? organisationProfile.gstNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.peppolId.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-2'}
            detailKey={'PEPPOL ID'}
            detailValue={
              organisationProfile.peppolId.value
                ? organisationProfile.peppolId.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.eInvoiceEnable.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsMYComplianceRow-3'}
            detailKey={'E-Invoice'}
            detailValue={
              !!organisationProfile.eInvoiceEnable.value
                ? 'Enabled'
                : 'Disabled'
            }
            className="mb-r"
          />
        )}
      </>
    );
  };

  const getUAEComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.vatNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUAEComplianceRow-1'}
            detailKey={'VAT Number'}
            detailValue={
              organisationProfile.vatNumber.value
                ? organisationProfile.vatNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.isBusinessDesignatedZone.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUAEComplianceRow-2'}
            detailKey={'Is your business located in a Designated Zone?'}
            detailValue={
              organisationProfile.isBusinessDesignatedZone.value ? 'Yes' : 'No'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.rcmAccount.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUAEComplianceRow-3'}
            detailKey={'Account for RCM'}
            detailValue={
              organisationProfile.rcmAccount.value
                ? organisationProfile.rcmAccount.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.contactOutsideUAE.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsUAEComplianceRow-4'}
            detailKey={'Enable trade with contacts outside UAE'}
            detailValue={
              <Toggle
                onChange={() => {}}
                isOn={!!organisationProfile.contactOutsideUAE.value}
                className="cursor-auto box-content"
              />
            }
          />
        )}
        {/* isExciseActivatedUAE(Activate Excise) is not implemented currently */}
      </>
    );
  };

  const getPhilippinesComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.vatNumberPH.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsPHComplianceRow-1'}
            detailKey={'VAT Number'}
            detailValue={
              organisationProfile.vatNumberPH.value
                ? organisationProfile.vatNumberPH.value
                : '-'
            }
          />
        )}
      </>
    );
  };

  const getCanadaComplianceInfo = () => {
    return (
      <>
        {!organisationProfile.gstNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsCanadaComplianceRow-1'}
            detailKey={'GST Number'}
            detailValue={
              organisationProfile.gstNumber.value
                ? organisationProfile.gstNumber.value
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile.businessNumber.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsCanadaComplianceRow-2'}
            detailKey={'Business Number'}
            detailValue={
              organisationProfile.businessNumber.value
                ? organisationProfile.businessNumber.value
                : '-'
            }
          />
        )}
      </>
    );
  };

  const getIsraelComplianceInfo = () => {
    return (
      <>
        {!organisationProfile?.ilDeductionFileId?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-1'}
            detailKey={'Deduction File Id'}
            detailValue={
              organisationProfile?.ilDeductionFileId?.hidden
                ? organisationProfile?.ilDeductionFileId?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilEngagementType?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-2'}
            detailKey={'Engagement Type'}
            detailValue={
              organisationProfile?.ilEngagementType?.hidden
                ? organisationProfile?.ilEngagementType?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilWithholdingTaxAccount?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-3'}
            detailKey={'Withholding Tax Account'}
            detailValue={
              organisationProfile?.ilWithholdingTaxAccount?.hidden
                ? organisationProfile?.ilWithholdingTaxAccount?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilIncomeTaxNo?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-4'}
            detailKey={'Income Tax No'}
            detailValue={
              organisationProfile?.ilIncomeTaxNo?.hidden
                ? organisationProfile?.ilIncomeTaxNo?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilLegalName?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-5'}
            detailKey={'Legal Name'}
            detailValue={
              organisationProfile?.ilLegalName?.hidden
                ? organisationProfile?.ilLegalName?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilVatCode?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-6'}
            detailKey={'Vat Code'}
            detailValue={
              organisationProfile?.ilVatCode?.hidden
                ? organisationProfile?.ilVatCode?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilNationalInsuranceNo?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-7'}
            detailKey={'National Insurance No'}
            detailValue={
              organisationProfile?.ilNationalInsuranceNo?.hidden
                ? organisationProfile?.ilNationalInsuranceNo?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilParentSubsidiaryName?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-8'}
            detailKey={'Parent Subsidiary Name'}
            detailValue={
              organisationProfile?.ilParentSubsidiaryName?.hidden
                ? organisationProfile?.ilParentSubsidiaryName?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilReturnEmail?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-9'}
            detailKey={'Return Email'}
            detailValue={
              organisationProfile?.ilReturnEmail?.hidden
                ? organisationProfile?.ilReturnEmail?.hidden
                : '-'
            }
            className="mb-r"
          />
        )}
        {!organisationProfile?.ilVatRegistrationNo?.hidden && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsIsraelComplianceRow-10'}
            detailKey={'Vat Registration No'}
            detailValue={
              organisationProfile?.ilVatRegistrationNo?.hidden
                ? organisationProfile?.ilVatRegistrationNo?.hidden
                : '-'
            }
          />
        )}
      </>
    );
  };

  const getOrgComplianceInfo = () => {
    return (
      <SettingsDetailCard
        key="SettingsDetailsCard-3"
        title={t(`SETTINGS.ORGANIZATION_PROFILE.COMPLIENCE_SETTINGS`)}
        editCallback={() =>
          setOrganisationFormPopup({
            activeTab: ORGANISATION_FORM_TAB.COMPLIANCE,
            showPopup: true
          })
        }
        className="mb-l"
      >
        {tenantDetails?.country === COUNTRY_CODES.SG && getSGComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.IN &&
          getIndiaComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.US && getUSAComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.MY &&
          getMYNewComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.UK && getUKComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.IL &&
          getIsraelComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.AE && getUAEComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.PH &&
          getPhilippinesComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.CA &&
          getCanadaComplianceInfo()}
        {tenantDetails?.country === COUNTRY_CODES.ID &&
          getIndonesiaComplianceInfo()}
      </SettingsDetailCard>
    );
  };

  const getOrgAddressInfo = () => {
    return (
      <SettingsDetailCard
        key="SettingsDetailsCard-4"
        title={t(`SETTINGS.ORGANIZATION_PROFILE.ADDRESS_SETTINGS`)}
        editCallback={() =>
          setOrganisationFormPopup({
            activeTab: ORGANISATION_FORM_TAB.ADDRESS_INFO,
            showPopup: true
          })
        }
        className="mb-l"
      >
        {Utility.isEmpty(organisationProfile.billingAddress) &&
          Utility.isEmpty(organisationProfile.shippingAddress) && (
            <div className="column parent-size align-items-center">
              <DKLabel text="No addresses available" className="text-gray" />
            </div>
          )}
        {Array.isArray(organisationProfile.billingAddress) && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsAddressInfoRow-1'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.BILLING_ADDRESS`)}
            detailValue={getFormattedAddress(
              organisationProfile.billingAddress[
                organisationProfile.billingAddress?.findIndex(
                  (address: any) => address.preferred
                ) | 0
              ]
            )}
            className="align-items-start mb-r"
          />
        )}
        {Array.isArray(organisationProfile.shippingAddress) && (
          <SettingsDetailsCardRow
            key={'SettingsDetailsAddressInfoRow-2'}
            detailKey={t(`SETTINGS.ORGANIZATION_PROFILE.SHIPPING_ADDRESS`)}
            detailValue={getFormattedAddress(
              organisationProfile.shippingAddress[
                organisationProfile.shippingAddress?.findIndex(
                  (address: any) => address.preferred
                ) | 0
              ]
            )}
            className="align-items-start"
          />
        )}
      </SettingsDetailCard>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <>
        <SettingsDetailCard
          key="SettingsDetailsCard-4"
          title="Categories"
          isLoading={categoryDataLoading}
          editCallback={() =>
            setOrganisationFormPopup({
              activeTab: ORGANISATION_FORM_TAB.CATEGORIES,
              showPopup: true
            })
          }
          className="mb-l"
        >
          <SettingsDetailsCardRow
            key={'SettingsDetailsCategoriesInfoRow-1'}
            detailKey="Track classes"
            detailValue={
              <Toggle
                onChange={() => {
                  updateOrgSetting(
                    {
                      isTrackedClassesClicked:
                        !tenantDetails?.additionalSettings?.CLASS?.trackClasses,
                      isTrackedLocationsClicked:
                        tenantDetails?.additionalSettings?.LOCATION
                          ?.trackLocation,
                      locationLabel: organisationProfile?.locationLabel,
                      classLabel: organisationProfile?.classLabel,
                      isMandatoryClassClicked:
                        tenantDetails?.additionalSettings?.CLASS?.mandatory,
                      isMandatoryLocationClicked:
                        tenantDetails?.additionalSettings?.LOCATION?.mandatory
                    },
                    true
                  );
                }}
                className="cursor-hand box-content"
                isOn={
                  organisationProfile.additionalSettings?.CLASS?.trackClasses
                }
              />
            }
            className="mb-r"
          />
          {organisationProfile?.additionalSettings?.CLASS?.trackClasses && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-1.5'}
              detailKey="Is mandatory"
              detailValue={
                <Toggle
                  onChange={() => {
                    updateOrgSetting(
                      {
                        isTrackedClassesClicked:
                          tenantDetails?.additionalSettings?.CLASS
                            ?.trackClasses,
                        isTrackedLocationsClicked:
                          tenantDetails?.additionalSettings?.LOCATION
                            ?.trackLocation,
                        locationLabel: organisationProfile?.locationLabel,
                        classLabel: organisationProfile?.classLabel,
                        isMandatoryClassClicked:
                          !tenantDetails?.additionalSettings?.CLASS?.mandatory,
                        isMandatoryLocationClicked:
                          tenantDetails?.additionalSettings?.LOCATION?.mandatory
                      },
                      true
                    );
                  }}
                  className="cursor-hand box-content"
                  isOn={
                    organisationProfile.additionalSettings?.CLASS?.mandatory
                  }
                />
              }
              className="mb-r"
            />
          )}
          {organisationProfile?.additionalSettings?.CLASS?.trackClasses && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-2'}
              detailKey="Assign classes"
              detailValue={classLabelInput()}
              className="mb-r"
            />
          )}

          {organisationProfile?.additionalSettings?.CLASS?.trackClasses && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-3'}
              detailKey=""
              detailValue={
                <DKButton
                  title={'Go To Classes'}
                  className={'fw-m text-bg-button-color'}
                  onClick={() => {
                    dispatch(setSelectedSetting(NEW_SETTINGS_SECTION.CLASSES));
                  }}
                  style={{ padding: 5 }}
                />
              }
              className="mb-r"
            />
          )}

          <SettingsDetailsCardRow
            key={'SettingsDetailsCategoriesInfoRow-4'}
            detailKey="Track locations"
            detailValue={
              <Toggle
                onChange={() => {
                  updateOrgSetting(
                    {
                      isTrackedClassesClicked:
                        tenantDetails?.additionalSettings?.CLASS?.trackClasses,
                      isTrackedLocationsClicked:
                        !tenantDetails?.additionalSettings?.LOCATION
                          ?.trackLocation,
                      locationLabel: organisationProfile?.locationLabel,
                      classLabel: organisationProfile?.classLabel,
                      isMandatoryClassClicked:
                        tenantDetails?.additionalSettings?.CLASS?.mandatory,
                      isMandatoryLocationClicked:
                        tenantDetails?.additionalSettings?.LOCATION?.mandatory
                    },
                    true
                  );
                }}
                className="cursor-hand box-content"
                isOn={
                  organisationProfile.additionalSettings?.LOCATION
                    ?.trackLocation
                }
              />
            }
            className={`${
              organisationProfile.additionalSettings?.LOCATION?.trackLocation
                ? 'mb-r'
                : ''
            }`}
          />
          {organisationProfile?.additionalSettings?.LOCATION?.trackLocation && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-4.5'}
              detailKey="Is mandatory"
              detailValue={
                <Toggle
                  onChange={() => {
                    updateOrgSetting(
                      {
                        isTrackedClassesClicked:
                          tenantDetails?.additionalSettings?.CLASS
                            ?.trackClasses,
                        isTrackedLocationsClicked:
                          tenantDetails?.additionalSettings?.LOCATION
                            ?.trackLocation,
                        locationLabel: organisationProfile?.locationLabel,
                        classLabel: organisationProfile?.classLabel,
                        isMandatoryClassClicked:
                          tenantDetails?.additionalSettings?.CLASS?.mandatory,
                        isMandatoryLocationClicked:
                          !tenantDetails?.additionalSettings?.LOCATION
                            ?.mandatory
                      },
                      true
                    );
                  }}
                  className="cursor-hand box-content"
                  isOn={
                    organisationProfile.additionalSettings?.LOCATION?.mandatory
                  }
                />
              }
              className="mb-r"
            />
          )}
          {organisationProfile.additionalSettings?.LOCATION?.trackLocation && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-5'}
              detailKey="Location label"
              detailValue={
                <div style={{ minWidth: 200 }}>{locationLabelInput()}</div>
              }
              className="mb-r"
            />
          )}
          {organisationProfile.additionalSettings?.LOCATION?.trackLocation && (
            <SettingsDetailsCardRow
              key={'SettingsDetailsCategoriesInfoRow-6'}
              detailKey=""
              detailValue={
                <DKButton
                  title={'Go To Locations'}
                  className={'fw-m text-bg-button-color'}
                  onClick={() => {
                    dispatch(
                      setSelectedSetting(NEW_SETTINGS_SECTION.LOCATIONS)
                    );
                  }}
                  style={{ padding: 5 }}
                />
              }
              className="mb-r"
            />
          )}
        </SettingsDetailCard>
      </>
    );
  };

  return (
    <>
      {organisationProfile && (
        <div className="column text-align-left parent-width pt-r pb-l overflow-y-auto hide-scroll-bar">
          {getOrgGeneralInfo()}
          {getOrgFinanceInfo()}
          {getOrgComplianceInfo()}
          {getOrgAddressInfo()}
          {tenantDetails?.additionalSettings && getAdditionalInfo()}
        </div>
      )}

      {organisationFormPopup.showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={`${getCapitalized(localizedText('organisation'))} Profile`}
          btnList={popupConfigForOrganisationProfile}
          width={!isDesktop ? '95%' : '40%'}
          height={!isDesktop ? '75%' : '95%'}
          disableClickOutside
          isActionBtnDisabled={isActionBtnDisabled}
        >
          <OrganisationProfileForm
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            activeTab={organisationFormPopup.activeTab}
            closePopup={() => {
              let updatedState = { ...organisationFormPopup };
              updatedState.showPopup = false;
              setOrganisationFormPopup({ ...updatedState });
            }}
          />
        </PopupWrapper>
      )}
    </>
  );
};

export default OrganisationProfile;
