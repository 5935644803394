import {
  DKButton,
  DKCalendar,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  showAlert,
  DKCheckMark
} from 'deskera-ui-library';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_NUMBER_INPUT_MODULES,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { UpdateCallBacksRefType } from '../../../Models/Interfaces';
import {
  CD_TYPE,
  JEFundTransferInitialState
} from '../../../Models/JournalEntry';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { selectCurrencyListWithExchangeRate } from '../../../Redux/Slices/CommonDataSlice';
import {
  fetchJournal,
  selectJournalColumnConfig,
  selectJournalColumnConfigTableId
} from '../../../Redux/Slices/Journal';
import AttachmentService from '../../../Services/Attachment';
import DateFormatService from '../../../Services/DateFormat';
import JournalService from '../../../Services/Journal';
import CurrencyDropdown from '../../../SharedComponents/CurrencyDropdown/CurrencyDropdown';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  getCapitalized
} from '../../../Utility/Utility';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import AddCoaPopup from '../COA/AddCoaPopup';
import NumberFormatService from '../../../Services/NumberFormat';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  customFieldsContainsErrors,
  getPrimaryCurrencyCheck
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import { localizedText } from '../../../Services/Localization/Localization';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import { DOCUMENT_MODE } from '../../../Constants/Constant';

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

const transferTypes = [
  {
    label: `${getCapitalized(localizedText('cheque'))}`,
    value: 'CHEQUE'
  },
  {
    label: 'Bank Transfer',
    value: 'BANK_TRANSFER'
  },
  {
    label: 'Card',
    value: 'CARD'
  }
];

export default function AddJournalFundTransfer(props: any) {
  const { t, i18n } = useTranslation();

  //Use App Selectors
  const dispatch = useAppDispatch();
  const accountList = useAppSelector(selectedAccounts);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const currencyExchangeRates = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const journalColumnConfig = useAppSelector(selectJournalColumnConfig);
  const journalConfigTableId = useAppSelector(selectJournalColumnConfigTableId);

  //Use state
  const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const [journalDate, setJournalDate] = useState(
    props.je
      ? DateFormatService.getDateFromStr(
          props.je.jeDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date()
  );
  const [chequeDate, setChequeDate] = useState(
    props.je
      ? DateFormatService.getDateFromStr(
          props.je.chequeDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      : new Date()
  );
  const [isFocused, setIsFocused] = useState<any>();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showChequeDatePicker, setShowChequeDatePicker] = useState(false);
  const [fromFieldUpdate, setFromFieldUpdate] = useState<any>();
  const [toFieldUpdate, setToFieldUpdate] = useState<any>();
  const [autoNumberingFormat, setAutoNumberingFormat] = useState<any>({
    text: 'JE-0000000'
  });
  const [tempPrimaryCurrencyExchangeRate, setTempPrimaryCurrencyExchangeRate] =
    useState<any>();
  const getEditValues = () => {
    let filtered;
    let lineItems = [...props.je.lineItems];
    filtered = lineItems.filter((item: any) => CD_TYPE.CREDIT === item.cdType);

    const amt = lineItems[0].amountInDocumentCurrency;
    lineItems[0] = {
      ...lineItems[0],
      amount: amt, //this is set due to, while editing the amountInDocumentCurrency is our amount, but while creating its vice versa
      amountInDocumentCurrency: null
    };
    lineItems[1] = {
      ...lineItems[1],
      amount: amt, // using same lineItems[0].amountInDocumentCurrency here too, because while edit the amountInDocumentCurrency, amount is same is [1]
      amountInDocumentCurrency: null
    };
    let primaryCurrencyExchangeRate = props.je?.primaryCurrencyExchangeRate;
    if (
      (primaryCurrencyExchangeRate === null ||
        primaryCurrencyExchangeRate === undefined) &&
      getPrimaryCurrencyCheck() &&
      !Utility.isEmpty(currencyExchangeRates)
    ) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      primaryCurrencyExchangeRate = currencyExchangeRates.find(
        (data) => data.currencyCode == primaryCurrencyCode
      )?.currencyExchangeRate;
    }
    return {
      ...props.je,
      amount: filtered[0].amountInDocumentCurrency,
      documentType: 'JOURNAL_ENTRY',
      chequePrinted: false,
      transferDate: props.je.jeDate,
      amountInDocumentCurrency: null,
      lineItems: lineItems,
      currencyExchangeRate: props.je?.bankTransferExchangeRate,
      primaryCurrencyExchangeRate: primaryCurrencyExchangeRate
    };
  };

  const jeState = props.je ? getEditValues() : JEFundTransferInitialState;
  const [je, setJE] = useState(jeState);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [canValidate, setCanValidate] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);

  //refs
  const addCoaRef = useRef<UpdateCallBacksRefType>(refInitialState);

  type onChange = (value: string) => any;
  type returnFunction = (index: number) => any;
  type onDateChange = (date: Date) => any;

  useEffect(() => {
    registerInteractions();
    fetchAttachments();

    let primaryCurrencyExchangeRate = je?.primaryCurrencyExchangeRate;
    if (
      (primaryCurrencyExchangeRate === null ||
        primaryCurrencyExchangeRate === undefined) &&
      getPrimaryCurrencyCheck() &&
      !Utility.isEmpty(currencyExchangeRates)
    ) {
      const primaryCurrencyCode =
        tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
      primaryCurrencyExchangeRate = currencyExchangeRates.find(
        (data) => data.currencyCode == primaryCurrencyCode
      )?.currencyExchangeRate;
    }
    je.primaryCurrencyExchangeRate = primaryCurrencyExchangeRate;
    setTempPrimaryCurrencyExchangeRate(primaryCurrencyExchangeRate);
  }, []);

  useEffect(() => {
    registerInteractions();
  }, [je]);

  useEffect(() => {
    if (
      props.transactionObj &&
      props.transactionObj.cdType === CD_TYPE.CREDIT
    ) {
      updateDebitLineItem({
        name: props.bankAccount.name,
        code: props.bankAccount.code
      });
    } else if (
      props.transactionObj &&
      props.transactionObj.cdType === CD_TYPE.DEBIT
    ) {
      updateCreditLineItem({
        name: props.bankAccount.name,
        code: props.bankAccount.code
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (accountList && accountList?.content && accountList.content.length > 0) {
      let filtered = accountList.content.filter((item: any) => {
        return (
          ['Bank', 'Cash'].includes(item.accountGroup) ||
          (item.accountGroup === 'Current Liabilities' && item.isCreditCard)
        );
      });
      let filteredActive = filtered.filter(
        (item: any) => item.status === STATUS_TYPE.ACTIVE
      );
      setAccounts(filteredActive);
    }
  }, [accountList]);

  useEffect(() => {
    let jDate = DateFormatService.getDateStrFromDate(
      journalDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    let cDate = DateFormatService.getDateStrFromDate(
      chequeDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    setJE({ ...je, jeDate: jDate, chequeDate: cDate, transferDate: jDate });
  }, [journalDate, chequeDate]);

  useEffect(() => {
    if (
      !Utility.isEmpty(getSelectedFromField()) &&
      !Utility.isEmpty(getSelectedToField())
    ) {
      const currencyExchangeRate = calculateCurrencyExchangeRate();
      let updatedJe = { ...je };
      updatedJe = {
        ...updatedJe,
        currencyExchangeRate: currencyExchangeRate
      };
      setJE({ ...updatedJe });
    }
  }, [fromFieldUpdate, toFieldUpdate]);

  //////////////////////////////////// custom fields - start ///////////////////////////////

  const getCustomFieldView = () => {
    return (
      <div className="column parent-width mt-s">
        <CustomFieldsHolder
          documentMode={isReadOnly ? DOCUMENT_MODE.VIEW : DOCUMENT_MODE.NEW}
          moduleName={MODULES_NAME.JOURNAL}
          customFieldsList={props.je ? je.customField : []}
          columnConfig={journalColumnConfig}
          columnConfigTableId={journalConfigTableId ? journalConfigTableId : ''}
          onUpdate={(list: any) => {
            setJE({
              ...je,
              customField: list
            });
          }}
        />
      </div>
    );
  };

  /////////////////////////////////// custom fields - end //////////////////////////////////

  const calculateCurrencyExchangeRate = () => {
    let currencyExchangeRateOfFromField = currencyExchangeRates.find((curr) => {
      return curr.currencyCode === getSelectedFromField()?.currency;
    })?.currencyExchangeRate;

    let currencyExchangeRateOfToField = currencyExchangeRates.find((curr) => {
      return curr.currencyCode === getSelectedToField()?.currency;
    })?.currencyExchangeRate;

    let rate: any =
      1 / (currencyExchangeRateOfFromField / currencyExchangeRateOfToField);
    rate = Number(rate.toFixed(6));

    return rate;
  };

  const closeView = (data: any = {}) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: data
      });
    }
  };

  const filterLineItemForType = (type: string) => {
    let lineItems = [...je.lineItems];
    const filtered = lineItems.filter((item: any) => type === item.cdType);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const validate = () => {
    if (
      Utility.isEmpty(je.jeDocumentSequenceCode) &&
      Utility.isEmpty(je.sequenceFormat) &&
      je.manualMode
    ) {
      showAlert('Error', 'Select Sequence Format');
      return false;
    }

    //dd-mm-yyyy
    const jeDate = DateFormatService.getDateFromStr(
      je.jeDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    //yyyy-mm-dd
    const bbDate = DateFormatService.getDateFromStr(
      tenantInfo.bookBeginningStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );

    if (jeDate < bbDate) {
      let buttons = [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      ];
      showAlert(
        'Please Note!',
        `Journal date should be greater than books beginning date <div class="fw-b">(${DateFormatService.getFormattedDateString(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD/MM/YYYY']
        )}).</div>`,
        buttons
      );
      return false;
    }

    if (
      !Utility.checkActiveDateRangeValidation(
        jeDate,
        tenantInfo,
        'Document date',
        'JOURNAL_ENTRY'
      )
    ) {
      return false;
    }

    if (!Utility.checkClosingDate(jeDate, 'Document Date')) {
      return false;
    }

    if (
      Utility.isEmpty(je.lineItems[0].accountCode) ||
      Utility.isEmpty(je.lineItems[0].accountCode)
    ) {
      showAlert('Error', 'Please select different From & To Accounts');
      return false;
    } else if (
      Utility.isEmpty(je.lineItems[1].accountCode) ||
      Utility.isEmpty(je.lineItems[1].accountCode)
    ) {
      showAlert('Error', 'Please select different From & To Accounts');
      return false;
    } else if (
      je.amount === null ||
      je.amount === undefined ||
      je.amount === 0 ||
      je.amount === '0'
    ) {
      return false;
    } else if (Utility.isEmpty(je.transferType)) {
      return false;
    } else if (
      (Utility.isEmpty(je.transferType) &&
        Utility.isEmpty(je.transferNumber)) ||
      (je.transferNumber && je.transferNumber.length > 40)
    ) {
      return false;
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(je.customField);
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    return true;
  };

  const parseResponseObject = (response: any) => {
    return {
      documentCode: response.jeCode,
      documentType: 'BANK_TRANSFER',
      contactName: '',
      referenceDate: je.jeDate,
      referenceNumber: je.transferNumber,
      transactionAmount: je.amount,
      currency: je.currency,
      accountCode: props.bankAccount.code,
      cdType:
        props.transactionObj.cdType === CD_TYPE.CREDIT
          ? CD_TYPE.DEBIT
          : CD_TYPE.CREDIT
    };
  };

  const saveButtonClicked = () => {
    setCanValidate(true);
    if (!validate()) {
      return;
    }

    let currentJE = { ...je };
    currentJE.currency = currentJE.fromBankCurrency;

    if (props.isCopy) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      JournalService.updateJournalBankTranfer(currentJE)
        .then(
          (res: any) => {
            closeView();
            dispatch(fetchJournal());
          },
          (error: any) => {
            closeView();
          }
        )
        .catch((error: any) => {
          closeView();
        });
    } else {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      JournalService.saveJournalBankTransfer(currentJE)
        .then(
          (res: any) => {
            closeView();
            if (props.bankAccount) {
              props.success(parseResponseObject(res));
            } else {
              dispatch(fetchJournal());
            }
          },
          (error: any) => {
            closeView();
          }
        )
        .catch((error: any) => {
          closeView();
        });
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_JE,
        data: () => {
          saveButtonClicked();
        }
      });
  };

  const getAccountForAccountCode = (code: any) => {
    let filtered = accounts.filter((item: any) => item.code === code);
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return '';
    }
  };

  const updateCreditLineItem = (value: any) => {
    let lineItemArray = [...je.lineItems];
    let lineItem = filterLineItemForType(CD_TYPE.CREDIT);
    let index = je.lineItems.indexOf(lineItem);
    lineItem = { ...lineItem, accountCode: value.code };
    lineItem = { ...lineItem, accountName: value.name };
    lineItemArray[index] = lineItem;
    setJE((preState: any) => {
      return {
        ...preState,
        lineItems: lineItemArray,
        currency: getCurrency(value.code),
        fromBankCurrency: getCurrency(value.code),
        fromAccount: filterIDForAccountCode(value.code),
        transferType:
          getAccountForAccountCode(value.code).accountGroup === 'Cash'
            ? getAccountForAccountCode(value.code).accountGroup.toUpperCase()
            : ''
      };
    });
  };

  const updateDebitLineItem = (value: any) => {
    let lineItemArray = [...je.lineItems];
    let lineItem = filterLineItemForType(CD_TYPE.DEBIT);
    let index = je.lineItems.indexOf(lineItem);
    lineItem = { ...lineItem, accountCode: value.code };
    lineItem = { ...lineItem, accountName: value.name };
    lineItemArray[index] = lineItem;
    setJE((preState: any) => {
      return {
        ...preState,
        lineItems: lineItemArray,
        toAccount: filterIDForAccountCode(value.code)
      };
    });
  };

  const selectedFormat = (selected: any) => {
    /**
     * RECEIVE Selected format {id: "", text: ""}
     */

    if (selected.manualMode) {
      setJE({
        ...je,
        sequenceFormat: selected.id,
        jeDocumentSequenceCode: selected.text,
        manualMode: selected.manualMode
      });
    } else if (selected.id) {
      setJE({
        ...je,
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      });
    }
  };

  const getCalendarView = (date: Date, onDateChange: onDateChange) => (
    <div
      className="position-absolute bg-white"
      style={{
        left: 0,
        top: 40,
        zIndex: 10
      }}
    >
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        selectedDate={date}
        onSelectDate={(newDate: Date) => {
          onDateChange(newDate);
        }}
        onClose={() =>
          setTimeout(() => {
            setShowDatePicker(false);
            setShowChequeDatePicker(false);
          })
        }
      />
    </div>
  );

  const jeNumberField = () => {
    return (
      <div className="column parent-width">
        {/* <DKLabel
          className="mt-s border-m parent-width rounded bg-gray1"
          text={je.id ? je.jeCode : 'JE-000000'}
          style={{ padding: '7px 8px' }}
        /> */}
        {je.id ? (
          <div className="column parent-width">
            <DKLabel text="Auto-numbering format" className="" />
            <DKLabel
              className="mt-s border-m parent-width rounded bg-gray1"
              text={je.id ? je.jeCode : 'JE-000000'}
              style={{ padding: '7px 8px' }}
            />
          </div>
        ) : (
          <div className="parent-width position-relative -mt-1">
            <CustomNumberFormatInput
              module={CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY}
              selectedFormat={selectedFormat}
              extraClass={''}
              isRow={false}
            />
          </div>
        )}
      </div>
    );
  };

  const getSelectedAccount = (code: string) => {
    let accountObj = getAccountForAccountCode(code);
    if (accountObj) {
      return {
        label: accountObj.name,
        value: accountObj.code
      };
    } else {
      return null;
    }
  };

  const getFromField = () => {
    return (
      <div className="parent-width">
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={'From'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          readOnly={
            je.id ||
            (props.transactionObj &&
              props.transactionObj.cdType === CD_TYPE.DEBIT)
          }
          value={
            getSelectedAccount(
              filterLineItemForType(CD_TYPE.CREDIT).accountCode
            )?.label
          }
          onChange={(accountObj: any) => {
            updateCreditLineItem(accountObj);
            setFromFieldUpdate(accountObj);
          }}
          canValidate={canValidate}
          dropdownConfig={{
            title: 'Account',
            allowSearch: true,
            searchableKey: 'name',
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            searchApiConfig: {
              getUrl: (searchValue: string) => {
                let endPoint =
                  ApiConstants.URL.ACCOUNTS.FETCH_ACCOUNTS +
                  '?query=search=&limit=400&status=ACTIVE';
                return ApiConstants.URL.BASE + endPoint;
              },
              dataParser: (response: any) => {
                let filtered = response?.content?.filter((item: any) => {
                  return ['Bank', 'Cash'].includes(item.accountGroup);
                });
                let filteredActive = filtered.filter(
                  (item: any) => item.status === STATUS_TYPE.ACTIVE
                );
                return filteredActive || [];
              },
              debounceTime: 300
            },
            data: undefined,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {},
            button: {
              title: '+ Add new',
              className: 'bg-button text-white',
              onClick: () => {
                setShowAccountPopup(true);
              }
            }
          }}
        />
      </div>
    );
  };

  const getSelectedTransferType = () => {
    let filtered = transferTypes.filter(
      (item: any) => je.transferType === item.value
    );
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      return null;
    }
  };

  const getTransferTypeOptions = () => {
    let transferTypeOptions = transferTypes;
    transferTypeOptions.map((transferType) => {
      if (transferType.value === 'CHEQUE') {
        transferType.label = getCapitalized(localizedText('cheque'));
      }
    });
    return transferTypeOptions;
  };

  const getTransferTypeField = () => {
    return (
      <div className="parent-width mt-l">
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          title={'Transfer Type'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={getSelectedTransferType()?.label}
          onChange={(value: any) => {
            setJE({ ...je, transferType: value.value });
          }}
          readOnly={
            je.id
            // ||
            // (props.transactionObj &&
            //   props.transactionObj.cdType === CD_TYPE.CREDIT)
          }
          canValidate={canValidate}
          dropdownConfig={{
            style: { minWidth: 150 },
            className: 'shadow-m width-auto',
            data: getTransferTypeOptions(),
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.label}`} />;
            },
            onSelect: (index: any, obj: any, rowIndex: any) => {}
          }}
        />
      </div>
    );
  };

  const getErrorReferenceNumberErrorText = () => {
    if (je.transferType === 'CHEQUE') {
      if (tenantInfo?.country === 'US') {
        if (je.transferNumber) {
          return 'Check Number length should be less than 40 ';
        } else {
          return 'Enter Valid Check Number';
        }
      } else {
        if (je.transferNumber) {
          return 'Cheque Number length should be less than 40 ';
        } else {
          return 'Enter Valid Cheque Number';
        }
      }
    } else {
      if (je.transferNumber) {
        return 'Reference Number length should be less than 40 ';
      } else {
        return 'Enter Valid Reference Number';
      }
    }
  };

  const getChequeNumberField = () => {
    return (
      <div className="parent-width mt-l">
        <DKInput
          title={
            je.transferType === 'CHEQUE'
              ? tenantInfo?.country === 'US'
                ? 'Check Number'
                : 'Cheque Number'
              : 'Reference Number'
          }
          className=""
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={je.transferNumber}
          onChange={(value: any) => {
            setJE({ ...je, transferNumber: value });
          }}
          canValidate={canValidate}
          validator={(value: string) => {
            return value && value.length < 40;
          }}
          errorMessage={getErrorReferenceNumberErrorText()}
        />
      </div>
    );
  };

  const getCurrency = (code: any) => {
    let accountObj = getAccountForAccountCode(code);
    if (accountObj) {
      return accountObj.currency;
    } else {
      return '';
    }
  };

  const getCurrencyField = () => {
    return (
      <div className="parent-width mt-l">
        <DKLabel className="mb-s" text="Currency" />
        <CurrencyDropdown
          width={`100%`}
          getInfo={() => {}}
          isDisabled={true}
          currencyCode={je.fromBankCurrency}
        ></CurrencyDropdown>
      </div>
    );
  };

  const getAmountField = () => {
    return (
      <div className="parent-width mt-l">
        <DKInput
          type={INPUT_TYPE.NUMBER}
          title={'Amount'}
          required={true}
          // canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={
            isFocused === 'amount'
              ? je.amount
              : je.amount
              ? NumberFormatService.getNumber(je.amount)
              : ''
          }
          canValidate={canValidate}
          onFocus={() => setIsFocused('amount')}
          onBlur={() => setIsFocused('')}
          onChange={(value: any) => {
            let val = Number(value) >= 0 ? value : 0;
            const currentLineItems = [...je.lineItems];
            currentLineItems[0] = {
              ...currentLineItems[0],
              amount: val,
              amountInDocumentCurrency: null
            };
            currentLineItems[1] = {
              ...currentLineItems[1],
              amount: val,
              amountInDocumentCurrency: null
            };
            if (val >= 0) {
              setJE({
                ...je,
                amount: val.trim(),
                lineItems: currentLineItems
              });
            }
          }}
        />
      </div>
    );
  };

  const getToAccountsList = () => {
    let filtered = accounts.filter(
      (item: any) =>
        item.code !== filterLineItemForType(CD_TYPE.CREDIT).accountCode
    );
    if (filtered.length > 0) {
      return filtered;
    } else {
      return [];
    }
  };

  const filterIDForAccountCode = (code: any) => {
    let accountObj = getAccountForAccountCode(code);
    if (accountObj) {
      return accountObj.id;
    } else {
      return undefined;
    }
  };

  const getToField = () => {
    return (
      <DKInput
        type={INPUT_TYPE.DROPDOWN}
        title={'To'}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        readOnly={
          props.transactionObj && props.transactionObj.cdType === CD_TYPE.CREDIT
        }
        value={
          getSelectedAccount(filterLineItemForType(CD_TYPE.DEBIT).accountCode)
            ?.label
        }
        onChange={(accountObj: any) => {
          updateDebitLineItem(accountObj);
          setToFieldUpdate(accountObj);
        }}
        className="mt-l"
        canValidate={canValidate}
        dropdownConfig={{
          title: 'Account',
          // allowSearch: true,
          // searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: (searchValue: string) => {
              // const config: AccountAPIConfig = {
              //   ...AccountsService.apiConfig,
              //   Page: 0,
              //   SearchTerm: searchValue,
              //   Limit: 20
              // };
              // AccountsService.apiConfig = config;
              // return DocumentConfigManager.getAccountURL(searchValue);
            },
            dataParser: (response: any) => {
              // return response?.content || [];
            },
            debounceTime: 300
          },
          data: getToAccountsList(),
          renderer: (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          },
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add new',
            className: 'bg-button text-white',
            onClick: () => {
              setShowAccountPopup(true);
            }
          }
        }}
      />
    );
  };

  const getMemoField = () => {
    return (
      <div className="parent-width mt-l">
        <DKInput
          title="Memo"
          required="false"
          type={INPUT_TYPE.LONG_TEXT}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={je.memo}
          onChange={(value: any) => {
            setJE({ ...je, memo: value });
          }}
        />
      </div>
    );
  };

  /////////////////////////////// add account popup - start /////////////////////////////////////////
  const getAddAccountPopup = () => {
    return showAccountPopup ? (
      <AddCoaPopup
        populateFormData={null}
        onCancel={() => {
          setShowAccountPopup(false);
        }}
      />
    ) : null;
  };
  /////////////////////////////// add account popup - end ///////////////////////////////////////////

  const getJournalDateField = (
    <DKInput
      className="parent-width mt-l"
      title="Journal Date"
      value={journalDate}
      type={INPUT_TYPE.DATE}
      onChange={(value: any) => {
        if (
          Utility.checkActiveDateRangeValidation(
            value,
            tenantInfo,
            'Document date',
            'JOURNAL_ENTRY'
          ) &&
          Utility.checkClosingDate(value, 'Journal date')
        ) {
          setJournalDate(value);
        } else {
          setJournalDate(new Date());
        }
      }}
      direction={INPUT_VIEW_DIRECTION.VERTICAL}
      required={false}
      dateFormat={convertBooksDateFormatToUILibraryFormat(
        tenantInfo.dateFormat
      )}
    />
  );

  const getChequeDateField = (
    <div className="parent-width mt-l">
      <DKInput
        className="parent-width"
        title={tenantInfo?.country === 'US' ? 'Check Date' : 'Cheque Date'}
        value={chequeDate}
        type={INPUT_TYPE.DATE}
        onChange={(value: any) => {
          setChequeDate(value);
        }}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
      />
    </div>
  );

  const ifAccountGroupisBank = () => {
    let accountObj = getAccountForAccountCode(
      filterLineItemForType(CD_TYPE.CREDIT).accountCode
    );
    if (accountObj) {
      return accountObj.accountGroup === 'Bank';
    } else {
      return false;
    }
  };

  const ifToAccountISBank = () => {
    let account = getAccountForAccountCode(
      filterLineItemForType(CD_TYPE.CREDIT).accountCode
    );
    return account ? account.accountGroup === 'Bank' : false;
  };

  ///////////////////// attachment functions - start //////////////////////////////
  const fetchAttachments = () => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.JOURNAL_ENTRY];

    if (props.je) {
      const entityId = props.je.id || props.je.entityId;
      if (!entityId) return;

      AttachmentService.attachmentConfig = {
        ...AttachmentService.attachmentConfig,
        Module: moduleType,
        EntityId: entityId
      };

      AttachmentService.getAllAttachments().then((attachmentList: any) => {
        setAttachments(attachmentList);
      });
    }
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.JOURNAL_ENTRY];
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const newAttachments = [...attachments, res];
        const attachmentIds =
          newAttachments && newAttachments.length > 0
            ? newAttachments.map((attachment: any) => attachment.attachmentId)
            : [];
        setAttachments(newAttachments);
        setJE({
          ...je,
          attachmentIds
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(newAttachments);
      })
      .catch(() => {});
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap gap-2">
        {attachments.map((attachment: any) => (
          <div
            className="row border-m border-radius-s p-h-r p-v-s mr-r bg-gray0 overflow-hidden"
            key={attachment.attachmentId}
            style={{
              width: '18rem'
            }}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="ml-s w-auto truncate cursor-pointer border-none py-2"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              {attachment.attachmentFileName}
            </div>
            {/* <DKButton
              title={attachment.attachmentFileName}
              className="ml-s w-auto truncate cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            /> */}
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };
  //////////////////// attachment functions - end /////////////////////////////////

  const isFromFieldEmpty = () => {
    return getSelectedAccount(
      filterLineItemForType(CD_TYPE.CREDIT).accountCode
    );
  };

  /////////////////// currency rate feild - start /////////////////////////////////

  const getSelectedFromField = () => {
    let selectedFromFieldAccount: any = {};
    if (accounts && accounts.length > 0) {
      // from
      const selectedFromFieldCode: any = getSelectedAccount(
        filterLineItemForType(CD_TYPE.CREDIT).accountCode
      );
      selectedFromFieldAccount = accounts.find((account: any) => {
        return account.code === selectedFromFieldCode?.value;
      });
    }
    return selectedFromFieldAccount;
  };

  const getSelectedToField = () => {
    let selectedToFieldAccount: any = {};
    if (accounts && accounts.length > 0) {
      // to
      const selectedToFieldCode: any = getSelectedAccount(
        filterLineItemForType(CD_TYPE.DEBIT).accountCode
      );
      selectedToFieldAccount = accounts.find((account: any) => {
        return account.code === selectedToFieldCode?.value;
      });
    }
    return selectedToFieldAccount;
  };

  const showCurrencyRateField = () => {
    if (
      tenantInfo.currency === getSelectedFromField()?.currency &&
      tenantInfo.currency === getSelectedToField()?.currency
    ) {
      return false;
    }
    return (
      tenantInfo.multicurrencyEnabled &&
      !Utility.isEmpty(getSelectedFromField()) &&
      !Utility.isEmpty(getSelectedToField()) &&
      je.currency
    );
  };

  const getCurrencyRateField = () => {
    return (
      <div className="column parent-width mt-s">
        <DKLabel text="Currency Rate" className="mb-xs" />
        <div
          className={'row bg-gray1 border-m rounded '}
          style={{ padding: 5 }}
        >
          <div className="row currency-select-trigger">
            <div className="ml-1">{`1 ${
              getSelectedFromField()?.currency
            }`}</div>
            <div className="currency-dropdown-flag ml-2">
              <span
                className={`currency-dropdown-selector-flag flag ${
                  getSelectedFromField()?.currency
                }`}
              ></span>
            </div>
          </div>
          {/* <DKIcon
            style={{
              transform: 'rotate(90deg)'
            }}
            src={DKIcons.ic_sort}
          /> */}
          <input
            className={`text-align-right outline-none bg-gray1`}
            style={{ width: 80 }}
            value={je?.currencyExchangeRate}
            type={'text'}
            onChange={(e: any) => {
              const val = Utility.isEmpty(e.target.value)
                ? 0
                : e.target.value.replace(/^0+/, '');
              setJE({
                ...je,
                currencyExchangeRate: val
              });
            }}
          />
          <div className="ml-1">{`${getSelectedToField()?.currency}`}</div>
          <div className="currency-dropdown-flag ml-2">
            <span
              className={`currency-dropdown-selector-flag flag ${
                getSelectedToField()?.currency
              }`}
            ></span>
          </div>
        </div>
      </div>
    );
  };

  ////////////////// currency rate field - end ////////////////////////////////////

  const getAttachFilesSection = () => {
    return (
      <div className="column parent-width mt-2">
        <DKButton
          title={
            <>
              + Attach Files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          icon={DKIcons.ic_attachment}
          className={`text-blue mt-r fw-m`}
          onClick={triggerAttachmentUpload}
        />
        <div className="row mt-r">{getAttachments()}</div>
      </div>
    );
  };

  const intercompanyTxnCheckbox = (
    <div className="flex flex-row w-full mt-5">
      <DKCheckMark
        color="bg-button"
        isSelected={je?.interCompany}
        onClick={() => {
          setJE((value: any) => ({
            ...value,
            interCompany: !value.interCompany
          }));
        }}
        className="text-black z-index-1"
        title={`Is Intercompany Transaction`}
      />
    </div>
  );

  const onPrimaryCurrencyChange = (primaryCurrencyExchangeRate: number) => {
    setJE((prevState: any) => {
      return {
        ...prevState,
        primaryCurrencyExchangeRate: primaryCurrencyExchangeRate
      };
    });
  };

  const getPrimaryCurrencyRateField = () => {
    const PrimaryCurrTotal: any =
      (je?.amount || 0) * (tempPrimaryCurrencyExchangeRate || 1);
    const primaryCurrencyCode =
      tenantInfo.additionalSettings.MULTI_COMPANY?.primaryCurrencyCode;
    return (
      je.currency !== primaryCurrencyCode &&
      getPrimaryCurrencyCheck() && (
        <div className="row parent-width mt-l mb-l justify-content-between align-items-start number-hide-arrows fs-s">
          <div className="parent-width" style={{ maxWidth: 320 }}>
            <DKLabel
              text={`Primary Conversion: ${
                je?.primaryCurrencyExchangeRate &&
                NumberFormatService.getNumber(PrimaryCurrTotal)
              }(${primaryCurrencyCode}) at`}
            />
            <div
              className={
                'row border rounded border-gray-300 w-full text-sm font-normal tracking-normal placeholder-gray-600::placeholder p-1 mt-s ' +
                'focus:outline-none focus:ring-2 focus:ring-blue-200'
              }
            >
              <div
                className="row currency-select-trigger align-items-center content-start"
                style={{ width: 'fit-content' }}
              >
                <div className="ml-1">{`1 ${tenantInfo.currency}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${tenantInfo.currency}`}
                  ></span>
                </div>
                <DKIcon
                  className="ml-r"
                  style={{
                    transform: 'rotate(90deg)'
                  }}
                  src={DKIcons.ic_sort}
                />
              </div>
              <div className="row align-items-start content-end ml-s">
                <input
                  onBlur={(e) => {
                    const rate = e.target.value
                      ? +e.target.value
                      : je.primaryCurrencyExchangeRate;
                    if (
                      +e.target.value !== 0 &&
                      je.primaryCurrencyExchangeRate !== 1 / (rate as number)
                    ) {
                      onPrimaryCurrencyChange(rate as number);
                    }
                  }}
                  className={`text-align-right outline-none hover:bg-blue-100 focus:bg-blue-100`}
                  value={tempPrimaryCurrencyExchangeRate}
                  type={'number'}
                  onChange={(e: any) => {
                    setTempPrimaryCurrencyExchangeRate(+e.target.value);
                  }}
                  style={{ width: '62%' }}
                  disabled={props.documentMode === DOCUMENT_MODE.VIEW}
                />
                <div className="ml-1">{`${primaryCurrencyCode}`}</div>
                <div className="currency-dropdown-flag ml-2">
                  <span
                    className={`currency-dropdown-selector-flag flag ${primaryCurrencyCode}`}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div>
      <div
        className="row align-items-start p-h-l justify-content-between"
        style={{ gap: 15 }}
      >
        {/* left */}
        <div className="column" style={{ width: '50%' }}>
          {getFromField()}
          {getToField()}
          {ifAccountGroupisBank() && getTransferTypeField()}
          {ifToAccountISBank() && getChequeNumberField()}
          {je.transferType === 'CHEQUE' &&
            ifToAccountISBank() &&
            getChequeDateField}
          {getAmountField()}
          {getMemoField()}
          {getPrimaryCurrencyRateField()}
        </div>

        {/* right */}
        <div className="column align-items-end" style={{ width: '50%' }}>
          {jeNumberField()}
          {getJournalDateField}
          {je.currency && getCurrencyField()}
          {showCurrencyRateField() && getCurrencyRateField()}
          {!!tenantInfo.additionalSettings?.MULTI_COMPANY &&
            intercompanyTxnCheckbox}
          {getCustomFieldView()}
        </div>
      </div>

      {getAttachFilesSection()}

      {getAddAccountPopup()}
    </div>
  );
}
