import ApiConstants from '../Constants/ApiConstants';
import { STICKER_HEIGHT, STICKER_WIDTH } from '../Constants/Constant';
import AuthService from './Auth';
import httpClient from './Interceptor';
export interface IBarcodeSettingsPayload {
  skuEnable?: boolean;
  skuRename?: string;
  activateGeneralBarcode?: boolean;
  activateGeneralBarcodeSODO?: boolean;
  barcodeType?: string;
  dpi?: number;
  height?: number;
  barcodeTopMargin?: number;
  barcodeLeftMargin?: number;
  barcodeLabelHeight?: number;
  generateBarcodeWithTenantName?: boolean;
  printCompanyNameType?: DIRECTION_TYPE;
  companyNameTranslateX?: number;
  companyNameTranslateY?: number;
  barcodeAlign?: string;
  companyNameFontSize?: number;
  companyNamePrefix?: string;
  generateBarcodeWithPriceTag?: boolean;
  printPriceType?: DIRECTION_TYPE;
  priceTranslateX?: number;
  priceTranslateY?: number;
  priceFontSize?: number;
  pricePrefix?: string;
  generateBarcodeWithProduct?: boolean;
  printProductNameDirection?: DIRECTION_TYPE;
  productNameTranslateX?: number;
  productNameTranslateY?: number;
  productNameFontSize?: number;
  barcodeTextFontSize?: number;
  productNamePrefix?: string;
  generateBarcodeWithProductCode?: boolean;
  printProductCodeDirection?: DIRECTION_TYPE;
  productCodeTranslateX?: number;
  productCodeTranslateY?: number;
  productCodeFontSize?: number;
  productCodePrefix?: string;
  generateBarcodeWithBatchManufactureDate?: boolean;
  printBatchManufactureDateDirection?: DIRECTION_TYPE;
  batchManufactureDateTranslateX?: number;
  batchManufactureDateTranslateY?: number;
  batchManufactureDateFontSize?: number;
  batchManufactureDatePrefix?: string;
  generateBarcodeWithBatchExpiryDate?: boolean;
  generateBarcodeForWorkOrderSettings?: boolean;
  generateBarcodeforJobcard?: boolean;
  generateBarcodeforJobcardAlongWithOperator?: boolean;
  printBatchExpiryDateDirection?: DIRECTION_TYPE;
  batchExpiryDateTranslateX?: number;
  batchExpiryDateTranslateY?: number;
  batchExpiryDateFontSize?: number;
  batchExpiryDatePrefix?: string;
  generateBarcodeWithRetailPrice?: boolean;
  printRetailPriceDirection?: DIRECTION_TYPE;
  maxRetailPriceTranslateX?: number;
  maxRetailPriceTranslateY?: number;
  maxRetailPriceFontSize?: number;
  maxRetailPricePrefix?: string;
  generateBarcodeforDocNoSD?: boolean;
  generateBarcodeforCustOrderNoSD?: boolean;
  generateBarcodeforDocNoforWorkOrder?: boolean;
  generateBarcodeforCustOrderNoforWorkOrder?: boolean;
  stickerHeight?: number;
  stickerWidth?: number;
}
export enum DIRECTION_TYPE {
  RIGHT = 'Right',
  LEFT = 'Left',
  TOP = 'Top',
  BOTTOM = 'Bottom'
}
export const barcodeAlignmentOptions = [
  {label:"Default", value: "start"},
  {label:"Center", value: "center"}
]
export const saveBarcodeSettings = (
  payload: any
): Promise<IBarcodeSettingsPayload> => {
  payload.generateBarcodeforJobcard =
    payload.generateBarcodeforJobcard || false;
  payload.stickerHeight = payload.stickerHeight || STICKER_HEIGHT;
  payload.stickerWidth = payload.stickerWidth || STICKER_WIDTH;
  payload.generateBarcodeForWorkOrderSettings =
    payload.generateBarcodeForWorkOrderSettings || false;
  payload.generateBarcodeforJobcardAlongWithOperator =
    payload.generateBarcodeforJobcardAlongWithOperator || false;
  return httpClient.post(
    ApiConstants.URL.TENANT.BARCODE_SETTINGS(AuthService.getUserTenantID()),
    payload
  );
};
