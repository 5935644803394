import {
  DKLabel,
  DKTooltipWrapper,
  showAlert,
  DKIcon,
  DKIcons,
  showLoader,
  removeLoader,
  DKDataGrid,
  INPUT_TYPE,
  DKSpinner
} from 'deskera-ui-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  QTY_ROUNDOFF_PRECISION,
  TIME_TYPES
} from '../../../../../Constants/Constant';
import useClickOutSide from '../../../../../Hooks/useClickOutside';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { selectWorkstations } from '../../../../../Redux/Slices/MRP/WorkstationSlice';
import DateFormatService from '../../../../../Services/DateFormat';

import { JOB_CARD_STATUS } from '../../../Constants/MRPColumnConfigs';
import AddJobCard from '../../../JobCard/AddJobCard/AddJobCard';
import {
  JobCardListPresenter,
  Job_Card_Keys,
  JC_PROCESS_TYPE,
  getRearrangedJCWithUpdatedDependencies,
  getDependentOnJCCodes,
  addNewJobcardRowInWOForm,
  isAnyUnsavedOperationAddedInWO,
  updateStartAndEndDatesAfterJCReorder,
  getJCListInvalidColumns,
  getNonEditableColumnsForJCRow,
  validateProcessTypeChange,
  allowRowDragInJobCardList
} from '../../../Shared/JobCardListComponent/JobCardListPresenter';
import {
  getStatusChipColor,
  hasWorkOrderItemsChanged,
  WorkOrderHelper
} from '../../WorkOrderHelper';
import Utility, { getCapitalized } from '../../../../../Utility/Utility';
import ConsumeProduceHelper from '../ConsumptionProduction/ConsumeProduceHelper';
import {
  buildJCPayload,
  getQueriedJobCardsProcessed,
  getStatusCard,
  renderProductNameByProductCode,
  toolTipContent
} from './JobCardHelper';
import { JCStatusChip } from './JobCardStatus';

import ProcessManufacturingService from '../../../../../Services/MRP/ProcessManufacturing';
import {
  getAlertButtons,
  getAttachedSubstitutes,
  getConsumeProcessAlertHeader,
  getConsumeProcessAlertMessage,
  getUpdatedWODetailsFromShortInfo,
  isAssignOptionVisible,
  isAvailableForQC,
  isConsumptionOptionVisible,
  isProcessOptionVisible,
  populateProducts,
  validateJCFormAndList
} from '../../../JobCard/AddJobCard/AddJobCardPresenter';
import { PROCESS_QC_STATUS } from '../../../Constants/MRPEnums';
import NumberFormatService from '../../../../../Services/NumberFormat';
import DetailsOpener from '../../../../Common/DetailsOpener';
import {
  JC_PROCESS_TYPE_DATA,
  JobCardHelper
} from '../../../JobCard/JobCardHelper';
import JobCardQC from '../../../Shared/JobCardQC/JobCardQC';
import {
  checkUserPermission,
  showNoPermissionAlert
} from '../../../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../../../Constants/Permission';
import { parseDateFromStringForWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import JobCardService from '../../../../../Services/MRP/JobCard';
import { selectOperations } from '../../../../../Redux/Slices/MRP/OperationSlice';
import ApiConstants from '../../../../../Constants/ApiConstants';
import WorkstationsService from '../../../../../Services/MRP/Workstations';

export interface IJobCardListProps {
  onRowChange: (row: any) => void;
  operationDetails: any;
  jobCards?: any;
  isReadOnly?: boolean;
  workOrderData?: any;
  onWOSaveFromJCList?: any;
  componentProductShortInfoList?: any[];
  addNewJCCallBack: (woRes: any) => void;
  isEditMode?: boolean;
  workOrders: any[];
  activeTabIndex: number;
}
const JobCardList: React.FC<IJobCardListProps> = (props: IJobCardListProps) => {
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [showAddJobCardPopup, setShowAddJobCardPopup] = useState(false);

  const workstations = useAppSelector(selectWorkstations);
  const [showQCPopup, setShowQCPopup] = useState(false);
  var presenter = new JobCardListPresenter();
  const [detailsPopupData, setDetailsPopupData] = useState<any>();
  const [localRows, setLocalRows] = useState<any>();
  const [gridColumns, setGridColumns] = useState(
    presenter.getColumnConfigs() ?? []
  );
  const [currentSelectedGrid, setCurrentSelectedGrid] = useState<any>(null);
  const [currentRowOnActionButtonClick, setCurrentRowOnActionButtonClick] =
    useState<any>(null);

  const queriedJobCards = useMemo(() => props.jobCards || [], [props.jobCards]);
  const operationsList = useAppSelector(selectOperations);

  const hasWOObjectChanged = hasWorkOrderItemsChanged(
    props.workOrderData?.workOrderItems,
    props.workOrderData?.workOrderItemsClonedCopy
  );

  const hasJCViewPermission = checkUserPermission(
    PERMISSIONS_BY_MODULE.JOB_CARD.VIEW
  );

  //constants
  let updatedWorkOrderDetails = getUpdatedWODetailsFromShortInfo(
    props?.componentProductShortInfoList,
    props.workOrderData
  );
  let allAttachedSubstitutes: any[] =
    getAttachedSubstitutes(
      updatedWorkOrderDetails?.workOrderItems || [],
      props?.componentProductShortInfoList
    ) ?? [];
  const workOrderItemWithSubstitutes = [
    ...(updatedWorkOrderDetails?.workOrderItems || []),
    ...allAttachedSubstitutes
  ];

  useEffect(() => {
    updateConfig();
  }, [queriedJobCards, currentSelectedGrid]);

  useEffect(() => {
    let rows = queriedJobCards;

    rows = getQueriedJobCardsProcessed(rows);

    setLocalRows(rows);
  }, [queriedJobCards]);

  const onAddNewJCFromWO = async ({ rowData, rowIndex }: any) => {
    let selectedOperation = rowData?.operationName;
    let workstationDetails =
      selectedOperation?.workstationDetails ??
      workstations?.content?.find(
        (workstation: any) =>
          workstation.id === selectedOperation?.defaultWorkstation
      );
    if (!workstationDetails && selectedOperation?.defaultWorkstation) {
      workstationDetails = await WorkstationsService.fetchWorkStationById(
        selectedOperation.defaultWorkstation
      );
      workstationDetails = workstationDetails?.content?.[0];
    }

    selectedOperation = { ...selectedOperation, workstationDetails };

    let operationItem = WorkOrderHelper.getOperationRow(
      props.workOrderData,
      rowData,
      selectedOperation,
      rowIndex,
      props.isEditMode ?? false,
      true
    );
    let updatedOperations = [...props.workOrderData?.workOrderOperations];

    if (rowData?.addedInBetween) {
      const position = rowIndex;

      if (position >= 0 && position < updatedOperations.length) {
        const inBetweenRow = {
          ...operationItem,
          isNewRow: true,
          addedInBetween: true,
          invalidFields: rowData.operationName?.name
            ? []
            : rowData?.invalidFields ?? []
        };
        updatedOperations.splice(position, 0, inBetweenRow);
      }
    }

    updatedOperations[rowIndex] = {
      ...operationItem,
      isNewRow: true,
      invalidFields: rowData.operationName?.name
        ? []
        : rowData?.invalidFields ?? []
    };

    const updatedWOs = [...props.workOrders];
    const updatedWorkOrder = {
      ...updatedWOs[props.activeTabIndex],
      workOrderOperations: updatedOperations
    };
    updatedWOs[props.activeTabIndex] = updatedWorkOrder;

    props.addNewJCCallBack(updatedWOs);
  };

  const updateConfig = () => {
    let config = [...gridColumns];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case Job_Card_Keys.JOB_CARD_CODE:
          conf.formatter = (data: any) => {
            return data?.rowData?.documentSequenceCode ?? '-';
          };
          break;
        case Job_Card_Keys.OPERATION_ID:
          const jobCardMappingForOperation: any =
            presenter.getJobCardMappingForRow(
              currentSelectedGrid?.rowData,
              props?.jobCards
            );
          conf.formatter = (data: any) => {
            return operationFormatter(data?.rowData);
          };
          const usedOperationIds =
            jobCardMappingForOperation?.allJCDetails?.map(
              (op: any) => op?.operationId
            );
          conf.dropdownConfig.data = operationsList?.content?.filter(
            (operation: any) => !usedOperationIds?.includes(operation?.id)
          );
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '-';
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {
            if (
              isAnyUnsavedOperationAddedInWO(
                props.workOrderData?.workOrderOperations
              )
            ) {
              showAlert(
                'Error Adding New Job Cards',
                'You cannot add new job cards because there are some operations that have not yet been saved in the Work Order (WO). Please save these operations first.'
              );
              return;
            }

            let indexOfNewOperationInOperationList =
              props?.workOrderData?.workOrderOperations?.length;

            if (currentSelectedGrid?.rowData?.addedInBetween) {
              indexOfNewOperationInOperationList =
                currentSelectedGrid?.rowIndex;
            }

            onAddNewJCFromWO({
              rowData: {
                operationName: value,
                addedInBetween:
                  currentSelectedGrid?.rowData?.addedInBetween ?? false
              },
              rowIndex: indexOfNewOperationInOperationList
            });
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            return `${ApiConstants.URL.BASE}mrp/operation/search?limit=25&search=${search}&page=0&sort=createdOn&sortDir=desc`;
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return response?.content?.filter(
              (operation: any) => !usedOperationIds?.includes(operation?.id)
            );
          };
          break;
        case Job_Card_Keys.WORKSTATION_ID:
          conf.formatter = (data: any) => {
            return workstationFormatter(data?.rowData);
          };
          break;
        case Job_Card_Keys.DEPENDENCY:
          const jobCardMapping: any = presenter.getJobCardMappingForRow(
            currentSelectedGrid?.rowData,
            props?.jobCards
          );

          let newList = jobCardMapping?.allJCDetails?.filter((jcMap: any) => {
            return !getDependentOnJCCodes(
              jobCardMapping?.allJCDetails
            )?.includes(jcMap?.jobCardCode);
          });

          newList =
            newList?.filter(
              (woJC: any) => woJC.id !== currentSelectedGrid?.rowData?.id
            ) ?? [];

          newList.unshift({ operationName: 'None' });

          conf.dropdownConfig.data = newList;
          conf.formatter = (data: any) => {
            const jobCardMapping = presenter.getJobCardMappingForRow(
              data?.rowData,
              props?.jobCards
            );
            const dependencyFound = jobCardMapping?.allJCDetails?.find(
              (jcItem: any) => {
                return (
                  jcItem.jobCardCode ===
                  data?.rowData?.jobcardDependency?.jobcardDependencyList?.[0]
                );
              }
            );
            return dependencyFound?.operationName || '-';
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.operationName ?? '-';
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {
            onSelectDependency(value);
          };
          return;
        case Job_Card_Keys.STATUS:
          conf.renderer = (data: any) => {
            const status: any = getStatusCard(data?.rowData?.status);
            return (
              <div className="column parent-width position-relative">
                <JCStatusChip
                  title={status?.title}
                  width={'auto'}
                  className={`${status?.color}`}
                  onClick={() => {}}
                />
              </div>
            );
          };
          break;
        case Job_Card_Keys.PLANNED_TIME:
          conf.formatter = (data: any) => {
            return `${data?.rowData?.plannedTime ?? 0} mins`;
          };
          break;
        case Job_Card_Keys.PLANNED_START_DATE:
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(data?.rowData?.plannedStartDate)
              ? DateFormatService.getDateStrFromDate(
                  parseDateFromStringForWorkOrder(
                    data?.rowData?.plannedStartDate as string
                  ) || new Date()
                )
              : '-';
          };
          break;
        case Job_Card_Keys.PLANNED_END_DATE:
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(data?.rowData?.plannedEndDate)
              ? DateFormatService.getDateStrFromDate(
                  parseDateFromStringForWorkOrder(
                    data?.rowData?.plannedEndDate as string
                  ) || new Date()
                )
              : '-';
          };
          break;
        case Job_Card_Keys.PROCESS_TYPE:
          conf.dropdownConfig.data = JC_PROCESS_TYPE_DATA;
          conf.formatter = (data: any) => {
            const [linkedItem = {}] = data?.rowData?.jobCardLinkDetails;
            let localProcessType =
              data?.rowData?.localProcessType ?? linkedItem?.processType;
            return Utility.isNotEmpty(localProcessType)
              ? getCapitalized(localProcessType.toLowerCase())
              : '-';
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {
            handleJCProcessChange(currentSelectedGrid?.rowData, value);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '-';
          };
          break;
        case Job_Card_Keys.ITEMS:
          if (Utility.isNotEmpty(currentSelectedGrid)) {
            const [linkedItem = {}] =
              currentSelectedGrid?.rowData?.jobCardLinkDetails;
            const processType =
              linkedItem?.processType ??
              currentSelectedGrid?.rowData?.localProcessType;
            const jobCardMappingUpdated: any =
              presenter.getJobCardMappingForRow(
                currentSelectedGrid?.rowData,
                props?.jobCards
              );
            const existingProduct: string[] =
              jobCardMappingUpdated?.allJCDetails?.reduce(
                (acc: any, jcObj: any) => {
                  if (
                    Utility.isNotEmpty(
                      jcObj.jobCardLinkDetails?.[0]?.productCode
                    )
                  ) {
                    acc.push({
                      productCode: jcObj.jobCardLinkDetails?.[0]?.productCode,
                      processType: jcObj.jobCardLinkDetails?.[0]?.processType
                    });
                  }
                  return acc;
                },
                []
              ) ?? [];
            conf.dropdownConfig.data = populateProducts(
              processType,
              workOrderItemWithSubstitutes || [],
              existingProduct
            );
          }
          conf.renderer = (data: any) => {
            const [linkedItem = {}] = data?.rowData?.jobCardLinkDetails;
            const productInfo: any = getProductByCode(linkedItem.productCode);
            return Utility.isNotEmpty(linkedItem?.productCode) ? (
              <div className="column parent-width">
                <div className="parent-width">{productInfo?.name ?? '-'}</div>
                <div className="parent-width fs-s text-gray">
                  ({productInfo.documentSequenceCode})
                </div>
              </div>
            ) : (
              '-'
            );
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <div className="parent-width">
                <div className="parent-width">{obj.name}</div>
                <div className="parent-width fs-s text-gray">
                  ({obj.documentSequenceCode})
                </div>
              </div>
            );
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {
            handleJCProductChange(currentSelectedGrid?.rowData, value);
          };
          break;
        case Job_Card_Keys.QC_NEEDED:
          let qcData = ['Yes', 'No'];
          if (
            currentSelectedGrid?.rowData?.jobCardLinkDetails?.[0]
              ?.processType === JC_PROCESS_TYPE.PROCESSING
          ) {
            qcData = ['Yes'];
          }
          conf.dropdownConfig.data = qcData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel
                style={{ width: 90 }}
                key={`${conf.name}-${currentSelectedGrid?.rowIndex}`}
                text={obj}
                className={`${getStatusChipColor(
                  obj === 'Yes' ? 'completed' : ''
                )}`}
              />
            );
          };
          conf.formatter = (data: any) => {
            const [linkedItem] = data?.rowData?.jobCardLinkDetails || {};
            if (Utility.isEmptyObject(linkedItem)) {
              return '-';
            }
            let { qcNeeded } = linkedItem;
            const classNameVal = getStatusChipColor(
              qcNeeded ? 'completed' : ''
            );
            return `<div class="${classNameVal}">${
              qcNeeded ? 'Yes' : 'No'
            }</div>`;
          };
          conf.dropdownConfig.onSelect = (index: number, value: any) => {
            if (
              currentSelectedGrid?.rowData?.localJobCardLinkDetails?.[0]
                ?.processType === JC_PROCESS_TYPE.PROCESSING
            ) {
              return;
            }

            handleQCToggle(currentSelectedGrid?.rowData, value === 'Yes');
          };
          break;
        case Job_Card_Keys.QC_STATUS:
          conf.renderer = (data: any) => {
            return renderQCStatus(conf, data?.rowData, data?.rowIndex);
          };
          break;
        case Job_Card_Keys.TOTAL_ASSIGNED_QTY:
          conf.formatter = (data: any) => {
            let quantityAssigned = ConsumeProduceHelper.getWholeQty(
              data?.rowData?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData
            );
            return NumberFormatService.getNumber(quantityAssigned);
          };
          break;
        case Job_Card_Keys.TOTAL_PRODUCED_QTY:
          conf.formatter = (data: any) => {
            let quantityProduced = JobCardHelper.getProducedQtyOnJCList(
              props?.workOrderData,
              data?.rowData
            );
            return NumberFormatService.getNumber(quantityProduced);
          };
          break;
        case Job_Card_Keys.JOB_CARD_DATE:
          conf.formatter = (data: any) => {
            return DateFormatService.getDateStrFromDate(
              DateFormatService.getDateFromStr(
                data?.rowData?.jobCardDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          };
          break;

        default:
          break;
      }
    });

    setGridColumns(config);
  };

  const saveDependency = (newDependency: any, currentUpdatedJobCard: any) => {
    const jobCardMapping = presenter.getJobCardMappingForRow(
      currentSelectedGrid?.rowData,
      props?.jobCards
    );
    let currentUpdatedJC = { ...currentUpdatedJobCard };
    let currentUpdatedJCDependencyCopy = {
      ...currentUpdatedJC.jobcardDependency
    };
    if (newDependency.operationName?.toLowerCase() === 'none') {
      currentUpdatedJCDependencyCopy = {
        jobcardDependencyList: []
      };
    } else {
      currentUpdatedJCDependencyCopy = {
        jobcardDependencyList: [newDependency?.jobCardCode]
      };
    }
    currentUpdatedJC = {
      ...currentUpdatedJC,
      jobcardDependency: currentUpdatedJCDependencyCopy
    };

    const payload = jobCardMapping?.allJCDetails?.map((item: any) => {
      if (item.jobCardCode === currentUpdatedJC?.jobCardCode) {
        item = currentUpdatedJC;
      }
      return item;
    });

    showLoader();
    JobCardService.bulkUpdateJobCard(payload)
      .then((res: any) => {
        removeLoader();
        props.onRowChange(true);
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const onSelectDependency = (newDependency: any) => {
    const jobCardMapping = presenter.getJobCardMappingForRow(
      currentSelectedGrid?.rowData,
      props?.jobCards
    );
    const updatedFormData = { ...jobCardMapping?.currentJCDetails };
    if (newDependency.operationName?.toLowerCase() === 'none') {
      let start_date = new Date(updatedFormData?.oldPlannedStartDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      saveDependency(newDependency, updatedFormData);
    } else {
      const isCyclicDependency = JobCardHelper.isCyclicDependency(
        jobCardMapping?.currentJCDetails,
        jobCardMapping?.allJCDetails,
        newDependency
      );
      const isSingularCyclicDependency =
        JobCardHelper.isSingularCyclicDependency(
          jobCardMapping?.currentJCDetails,
          jobCardMapping?.allJCDetails
        );
      if (isCyclicDependency || isSingularCyclicDependency) {
        showAlert(
          'Cyclic dependency found!',
          `You can't select this operation as its creating cyclic dependency on one of the operation.`
        );
        return;
      }
      let start_date = new Date(newDependency?.plannedEndDate);
      const plannedTimeInMins =
        Utility.calculateTimeInMinutes(
          updatedFormData?.plannedTime,
          !Utility.isEmpty(updatedFormData?.operationTimeType?.value)
            ? updatedFormData?.operationTimeType?.value
            : TIME_TYPES[1]?.value
        ) ?? 0;
      let end_date = DateFormatService.addMinutes(
        start_date,
        plannedTimeInMins
      );
      updatedFormData.plannedStartDate = start_date;
      updatedFormData.plannedEndDate = end_date;
      saveDependency(newDependency, updatedFormData);
    }
  };

  const onJobCardRowClick = (row: any) => {
    if (props.isReadOnly) return;
    setSelectedRow(row);
    setShowAddJobCardPopup(true);
  };

  /**
   * @description - this function finds the name of product from workorderData (getProductShotInfo)
   * @param productCode - code of the product against the name is required
   * @returns - name of the product
   */
  const getProductByCode = (productCode: string) => {
    const item = workOrderItemWithSubstitutes?.find((woItem: any) => {
      const code = woItem?.itemName?.productId ?? woItem?.productId;
      return code === productCode;
    });

    if (item?.isSubstitute) {
      return item;
    }

    return item?.itemName;
  };

  const renderQCStatus = (column: any, row: any, rowIndex: number) => {
    const [linkedItem] = row?.jobCardLinkDetails || {};
    if (Utility.isEmptyObject(linkedItem)) return '-';
    let {
      status,
      passedQuantity,
      rejectedQuantity,
      itemQuantity,
      qcNeeded,
      processType
    } = linkedItem;
    let updatedNameStatus =
      status === PROCESS_QC_STATUS.QA_DONE
        ? PROCESS_QC_STATUS.QC_DONE_UI_ONLY
        : status;

    if (!qcNeeded && processType === JC_PROCESS_TYPE.PRODUCTION) {
      updatedNameStatus = PROCESS_QC_STATUS.NOT_APPLICABLE_UI_ONLY;
    }

    const readableStatus = Utility.convertInTitleCase(
      updatedNameStatus?.replaceAll('_', ' ')
    );

    return (
      <div
        key={`${column.name}-${rowIndex}`}
        className={`${getStatusChipColor(
          updatedNameStatus
        )} row align-items-center justify-content-center border-radius-s p-v-xs`}
      >
        <DKTooltipWrapper
          content={toolTipContent({
            itemQuantity,
            passedQuantity,
            rejectedQuantity,
            processType
          })}
          tooltipClassName="bg-deskera-secondary width-auto"
        >
          {readableStatus ?? ''}
        </DKTooltipWrapper>
      </div>
    );
  };

  const handleJCProcessChange = (row: any, process: any) => {
    if (!validateProcessTypeChange(process, row, localRows)) {
      return;
    }

    const existingProduct: string[] =
      props?.jobCards?.reduce((acc: any, jcObj: any) => {
        if (Utility.isNotEmpty(jcObj.jobCardLinkDetails?.[0]?.productCode)) {
          acc.push({
            productCode: jcObj.jobCardLinkDetails?.[0]?.productCode,
            processType: jcObj.jobCardLinkDetails?.[0]?.processType
          });
        }
        return acc;
      }, []) ?? [];

    const products = populateProducts(
      process.key,
      workOrderItemWithSubstitutes || [],
      existingProduct
    );

    if (Utility.isEmpty(products)) {
      showAlert(
        'Please note...',
        'There are no items available to select for this process type.'
      );
      return;
    }

    let localRowsCopy = [...localRows];

    localRowsCopy = localRowsCopy?.map((itemRow: any) => {
      if (itemRow?.id === row.id) {
        itemRow = {
          ...itemRow,
          localProcessType: process.key
        };
      }
      return itemRow;
    });
    setLocalRows(localRowsCopy);
  };

  const handleJCProductChange = (row: any, product: any) => {
    const jobCardMapping = presenter.getJobCardMappingForRow(
      row,
      queriedJobCards
    );
    const formData = {
      processType: {
        key: row?.localProcessType ?? row?.jobCardLinkDetails?.[0]?.processType
      },
      jobCardCode: row?.jobCardCode
    };

    if (
      !validateJCFormAndList(
        product,
        jobCardMapping,
        formData,
        jobCardMapping?.currentJCDependentOn,
        false,
        (updatedDependentJc: any) => {}
      )
    ) {
      return;
    }

    const jcDetails = buildJCPayload(row, product);
    jcDetails.productCode = product.productId;
    jcDetails.processType =
      row?.localProcessType ?? row?.jobCardLinkDetails?.[0]?.processType;
    jcDetails.itemWarehouseInventoryData = product.warehouseInventoryData ?? [];
    updateJCDetails([jcDetails]);
  };

  const handleQCToggle = (row: any, qcNeeded: boolean) => {
    const jcDetails = buildJCPayload(row, null);
    jcDetails.qcNeeded = qcNeeded;
    updateJCDetails([jcDetails]);
  };

  const updateJCDetails = (jcDetails: any[]) => {
    showLoader();
    ProcessManufacturingService.addOrUpdateProcessDetailsJC(jcDetails)
      .then((res: any) => {
        removeLoader();
        props.onRowChange({});
      })
      .catch((err: any) => {
        removeLoader();
      });
  };

  const operationFormatter = (row: any) => {
    const operation = props.operationDetails?.find(
      (operation: any) => operation.id === row['operationId']
    );
    return operation?.name || row.operationName || '-';
  };

  const workstationFormatter = (row: any) => {
    const workstation = workstations?.content?.find(
      (workstation: any) => workstation.id === row['workstationId']
    );
    return (
      <DKTooltipWrapper
        content={workstation?.workstationName || '-'}
        tooltipClassName="bg-deskera-secondary width-auto"
      >
        <DKLabel
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: 90
          }}
          text={workstation?.workstationName || '-'}
          className="text-align-left white-space-nowrap"
        />
      </DKTooltipWrapper>
    );
  };

  const handleGridRowClick = (gridData: any) => {
    const [linkedItem = {}] = gridData?.rowData?.jobCardLinkDetails;
    if (gridData?.columnData?.key === 'processType') {
      const jobCardMapping = presenter.getJobCardMappingForRow(
        gridData?.rowData,
        props?.jobCards
      );

      if (
        Utility.isNotEmpty(linkedItem?.itemWarehouseInventoryData) ||
        Utility.isNotEmpty(linkedItem?.processType)
      ) {
        return;
      }

      if (
        Utility.isNotEmpty(jobCardMapping?.currentJCDependentOn) &&
        Utility.isEmpty(
          jobCardMapping?.currentJCDependentOn?.jobCardLinkDetails
        ) &&
        linkedItem?.jobCardLinkDetails?.[0]?.status !==
          PROCESS_QC_STATUS.QA_DONE
      ) {
        showAlert(
          'Please note.',
          `As this job card is a dependent job card, you need to tag material in dependent job card first.`
        );
        return;
      }
    } else if (gridData?.columnData?.key === 'items') {
      if (
        Utility.isNotEmpty(linkedItem?.itemWarehouseInventoryData) ||
        linkedItem.processType === JC_PROCESS_TYPE.PRODUCTION
      )
        return;
    }
    setCurrentSelectedGrid(gridData);
  };

  const onEditClick = (rowData: any) => {
    if (checkUserPermission(PERMISSIONS_BY_MODULE.JOB_CARD.EDIT)) {
      onRowOpenClick(rowData);
    } else {
      showNoPermissionAlert();
    }
  };

  const onQCClicked = (updatedRow: any) => {
    setCurrentRowOnActionButtonClick(updatedRow);
    setShowQCPopup(true);
  };

  const onConsumeOrProcessClicked = (
    type: 'consume' | 'process',
    updatedRow: any
  ) => {
    const [linkedItem = {}] = updatedRow?.jobCardLinkDetails || {};
    const onConfirm = async () => {
      ProcessManufacturingService.jcDetailsCompletion([linkedItem?.id]).then(
        (res) => {
          if (Utility.isNotEmpty(res)) {
            props.onRowChange({});
          }
        },
        (err) => {}
      );
    };
    const onCancel = () => {};
    showAlert(
      getConsumeProcessAlertHeader(type),
      getConsumeProcessAlertMessage(
        type,
        getProductByCode(linkedItem.productCode)?.name
      ),
      getAlertButtons(onConfirm, onCancel)
    );
  };

  const insertNewJobCard = (data: any) => {
    if (
      isAnyUnsavedOperationAddedInWO(props.workOrderData?.workOrderOperations)
    ) {
      showAlert(
        'Error Adding New Job Cards',
        'You cannot add new job cards because there are some operations that have not yet been saved in the Work Order (WO). Please save these operations first.'
      );
      return;
    }

    let copyLocalRows = [...localRows];
    const position = data?.rowIndex + 1; // +1 to add it below the existing row
    const newEntry = addNewJobcardRowInWOForm(true);

    if (position >= 0 && position <= copyLocalRows.length) {
      copyLocalRows.splice(position, 0, newEntry);
    }
    setLocalRows(copyLocalRows);
  };

  const getRowButtons = (row: any, rowIndex: number) => {
    let btns: any[] = [];

    let updatedRow = { ...row };

    if (updatedRow?.isNewRow) {
      btns.push({
        title: 'Delete',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          const copyLocalRows = [...localRows];
          copyLocalRows.splice(rowIndex, 1);
          setLocalRows([...copyLocalRows]);
        }
      });
      return btns;
    }

    const jobCardMapping = presenter.getJobCardMappingForRow(
      updatedRow,
      queriedJobCards
    );
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};

    updatedRow = { ...row, productCode: jcDetails?.productCode };

    const isStatusCompletedOrCancelled = [
      JOB_CARD_STATUS.COMPLETED,
      JOB_CARD_STATUS.CANCELLED
    ].includes(updatedRow.status);

    let showAssignButton = isAssignOptionVisible(
      updatedRow,
      { key: updatedRow?.jobCardLinkDetails?.[0]?.processType },
      jobCardMapping?.currentJCDependentOn
    );

    const showProducedButton =
      jcDetails?.processType === JC_PROCESS_TYPE.PRODUCTION &&
      Utility.isNotEmpty(
        JobCardHelper.getWOAttachedObj(props?.workOrderData, updatedRow)
      ) &&
      !isStatusCompletedOrCancelled;

    let showDeleteButton = JobCardHelper.showDeleteButtonOnJCList(
      jcDetails,
      isStatusCompletedOrCancelled,
      props?.workOrderData,
      updatedRow
    );

    if (showAssignButton) {
      btns.push({
        title: 'Assign',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          handleAssignClick(updatedRow);
        }
      });
    }

    if (showProducedButton) {
      btns.push({
        title: 'Produce',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          handleProduceClick(
            updatedRow,
            JobCardHelper.getWOAttachedObj(props?.workOrderData, updatedRow)
          );
        }
      });
    }

    if (showDeleteButton) {
      btns.push({
        title: 'Delete',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          handleDeleteClick(updatedRow);
        }
      });
    }

    if (isAvailableForQC(updatedRow)) {
      btns.push({
        title: 'Perform QC',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          onQCClicked(updatedRow);
        }
      });
    }

    if (isConsumptionOptionVisible(updatedRow)) {
      btns.push({
        title: 'Consume',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          onConsumeOrProcessClicked('consume', updatedRow);
        }
      });
    }

    if (isProcessOptionVisible(updatedRow)) {
      btns.push({
        title: 'Process',
        className: 'underline cursor-hand fs-m text-blue',
        onClick: (data: any) => {
          onConsumeOrProcessClicked('process', updatedRow);
        }
      });
    }

    return btns;
  };

  const getContextMenuData = (row: any, rowIndex: number) => {
    let contextMenu = [];

    let updatedRow: any = { ...row };

    if (updatedRow?.isNewRow) {
      contextMenu.push({
        title: 'Delete',
        onClick: (data: any) => {
          const copyLocalRows = [...localRows];
          copyLocalRows.splice(rowIndex, 1);
          setLocalRows([...copyLocalRows]);
        }
      });
      return contextMenu;
    }

    const jobCardMapping = presenter.getJobCardMappingForRow(
      updatedRow,
      queriedJobCards
    );
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};

    updatedRow = { ...row, productCode: jcDetails?.productCode };

    const isStatusCompletedOrCancelled = [
      JOB_CARD_STATUS.COMPLETED,
      JOB_CARD_STATUS.CANCELLED
    ].includes(updatedRow.status);

    let showAssignButton = isAssignOptionVisible(
      updatedRow,
      { key: updatedRow?.jobCardLinkDetails?.[0]?.processType },
      jobCardMapping?.currentJCDependentOn
    );

    const showProducedButton =
      jcDetails?.processType === JC_PROCESS_TYPE.PRODUCTION &&
      Utility.isNotEmpty(
        JobCardHelper.getWOAttachedObj(props?.workOrderData, updatedRow)
      ) &&
      !isStatusCompletedOrCancelled;

    let showDeleteButton = JobCardHelper.showDeleteButtonOnJCList(
      jcDetails,
      isStatusCompletedOrCancelled,
      props?.workOrderData,
      updatedRow
    );

    if (showAssignButton) {
      contextMenu.push({
        title: 'Assign',
        onClick: (data: any) => {
          handleAssignClick(updatedRow);
        }
      });
    }

    if (showProducedButton) {
      contextMenu.push({
        title: 'Produce',
        onClick: (data: any) => {
          handleProduceClick(
            updatedRow,
            JobCardHelper.getWOAttachedObj(props?.workOrderData, updatedRow)
          );
        }
      });
    }

    if (showDeleteButton) {
      contextMenu.push({
        title: 'Delete',
        onClick: (data: any) => {
          handleDeleteClick(updatedRow);
        }
      });
    }

    if (isAvailableForQC(updatedRow)) {
      contextMenu.push({
        title: 'Perform QC',
        onClick: (data: any) => {
          onQCClicked(updatedRow);
        }
      });
    }

    if (isConsumptionOptionVisible(updatedRow)) {
      contextMenu.push({
        title: 'Consume',
        onClick: (data: any) => {
          onConsumeOrProcessClicked('consume', updatedRow);
        }
      });
    }

    if (isProcessOptionVisible(updatedRow)) {
      contextMenu.push({
        title: 'Process',
        onClick: (data: any) => {
          onConsumeOrProcessClicked('process', updatedRow);
        }
      });
    }

    if (
      (!isStatusCompletedOrCancelled &&
        updatedRow?.jobCardLinkDetails?.[0]?.qcNeeded &&
        updatedRow?.jobCardLinkDetails?.[0]?.status !== 'COMPLETE') ||
      (!isStatusCompletedOrCancelled &&
        !updatedRow?.jobCardLinkDetails?.[0]?.qcNeeded)
    ) {
      contextMenu.push({
        title: 'Insert new row',
        onClick: (data: any) => {
          insertNewJobCard(data);
        }
      });
    }

    return contextMenu?.length > 0 ? contextMenu : null;
  };

  const onDragRowEndCallback = (fromIndex: number, toIndex: number) => {
    let updatedRows = getRearrangedJCWithUpdatedDependencies(
      localRows,
      fromIndex,
      toIndex
    );

    if (JSON.stringify(localRows) === JSON.stringify(updatedRows)) {
      showAlert(
        'Dependency Constraints',
        'There are dependencies that require a specific order, and drag-and-drop actions could disrupt these dependencies when placed in between jobcards that are in action.'
      );
      setLocalRows(updatedRows);
      return;
    }

    const movedJobCard = localRows?.[fromIndex];

    updatedRows = updatedRows?.map((row: any) => {
      if (row?.jobCardCode === movedJobCard?.jobCardCode) {
        row = {
          ...row,
          jobCardLinkDetails: row?.jobCardLinkDetails?.map(
            (jcLinkCard: any) => {
              return {
                ...jcLinkCard,
                isDeleted: true
              };
            }
          )
        };
      }
      return row;
    });

    // const product = workOrderItemWithSubstitutes?.find(
    //   (item: any) =>
    //     movedJobCard?.jobCardLinkDetails?.[0]?.productCode === item?.productCode
    // );
    // const jobCardMapping = presenter.getJobCardMappingForRow(
    //   movedJobCard,
    //   updatedRows
    // );
    // const formData = {
    //   processType: {
    //     key:
    //       movedJobCard?.localProcessType ??
    //       movedJobCard?.jobCardLinkDetails?.[0]?.processType
    //   },
    //   jobCardCode: movedJobCard?.jobCardCode
    // };

    // if (
    //   !validateJCFormAndList(
    //     product,
    //     jobCardMapping,
    //     formData,
    //     jobCardMapping?.currentJCDependentOn,
    //     false,
    //     (updatedDependentJc: any) => {}
    //   )
    // ) {
    //   props?.onRowChange(true);
    //   return;
    // }

    let updatedRowsWithDates =
      updateStartAndEndDatesAfterJCReorder(updatedRows);

    let apiCall = JobCardService.bulkUpdateJobCard(updatedRowsWithDates);
    apiCall
      .then((res: any) => {
        props?.onRowChange(true);
      })
      .catch((err: any) => {
        showAlert('Error', err?.message ?? 'Unable to save job card');
      })
      .finally(() => {});
  };

  const getGrid = () => {
    let invalidRowFieldsCount: number = 0;
    const updatedGridRows =
      localRows?.map((row: any, index: number) => {
        const isStatusCompletedOrCancelled = [
          JOB_CARD_STATUS.COMPLETED,
          JOB_CARD_STATUS.CANCELLED
        ].includes(row.status);
        invalidRowFieldsCount =
          invalidRowFieldsCount +
          getJCListInvalidColumns(row, localRows, isStatusCompletedOrCancelled)
            ?.length;
        return {
          ...row,
          rowButtons: getRowButtons(row, index),
          rowContextMenu: getContextMenuData(row, index),
          // allowRowEdit: !isStatusCompletedOrCancelled,
          nonEditableColumns: getNonEditableColumnsForJCRow(
            row,
            isStatusCompletedOrCancelled
          ),
          invalidFields: getJCListInvalidColumns(
            row,
            localRows,
            isStatusCompletedOrCancelled
          )
        };
      }) ?? [];
    return (
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        onRowOpenClick={(data: any) => {
          if (data?.rowData?.isNewRow) {
            showAlert(
              'Unable to open row',
              'Job card creation is pending. Please select an operation inorder to save it.'
            );
            return;
          }
          onEditClick(data?.rowData);
        }}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={updatedGridRows}
        columns={[
          ...gridColumns,
          {
            id: 'action',
            key: 'action',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            width: 250
          }
        ]}
        onRowClick={(data: any) => {
          if (data?.columnData?.key === 'status') {
            if (data?.rowData?.isNewRow) {
              showAlert(
                'Unable to open row',
                'Job card creation is pending. Please select an operation inorder to save it.'
              );
              return;
            }
            onEditClick(data?.rowData);
            return;
          }
          handleGridRowClick(data);
        }}
        allowRowDrag={allowRowDragInJobCardList(
          invalidRowFieldsCount,
          updatedGridRows
        )}
        // allowRowDrag={true}
        onDragRowEnd={(fromIndex: number, toIndex: number) => {
          onDragRowEndCallback(fromIndex, toIndex);
        }}
      />
    );
  };

  const handleAssignClick = (row: any) => {
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};
    const { jobCardLinkDetailsAvailableToAssign = [] } = row;
    const onConfirm = () => {
      const filterJCDetails = jobCardLinkDetailsAvailableToAssign.filter(
        (linkDetails: any) =>
          linkDetails?.productCode === jcDetails?.productCode
      );
      const payload = filterJCDetails?.map((item: any) => {
        let quantity = item?.warehouseInventoryData?.reduce(
          (total: number, whItem: any) => {
            const qty = whItem?.uomQuantity ?? whItem?.quantity;
            total += Number(qty ?? 0);
            return total;
          },
          0
        );

        quantity = Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);

        return {
          jobCardCode: item.jobCardCode,
          productCode: item.productCode,
          workOrderCode: item.workOrderCode,
          itemQuantity: quantity,
          uomItemQuantity: quantity,
          itemWarehouseInventoryData: item?.warehouseInventoryData ?? [],
          documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
          stockUom: item?.stockUom
        };
      });

      ProcessManufacturingService.assignAllocatedQuantities(payload)
        .then((res) => {
          if (Utility.isNotEmpty(res)) {
            props.onRowChange({});
          }
        })
        .catch((err) => {});
    };
    const onCancel = () => {};
    showAlert(
      'Confirm assignment to material',
      `Are you sure, you want to assign all the allocated quantities to <span class="fw-b">${renderProductNameByProductCode(
        jcDetails?.productCode,
        props.workOrderData
      )}</span>?`,
      getAlertButtons(onConfirm, onCancel)
    );
  };

  const handleProduceClick = (row: any, woData: any) => {
    setDetailsPopupData({
      documentSeqCode: woData?.workOrderSeqCode,
      documentType: DOC_TYPE.WORK_ORDER,
      showDetailsOpener: true
    });
  };

  const handleDeleteClick = (row: any) => {
    const [jcDetails = {}] = row?.jobCardLinkDetails || {};
    const onDelete = () => {
      if (Utility.isNotEmpty(jcDetails?.id?.toString())) {
        ProcessManufacturingService.deleteProcessDetailsJC([jcDetails.id])
          .then((res: any) => {
            if (Utility.isNotEmpty(res)) {
              props.onRowChange({});
            }
          })
          .catch((error: any) => {});
      }
    };
    const onCancel = () => {};

    showAlert(
      'Confirm deletion of material',
      `Are you sure, you want to delete material <span class="fw-b">${
        jcDetails?.productCode ?? ''
      }</span>?`,
      getAlertButtons(onDelete, onCancel)
    );
  };

  const onRowOpenClick = (row: any) => {
    if (presenter.isLinkedInventory && hasWOObjectChanged) {
      let buttons = [
        {
          title: 'Cancel',
          className: 'bg-gray2 border-m ',
          onClick: () => {}
        },
        {
          title: 'Save',
          className: 'bg-button text-white ml-r',
          onClick: () => {
            props?.onWOSaveFromJCList();
          }
        }
      ];
      showAlert(
        'Warning !!!',
        'There has been an update in your current Work Order, Please save your current Work Order to update Job Card details',
        buttons
      );
      return;
    }
    onJobCardRowClick(row);
  };

  const renderEmptyState = () => {
    return (
      <div className="column parent-size align-items-center justify-content-center">
        No job cards found for this work order
        <br />
        <span className="text-gray">
          It will start appearing here once available
        </span>
      </div>
    );
  };
  const totalJobCards = hasJCViewPermission
    ? props?.workOrderData?.totalJobCards
    : 0;
  const getHeader = () => {
    return (
      <div className="row parent-width justify-content-between">
        <div className="row width-auto">
          <DKLabel text="Job Card Details" className="fw-m text-app-color" />
          <DKLabel
            text={totalJobCards || 0}
            className="column p-xs bg-chip-red text-red circle ml-r justify-content-center align-items-center"
            style={{ width: 20, height: 20 }}
          />
          {props.workOrderData?.workOrderJobCardListFetchInProgress && (
            <DKSpinner iconClassName="ic-s ml-s" />
          )}
        </div>
        <div className="row width-auto">
          {Utility.isProcessManufacturingAndAdhocEnabledForMRP() &&
            !props.isReadOnly && (
              <div
                className="fw-m text-app-color mr-l cursor-hand "
                onClick={() => {
                  if (
                    isAnyUnsavedOperationAddedInWO(
                      props.workOrderData?.workOrderOperations
                    )
                  ) {
                    showAlert(
                      'Error Adding New Job Cards',
                      'You cannot add new job cards because there are some operations that have not yet been saved in the Work Order (WO). Please save these operations first.'
                    );
                    return;
                  }

                  let copyLocalRows = [...localRows];
                  copyLocalRows.push(addNewJobcardRowInWOForm());
                  setLocalRows(copyLocalRows);
                }}
              >
                + Add Job Card
              </div>
            )}
          {!props.workOrderData?.actualTime && (
            <DKTooltipWrapper
              content="Actual time will be updated on work order completion."
              tooltipClassName=""
            >
              <DKIcon
                src={DKIcons.ic_info}
                className="ic-xs opacity-40 mr-xs cursor-hand"
                onClick={() => {}}
              />
            </DKTooltipWrapper>
          )}
          <DKLabel text="Actual Time" className="fw-m text-app-color mr-xs" />
          <DKLabel
            text={`${
              props.workOrderData?.actualTime
                ? props.workOrderData?.actualTime + ' min'
                : '-'
            }`}
            className="mr-l"
          />
          <DKLabel
            text="Estimated Time"
            className="fw-m text-app-color mr-xs"
          />
          <DKLabel
            text={`${
              props.workOrderData?.estimatedTime
                ? props.workOrderData?.estimatedTime + ' min'
                : '-'
            }`}
            className=""
          />
        </div>
      </div>
    );
  };

  const getJobCardQCPopup = () => {
    return showQCPopup ? (
      <JobCardQC
        productCode={currentRowOnActionButtonClick?.productCode}
        jobCardCode={currentRowOnActionButtonClick?.jobCardCode}
        jobCardDocumentSequenceCode={
          currentRowOnActionButtonClick?.documentSequenceCode
        }
        documentUOMSchemaDefinition={
          currentRowOnActionButtonClick
            ?.jobCardLinkDetailsAvailableToAssign?.[0]
            ?.documentUOMSchemaDefinition ??
          currentRowOnActionButtonClick?.jobCardLinkDetails?.[0]
            ?.documentUOMSchemaDefinition
        }
        woCode={currentRowOnActionButtonClick?.workOrderCode ?? ''}
        onClose={() => {
          setShowQCPopup(false);
          setCurrentRowOnActionButtonClick(null);
        }}
        onSuccess={() => {
          setShowQCPopup(false);
          const [linkedItem = {}] =
            currentRowOnActionButtonClick?.jobCardLinkDetails || {};
          ProcessManufacturingService.jcDetailsCompletion([
            linkedItem?.id
          ]).then(
            (res) => {
              if (Utility.isNotEmpty(res)) {
                setCurrentRowOnActionButtonClick(null);
                props.onRowChange({});
              }
            },
            (err) => {}
          );
        }}
      />
    ) : null;
  };

  const showEmptyState = Utility.isEmpty(localRows);

  /** Main Renderer */
  return (
    <div
      className="column parent-height bg-white border-m border-radius-m p-l overflow-hidden"
      style={{ width: '59%', height: 'auto', maxHeight: 705 }}
    >
      {getHeader()}
      <div className="column parent-size">
        {!showEmptyState && hasJCViewPermission && (
          <div className="column parent-size overflow-x-scroll">
            {getGrid()}
          </div>
        )}
        {(showEmptyState || !hasJCViewPermission) && renderEmptyState()}
        {showAddJobCardPopup && (
          <AddJobCard
            editableJobCard={selectedRow}
            column
            onCancel={() => {
              setShowAddJobCardPopup(false);
              setSelectedRow({});
            }}
            updateJCList={() => {
              props.onRowChange({});
            }}
            onSuccess={() => {
              setShowAddJobCardPopup(false);
              setSelectedRow({});
              props.onRowChange(selectedRow);
            }}
          />
        )}
        {!Utility.isEmptyObject(detailsPopupData) &&
          detailsPopupData?.showDetailsOpener && (
            <DetailsOpener
              data={detailsPopupData}
              onCancel={() => {
                setDetailsPopupData(null);
                props.onRowChange(true);
              }}
              isReadOnly={false}
            />
          )}
        {showQCPopup && getJobCardQCPopup()}
      </div>
    </div>
  );
};
export default JobCardList;
