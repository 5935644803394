import { Fragment, useEffect, useRef, useState } from 'react';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import {
  DOC_TYPE_TO_ATTACHMENT_MAP,
  BOOKS_ADDRESS_TYPES,
  DOC_TYPE,
  MODULES_NAME,
  BOOKS_DATE_FORMAT,
  STATUS_TYPE,
  DOCUMENT_MODE,
  TRACKING_TYPE,
  PRODUCT_TYPE,
  TAX_SYSTEM,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_OFFERING_TYPE,
  LOCATION_CLASS_ENUM,
  POPUP_TYPE,
  POPUP_CLICK_TYPE,
  COUNTRY_CODES,
  CURRENCY_PRECISION,
  CLASS_ASSIGNMENT,
  CUSTOM_NUMBER_INPUT_MODULES,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  COMMON_CONSTANT,
  GST_TYPE,
  INPUT_VIEW_DIRECTION,
  COMPLAINCE_CURRENCY,
  PRODUCT_TRANSACTIONTYPE_PURCHASE
} from '../../Constants/Constant';
import AttachmentService from '../../Services/Attachment';
import {
  BooksAddress,
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  LocationDTO,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { DraftTypes } from '../../Models/Drafts';
import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKButton,
  showToast,
  showAlert,
  DKDataGrid,
  INPUT_TYPE,
  DKCalendar,
  DKListPicker2,
  TOAST_TYPE,
  DKInput,
  DKCheckMark
} from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';
import {
  createLineItem,
  rcmAppliedIndia,
  roundingOffStr,
  convertToCurrenctExchangeRate,
  isGSTExchangeRateRequired
} from './NewDocumentHelper';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import DateFormatService from '../../Services/DateFormat';
import CustomNumberFormatInput from '../CustomNumberFormat/CustomNumberFormatInput';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import { addDays, subDays } from 'date-fns';
import {
  activeTenantInfo,
  appCustomizationInfo
} from '../../Redux/Slices/AuthSlice';
import {
  BillingAddressType,
  Document,
  ShippingAddressType
} from '../../Models/Document';
import {
  fetchOrders,
  selectPurchaseOrders as selectRequisitionOrders
} from '../../Redux/Slices/RequisitionSlice';
import { OrderInitialState } from '../../Models/RequisitionOrder';
import {
  DocumentItem,
  DocumentItemDefaultObj
} from '../../Models/DocumentItem';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';
import { INDIAN_STATES_MOCK_DATA } from '../../Constants/StaticData';
import {
  fetchTaxes,
  selectUOMs,
  selectCustomFields,
  fetchUOMs,
  selectCurrencyListWithExchangeRate,
  fetchCurrencyExchangeRateByDocDate,
  selectCurrencyListWithExchangeRateByDocDate
} from '../../Redux/Slices/CommonDataSlice';
import {
  checkContact,
  rebuildAdvancedTrackingMetaDtosAndUOMInfo,
  getMainModuleName,
  getValidTillDateFromDocDate,
  getNewColumnForCF,
  getFormattedAddressObj,
  getTenantTaxSystem,
  getNewCFItem,
  updateGstType
} from './NewDocumentHelper';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import ic_inventory_fulfill from '../../Assets/Icons/ic_inventory_fulfill.svg';
import ic_inventory_pending from '../../Assets/Icons/ic_inventory_pending.svg';
import PriceListService from '../../Services/PriceList';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import {
  fetchContacts,
  selectContacts
} from '../../Redux/Slices/ContactsSlice';
import {
  fetchProductsWithVariants,
  selectProductsWithVariants
} from '../../Redux/Slices/ProductsSlice';
import ProductService from '../../Services/Product';
import {
  fetchCategoryDimensions,
  locationsData,
  selectDimensions
} from '../../Redux/Slices/LocationSlice';
import { CONTACT_FORM_TAB, ORGANISATION_FORM_TAB } from '../../Constants/Enum';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import {
  selectSalesTax,
  selectPurchaseTax
} from '../../Redux/Slices/CommonDataSlice';
import {
  fetchProducts,
  selectProducts
} from '../../Redux/Slices/ProductsSlice';
import DocumentSummaryView from './DocumentSummaryView';
import { Store } from '../../Redux/Store';
import { makePaymentMethod } from '../../Models/PaymentMethod';
import AccountsService from '../../Services/Accounts';
import { ItemTaxCalculator } from './ItemTaxCalculator';
import TaxService from '../../Services/Tax';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import DocumentActionMenu from './DocumentActionMenu';
import { SupplierQuote } from '../../Models/SupplierQuote';
import { GSTExchangeRateDialog } from './GSTExchangeRateDialog';
import AuthService from '../../Services/Auth';
import CreateProductView from '../../Components/Product/CreateProductView';
export interface SupplierQuoteProps2 {
  booksDocument: SupplierQuote;
  draftType?: any;
  documentMode?: DOCUMENT_MODE;
  canValidate?: any;
  onDocumentUpdate: (document: Document) => void;
  draftData?: any;
  permissionKeys: any;
  id: any;
  RfqToSQuote?: boolean;
}

export default function SupplierQuoteForm(props: SupplierQuoteProps2) {
  const [booksDocument, setBooksDocument] = useState<any>(props.booksDocument);
  const currentDate = new Date();
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const [openCustomNumberList, setopenCustomNumberList] = useState(false);
  const [editModeShippingAddresses, setEditModeShippingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.shippingAddress);

  const [supplierQuoteDateOpen, setSupplierQuoteDateOpen] = useState(false);
  const [documentDate, setDocumentDate] = useState<Date>(currentDate);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [validDateOpen, setvalidDateOpen] = useState(false);
  const laterDate = addDays(currentDate, 30);
  const [validDate, setvalidDate] = useState<Date>(laterDate);
  const [receiveByDateOpen, setreceiveByDateOpen] = useState(false);
  const [receiveByDate, setreceiveByDate] = useState<Date>(currentDate);
  const { t, i18n } = useTranslation();
  const [checkState, setCheckState] = useState(false);
  const [showProductPopup, setShowProductPopup] = useState(false);
  const GENERALFIELD_LABEL_WIDTH = 170;
  const GENERALFIELD_VALUE_WIDTH = 120;
  const [showSRPicker, setShowSRPicker] = useState(false);
  const [sr, setSR] = useState<any>();
  const [canValidate, setCanValidate] = useState(false);
  const RequisitionData = useAppSelector(selectRequisitionOrders);
  const [existingLineNumbers, setExistingLineNumbers] = useState<number[]>([]);
  const [productRows, setProductRows] = useState<DocumentItem[]>([]);
  const lastUpdatedIndex = useRef<any>(null);
  const uomsData = useAppSelector(selectUOMs);
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();
  const [productsLastUnitPrice, setProductsLastUnitPrice] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [lastUpdatedColumn, setLastUpdatedColumn] = useState<any>(null);
  const isQuantityChangedManually = useRef<boolean>(false);
  const appCustomizationData = useAppSelector(appCustomizationInfo);
  const [contact, setContact] = useState<any>(null);
  const isUOMChangedManually = useRef<boolean>(false);
  const dimensionData = useAppSelector(selectDimensions);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [showReserveStockPopup, setShowReserveStockPopup] = useState(false);
  const [reserveStockLineItem, setReserveStockLineItem] = useState<any>();
  const productsData = useAppSelector(selectProductsWithVariants);
  const isContactChangedManually = useRef<boolean>(false);
  const [openContactSelector, setOpenContactSelector] = useState(false);
  const [reservedQuantitiesOriginal, setReservedQuantitiesOriginal] =
    useState<any[]>();
  const shippingAddressBlock = useRef<any>(null);
  const contactsData = useAppSelector(selectContacts);
  const [showLandedCostPopup, setShowLandedCostPopup] = useState(false);
  const [billingAddress, setBillingAddress] =
    useState<BillingAddressType | null>(null);
  const [shippingAddress, setShippingAddress] =
    useState<ShippingAddressType | null>(null);
  const [customLocation, setCustomLocation] = useState<LocationDTO>();
  const [currentRowLandedCostInfo, setCurrentRowLandedCostInfo] =
    useState<any>(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<any>();
  const [contactMode, setContactMode] = useState(DOCUMENT_MODE.NEW);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const [openShipFromForBuy, setOpenShipFromForBuy] = useState(false);
  const [showUpdateOrgPopup, setShowUpdateOrgPopup] = useState(false);
  const [shipToOrFromAddressChangedForUS, setShipToOrFromAddressChangedForUS] =
    useState(false);
  const [activeOrgProfileTab, setActiveOrgProfileTab] =
    useState<ORGANISATION_FORM_TAB>(ORGANISATION_FORM_TAB.GENERAL_INFO);
  const [indianStatesOptions, setIndianStatesOptions] = useState<any>([]);
  const [pos, setPos] = useState<any>([]);
  const [purchaseRequestCode, setPurchaseRequestCode] = useState<any>();
  const [purchaseRequestDocumentCode, setPurchaseRequestDocumentCode] =
    useState<any>();
  const [dos, setDos] = useState<any>([]);
  const allProductsData = useAppSelector(selectProducts);
  const [activeContactTab, setActiveContactTab] = useState<CONTACT_FORM_TAB>(
    CONTACT_FORM_TAB.GENERAL_INFO
  );

  const [lineItemsTouched, setLineItemsTouched] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedProductPriceList, setSelectedProductPriceList] = useState<
    number[]
  >([]);
  const [needToUpdateAddresses, setNeedToUpdateAddresses] = useState(
    props.booksDocument?.needToUpdateAddresses ? true : false
  );
  const [columnConfig, setColumnConfig] = useState(
    DocumentConfigManager.get('SUPPLIER_QUOTE')
  );
  const availableCustomFields = useAppSelector(selectCustomFields);
  const [requisitionStateData, setRequisitionStateData] =
    useState<any>(OrderInitialState);
  const [roundOffDirty, setRoundOffDirty] = useState<any>(
    props.documentMode === DOCUMENT_MODE.VIEW ||
      props.documentMode === DOCUMENT_MODE.EDIT ||
      props.documentMode === DOCUMENT_MODE.COPY
      ? true
      : false
  );
  const salesTaxes = useAppSelector(selectSalesTax);
  const purchaseTaxes = useAppSelector(selectPurchaseTax);
  const [filteredTaxList, setFilteredTaxList] = useState<any[]>([]);
  const [editModeBillingAddresses, setEditModeBillingAddresses] = useState<
    BooksAddress[]
  >(booksDocument.contactDto?.billingAddress);
  const [openBillingAddressList, setOpenBillingAddressList] = useState(false);
  const [openShippingAddressList, setOpenShippingAddressList] = useState(false);
  const [dropshipShipToContact, setDropshipShipToContact] = useState<string>();
  const [booksAddressType, setBooksAddressType] = useState<BOOKS_ADDRESS_TYPES>(
    BOOKS_ADDRESS_TYPES.NONE
  );
  const [dueAmount, setDueAmount] = useState<number>(
    (props.booksDocument.isPartialInvoice
      ? props.booksDocument.totalAmount
      : props.booksDocument.dueAmount) || 0
  );
  const [paymentAccountGroupsOptions, setPaymentAccountGroupsOptions] =
    useState<any[]>([]);
  const [currentRowGSTInfo, setCurrentRowGSTInfo] = useState<any>(null);
  const usTaxCalculateAlertVisible = useRef<boolean>(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [billToValue, setBillToValue] = useState();
  const [shipToValue, setShipToValue] = useState();
  const [showTaxExchangeRatePopup, setShowTaxExchangeRatePopup] =
    useState(false);
  const [productToEdit, setProductToEdit] = useState<any>(null);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRateByDocDate
  );
  const additionalCharges = useRef<any>(null);
  const PurchaseTaxData = useAppSelector(selectPurchaseTax);
  const [isdocumentDateChanged, setIsdocumentDateChanged] =
    useState<Boolean>(false);
  useEffect(() => {
    props.onDocumentUpdate(booksDocument);
  }, [booksDocument]);

  useEffect(() => {
    if (!purchaseTaxes?.length) {
      dispatch(fetchTaxes());
    }
  }, []);

  useEffect(() => {
    setBooksDocument((prevState: any) => ({
      ...prevState,
      items: productRows
    }));
  }, [productRows]);

  useEffect(() => {
    if (props.documentMode === DOCUMENT_MODE.VIEW) {
      if (!Utility.isEmpty(booksDocument.linkedDocuments)) {
        const LinkedPurchaseRequest = booksDocument.linkedDocuments.find(
          (doc: any) => {
            return doc.documentType === 'PURCHASE_REQUEST';
          }
        );
        if (!Utility.isEmpty(LinkedPurchaseRequest)) {
          setCheckState(true);
          setSR(LinkedPurchaseRequest.documentSequenceCode);
        }
      }
      setBooksDocument((prevState: Document) => {
        return {
          ...prevState,
          items: productRows,
          unitPriceGstInclusive: props.booksDocument.unitPriceGstInclusive
        };
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      fetchProductsWithVariants({
        searchTerm: '',
        query: 'active=true,hasVariants=false',
        params: PRODUCT_TRANSACTIONTYPE_PURCHASE
      })
    );
  }, []);
  useEffect(() => {
    setBooksDocument((prevState: any) => ({
      ...prevState,
      purchaseRequestCode: purchaseRequestCode,
      purchaseRequestSequenceCode: purchaseRequestDocumentCode
    }));
  }, [purchaseRequestCode, purchaseRequestDocumentCode]);

  useEffect(() => {
    const itemData = productRows;
    itemData?.forEach((item: any, index: number) => {
      calculateTaxesAndAmount(index, itemData);
    });
  }, [pos, dos]);

  useEffect(() => {
    updateConfig();
  }, [
    productsData,
    salesTaxes,
    purchaseTaxes,
    uomsData,
    productRows,
    selectedProductPriceList,
    selectedProductUOMS,
    props.draftType,
    props.documentMode
  ]);

  useEffect(() => {
    if (
      lastUpdatedIndex.current !== null &&
      !Utility.isEmpty(productRows[lastUpdatedIndex.current as any]?.product)
    ) {
      let index = lastUpdatedIndex.current as number;
      lastUpdatedIndex.current = null;
    }
  }, [productRows]);

  useEffect(() => {
    if (!Utility.isEmpty(RequisitionData.requisition)) {
      const tmpRequisitionData = RequisitionData.requisition.content;
      setRequisitionStateData(tmpRequisitionData);
    }
  }, [RequisitionData]);

  useEffect(() => {
    if (!Utility.isEmpty(contact) && !props.RfqToSQuote) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(false);

      const updateAddresses =
        Utility.isEmpty(booksDocument.contact) ||
        booksDocument.contactCode !== contact.code ||
        needToUpdateAddresses ||
        booksDocument.isPartialInvoice ||
        booksDocument.isPartialBill;

      let tempDocument = {
        ...booksDocument,
        contact: contact,
        contactCode: contact.code
      };

      if (
        props.documentMode === DOCUMENT_MODE.NEW ||
        (props.documentMode === DOCUMENT_MODE.COPY &&
          isContactChangedManually.current)
      )
        if (!tempDocument.documentDate)
          // Check for opening tax exchange rate popup

          tempDocument.documentDate = DateFormatService.getDateStrFromDate(
            currentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          );
      tempDocument = checkContact(
        { ...tempDocument },
        { ...contact },
        updateAddresses
      );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        if (Utility.isSalesDocument(props.booksDocument)) {
          let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
            ? tempDocument?.shipTo?.placeOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;
          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === state?.toLowerCase()
          );
          setPos(pos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
        if (
          props.booksDocument.documentType === DOC_TYPE.BILL ||
          props.booksDocument.documentType === DOC_TYPE.ORDER ||
          props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
          Utility.isDropship(props.booksDocument)
        ) {
          let posState: any = !Utility.isEmpty(
            tempDocument?.shipFrom?.placeOfSupply
          )
            ? tempDocument?.shipFrom?.placeOfSupply
            : tempDocument?.shipFrom?.state || shippingAddress?.state;

          let pos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
          );

          setPos(pos[0]);
          let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null;
          let shipFromAddress = {
            ...shipFrom,
            placeOfSupply: pos ? pos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipFrom: shipFromAddress };

          let dosState: any = !Utility.isEmpty(
            tempDocument?.shipTo?.destinationOfSupply
          )
            ? tempDocument?.shipTo?.destinationOfSupply
            : tempDocument?.shipTo?.state || shippingAddress?.state;

          let dos = indianStatesOptions.filter(
            (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
          );
          setDos(dos[0]);
          let shipToAddress = {
            ...tempDocument.shipTo,
            destinationOfSupply: dos ? dos?.[0]?.value : ''
          };
          tempDocument = { ...tempDocument, shipTo: shipToAddress };
        }
      }
      //  Keep the shipTo value if document is not new
      if (props.documentMode !== DOCUMENT_MODE.NEW) {
        tempDocument = {
          ...tempDocument,
          shipTo: booksDocument.shipTo
        };
      }

      //Update validTillDate only if contact is changed manually
      if (isContactChangedManually.current) {
        // Load account groups if contact currency is different
        if (props.draftData?.data?.isCashInvoice) {
          loadAccountGroups(tempDocument.currency);
        }
      } else {
        // Set initial dates while opening an existing document, if contact is not changed manually
        tempDocument = {
          ...tempDocument,
          documentDate: props.booksDocument.documentDate
            ? props.booksDocument.documentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          fulfillmentDate: props.booksDocument.fulfillmentDate
            ? props.booksDocument.fulfillmentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          validTillDate: props.booksDocument.validTillDate
            ? props.booksDocument.validTillDate
            : DateFormatService.getDateStrFromDate(
                laterDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )
        };
      }

      tempDocument = {
        ...tempDocument,
        items: [...tempDocument.items].sort(
          (item1: any, item2: any) => item1.lineNumber - item2.lineNumber
        )
      };

      tempDocument = {
        ...tempDocument,
        items: tempDocument.items?.map((item: any) => {
          const tdsInfoIndia =
            props.documentMode === DOCUMENT_MODE.EDIT ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            (props.draftType === DraftTypes.DRAFT &&
              props.booksDocument?.contact?.code === contact.code)
              ? item.tdsInfoIndia
              : undefined;
          let tax =
            props.documentMode === DOCUMENT_MODE.NEW
              ? !Utility.isEmpty(item.tax)
                ? item.tax
                : getTax({ ...item.product })
              : !Utility.isEmpty(item.tax)
              ? item.tax
              : null;
          return { ...item, gstType: tempDocument.gstType, tdsInfoIndia, tax };
        })
      };

      // const isBillOrOrder = [DOC_TYPE.BILL, DOC_TYPE.ORDER].includes(
      //   props.booksDocument.documentType
      // );

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        let gstType = booksDocument.gstType;
        gstType = updateGstType(tempDocument);
        tempDocument = {
          ...tempDocument,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }

      const contactCurrency = activeMultiCurrencyList.find(
        (currency: any) => currency.currencyCode === contact?.currencyCode
      );

      if (typeof contactCurrency !== 'undefined' && contactCurrency !== null) {
        let currencyUpdatedDoc = updateCurrencyAndExchangeRate(
          tempDocument,
          contactCurrency.currencyCode,
          contactCurrency.currencyExchangeRate,
          false
        );
        tempDocument = {
          ...tempDocument,
          ...currencyUpdatedDoc
        };
      }

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument,
          billTo: Utility.isEmpty(tempDocument.billTo)
            ? billingAddress
            : tempDocument.billTo,
          shipTo:
            props.documentMode !== DOCUMENT_MODE.NEW
              ? tempDocument.shipTo
              : tempDocument.shipTo || shippingAddress,
          shipFrom: !Utility.isEmpty(tempDocument.shipFrom)
            ? tempDocument.shipFrom
            : tenantInfo.shippingAddresses?.length
            ? tenantInfo.shippingAddresses[0]
            : null
        };
      });
      setProductRows(tempDocument.items);
      setNeedToUpdateAddresses(false);

      if (productRows.length && isContactChangedManually.current) {
        const productsPayload = productRows
          .filter((item) => !Utility.isEmpty(item.product))
          .map((item) => ({
            productId: item.product?.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          tempDocument.currency,
          true,
          tempDocument.exchangeRate,
          { contactChanged: true },
          { shipTo: tempDocument.shipTo, shipFrom: tempDocument.shipFrom }
        );
      }
    }
  }, [contact]);
  useEffect(() => {
    if (!Utility.isEmpty(contact) && !props.RfqToSQuote) {
      let { billingAddress, shippingAddress } =
        updateBillingShippingAddress(false);

      let tempDocument = {
        ...booksDocument,
        contact: contact,
        contactCode: contact.code
      };
      let state = !Utility.isEmpty(tempDocument?.shipTo?.placeOfSupply)
        ? tempDocument?.shipTo?.placeOfSupply
        : tempDocument?.shipTo?.state || shippingAddress?.state;
      let posState: any = !Utility.isEmpty(
        tempDocument?.shipFrom?.placeOfSupply
      )
        ? tempDocument?.shipFrom?.placeOfSupply
        : tempDocument?.shipFrom?.state || shippingAddress?.state;

      let dosState: any = !Utility.isEmpty(
        tempDocument?.shipTo?.destinationOfSupply
      )
        ? tempDocument?.shipTo?.destinationOfSupply
        : tempDocument?.shipTo?.state || shippingAddress?.state;

      let pos = indianStatesOptions.filter(
        (ele: any) => ele?.value?.toLowerCase() === posState?.toLowerCase()
      );
      setPos(pos[0]);
      let shipFrom = !Utility.isEmpty(tempDocument.shipFrom)
        ? tempDocument.shipFrom
        : tenantInfo.shippingAddresses?.length
        ? tenantInfo.shippingAddresses[0]
        : null;
      let shipFromAddress = {
        ...shipFrom,
        placeOfSupply: pos ? pos?.[0]?.value : ''
      };

      let dos = indianStatesOptions.filter(
        (ele: any) => ele?.value?.toLowerCase() === dosState?.toLowerCase()
      );
      setDos(dos[0]);

      let shipToAddress = {
        ...tempDocument.shipTo,
        destinationOfSupply: dos ? dos?.[0]?.value : ''
      };
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress
      };
      if (tempDocument.items && tempDocument.items.length > 0) {
        if (productRows.length && getTenantTaxSystem() === TAX_SYSTEM.US) {
          //calculateUSTaxOnContactChange(tempDocument);
        } else {
          // tempDocument.items.forEach((rowData: any, index: number) => {
          //   calculateTaxesAndAmount(index, tempDocument.items);
          // });
        }
      }
    }
  }, [contact]);

  useEffect(() => {
    if (
      !props.RfqToSQuote &&
      Utility.isEmpty(booksDocument?.requisitionDocumentCode) &&
      props.documentMode !== DOCUMENT_MODE.VIEW
    ) {
      setProductRows([]);
      setPurchaseRequestCode(undefined);
    }
  }, [checkState]);

  useEffect(() => {
    if (
      props.RfqToSQuote === true
      //&& !Utility.isEmpty(booksDocument?.requisitionDocumentCode)
    ) {
      setCheckState(true);
      let contact: any[] = [];
      setSR(booksDocument?.requisitionDocumentCode);
      if (booksDocument.vendorCode && !Utility.isEmpty(contactsData.content)) {
        contact = contactsData.content.filter((contacts: any) => {
          return contacts.code === booksDocument.vendorCode;
        });
        setContact(contact[0]);
      }

      const itemData = booksDocument?.purchaseRequestForQuotesItems?.map(
        (element: any, index: any) => {
          const product = getProduct(element.productCode);
          setUomsForSelectedProduct(product);
          const uom = uomsData.filter((uomItem: any) => {
            return uomItem.id === element.documentUom;
          });

          const tax = product ? getTax(product) : '';
          let percent = 0;
          let price = 0;
          let quantity = 0;
          let taxAmount = 0;
          let totalAmount = 0;
          if (!Utility.isEmpty(tax)) {
            percent = tax.percent * 0.01;
            price = +element.unitPrice * 1;
            quantity = element.productQuantity;
            taxAmount = Number((percent * price).toFixed(2));
            totalAmount = (taxAmount + price) * quantity;
          }
          let data = {
            customField: element.customField,
            discount: element.discount,
            discountInPercent: element.discountInPercent,
            documentSequenceCode: element.documentSequenceCode,
            documentUOMSchemaDefinition: element.documentUOMSchemaDefinition,
            documentUom: element.documentUom,
            lineNumber: element.lineNumber,
            product: product,
            productCode: element.productCode,
            productDescription: element.productDescription,
            productOrder: element.productOrder,
            productQuantity: element.productQuantity,
            taxAmount: taxAmount,
            taxSystem: getTenantTaxSystem(),
            taxCode: !Utility.isEmpty(tax) ? tax.taxCode : null,
            taxName: !Utility.isEmpty(tax) ? tax.name : null,
            tax: tax,
            uom: uom[0],
            taxPreference: true,
            isTaxable: product ? product.taxable : '',
            totalAmount: totalAmount,
            unitPrice: product ? product.purchasePrice : 0,
            uomQuantity: element.productQuantity,
            uomUnitPrice: 0,
            unitPriceGstInclusive: false,
            gstType: 1
          };
          return data;
        }
      );
      setPos({
        label: booksDocument.shipFrom.state,
        value: booksDocument.shipFrom.state
      });
      setDos({
        label: booksDocument.shipTo.state,
        value: booksDocument.shipTo.state
      });
      setDocumentDate(
        DateFormatService.getDateFromStr(
          booksDocument.purchaseRequestForQuotesDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
      setvalidDate(
        DateFormatService.getDateFromStr(
          booksDocument.shipByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
      //need to change when will have date from backend
      setreceiveByDate(
        DateFormatService.getDateFromStr(
          booksDocument.receiveByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      );
      let tempDocument = {
        ...booksDocument,
        contact: contact[0],
        contactCode: contact[0].code,
        items: itemData
      };
      const contactCurrency = activeMultiCurrencyList.find(
        (currency: any) => currency.currencyCode === contact[0]?.currencyCode
      );
      let currencyUpdatedDoc = updateCurrencyAndExchangeRate(
        tempDocument,
        contactCurrency.currencyCode,
        contactCurrency.currencyExchangeRate,
        false
      );
      tempDocument = {
        ...tempDocument,
        ...currencyUpdatedDoc
      };
      setProductRows((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument.items
        };
      });
      onPlaceOfsupplyChange(booksDocument.shipFrom.state);
      onDestinationOfsupplyChange(booksDocument.shipTo.state);
      setPurchaseRequestCode(booksDocument.purchaseRequestForQuotesCode);
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          ...tempDocument,
          documentDate: booksDocument.purchaseRequestForQuotesDate,
          items: tempDocument.items,
          billTo: booksDocument.billTo,
          shipTo: booksDocument.shipTo,
          placeOfSupply: {
            label: booksDocument.shipFrom.state,
            value: booksDocument.shipFrom.state
          }
        };
      });
      //need to change this when rececive billTo from backend
      setBillToValue(booksDocument.billTo);
      setShipToValue(booksDocument.shipTo);
      setShowSRPicker(false);
      tempDocument.items?.forEach((item: any, index: number) => {
        calculateTaxesAndAmount(index, tempDocument.items);
      });
    }
  }, [props.RfqToSQuote]);
  useEffect(() => {
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        shipByDate: DateFormatService.getDateStrFromDate(
          validDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        receiveByDate: DateFormatService.getDateStrFromDate(
          receiveByDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )
      };
    });
  }, [documentDate, validDate, receiveByDate]);

  useEffect(() => {
    const docDate = addDays(documentDate, 1);
    dispatch(
      fetchCurrencyExchangeRateByDocDate(
        DateFormatService.getDateStrFromDate(
          docDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      )
    );
  }, [documentDate]);

  useEffect(() => {
    if (!Utility.isEmpty(activeMultiCurrencyList) && isdocumentDateChanged) {
      const currencyCode = booksDocument.currencyCode;
      const exchangeRate = activeMultiCurrencyList.find(
        (data) => data.currencyCode == currencyCode
      )?.currencyExchangeRate;
      setIsdocumentDateChanged(false);
      updateCurrencyAndExchangeRate(
        { ...booksDocument },
        currencyCode,
        exchangeRate
      );
    }
  }, [activeMultiCurrencyList]);
  useEffect(() => {
    if (props.documentMode === DOCUMENT_MODE.EDIT) {
      let temprows: any = {
        id: 54704,
        accountCode: null,
        productCode: 'P-0000012',
        productDescription: 'DEMO',
        productQuantity: 1,
        uomQuantity: 1,
        uomUnitPrice: 12,
        documentUom: 2,
        documentUOMSchemaDefinition: null,
        pendingQuantity: 1,
        pendingAmount: 12,
        receivedQuantityInOrder: 0,
        receivedQuantityInBills: null,
        unitPrice: 12,
        discount: '0%',
        discountInPercent: true,
        taxAmount: 2.16,
        taxCode: 'TX-0000004',
        totalAmount: 14.16,
        productOrder: null,
        purchaseOrderItemCode: 'POI-0000091',
        customField: null,
        product: {
          name: 'Batch Product ZEN-7602',
          type: 'TRACKED',
          description: 'DEMO',
          purchasePrice: 0,
          salesPrice: 0,
          purchaseAccountCode: 'AC-0000015',
          salesAccountCode: 'AC-0000026',
          purchaseReturnAccountCode: 'AC-0000015',
          salesReturnAccountCode: 'AC-0000026',
          manufacturingCostAccountCode: '',
          purchaseTaxCode: 'TX-0000001',
          salesTaxCode: 'TX-0000001',
          autoFulfillmentEnabled: false,
          stockUom: 2,
          images: [],
          taxable: true,
          customField: [],
          reorderEnabled: false,
          documentSequenceCode: 'P-0000011',
          sequenceFormat: '63b7a90f23ad50d05027e357',
          advancedTracking: 'BATCH',
          isVariant: false,
          hasVariants: false,
          attributes: [],
          variantAttributes: {},
          multipleUomSchema: false,
          isUiProductUpdate: false,
          reorderVendorCode: '',
          source: 'DESKERA',
          landedCostCategory: [],
          barcodeDetails: {
            barcodeField: 'PRODUCT_ID',
            hideBarcodeLabel: false
          },
          productSubstitutesDetails: [],
          id: 342091,
          productId: 'P-0000012',
          active: true,
          deleted: false,
          incomingQty: 12,
          outgoingQty: 1,
          reorderLevelReached: false,
          availableQuantity: 100,
          offeringType: 'GOODS',
          hsnOrSacCode: '0101',
          itcAdjustment: 'NA',
          glAccountCode: 'NA',
          taxPreference: true,
          tdsApplicableIndia: false,
          tdsNatureOfPaymentIndia: '',
          reservedQuantity: 0,
          costOfGoodsSoldAccountCode: 'AC-0000033',
          inventoryAccountCode: 'AC-0000004',
          stockAdjustmentAccountCode: 'AC-0000048',
          bomProductsConfiguration: [],
          bomOperationsConfiguration: [],
          bomAddCostConfiguration: []
        },
        tax: {
          id: 222542,
          name: 'GST 18%',
          taxCode: 'GST18',
          accountCode: 'AC-0000022',
          accountName: 'GST Payable',
          type: 'BOTH',
          description: 'GST 18%',
          percent: 18,
          code: 'TX-0000004',
          status: 'active',
          deleted: false,
          tenantId: 75193,
          category: 'SYSTEM',
          taxSectionAus: null,
          basReporting: null,
          taxAccountCode: null,
          taxGroupDetails: null,
          netAmountBefore: null,
          taxAmountIn: null,
          isTaxGroup: false,
          isDefault: null,
          taxAgencyCan: null,
          netAmountUk: null,
          taxAmountUk: null,
          defaultMemoUk: null,
          flatRateSchemeUk: null,
          showInReportsID: null,
          netAmountBeforeNL: null,
          taxAmountInNL: null,
          defaultMemoNL: null,
          netAmountBeforeBE: null,
          taxAmountInBE: null,
          defaultMemoBE: null,
          netAmountDe: null,
          taxAmountDe: null,
          defaultMemoDe: null,
          additionalTaxIn: null,
          taxSectionSAR: null,
          effectiveStartDate: '1970-01-01',
          effectiveEndDate: null
        },
        taxDetails: [],
        sgstRate: 0,
        cgstRate: 0,
        igstRate: 18,
        sgstAmount: 0,
        cgstAmount: 0,
        igstAmount: 2.16,
        cessAmount: 0,
        cessPercentage: 0,
        otherRate: 0,
        otherTaxAmount: 0,
        hsnOrSacCode: null,
        taxPreference: null,
        taxExemptionReason: null,
        lineNumber: 0,
        itcIneligibleType: null,
        exciseAmount: 0,
        exciseType: null,
        exciseRate: null,
        userSetTaxes: false,
        taxSystem: 'IN',
        unitPriceGstInclusive: false,
        isTaxable: true,
        uom: {
          id: 2,
          name: 'UNIT',
          description: 'UNIT',
          isSystemGenerated: true,
          deleted: false,
          active: true,
          isBaseUom: true
        },
        subTotal: 12,
        discountAmount: 0,
        totalWithDiscount: 12,
        rcmRate: 18,
        total: 14.16,
        gstType: 1,
        taxName: 'GST 18%',
        isRcmApplied: false,
        amount: 14.16,
        invalidFields: []
      };
      setProductRows([temprows]);

      // props.onDocumentUpdate(booksDocument);
    }
  }, []);

  // useEffect(() => {
  //   setBooksDocument((prevState: any) => ({
  //     ...prevState,
  //     contact: contact
  //   }));
  // }, [contact]);

  // useEffect(() => {
  //   setPos((prevState: any) => ({
  //     ...prevState,
  //     contact: contact,

  //   }));
  // }, [contact]);

  const getBillToShipTo = () => {
    const billingAddressList: BooksAddress[] = tenantInfo.billingAddresses;
    const shippingAddressList: BooksAddress[] = tenantInfo.shippingAddresses;

    let billingAddress: BooksAddress | undefined;
    let shippingAddress: BooksAddress | undefined;

    if (isBillOrOrder) {
      billingAddress =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      shippingAddress =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);
    }
    //if (props.draftType === DraftTypes.NEW) {
    setBillingAddress({
      billingAddress: billingAddressList,
      currentBillingAddress: billingAddress as BooksAddress
    });
    setShippingAddress({
      shippingAddress: shippingAddressList,
      currentShippingAddress: shippingAddress as BooksAddress
    });
    //}
    return {
      billingAddress,
      shippingAddress
    };
  };

  useEffect(() => {
    getBillToShipTo();
  }, []);
  // update billing and shipping address
  useEffect(() => {
    if (props.draftType === DraftTypes.NEW && props.RfqToSQuote !== true) {
      setBooksDocument((prevState: any) => ({
        ...prevState,
        billTo: billingAddress?.currentBillingAddress,
        shipTo: shippingAddress?.currentShippingAddress
      }));
    }
  }, [
    billingAddress?.currentBillingAddress,
    shippingAddress?.currentShippingAddress
  ]);
  useEffect(() => {
    if (
      props.documentMode === DOCUMENT_MODE.EDIT &&
      props.RfqToSQuote !== true
    ) {
      setBooksDocument((prevState: any) => ({
        ...prevState,
        billTo: billingAddress?.currentBillingAddress,
        shipTo: shippingAddress?.currentShippingAddress
      }));
    }
  }, [
    billingAddress?.currentBillingAddress,
    shippingAddress?.currentShippingAddress
  ]);

  useEffect(() => {
    if (props.RfqToSQuote !== true) {
      let lineItems = [...(props?.booksDocument?.items as DocumentItem[])];

      if (lineItems?.length && props?.documentMode === DOCUMENT_MODE.COPY) {
        setExistingLineNumbers(lineItems?.map((item) => item.lineNumber));
      }

      lineItems = getLineItemsWithNonEditableCells(lineItems);

      if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
        let states: ReactOptionType[] = [];
        const indianStates = INDIAN_STATES_MOCK_DATA;
        indianStates.forEach((state) => {
          let option: ReactOptionType = {
            label: state.name,
            value: state.code
          };
          states.push(option);
        });
        setIndianStatesOptions(states);
      }

      // updateConfig();
      if (props.documentMode) {
        // Don't show tooltip in VIEW mode
        DocumentConfigUtility.documentMode = props.documentMode;
      }
      // Set doc type in dicument config utility
      DocumentConfigUtility.documentType = props.booksDocument.documentType;

      if (lineItems && lineItems.length > 0) {
        lineItems.forEach((rowData, index) => {
          calculateAndSetUomRelatedDataOnEdit(index, [
            ...(lineItems as DocumentItem[])
          ]);
        });
      }

      let documentUpdates: any = {};

      if (props.booksDocument.documentDate) {
        setDocumentDate(
          DateFormatService.getDateFromStr(
            props.booksDocument.documentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        );
      }

      if (props.booksDocument.fulfillmentDate) {
        setreceiveByDate(
          DateFormatService.getDateFromStr(
            props.booksDocument.fulfillmentDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        );
      }

      if (props.booksDocument.validTillDate) {
        setvalidDate(
          DateFormatService.getDateFromStr(
            props.booksDocument.validTillDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        );
      }

      if (!productsData?.content) {
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false',
            params: PRODUCT_TRANSACTIONTYPE_PURCHASE
          })
        );
      }

      if (true) {
        dispatch(fetchUOMs);
      }
      if (!contactsData?.content) {
        dispatch(fetchContacts());
      }
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...documentUpdates,
          currency: booksDocument.currency
            ? booksDocument.currency
            : tenantInfo.currency,
          currencyCode: booksDocument.currencyCode
            ? booksDocument.currencyCode
            : tenantInfo.currency,

          documentDate: props.booksDocument.documentDate
            ? props.booksDocument.documentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          fulfillmentDate: props.booksDocument.fulfillmentDate
            ? props.booksDocument.fulfillmentDate
            : DateFormatService.getDateStrFromDate(
                currentDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          validTillDate: props.booksDocument.validTillDate
            ? props.booksDocument.validTillDate
            : DateFormatService.getDateStrFromDate(
                laterDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              ),
          totalAmount: props.booksDocument.totalAmount
        };
      });

      document.addEventListener('mouseup', onMouseUp);
      return () => {
        document.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, []);

  useEffect(() => {
    setBooksDocument((prev: any) => {
      return {
        ...prev,
        billTo: billToValue,
        shipTo: shipToValue
      };
    });
  }, [billToValue, shipToValue]);

  const onMouseUp = (e: any) => {
    const target = e.target;
    const editingContainer = target.closest('#custom-number-list');

    if (!editingContainer) {
      setopenCustomNumberList(false);
    }
  };
  const getClassDimensionData = () => {
    if (dimensionData) {
      const classData = dimensionData?.content?.find(
        (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
      );
      const classFields: any[] = classData?.attributes?.map((attr: any) => {
        return {
          id: classData.id,
          shortName: classData.shortName,
          module: classData.module,
          code: classData.code,
          label: classData.label,
          value: attr.value
        };
      });
      return classFields;
    }
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };
  const hideTaxAmountColumn = () => {
    let flag = true;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      Utility.isComplianceEnabled()
    ) {
      flag = false;
    }

    return flag;
  };
  const updateConfig = () => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        // case 'customField':
        //   const data = getClassDimensionData();
        //   conf.hidden = hideClassColumn();
        //   conf.editable =
        //     props.documentMode !== DOCUMENT_MODE.VIEW &&
        //     props.draftType !== DraftTypes.READONLY;
        //   conf.dropdownConfig.data = data?.length ? data : [];
        //   conf.dropdownConfig.button.onClick = () => setShowAddClassPopup(true);
        //   break;
        case 'documentSequenceCode':
          conf.editable = false;
          break;
        case 'productDescription':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.documentMode !== DOCUMENT_MODE.EDIT &&
            props.draftType !== DraftTypes.READONLY;
          break;
        case 'product':
          let productDataArray =
            productsData?.content?.length > 0 ? productsData.content : [];
          conf.editable =
            (Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.REQUISITION) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.documentMode === DOCUMENT_MODE.EDIT ||
            props.draftType === DraftTypes.READONLY ||
            props.booksDocument.isPartialInvoice ||
            props.booksDocument.isPartialBill
              ? false
              : true;
          conf.dropdownConfig.data =
            productDataArray.length > 0
              ? DocumentConfigUtility.productDataParser(
                  { content: productDataArray },
                  props.booksDocument.documentType
                )
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getProductURL(
              search,
              tenantInfo,
              PRODUCT_TRANSACTIONTYPE_PURCHASE
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.productDataParser(
              data,
              props.booksDocument.documentType
            );
          if (checkUserPermission(PERMISSIONS_BY_MODULE.PRODUCTS.CREATE)) {
            conf.dropdownConfig.button.onClick = () =>
              setShowProductPopup(true);
          } else {
            conf.dropdownConfig.button = null;
          }
          break;

        case 'uom':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.documentMode !== DOCUMENT_MODE.EDIT &&
            props.draftType !== DraftTypes.READONLY;
          conf.dropdownConfig.data = selectedProductUOMS || [];
          break;

        case 'productQuantity':
          conf.editable =
            (Utility.isDropship(props.booksDocument) &&
              props.booksDocument.documentType === DOC_TYPE.REQUISITION) ||
            props.documentMode === DOCUMENT_MODE.VIEW ||
            props.documentMode === DOCUMENT_MODE.EDIT ||
            props.draftType === DraftTypes.READONLY
              ? false
              : true;
          break;
        case 'unitPrice':
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.documentMode !== DOCUMENT_MODE.EDIT &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_PRICE)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_PRICE));
          conf.formatter = (data: any) =>
            DocumentConfigUtility.amountFormatter(
              data.value,
              booksDocument.currency
            );

          break;
        case 'tax':
          let taxData = Utility.isSalesDocument(props.booksDocument)
            ? salesTaxes
            : purchaseTaxes;
          taxData = taxData.filter((taxItem: any) => {
            if (
              taxItem.effectiveEndDate !== undefined &&
              taxItem.effectiveEndDate !== null
            ) {
              if (
                documentDate >=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  ) &&
                documentDate <=
                  DateFormatService.getDateFromStr(
                    taxItem.effectiveEndDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  )
              ) {
                return taxItem;
              }
            } else {
              if (
                documentDate >=
                DateFormatService.getDateFromStr(
                  taxItem.effectiveStartDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              ) {
                return taxItem;
              }
            }
          });
          setFilteredTaxList(taxData);
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            props.documentMode !== DOCUMENT_MODE.EDIT &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX));

          conf.dropdownConfig.data = taxData?.length
            ? DocumentConfigUtility.taxDataParser(
                { content: taxData },
                props.booksDocument.documentType
              )
            : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            DocumentConfigManager.getTaxURL(
              search,
              DateFormatService.getDateStrFromDate(
                documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              )
            );
          conf.dropdownConfig.searchApiConfig.dataParser = (data: any) =>
            DocumentConfigUtility.taxDataParser(
              data,
              props.booksDocument.documentType
            );
          if (!checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX)) {
            conf.dropdownConfig.button = null;
          }
          break;
        case 'taxAmount':
          conf.hidden = hideTaxAmountColumn();
          conf.name =
            getTenantTaxSystem() === TAX_SYSTEM.US ? 'Tax' : 'Tax Amount';
          conf.editable =
            !(!hideTaxAmountColumn() && contact && contact.taxExempted) &&
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY &&
            (Utility.isEmpty(props.permissionKeys?.EDIT_TAX)
              ? true
              : checkUserPermission(props.permissionKeys.EDIT_TAX));
          conf.type =
            !hideTaxAmountColumn() && contact && contact.taxExempted
              ? INPUT_TYPE.TEXT
              : INPUT_TYPE.NUMBER;
          conf.formatter = (data: any) => {
            if (!hideTaxAmountColumn() && contact && contact.taxExempted) {
              // Tax exempted contact for US
              return 'Exempted';
            } else {
              return DocumentConfigUtility.amountFormatter(
                data.value,
                booksDocument.currency
              );
            }
          };
          break;
        default:
          conf.editable =
            props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.documentMode !== DOCUMENT_MODE.EDIT &&
            props.draftType !== DraftTypes.READONLY;
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };
  const getSelectedUomForEdit = (lineItem: any) => {
    if (lineItem.documentUOMSchemaDefinition) {
      return lineItem.documentUOMSchemaDefinition;
    } else {
      let filtered = uomsData.filter(
        (uom: any) => uom.id === lineItem?.documentUom
      );
      let defaultUOM: any;
      if (!Utility.isEmpty(filtered)) {
        defaultUOM = { ...filtered[0], isBaseUom: true };
      }
      return defaultUOM;
    }
  };
  const calculateAndSetUomRelatedDataOnEdit = (
    rowIndex: number,
    unsavedRows?: any
  ) => {
    let item = unsavedRows[rowIndex];
    let clonedItem: any = {};

    let unitPrice = item.unitPrice;

    const pendingQuantity = item.pendingQuantity;
    let finalQty;
    if (item.isPartialInvoice && !item.id) {
      finalQty = pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else if (item.isPartialBill && !item.id) {
      const receivedButNotBilled = Utility.receivedButNotBilledQuantity(item);
      finalQty =
        receivedButNotBilled > 0 && receivedButNotBilled < pendingQuantity
          ? receivedButNotBilled
          : pendingQuantity;
      if (finalQty <= 0) {
        finalQty = 1;
      }
    } else {
      finalQty = item.productQuantity;
    }

    item.productQuantity = finalQty;
    item.unitPrice = unitPrice;

    item.uom = getSelectedUomForEdit(item);
    if (item.documentUOMSchemaDefinition) {
      item.unitPrice = item.uomUnitPrice || item.unitPrice;
      item.productQuantity = item.uomQuantity || item.productQuantity;
    }
    if (props.documentMode === DOCUMENT_MODE.VIEW) {
      item.tax = purchaseTaxes.find((tax: any) => tax.code === item.taxCode);
    }
    lastUpdatedIndex.current = rowIndex;
    setProductRows([...unsavedRows]);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...unsavedRows]
      };
    });
  };
  const getLineItemsWithNonEditableCells = (lineItems: any[]) => {
    lineItems = lineItems.map((item: any) => {
      let updatedItem = { ...item, isTaxable: true };

      if (
        (typeof updatedItem?.product?.taxPreference !== 'undefined' &&
          updatedItem?.product?.taxPreference !== null &&
          !updatedItem?.product?.taxPreference) ||
        !updatedItem.isTaxable
      ) {
        updatedItem.nonEditableColumns = updatedItem.nonEditableColumns
          ? [...updatedItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedItem?.nonEditableColumns?.push('tax');
      }
      return updatedItem;
    });

    return lineItems;
  };

  const getTaxExchangeRatePopup = () => {
    return (
      <GSTExchangeRateDialog
        TaxResidencyCurrency={
          COMPLAINCE_CURRENCY[AuthService.userDetails.country]
        }
        TransferCurrency={booksDocument.currency}
        GstExchangeRate={booksDocument.gstExchangeRate}
        onClose={() => setShowTaxExchangeRatePopup(false)}
        onUpdateTaxRate={(rate: number) => {
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              gstExchangeRate: rate
            };
          });
          setShowTaxExchangeRatePopup(false);
        }}
      />
    );
  };

  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};

    if (selected.manualMode) {
      updatedState.documentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
    } else {
      if (selected.text === 'PRQ-0000000') {
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      } else if (selected.text !== 'PRQ-0000000') {
        updatedState.documentSequenceCode = selected.text;
        updatedState.sequenceFormat = selected.id;
        updatedState.manualMode = selected.manualMode;
      }
    }

    setBooksDocument((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };

  const getLineItemsWithUpdatedColumnConfig = (items: any[]) => {
    if (!Utility.isEmpty(availableCustomFields)) {
      let updatedConfigs = [...columnConfig];
      const prodCustomFields = availableCustomFields?.content?.filter(
        (item: any) => {
          return item.modules?.includes(MODULES_NAME.PRODUCT) && !item.system;
        }
      );

      prodCustomFields?.forEach((prodCF: any) => {
        const newItem = getNewColumnForCF(
          prodCF,
          props.documentMode !== DOCUMENT_MODE.VIEW &&
            props.draftType !== DraftTypes.READONLY
        );
        updatedConfigs.push(newItem);
      });

      if (
        props.documentMode !== DOCUMENT_MODE.NEW &&
        !Utility.isEmpty(prodCustomFields)
      ) {
        items?.forEach((lineItem: any, lineItemIndex: any) => {
          lineItem?.customField?.map((item: any, index: any) => {
            let filteredCF: any = availableCustomFields?.content?.find(
              (cf: any) => cf.id === item.id && !cf.system
            );
            if (!Utility.isEmpty(filteredCF)) {
              const newItem = getNewCFItem(
                item,
                filteredCF,
                props.documentMode !== DOCUMENT_MODE.VIEW &&
                  props.draftType !== DraftTypes.READONLY
              );

              let alreadyPresentConfig = updatedConfigs?.find((col: any) => {
                if (col.id === item.id) {
                  return col;
                }
              });
              if (Utility.isEmpty(alreadyPresentConfig)) {
                updatedConfigs.push(newItem);
              }

              if (filteredCF) {
                if (
                  typeof item.value !== 'undefined' &&
                  item.value !== null &&
                  item.value !== ''
                ) {
                  if (
                    filteredCF.fieldType.toLowerCase() ===
                    INPUT_TYPE.DATE.toLowerCase()
                  ) {
                    items[lineItemIndex][item.id] =
                      DateFormatService.getDateFromStr(
                        item.value,
                        BOOKS_DATE_FORMAT['MM/DD/YYYY']
                      );
                  } else {
                    items[lineItemIndex][item.id] = item.value;
                  }
                } else {
                  items[lineItemIndex][item.id] = '';
                }
              }
            }
          });
        });
      }
      return { items, updatedConfigs };
    } else {
      return { items: null, updatedConfigs: [...columnConfig] };
    }
  };
  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
          setIsdocumentDateChanged(true);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };
  const activeDateRangeValidation = (
    newDate: Date,
    tenantInfo: any,
    callback: any,
    warningMessage: string
  ) => {
    let checkActiveRange: boolean = true;
    const isActiveDateRange =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING
        ?.isActiveDateRange || false;
    let fromDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeFromDate;
    let toDate =
      tenantInfo?.additionalSettings?.ACTIVE_DATE_RANGE_SETTING?.activeToDate;
    const isBackDatedEnable =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.isBackDateRestrictionEnabled || false;
    const configDetails =
      tenantInfo?.additionalSettings?.BACK_DATE_RESTRICTION_SETTING
        ?.dateRestrictionConfigs || [];

    if (isBackDatedEnable && !Utility.isEmpty(configDetails)) {
      let documentConfig = configDetails.find(
        (ele: any) => ele.documentType === props.booksDocument.documentType
      );
      if (documentConfig && documentConfig.restrictType === 'Fully_Restrict') {
        let backDate = subDays(
          new Date(new Date().setHours(0, 0, 0, 0)),
          Number(documentConfig.noOfDays)
        );
        let formatedDate = DateFormatService.getDateStrFromDate(backDate);
        if (newDate.getTime() >= backDate.getTime()) {
          checkActiveRange = true;
        } else {
          showAlert(
            'Invalid Date',
            `Requisition date should not be less than back date : ${formatedDate}.`
          );
          return;
        }
      }
    }
    if (
      checkActiveRange &&
      isActiveDateRange &&
      !Utility.isEmpty(fromDate) &&
      !Utility.isEmpty(toDate)
    ) {
      let minAcceptedDate = DateFormatService.getDateFromStr(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      let maxAcceptedDate = DateFormatService.getDateFromStr(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const startDate = DateFormatService.getFormattedDateString(
        fromDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      const endDate = DateFormatService.getFormattedDateString(
        toDate,
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );
      if (
        newDate.getTime() >= minAcceptedDate.getTime() &&
        newDate.getTime() <= maxAcceptedDate.getTime()
      ) {
        callback(newDate);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      } else {
        showAlert(
          'Invalid Date',
          ` ${warningMessage} - From Date : ${startDate} To Date : ${endDate}.`
        );
      }
    } else {
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }
  };
  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string,
    isDocDate: boolean
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (isDocDate) {
        activeDateRangeValidation(
          newDate,
          tenantInfo,
          setDocumentDate,
          `SupplierQuote should be in active date range`
        );
        return;
      }
      callback(newDate);
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };
  const getProduct = (productCode: any) => {
    const productDetails = productsData.content;

    const product = productDetails?.filter((productItem: any) => {
      return productItem.productId === productCode;
    });
    return Utility.isEmpty(product) ? null : product[0];
  };
  const getSRPickerView = () => {
    return (
      <div className=" p-v-xs p-h-s">
        <DKListPicker2
          data={requisitionStateData}
          onClose={() => {
            setShowSRPicker(false);
          }}
          className="position-absolute p-v-xs p-h-s shadow-md bg-white border-m z-index-2"
          style={{ top: 0, left: 180 }}
          allowSearch={true}
          searchableKey="documentSequenceCode"
          onSelect={(index: any, obj: any) => {
            setSR(obj.documentSequenceCode);
            const row = requisitionStateData.filter((item: any) => {
              return item.documentSequenceCode === obj.documentSequenceCode;
            });

            let itemData = row[0].purchaseRequestItems.map(
              (element: any, index: any) => {
                setUomsForSelectedProduct(element.product);
                const product = getProduct(element.productCode);
                const uom = uomsData.filter((uomItem: any) => {
                  return uomItem.id === element.documentUom;
                });
                const tax = getTax(product);
                let percent = 0;
                let price = 0;
                let quantity = 0;
                let taxAmount = 0;
                let totalAmount = 0;
                if (!Utility.isEmpty(tax)) {
                  percent = tax.percent * 0.01;
                  price = +element.unitPrice * 1;
                  quantity = element.productQuantity;
                  taxAmount = Number((percent * price).toFixed(2));
                  totalAmount = (taxAmount + price) * quantity;
                }
                let data = {
                  customField: element.customField,
                  discount: element.discount,
                  discountInPercent: element.discountInPercent,
                  documentSequenceCode: element.documentSequenceCode,
                  documentUOMSchemaDefinition:
                    element.documentUOMSchemaDefinition,
                  documentUom: element.documentUom,
                  lineNumber: element.lineNumber,
                  product: product,
                  productCode: element.productCode,
                  productDescription: element.productDescription,
                  productOrder: element.productOrder,
                  productQuantity: element.productQuantity,
                  taxAmount: taxAmount,
                  taxSystem: getTenantTaxSystem(),
                  taxCode: !Utility.isEmpty(tax) ? tax.taxCode : null,
                  taxName: !Utility.isEmpty(tax) ? tax.name : null,
                  tax: tax,
                  uom: uom[0],
                  taxPreference: true,
                  isTaxable: element.product.taxable,
                  totalAmount: totalAmount,
                  unitPrice: element.product.purchasePrice,
                  uomQuantity: element.productQuantity,
                  uomUnitPrice: 0,
                  unitPriceGstInclusive: false,
                  gstType: 1
                };
                return data;
              }
            );
            let tempDocument = {
              ...booksDocument,
              items: itemData
            };
            if (!Utility.isEmpty(contact)) {
              const contactCurrency = activeMultiCurrencyList.find(
                (currency: any) =>
                  currency.currencyCode === contact?.currencyCode
              );
              const exchangeRate = contactCurrency.currencyExchangeRate;
              const previousExchangeRate = 1;

              let data = updateBasedOnCurrency(
                tempDocument,
                exchangeRate,
                previousExchangeRate,
                false
              );
              tempDocument = data;
            }

            setProductRows(tempDocument.items);
            setPurchaseRequestCode(row[0].purchaseRequestCode);
            setPurchaseRequestDocumentCode(row[0].documentSequenceCode);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                ...tempDocument,
                billTo: row[0].billTo,
                shipTo: row[0].shipTo
              };
            });
            setBillToValue(row?.[0]?.billTo);
            setShipToValue(row?.[0]?.shipTo);
            setShowSRPicker(false);
            tempDocument.items.forEach((item: any, index: number) => {
              calculateTaxesAndAmount(index, tempDocument.items);
            });
          }}
          renderer={(index: number, obj: any) => {
            return obj?.documentSequenceCode;
          }}
          width={150}
        />
      </div>
    );
  };
  const onDelete = ({ rowIndex }: any) => {
    let rows = [...productRows];
    rows.splice(rowIndex, 1);

    // Update existing Line Number in case of copy
    if (existingLineNumbers.includes(rowIndex + 1)) {
      let existingLineNumbersCopy = [...existingLineNumbers];
      let indexToDelete = existingLineNumbersCopy.findIndex(
        (x) => x === rowIndex + 1
      );
      if (indexToDelete !== -1) {
        existingLineNumbersCopy.splice(indexToDelete, 1);
        setExistingLineNumbers(existingLineNumbersCopy);
      }
    }

    rows = rows.map((row: DocumentItem, index: number) => {
      return {
        ...row,
        lineNumber: index + 1
      };
    });
    lastUpdatedIndex.current = null;
    setProductRows(rows);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        items: [...rows],
        reservedStock: rows.length ? prevBooksDocument.reservedStock : false
      };
    });
  };
  const getDataGridColumns = () => {
    let columns = [...columnConfig];

    columns.push(
      props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.VIEW ||
        props.RfqToSQuote
        ? []
        : {
            id: 'action',
            key: 'action',
            name: 'actions',
            type: INPUT_TYPE.BUTTON,
            width: 80,
            options: [
              {
                icon: DKIcons.ic_delete,
                className: 'p-0 padding-top-2px',
                onClick: (data: any) => onDelete(data)
              }
            ]
          }
    );
    return columns;
  };
  const addNewItem = () => {
    let rows = [...productRows];

    let newRow = DocumentConfigManager.getBlankRow(
      props.booksDocument.documentType
    );
    newRow['productQuantity'] = 1;
    newRow['unitPrice'] = 0;
    newRow['gstType'] = 1;
    newRow.invalidFields = DocumentConfigManager.getRequiredFields(
      props.booksDocument.documentType
    );
    newRow['lineNumber'] = productRows.length + 1;
    newRow['firstAmountChangeDone'] = false;

    if (!Utility.isEmpty(availableCustomFields)) {
      // Set default values of CFs when new line is added
      availableCustomFields?.content?.forEach((item: any) => {
        if (
          item.modules?.includes(MODULES_NAME.PRODUCT) &&
          item.status === STATUS_TYPE.ACTIVE &&
          !item.system
        ) {
          if (
            typeof item.defaultValue !== 'undefined' &&
            item.defaultValue !== null &&
            item.defaultValue !== ''
          ) {
            if (
              item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ) {
              newRow[item.id] = DateFormatService.getDateFromStr(
                item.defaultValue,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              newRow[item.id] = item.defaultValue;
            }
          } else {
            newRow[item.id] = '';
          }
        }
      });
    }

    rows.push(newRow);
    setProductRows(rows);
  };
  const clearAllItems = () => {
    setProductRows([]);
    setBooksDocument((prevState: any) => {
      return {
        ...prevState,
        items: [],
        reservedStock: false
      };
    });
  };
  const validateProduct = (product: any) => {
    const offeringType = product.offeringType;
    const productType = product.type;
    const hsnOrSacCode = product.hsnOrSacCode;
    const uqcIndia = product.uqcIndia;

    let showPopup = false;
    let message = '';

    if (
      offeringType === PRODUCT_OFFERING_TYPE.GOODS &&
      (productType === PRODUCT_TYPE.TRACKED ||
        productType === PRODUCT_TYPE.NON_TRACKED) &&
      (Utility.isEmpty(hsnOrSacCode) || Utility.isEmpty(uqcIndia))
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code and UQC required. Please update or select another product.';
    }

    if (
      offeringType === PRODUCT_OFFERING_TYPE.SERVICES &&
      productType === PRODUCT_TYPE.NON_TRACKED &&
      Utility.isEmpty(hsnOrSacCode)
    ) {
      showPopup = true;
      message =
        'Product HSN/SAC code required. Please update or select another product.';
    }

    return true;
  };
  const isPriceListEnabled = () => {
    const hiddenApps = appCustomizationData?.hiddenApps?.split(',');
    return !hiddenApps?.includes('PRICE_LIST');
  };
  const getPricing = async (
    productsPayload: any[],
    lineItems: any[],
    currencyCode: string,
    updateExistingItem = true,
    exchangeRate?: number,
    changedValues?: any,
    shippingInfo?: any
  ) => {
    if (
      productsPayload.length &&
      currencyCode &&
      documentDate &&
      isPriceListEnabled()
    ) {
      if (Utility.isEmpty(contact)) {
        return;
      }
      let payload = {
        contactCode: contact.code ? contact.code : '',
        priceListPricingItems: productsPayload,
        type: MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
        currency: currencyCode,
        documentDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        exchangeRate: exchangeRate
      };

      try {
        const priceListResponse = await PriceListService.getPricesFromPriceList(
          payload
        );
      } catch (err) {
        console.error('Error loading prices: ', err);
      }
    }
  };
  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(availableCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = availableCustomFields?.content?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const getProductForm = () => (
    <CreateProductView
      product={productToEdit}
      needAdvanceTrackPopupAfterSave={true}
      passingInteraction={(callback: CallBackPayloadType) => {
        if (callback.type === POPUP_CALLBACKS_TYPE.CLOSE_POPUP) {
          if (lastUpdatedIndex.current !== null) {
            onRowUpdate({
              columnKey: 'product',
              rowIndex: lastUpdatedIndex,
              rowData: { product: callback.data }
            });
          }
          setShowProductPopup(false);
        }
      }}
      onCancel={() => {
        setShowProductPopup(false);
        setProductToEdit(null);
      }}
      selectedTab={productToEdit ? 3 : 0}
      onSuccess={(product: any) => {
        setShowProductPopup(false);
        setProductToEdit(null);
        if (
          Utility.isSalesDocument(props.booksDocument) ||
          (product.type !== PRODUCT_TYPE.BILL_OF_MATERIALS &&
            (props.booksDocument.documentType === DOC_TYPE.BILL ||
              props.booksDocument.documentType === DOC_TYPE.ORDER ||
              booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER))
        ) {
          onProductChange(currentIndex, product);
        }
        setCurrentIndex(-1);
        dispatch(
          fetchProductsWithVariants({
            searchTerm: '',
            query: 'active=true,hasVariants=false'
          })
        );
      }}
    />
  );
  const updateUomSchemaForLineItem = (index: number, uomData: any) => {
    let rows: any = [...productRows];
    let selectedRow = { ...rows[index] };
    let unitPrice = selectedRow.unitPrice ? selectedRow.unitPrice : 0;
    if (unitPrice) {
      if (selectedRow.documentUOMSchemaDefinition) {
        const documentUOMSchemaDefinition =
          selectedRow.documentUOMSchemaDefinition;
        unitPrice =
          (unitPrice * documentUOMSchemaDefinition.sinkConversionFactor) /
            documentUOMSchemaDefinition.sourceConversionFactor || 0;
      }
      if (!uomData.isBaseUom) {
        unitPrice =
          (unitPrice * uomData.sourceConversionFactor) /
          uomData.sinkConversionFactor;
      }
    }
    if (unitPrice) {
      selectedRow.unitPrice = Utility.roundOff(unitPrice);
      selectedRow.uomUnitPrice = Utility.roundOff(unitPrice);
    }
    if (uomData.isBaseUom) {
      selectedRow.documentUOMSchemaDefinition = null;
      selectedRow.documentUom = uomData.id;
    } else {
      selectedRow.documentUOMSchemaDefinition = {
        uid: uomData.uid,
        sourceUOM: uomData.sourceUOM,
        sourceConversionFactor: uomData.sourceConversionFactor,
        sinkUOM: uomData.sinkUOM,
        sinkConversionFactor: uomData.sinkConversionFactor,
        id: uomData.sinkUOM,
        name: uomData.name,
        isBaseUom: false,
        schemaId: selectedRow.product.uomSchemaDto.id
      };
      selectedRow.documentUom = uomData.sinkUOM;
    }
    return selectedRow;
  };
  const getUomQuantity = (
    baseQuantity: any,
    documentUOMSchemaDefinition: any
  ) => {
    if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
          documentUOMSchemaDefinition.sourceConversionFactor
      );
    }
    return baseQuantity;
  };
  const getUomPrice = (basePrice: any, documentUOMSchemaDefinition: any) => {
    if (!isNaN(basePrice) && documentUOMSchemaDefinition) {
      return Utility.roundOff(
        (basePrice * documentUOMSchemaDefinition.sourceConversionFactor) /
          documentUOMSchemaDefinition.sinkConversionFactor
      );
    }
    return basePrice;
  };
  const updateTaxList = () => {
    let taxData = purchaseTaxes;
    taxData = taxData.filter((taxItem: any) => {
      if (
        taxItem.effectiveEndDate !== undefined &&
        taxItem.effectiveEndDate !== null
      ) {
        if (
          documentDate >=
            DateFormatService.getDateFromStr(
              taxItem.effectiveStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ) &&
          documentDate <=
            DateFormatService.getDateFromStr(
              taxItem.effectiveEndDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
        ) {
          return taxItem;
        }
      } else {
        if (
          documentDate >=
          DateFormatService.getDateFromStr(
            taxItem.effectiveStartDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )
        ) {
          return taxItem;
        }
      }
    });

    return taxData;
  };

  const getDefaultTaxForSG = (tax: any) => {
    let taxList = updateTaxList();
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.SG) {
      if (taxList.length > 0) {
        let newTax = taxList.filter((item: any) => {
          if (
            item.description.replace(/[0-9]/g, '') ===
            tax?.description.replace(/[0-9]/g, '')
          ) {
            return item;
          }
        });
        tax = newTax[0];
      }
    }
    return tax;
  };
  const getTax = (product: any) => {
    let tax: any = null;
    const taxSystem = getTenantTaxSystem();
    if (taxSystem === TAX_SYSTEM.INDIA_GST && product.taxPreference === false) {
      return null;
    } else if (
      taxSystem === TAX_SYSTEM.MALAYSIA &&
      product.exemptedMalaysia === false
    ) {
      return null;
    } else if (taxSystem === TAX_SYSTEM.US) {
      return null;
    } else {
      let taxCode: any = null;
      taxCode = product?.purchaseTaxCode;
      tax = purchaseTaxes.find((tax: any) => tax.code === taxCode);
      tax = getDefaultTaxForSG(tax);
      return tax ? tax : taxCode === '' ? null : taxCode;
    }
    return tax;
  };
  const onProductChange = (rowIndex: number, product: any) => {
    // setUomsForSelectedProduct(product);
    if (!booksDocument.isDocumentTouched) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          isDocumentTouched: true
        };
      });
    }

    let rows = [...productRows];
    let selectedRow = rows[rowIndex];

    if (!Utility.isEmpty(product)) {
      let updatedLineItem: DocumentItem | any = {} as any;
      if (
        props.documentMode === DOCUMENT_MODE.EDIT ||
        props.documentMode === DOCUMENT_MODE.COPY
      ) {
        updatedLineItem =
          !Utility.isEmpty(selectedRow.productName) &&
          product.name === selectedRow.productName
            ? {
                ...selectedRow,
                gstType: Utility.getValue(
                  selectedRow.gstType,
                  booksDocument.gstType
                )
              }
            : createLineItem(
                product,
                Utility.getValue(selectedRow.gstType, booksDocument.gstType),
                selectedRow.lineNumber,
                booksDocument.documentType as DOC_TYPE
              );
      } else {
        updatedLineItem = createLineItem(
          product,
          Utility.getValue(selectedRow.gstType, booksDocument.gstType),
          selectedRow.lineNumber,
          booksDocument.documentType as DOC_TYPE
        );
      }

      updatedLineItem = {
        ...updatedLineItem,
        advancedTracking: product.advancedTracking,
        unitPriceGstInclusive: selectedRow.unitPriceGstInclusive
      };

      updatedLineItem.invalidFields = updatedLineItem.invalidFields
        ? [...updatedLineItem.invalidFields].filter(
            (field) => field !== 'unitPrice'
          )
        : [];
      if (updatedLineItem.unitPrice === 0) {
        updatedLineItem.invalidFields.push('unitPrice');
      }

      if (
        (typeof product.taxPreference !== 'undefined' &&
          product.taxPreference !== null &&
          !product.taxPreference) ||
        !updatedLineItem.isTaxable
      ) {
        updatedLineItem.nonEditableColumns = updatedLineItem.nonEditableColumns
          ? [...updatedLineItem.nonEditableColumns].filter(
              (field) => field !== 'tax'
            )
          : [];
        updatedLineItem?.nonEditableColumns?.push('tax');
      }

      //Update UOM Values

      updatedLineItem.uomQuantity = getUomQuantity(
        selectedRow.productQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomUnitPrice = getUomPrice(
        selectedRow.unitPrice,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomAvailableQuantity = getUomQuantity(
        selectedRow.availableQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomFulfilledQuantity = getUomQuantity(
        selectedRow.fulfilledQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomQuantityFulfilled = getUomQuantity(
        selectedRow.quantityFulfilled,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomPendingQuantity = getUomQuantity(
        selectedRow.pendingQuantity,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInBills = getUomQuantity(
        selectedRow.receivedQuantityInBills,
        selectedRow.documentUOMSchemaDefinition
      );
      updatedLineItem.uomReceivedQuantityInOrder = getUomQuantity(
        selectedRow.receivedQuantityInOrder,
        selectedRow.documentUOMSchemaDefinition
      );

      // updatedLineItem.uomUnitPrice = selectedRow.unitPrice;
      // updatedLineItem.uomQuantity = selectedRow.productQuantity;

      updatedLineItem = addProductCustomFieldsToLineItem(
        { ...updatedLineItem },
        product
      );

      updatedLineItem = {
        ...updatedLineItem,
        unitPrice: updatedLineItem.unitPrice
          ? updatedLineItem.unitPrice * booksDocument.exchangeRate
          : 0,
        unitPriceGstInclusive: booksDocument.unitPriceGstInclusive,
        documentUom: product.stockUom,
        cessRule: product.cessRule,
        cessRuleDescription: product.cessRuleDescription,
        cessNonAdvol: product?.cessNonAdvol,
        stockUom: product.stockUom,
        reservedQuantitiesData: null
      };

      lastUpdatedIndex.current = rowIndex;

      const newState = booksDocument['items'].map((obj: any, index: number) => {
        if (index === rowIndex) {
          return { ...obj, productDescription: obj['product']['description'] };
        }
        return obj;
      });
      rows[rowIndex] = { ...updatedLineItem };
      setProductRows(newState);

      // const product = rows?.product;
      const tax = getTax(product);
      if (!Utility.isEmpty(tax)) {
        const percent = tax.percent * 0.01;
        const price = +updatedLineItem.unitPrice * 1;
        const quantity = updatedLineItem.productQuantity;
        const taxAmount = Number((percent * price).toFixed(2));
        const totalAmount = (taxAmount + price) * quantity;

        updatedLineItem.taxAmount = taxAmount;
        updatedLineItem.taxName = tax.name;
        updatedLineItem.taxCode = tax.code;
        updatedLineItem.totalAmount = totalAmount;
        updatedLineItem.taxSystem = getTenantTaxSystem();
        updatedLineItem.product = product;
      }
      rows[rowIndex] = { ...updatedLineItem, tax };
      const productsPayload = [
        { productId: product.productId, uomId: product.stockUom, quantity: 1 }
      ];
      getPricing(
        productsPayload,
        rows,
        booksDocument.currency,
        false,
        booksDocument.exchangeRate
      );
      calculateTaxesAndAmount(rowIndex, rows, false);
      setProductRows(rows);
    }
  };
  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    setLastUpdatedColumn(columnKey);
    let rows: any = [...productRows];
    let selectedRows = rows[rowIndex];
    let dataToUpdate: any = rowData && rowData[columnKey];
    selectedRows.invalidFields = selectedRows.invalidFields
      ? [...selectedRows.invalidFields].filter((field) => field !== columnKey)
      : [];

    setRoundOffDirty(false);

    switch (columnKey) {
      case 'product':
        setCurrentIndex(rowIndex);

        if (!validateProduct(rowData[columnKey])) {
          if (Utility.isEmpty(rows[rowIndex]?.product)) {
            rows[rowIndex] = {};
            selectedRows.invalidFields.push('product');
            setProductRows(rows);
          } else {
            setProductRows(rows);
          }
          return;
        }

        rows[rowIndex][columnKey] = dataToUpdate;

        break;
      case 'productQuantity':
        if (!dataToUpdate || isNaN(dataToUpdate)) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        } else {
          dataToUpdate = Utility.roundingOff(
            dataToUpdate,
            tenantInfo.decimalScale
          );
          selectedRows.uomQuantity = Utility.roundOff(dataToUpdate);
        }

        if (
          dataToUpdate &&
          !isNaN(dataToUpdate) &&
          (+dataToUpdate === 0 || +dataToUpdate < 0)
        ) {
          dataToUpdate = undefined;
          selectedRows.invalidFields.push('productQuantity');
        }
        if (!selectedRows.invalidFields.includes('productQuantity')) {
          if (!Utility.isEmpty(selectedRows?.product)) {
            isQuantityChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +dataToUpdate
                    : item.productQuantity
              }));

            rows[rowIndex][columnKey] = +dataToUpdate;

            // Fetch price list price after first change for new rows.
            // For existing rows price list price will be fetched as usual.
            let fetchUnitPrice = false;
            if (
              typeof rows[rowIndex]['id'] !== 'undefined' &&
              rows[rowIndex]['id'] !== null
            ) {
              fetchUnitPrice = true;
            } else {
              if (!rows[rowIndex]['firstAmountChangeDone']) {
                rows[rowIndex]['firstAmountChangeDone'] = true;
              } else {
                fetchUnitPrice = true;
              }
            }
            if (fetchUnitPrice) {
              getPricing(
                products,
                rows,
                booksDocument.currency,
                true,
                booksDocument.exchangeRate,
                { quantityChanged: true }
              );
            }
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        calculateTaxesAndAmount(rowIndex, rows);
        break;

      case 'uom':
        rows[rowIndex] = updateUomSchemaForLineItem(rowIndex, dataToUpdate);
        if (!selectedRows.invalidFields.includes('uom')) {
          if (
            !Utility.isEmpty(selectedRows?.product) &&
            !selectedRows.invalidFields.includes('productQuantity')
          ) {
            isUOMChangedManually.current = true;
            const products = rows
              .filter((item: any) => !Utility.isEmpty(item.product))
              .map((item: any, index: number) => ({
                productId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? selectedRows?.product.productId
                    : item.product.productId,
                uomId:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? rows[rowIndex]?.documentUom
                    : item.documentUom,
                quantity:
                  selectedRows.lineNumber === currentIndex + 1 &&
                  index === currentIndex
                    ? +selectedRows?.productQuantity
                    : item.productQuantity
              }));
            getPricing(
              products,
              rows,
              booksDocument.currency,
              true,
              booksDocument.exchangeRate,
              { uomChanged: true }
            );
          }
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        rows[rowIndex]['reservedQuantitiesData'] = null;
        break;
      case 'tax':
        // If product is non-taxable, then don't update the tax value.
        // We need to handle this in grid by making the cell non-editable.
        if (
          typeof selectedRows.product.taxPreference !== 'undefined' &&
          selectedRows.product.taxPreference !== null &&
          !selectedRows.product.taxPreference
        ) {
          dataToUpdate = null;
        }
        rows[rowIndex][columnKey] = dataToUpdate;
        calculateTaxesAndAmount(rowIndex, rows);
        break;
      case 'taxAmount':
        const tax = selectedRows.tax;
        const updates: any = {
          taxCode: tax ? tax.code : '',
          taxName: tax ? tax.name : ''
        };
        if (!tax) {
          updates.taxAmount = 0;
        }
        rows[rowIndex] = {
          ...rows[rowIndex],
          ...updates
        };
        rows[rowIndex][columnKey] = dataToUpdate;
        break;
      case 'unitPrice':
        rows[rowIndex].unitPrice = rowData.unitPrice;
        calculateTaxesAndAmount(rowIndex, rows);
        break;
      case 'customField':
        let existingCFs = [...rows[rowIndex].customField];
        existingCFs[0] = dataToUpdate;
        rows[rowIndex].customField = existingCFs;
        break;
      default:
        const CFColumnConfig = columnConfig?.find(
          (cf: any) => cf?.id === columnKey && cf.isCustomField
        );
        const filteredCF: any = availableCustomFields?.content?.find(
          (field: any) => field.id === columnKey
        );
        if (CFColumnConfig && filteredCF) {
          const cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value:
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
                ? DateFormatService.getDateStrFromDate(
                    new Date(dataToUpdate),
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  )
                : dataToUpdate
          };
          let existingCFs = [...rows[rowIndex].customField];
          const existingCFIndex = existingCFs.findIndex(
            (cf: any) => cf?.id === cfToUpdate?.id
          );
          if (existingCFIndex === -1) {
            existingCFs = [...existingCFs, cfToUpdate];
          } else {
            existingCFs[existingCFIndex] = cfToUpdate;
          }
          rows[rowIndex].customField = existingCFs;
        }
        rows[rowIndex][columnKey] =
          filteredCF?.fieldType?.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
            ? new Date(dataToUpdate)
            : dataToUpdate;
        break;
    }
    // if (columnKey !== 'uom') {
    // rows[rowIndex][columnKey] = dataToUpdate;
    // }
    lastUpdatedIndex.current = rowIndex;
    if (columnKey === 'product') {
      onProductChange(rowIndex, dataToUpdate);
    } else {
      setProductRows(rows);
      setLineItemsTouched(true);
    }
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomsData.filter(
      (uom: any) => uom.id === productCopy.stockUom
    );
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filtered = uomsData.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filtered && filtered.length > 0) {
            name = filtered[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };
  const setPricesForSelectedProduct = (lineItem: any) => {
    if (
      lineItem &&
      !Utility.isEmpty(lineItem.product) &&
      !Utility.isEmpty(productsLastUnitPrice) &&
      productsLastUnitPrice[lineItem.product.productId]
    ) {
      setSelectedProductPriceList([
        ...productsLastUnitPrice[lineItem.product.productId]
      ]);
    }
  };
  const onRowClick = ({ rowIndex }: any) => {
    let lineitem = { ...productRows[rowIndex] };
    // lastUpdatedIndex.current = rowIndex;
    setUomsForSelectedProduct(lineitem.product);
    setPricesForSelectedProduct(lineitem);

    // Set current index to know on which row user clicked, before adding the product
    setCurrentIndex(rowIndex);
  };
  const openReservedStockPopup = (
    lineItem: any,
    selectedProductInfo: any[]
  ) => {
    const productId = lineItem.product.id;
    const exitingReservedQuantitiesDataInLines = booksDocument.items
      ?.filter(
        (item: any) =>
          item.lineNumber !== lineItem.lineNumber &&
          item.product?.id === productId &&
          !Utility.isEmpty(item.reservedQuantitiesData)
      )
      .map((data: any) =>
        rebuildAdvancedTrackingMetaDtosAndUOMInfo(
          data.reservedQuantitiesData,
          data.documentUOMSchemaDefinition
        )
      )
      .reduce((acc: any[], curVal: any[]) => {
        return acc.concat(curVal);
      }, []);

    if (selectedProductInfo && exitingReservedQuantitiesDataInLines) {
      selectedProductInfo.forEach((obj) => {
        const warehouseObj = exitingReservedQuantitiesDataInLines.find(
          (o: any) => o.warehouseCode === obj.warehouseCode
        );
        if (warehouseObj) {
          const availableQty =
            obj.reservedQuantity + Number(warehouseObj.reservedQuantity);
          obj.reservedQuantity = availableQty < 0 ? 0 : Number(availableQty);
          if (
            obj.advancedTrackingType !== TRACKING_TYPE.NONE &&
            obj.advancedTrackingMetaDtos &&
            obj.advancedTrackingMetaDtos.length > 0 &&
            warehouseObj.advancedTrackingMetaDtos
          ) {
            obj.advancedTrackingMetaDtos.forEach((e: any) => {
              const batchObj = warehouseObj.advancedTrackingMetaDtos.find(
                (o: any) => o.serialBatchNumber === e.serialBatchNumber
              );
              if (batchObj) {
                const availableBatchQty =
                  e.reservedQuantity + Number(batchObj.reservedQuantity);
                e.reservedQuantity =
                  availableBatchQty < 0 ? 0 : Number(availableBatchQty);
              }
            });
          }
        }
      });
    }

    setReserveStockLineItem({ ...lineItem });

    // convertReservedBaseUOMToSchema(selectedProductInfo, lineItem);

    setReservedQuantitiesOriginal(selectedProductInfo);
    setShowReserveStockPopup(true);
  };

  //  console.log("aaa",contact.shippingAddress[0].state)

  const getPlaceOfSupply = () => {
    return (
      <div style={{ width: 150, marginLeft: 0, marginTop: 10 }}>
        <DKInput
          value={pos || ''}
          title={
            Utility.isSalesDocument(props.booksDocument)
              ? 'Place of Supply'
              : 'Source of Supply'
          }
          readOnly={props.RfqToSQuote}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatter={(obj: any) => {
            return obj.label;
          }}
          className="parent-width mt-5"
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={{
            color: 'gray'
          }}
          onChange={(value: any) => {
            if (!props.RfqToSQuote) {
              setPos(value);
              let updatedState = {
                ...booksDocument,
                placeOfSupply: value.value
              };
              setBooksDocument((prevState: any) => {
                return {
                  ...prevState,
                  placeOfSupply: value.value
                };
              });

              onPlaceOfsupplyChange(value);
            }
          }}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: 'label',
            data: indianStatesOptions,

            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj.label}
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  };

  const getSourceOfDestination = () => {
    return (
      <div style={{ width: 150, marginLeft: 5, marginTop: 10 }}>
        <DKInput
          value={dos || ''}
          title={'Destination of Supply'}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          formatter={(obj: any) => {
            return obj.label;
          }}
          readOnly={props.RfqToSQuote}
          className="parent-width mt-5"
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          titleStyle={{
            color: 'gray'
          }}
          onChange={(value: any) => {
            if (props.RfqToSQuote) {
              setDos(value);
              onDestinationOfsupplyChange(value);
            }
          }}
          dropdownConfig={{
            allowSearch: true,
            searchableKey: 'label',
            data: indianStatesOptions,
            renderer: (index: any, obj: any) => {
              return (
                <div className="flex flex-row w-full justify-content-between">
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s"
                    text={obj.label}
                  />
                </div>
              );
            }
          }}
        />
      </div>
    );
  };

  const fetchProductReservedInfo = ({ rowData }: any) => {
    const product = rowData?.product;
    const productId = product?.productId;
    if (!Utility.isEmpty(productId)) {
      ProductService.getProductReservedInfo([productId]).then(
        (reserveInfo: any[]) => {
          if (reserveInfo?.length) {
            const selectedProductInfo = reserveInfo?.filter(
              (obj) => obj.productCode === productId
            );

            if (selectedProductInfo?.length) {
              openReservedStockPopup(rowData, selectedProductInfo);
            }
          }
        },
        (err) => {
          console.error('Error while fetching product reserved info: ', err);
        }
      );
    }
  };
  const onOpenAllocateCost = ({ rowIndex, rowData }: any) => {
    setShowLandedCostPopup(true);
    lastUpdatedIndex.current = rowIndex;
    setCurrentRowLandedCostInfo({ rowIndex: rowIndex });
  };
  const getContextMenu = (row: any) => {
    let contextMenu = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      props.booksDocument.documentType !== DOC_TYPE.SALES_ORDER &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      contextMenu.push({
        title: row.reservedQuantitiesData
          ? 'Update reserved stock'
          : 'Add reserved stock',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: ' p-0',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }

    let contactObj = contact || props.booksDocument.contact;
    if (
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      contactObj?.tdsInfoIndia?.deductionApplicable &&
      row?.product?.tdsApplicableIndia
    ) {
      contextMenu.push({
        title: 'Deduct TDS',
        icon: DKIcons.ic_add,
        className: ' p-0',
        onClick: (data: any) => showAlert('working on it')
      });
    }

    if (
      tenantInfo?.landedCostSettings?.landedCost &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      (props.booksDocument.landedCost || booksDocument.landedCost)
    ) {
      contextMenu.push({
        title: 'Allocate Cost',
        icon: DKIcons.ic_edit,
        className: ' p-0',
        onClick: (data: any) => onOpenAllocateCost(data)
      });
    }

    contextMenu.push({
      title: 'Delete',
      icon: DKIcons.ic_delete,
      className: ' p-0',
      onClick: (data: any) => onDelete(data)
    });
    return contextMenu;
  };
  const getDataRows = (rowData: any) => {
    if (!Utility.isDropship(props.booksDocument)) {
      return {
        ...rowData,
        rowContextMenu:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getContextMenu(rowData),
        rowButtons:
          props.documentMode === DOCUMENT_MODE.VIEW ||
          props.draftType === DraftTypes.READONLY
            ? null
            : getButtonsForRow(rowData)
      };
    } else {
      return {
        ...rowData
      };
    }
  };
  const getButtonsForRow = (row: any) => {
    let buttons: any[] = [];
    if (
      Utility.isSalesDocument(props.booksDocument) &&
      booksDocument.reservedStock &&
      row.product &&
      row.product.type === PRODUCT_TYPE.TRACKED
    ) {
      buttons.push({
        title: '',
        icon: row.reservedQuantitiesData
          ? ic_inventory_fulfill
          : ic_inventory_pending,
        className: 'p-v-0 inventory-icon-document',
        onClick: (data: any) => fetchProductReservedInfo(data)
      });
    }
    return buttons;
  };
  const getLeftPosForShippingAddress = () => {
    if (shippingAddressBlock) {
      const popup = shippingAddressBlock.current?.closest('.popup-window');
      if (popup) {
        const popupLeft = popup.getBoundingClientRect().left;
        const rect = shippingAddressBlock.current?.getBoundingClientRect();
        return rect.left - popupLeft - 22;
      } else {
        return 198;
      }
    } else {
      return 198;
    }
  };
  const setDetailedContact = async (id: number) => {
    try {
      const detailedContact = await ContactService.getContactDetailsById(id);
      setNeedToUpdateAddresses(true);
      setContact(detailedContact);
      if (!booksDocument.isDocumentTouched) {
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            isDocumentTouched: true
          };
        });
      }
      setBillingAddress({
        billingAddress: detailedContact.billingAddress,
        currentBillingAddress: booksDocument.billTo
      });
      setShippingAddress({
        shippingAddress: detailedContact.shippingAddress,
        currentShippingAddress: booksDocument.shipTo
      });
    } catch (err) {
      console.error('Error loading detailed contact: ', err);
    }
  };

  const getContactPicker = () => {
    let contactList =
      contactsData?.content?.filter(
        (contact: any) => contact.status.toLowerCase() === STATUS_TYPE.active
      ) || [];

    if (props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      contactList =
        contactList.filter((contact: any) => contact.vendor === true) || [];
    }

    const contactPickerTop =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
        ? -50
        : 50;

    return openContactSelector ? (
      <DKListPicker2
        data={contactList}
        className="position-absolute z-index-3 bg-white border-m"
        style={{
          top: contactPickerTop,
          left: [
            BOOKS_ADDRESS_TYPES.NONE,
            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
          ].includes(booksAddressType)
            ? 0
            : getLeftPosForShippingAddress(),
          minWidth: 200
        }}
        renderer={(index: number, contactObj: any) => contactObj.name}
        onSelect={(index: number, contactObj: any) => {
          // setProductRows([]);
          isContactChangedManually.current = true;
          setOpenContactSelector(false);
          setDetailedContact(contactObj.id);
          setSelectedPaymentOption(null);
          setBooksDocument((prevState: any) => {
            return {
              ...prevState,
              paymentInformation: null
            };
          });
        }}
        onClose={() => setTimeout(() => setOpenContactSelector(false), 100)}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query:
                props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
                  ? 'status=active,vendor=true'
                  : 'status=active'
            };

            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        }}
        button={
          checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
            ? {
                title: '+ Add New',
                icon: DKIcons.ic_contact,
                className: 'bg-button text-white',
                style: {},
                onClick: () => {
                  setContactMode(DOCUMENT_MODE.NEW);
                  setShowAddContactPopup(true);
                }
              }
            : null
        }
      />
    ) : null;
  };
  const getFormattedAddress = (address: any, isVendorType?: boolean) => {
    const { contactName, line1, line2, cityAndState, countryAndPostalCode } =
      getFormattedAddressObj(address);

    let formattedAddress = '';
    if (!Utility.isEmpty(contactName) && !isVendorType) {
      formattedAddress += contactName + ', <br/>';
    }
    if (!Utility.isEmpty(line1) && !isVendorType) {
      formattedAddress += line1 + ', ';
    }
    if (!Utility.isEmpty(line2) && !isVendorType) {
      formattedAddress += line2 + ', ';
    }

    if (isVendorType && !Utility.isEmpty(address.state)) {
      formattedAddress += address.state + ', ';
    } else if (!Utility.isEmpty(cityAndState)) {
      formattedAddress += cityAndState + ', ';
    }

    if (!Utility.isEmpty(countryAndPostalCode)) {
      formattedAddress += countryAndPostalCode;
    }

    return formattedAddress;
  };
  const onPlaceOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      if (Utility.isSalesDocument(props.booksDocument)) {
        let shipToAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipToAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipToAddress = {
            ...tempDocument.shipTo,
            placeOfSupply: value.value
          };
        }
        const gstType = updateGstType({
          ...tempDocument,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipTo: shipToAddress,
          gstType: gstType,
          placeOfSupply: value.value,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      } else {
        let shipFromAddress: any = {};
        if (!Utility.isEmpty(address)) {
          shipFromAddress = {
            ...address,
            placeOfSupply: value.value
          };
        } else {
          shipFromAddress = {
            ...tempDocument.shipFrom,
            placeOfSupply: value.value
          };
        }
        let shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: dos.value
        };
        const gstType = updateGstType({
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress
        });

        tempDocument = {
          ...tempDocument,
          shipFrom: shipFromAddress,
          shipTo: shipToAddress,
          gstType: gstType,
          items: tempDocument.items?.map((item: any) => {
            return { ...item, gstType: gstType };
          })
        };
      }
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument
        };
      });
    }
  };
  const onDestinationOfsupplyChange = (value: any, address?: any) => {
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      let tempDocument: any = { ...booksDocument };
      let shipToAddress: any = {};
      if (!Utility.isEmpty(address)) {
        shipToAddress = {
          ...address,
          destinationOfSupply: value.value
        };
      } else {
        shipToAddress = {
          ...tempDocument.shipTo,
          destinationOfSupply: value.value
        };
      }
      let shipFromAddress = {
        ...tempDocument.shipFrom,
        placeOfSupply: pos.value,
        destinationOfSupply: value.value
      };

      const gstType = updateGstType({
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress
      });
      tempDocument = {
        ...tempDocument,
        shipTo: shipToAddress,
        shipFrom: shipFromAddress,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };

      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          ...tempDocument
        };
      });
    }
  };
  const updateBillingShippingAddress = (requireAddressCheck?: boolean) => {
    const isBillOrOrder =
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER;

    const billingAddressList: BooksAddress[] = isBillOrOrder
      ? tenantInfo.billingAddresses
      : props.documentMode === DOCUMENT_MODE.EDIT
      ? editModeBillingAddresses
      : contact?.billingAddress;

    const shippingAddressList: BooksAddress[] =
      isBillOrOrder && !Utility.isDropship(props.booksDocument)
        ? tenantInfo.shippingAddresses
        : props.documentMode === DOCUMENT_MODE.EDIT
        ? editModeShippingAddresses
        : contact?.shippingAddress;

    let billingAddress: BooksAddress | undefined;
    let shippingAddress: BooksAddress | undefined;

    let canShowTenantAddressAlert = false;
    const complianceStatus = Utility.getTenantComplianceSettings(tenantInfo);
    if (
      !complianceStatus.complianceSetting &&
      (props.documentMode === DOCUMENT_MODE.NEW ||
        props.documentMode === DOCUMENT_MODE.COPY)
    ) {
      canShowTenantAddressAlert = true;
    }

    if (isBillOrOrder) {
      billingAddress =
        billingAddressList?.filter((address: any) => address.preferred)[0] ||
        (billingAddressList?.length ? billingAddressList[0] : null);

      shippingAddress =
        shippingAddressList?.filter((address: any) => address.preferred)[0] ||
        (shippingAddressList?.length ? shippingAddressList[0] : null);

      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !shippingAddress
      ) {
        showAlert();
      }
    } else {
      billingAddress = billingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      shippingAddress = shippingAddressList?.filter(
        (address) => address.preferred === true
      )[0];

      const tenantHasShippingAddress = Boolean(
        tenantInfo.shippingAddresses?.length
      );
      if (
        requireAddressCheck &&
        canShowTenantAddressAlert &&
        !tenantHasShippingAddress
      ) {
        showAlert();
      }
    }

    setBillingAddress({
      billingAddress: billingAddressList,
      currentBillingAddress: billingAddress as BooksAddress
    });

    setShippingAddress({
      shippingAddress: shippingAddressList,
      currentShippingAddress: shippingAddress as BooksAddress
    });

    return {
      billingAddress,
      shippingAddress
    };
  };

  const updatePlaceOfSupplyOnAddress = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele.value?.toLowerCase() === (address.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    if (Utility.isSalesDocument(props.booksDocument)) {
      setPos(pos[0]);
      onPlaceOfsupplyChange(obj, address);
    } else {
      setDos(pos[0]);
      onDestinationOfsupplyChange(obj, address);
    }
  };
  const updatePlaceOfSupplyOnAddressBuy = (address: any) => {
    let pos = indianStatesOptions.filter(
      (ele: any) =>
        ele?.value?.toLowerCase() === (address?.state?.toLowerCase() || '')
    );
    let obj: any = { ...pos[0] };
    setPos(pos[0]);
    onPlaceOfsupplyChange(obj, address);
  };

  const getCustomCompanyDetails = (title?: string) => {
    const isBillOrOrder = [
      DOC_TYPE.SUPPLIER_QUOTE,
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);
    const orgName = customLocation?.locationDetails?.title;
    const address = getFormattedAddress(
      customLocation?.locationDetails?.address,
      isBillOrOrder
    );
    const phone = customLocation?.locationDetails?.phone;
    const email = customLocation?.locationDetails?.email;

    return (
      <div
        className={`column`}
        style={{
          maxWidth: 250
        }}
      >
        <div className="column width-auto">
          <div className="row">
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
          <div className={`row`}>
            <DKLabel text={orgName} className="fw-m fs-r" />
          </div>
          <DKLabel text={address} className="parent-width" />
          {phone && <DKLabel text={phone} className="parent-width" />}
          {email && <DKLabel text={email} className="parent-width" />}
        </div>
      </div>
    );
  };
  const getDeliveryAddressDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.SUPPLIER_QUOTE,
      DOC_TYPE.BILL,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);

    return !isBillOrOrder && Utility.isEmpty(contact) ? (
      getContactSelector()
    ) : (
      <div>
        <div className="row width-auto align-items-start gap-2">
          {getContactCard(
            t(`DOCUMENT.BILL_TO`),
            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS,
            isBillOrOrder
          )}
          {getContactCard(
            t(`DOCUMENT.SHIP_TO`),
            BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS,
            isBillOrOrder
          )}
        </div>
      </div>
    );
  };

  const getContactCard = (
    title: string,
    addressType: BOOKS_ADDRESS_TYPES,
    isBillOrOrder: boolean
  ) => {
    if (isBillOrOrder && !Utility.isEmpty(customLocation)) {
      return getCustomCompanyDetails(title);
    }

    const canEditContact =
      props.documentMode !== DOCUMENT_MODE.EDIT &&
      !isBillOrOrder &&
      checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE);
    return (
      <div
        ref={
          addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
            ? shippingAddressBlock
            : null
        }
        className={`column document-address-block`}
        style={{
          minWidth: 170,
          maxWidth: 250,
          marginLeft: -10,
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10
        }}
      >
        <div className="row parent-block justify-content-between">
          <div
            className={`row width-auto ${
              canEditContact ? 'cursor-pointer listPickerBG' : ''
            }`}
            onClick={() => {
              if (canEditContact) {
                setOpenContactSelector((prevValue) => !prevValue);
                setBooksAddressType(addressType);
              }
            }}
          >
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
        </div>
        <div className={`mt-s`}>
          {addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS && (
            <div className="position-relative">
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                title={canEditContact ? 'Change contact' : ''}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder
                      ? tenantInfo.name
                      : `${contact.name}-(${contact.currencyCode})`
                  }
                  className="fw-m fs-r"
                />
              </div>
              <div
                className={`row ${
                  !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ? ''
                    : 'cursor-pointer listPickerBG'
                }`}
                onClick={(e: any) => {
                  if (
                    checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                  ) {
                    setOpenBillingAddressList(true);
                  }
                }}
              >
                <DKLabel
                  text={
                    !Utility.isEmpty(
                      booksDocument.billTo ||
                        billingAddress?.currentBillingAddress ||
                        (isBillOrOrder ? tenantInfo?.address : null)
                    )
                      ? getFormattedAddress(
                          booksDocument.billTo ||
                            billingAddress?.currentBillingAddress ||
                            (isBillOrOrder ? tenantInfo?.address : null)
                        )
                      : ''
                  }
                />
              </div>
              {openBillingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.billingAddresses
                      : billingAddress?.billingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={
                    isBillOrOrder && Utility.isDropship(props.booksDocument)
                      ? null
                      : {
                          title: 'Manage address',
                          className:
                            'text-white fw-m bg-button justify-content-center',
                          onClick: () => {
                            if (isBillOrOrder) {
                              setActiveOrgProfileTab(
                                ORGANISATION_FORM_TAB.ADDRESS_INFO
                              );
                              setShowUpdateOrgPopup(true);
                            } else {
                              setActiveContactTab(
                                CONTACT_FORM_TAB.ADDRESS_INFO
                              );
                              setContactMode(DOCUMENT_MODE.EDIT);
                              setShowAddContactPopup(true);
                            }
                            setOpenBillingAddressList(false);
                          }
                        }
                  }
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setBillingAddress({
                      billingAddress:
                        billingAddress?.billingAddress as BooksAddress[],
                      currentBillingAddress: addressObj
                    });
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      billTo: addressObj
                    }));
                    setOpenBillingAddressList(false);
                  }}
                  onClose={() => {
                    setOpenBillingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
            <>
              <div
                className={`row ${
                  canEditContact ? 'cursor-pointer listPickerBG' : ''
                }`}
                title={canEditContact ? 'Change contact' : ''}
                onClick={() => {
                  if (canEditContact) {
                    setActiveContactTab(CONTACT_FORM_TAB.GENERAL_INFO);
                    setOpenContactSelector((prevValue) => !prevValue);
                    setBooksAddressType(addressType);
                  }
                }}
              >
                <DKLabel
                  text={
                    isBillOrOrder && !Utility.isDropship(props.booksDocument)
                      ? tenantInfo.name
                      : dropshipShipToContact
                      ? dropshipShipToContact
                      : contact?.name
                      ? `${contact.name}-(${contact.currencyCode})`
                      : ''
                  }
                  className="fw-m fs-r"
                />
              </div>
              <div
                className={`row ${
                  !checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ? ''
                    : 'cursor-pointer listPickerBG'
                }`}
                onClick={(e: any) => {
                  if (
                    checkUserPermission(
                      PERMISSIONS_BY_MODULE.CONTACTS.CREATE
                    ) &&
                    !Utility.isDropship(props.booksDocument)
                  ) {
                    setOpenShippingAddressList(true);
                  }
                }}
              >
                <DKLabel
                  text={
                    !Utility.isEmpty(
                      booksDocument.shipTo ||
                        shippingAddress?.currentShippingAddress ||
                        (isBillOrOrder &&
                        !Utility.isDropship(props.booksDocument)
                          ? tenantInfo?.address
                          : null)
                    )
                      ? getFormattedAddress(
                          booksDocument.shipTo ||
                            shippingAddress?.currentShippingAddress ||
                            (isBillOrOrder &&
                            !Utility.isDropship(props.booksDocument)
                              ? tenantInfo?.address
                              : null)
                        )
                      : ''
                  }
                />
              </div>
              {openShippingAddressList && (
                <DKListPicker2
                  data={
                    isBillOrOrder
                      ? tenantInfo.shippingAddresses
                      : shippingAddress?.shippingAddress
                  }
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={{
                    title: 'Manage address',
                    className:
                      'text-white fw-m bg-button justify-content-center',
                    onClick: () => {
                      if (isBillOrOrder) {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                      } else {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                      }
                      setOpenShippingAddressList(false);
                    }
                  }}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setShippingAddress({
                      shippingAddress:
                        shippingAddress?.shippingAddress as BooksAddress[],
                      currentShippingAddress: addressObj
                    });
                    if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                      setShipToOrFromAddressChangedForUS(true);
                    }
                    setBooksDocument((prevState: any) => ({
                      ...prevState,
                      shipTo: addressObj
                    }));
                    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
                      updatePlaceOfSupplyOnAddress(addressObj);
                    }
                    setOpenShippingAddressList(false);
                  }}
                  onClose={() => {
                    setOpenShippingAddressList(false);
                  }}
                  allowSearch={false}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getCompanyDetails = () => {
    const isBillOrOrder = [
      DOC_TYPE.BILL,
      DOC_TYPE.SUPPLIER_QUOTE,
      DOC_TYPE.ORDER,
      DOC_TYPE.JOB_WORK_OUT_ORDER
    ].includes(props.booksDocument.documentType);

    const canEditContact =
      isBillOrOrder && props.documentMode !== DOCUMENT_MODE.EDIT;

    const companyAddress = isBillOrOrder
      ? booksDocument.shippingAddress
        ? booksDocument.shippingAddress
        : contact?.billingAddress?.length
        ? contact?.billingAddress[0]
        : null
      : tenantInfo?.address;

    return (
      <div
        className={`column parent-block`}
        style={{
          maxWidth: 250,
          marginTop: canEditContact ? -10 : 0,
          marginLeft: canEditContact ? -10 : 0,
          padding: canEditContact ? 10 : 0
        }}
      >
        <div className="row width-auto">
          {isBillOrOrder && Utility.isEmpty(contact) ? (
            getContactSelector()
          ) : (
            <div
              className={`${
                canEditContact && !Utility.isEmpty(contact)
                  ? 'cursor-pointer border-box'
                  : ''
              }`}
              onClick={() => {
                if (canEditContact && !Utility.isEmpty(contact)) {
                  setOpenContactSelector((prevValue) => !prevValue);
                  setBooksAddressType(BOOKS_ADDRESS_TYPES.BILLING_ADDRESS);
                }
              }}
            >
              <div
                className={`row justify-content-between ${
                  canEditContact ? 'listPickerBG' : ''
                }`}
              >
                <DKLabel
                  text={
                    isBillOrOrder || props.RfqToSQuote
                      ? `${contact.name}-(${contact.currencyCode})`
                      : tenantInfo.name
                  }
                  className="fw-m fs-r"
                />
              </div>
            </div>
          )}
        </div>
        {Utility.isEmpty(companyAddress) ? null : (
          // Ship from for Bill/Order
          <>
            {isBillOrOrder ? (
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      isBillOrOrder &&
                      checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(companyAddress, isBillOrOrder)}
                    className="parent-width"
                  />
                </div>
                {openShipFromForBuy && (
                  <DKListPicker2
                    data={contact?.billingAddress}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      ></div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                        setContactMode(DOCUMENT_MODE.EDIT);
                        setShowAddContactPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddressBuy(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
              </div>
            ) : (
              // Ship from for Invoice/Quote
              <div>
                <div
                  title="Edit selected contact"
                  className="row width-auto listPickerBG cursor-pointer"
                  onClick={() => {
                    if (
                      !isBillOrOrder &&
                      checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.CREATE)
                    ) {
                      setOpenShipFromForBuy(true);
                    }
                  }}
                >
                  <DKLabel
                    text={getFormattedAddress(
                      !Utility.isEmpty(booksDocument.shipFrom)
                        ? booksDocument.shipFrom
                        : companyAddress,
                      isBillOrOrder
                    )}
                    className="parent-width"
                  />
                </div>
                {!props.RfqToSQuote && openShipFromForBuy && (
                  <DKListPicker2
                    data={tenantInfo?.shippingAddresses}
                    className="position-absolute z-index-3 bg-white border-m shadow-m"
                    style={{ minWidth: 250 }}
                    renderer={(index: number, addressObj: any) => (
                      <div
                        style={{
                          width: 200,
                          whiteSpace: 'pre-wrap',
                          textAlign: 'left'
                        }}
                        dangerouslySetInnerHTML={{
                          __html: getFormattedAddress(addressObj)
                        }}
                      >
                        {/* {getFormattedAddress(addressObj)} */}
                      </div>
                    )}
                    onEdit={(index: number, obj: any) => {}}
                    button={{
                      title: 'Manage address',
                      className:
                        'text-white fw-m bg-button justify-content-center',
                      onClick: () => {
                        setActiveOrgProfileTab(
                          ORGANISATION_FORM_TAB.ADDRESS_INFO
                        );
                        setShowUpdateOrgPopup(true);
                        setOpenShipFromForBuy(false);
                      }
                    }}
                    canEdit={false}
                    onSelect={(index: number, addressObj: any) => {
                      if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                        setShipToOrFromAddressChangedForUS(true);
                      }
                      setBooksDocument((prevState: any) => {
                        return {
                          ...prevState,
                          shipFrom: addressObj
                        };
                      });
                      if (
                        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                        (props.booksDocument.documentType === DOC_TYPE.BILL ||
                          props.booksDocument.documentType === DOC_TYPE.ORDER ||
                          DOC_TYPE.JOB_WORK_OUT_ORDER)
                      ) {
                        updatePlaceOfSupplyOnAddress(addressObj);
                      }
                      setOpenShipFromForBuy(false);
                    }}
                    onClose={() => {
                      setOpenShipFromForBuy(false);
                    }}
                    allowSearch={false}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const getContactSelector = () => {
    return (
      <div
        className={`${
          props.canValidate
            ? 'bg-chip-red border-red text-red'
            : 'bg-chip-blue border-blue text-blue'
        } p-v-s p-h-r border-radius-s mt-s cursor-pointer`}
        style={{ border: 'dashed', borderWidth: 1 }}
        onClick={() => setOpenContactSelector((prevValue) => !prevValue)}
      >
        <DKLabel text={`+ Add a Contact`} />
      </div>
    );
  };

  const getDatagrid = () => {
    return (
      <>
        <DKDataGrid
          className={'m-r'}
          allowBulkOperation={false}
          needColumnIcons={false}
          allowColumnEdit={false}
          allowColumnSort={false}
          allowRowEdit={true}
          rows={productRows}
          columns={getDataGridColumns()}
          onRowUpdate={onRowUpdate}
          onRowClick={onRowClick}
        />
        {!(props.draftType === DraftTypes.READONLY) && !checkState && (
          <div className="row justify-content-between">
            <div className="row">
              <DKButton
                title={`+ Add Item`}
                onClick={() => addNewItem()}
                className={`${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } fw-m p-0`}
                style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
              />
              {productRows.length > 0 && (
                <DKButton
                  title={`- Clear all items`}
                  onClick={() => clearAllItems()}
                  className={`${
                    props.draftType === DraftTypes.READONLY
                      ? 'text-gray'
                      : 'text-blue'
                  } fw-m p-0`}
                  style={{ marginTop: -10, zIndex: 1 }}
                />
              )}
            </div>
            <div className="row"></div>
          </div>
        )}
      </>
    );
  };

  const updateCurrency = ({
    tempDocument,
    currencyCode,
    exchangeRate,
    gstExchangeRate,
    updateState = true
  }: any) => {
    const oldCurrency = tempDocument.currency;
    const currencyChanged = oldCurrency !== currencyCode;
    const previousExchangeRate = tempDocument.exchangeRate;

    let calculatedGSTExchangeRate = 1;
    if (currencyChanged && gstExchangeRate) {
      calculatedGSTExchangeRate =
        (gstExchangeRate * exchangeRate) / tempDocument.previousExchangeRate;
    }
    tempDocument = {
      ...tempDocument,
      currencyCode: currencyCode,
      currency: currencyCode,
      exchangeRate: exchangeRate,
      previousExchangeRate: previousExchangeRate,
      gstExchangeRate: calculatedGSTExchangeRate
    };

    if (updateState) {
      setBooksDocument((prevState: any) => {
        return {
          ...prevState,
          currencyCode: currencyCode,
          currency: currencyCode,
          exchangeRate: exchangeRate,
          previousExchangeRate: previousExchangeRate,
          gstExchangeRate: calculatedGSTExchangeRate
        };
      });
    }

    if (currencyChanged || exchangeRate) {
      if (!updateState) {
        return updateBasedOnCurrency(
          tempDocument,
          exchangeRate,
          previousExchangeRate,
          updateState
        );
      } else {
        updateBasedOnCurrency(tempDocument, exchangeRate, previousExchangeRate);
      }
    }
  };

  const updateBasedOnCurrency = (
    tempDocument: any,
    exchangeRate: number,
    previousExchangeRate: number,
    updateState = true
  ) => {
    if (!tempDocument.items) {
      return;
    }
    let documentItems = [...(tempDocument.items as Array<DocumentItem>)];
    documentItems = documentItems.map((item, index) => {
      const discount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        item.discountAmount
      );
      if (item.discountInPercent) {
        item = {
          ...item,
          discountAmount: discount
        };
      } else {
        item = {
          ...item,
          discountAmount: discount,
          discount: discount
        };
      }
      return {
        ...item,
        unitPrice: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.unitPrice
        ),
        taxAmount: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.taxAmount
        ),
        totalAmount: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.totalAmount
        ),
        total: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.total
        ),
        totalWithDiscount: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.totalWithDiscount
        ),
        subTotal: convertToCurrenctExchangeRate(
          exchangeRate,
          previousExchangeRate,
          item.subTotal
        )
      };
    });

    let tcsAmount = 0;
    let totalTdsAmount = 0;

    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      tcsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.tcsAmount
      );
      totalTdsAmount = convertToCurrenctExchangeRate(
        exchangeRate,
        previousExchangeRate,
        tempDocument.totalTdsAmount
      );
    }

    tempDocument = {
      ...tempDocument,
      items: documentItems,
      tcsAmount,
      totalTdsAmount
    };

    if (tempDocument.items && tempDocument.items.length > 0 && updateState) {
      // if pricelist is enabled, then calculate taxes after pricelist prices are applied
      if (isPriceListEnabled()) {
        const productsPayload = tempDocument.items
          .filter((item: any) => !Utility.isEmpty(item.product))
          .map((item: any) => ({
            productId: item.product.productId,
            uomId: item.documentUom,
            quantity: +item.productQuantity
          }));
        getPricing(
          productsPayload,
          tempDocument.items,
          tempDocument.currency,
          true,
          exchangeRate
        );
      } else {
        if (getTenantTaxSystem() === TAX_SYSTEM.US) {
          calculateUSTax(undefined, tempDocument.items);
        } else {
          tempDocument.items.forEach((item: any, index: number) => {
            calculateTaxesAndAmount(index, tempDocument.items);
          });
        }
      }
    } else {
      return tempDocument;
    }

    // this.calculateTaxLocalAndUpdate();
    // this.updateFormFieldConfig();
    // this.setCurrencyInDropdown();
  };

  const enableTaxColumForUS = (flag: boolean) => {
    let config = columnConfig;
    config = config.map((column: any) => {
      if (column.key === 'taxAmount') {
        column.editable = flag;
      }
    });
    setColumnConfig(columnConfig);
  };

  const showTaxField = () =>
    getTenantTaxSystem() === TAX_SYSTEM.US &&
    tenantInfo.complianceEnabled &&
    !(
      props.booksDocument.documentType === DOC_TYPE.BILL ||
      props.booksDocument.documentType === DOC_TYPE.ORDER ||
      props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
    );

  const showUSInvalidZipCodeAlert = (message: string) => {
    const alertButtonConfig = [
      {
        title: 'Cancel',
        className: 'bg-gray2 border-m mr-s',
        onClick: () => {
          usTaxCalculateAlertVisible.current = false;
        }
      },
      {
        title: 'Update',
        className: 'bg-button text-white',
        onClick: () => {
          setContactMode(DOCUMENT_MODE.EDIT);
          setShowAddContactPopup(true);
          usTaxCalculateAlertVisible.current = false;
        }
      }
    ];
    showAlert('Error', message, alertButtonConfig);
  };

  const handleUSLineItemsWhenTaxIsOff = (
    updatedItemIndex: any,
    lineItems: DocumentItem[],
    shipTo: any
  ) => {
    let prodRows: any[] = [...lineItems];
    lineItems.forEach((row: any, index: number) => {
      ItemTaxCalculator.item = {
        ...row,
        ...getDiscountRelatedKeys(
          row.discountInPercent ? row.discount + '%' : row.discount
        )
      };
      ItemTaxCalculator.setInitialValues();
      let taxAmount = ItemTaxCalculator.calculateTaxAmount();
      if (updatedItemIndex === undefined || updatedItemIndex === index) {
        taxAmount = (row.product.taxable ? null : 0) as number;
      }
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      prodRows[index] = ItemTaxCalculator.item;
    });
    setBooksDocument((prevState: any) => ({
      ...prevState,
      shipTo: shipTo,
      items: [...prodRows]
    }));
    setProductRows([...prodRows]);
  };

  const calculateUSTax = (
    updatedItemIndex: any,
    items?: DocumentItem[],
    shippingInfo?: any
  ) => {
    let lineItems = items?.length ? items : [...productRows];
    if (lineItems.length > 0) {
      ItemTaxCalculator.tenantInfo = tenantInfo;
      let payload: any = {
        customerCode: contact?.avalaraCustomerCode,
        companyCode: tenantInfo.avalaraCode,
        shipTo: shippingInfo ? shippingInfo.shipTo : booksDocument.shipTo,
        shipFrom: shippingInfo ? shippingInfo.shipFrom : booksDocument.shipFrom,
        lines: [],
        docDate: DateFormatService.getDateStrFromDate(
          documentDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      };

      if (contact?.taxExempted === true) {
        payload['exemptionNo'] = contact.code;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        !tenantInfo.complianceEnabled &&
        payload.shipTo &&
        (!payload.shipTo.postalCode ||
          !payload.shipTo.address1 ||
          !payload.shipTo.state)
      ) {
        payload.shipTo = null;
      }
      if (
        getTenantTaxSystem() === TAX_SYSTEM.US &&
        payload.shipTo &&
        payload.shipTo.country !== COUNTRY_CODES.US &&
        payload.shipTo.country !== 'United States of America' &&
        payload.shipTo.country !== 'United States'
      ) {
        enableTaxColumForUS(false);
      } else {
        enableTaxColumForUS(true);
      }

      lineItems.forEach((row: any, index: number) => {
        ItemTaxCalculator.item = {
          ...row,
          ...getDiscountRelatedKeys(
            row.discountInPercent ? row.discount + '%' : row.discount
          )
        };
        ItemTaxCalculator.setInitialValues();
        let taxAmount: any = ItemTaxCalculator.calculateTaxAmount();
        if (updatedItemIndex === undefined || updatedItemIndex === index) {
          taxAmount = row.product.taxable ? null : 0;
        }
        if (items !== undefined) {
          taxAmount = null;
        }

        payload.lines.push({
          amount: ItemTaxCalculator.item.totalWithDiscount,
          description: ItemTaxCalculator.item.productDescription,
          quantity: ItemTaxCalculator.item.productQuantity,
          taxAmount: taxAmount,
          taxCode: ItemTaxCalculator.item.product.categoryCode
        });
        ItemTaxCalculator.item.taxAmount = null as any;
      });

      if (showTaxField()) {
        TaxService.calculateUsTax(payload).then(
          (taxes: any) => {
            let prodRows: any[] = [...lineItems];
            taxes.lines.forEach((taxLine: any, index: number) => {
              ItemTaxCalculator.item = {
                ...prodRows[index],
                ...getDiscountRelatedKeys(
                  prodRows[index].discountInPercent
                    ? prodRows[index].discount + '%'
                    : prodRows[index].discount
                )
              };
              ItemTaxCalculator.setInitialValues();
              ItemTaxCalculator.item.taxAmount = null as any;
              ItemTaxCalculator.item.taxAmount = taxLine.tax;
              ItemTaxCalculator.updateCalculatedValues();
              prodRows[index] = ItemTaxCalculator.item;
            });
            setProductRows([...prodRows]);
            setBooksDocument((prevBooksDocument: any) => {
              return {
                ...prevBooksDocument,
                items: [...prodRows]
              };
            });
          },
          (err) => {
            console.error('Error calculating tax: ', err.data);
            if (
              !usTaxCalculateAlertVisible.current &&
              err.data?.errorMessage?.includes('postal code is not valid')
            ) {
              usTaxCalculateAlertVisible.current = true;
              showUSInvalidZipCodeAlert(err.data?.errorMessage);
            }
            handleUSLineItemsWhenTaxIsOff(
              updatedItemIndex,
              lineItems,
              payload.shipTo
            );
          }
        );
      } else {
        handleUSLineItemsWhenTaxIsOff(
          updatedItemIndex,
          lineItems,
          payload.shipTo
        );
      }
    }
  };

  const setTotalAmountforLandedCost = (rowIndex: any) => {
    let row = productRows[rowIndex];
    let landedCostDetails = row?.landedCostDetails;
    let category =
      !Utility.isEmpty(landedCostDetails) &&
      JSON.parse(landedCostDetails?.landedCostCategory);
    let flag =
      currentRowLandedCostInfo?.rowIndex === rowIndex
        ? currentRowLandedCostInfo?.rowIndex === rowIndex
        : props.documentMode === DOCUMENT_MODE.EDIT;
    if (
      flag &&
      category &&
      category.value === 'CUSTOM_DUTY' &&
      props.booksDocument.documentType === DOC_TYPE.BILL &&
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST
    ) {
      let totalSum = row?.landedCostDetails?.productDetails.reduce(function (
        prev: any,
        cur: any
      ) {
        return prev + cur['customDutyAndOtherCharges'];
      },
      0);
      ItemTaxCalculator.item['totalAmount'] = Number(totalSum);
      ItemTaxCalculator.item['total'] = Number(totalSum);
      ItemTaxCalculator.item['subTotal'] = Number(totalSum);
      ItemTaxCalculator.item['unitPrice'] = Number(totalSum);
      let sampleTax = purchaseTaxes.find((tax) => tax.percent === 0);
      ItemTaxCalculator.item['tax'] = sampleTax;
      ItemTaxCalculator.item['taxCode'] = sampleTax.code;
      ItemTaxCalculator.item['taxDetails'] = [];
      ItemTaxCalculator.item['taxName'] = sampleTax.name;
      const taxAmount = ItemTaxCalculator.calculateTaxAmount();
      ItemTaxCalculator.item.taxAmount = taxAmount;
      setCurrentRowLandedCostInfo(null);
    }
  };

  const getDiscountRelatedKeys = (discount: string) => {
    let updatedItemKeys: any = {};
    let discountInNum = 0;
    if (discount) {
      if (discount.toString().indexOf('%') > -1) {
        updatedItemKeys.discountInPercent = true;
        discountInNum = Number(discount.replace('%', ''));
      } else {
        discountInNum = Number(discount);
        updatedItemKeys.discountInPercent = false;
      }
      updatedItemKeys.discount = Utility.roundOff(discountInNum);
      return updatedItemKeys;
    } else {
      updatedItemKeys.discount = discountInNum;
    }
    return updatedItemKeys;
  };

  const setGSTValueManual = (rowIndex: any, productRow: any, rowItem: any) => {
    let row = productRow;
    let gstType = rowItem.gstType ? rowItem.gstType : row && row.gstType;
    if (typeof gstType === 'undefined' || gstType === null || gstType === '') {
      gstType = updateGstType(booksDocument);
    }
    if (
      tenantInfo?.country === TAX_SYSTEM.INDIA_GST &&
      ItemTaxCalculator.item &&
      row &&
      row.userSetTaxes &&
      gstType !== GST_TYPE.EXEMPT
    ) {
      let amount = 0;
      if (gstType === GST_TYPE.INTER) {
        amount = Number(row.igstAmount);
      } else if (gstType === GST_TYPE.INTRA) {
        amount = Number(row.cgstAmount) + Number(row.sgstAmount);
      }
      let subTotal = row.subTotal;
      if (Utility.isEmpty(subTotal)) {
        subTotal = rowItem.subTotal;
      }
      if (!row.unitPriceGstInclusive) {
        let per = (Number(amount) / Number(subTotal)) * 100;
        let tax = deepClone(ItemTaxCalculator.item['tax']);
        let prevTax = ItemTaxCalculator.item['tax'];
        tax['percent'] = per;
        tax['isTaxGroup'] = false;
        ItemTaxCalculator.item['tax'] = tax;
        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
        }
        ItemTaxCalculator.item.taxAmount =
          ItemTaxCalculator.calculateTaxAmount();
        ItemTaxCalculator.item['tax'] = prevTax;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item['igstRate'] = prevTax.percent;
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Number(row.cgstAmount) + Number(row.sgstAmount);
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
          ItemTaxCalculator.item['cgstRate'] = prevTax.percent / 2;
          ItemTaxCalculator.item['sgstRate'] = prevTax.percent / 2;
        }
      } else {
        // making unitPriceGstInclusive flag false for calculating subtotal without unitPriceGstInclusive flag
        //for calculating manual user taxes and subtotal
        // ItemTaxCalculator.item.unitPriceGstInclusive = false;
        ItemTaxCalculator.setSubTotal();
        let calculatedSubTotal =
          ItemTaxCalculator.item.subTotal || row.subTotal;
        // ItemTaxCalculator.item.unitPriceGstInclusive = true;
        ItemTaxCalculator.setTotal();

        if (gstType === GST_TYPE.INTER) {
          ItemTaxCalculator.item['igstAmount'] = row.igstAmount;
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            row.igstAmount + row.cessAmount,
            tenantInfo.decimalScale
          );
        } else if (gstType === GST_TYPE.INTRA) {
          amount = Utility.roundingOff(
            Number(row.cgstAmount) + Number(row.sgstAmount),
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item.taxAmount = Utility.roundingOff(
            amount + row.cessAmount,
            tenantInfo.decimalScale
          );
          ItemTaxCalculator.item['cgstAmount'] = row.cgstAmount;
          ItemTaxCalculator.item['sgstAmount'] = row.sgstAmount;
        }
        if (ItemTaxCalculator && ItemTaxCalculator.item) {
          let taxAmount = ItemTaxCalculator?.item?.taxAmount || 0;
          ItemTaxCalculator.item.subTotal = Utility.roundingOff(
            calculatedSubTotal,
            tenantInfo.decimalScale
          );
        }
      }
      setCurrentRowGSTInfo(null);
    }
  };

  const calculateTaxesAndAmount = (
    rowIndex: number,
    unsavedRows?: any,
    isBarcode?: boolean
  ) => {
    if (!unsavedRows) {
      unsavedRows = [...productRows];
    }

    let row = { ...unsavedRows[rowIndex] };
    let tempDocument = {
      ...booksDocument,
      contact: contact,
      contactCode: contact?.code
    };

    let gstType = booksDocument.gstType;
    if (getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST) {
      gstType = updateGstType(tempDocument);
      tempDocument = {
        ...tempDocument,
        gstType: gstType,
        items: tempDocument.items?.map((item: any) => {
          return { ...item, gstType: gstType };
        })
      };
    }
    if (!Utility.isEmpty(row?.product)) {
      let item = {
        ...row,
        taxCode: row?.tax?.code,
        taxName: row?.tax?.name,
        taxSystem: getTenantTaxSystem(),
        gstType: gstType,
        unitPriceGstInclusive: row.unitPriceGstInclusive,
        exciseAmount: 0,
        cessRule: row.product.cessRule,
        isRcmApplied: rcmAppliedIndia(
          props.booksDocument.documentType,
          contact?.gstTreatment,
          row.product,
        )
      };

      item = {
        ...item,
        ...getDiscountRelatedKeys(
          row['discountInPercent'] ? row['discount'] + '%' : row['discount']
        )
      };

      ItemTaxCalculator.item = item;
      if (!ItemTaxCalculator.item.taxAmount) {
        ItemTaxCalculator.item.taxAmount = 0;
      }
      ItemTaxCalculator.tenantInfo = tenantInfo;
      ItemTaxCalculator.setInitialValues();
      const taxAmount = ItemTaxCalculator.calculateTaxAmount();
      ItemTaxCalculator.item.taxAmount = taxAmount;
      ItemTaxCalculator.updateCalculatedValues();
      // for landed cost calculation for india only
      setTotalAmountforLandedCost(rowIndex);
      if (
        getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
        (props.documentMode !== DOCUMENT_MODE.NEW ||
          props.booksDocument.isPartialInvoice ||
          !Utility.isEmpty(contact))
      ) {
        setGSTValueManual(rowIndex, row, item);
      }
      ItemTaxCalculator.item.amount = ItemTaxCalculator.item.totalAmount || 0;
      unsavedRows[rowIndex] = { ...ItemTaxCalculator.item };
      lastUpdatedIndex.current = null;
      setBooksDocument((prevBooksDocument: any) => {
        return {
          ...prevBooksDocument,
          ...tempDocument,
          items: unsavedRows,
          isDocumentTouched:
            lineItemsTouched || prevBooksDocument.isDocumentTouched
        };
      });
      if (lineItemsTouched) {
        setLineItemsTouched(false);
      }
    }
    // setProductRows(unsavedRows);
    if (isBarcode) {
      return { ...ItemTaxCalculator.item };
    } else {
      setProductRows([...unsavedRows]);
    }
  };

  const onTCSChange = (newTCS: any) => {
    let doc: Document = { ...booksDocument };
    doc.tcsRateId = newTCS?.id;
    doc.tcsPercentage = newTCS?.ratePercentage;
    doc.tcsAmount = getTCSAmount(doc);
    setBooksDocument((prevBooksDocument: any) => {
      return {
        ...prevBooksDocument,
        ...doc
      };
    });
  };

  const getTCSAmount = (doc: any) => {
    let total = doc.items
      ? doc.items.reduce(
          (total: number, docItem: any) => total + docItem.total,
          0
        )
      : 0;
    return (Number(total) * Number(doc.tcsPercentage)) / 100;
  };

  const setTaxInclusiveFlag = () => {
    if (props.documentMode !== DOCUMENT_MODE.VIEW) {
      const taxInclusiveFlag = booksDocument.unitPriceGstInclusive;
      let documentItems = [...(booksDocument.items as Array<DocumentItem>)];
      documentItems = documentItems.map((item, index) => {
        return { ...item, unitPriceGstInclusive: !taxInclusiveFlag };
      });
      setRoundOffDirty(false);
      documentItems.map((item, index) =>
        calculateTaxesAndAmount(index, documentItems)
      );
      setBooksDocument((prevState: Document) => {
        return {
          ...prevState,
          items: [...documentItems],
          unitPriceGstInclusive: !taxInclusiveFlag
        };
      });
    }
  };

  const setRoundOffAmountInDocumentCurrency = (val: number) => {
    setRoundOffDirty(true);
    setBooksDocument((prevState: Document) => {
      return {
        ...prevState,
        roundOffAmountInDocumentCurrency: val
      };
    });
  };

  const updateCurrencyAndExchangeRate = (
    tempDocument: any,
    currencyCode: string,
    exchangeRate: number,
    updateState = true
  ) => {
    const preciseCurrencyExchangeRate = roundingOffStr(
      1 / exchangeRate,
      CURRENCY_PRECISION
    );
    return updateCurrency({
      tempDocument,
      currencyCode: currencyCode,
      exchangeRate: 1 / parseFloat(preciseCurrencyExchangeRate),
      gstExchangeRate: tempDocument.gstExchangeRate,
      updateState
    });
  };

  const loadAccountGroups = async (currency: string) => {
    AccountsService.fetchAccountGroup(currency)
      .then((accountGroups: any[]) => {
        const filterAccounts =
          tenantInfo?.additionalSettings?.ACCOUNT?.showCardCashBankAccountsOnly;
        let filteredAccountGroups = [];
        if (filterAccounts) {
          filteredAccountGroups = accountGroups?.filter(
            (acc: any) =>
              ([COMMON_CONSTANT.CASH, COMMON_CONSTANT.BANK].includes(
                acc.accountGroup
              ) ||
                (acc.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                  acc.isCreditCard === true)) &&
              acc.status === STATUS_TYPE.ACTIVE
          );
        } else {
          filteredAccountGroups = accountGroups.filter(
            (el) =>
              el.accountGroup === COMMON_CONSTANT.BANK ||
              el.accountGroup === COMMON_CONSTANT.CASH ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_LIABILITY &&
                el.isCreditCard) ||
              (el.accountGroup === COMMON_CONSTANT.CURRENT_ASSETS &&
                el.isUndepositedFundAccount &&
                props.booksDocument.documentType === DOC_TYPE.INVOICE)
          );
        }

        let paymentMethodOptions: any[] = [];
        const paymentMethods = filteredAccountGroups.map(
          (filteredAccountGroup) => makePaymentMethod(filteredAccountGroup)
        );

        paymentMethods.forEach((method) => {
          let option: any = {
            label: method.name,
            accountGroupName: Utility.convertInTitleCase(method.accountGroup),
            value: method.code,
            isUndepositedFundAccount: method.isUndepositedFundAccount
              ? true
              : false
          };
          let found = false;
          paymentMethodOptions.forEach((paymentMethodOption) => {
            if (
              paymentMethodOption.label === option.label &&
              paymentMethodOption.accountGroup === option.accountGroup
            ) {
              found = true;
            }
          });
          if (!found) {
            paymentMethodOptions.push(option);
          }
        });

        const defaultAccountGroupOption =
          paymentMethodOptions.find(
            (el: any) => el.value === COMMON_CONSTANT.DEFAULT_DEPOSIT_TO
          ) || paymentMethodOptions[0];
        setPaymentAccountGroupsOptions(paymentMethodOptions);
        setBooksDocument((prevState: any) => ({
          ...prevState,
          accountGroupForPayment: defaultAccountGroupOption,
          paymentType: 'BANK_TRANSFER'
        }));
      })
      .catch((err: any) => {
        console.error('Error loading account groups: ', err);
      });
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType =
      DOC_TYPE_TO_ATTACHMENT_MAP[props.booksDocument.documentType];
    const entityId = booksDocument.id || booksDocument.entityId || '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId:
        props.booksDocument.documentType === DOC_TYPE.ORDER ||
        props.booksDocument.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER ||
        props.booksDocument.documentType === DOC_TYPE.QUOTE ||
        props.booksDocument.documentType === DOC_TYPE.SALES_ORDER
          ? entityId
          : ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        const attachmentForListing = [...attachments, res];
        const newlyAddedAttachments = [...newAttachments, res];
        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
      })
      .catch((err) => {
        showToast(
          'Something went wrong while uploading the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setBooksDocument((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const viewContactDetailsInVIewMode = () => {
    let contactCode = props?.booksDocument?.vendorCode;
    let contact = contactsData?.content?.filter((data: any) => {
      return contactCode === data.code;
    });

    return (
      <div>
        <DKLabel
          text={`${contact[0]?.name}-(${contact[0]?.currencyCode})`}
          className="fw-m fs-r"
        />
        <DKLabel
          text={`${contact[0]?.billingAddress[0]?.state}, ${contact[0]?.billingAddress[0]?.country}, ${contact[0]?.billingAddress[0]?.postalCode}`}
          className=""
        />
      </div>
    );
  };

  const isBillOrOrder = true;
  return (
    <div className=" m-l">
      <div>
        <div className="row justify-content-between align-items-stretch mt-l">
          <div
            className={
              'column justify-content-between position-relative width-80 pr-l '
            }
          >
            {/* {props.documentMode === DOCUMENT_MODE.EDIT ? (
              <div>
                <DKLabel
                  text={'Supplier12-(INR) '}
                  className="fw-b fs-r text-black"
                />
                <DKLabel text={'India'} className="fw-m fs-r" />
              </div>
            ) : (
              getCompanyDetails()
            )} */}

            {props.documentMode === DOCUMENT_MODE.NEW && getCompanyDetails()}

            {props.documentMode === DOCUMENT_MODE.NEW && getContactPicker()}

            {props.documentMode === DOCUMENT_MODE.VIEW &&
              viewContactDetailsInVIewMode()}

            {/* {getDeliveryAddressDetails()}  */}
            <div className="column justify-content-between position-relative width-80 pr-l">
              <div className="row width-auto align-items-start gap-2">
                <div>
                  <div
                    className={`column document-address-block`}
                    style={{
                      minWidth: 170,
                      maxWidth: 250,
                      marginLeft: -10,
                      paddingRight: 10,
                      paddingLeft: 10,
                      paddingBottom: 10
                    }}
                  >
                    <div
                      className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                      onClick={() => {
                        if (!props.RfqToSQuote) {
                          if (props.documentMode !== DOCUMENT_MODE.VIEW) {
                            setOpenBillingAddressList(true);
                          }
                        }
                      }}
                    >
                      <DKLabel
                        text={'Bill to'}
                        className="fw-b fs-r text-gray"
                      />
                      <DKLabel
                        text={
                          isBillOrOrder
                            ? tenantInfo.name
                            : `${contact.name}-(${contact.currencyCode})`
                        }
                        className="fw-m fs-r"
                      />
                      {(props.documentMode === DOCUMENT_MODE.NEW ||
                        props.documentMode === DOCUMENT_MODE.EDIT) && (
                        <DKLabel
                          className="cursor-pointer"
                          text={
                            !Utility.isEmpty(
                              billingAddress?.currentBillingAddress ||
                                (isBillOrOrder &&
                                !Utility.isDropship(props.booksDocument)
                                  ? booksDocument?.billTo
                                  : null)
                            )
                              ? getFormattedAddress(
                                  booksDocument.billTo ||
                                    billingAddress?.currentBillingAddress ||
                                    (isBillOrOrder &&
                                      !Utility.isDropship(props.booksDocument))
                                    ? booksDocument?.billTo
                                    : null
                                )
                              : ''
                          }
                        />
                      )}
                      {props.documentMode === DOCUMENT_MODE.VIEW && (
                        <DKLabel
                          className=""
                          text={
                            props?.booksDocument.billTo
                              ? getFormattedAddress(props?.booksDocument.billTo)
                              : ''
                          }
                        />
                      )}
                    </div>
                  </div>
                  {(!(props.draftType === DraftTypes.READONLY) &&
                    props.documentMode === DOCUMENT_MODE.EDIT) ||
                    (openBillingAddressList && (
                      <DKListPicker2
                        data={
                          isBillOrOrder
                            ? tenantInfo.billingAddresses
                            : billingAddress?.billingAddress
                        }
                        className="position-absolute z-index-3 bg-white border-m shadow-m"
                        style={{ minWidth: 250 }}
                        renderer={(index: number, addressObj: any) => (
                          <div
                            style={{
                              width: 200,
                              whiteSpace: 'pre-wrap',
                              textAlign: 'left'
                            }}
                            dangerouslySetInnerHTML={{
                              __html: getFormattedAddress(addressObj)
                            }}
                          ></div>
                        )}
                        onEdit={(index: number, obj: any) => {}}
                        button={
                          isBillOrOrder &&
                          Utility.isDropship(props.booksDocument)
                            ? null
                            : {
                                title: 'Manage address',
                                className:
                                  'text-white fw-m bg-button justify-content-center',
                                onClick: () => {
                                  if (isBillOrOrder) {
                                    setActiveOrgProfileTab(
                                      ORGANISATION_FORM_TAB.ADDRESS_INFO
                                    );
                                    setShowUpdateOrgPopup(true);
                                  } else {
                                    // //   setActiveContactTab(CONTACT_FORM_TAB.ADDRESS_INFO);
                                    // //   setContactMode(DOCUMENT_MODE.EDIT);
                                    // //   setShowAddContactPopup(true);
                                    // // }
                                    // // setOpenBillingAddressList(false);
                                    showAlert('Working on it');
                                  }
                                }
                              }
                        }
                        canEdit={false}
                        onSelect={(index: number, addressObj: any) => {
                          setBillingAddress({
                            billingAddress:
                              billingAddress?.billingAddress as BooksAddress[],
                            currentBillingAddress: addressObj
                          });
                          setBooksDocument((prevState: any) => ({
                            ...prevState,
                            billTo: addressObj
                          }));
                          setOpenBillingAddressList(false);
                        }}
                        onClose={() => {
                          setOpenBillingAddressList(false);
                        }}
                        allowSearch={false}
                      />
                    ))}
                </div>
                <div>
                  <div
                    className={`column document-address-block`}
                    style={{
                      minWidth: 170,
                      maxWidth: 250,
                      marginLeft: -10,
                      paddingRight: 10,
                      paddingLeft: 10,
                      paddingBottom: 10
                    }}
                  >
                    <div
                      className={`column width-auto m-s mb-xs justify-content-between align-items-start`}
                      onClick={() => {
                        if (!props.RfqToSQuote) {
                          if (props.documentMode !== DOCUMENT_MODE.VIEW) {
                            setOpenShippingAddressList(true);
                          }
                        }
                      }}
                    >
                      <DKLabel
                        text={'Ship to'}
                        className="fw-b fs-r text-gray"
                      />
                      <DKLabel
                        text={
                          isBillOrOrder
                            ? tenantInfo.name
                            : `${contact.name}-(${contact.currencyCode})`
                        }
                        className="fw-m fs-r"
                      />
                      {(props.documentMode === DOCUMENT_MODE.NEW ||
                        props.documentMode === DOCUMENT_MODE.EDIT) && (
                        <DKLabel
                          className="cursor-pointer"
                          text={
                            !Utility.isEmpty(
                              shippingAddress?.currentShippingAddress ||
                                (isBillOrOrder &&
                                !Utility.isDropship(props.booksDocument)
                                  ? booksDocument?.shipTo
                                  : null)
                            )
                              ? getFormattedAddress(
                                  booksDocument.shipTo ||
                                    shippingAddress?.currentShippingAddress ||
                                    (isBillOrOrder &&
                                      !Utility.isDropship(props.booksDocument))
                                    ? booksDocument?.shipTo
                                    : null
                                )
                              : ''
                          }
                        />
                      )}

                      {props.documentMode === DOCUMENT_MODE.VIEW && (
                        <DKLabel
                          className=""
                          text={
                            props?.booksDocument.shipTo
                              ? getFormattedAddress(props?.booksDocument.shipTo)
                              : ''
                          }
                        />
                      )}
                    </div>
                  </div>

                  {/* <div className={`row justify-content-between align-items-stretch mt-l`}> */}
                </div>
                {(!(props.draftType === DraftTypes.READONLY) &&
                  props.documentMode === DOCUMENT_MODE.EDIT) ||
                  (openShippingAddressList && (
                    <DKListPicker2
                      data={
                        isBillOrOrder
                          ? tenantInfo.shippingAddresses
                          : shippingAddress?.shippingAddress
                      }
                      className="position-absolute z-index-3 bg-white border-m shadow-m"
                      style={{ minWidth: 250 }}
                      renderer={(index: number, addressObj: any) => (
                        <div
                          style={{
                            width: 200,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'left'
                          }}
                          dangerouslySetInnerHTML={{
                            __html: getFormattedAddress(addressObj)
                          }}
                        ></div>
                      )}
                      onEdit={(index: number, obj: any) => {}}
                      button={{
                        title: 'Manage address',
                        className:
                          'text-white fw-m bg-button justify-content-center',
                        onClick: () => {
                          if (isBillOrOrder) {
                            setActiveOrgProfileTab(
                              ORGANISATION_FORM_TAB.ADDRESS_INFO
                            );
                            setShowUpdateOrgPopup(true);
                          }
                          setOpenShippingAddressList(false);
                        }
                      }}
                      canEdit={false}
                      onSelect={(index: number, addressObj: any) => {
                        setShippingAddress({
                          shippingAddress:
                            shippingAddress?.shippingAddress as BooksAddress[],
                          currentShippingAddress: addressObj
                        });
                        if (getTenantTaxSystem() === TAX_SYSTEM.US) {
                          setShipToOrFromAddressChangedForUS(true);
                        }
                        setBooksDocument((prevState: any) => ({
                          ...prevState,
                          shipTo: addressObj
                        }));

                        setOpenShippingAddressList(false);
                      }}
                      onClose={() => {
                        setOpenShippingAddressList(false);
                      }}
                      allowSearch={false}
                    />
                  ))}
              </div>
            </div>
            <div className="row justify-content-between position-relative width-80 pr-l mb-l">
              <div className="row width-auto align-items-start mr-l gap-3">
                {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                  !Utility.isEmpty(contact) &&
                  getSourceOfDestination()}
              </div>
              <div className="row width-auto align-items-start gap-3">
                {getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST &&
                  !Utility.isEmpty(contact) &&
                  getPlaceOfSupply()}
              </div>
            </div>
          </div>

          <div className="column align-items-end m-l">
            <div className="position-relative mb-m">
              {
                <div
                  className={`row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs`}
                  style={{
                    width: 240
                  }}
                >
                  <DKCheckMark
                    title="Create From Requisition"
                    isSelected={checkState}
                    color="bg-blue"
                    onClick={() => {
                      if (
                        !(
                          props.RfqToSQuote ||
                          props.documentMode === DOCUMENT_MODE.VIEW
                        )
                      ) {
                        setCheckState(!checkState);
                      }
                    }}
                    className={'fw-m ml-r'}
                  />
                </div>
              }
            </div>
            {checkState && (
              <div className="position-relative mb-m">
                <div
                  className={`row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs`}
                  style={{
                    width: 240
                  }}
                >
                  <div
                    className="row justify-content-start gap-2 position-relative"
                    style={{ height: 30 }}
                  >
                    <div>
                      <DKLabel
                        text="Requistion Number"
                        className={'fw-m ml-r'}
                      />
                    </div>

                    <div
                      onClick={() => {
                        if (
                          !(
                            props.RfqToSQuote ||
                            props.documentMode === DOCUMENT_MODE.VIEW
                          )
                        ) {
                          setShowSRPicker(!showSRPicker);
                        }
                      }}
                      className="cursor-hand"
                    >
                      <DKLabel
                        text={
                          props.RfqToSQuote
                            ? props?.booksDocument?.linkedDocuments?.[0]
                                ?.documentSequenceCode
                            : sr ?? 'Select'
                        }
                        className={`bg-gray1 border-radius-s p-v-xs p-h-s ${
                          canValidate ? 'border-red text-red bg-chip-red' : ''
                        }`}
                        style={{
                          width: GENERALFIELD_VALUE_WIDTH
                        }}
                      />
                      {!props.RfqToSQuote && showSRPicker && getSRPickerView()}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="column align-items-end m-l">
            <div>
              <div className="position-relative">
                <div
                  className={`row width-auto mb-xs justify-content-between ${
                    props.draftType === DraftTypes.READONLY
                      ? 'listPickerBG'
                      : 'listPickerBG'
                  }`}
                  style={{
                    width: 240
                  }}
                >
                  <div
                    className="row width-auto cursor-pointer"
                    onClick={() => setopenCustomNumberList((value) => !value)}
                  >
                    <DKIcon
                      src={DKIcons.data_type.ic_number}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />

                    <DKLabel text={'No.'} className={'fw-m ml-r'} />
                  </div>
                  {/* {(props.documentMode === DOCUMENT_MODE.EDIT ||
                    props.documentMode === DOCUMENT_MODE.VIEW) && (
                    //props.booksDocument.documentSequenceCode &&
                    <DKLabel
                      text={`SPQ-${props.booksDocument.supplierQuotesCode}`} //props.booksDocument.supplierQuotesCode
                      className={'ml-r '}
                    />
                  )} */}
                  {props.documentMode === DOCUMENT_MODE.VIEW && (
                    <DKLabel
                      text={`SPQ-${props.booksDocument.supplierQuotesCode}`} //props.booksDocument.supplierQuotesCode
                      className={'ml-r '}
                    />
                  )}

                  {(props.documentMode === DOCUMENT_MODE.NEW ||
                    props.documentMode === DOCUMENT_MODE.COPY) && (
                    <div className="w-9/12 -mr-1">
                      <CustomNumberFormatInput
                        module={CUSTOM_NUMBER_INPUT_MODULES.SUPPLIER_QUOTE}
                        selectedFormat={selectedFormat}
                        showCompact={true}
                        extraClass={'top-12 right-0'}
                        openList={openCustomNumberList}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="position-relative mb-m">
                <div
                  className={`row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs`}
                  style={{
                    width: 240
                  }}
                  onClick={() => {
                    if (props.documentMode !== DOCUMENT_MODE.VIEW) {
                      setSupplierQuoteDateOpen(!supplierQuoteDateOpen);
                    }
                  }}
                >
                  <div className="row width-auto ">
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />
                    <DKLabel
                      text="Supplier Quote Date"
                      className={'fw-m ml-r'}
                    />
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(documentDate)}
                    className="ml-r "
                  />
                </div>
                {(!(props.draftType === DraftTypes.READONLY) &&
                  props.documentMode === DOCUMENT_MODE.EDIT) ||
                  (supplierQuoteDateOpen &&
                    getCalendarView(
                      documentDate,
                      (newDate: any) => {
                        validateAndUpdateDate(
                          newDate,
                          DateFormatService.getDateFromStr(
                            tenantInfo.bookBeginningStartDate,
                            BOOKS_DATE_FORMAT['YYYY-MM-DD']
                          ),
                          setDocumentDate,
                          `Supplier Quote Date cannot be before books beginning date.`,
                          true
                        );
                      },
                      setSupplierQuoteDateOpen
                    ))}
              </div>

              <div className="position-relative mb-m">
                <div
                  className={`row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs`}
                  style={{
                    width: 240
                  }}
                  onClick={() => {
                    if (props.documentMode !== DOCUMENT_MODE.VIEW) {
                      setvalidDateOpen(!validDateOpen);
                    }
                  }}
                >
                  <div className="row width-auto  ">
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />
                    <DKLabel text="Valid Date" className={'fw-m ml-r'} />
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(validDate)}
                    className="ml-r "
                  />
                </div>
                {(!(props.draftType === DraftTypes.READONLY) &&
                  props.documentMode === DOCUMENT_MODE.EDIT) ||
                  (validDateOpen &&
                    getCalendarView(
                      validDate,
                      (newDate: any) =>
                        validateAndUpdateDate(
                          newDate,
                          documentDate,
                          setvalidDate,
                          `${t(
                            `DOCUMENT.DUE_DATE`
                          )} cannot be before Supplier Quote date.`,
                          false
                        ),
                      setvalidDateOpen
                    ))}
              </div>

              <div className="position-relative mb-s">
                <div
                  className={`row width-auto mb-xs justify-content-between cursor-pointer listPickerBG p-v-xs`}
                  style={{
                    width: 240
                  }}
                  onClick={() => {
                    if (props.documentMode !== DOCUMENT_MODE.VIEW) {
                      setreceiveByDateOpen(!receiveByDateOpen);
                    }
                  }}
                >
                  <div className="row width-auto ">
                    <DKIcon
                      src={DKIcons.data_type.ic_date}
                      className="ic-xs-2"
                      style={{ opacity: 0.6 }}
                    />
                    <DKLabel text="Receive By" className={'fw-m ml-r'} />
                  </div>
                  <DKLabel
                    text={DateFormatService.getDateStrFromDate(receiveByDate)}
                    className="ml-r "
                  />
                </div>
                {(!(props.draftType === DraftTypes.READONLY) &&
                  props.documentMode === DOCUMENT_MODE.EDIT) ||
                  (receiveByDateOpen &&
                    getCalendarView(
                      receiveByDate,
                      (newDate: any) =>
                        validateAndUpdateDate(
                          newDate,
                          documentDate,
                          setreceiveByDate,
                          `${receiveByDate} date cannot be before Supplier Quote date.`,
                          false
                        ),
                      setreceiveByDateOpen
                    ))}
              </div>
            </div>
          </div>
        </div>
        <>
          <DKDataGrid
            className={'m-r'}
            allowBulkOperation={false}
            needColumnIcons={false}
            allowColumnEdit={
              props.documentMode !== DOCUMENT_MODE.VIEW ? true : false
            }
            allowColumnSort={false}
            allowRowEdit={
              props.documentMode !== DOCUMENT_MODE.VIEW ? true : false
            }
            rows={productRows}
            columns={getDataGridColumns()}
            onRowUpdate={onRowUpdate}
            onRowClick={onRowClick}
            allowRowDelete={
              props.documentMode !== DOCUMENT_MODE.VIEW ? true : false
            }
            allowColumnDelete={
              props.documentMode !== DOCUMENT_MODE.VIEW ? true : false
            }
            // onRowSelect={onRowClick}
          />
          {!(props.draftType === DraftTypes.READONLY) &&
            !checkState &&
            props.RfqToSQuote !== true && (
              <div className="row justify-content-between">
                <div className="row">
                  <DKButton
                    title={`+ Add Item`}
                    onClick={() => addNewItem()}
                    className={`${
                      props.draftType === DraftTypes.READONLY
                        ? 'text-gray'
                        : 'text-blue'
                    } fw-m p-0`}
                    style={{ marginTop: -10, zIndex: 1, paddingLeft: 0 }}
                  />
                  {productRows.length > 0 && !props.RfqToSQuote && (
                    <DKButton
                      title={`- Clear all items`}
                      onClick={() => clearAllItems()}
                      className={`${
                        props.draftType === DraftTypes.READONLY
                          ? 'text-gray'
                          : 'text-blue'
                      } fw-m p-0`}
                      style={{ marginTop: -10, zIndex: 1 }}
                    />
                  )}
                </div>
                <div className="row"></div>
              </div>
            )}
        </>
      </div>
      <div className="flex flex-row items-start justify-content-between box-border w-full mt-m">
        <div
          className={`column ${
            props.draftType === DraftTypes.READONLY &&
            props.booksDocument.documentType === DOC_TYPE.INVOICE
              ? 'pointer-events-auto'
              : 'mt-xl'
          }`}
        >
          <textarea
            className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
            disabled={props.draftType === DraftTypes.READONLY}
            style={{
              width: 500,
              height: 130,
              backgroundColor: 'rgb(250, 250, 250)',
              border: '1px dashed rgb(200, 200, 200)'
            }}
            placeholder={t(`DOCUMENT.MEMO_OPTIONAL`)}
            value={booksDocument.memo}
            onChange={(e: any) => {
              setBooksDocument((prevState: any) => {
                return {
                  ...prevState,
                  memo: e.target.value,
                  isDocumentTouched:
                    e.target.value?.trim() !== '' &&
                    booksDocument.memo?.trim() !== '' &&
                    e.target.value?.trim() !== booksDocument.memo?.trim()
                };
              });
            }}
          ></textarea>
          {/* {
            <Fragment>
              <DKButton
                title={
                  <>
                    + Attach files
                    <span className="text-gray fw-r ml-s">(Max 5MB)</span>
                  </>
                }
                className={`${
                  props.draftType === DraftTypes.READONLY
                    ? 'text-gray'
                    : 'text-blue'
                } mt-r fw-m`}
                style={{ paddingLeft: 0 }}
                disabled={props.draftType === DraftTypes.READONLY}
                onClick={triggerAttachmentUpload}
              />
              <div className="row">{getAttachments()}</div>
            </Fragment>
          } */}
          {/* <div className="row mt-r">
            {!props.draftData.isLoading &&
              (props.documentMode === DOCUMENT_MODE.EDIT ||
                props.documentMode === DOCUMENT_MODE.VIEW) && (
                <DocumentActionMenu
                  booksDocument={{ ...props.booksDocument, ...booksDocument }}
                  draftId={props.draftData.id}
                  position="bottom"
                  draftType={props.documentMode}
                  updateWithoutClosing={() => {
                    // if (props.updateDocWithoutClosing) {
                    //   props.updateDocWithoutClosing();
                    // }
                  }}
                />
              )}
          </div> */}
          {/* <div className="mt-">{showInvoiceNowDetails()}</div> */}
        </div>
        <div
          className={props.documentMode === DOCUMENT_MODE.VIEW ? 'mt-r' : ''}
          style={{ width: 330, marginTop: 16, marginRight: 16 }}
        >
          <DocumentSummaryView
            booksDocument={booksDocument}
            documentMode={
              props.documentMode ? props.documentMode : DOCUMENT_MODE.VIEW
            }
            hidden={false}
            roundOffDirty={roundOffDirty}
            onTotalOrAdditionalChargeChange={(additionalInfo) => {
              let paidAmount =
                booksDocument.knockoffInfo?.reduce(
                  (total: number, paymentInfo: any) =>
                    total +
                    Number(paymentInfo?.amount || 0) *
                      Number(paymentInfo?.exchangeRate || 1),
                  0
                ) || 0;
              if (tenantInfo.country === COUNTRY_CODES.IN) {
                const tdsAmount =
                  booksDocument.tdsInfo?.reduce(
                    (total: number, tdsData: any) =>
                      total + Number(tdsData?.tdsAmount || 0),
                    0
                  ) || 0;
                paidAmount = paidAmount + tdsAmount;
              }
              setDueAmount(
                Utility.roundOff(
                  additionalInfo.total - paidAmount,
                  Store.getState().authInfo.currentTenantInfo.data.decimalScale
                )
              );
              additionalCharges.current = additionalInfo.additionalCharges;

              let tempDoc = { ...booksDocument };
              if (Utility.isUSorg()) {
                tempDoc = {
                  ...tempDoc,
                  items: tempDoc?.items?.length
                    ? tempDoc?.items?.map((item: any) => {
                        let itemCopy = deepClone(item);
                        ItemTaxCalculator.item = itemCopy;
                        ItemTaxCalculator.setInitialValues();
                        itemCopy = { ...ItemTaxCalculator.item };
                        return itemCopy;
                      })
                    : []
                };

                if (
                  typeof additionalInfo.additionalCharges
                    ?.additionalChargesDetails !== 'undefined' &&
                  additionalInfo.additionalCharges?.additionalChargesDetails !==
                    null
                ) {
                  let remainingCharges =
                    additionalInfo.additionalCharges?.additionalChargesDetails?.map(
                      (item: any) => item.additionalCharge
                    );

                  tempDoc.items = tempDoc?.items?.map((item: any) => {
                    let itemCopy = { ...item };
                    let additionalChargesDetails =
                      itemCopy?.additionalCharges?.additionalChargesDetails ??
                      [];
                    additionalChargesDetails = additionalChargesDetails.filter(
                      (charge: any) =>
                        remainingCharges.includes(charge.additionalCharge)
                    );
                    return {
                      ...itemCopy,
                      additionalCharges: {
                        additionalChargesDetails
                      }
                    };
                  });

                  additionalInfo.additionalCharges?.additionalChargesDetails?.forEach(
                    (charge: any) => {
                      tempDoc = {
                        ...tempDoc
                      };
                    }
                  );
                }
              }

              tempDoc.items && setProductRows([...tempDoc.items]);

              setBooksDocument({
                ...tempDoc,
                totalAmount: additionalInfo.total,
                amountToReceiveOrPay: additionalInfo.total - paidAmount,
                additionalCharges: additionalInfo.additionalCharges
              });
            }}
            onTCSChange={onTCSChange}
            onTaxInclusiveFlagChange={setTaxInclusiveFlag}
            onRoundingOffChange={setRoundOffAmountInDocumentCurrency}
            onCurrencyAndExchangeRateChange={(
              currency: any,
              exchangeRate: number
            ) => {
              if (props.draftData?.data?.isCashInvoice) {
                loadAccountGroups(currency);
              }

              updateCurrencyAndExchangeRate(
                { ...booksDocument },
                currency,
                exchangeRate
              );
            }}
          />
          {showTaxExchangeRatePopup && getTaxExchangeRatePopup()}
          {showProductPopup && getProductForm()}
        </div>
      </div>
    </div>
  );
}
