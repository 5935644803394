import {
  DKButton,
  DKIcon,
  DKIcons,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_TYPE,
  MODULES_NAME,
  PAGE_SIZE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TEMPLATE_CATEGORY,
  TRACKING_TYPE
} from '../../Constants/Constant';

import AppManager from '../../Managers/AppManager';
import { FulfillmentRecordProps } from '../../Models/Fulfillment';
import { BtnType, PopupClickActionType } from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectUOMs } from '../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../Services/DateFormat';
import NewReportService, { NewReportConfig } from '../../Services/DebitReport';
import FulfillmentService from '../../Services/FulfillmentService';
import Utility from '../../Utility/Utility';
import PopupWrapper from '../PopupWrapper';
import PrintPreview from '../Printing/PrintPreview';
import FulfillmentTable from './FulFillmentTable';

import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import NumberFormatService from '../../Services/NumberFormat';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import FulfillmentRecordDetailWithTable from './FulfillmentRecordDetailWithTable';
import { enableMultiViewJournalEntryChildren } from '../MultiViewJournalEntry/enableMultiViewJounalEntry';
import MultiViewJournalEntry from '../MultiViewJournalEntry/MultiViewJournalEntry';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import FulfillmentMasterService from '../../Services/FulfillmentMasterService';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';

const FulfillmentRecords: React.FC<FulfillmentRecordProps> = (props) => {
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>(
    props.fulfillmentDetails
  );
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    fulfillmentDetails[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);
  const [fulfillmentCode, setFulfillmentCode] = useState<any>(
    fulfillmentDetails[0]?.fulfillment_code
  );
  const [documentUOMSchemaDefinition, setDocumentUOMSchemaDefinition] =
    useState<any>(fulfillmentDetails[0]?.documentUOMSchemaDefinition);
  const [res, setRes] = useState<any>({});
  const { t, i18n } = useTranslation();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isVisibleState, setIsVisibleState] = useState(false);
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [isSerail, setIsSerial] = useState<any>(false);
  let module: any = MODULES_NAME.INVOICE;
  if (props.documentType === 'SALES_ORDER') {
    module = MODULES_NAME.SALESORDER;
  } else if (props.documentType === 'QUOTATION') {
    module = MODULES_NAME.QUOTATION;
  }
  const popupBtnConfig: BtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const uoms = useAppSelector(selectUOMs);
  const dispatch = useAppDispatch();
  const [itemDetails, setItemDetails] = useState<any>();
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [attachments, setAttachments] = useState<any[]>([]);
  const [showMultiViewJournalEntry, setShowMultiViewJournalEntry] =
    useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    let itemsArray =
      documentDetails.salesInvoiceItems ||
      documentDetails.quotationItemDtoList ||
      documentDetails.salesOrderItems;
    setItemDescriptionArray(itemsArray);
    if (tenantInfo.country === COUNTRY_CODES.IN) setIsVisibleState(true);
  }, [itemsDescriptionArray]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const getFulfillmentQuantity = (fulfillmentItems = []) => {
    return fulfillmentItems.reduce(
      (a, b) =>
      (a += b['documentUOMSchemaDefinition']
        ? b['uomFulfilledQuantity']
        : b['fulfilledQuantity']),
      0
    );
  };

  const getTotalQuantity = (index:any) => {
    let totalFullfillment = 0;
    const linkedDraft:any = fulfillmentDetails.find((l:any)=>l.linkedTolerancePendingApprovalFF);
    if(linkedDraft) {
      
    const items = fulfillmentDetails[index]?.fulfillmentItems;
    items?.forEach((item: any) => {
      totalFullfillment +=
        (item.documentUOMSchemaDefinition
          ? item['uomFulfilledQuantity']
          : item['fulfilledQuantity']) +
        (item.documentUOMSchemaDefinition
          ? item['uomPendingQuantity']
          : item['pendingQuantity']);
    });
    return totalFullfillment;
    } else {
      itemsDescriptionArray?.forEach((item: any) => {
        totalFullfillment += item.documentUOMSchemaDefinition
          ? item['uomQuantity']
          : item['productQuantity'];
      });
      return totalFullfillment;
    }
  };

  const getFulfillmentDetails = (fulfillmentCode: any) => {
    let fulfillmentData = fulfillmentDetails?.find(
      (item: any) => item.fulfillment_code === fulfillmentCode
    );
    setFulfillmentCode(fulfillmentData.fulfillment_code);
    setDocumentUOMSchemaDefinition(fulfillmentData.documentUOMSchemaDefinition);
    setFulfillmentData(fulfillmentData);
  };

  const printFulfillment = (fulfillmentData: any) => {
    setShowPrintPreview(true);
  };

  const deleteFulfillment = (fulfillmentData: any, deleteAll = false) => {
    let payload = {
      fulfillmentCode: fulfillmentData.parentFulfillmentCode
        ? fulfillmentData.parentFulfillmentCode
        : fulfillmentData.fulfillment_code,
      code: fulfillmentData.documentCode,
      isBulk: deleteAll
    };
    if (fulfillmentData.documentType === DOC_TYPE.INVOICE) {
      deleteInvoiceFulfillment(payload);
    } else if (fulfillmentData.documentType === DOC_TYPE.SALES_ORDER) {
      deleteSalesOrderFulfillment(payload);
    } else {
      deleteQuoteFulfillment(payload);
    }
  };

  const deleteInvoiceFulfillment = (payload: any) => {
    showLoader();
    FulfillmentService.deleteInvoiceFulfillment(payload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const deleteSalesOrderFulfillment = (payload: any) => {
    showLoader();
    const newPayload = {
      ...payload,
      documentSequenceCode: documentDetails.documentSequenceCode
    };
    FulfillmentService.deleteSalesOrderFulfillment(newPayload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        if (error && error.code == 400 && error.errorMessage && error.errorMessage.includes("pending for approval against this order")) {
          if (Utility.isToleranceSettingsEnabled()) {
            const buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => { }
              }
            ]
            if (checkUserPermission(
              PERMISSIONS_BY_MODULE.INVOICE.FULFILL
            ) ||
              checkUserPermission(
                PERMISSIONS_BY_MODULE.QUOTATION.FULFILL
              ) ||
              checkUserPermission(
                PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL
              )) {
              buttons.push(
                {
                  title: 'Go to Approval screen',
                  className: 'bg-blue text-white ml-r',
                  onClick: () => {
                    FulfillmentMasterService.setSelectedIndex(1)
                    RouteManager.navigateToPage(PAGE_ROUTES.FULFILLMENT_MASTER_LIST);
                    // props.onTabSelect(1);
                  }
                })
            } else {
              buttons.push(
                {
                  title: 'Ok',
                  className: 'bg-blue text-white ml-r',
                  onClick: () => { }
                })
            }
            showAlert("Error!", error.errorMessage, buttons);
          }
        }
        removeLoader();
      });
  };

  const deleteQuoteFulfillment = (payload: any) => {
    const newPayload = {
      ...payload,
      documentSequenceCode: documentDetails.documentSequenceCode
    };
    showLoader();
    FulfillmentService.deleteQuoteFulfillment(newPayload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const updateFulfillmentRecords = (payload: any) => {
    let fulfillmentDetailsData = [...fulfillmentDetails];
    let fulfillmentData = fulfillmentDetailsData.find((item: any) => {
      const itemCode = item.parentFulfillmentCode
        ? item.parentFulfillmentCode
        : item.fulfillment_code;
      return itemCode === payload.fulfillmentCode;
    });

    const indexRecord = fulfillmentDetailsData.indexOf(fulfillmentData);
    fulfillmentData.fulfillmentItems.forEach((element: any) => {
      if (
        element.advancedTrackingType === TRACKING_TYPE.BATCH ||
        element.advancedTrackingType === TRACKING_TYPE.SERIAL
      ) {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      }
    });
    if (indexRecord > -1) {
      fulfillmentDetailsData.splice(indexRecord, 1);
      if (fulfillmentDetailsData && fulfillmentDetailsData.length > 0) {
        setFulfillmentDetails((prevState: any[]) => fulfillmentDetailsData);
        setFulfillmentCode(fulfillmentDetailsData[0]?.fulfillment_code);
        getFulfillmentDetails(fulfillmentDetailsData[0]?.fulfillment_code);
      } else {
        closePopup();
      }
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  const onPaginationClick = (pageNo: number = 0) => {
    const config: NewReportConfig = {
      ...NewReportService.apiConfig,
      Page: pageNo - 1,
      Limit: PAGE_SIZE
    };
    NewReportService.apiConfig = config;
    NewReportService.getFullfillment(itemDetails.fulfillment_item_code).then(
      (res: any) => {
        setRes(res);
      }
    );
  };

  let allowToDeleteFulfillmentRec = true;
  if (DOC_TYPE.SALES_ORDER === props.documentType) {
    allowToDeleteFulfillmentRec = checkUserPermission(
      PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL
    );
  }

  return (
    <div className="flex justify-content-between px-1">
      {showPrintPreview && (
        <PrintPreview
          documentType={TEMPLATE_CATEGORY.FULFILLMENT}
          document={{ ...documentDetails, documentCode: fulfillmentCode }}
          closePreview={(val: boolean) => setShowPrintPreview(val)}
        />
      )}

      {showMultiViewJournalEntry && (
        <MultiViewJournalEntry
          onCancel={() => setShowMultiViewJournalEntry(false)}
          documentCode={documentDetails?.salesInvoiceCode}
          documentType={DOC_TYPE.FULFILLMENT}
          data={null}
        />
      )}
      <div
        className="flex"
        style={{
          width: '30%',
          // height: '650px',
          display: props.isFromMaster ? "none" : "inherit",
          borderRight: '1px solid rgba(0, 0, 0, 0.12)'
        }}
      >
        <ul className="flex flex-col w-full">
          {fulfillmentDetails &&
            fulfillmentDetails?.map((item: any, i: any) => {
              return (
                <li
                  className={
                    'flex cursor-hand p-2 flex-col hover:bg-blue-100 rounded mb-2 mr-3' +
                    (fulfillmentCode === item.fulfillment_code
                      ? ' bg-blue-100 '
                      : '')
                  }
                  onClick={() => {
                    setSelectedTabIndex(i);
                    setFulfillmentData(null);
                    setTimeout(() => {
                      getFulfillmentDetails(item.fulfillment_code);
                    }, 0);
                  }}
                >
                  <div className="text-black fs-r ml-0 flex row-responsive justify-content-between">
                    <span className="pr-2  font-medium">
                      {item.fulfillment_code}
                    </span>
                    <div className="flex">
                      {!isTabletView() && (
                        <span className="align-items-end pt-1 mr-2">
                          <DKIcon
                            src={DKIcons.ic_printer}
                            className="ic-s cursor-hand"
                            title="Print"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              printFulfillment(item);
                            }}
                          />
                        </span>
                      )}
                      {allowToDeleteFulfillmentRec && (
                        <span className="align-items-end pt-1">
                          <DKIcon
                            src={DKIcons.ic_delete}
                            className="ic-s cursor-hand"
                            title="Delete"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              let buttons: any[] = [];
                              if (item.isBulkFulfillment) {
                                buttons = [
                                  {
                                    title: 'No',
                                    className: 'bg-gray2 border-m ',
                                    onClick: () => { }
                                  },
                                  {
                                    title: 'Delete',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteFulfillment(item, false);
                                    }
                                  },
                                  {
                                    title: 'Delete All',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteFulfillment(item, true);
                                    }
                                  }
                                ];

                                showAlert(
                                  'Delete Fulfillment Record',
                                  'This record contains multiple fulfillment transactions, Do you want to delete all transactions or only for this document?',
                                  buttons
                                );
                              } else {
                                buttons = [
                                  {
                                    title: 'No',
                                    className: 'bg-gray2 border-m ',
                                    onClick: () => { }
                                  },
                                  {
                                    title: 'Delete',
                                    className: 'bg-red text-white ml-r',
                                    onClick: () => {
                                      deleteFulfillment(item, false);
                                    }
                                  }
                                ];

                                showAlert(
                                  'Delete Fulfillment Record',
                                  t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`),
                                  buttons
                                );
                              }
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex row-responsive justify-content-between mt-1">
                    <span className="text-gray mr-1">
                      {DateFormatService.getFormattedDateString(
                        item.fulfillmentDate,
                        BOOKS_DATE_FORMAT['DD-MM-YYYY']
                      )}
                    </span>
                    <span className="align-items-end text-gray">
                      {NumberFormatService.getNumber(
                        getFulfillmentQuantity(item.fulfillmentItems)
                      )}
                      / {NumberFormatService.getNumber(getTotalQuantity(i))} 
                      Item(s)
                    </span>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
      <div
        className="flex flex-col align-self-start p-4"
        style={{
          width: props.isFromMaster ? '100%' : '80%'
        }}
      >
        {enableMultiViewJournalEntryChildren({
          children: (
            <div className="flex items-center justify-end">
              <div className="pl-3 flex justify-end">
                <DKButton
                  title={'Show GL Impact'}
                  className="mr-r bg-gray-100"
                  onClick={() => {
                    setShowMultiViewJournalEntry(true);
                  }}
                />
              </div>
            </div>
          )
        })}
        <FulfillmentRecordDetailWithTable
          documentDetails={props.documentDetails}
          fulfillmentDetails={fulfillmentDetails?.filter(
            (fulfillment: any) =>
              fulfillment.fulfillment_code === fulfillmentCode
          )}
          documentType={props.documentType}
          isDeleted={(val: boolean) => { }}
          selectedTabIndex={selectedTabIndex}
          isToleranceApprovalFlow={props.isToleranceApprovalFlow}
        />
      </div>
      {showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={
            isSerail
              ? 'Serial-Fulfillment Records'
              : 'Batch-Fulfillment Records'
          }
          btnList={popupBtnConfig}
          width={`${isSerail ? '22%' : '60%'}`}
          height={!Utility.isEmptyObject(res) ? 'auto' : 350}
          disableClickOutside={true}
        >
          <FulfillmentTable
            populateFormData={res}
            isSerial={isSerail}
            module={'SELL'}
            itemData={itemDetails}
            width={isSerail ? 300 : '100%'}
            totalPageCount={res.totalPages || 0}
            currentPage={res?.pageable?.pageNumber + 1 || 1}
            onPaginationClick={(pageNo: number) => onPaginationClick(pageNo)}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default FulfillmentRecords;
