import { DKButton, DKIcons, DKLabel } from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ApiConstants from '../../../Constants/ApiConstants';
import { PAGE_SIZE } from '../../../Constants/Constant';
import {
  REMOTE_CONFIG_TABLES,
  TABLES
} from '../../../Constants/TableConstants';
import useConfirm from '../../../Hooks/useConfirm';
import { IState } from '../../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchJobCardsList } from '../../../Redux/Slices/MRP/JobCardSlice';
import { selectOperations } from '../../../Redux/Slices/MRP/OperationSlice';
import { fetchWorkOrderList } from '../../../Redux/Slices/MRP/WorkOrderSlice';
import { selectWorkstations } from '../../../Redux/Slices/MRP/WorkstationSlice';
import DateFormatService from '../../../Services/DateFormat';
import JobCardService, {
  JobCardAPIConfig
} from '../../../Services/MRP/JobCard';
import DataGridHolder from '../../../SharedComponents/DataGridHolder';
import Utility from '../../../Utility/Utility';
import { JOB_CARD_COLS, JOB_CARD_STATUS } from '../Constants/MRPColumnConfigs';
import { JOB_CARD_GRID_KEYS } from '../Constants/TableConstant';
import AddJobCard from '../JobCard/AddJobCard/AddJobCard';

const initialState: IState = {
  columnData: JOB_CARD_COLS,
  rowData: [],
  originalData: [],
  filter: []
};

const JCFormGrid = (props: any) => {
  const isReadOnlyMode =
    props?.selectedWorkOrderRow?.status?.[0] === JOB_CARD_STATUS.COMPLETED ||
    props?.selectedWorkOrderRow?.status?.[0] === JOB_CARD_STATUS.CANCELLED;
  //states
  const [jobCardData, setJobCardData] = useState<any>(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [updating, setUpdating] = useState(false);
  const [editableJobCard, setEditableJobCard] = useState<any>(undefined);
  const [showAddJobCardPopup, setShowAddJobCardPopup] = useState(false);

  //selectors
  const { confirm } = useConfirm();
  const dispatch = useAppDispatch();
  const [jobCardsData, setJobCardsData] = useState<any>();
  const workStationData = useAppSelector(selectWorkstations);
  const operationData = useAppSelector(selectOperations);
  const [config, setConfig] = useState<JobCardAPIConfig>({
    SearchTerm: '',
    Limit: PAGE_SIZE,
    Page: 0,
    sort: 'ASC',
    sortBy: '',
    Query: `workOrderCode=${props?.selectedWorkOrderRow?.workOrderCode}`
  });

  //effects
  useEffect(() => {
    return () => {
      setJobCardData(initialState);
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(jobCardsData)) {
      const { content, pageable, totalPages } = jobCardsData;
      const rowData = Utility.isEmpty(jobCardsData)
        ? []
        : getRowDataForCreate(content);
      const passingData = {
        columnData: JOB_CARD_COLS,
        rowData,
        filter: [],
        originalData: rowData
      };

      setJobCardData(passingData);
      setTotalPageCount(totalPages);
      setCurrentPage(+pageable?.pageNumber + 1);
    }
  }, [jobCardsData]);

  const getConfigUrl = () => {
    let urlString = `?limit=${config.Limit}&page=${config.Page}&sort=${config.sortBy}&query=${config.Query}&sortDir=${config.sort}`;
    return urlString;
  };

  useEffect(() => {
    callFetchJC();
  }, [config]);

  const callFetchJC = () => {
    setUpdating(true);
    let finalURL =
      ApiConstants.URL.MRP.JOB_CARD.GET_ALL_JOB_CARD + getConfigUrl();
    JobCardService.fetchAllJobCardsForWorkOrder(finalURL)
      .then((response: any) => {
        setJobCardsData(response);
        setUpdating(false);
      })
      .catch((err: any) => {
        console.log('Error occured while fetching Job Cards');
        setUpdating(false);
      });
  };

  const rowDelete = (index: any) => {
    JobCardService.deleteJobCard(index)
      .then(
        (res) => {
          dispatch(fetchJobCardsList());
        },
        (err) => {
          console.log('Error while deleting', err);
        }
      )
      .catch((error) => {
        console.log('Error while deleting', error);
      });
  };

  const getContextMenuForRow = (jobCard: any) => {
    let menuItems: any[] = [];

    if (jobCard?.status !== JOB_CARD_STATUS.COMPLETED) {
      menuItems.push({
        title: 'Edit',
        icon: DKIcons.ic_edit,
        onClick: (data: any) => {
          onRowEdit(data);
        }
      });
    }

    // menuItems = [
    // {
    //   title: 'Delete',
    //   icon: DKIcons.ic_delete,
    //   onClick: async (data: any) => {
    //     const rowData = [data?.rowData];
    //     const index = jobCardsData?.content[data.rowIndex]?.id;
    //     if (!Utility.isEmpty(rowData)) {
    //       const isConfirmed = await confirm(
    //         'Are you sure you want to delete this job card'
    //       );
    //       if (isConfirmed) {
    //         rowDelete([index]);
    //       }
    //     }
    //   }
    // }
    // ];

    return menuItems;
  };

  const onRowEdit = (data: any) => {
    const list: any[] = jobCardsData['content'];
    const id: number = data?.rowData?.id;
    const updatedJobCardData = list.filter((item) => item.id === id)[0];
    if (updatedJobCardData) {
      setEditableJobCard(updatedJobCardData);
      setShowAddJobCardPopup(true);
    }
  };

  const getRowDataForCreate = (tableList: any[]) => {
    let rowData: any[] = [];

    const getWorkstationColumn = (codeId: any) => {
      const found = workStationData?.content?.find((item: any) => {
        return item.id === codeId;
      });

      return !Utility.isEmpty(found) ? found?.workstationName : '';
    };

    const getOperationColumn = (codeId: any) => {
      const found = operationData?.content?.find((item: any) => {
        return item.id === codeId;
      });

      return !Utility.isEmpty(found) ? found?.name : '';
    };

    tableList.forEach((jobCard, index) => {
      const row = {
        [JOB_CARD_GRID_KEYS.ID]: jobCard?.jobCardCode,
        [JOB_CARD_GRID_KEYS.STATUS]: [jobCard?.status],
        [JOB_CARD_GRID_KEYS.WORK_ORDER_CODE]: jobCard?.workOrderSequenceCode,
        [JOB_CARD_GRID_KEYS.SALES_ORDER_CODE]: jobCard?.salesOrderSequenceCode,
        [JOB_CARD_GRID_KEYS.WORK_ORDER_ITEM_NAME]: jobCard?.workOrderItemName,
        [JOB_CARD_GRID_KEYS.WORKSTATION_ID]: getWorkstationColumn(
          jobCard?.workstationId
        ),
        [JOB_CARD_GRID_KEYS.OPERATION_ID]: getOperationColumn(
          jobCard?.operationId
        ),
        [JOB_CARD_GRID_KEYS.TOTAL_COMPLETED_QUANTITY]:
          jobCard?.totalCompletedQuantity,
        [JOB_CARD_GRID_KEYS.JOB_CARD_DATE]:
          DateFormatService.getDateStrFromDate(new Date(jobCard?.jobCardDate)),
        [JOB_CARD_GRID_KEYS.JC_ID]: jobCard?.id,
        rowContextMenu: getContextMenuForRow(jobCard),
        rowButtons:
          jobCard?.status !== JOB_CARD_STATUS.COMPLETED &&
          jobCard?.status !== JOB_CARD_STATUS.CANCELLED
            ? [
                {
                  title: 'Edit',
                  className:
                    'p-v-0 text-blue underline grid-action-link-h-padding',
                  onClick: (data: any) => {
                    onRowEdit(data);
                  }
                }
              ]
            : []
      };
      rowData.push(row);
    });
    return rowData;
  };

  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={`Job card/s for ${
              props?.selectedWorkOrderRow?.documentSequenceCode ?? '-'
            }`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
      </div>
    );
  };
  const getWidth = () => {
    var elem = document.getElementById('mainDiv');
    if (typeof elem !== 'undefined' && elem !== null) {
      return elem.offsetWidth - 100;
    }
    return 1350;
  };

  const onRowUpdate = (data: any) => {
    if (Utility.isEmpty(jobCardsData?.content)) {
      return;
    }
    if (data.columnKey === 'status') {
      let updateJobCard = jobCardsData?.content?.find(
        (item: any) => item.id === data.rowData.id
      );
      let updatedStatus = Utility.isEmpty(data?.rowData?.status)
        ? 'OPEN'
        : data.rowData.status[0];
      setUpdating(true);
      JobCardService.updateJobCard(
        { ...updateJobCard, status: updatedStatus },
        updateJobCard.id
      )
        .then((res: any) => {
          setUpdating(false);
          dispatch(fetchWorkOrderList());
          callFetchJC();
          props.onSuccess();
        })
        .catch((err: any) => {
          setUpdating(false);
        });
    }
  };

  const jcGridView = () => {
    return (
      <div className="column parent-size p-h-xl mt-2">
        <DataGridHolder
          updating={updating}
          tableName={TABLES.JOB_CARDS}
          displayTableName={'Job Cards'}
          filterTableName={REMOTE_CONFIG_TABLES.JOB_CARDS}
          allowSearch={false}
          allowRowEdit={!isReadOnlyMode}
          onRowOpenClick={(data: any) => {
            onRowEdit(data);
          }}
          onRowUpdate={(data: any) => {
            onRowUpdate(data);
          }}
          allowColumnEdit={false}
          allowRowAdd={false}
          allowColumnAdd={false}
          allowFilter={false}
          onFilter={() => {}}
          refresh={true}
          headerButtons={[]}
          allowDataExport={false}
          needTrailingColumn={true}
          gridData={jobCardData}
          onPaginationClick={(requestedPageNumber: number) => {
            const configCopy = {
              ...config,
              Page: requestedPageNumber - 1 // Books backend pagination offset is 0, so subtract 1
            };
            setConfig(configCopy);
          }}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          contextMenu={[]}
          allowColumnSort={false}
          allowBulkOperation={false}
          width={getWidth()}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background" style={{ zIndex: 9997 }}>
      <div
        className="popup-window"
        id={'mainDiv'}
        style={{
          minHeight: 400,
          maxHeight: '90%',
          maxWidth: 1400,
          width: '90%',
          padding: 0,
          overflowY: 'hidden'
        }}
      >
        {getHeader()}
        <div className="column parent-size hide-scroll-bar">{jcGridView()}</div>
        <DKLabel
          text="Please note: You can view the job card from the Work Order master screen too."
          className="ml-m fw-b pb-2 bottom-0 left-0 position-absolute"
        />
      </div>
      {showAddJobCardPopup && (
        <AddJobCard
          editableJobCard={editableJobCard}
          onCancel={() => {
            setShowAddJobCardPopup(false);
            setEditableJobCard({});
          }}
          onSuccess={(data: any) => {
            setShowAddJobCardPopup(false);
            callFetchJC();
            if (data?.markWorkOrderComplete) {
              props?.onWorkOrderComplete?.();
            } else {
              dispatch(fetchWorkOrderList());
            }
          }}
        />
      )}
    </div>
  );
};

export default JCFormGrid;
