import {
  DKButton,
  DKLabel,
  DKSpinner,
  removeLoader,
  showLoader
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COUNTRY_CODES,
  MOBILE_DOWNLOAD_BANNER_CLOSED,
  MOBILE_DOWNLOAD_POPUP_CLOSED,
  STANDLEY_EMAIL
} from '../../Constants/Constant';
import AppManager from '../../Managers/AppManager';
import RouteManager from '../../Managers/RouteManager';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo, allTenantsInfo } from '../../Redux/Slices/AuthSlice';
import IamService from '../../Services/iam';
import { localizedText } from '../../Services/Localization/Localization';
import TenantService from '../../Services/Tenant';
import Utility, { getCapitalized } from '../../Utility/Utility';
import AgedReceivable from './AgedReceivable';
import BankAccounts from './BankAccounts';
import BillsSummary from './BillsSummary';
import Cashflow from './Cashflow';
import MobileDownloadBanner from './DownloadMobile/MobileDownloadBanner';
import MobileDownloadPopup from './DownloadMobile/MobileDownloadPopup';
import InvoiceSummary from './InvoiceSummary';
import ProfitAndLoss from './ProfitAndLoss';
import UpcomingInvoices from './UpcomingInvoices';
import AuthService from '../../Services/Auth';

export default function Dashboard(props: any) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    RouteManager.setPresenter({ props });
  }, []);

  const initialFilterState = {
    module: 'DASHBOARD',
    cashFlowFilter: 'LAST30',
    salesInvoiceFilter: 'LAST30',
    profitLossFilter: 'CURRENT_YEAR'
  };

  const allTenantInfo = useAppSelector(allTenantsInfo);
  const tenant = useAppSelector(activeTenantInfo);
  const [filter, setFilter] = useState<any>(initialFilterState);

  const [needMobilePopup, setNeedMobilePopup] = useState(
    Utility.getPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED) !== 'true'
  );
  const [needMobileBanner, setNeedMobileBanner] = useState(
    Utility.getPersistentValue(MOBILE_DOWNLOAD_BANNER_CLOSED) !== 'true' &&
      Utility.getPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED) === 'true'
  );

  const createDemoOrg = () => {
    showLoader(`Creating demo ${localizedText('organisation')}...`);

    let country = tenant.country;
    if (country === 'US') {
      country = 'US';
    } else {
      country = 'SG';
    }

    TenantService.createDemoOrg(country).then(
      (selectedTenant: any) => {
        /*
         * Switch tenant to demo app before reloading
         *
         * */
        const tenantId = selectedTenant.tenantId;
        IamService.switchTenant({ tenantId: tenantId }).then(
          (res) => {
            removeLoader();
            setTimeout(() => {
              AppManager.refreshApp();
            }, 1000);
          },
          (err) => {
            removeLoader();
            console.log('Error occured while switching tenant', err);
          }
        );
      },
      (error) => {
        removeLoader();
        console.log('Error occured while creating demo org', error);
      }
    );
  };

  const updateFilterData = (value: string, type: string) => {
    let newState = filter;
    switch (type) {
      case 'CASHFLOW':
        newState.cashFlowFilter = value;
        break;
      case 'SALES_INVOICE':
        newState.salesInvoiceFilter = value;
        break;
      case 'PNL':
        newState.profitLossFilter = value;
        break;
    }
    setFilter({ ...newState });
  };

  ////////////////// banner - start /////////////////////////

  const getMobileView = () => {
    return (
      <MobileDownloadBanner
        onClose={() => {
          setNeedMobileBanner(false);
          Utility.setPersistentValue(MOBILE_DOWNLOAD_BANNER_CLOSED, true);
        }}
      />
    );
  };

  const getMobilePopup = () => {
    return (
      <MobileDownloadPopup
        onClose={() => {
          setNeedMobilePopup(false);
          setNeedMobileBanner(true);
          Utility.setPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED, true);
        }}
      />
    );
  };

  ///////////////// banner - end ////////////////////////////

  const _checkComplianceForDemoOrg = () => {
    switch (tenant.country) {
      // Allowed compliance for Demo Org
      case COUNTRY_CODES.IN:
      case COUNTRY_CODES.SG:
      case COUNTRY_CODES.US:
        return true;
      // Not Allowed compliance for Demo Org
      case COUNTRY_CODES.MY:
        return false;
      default:
        break;
    }
  };

  return (
    <div className="parent-width bg-gray1 main-holder-padding padding-bottom-50">
      {/* {needMobileBanner && getMobileView()}
      {needMobilePopup && getMobilePopup()} */}
      <div className="row justify-content-end mb-3">
        <div className="row justify-content-between">
          <DKLabel text={t('DASHBOARD.TITLE')} className="fw-m fs-m" />
          {tenant && !tenant.isDemoOrg && _checkComplianceForDemoOrg() && (
            <DKButton
              title={`Try Demo ${getCapitalized(
                localizedText('organisation')
              )}`}
              className="border bg-white"
              onClick={() => {
                let found = false;
                let tenantfound: any = {};
                allTenantInfo.forEach((tenantDetails: any) => {
                  if (
                    tenantDetails.isDemoOrg &&
                    (tenantDetails.countryLookUpCode === COUNTRY_CODES.US ||
                      tenantDetails.countryLookUpCode === COUNTRY_CODES.SG)
                  ) {
                    found = true;
                    tenantfound = tenantDetails;
                  }
                });
                if (!found) {
                  createDemoOrg();
                } else {
                  IamService.switchTenant({
                    tenantId: tenantfound.tenantId
                  }).then(
                    (res) => {
                      AppManager.refreshApp();
                    },
                    (err) => {}
                  );
                }
                //createDemoOrg();
              }}
            />
          )}
        </div>
      </div>
      {tenant && (
        <>
          <div className="flex mt-r row-responsive">
            {AuthService.getUserEmail()?.toLowerCase() !==
              STANDLEY_EMAIL.toLowerCase() && (
              <Cashflow
                updateFilter={(value: any) => {
                  updateFilterData(value, 'CASHFLOW');
                }}
              />
            )}
            {AuthService.getUserEmail()?.toLowerCase() !==
              STANDLEY_EMAIL.toLowerCase() && <BankAccounts />}
            <UpcomingInvoices filter={filter} />
          </div>
          <div className="flex mt-l row-responsive">
            <InvoiceSummary
              updateFilter={(value: any) => {
                updateFilterData(value, 'SALES_INVOICE');
              }}
            />
            <BillsSummary />
          </div>
          <div className="flex mt-l mb-xxl row-responsive">
            {AuthService.getUserEmail()?.toLowerCase() !==
              STANDLEY_EMAIL.toLowerCase() && (
              <>
                <ProfitAndLoss
                  updateFilter={(value: any) => {
                    updateFilterData(value, 'PNL');
                  }}
                />
                <AgedReceivable />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export const getDashboardWidgetLoading = () => {
  return (
    <div className="parent-width parent-height row justify-content-center">
      <DKSpinner title="Loading..." />
    </div>
  );
};
