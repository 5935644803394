import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate } from "../../Utilities/Utility"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import DataParser from "../DataParser"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import { getLocalisedText } from "../../Translate/LanguageManager"

export default class RequestForQuotationParser {
    static parseData(dataFromAPI, templateDataToReturn) {

        templateDataToReturn.customFields = dataFromAPI?.rfq?.customField ?? ''
        CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        // /company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.tenantInfo?.name ?? '')
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        dataFromAPI.tenantInfo = {
            ...dataFromAPI.tenantInfo,
            state: fromAddressObj?.state,
        }
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo

        //ref number
        templateDataToReturn.refNumber = dataFromAPI?.rfq?.documentSequenceCode ?? ''

         //start date
        const rfqDate = dataFromAPI?.rfq?.rfqDate ?? undefined
        if(rfqDate) {
            templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(rfqDate), templateDataToReturn.dateFormat)
        }

        const validByDate = dataFromAPI?.rfq?.validByDate ?? undefined
        if(validByDate) {
            templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(validByDate), templateDataToReturn.dateFormat)
        }

        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        const contactList = dataFromAPI?.rfq?.contact ?? []
        if(contactList.length > 0) {
            templateDataToReturn.clientBillToAddress =  contactList.map(x => x.name).join('\n')
        }

        const emailList = dataFromAPI?.rfq?.email ?? []
        if (emailList.length > 0) {
            templateDataToReturn.clientShipToAddress = emailList.join('\n')
        }

        templateDataToReturn.lineItems = this.getLineItem(dataFromAPI)


        let shipToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.shipToDetails.title
        let billToTitle = TemplateSettingsManager.defaultTemplateSettings.documentInfo.clientAddresses.billToDetails.title
        if (shipToTitle === 'ship_to' && billToTitle === 'bill_to') {
            templateDataToReturn.billToTitle = getLocalisedText('contact')
            TemplateSettingsManager.updateBillToTitle(getLocalisedText('contact'))
            templateDataToReturn.shipToTitle = getLocalisedText('email')
            TemplateSettingsManager.updateShipToTitle(getLocalisedText('email'))
        }

        return templateDataToReturn

    }

    static getLineItem(dataFromAPI) {
        let lineItems = []

        const rawItems = dataFromAPI?.rfq?.requestForQuotationItems ?? []

        if(rawItems.length > 0) {
            rawItems.forEach((element, index) => {
                let item = {}
                item.lineNumber = index + 1
                item.code = element.product?.productId ?? ''
                item.name = element.product?.name ?? ''
                item.description = element.product?.description ?? ''
                item.uom = element.uomDto?.name ?? 'N/A'
                item.quantity = element.productQuantity ?? 0
                item.customFields = element.customField

                lineItems.push(item)
            });
        }

        return lineItems
    }
}
