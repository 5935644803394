import { DKLabel, DKButton, DKSpinner } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  fetchInvoices,
  selectInvoices
} from '../../Redux/Slices/InvoicesSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { Link } from 'react-router-dom';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import InvoiceService, { InvoiceAPIConfig } from '../../Services/Invoice';
import DateFormatService from '../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  ERROR,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STANDLEY_EMAIL
} from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import NumberFormatService from '../../Services/NumberFormat';
import { getUpdatedInvoiceObject } from '../Invoices/InvoiceHelper';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import {
  BtnType,
  CallBackPayloadType,
  LoadingBtnType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import Payment from '../../SharedComponents/PaymentPopup/Payment';
import { selectIsLoadingPopupWrapper } from '../../Redux/Slices/CommonDataSlice';
import { GranularPermissionsHelper } from '../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import DKDropdown from './DKDropdown';
import AuthService from '../../Services/Auth';
import { selectApplySalesPersonFilter } from '../../Redux/Slices/BookFilterSlice';

function UpcomingInvoices(props: any) {
  const { t, i18n } = useTranslation();
  const invoiceData = useAppSelector(selectInvoices);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [invoiceList, setInvoiceList] = useState([]);
  const [noData, setNoData] = useState(false);
  const [noDataLoading, setNoDataLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [editableInvoice, setEditableInvoice] = useState<any>();
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [noDataFound, setNoDatFound] = useState(false);
  const popupLoading = useAppSelector(selectIsLoadingPopupWrapper);
  const [selectedFilterIndex, setSelectedFilterIndex]: any = useState(0);
  const canApplySalesPersonFilterOnInvoice = useAppSelector(
    selectApplySalesPersonFilter
  );
  const filters = [
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_30_DAYS'),
      value: 'LAST30'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_MONTH'),
      value: 'CURRENT_MONTH'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.CURRENT_YEAR'),
      value: 'CURRENT_YEAR'
    },
    {
      name: t('DASHBOARD.CHART.BUY_SELL_FILTER_OPTIONS.LAST_YEAR'),
      value: 'LAST_YEAR'
    }
  ];

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let query = `sort=salesInvoiceDate&sortDir=DESC&customfield=[]`;

      if (
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.INVOICE.VIEW
        )
      ) {
        try {
          const config: InvoiceAPIConfig = {
            ...InvoiceService.apiConfig,
            Page: 0,
            Limit: 50,
            Query: encodeURI(query),
            QueryParam: '',
            Duration: filters[selectedFilterIndex].value,
            SearchTerm: ''
          };
          if (
            canApplySalesPersonFilterOnInvoice.canApplyFilter &&
            canApplySalesPersonFilterOnInvoice?.loggedInUserInfo?.[0]?.id
          ) {
            config.SalesPerson =
              canApplySalesPersonFilterOnInvoice?.loggedInUserInfo?.[0]?.id;
          }
          InvoiceService.apiConfig = config;
          dispatch(fetchInvoices());
        } catch (err) {
          console.error('Error loading invoices: ', err);
        }
      }
    }
  }, [tenantInfo, selectedFilterIndex]);

  useEffect(() => {
    if (invoiceData === ERROR) {
      setNoDataLoading(false);
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    if (
      !GranularPermissionsHelper.hasPermissionFor(
        PERMISSIONS_BY_MODULE.INVOICE.VIEW
      )
    ) {
      setNoDataLoading(false);
      setNoData(false);
      setNoDatFound(true);
      return;
    }

    let invoices =
      invoiceData &&
      invoiceData.content &&
      invoiceData.content.map((item: any) => {
        return {
          invoiceCurrency: item.currency,
          dueAmount: item.dueAmount,
          salesInvoiceCode: item.salesInvoiceCode,
          balance: item.totalAmount,
          name: item.contact && item.contact.name,
          dueDate: item.salesInvoiceDueDate
        };
      });
    if (invoices && invoices.length > 0) {
      setInvoiceList(invoices);
      setNoDataLoading(false);
      setNoData(false);
    } else if (invoices && invoices.length === 0) {
      setNoData(true);
      setNoDataLoading(false);
    } else {
      setNoDataLoading(true);
    }
  }, [invoiceData]);

  const loadInvoiceDetailsForEdit = (salesInvoiceCode: any) => {
    InvoiceService.getInvoiceByCode(salesInvoiceCode).then(
      (data: any) => {
        let invoiceDetailsData: any = getUpdatedInvoiceObject(data);
        setEditableInvoice(invoiceDetailsData);
        setShowPaymentPopup(true);
      },
      (err) => {
        console.error('Error while fetching invoice details: ', err);
      }
    );
  };

  const paymentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.RECEIVE`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.RECEIVE_PAYMENT
    }
  ];

  const loadingPaymentPopupBtnConfig: LoadingBtnType[] = [
    {
      title: t(`INVOICES.DIALOG.RECEIVE_PAYMENT.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      type: 'CLOSE'
    },
    {
      title: 'Receiving',
      class: 'bg-app text-white mr-ss',
      type: 'ACTION'
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPaymentPopup(false);
        break;
      case POPUP_CLICK_TYPE.RECEIVE_PAYMENT:
        addInvoiceRef.current?.storeCallbacksRef.receivePayment();
        break;
    }
  };
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addInvoiceRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowPaymentPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.RECEIVE_PAYMENT:
        addInvoiceRef.current.storeCallbacksRef.receivePayment =
          passingData.data;
        break;
    }
  };

  const getAllInvoices = () => {
    return invoiceList.map((bank: any) => {
      return (
        <>
          {bank?.dueAmount > 0 && (
            <div className="row mt-m justify-content-between">
              <div>
                <DKLabel
                  text={`<b>${
                    bank?.name
                  }</b>, ${Utility.getCurrencySymbolFromCode(
                    bank?.invoiceCurrency
                  )}${NumberFormatService.getNumber(
                    parseFloat(bank?.dueAmount.toString())
                  )}`}
                  className={
                    parseFloat(bank?.dueAmount.toString()) < 0
                      ? 'text-red-300'
                      : ''
                  }
                />
                <DKLabel
                  text={DateFormatService.getFormattedDateString(
                    bank?.dueDate,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )}
                  className="text-gray mt-xs"
                />
              </div>
              {bank?.dueAmount >= 1 && (
                <DKButton
                  title={'Get paid'}
                  className="text-blue bg-chip-blue p-v-xs p-h-s border-radius-r"
                  onClick={() => {
                    loadInvoiceDetailsForEdit(bank?.salesInvoiceCode);
                  }}
                />
              )}
              {bank?.dueAmount <= 0 && (
                <DKButton
                  disabled={true}
                  title={'Paid'}
                  className="text-green bg-chip-green p-v-xs p-h-s border-radius-r"
                  onClick={() => {}}
                />
              )}
            </div>
          )}
        </>
      );
    });
  };

  return (
    <div
      className={`column border-radius-m shadow-s border-ms p-l bg-white border-box justify-content-between overflow-hidden db-grid-width db-mb  ${
        AuthService.getUserEmail()?.toLowerCase() ===
        STANDLEY_EMAIL.toLowerCase()
          ? 'db-grid-width-50'
          : ''
      }`}
    >
      <div className="row justify-content-between mb-s" style={{ height: 25 }}>
        <div style={{ display: 'flex' }}>
          <DKLabel text="Upcoming Invoices" className="fw-m fs-m" />
          <DKLabel
            text={'Overdue'}
            className="text-red fw-m bg-deskera-secondary p-h-s border-radius-s p-v-xs ml-r mr-l"
            style={{ position: 'relative', top: '-4px' }}
          />
        </div>
        <DKDropdown
          title={filters[selectedFilterIndex].name}
          data={filters.map((item: any) => {
            return item.name;
          })}
          onSelect={(data: any) => {
            setSelectedFilterIndex(data);
          }}
        />
      </div>
      <div
        className="column position-relative parent-width overflow-y-scroll hide-scroll-bar"
        style={{ height: 210 }}
      >
        {noDataLoading && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">
              <DKSpinner iconClassName="ic-s" />
            </div>
          </div>
        )}
        {noData && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <DKLabel
              className="bg-white rounded px-2 py-2 shadow"
              text="You dont have any overdue invoices."
            />
          </div>
        )}
        {noDataFound && (
          <div
            className="column position-absolute justify-content-center align-items-center parent-size"
            style={{
              left: 0,
              top: 0,
              zIndex: 1,
              backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
          >
            <div className="bg-white rounded px-2 py-2 shadow">No Data</div>
          </div>
        )}
        {!noData && getAllInvoices()}
      </div>
      <div className="row mt-m" style={{ height: 40 }}>
        {!noDataFound && (
          <Link to={PAGE_ROUTES.INVOICES}>
            <DKButton
              title="View all"
              className="bg-gray1 border-m mr-m"
              onClick={() => {}}
            />
          </Link>
        )}
        {/* <DKButton title="+ New Invoice" className="bg-button text-white" /> */}
      </div>
      {showPaymentPopup && (
        <PopupWrapper
          isLoading={popupLoading}
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={t(`INVOICES.DIALOG.RECEIVE_PAYMENT.TITLE`)}
          btnList={paymentPopupBtnConfig}
          loadingBtnList={loadingPaymentPopupBtnConfig}
          maxWidth={'800px'}
          disableClickOutside={true}
          maxHeight={'90%'}
        >
          <Payment
            extraData={props.filter}
            documentDetails={editableInvoice}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
    </div>
  );
}

export default UpcomingInvoices;
