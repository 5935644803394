import { DKButton, DKIcons, showAlert } from 'deskera-ui-library';
import SettingsLeftPanel from './SettingsLeftPanel';
import SettingsRightPanel from './SettingsRightPanel';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';
import Utility, { getCapitalized } from '../../Utility/Utility';
import { RolePermissionUtil } from '../../RolePermission/RolePermissionUtil';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  featurePermissions,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import { localizedText } from '../../Services/Localization/Localization';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE
} from '../../Constants/Permission';
import {
  isRevRecVisible,
  isSalesOrderVisible
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { useCallback, useEffect, useState } from 'react';
import { COUNTRY_CODES, NEW_SETTINGS_SECTION } from '../../Constants/Constant';
import barcode_icon from '../../Assets/Icons/barcode.png';
import ic_expand from '../../Assets/Icons/ic_expand.svg';
import ic_shrink from '../../Assets/Icons/ic_shrink.svg';
import './Settings.scss';
import {
  selectSelectedSetting,
  selectShowSettingsInFullScreen,
  setShowSettingsInFullScreen
} from '../../Redux/Slices/CommonDataSlice';
import { PLANS } from '../../Constants/Enum';
import {
  checkUserPermission,
  hasAnySettingsPermission,
  showNoPermissionAlert
} from './GranularPermissions/GranularPermissionsHelper';
import RouteManager from '../../Managers/RouteManager';
import ic_whatsapp from '../../Assets/automation/actions/ic_whatsapp.png';

interface SettingsProps {
  onClose: () => void;
}
export interface SettingsOption {
  title: string;
  description: string;
  propertyName: NEW_SETTINGS_SECTION;
  hidden: boolean;
  showHeader: boolean;
  children?: SettingsOption[];
  icon?: any | undefined;
  url?: string | undefined;
  keywords?: string[];
}

const SettingsNew = ({ onClose }: SettingsProps) => {
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const featurePermissionsInfo = useAppSelector(featurePermissions);
  const showSettingsInFullscreen = useAppSelector(
    selectShowSettingsInFullScreen
  );

  const [selectedSetting, setSelectedSetting] = useState<any>(null);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const selectedSettingFromStore = useAppSelector(selectSelectedSetting);

  const getSettingsOptions = useCallback(() => {
    const settingOptions: SettingsOption[] = [
      {
        title: `${getCapitalized(localizedText('organisation'))} Settings`,
        propertyName: NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS,
        description: `Update or modify your ${getCapitalized(
          localizedText('organisation')
        )} details`,
        icon: DKIcons.ic_home,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION
        ),
        showHeader: true,
        keywords: [
          'tax residency',
          'date format',
          'business type',
          'email',
          'phone',
          'fax',
          'website',
          'Finance Settings',
          'financial start date',
          'Book Beginning Date',
          'Reporting Currency',
          'Decimal Places',
          'Number Format',
          'Auto Round Off',
          'Compliance Settings',
          'EIN',
          'Tax Collection States',
          'Address Settings',
          'Billing Address',
          'Shipping Address',
          'Categories',
          'Track classes',
          'Assign Classes',
          'Track locations'
        ]
      },
      {
        title: 'Advanced Settings',
        propertyName: NEW_SETTINGS_SECTION.ADVANCED_SETTINGS,
        description: 'Update or modify your advanced settings here',
        icon: DKIcons.ic_volunteer,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.ADVANCED),
        showHeader: true,
        keywords: [
          'Module Visibility',
          'Multiple warehouses',
          'Price List',
          'Pick Pack Ship service',
          'Sales Orders',
          'Expense Allocation',
          'Advanced Inventory Setting',
          'Negative Inventory Setting',
          'Filter Accounts Setting',
          'Bill Edit Setting',
          'Invoice Edit Setting',
          'Row Rack Bin Settings',
          'Treatment of Additional Charges',
          'Individual charges',
          'Apportion on Line Item Level',
          'Work Order Shortfall Setting',
          'Valuation Method Setting'
        ]
      },
      {
        title: 'Multi Company Settings',
        propertyName: NEW_SETTINGS_SECTION.MULTI_COMPANY_SETTINGS,
        description: 'Setup multi company details.',
        icon: DKIcons.ic_company,
        hidden:
          !RolePermissionUtil.checkIsAdmin() ||
          !featurePermissionsInfo?.Supported?.includes(
            FEATURE_PERMISSIONS.MULTI_COMPANY_ADV
          ),
        showHeader: true
      },
      {
        title: 'User Permissions',
        propertyName: NEW_SETTINGS_SECTION.USER_PERMISSIONS,
        description:
          'Invite new users to your org and manage user permissions.',
        icon: DKIcons.ic_user,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.USER_PERMISSION
        ),
        showHeader: true
      },
      {
        title: 'Label Template',
        propertyName: NEW_SETTINGS_SECTION.LABEL_TEMPLATE,
        description: 'View and edit label templates.',
        icon: DKIcons.ic_barcode,
        hidden: !tenantInfo?.additionalSettings?.LABEL_PRINT,
        showHeader: true
      },
      {
        title: 'QC Flow',
        description: 'QC Flow modules',
        propertyName: NEW_SETTINGS_SECTION.QC_FLOW_MODULES,
        icon: DKIcons.ic_tax,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.QC_FLOW),
        showHeader: true,
        keywords: [
          'QC Flow Module Visibility',
          'Goods Receipt',
          'Fulfillment',
          'Stock Adjustment'
        ]
      },
      {
        title: 'QC Template',
        description:
          'Create or assign QC Templates & quality check parameters to products.',
        propertyName: NEW_SETTINGS_SECTION.QC_TEMPLATE,
        icon: DKIcons.ic_tax,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.QC_TEMPLATE
        ),
        showHeader: false
      },
      {
        title: 'Currency',
        propertyName: NEW_SETTINGS_SECTION.CURRENCY,
        description:
          'Manage currencies to be used (if multi-curency is enabled)',
        icon: DKIcons.ic_currency,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.CURRENCY),
        showHeader: false
      },
      {
        title: 'Tax',
        propertyName: NEW_SETTINGS_SECTION.TAX,
        description: 'Set Sales and Purchase Tax Codes',
        icon: DKIcons.ic_tax,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TAX),
        showHeader: false
      },
      {
        title: 'TDS Setup',
        propertyName: NEW_SETTINGS_SECTION.TDS_SETUP,
        description: 'Deductee Type, TDS Rates, Nature of Income Payment',
        icon: DKIcons.ic_tax,
        hidden:
          !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TDS_SETUP) ||
          tenantInfo.country !== COUNTRY_CODES.IN,
        showHeader: true
      },
      {
        title: 'TCS Setup',
        propertyName: NEW_SETTINGS_SECTION.TCS_SETUP,
        description: 'TCS Rates',
        icon: DKIcons.ic_tax,
        hidden:
          !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.TCS_SETUP) ||
          tenantInfo.country !== COUNTRY_CODES.IN,
        showHeader: false
      },
      {
        title: 'Revenue Recognition',
        propertyName: NEW_SETTINGS_SECTION.REVENUE_RECOGNITION,
        description:
          'Revenue Recognition Rules, Fair Value Price List, Fair Value Formula, Revenue Categories',
        icon: DKIcons.ic_tax,
        hidden: !isRevRecVisible(),
        showHeader: false,
        children: [
          {
            title: 'Item Revenue Categories',
            propertyName: NEW_SETTINGS_SECTION.ITEM_REVENUE_CATEGORIES,
            description: '',
            icon: DKIcons.ic_tax,
            hidden: !isRevRecVisible(),
            showHeader: false
          },
          {
            title: 'Fair Value Formulas',
            propertyName: NEW_SETTINGS_SECTION.FAIR_VALUE_FORMULAS,
            description: '',
            icon: DKIcons.ic_tax,
            hidden: !isRevRecVisible(),
            showHeader: false
          },
          {
            title: 'Fair Value Price List',
            propertyName: NEW_SETTINGS_SECTION.FAIR_VALUE_PRICE_LIST,
            description: '',
            icon: DKIcons.ic_tax,
            hidden: !isRevRecVisible(),
            showHeader: false
          },
          {
            title: 'Revenue Recognition Rules',
            propertyName: NEW_SETTINGS_SECTION.REVENUE_REC_RULES,
            description: '',
            icon: DKIcons.ic_tax,
            hidden: !isRevRecVisible(),
            showHeader: false
          }
        ]
      },
      {
        title: 'Payment Terms',
        propertyName: NEW_SETTINGS_SECTION.PAYMENT_TERMS,
        description: 'Predefine payment terms for invoices',
        icon: DKIcons.ic_cards,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.PAYMENT_TERMS
        ),
        showHeader: false
      },
      {
        title: 'Unit of Measurement',
        propertyName: NEW_SETTINGS_SECTION.UNIT_OF_MEASUREMENT,
        description: 'Set up your unit of measurement used in stock',
        icon: DKIcons.ic_resource,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.UOM),
        showHeader: false,
        keywords: ['UOM', 'Unit']
      },
      {
        title: 'Print Template',
        propertyName: NEW_SETTINGS_SECTION.PRINT_TEMPLATE,
        description: 'View and edit saved template',
        icon: DKIcons.ic_document,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.PRINT_TEMPLATE
        ),
        showHeader: true
      },
      {
        title: 'Connect Email',
        propertyName: NEW_SETTINGS_SECTION.EMAIL,
        description: 'Connect your email account to send emails directly',
        icon: DKIcons.ic_email,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.EMAIL),
        showHeader: true,
        keywords: [
          'Setup Email Account',
          'Sender Name',
          'Sender Email',
          'SMTP Server Information',
          'Server Host Name',
          'Port Number',
          'Server Security',
          'Allow SSL',
          'How to setup SMTP'
        ]
      },
      {
        title: 'Connect WhatsApp API',
        propertyName: NEW_SETTINGS_SECTION.WHATSAPP_API,
        description:
          'Connect your WhatsApp API account to send WhatsApp notifications',
        icon: ic_whatsapp,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE),
        showHeader: true,
        keywords: ['Setup WhatsApp API Account']
      },
      {
        title: 'Word Document Template',
        propertyName: NEW_SETTINGS_SECTION.WORD_DOCUMENT_TEMPLATE,
        description: 'Integrate  Word Document Template',
        icon: DKIcons.ic_document,
        hidden: false,
        showHeader: true,
        keywords: ['Setup Word Document Template']
      },
      // {
      //   title: 'Import Log',
      //   propertyName: NEW_SETTINGS_SECTION.IMPORT_LOG,
      //   description: 'View imported file to Books',
      //   icon: DKIcons.ic_import,
      //   url: PAGE_ROUTES.SETTINGS_IMPORT_LOG,
      //   hidden: !RolePermissionUtil.disableOperation(ModulePermissionEnum.CORE)
      // },
      {
        title: 'Revenue Recognition JE Log',
        propertyName: NEW_SETTINGS_SECTION.REV_REC_JE_LOG,
        description: 'View created JE from revenue recognition',
        icon: DKIcons.ic_import,
        hidden: !isRevRecVisible(),
        showHeader: true
      },
      // {
      //   title: 'Audit Log',
      //   propertyName: NEW_SETTINGS_SECTION.AUDIT_LOG,
      //   description: 'Verify activities performed by users in the system',
      //   icon: DKIcons.ic_reports,
      //   url: PAGE_ROUTES.SETTINGS_AUDIT_LOG,
      //   hidden: !RolePermissionUtil.disableOperation(ModulePermissionEnum.CORE)
      // },
      // {
      //   title: 'Automation Logs',
      //   propertyName: NEW_SETTINGS_SECTION.AUTOMATION_LOGS,
      //   description: 'View all of your automation logs here',
      //   icon: DKIcons.ic_document,
      //   hidden: false
      // },
      {
        title: 'UOM Schema',
        propertyName: NEW_SETTINGS_SECTION.UOM_SCHEMA,
        description: 'Set up your multiple unit of measurement used in stock',
        icon: DKIcons.ic_sales,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.UOM_SCHEMA),
        showHeader: false,
        keywords: ['UOM', 'Unit', 'Schema', 'UOM Schema']
      },
      {
        title: 'Sequence Number Setting',
        propertyName: NEW_SETTINGS_SECTION.SEQUENCE_NUMBER_SETTING,
        description: 'Set default document number sequencing',
        icon: DKIcons.ic_cards,
        hidden: !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.SEQ_NUMBER),
        showHeader: true,
        keywords: ['Default Sequence Number']
      },
      {
        title: 'Credit Limit',
        propertyName: NEW_SETTINGS_SECTION.CREDIT_LIMIT,
        description: `Configure alert for Sales ${
          Utility.isUSorg() ? 'Estimate' : 'Quote'
        }/Invoice ${
          isSalesOrderVisible() ? `/Sales Order` : ``
        } if customer reach their credit limit`,
        icon: DKIcons.ic_volunteer,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.CREDIT_LIMIT
        ),
        showHeader: true,
        keywords: ['Sales Estimates', 'Sales Invoices', 'Sales Orders']
      },
      {
        title: 'Custom Fields',
        propertyName: NEW_SETTINGS_SECTION.CUSTOM_FIELDS,
        description: 'Create additional field to capture data that you need',
        icon: DKIcons.ic_volunteer,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.CUSTOM_FIELD
        ),
        showHeader: false
      },
      {
        title: 'Sales Person Master',
        propertyName: NEW_SETTINGS_SECTION.SALES_PERSON_MASTER,
        description: 'Create Sales Person data',
        icon: DKIcons.ic_volunteer,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.MANAGE_SALES_PERSON_MASTER
        ),
        showHeader: false
      },
      {
        title: 'Locations',
        propertyName: NEW_SETTINGS_SECTION.LOCATIONS,
        description:
          'You can use locations to categorize your transactions by different parts of your company.',
        icon: DKIcons.ic_volunteer,
        hidden:
          !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.LOCATIONS) ||
          !tenantInfo?.additionalSettings?.LOCATION?.trackLocation,
        showHeader: false
      },
      {
        title: 'Classes',
        propertyName: NEW_SETTINGS_SECTION.CLASSES,
        description:
          'Displays the classes you can use to categorize your accounting transactions',
        icon: DKIcons.ic_volunteer,
        hidden:
          !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.CLASSES) ||
          !tenantInfo?.additionalSettings?.CLASS?.trackClasses,
        showHeader: false
      },
      {
        title: 'Item Landed Cost Setting',
        propertyName: NEW_SETTINGS_SECTION.ILC_SETTING,
        description: 'Item Landed Cost',
        icon: DKIcons.ic_volunteer,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.ITEM_LANDED_COST
        ),
        showHeader: true,
        keywords: ['Landed Cost Category', 'Activate Item Landed Cost Setting']
      },
      {
        title: 'Agencies',
        propertyName: NEW_SETTINGS_SECTION.AGENCIES,
        description: 'Sales tax agencies',
        icon: DKIcons.ic_volunteer,
        hidden:
          !checkUserPermission(PERMISSIONS_BY_MODULE.SETTINGS.AGENCIES) ||
          tenantInfo.country !== COUNTRY_CODES.US,
        showHeader: false
      },
      {
        title: 'Active Date Range Settings',
        propertyName: NEW_SETTINGS_SECTION.ACTIVE_DATE_RANGE_SETTINGS,
        description: `Active Date Range Restrictions`,
        icon: DKIcons.data_type.ic_date,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.ACTIVE_DATE_RANGE_SETTINGS
        ),
        keywords: ['Enable Active Date Range'],
        showHeader: true
      },
      {
        title: 'Back Dated Restrictions Settings',
        propertyName: NEW_SETTINGS_SECTION.BACK_DATED_RESTRICTIONS_SETTINGS,
        description: `Back Dated Restrictions`,
        icon: DKIcons.data_type.ic_date,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.BACK_DATED_RESTRICTIONS_SETTINGS
        ),
        showHeader: true,
        keywords: ['Restrict Back Dated Transactions']
      },
      {
        title: 'Barcode Settings',
        propertyName: NEW_SETTINGS_SECTION.BARCODE_SETTINGS,
        description: `Manage barcode settings`,
        icon: barcode_icon,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.BARCODE_SETTINGS
        ),
        showHeader: true,
        keywords: [
          'Generate Barcode along with Company Name',
          'Generate Barcode along with Price Tag',
          'Generate Barcode along with Product Name',
          'Generate Barcode along with Product ID',
          'Generate Barcode along with Batch Manufacture Date',
          'Generate Barcode along with Batch Expiry Date'
        ]
      },
      {
        title: 'Withholding Taxes',
        propertyName: NEW_SETTINGS_SECTION.WITHHOLDING_TAXES,
        description: 'Set Withholding taxes and groups',
        icon: DKIcons.ic_tax,
        hidden: tenantInfo.country !== COUNTRY_CODES.IL,
        showHeader: false
      },
      {
        title: 'Rate Analysis',
        propertyName: NEW_SETTINGS_SECTION.RATE_ANALYSIS,
        description:
          'Conduct a profit analysis for each Sales Order/Invoice by comparing it with the associated incurred costs.',
        icon: DKIcons.ic_volunteer,
        hidden: !tenantInfo?.additionalSettings?.ENABLE_RATE_ANALYSIS,
        showHeader: true
      },
      {
        title: 'Client Portal',
        propertyName: NEW_SETTINGS_SECTION.CLIENT_PORTAL,
        description: 'Manage client portal logo, name & theme',
        icon: DKIcons.ic_user,
        hidden: !checkUserPermission(
          PERMISSIONS_BY_MODULE.SETTINGS.CLIENT_PORTAL
        ),
        showHeader: true
      },
      {
        title: 'Job Cards Email Settings',
        propertyName: NEW_SETTINGS_SECTION.JOB_CARDS_EMAIL_SETTINGS,
        description:
          "Configure email id's to receive emails on assign / reassign of operators",
        icon: DKIcons.ic_company,
        hidden:
          !Utility.isMRPWithURLCheck() || !RolePermissionUtil.checkIsAdmin(),
        showHeader: true
      }
    ];

    return settingOptions;
  }, [
    featurePermissionsInfo?.Supported,
    tenantInfo?.additionalSettings?.CLASS?.trackClasses,
    tenantInfo?.additionalSettings?.ENABLE_RATE_ANALYSIS,
    tenantInfo?.additionalSettings?.LABEL_PRINT,
    tenantInfo?.additionalSettings?.LOCATION?.trackLocation,
    tenantInfo.country
  ]);

  const getFirstVisibleSetting = (settingsList: any) => {
    let firstSetting: any = null;
    settingsList?.forEach((setting: any) => {
      if (!setting.hidden && !firstSetting) {
        firstSetting = setting;
      }
    });
    return firstSetting;
  };
  useEffect(() => {
    if (!hasAnySettingsPermission()) {
      showNoPermissionAlert();
      onClose();
      RouteManager.navigateToHome();
    }
  }, []);
  useEffect(() => {
    setSelectedSetting(getFirstVisibleSetting(getSettingsOptions()));
  }, [getSettingsOptions]);

  useEffect(() => {
    if (Utility.isEmpty(selectedSettingFromStore)) {
      setSelectedSetting(getFirstVisibleSetting(getSettingsOptions()));
    } else {
      const flattenedSettingOptions = getSettingsOptions().flatMap(
        (setting: any) => {
          return setting.children ? [setting, ...setting.children] : setting;
        }
      );
      const filteredSetting = flattenedSettingOptions.find(
        (setting: any) => setting.propertyName === selectedSettingFromStore
      );
      setSelectedSetting(filteredSetting);
    }
  }, [selectedSettingFromStore]);

  const detectOutsideClick = useCallback(
    (event: MouseEvent) => {
      if ((event.target as any)?.classList.contains('new-settings-wrapper')) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', close);
    document.addEventListener('mousedown', detectOutsideClick);
    return () => {
      window.removeEventListener('keydown', close);
      document.removeEventListener('mousedown', detectOutsideClick);
    };
  }, [detectOutsideClick, onClose]);

  const getShrinkExpandButton = () => {
    return (
      <DKButton
        icon={showSettingsInFullscreen ? ic_shrink : ic_expand}
        onClick={() => {
          dispatch(setShowSettingsInFullScreen(!showSettingsInFullscreen));
        }}
        className="mr-m"
        style={{ padding: 0 }}
      />
    );
  };

  const onSettingSelected = (setting: any) => {
    if (
      setting.propertyName === NEW_SETTINGS_SECTION.CUSTOM_FIELDS &&
      subscriptionDetails?.PlanName?.toLowerCase() === PLANS.STARTUP
    ) {
      showAlert(
        'Warning',
        'This feature is not available in your current plan, please upgrade to use.',
        [
          {
            title: 'Cancel',
            className: 'border-m bg-gray1',
            onClick: () => {}
          },
          {
            title: 'Upgrade Now',
            className: 'bg-button text-white ml-m',
            onClick: () => {
              window.open(process.env.REACT_APP_URL_GO + `billing`, '_blank');
            }
          }
        ]
      );
      return;
    }
    setSelectedSetting({ ...setting });
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background new-settings-wrapper">
        <div
          className="popup-window"
          style={{
            padding: 0,
            height: showSettingsInFullscreen ? '100%' : '95%',
            maxHeight: showSettingsInFullscreen ? '100%' : '95%',
            width: showSettingsInFullscreen ? '100%' : '90%',
            maxWidth: showSettingsInFullscreen ? '100%' : 1070,
            overflowY: 'hidden',
            borderRadius: showSettingsInFullscreen ? 0 : 8,
            minHeight: 300
          }}
        >
          <div className="row parent-size position-relative overflow-y-hidden">
            <div
              className="row position-absolute width-auto"
              style={{ right: 15, top: 15, zIndex: 1 }}
            >
              {getShrinkExpandButton()}
              <DKButton
                icon={DKIcons.ic_close_}
                onClick={() => onClose()}
                className=""
                style={{
                  padding: 0,
                  width: 16,
                  height: 16
                }}
              />
            </div>

            <div
              className="bg-gray1 parent-height overflow-y-hidden"
              style={{ width: 220 }}
            >
              <SettingsLeftPanel
                selectedSetting={selectedSetting}
                data={getSettingsOptions()}
                onSettingSelected={(setting: any) => {
                  onSettingSelected(setting);
                }}
              />
            </div>
            <div
              className="column parent-size overflow-y-hidden"
              style={{ width: 'calc(100% - 220px)' }}
            >
              {selectedSetting && (
                <SettingsRightPanel selectedSetting={selectedSetting} />
              )}
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default SettingsNew;
