import {
  COLORS as GraphColors,
  DropdownSelectionType,
  FORM_ELEMENTS
} from '../Constants/Constant';
import { CallBackPayloadType } from './Interfaces';
import { COLORS } from 'deskera-ui-library';
import { DropdownTag, MultiDropdownTag } from './NewContact';
import { CustomField } from './CustomField';

export interface Product {
  offeringType: string;
  type: string;
  name: string;
  productId?: string;
  productNumberFormat: string;
  documentSequenceCode?: string;
  // documentSequenceCode: "P-numbernumbernumbernumber000",
  barcode: string;
  description: string;
  purchaseAccountCode: string; //inventorySettings.inventoryPurchaseAccount
  purchaseTaxCode: string;
  purchasePrice: number;
  salesAccountCode: string; //inventorySettings.salesAccount
  salesPrice: number;
  salesTaxCode: string;
  salesReturnAccountCode: string; //inventorySettings.salesReturnAccount
  purchaseReturnAccountCode: string; //inventorySettings.inventoryPurchaseReturnAccount
  stockUom: number;
  advancedTracking: string; // "NONE", "SERIEAL", "BATCHED"
  inventory: {
    costOfGoodsSoldAccountCode?: string;
    inventoryAccountCode?: string;
    stockAdjustmentAccountCode?: string;
    openingQuantity: number;
    availableQuantity: number;
    openingValuation: number;
    warehouseCode?: string;
    landedCostWeight?: number;
  };
  whtApplicable: boolean;
  exciseApplicable: boolean;
  exciseRate: number;
  exciseRateIndo: number;
  exciseType: string;
  multipleUomSchema: boolean;
  images: string[];
  incomingQty: number;
  outgoingQty: number;
  manufacturingCostAccountCode: string;
  exemptedMalaysia?: boolean;
  tariffOrServiceCodeMalaysia?: string;
  whtSectionToWhtRateId?: string;
  attributes: [];
  variantAttributes: {};
  isVariant: boolean;
  hasVariants: boolean;
  reorderLevelReached: boolean;
  variantCount: number;
  customField?: [];
  hsnOrSacCode?: string;
  taxPreference: boolean;
  cessRule?: string;
  cessRuleDescription: null; //Cess Applicable
  cessNonAdvol: null;
  itcAdjustment: string;
  glAccountCode: string;
  tdsApplicableIndia: boolean;
  reorderLevel?: string;
  reorderQuantity: any;
  reorderVendorCode: string;
  uomSchema?: string;
  uqcIndia?: string;
  tdsNatureOfPaymentIndia: string;
  taxExemptionReason?: string; //"EXEMPTED", "NON_GST"
  bomAddCostConfiguration?: [];
  bomProductsConfiguration?: [];
  advancedTrackingMetaData?: [];
  taxable?: boolean;
  barcodeDetails?: {
    barcodeField?: 'PRODUCT_ID' | 'BARCODE' | undefined;
    hideBarcodeLabel?: boolean;
  };
  landedCostCategory?: [];
  productSubstitutesDetails?: [];
  active?: boolean;
  valuationMethod?: any;
  deferredExpenseAccountCode?: string | null;
  amortizationPeriod?: number | null;
  amortizationTemplateCode?: string | null;
}

export interface ProductAttachmentResponse {
  attachmentFileName: string;
  attachmentDate: string;
  attachmentId: number;
  attachmentSize: string;
}

export const ProductInitialState: Product = {
  offeringType: 'GOODS', //"GOODS", "SERVICES"
  type: 'TRACKED',
  name: '',
  productNumberFormat: 'Manual',
  // documentSequenceCode: "P-0000000",
  barcode: '',
  description: '',
  purchaseAccountCode: '', //inventorySettings.inventoryPurchaseAccount
  purchaseTaxCode: '',
  purchasePrice: 0,
  salesAccountCode: '', //inventorySettings.salesAccount
  salesPrice: 0,
  salesTaxCode: '',
  salesReturnAccountCode: '', //inventorySettings.salesReturnAccount
  purchaseReturnAccountCode: '', //inventorySettings.inventoryPurchaseReturnAccount
  stockUom: 2,
  advancedTracking: 'NONE', // "NONE", "SERIEAL", "BATCHED"
  inventory: {
    openingQuantity: 0,
    availableQuantity: 0,
    openingValuation: 0,
    landedCostWeight: 0
  },
  whtApplicable: false,
  exciseApplicable: false,
  exciseRate: 0,
  exciseRateIndo: 0,
  exciseType: 'FLAT',
  multipleUomSchema: false,
  images: [],
  incomingQty: 0,
  outgoingQty: 0,
  manufacturingCostAccountCode: '',
  attributes: [],
  variantAttributes: {},
  isVariant: false,
  hasVariants: false,
  reorderLevelReached: false,
  variantCount: 0,
  taxPreference: true,
  cessRuleDescription: null, //Cess Applicable
  cessNonAdvol: null, //Cess Applicable
  itcAdjustment: 'NA',
  glAccountCode: 'NA',
  uqcIndia: 'NOT',
  tdsApplicableIndia: false,
  reorderQuantity: undefined,
  reorderVendorCode: '',
  tdsNatureOfPaymentIndia: '',
  taxable: true,
  landedCostCategory: [],
  barcodeDetails: {
    barcodeField: 'PRODUCT_ID',
    hideBarcodeLabel: false
  },
  exemptedMalaysia: true,
  productSubstitutesDetails: [],
  valuationMethod: '',
  deferredExpenseAccountCode: null,
  amortizationPeriod: null,
  amortizationTemplateCode: null
};

export interface ProductDashboardProps {
  productDetails: any;
  passingInteraction: (callback: CallBackPayloadType) => void;
}

export interface ProductDashboardState {
  period: { options: string[]; currentOption: string };
  productCode: string;
  currentDate: string;
  salesInfo: SalesProductDashboard;
  purchaseInfo: PurchaseProductDashboard;
  InvoiceInfo: InvoiceProductDashboard;
  momGrowthGraph: GraphData<Array<LineGraph>>;
  stockValueGraph: GraphData<Array<GraphPoint>>;
  salesPurchaseGraph: GraphData<Array<LineGraph>>;
  stockAvailableGraph: GraphData<Array<LineGraph>>;
  topCustomerGraph: GraphData<Array<BarGraph>>;
  topSuppilerGraph: GraphData<Array<BarGraph>>;
  showProductPopup: boolean;
  productDetails: any;
  variantDetails: {
    variantList: any;
    variantGridData: any;
    currentPage: number;
    totalPageCount: number;
    showAddVariantPopup: boolean;
    attributesList: Attribute[];
    selectedVariantDetails: any;
  };
  advancedTracking: {
    isAdvancedTracked: boolean;
    advancedTrackingData: any;
    currentPage: number;
    totalPageCount: number;
    advancedTrackingGridData: any;
  };
  createDocument: { options: string[]; currentOption: string; hidden: boolean };
}

export interface Attribute {
  id: number;
  name: string;
  values: string[];
}

export interface SalesProductDashboard {
  sale: CardDashboard;
  profit: CardDashboard;
  customer: CardDashboard;
  quantitySold: CardDashboard;
}
export interface InvoiceProductDashboard {
  noOfInvoices: CardDashboard;
  noOfUpaidInvoices: CardDashboard;
}

export interface PurchaseProductDashboard {
  purchase: CardDashboard;
  inventory: CardDashboard;
  daysToSell: CardDashboard;
  quantityBought: CardDashboard;
}

export interface CardDashboard {
  text: string;
  amount: string;
  compareInfo: { percentage: number; helpText: string };
}

export interface GraphData<T> {
  title: string;
  data: T;
  noData: boolean;
}

export interface GraphPoint {
  title: string;
  value: number;
  color: string;
}

export interface LineGraph {
  title: string;
  color: string;
  points: GraphPoint[];
}

export interface BarGraph {
  title: string;
  color: string;
  value: number;
}

export const initialStateProductDashboard: ProductDashboardState = {
  period: { options: [], currentOption: 'Past 6 months' },
  createDocument: { options: [], currentOption: 'Create Order', hidden: false },
  productCode: '',
  currentDate: '',
  salesInfo: {
    sale: {
      text: 'Sales',
      amount: '0.00',
      compareInfo: { percentage: 0, helpText: '' }
    },
    profit: {
      text: 'Profit',
      amount: '0.00',
      compareInfo: { percentage: 0, helpText: '' }
    },
    customer: {
      text: 'No. of Customers',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    },
    quantitySold: {
      text: 'Quantity Sold',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    }
  },
  InvoiceInfo: {
    noOfInvoices: {
      text: 'No. of Invoices',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    },
    noOfUpaidInvoices: {
      text: 'No. of Unpaid Invoices',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    }
  },
  purchaseInfo: {
    purchase: {
      text: 'Purchase',
      amount: '0.00',
      compareInfo: { percentage: 0, helpText: '' }
    },
    inventory: {
      text: 'Inventory Turnover',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    },
    daysToSell: {
      text: 'Days to Sell',
      amount: '0 days',
      compareInfo: { percentage: 0, helpText: '' }
    },
    quantityBought: {
      text: 'Quantity Bought',
      amount: '0',
      compareInfo: { percentage: 0, helpText: '' }
    }
  },
  momGrowthGraph: {
    title: 'Sales with MoM Growth',
    data: [
      {
        title: 'Sales',
        color: COLORS.chart.blue,
        points: [
          {
            title: 'Jan',
            value: 100,
            color: COLORS.chart.yellow
          },
          {
            title: 'Feb',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Mar',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Apr',
            value: 100,
            color: COLORS.chart.red
          }
        ]
      },
      {
        title: 'COGS',
        color: COLORS.chart.yellow,
        points: [
          {
            title: 'Jan',
            value: 80,
            color: COLORS.chart.yellow
          },
          {
            title: 'Feb',
            value: 20,
            color: COLORS.chart.red
          },
          {
            title: 'Mar',
            value: 90,
            color: COLORS.chart.red
          },
          {
            title: 'Apr',
            value: 10,
            color: COLORS.chart.red
          }
        ]
      },
      {
        title: 'MOM Growth',
        color: COLORS.chart.red,
        points: [
          {
            title: 'Jan',
            value: 10,
            color: COLORS.chart.yellow
          },
          {
            title: 'Feb',
            value: 20,
            color: COLORS.chart.red
          },
          {
            title: 'Mar',
            value: 30,
            color: COLORS.chart.red
          },
          {
            title: 'Apr',
            value: 40,
            color: COLORS.chart.red
          }
        ]
      }
    ],
    noData: true
  },
  stockValueGraph: {
    title: 'Stock Value',
    noData: true,
    data: [
      {
        title: 'Mon',
        value: 200.25,
        color: COLORS.chart.blue
      },
      {
        title: 'Tue',
        value: 0,
        color: COLORS.chart.blue2
      },
      {
        title: 'thu',
        value: 0,
        color: COLORS.chart.lightBlue
      },
      {
        title: 'fri',
        value: 0,
        color: COLORS.chart.blue2
      },
      {
        title: 'sat',
        value: 60.2,
        color: COLORS.chart.lightBlue
      }
    ]
  },
  salesPurchaseGraph: {
    title: 'Sales and Purchases',
    noData: true,
    data: [
      {
        title: 'Sales',
        color: COLORS.chart.blue,
        points: [
          {
            title: 'Jan',
            value: 100,
            color: COLORS.chart.yellow
          },
          {
            title: 'Feb',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Mar',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Apr',
            value: 100,
            color: COLORS.chart.red
          }
        ]
      },
      {
        title: 'Purchase',
        color: COLORS.chart.green,
        points: [
          {
            title: 'Wed3',
            value: 30,
            color: COLORS.chart.yellow
          },
          {
            title: 'Wed4',
            value: 80,
            color: COLORS.chart.red
          }
        ]
      }
    ]
  },
  stockAvailableGraph: {
    title: 'Stock Available Quantity',
    noData: true,
    data: [
      {
        title: 'Quantity',
        color: COLORS.chart.blue,
        points: [
          {
            title: 'Jan',
            value: 100,
            color: COLORS.chart.yellow
          },
          {
            title: 'Feb',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Mar',
            value: 50,
            color: COLORS.chart.red
          },
          {
            title: 'Apr',
            value: 100,
            color: COLORS.chart.red
          }
        ]
      }
    ]
  },
  topCustomerGraph: {
    title: 'Top 5 Customers',
    noData: true,
    data: [
      { title: 'Jan', value: 7, color: GraphColors.charts.bar_vertical },
      { title: 'Feb', value: 9, color: GraphColors.charts.bar_vertical },
      { title: 'Mar', value: 3, color: GraphColors.charts.bar_vertical },
      { title: 'Apr', value: 5, color: GraphColors.charts.bar_vertical },
      { title: 'May', value: 4, color: GraphColors.charts.bar_vertical },
      { title: 'Jun', value: 2, color: GraphColors.charts.bar_vertical },
      { title: 'Jul', value: 6, color: GraphColors.charts.bar_vertical },
      { title: 'Aug', value: 8, color: GraphColors.charts.bar_vertical },
      { title: 'Sep', value: 4, color: GraphColors.charts.bar_vertical },
      { title: 'Oct', value: 10, color: GraphColors.charts.bar_vertical },
      { title: 'Nov', value: 8, color: GraphColors.charts.bar_vertical },
      { title: 'Dec', value: 3, color: GraphColors.charts.bar_vertical }
    ]
  },
  topSuppilerGraph: {
    title: 'Top 5 Suppliers',
    noData: true,
    data: [
      { title: 'Jan', value: 7, color: GraphColors.charts.bar_vertical },
      { title: 'Feb', value: 9, color: GraphColors.charts.bar_vertical },
      { title: 'Mar', value: 3, color: GraphColors.charts.bar_vertical },
      { title: 'Apr', value: 5, color: GraphColors.charts.bar_vertical },
      { title: 'May', value: 4, color: GraphColors.charts.bar_vertical },
      { title: 'Jun', value: 2, color: GraphColors.charts.bar_vertical },
      { title: 'Jul', value: 6, color: GraphColors.charts.bar_vertical },
      { title: 'Aug', value: 8, color: GraphColors.charts.bar_vertical },
      { title: 'Sep', value: 4, color: GraphColors.charts.bar_vertical },
      { title: 'Oct', value: 10, color: GraphColors.charts.bar_vertical },
      { title: 'Nov', value: 8, color: GraphColors.charts.bar_vertical },
      { title: 'Dec', value: 3, color: GraphColors.charts.bar_vertical }
    ]
  },
  showProductPopup: false,
  productDetails: null,
  variantDetails: {
    variantList: null,
    variantGridData: null,
    currentPage: 1,
    totalPageCount: 1,
    showAddVariantPopup: false,
    attributesList: [],
    selectedVariantDetails: null
  },
  advancedTracking: {
    isAdvancedTracked: false,
    advancedTrackingData: null,
    currentPage: 1,
    totalPageCount: 1,
    advancedTrackingGridData: null
  }
};
export interface ProductFormState {
  compliance: ComplianceState;
  customFields: CustomField[] | null;
  isAutoFulfillEnabled: boolean;
  landedCostCategory: MultiDropdownTag<string>;
}

interface ComplianceState {
  singapore: SGComplianceFields;
  india: IndiaComplianceFields;
  uk: UKComplianceFields;
}

interface SGComplianceFields {
  purchaseTax: DropdownTag<string>;
  defaultPurchaseTax: CheckBox;
  salesTax: DropdownTag<string>;
  defaultSalesTax: CheckBox;
}

interface UKComplianceFields {
  purchaseTax: DropdownTag<string>;
  defaultPurchaseTax: CheckBox;
  salesTax: DropdownTag<string>;
  defaultSalesTax: CheckBox;
}

interface IndiaComplianceFields {
  defaultPurchaseTax: CheckBox;
  defaultSalesTax: CheckBox;
}

interface CheckBox {
  isSelected: boolean;
  hidden: boolean;
}
export const initialProductFormState: ProductFormState = {
  compliance: {
    singapore: {
      purchaseTax: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      defaultPurchaseTax: { isSelected: false, hidden: true },
      salesTax: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      defaultSalesTax: { isSelected: false, hidden: true }
    },
    uk: {
      purchaseTax: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      defaultPurchaseTax: { isSelected: false, hidden: true },
      salesTax: {
        key: '',
        hidden: true,
        value: { label: '', value: '' },
        selectionType: DropdownSelectionType.SINGLE,
        type: FORM_ELEMENTS.DROPDOWN
      },
      defaultSalesTax: { isSelected: false, hidden: true }
    },
    india: {
      defaultPurchaseTax: { isSelected: false, hidden: true },
      defaultSalesTax: { isSelected: false, hidden: true }
    }
  },
  customFields: null,
  isAutoFulfillEnabled: false,
  landedCostCategory: {
    key: '',
    hidden: false,
    value: [],
    selectionType: DropdownSelectionType.MULTIPLE,
    type: FORM_ELEMENTS.DROPDOWN,
    defaultValue: []
  }
};

export enum PRODUCT_DROPDOWN_FIELDS {
  SG_PURCHASE_TAX = 'purchaseTax',
  SG_SALES_TAX = 'salesTax',
  UK_PURCHASE_TAX = 'uk_purchaseTax',
  UK_SALES_TAX = 'uk_salesTax',
  NONE = 'none',
  OPENING_QUANITIY = 'openingQuantity',
  OPENING_VALUATION = 'openingValuation'
}

export enum PRODUCT_CHECKBOX_FIELDS {
  SG_DEFAULT_PURCHASE_TAX = 'defaultPurchaseTax',
  SG_DEFAULT_SALES_TAX = 'defaultSalesTax',
  UK_DEFAULT_PURCHASE_TAX = 'uk_defaultPurchaseTax',
  UK_DEFAULT_SALES_TAX = 'uk_defaultSalesTax',
  IND_DEFAULT_PURCHASE_TAX = 'indDefaultPurchaseTax',
  IND_DEFAULT_SALES_TAX = 'indDefaultSalesTax',
  AUTO_FULFILL = 'autoFulfill'
}

export enum FUTURE_ACCOUNTS_APPLY {
  COST_OF_GOODS_SOLD_ACCOUNT = 'costOfGoodsSoldAccount',
  INVENTORY_ACCOUNT = 'inventoryAccount',
  WIP_INVENTORY_ACCOUNT = 'wipInventoryAccount',
  STOCK_ADJUSTMENT_ACCOUNT = 'stockAdjustmentAccount',
  INVENTORY_PURCHASE_ACCOUNT = 'inventoryPurchaseAccount',
  SALES_ACCOUNT = 'salesAccount',
  MANUFACTURING_COST = 'manufacturingCost',
  DEFERRED_EXPENSE_ACCOUNT = 'deferredExpenseAccount'
}

export const futureAccountInitialState = {
  [FUTURE_ACCOUNTS_APPLY.COST_OF_GOODS_SOLD_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.INVENTORY_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.WIP_INVENTORY_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.STOCK_ADJUSTMENT_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.INVENTORY_PURCHASE_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.SALES_ACCOUNT]: false,
  [FUTURE_ACCOUNTS_APPLY.MANUFACTURING_COST]: false
};
export const GENERATE_BARCODE_OPTION = [
  {
    label: 'Product ID',
    value: 'PRODUCT_ID'
  },
  {
    label: 'Barcode',
    value: 'BARCODE'
  }
];
