import ApiConstants from '../Constants/ApiConstants';
import { PurchaseReturn } from '../Models/PurchaseReturn';
import { Returned } from '../Models/SalesReturned';
import Utility from '../Utility/Utility';
import http2 from './Interceptor2';
class PurchaseReturnService {
  static isFullyReturned(documentCode: any, documentType: any, forRejectedGr=false) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.DETAILS(documentCode, documentType, forRejectedGr)}` 
    );
  }
  static checkReceivedGoods(documentCode: any) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.CHECK_RECEIVED_GOODS(documentCode)}`
    );
  }
  static checkReceivedGoodsReturn(documentCode: any) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.CHECK_RECEIVED_GOODS_RETURN(documentCode)}`
    );
  }
  static savePurchaseReturn(returned: PurchaseReturn) {
    console.log('PURCHASE RETURN ', returned);
    return http2.post(ApiConstants.URL.PURCHASE_RETURN.CREATE_RETURN, returned);
  }

  static deletePurchaseReturn(code: any) {
    return http2.delete(
      ApiConstants.URL.PURCHASE_RETURN.DELETE_PURCHASE_RETURNS(code)
    );
  }
  static getAdvanceTrackingReturnData(code: any, docType: any) {
    return http2.post(
      `${ApiConstants.URL.PURCHASE_RETURN.GET_ADVANCE_TRACKING(code, docType)}`
    );
  }
  static getAllPurchaseReturnInfo(code: any, docType: any) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.GET_CONSOLIDATED_PURCHASE_RETURN_INFO(
        code,
        docType
      )}`
    );
  }
  static getAllPurchaseReturnReportInfo(code: any) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.GET_CONSOLIDATED_PURCHASE_RETURN_REPORT_INFO(
        code
      )}`
    );
  }
  static getAdvanceTrackingRecord(code: any) {
    return http2.get(
      `${ApiConstants.URL.PURCHASE_RETURN.GET_ADVANCE_RECORD_PURCHASE_RETURN}?limit=100&page=0&sort=createdAt&transactionRefCode=${code}`
    );
  }
  static getReasons() {
    return http2.get(ApiConstants.URL.PURCHASE_RETURN.GET_REASONS);
  }
}
export default PurchaseReturnService;
