import React, { Component } from 'react';
import { DKLabel, DKButton } from 'deskera-ui-library';
import Utility from '../../../Utility/Utility';
import DateFormatService from '../../../Services/DateFormat';
import { BOOKS_DATE_FORMAT, DOC_TYPE } from '../../../Constants/Constant';
import { WORK_ORDER_PR_PO } from '../../Settings/AdvancedSettings/AdvancedSettings';

const LinkedDocsPopup = (props: any) => {
  const getDcumentTypeName = (docType: any) => {
    switch (docType) {
      case WORK_ORDER_PR_PO.PURCHASE_ORDER:
        return 'PO';
      case WORK_ORDER_PR_PO.PURCHASE_REQUEST:
      case WORK_ORDER_PR_PO.PURCHASE_REQUISITION:
        return 'PR';
      case DOC_TYPE.STOCK_REQUEST:
        return 'SR';

      default:
        return '';
    }
  };

  return (
    <div
      className="transparent-background"
      style={{
        zIndex: 9998
      }}
    >
      <div
        className="popup-window"
        style={{
          maxWidth: 550,
          width: '90%',
          maxHeight: '95%',
          padding: 0,
          paddingBottom: 0,
          overflow: 'visible'
        }}
      >
        {/* header */}
        <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
          <div className="row width-auto">
            <div className="row">
              <DKLabel
                text={
                  Utility.isBinAllocationMandatory() ||
                  Utility.isWarehouseTaggingEnabled()
                    ? 'Linked PO/PR/SR '
                    : 'Linked PO/PR '
                }
                className="fs-m fw-m"
              />
              <DKLabel
                text={`(${props.selectedProduct?.productName})`}
                className="text-gray fs-r"
              />
            </div>
          </div>
          <div className="row width-auto">
            <DKButton
              title={'Close'}
              className="bg-white border-m"
              onClick={() => {
                props.onCancel();
              }}
            />
          </div>
        </div>

        {/* linked documents */}
        <div
          className="parent-width overflow-scroll"
          style={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {!Utility.isEmpty(props.selectedProduct?.linkedPOPRData) && (
            <div className="py-4 parent-width">
              <div className="row mb-s">
                <div className="fw-b w-2/4 text-align-center">Type</div>
                <div className="fw-b w-2/4 text-align-left">Document Code</div>
                <div className="fw-b w-2/4 text-align-left">
                  Expected Receive By Date
                </div>
              </div>
              {props.selectedProduct?.linkedPOPRData?.map(
                (itemProduct: any, index: any) => {
                  return (
                    <div className="row py-2">
                      <div className="w-2/4 text-align-center">
                        {getDcumentTypeName(itemProduct?.documentType)}
                      </div>
                      <div
                        className="w-2/4 text-align-left cursor-hand underline"
                        onClick={() => {
                          props.onLinkClick(itemProduct);
                        }}
                      >
                        {itemProduct?.documentSequenceCode}
                      </div>
                      <div className="w-2/4 text-align-left">
                        {DateFormatService.getFormattedDateString(
                          itemProduct?.documentReceiveByDate,
                          BOOKS_DATE_FORMAT['DD-MM-YYYY']
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )}

          {Utility.isEmpty(props.selectedProduct?.linkedPOPRData) && (
            <DKLabel text="No records found" className="p-m" />
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkedDocsPopup;
