import {
  removeLoader,
  DKTooltipWrapper,
  DKDataGrid,
  DKButton,
  DKLabel,
  DKIcon
} from 'deskera-ui-library';
import { Fragment, useEffect, useRef, useState } from 'react';
import { IState } from '../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { selectAuditLogColumnConfig } from '../Redux/Slices/AuditLogSlice';
import {
  fetchAllUsersList,
  selectUsersList
} from '../Redux/Slices/PermissionsSlice';
import ic_no_data from '../Assets/Icons/ic_no_data.png';
import { activeTenantInfo } from '../Redux/Slices/AuthSlice';
import {
  BOOKS_DATE_FORMAT,
  MODULES_NAME,
  MONTHS,
  MONTHS_WITH_NUMBER
} from '../Constants/Constant';
import { IColumnOption, IColumnType } from '../Models/Table';

import DateFormatService from '../Services/DateFormat';
import { COLUMN_CODE } from '../Constants/TableConstants';
import {
  AUDIT_LOG_CONSTANT as AUDIT_LOG_KEY,
  KEYS_TO_REPLACE,
  MODULE_FIELDS_TO_IGNOR,
  detailJSONIgnorKeys
} from '../Components/Settings/AuditLogConstants';
import TransactionLogService from '../Services/TransactionLog';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Components/Contacts/Details';
import SideBarService from '../Services/sidebar';
import useScreenResize from '../Hooks/useScreenResize';
import Utility, { deepClone } from '../Utility/Utility';

export default function TransactionLogForContact(props: any) {
  const initialState: IState = {
    columnData: [],
    rowData: [],
    originalData: [],
    filter: []
  };

  const dispatch = useAppDispatch();

  const columnConfig = useAppSelector(selectAuditLogColumnConfig);
  const [colConfigToShow, setColConfigToShow] = useState<any>([]);
  const usersListData = useAppSelector(selectUsersList);
  const [auditLog, setAuditLog] = useState(initialState);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(gridContainerRef);

  useEffect(() => {
    if (Utility.isEmpty(usersListData)) {
      dispatch(fetchAllUsersList());
    }
  }, []);

  useEffect(() => {
    getTransactionLogData();
  }, []);

  useEffect(() => {
    let newModuleOptions: any = [
      {
        color: 'data-grid-badge-color-1',
        id: 'WORK_ORDER',
        name: 'Work Order'
      },
      {
        color: 'data-grid-badge-color-2',
        id: 'JOB_CARD',
        name: 'Job Card'
      },
      {
        color: 'data-grid-badge-color-3',
        id: 'WORK_ORDER_ITEM',
        name: 'Work Order Item'
      },
      {
        color: 'data-grid-badge-color-4',
        id: 'OPERATOR',
        name: 'Operator'
      },
      {
        color: 'data-grid-badge-color-5',
        id: 'OPERATION',
        name: 'Operation'
      },
      {
        color: 'data-grid-badge-color-6',
        id: 'WORKSTATION',
        name: 'Workstation'
      },
      {
        color: 'data-grid-badge-color-1',
        id: 'JOB_WORK_OUT',
        name: 'Job Work Out'
      },
      {
        color: 'data-grid-badge-color-2',
        id: 'JOB_WORK_OUT_ITEM',
        name: 'Jon Work Out Item'
      },
      {
        color: 'data-grid-badge-color-2',
        id: 'FORECAST',
        name: 'Forecast'
      },
      {
        color: 'data-grid-badge-color-3',
        id: 'FORECAST_DETAILS',
        name: 'Forecast Details'
      },
      {
        color: 'data-grid-badge-color-4',
        id: 'PRODUCTION_CONSUMPTION_DETAILS',
        name: 'Production Consumption Details'
      }
    ];
    let tempColumnConfig = deepClone(columnConfig);
    let userColumnIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'user'
    );
    let moduleIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'module'
    );

    if (moduleIndex !== -1 && Utility.isMRPWithURLCheck()) {
      tempColumnConfig[moduleIndex].options = [
        ...(tempColumnConfig[moduleIndex].options as IColumnOption[]),
        ...newModuleOptions
      ];
    }
    if (userColumnIndex !== -1) {
      const options = usersListData?.map((user: any, index: any) => ({
        id: user.id,
        name: `${user.firstName}${user.lastName ? ' ' + user.lastName : ''}`,
        color: 'data-grid-badge-color-6'
      }));

      tempColumnConfig[userColumnIndex] = {
        ...tempColumnConfig[userColumnIndex],
        options,
        type: IColumnType.select
      };
    }

    tempColumnConfig.forEach((column) => {
      if (column.columnCode === 'details') {
        column['renderer'] = ({ value }) => {
          return getLogDetails(value);
        };
      }
    });
    setColConfigToShow([...tempColumnConfig]);
  }, [columnConfig]);

  const hasDetailsColumns = (auditLog: any) => {
    if (
      auditLog.action === 'update' ||
      [MODULES_NAME.MRP_JOB_CARD].includes(auditLog.module) ||
      auditLog.action === 'create'
    ) {
      return true;
    }
    return false;
  };

  function AuditLogColumnConfig(auditLogList: any[] = []): any {
    let rowData: any[] = [];

    auditLogList.forEach((auditLog, index) => {
      const date = auditLog.auditTimeStamp
        ? auditLog.auditTimeStamp.substring(0, 11)
        : '';
      const month = auditLog.auditTimeStamp
        ? auditLog.auditTimeStamp.substring(3, 6)
        : '';
      let monthNumber = '01';
      for (let i = 0; i < MONTHS.length; i++) {
        if (MONTHS_WITH_NUMBER[i].name === month) {
          monthNumber = MONTHS_WITH_NUMBER[i].num;
        }
      }
      const newDate =
        date.substring(0, 3) + monthNumber + date.substring(6, 11);
      const newdateFormatted = date
        ? DateFormatService.getFormattedDateString(
            newDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        : '';
      const row = {
        [COLUMN_CODE.AUDIT_LOG.Event]: auditLog.message,
        [COLUMN_CODE.AUDIT_LOG.Date]: newdateFormatted,
        [COLUMN_CODE.AUDIT_LOG.User]: [auditLog.createdBy],
        [COLUMN_CODE.AUDIT_LOG.Role]: auditLog.userRoles,
        [COLUMN_CODE.AUDIT_LOG.IpAddress]: auditLog.ipAddress,
        [COLUMN_CODE.AUDIT_LOG.Details]: hasDetailsColumns(auditLog)
          ? auditLog
          : null,
        module: [auditLog.module]
      };
      rowData.push(row);
    });
    return rowData;
  }

  const getTransactionLogData = () => {
    const payload = {
      documentType:
        props?.data?.documentType == 'REQUISITION'
          ? 'PURCHASE_REQUEST'
          : props?.data?.documentType,
      documentCode: props?.data?.documentCode
    };
    TransactionLogService.getTransactionLog(payload)
      .then((auditLogData: any) => {
        removeLoader();
        if (!Utility.isEmpty(auditLogData) && !Utility.isEmpty(usersListData)) {
          const rowData = AuditLogColumnConfig(auditLogData);
          const passingData = {
            columnData: colConfigToShow,
            rowData,
            filter: [],
            originalData: rowData
          };
          removeLoader();
          setAuditLog(passingData);
        } else {
        }
      })
      .catch((err: any) => {
        removeLoader();
        console.error('Error loading transaction log: ', err);
      });
  };

  const getLogKey = (key: string) => {
    if (AUDIT_LOG_KEY[key]) {
      return AUDIT_LOG_KEY[key];
    }

    return !Utility.isEmpty(key)
      ? key
          .split('_')
          .map((k) => k.charAt(0).toUpperCase() + k.substring(1))
          .join(' ')
      : '';
  };

  const keysReplaceWith = (key: any, module: any) => {
    if (KEYS_TO_REPLACE[module] && KEYS_TO_REPLACE[module][key]) {
      return KEYS_TO_REPLACE[module][key];
    } else {
      return key?.toUpperCase();
    }
  };

  const formatValue: any = (value: any, key: any, module: any) => {
    if (value !== null && value !== undefined && value !== '') {
      switch (typeof value) {
        case 'number':
          return Utility.roundingOff(value, tenantInfo.decimalScale);
        case 'boolean':
          return value ? 'True' : 'False';
        case 'object':
          let str = '';
          for (let key in value) {
            if (!detailJSONIgnorKeys[module]?.includes(key)) {
              str += `${keysReplaceWith(key, module)} : ${formatValue(
                value[key],
                key,
                module
              )} | `;
            }
          }
          return str;
        case 'string':
          try {
            const parseJson = JSON.parse(value);

            if (Array.isArray(parseJson)) {
              return parseJson
                .map((v: any) => formatValue(v, key, module))
                .join(', ');
            } else if (typeof parseJson === 'object') {
              let str = '';
              for (let key in parseJson) {
                str += `${key.toUpperCase()} : ${formatValue(
                  parseJson[key],
                  key,
                  module
                )} | `;
              }
              return str;
            }
          } catch {}
          if (key === 'instructions') {
            return Utility.decodeHTML(value);
          }
          return value;
        default:
          return value;
      }
    }
    return '-';
  };
  const getLogRow = (log: any, module: any) => {
    const fieldName = getLogKey(log.fieldName);
    const oldVal =
      formatValue(log.oldVal, log.fieldName, module) === ''
        ? '-'
        : formatValue(log.oldVal, log.fieldName, module);
    const newVal =
      formatValue(log.newVal, log.fieldName, module) === ''
        ? '-'
        : formatValue(log.newVal, log.fieldName, module);

    return (
      <div className="row" style={{ marginBottom: '2px', textAlign: 'start' }}>
        <DKTooltipWrapper
          content={fieldName}
          tooltipClassName="bg-deskera-secondary width-auto"
        >
          <div className="fw-b match-header-field" style={{ width: '120px' }}>
            {fieldName}
          </div>
        </DKTooltipWrapper>
        <div className="fw-b">:&nbsp;</div>
        <DKTooltipWrapper
          content={oldVal}
          tooltipClassName="bg-deskera-secondary width-auto"
        >
          <div
            className="text-red border-radius-s data-grid-badge-color-10 match-header-field"
            style={{ width: '150px', padding: '2px 6px', textAlign: 'right' }}
            dangerouslySetInnerHTML={{ __html: oldVal }}
          ></div>
        </DKTooltipWrapper>
        &nbsp;&#10142;&nbsp;
        <DKTooltipWrapper
          content={newVal}
          tooltipClassName="bg-deskera-secondary width-auto"
        >
          <div
            className="text-green border-radius-s data-grid-badge-color-6 match-header-field"
            style={{ width: '150px', padding: '2px 6px' }}
            dangerouslySetInnerHTML={{ __html: newVal }}
          ></div>
        </DKTooltipWrapper>
      </div>
    );
  };
  const getLogDetails = (auditLog: any) => {
    let filteredLogs;
    if (auditLog && Array.isArray(auditLog.details)) {
      filteredLogs = auditLog.details.filter(
        (log: any) =>
          !(
            MODULE_FIELDS_TO_IGNOR['ALL'].includes(log.fieldName) ||
            MODULE_FIELDS_TO_IGNOR[auditLog.module]?.includes(log.fieldName)
          )
      );
    }
    if (auditLog.action === 'create') {
      switch (auditLog.module) {
        case MODULES_NAME.MRP_JOB_CARD:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'document_seq_code',
              'work_order_seq_code',
              'operation_name',
              'planned_start_date',
              'status'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.WORK_ORDER:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'document_seq_code',
              'work_order_seq_code',
              'operation_name',
              'planned_start_date',
              'planned_end_date',
              'actual_start_date',
              'actual_end_date',
              'delivery_date',
              'created_on',
              'updated_on',
              'status',
              'product_name',
              'manufacture_quantity'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.MRP_JOB_WORK_OUT:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'jwo_code',
              'vendor_code',
              'receive_by_dt',
              'document_dt',
              'due_dt',
              'total_amount',
              'created_dte',
              'updated_dte',
              'receipt_status',
              'billed_status',
              'dispatch_status'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.FORECAST:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'name',
              'created_on',
              'updated_on',
              'to_period',
              'to_date',
              'from_period',
              'from_date',
              'period_frequency',
              'forecast_type'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.MRP_OPERATOR:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'name',
              'email',
              'job_title',
              'fixed_rate',
              'cost_per_hour',
              'address',
              'created_on'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.MRP_OPERATION:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'name',
              'default_workstation',
              'created_on',
              'updated_on',
              'operation_code',
              'operation_time',
              'fixed_rate',
              'cost_per_hour',
              'workstation_name'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.MRP_WORKSTATION:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'name',
              'electricity_cost',
              'created_on',
              'updated_on',
              'consumable_cost',
              'rent_cost',
              'working_hours',
              'workstation_code'
            ].includes(logData.fieldName)
          );
          break;
        case MODULES_NAME.MRP_PRODUCT:
          filteredLogs = filteredLogs.filter((logData: any) =>
            [
              'name',
              'type',
              'purchase_price',
              'sales_price',
              'purchase_account_code',
              'sales_account_code',
              'source',
              'bom_product_cost_details'
            ].includes(logData.fieldName)
          );
          break;
        default:
          filteredLogs = [];
          break;
      }
    }

    return (
      <Fragment>
        {filteredLogs &&
          filteredLogs.map((log: any) => {
            return getLogRow(log, auditLog.module);
          })}
      </Fragment>
    );
  };

  const getHeader = () => {
    return (
      <div
        style={{ zIndex: 1, left: 0 }}
        className="row justify-content-between p-h-r p-v-s bg-gray1 sticky "
      >
        <div className="row">
          <DKLabel text={'Transaction Log'} className="fw-m fs-l" />
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={props.onCancel}
          />
        </div>
      </div>
    );
  };

  const getNoDataView = () => {
    return (
      <div
        className={`column parent-size align-items-center justify-content-center mb-xl ${
          !props.isNoDataAbsolute ? 'position-absolute' : ''
        }`}
        style={{ pointerEvents: 'none' }}
      >
        <DKIcon src={ic_no_data} className="ic-l opacity-20" />
        <DKLabel className="fs-m fw-m mt-l" text={'No data found'} />
        <DKLabel
          className="text-dark-gray mt-s text-align-center"
          text={'No data available for your selection.'}
        />
      </div>
    );
  };

  return (
    <div
      className={`column mobile-max-width-100 parent-width parent-height margin-empty-mobile `}
      ref={gridContainerRef}
    >
      <div className="column mt-s parent-width mb-xl position-relative flex-1">
        {auditLog.columnData && (
          <DKDataGrid
            title={'📋 Transaction Logs'}
            needBoldTheme={true}
            allowRowEdit={false}
            allowColumnEdit={false}
            allowRowAdd={false}
            allowColumnAdd={false}
            allowColumnShift={true}
            allowSearch={false}
            allowFilter={false}
            refresh={false}
            width={
              SideBarService.getContainerWidth() - CONTACT_LEFT_PANEL_WIDTH
            }
            extraWidth={CONTACT_LEFT_PANEL_WIDTH}
            rows={auditLog?.rowData?.length ? auditLog.rowData : []}
            columns={colConfigToShow}
            actionColumnWidth={120}
            allowBulkOperation={false}
            needTrailingColumn={false}
          />
        )}
        {auditLog?.rowData?.length === 0 && getNoDataView()}
      </div>
    </div>
  );
}
