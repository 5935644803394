import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { BulkDeleteResponse } from '../Models/Interfaces';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface WorkoutAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
}

export const defaultConfig: WorkoutAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};
class WorkoutService {
  static apiConfig: WorkoutAPIConfig;
  static abortController: any = null;

  static getWorkoutsByPage() {
    Utility.cancelRequest(WorkoutService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const country = Utility.getTenantSpecificApiCode(
      COMPLIANCE_SPECIFIC_FIELD_NAME.JOB_WORK_OUT
    );

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    const finalEndpoint: string =
      ApiConstants.URL.WORK_OUT.WORK_OUTS + country + queryString;

    WorkoutService.abortController = Utility.createAbortController();
    return http
      .get(`${finalEndpoint}`, {
        signal: WorkoutService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createWorkout(payload: any, country: string) {
    return http2
      .post(`${ApiConstants.URL.WORK_OUT.WORK_OUTS}${country}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateWorkout(payload: any, country: string) {
    const workOutId: string = payload.id;
    return http2
      .put(
        `${ApiConstants.URL.WORK_OUT.UPDATE_WORK_OUTS(country, workOutId)}`,
        payload
      )
      .catch((err: any) => {
        console.error('Error updating order: ', err);
        return Promise.reject(err);
      });
  }

  static deleteWorkout(id: number) {
    return http2
      .delete(`${ApiConstants.URL.WORK_OUT.DELTE_WORK_OUT(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchJobWorkOutDetails(jwoCode: string) {
    return http
      .get(`${ApiConstants.URL.WORK_OUT.FETCH_WORK_OUT_DETAILS(jwoCode)}`)
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static fetchJobWorkOutDetailsByWO(woCode: string) {
    return http
      .get<any, any[]>(
        `${ApiConstants.URL.WORK_OUT.FETCH_WORK_OUT_DETAILS_BY_WO(woCode)}`
      )
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }

  static bulkDelete(docIds: number[]): Promise<BulkDeleteResponse[]> {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: docIds
    };
    return http.delete(ApiConstants.URL.WORK_OUT.BULK_BELETE, config);
  }

  static jobWorkOutDetailByCode(documentCode: string) {
    Utility.cancelRequest(WorkoutService.abortController);
    WorkoutService.abortController = Utility.createAbortController();
    return http
      .get(
        `${ApiConstants.URL.WORK_OUT.FETCH_WORK_OUT_DETAILS(documentCode)}`,
        {
          signal: WorkoutService.abortController.signal
        }
      )
      .catch((err: any) => {
        console.error('Error fetching order: ', err);
        return Promise.reject(err);
      });
  }
}

export default WorkoutService;
