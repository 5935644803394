import React, { Component } from "react";
import "../../index.css";
import { COLOR_LIGHTGRAY, COLOR_LIGHTGRAY2, EditorInfoType, FONT_SIZE } from '../../Constants/Constants';
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import DocumentManager from "../../Manager/DocumentManager";
import { getLocalisedText } from '../../Translate/LanguageManager';
import { HTMLTag } from "../../Utilities/HTMLTag";
import Utility, { getFontSizeClass, getCapitalized, getPageHeight, getVW, getIsFulfillment, getIsGoodsReceipt, getIsJournalEntry } from "../../Utilities/Utility";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import "./css/Templates.css";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate5 extends Component {
    arabicHeaderWidth = '72%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="RowDiv"
                style={{
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}>
                {this.state.data.showCompanyName && this.getCompanyName()}
                <text className={"docFontStyleBold" + getFontSizeClass()}
                    style={{
                        color: this.state.data.themeColor,
                        width: Utility.getIsArabicLang() ? undefined : '100%',
                        textAlign: 'right',
                        ...Utility.getFontStyleProp({fontSize: FONT_SIZE.large}, this.state.data.printScale)
                    }}>
                    {getCapitalized(getLocalisedText(this.state.data.documentType))}
                </text>
            </div>
        );
    }

    getCompanyName() {
        return <text
            className={"docFontStyleBold" + getFontSizeClass()}
            style={{
                width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '70%',
                ...Utility.getFontStyleProp({fontSize: FONT_SIZE.xLarge}, this.state.data.printScale)
            }}>
            {getLocalisedText(Utility.getCompanyName(this.state.data))}
        </text>;
    }

    addTermsNConditionsView() {
        return (
            <div id={HTMLTag.TERMS_NOTE_PREFIX} style={{ bottom: 0, width: '100%' }}>
                {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getTermsAndConditionTitle(getLocalisedText('terms_&_condition')),
                        this.state.data.termsAndCondition,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.termsAndCondition)
                    )}
                {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(20)}
                {(this.state.data.showNotes && this.state.data.notes) &&
                    ComponentManager.getTermsAndConditionOrNotesSection(
                        Utility.getNotesTitle(getIsJournalEntry(this.state.data.type) ? getLocalisedText('memo') : getLocalisedText('notes')),
                        this.state.data.notes,
                        "100%",
                        undefined,
                        undefined,
                        undefined,
                        getVW(Utility.convertToPrintScale(6, this.state.data.printScale)),
                        this.state.data.printScale,
                        Utility.getTnCOrNotesFontSettingsByType(EditorInfoType.notes)
                    )}
            </div>
        )
    }

    render() {
        return (
            <div className='DocumentHolder ColumnDiv' style={{ justifyContent: 'space-between', minHeight: getPageHeight(this.state.data.printScale) }}>
                <div className='parent-width' style={{ width: '100%', paddingBottom: getVW(30) }}>
                    <DKEInvoiceSection
                        data={this.state.data}
                        position={eInvoiceSectionPosition.top}
                    />
                    {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { top: getVW(30), right: 0 })}
                    {this.addCompanyNameAndDocTypeLabels()}
                    {this.state.data.showFrom && this.getCompanyAddress()}
                    {ComponentManager.addVerticalSpace(50)}
                    {this.getShippingBillingAddressSection()}
                    {ComponentManager.addVerticalSpace(40)}
                    {this.getInvoiceDetails()}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                    {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                    {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor />
                    {ComponentManager.addVerticalSpace(20)}
                    {
                        TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                        <>
                            <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                            {ComponentManager.addVerticalSpace(5)}
                        </>
                    }
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {this.getEditableDocumentTable()}
                    {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                    {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                    {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20)}
                    <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor />
                    {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                    <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                        {ComponentManager.addVerticalSpace(30)}
                        {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                    </div>
                </div>
                <div className='parent-width' style={{ width: '100%' }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} isThemeColor />}
                    {this.addTermsNConditionsView()}
                    {Utility.getIsShowApprovalSection() && <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />}
                </div>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
            </div>
        );
    }

    getShippingBillingAddressSection() {
        return <div
            className="RowDiv"
            style={{
                alignItems: "flex-start",
                backgroundColor: "white",
            }}
        >
            {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                this.state.data.billToTitle,
                Utility.getClientBillToAddress(this.state.data),
                "35%",
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.billTo),
                this.state.data.printScale
            )}
            {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "2%" }} />}
            {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                this.state.data.shipToTitle,
                Utility.getClientShipToAddress(this.state.data),
                "35%",
                this.state.data.themeColor,
                false,
                '#000000',
                getVW(6),
                Utility.getOwnerType(this.state.data, AddressType.shipTo),
                this.state.data.printScale
            )}
        </div>;
    }

    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '52%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    getCompanyAddress() {
        return ComponentManager.getDescriptionSection(
            "",
            Utility.getCompanyAddress(this.state.data),
            Utility.getIsArabicLang() ? this.arabicHeaderWidth : "45%",
            'black',
            false,
            '#000000',
            getVW(6),
            Utility.getOwnerType(this.state.data, AddressType.from),
            this.state.data.printScale
        );
    }

    getInvoiceDetails() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    backgroundColor: "white",
                }}
            >
                {this.getDocumentDetailsSection()}
            </div>
        )
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map(element => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }
            return ComponentManager.getDescriptionSection(
                element.label,
                value,
                "20%",
                this.state.data.themeColor,
                undefined,
                undefined,
                undefined,
                undefined,
                this.state.data.printScale
            )
        });
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerTextColor = "#ffffff";
        style.footerTextColor = '#ffffff';
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerBorderColor = this.state.data.themeColor;
        style.itemBackgroundColor = COLOR_LIGHTGRAY;
        style.itemBackgroundColorIsAlternate = true;
        style.itemBorderColor = COLOR_LIGHTGRAY2;
        style.footerBackgroundColor = this.state.data.themeColor;
        style.footerBorderColor = this.state.data.themeColor;
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 5
        style.columnWidths = ['20%', '20%', '20%', '20%', '20%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    this.state.data.themeColor,
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
        </div>;
    }
    
    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
