import {
  DKButton,
  DKLabel,
  removeLoader,
  showAlert,
  showLoader,
  DKSpinner,
  DKInput,
  INPUT_TYPE,
  DKDataGrid
} from 'deskera-ui-library';
import { useEffect, useState, useRef } from 'react';
import ic_barcode_green from '../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../Assets/Icons/ic_barcode_red.svg';
import { MODULE_TYPE, PRODUCT_TYPE } from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchBatchTrackingProducts } from '../../../Redux/Slices/BatchTrackingSlice';
import { fetchSerialTrackingProducts } from '../../../Redux/Slices/SerialTrackingSlice';
import { fetchProductInventoryByID } from '../../../Redux/Slices/WarehouseProductSlice';
import { selectWarehouse } from '../../../Redux/Slices/WarehouseSlice';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import ProductService from '../../../Services/Product';
import BatchTrackingAssignment from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import SerialTrackedAssignment from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import NoneTrackedAssignment from '../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import Utility, { deepClone } from '../../../Utility/Utility';
import {
  calculateAdvancedTrackedReservedQuantities,
  calculateNoneTrackedReservedQuantities,
  mapDataFromNoneTrackPopup,
  mapInventoryToReservedQtyData
} from './WorkOrderHelper';
import useScreenResize from '../../../Hooks/useScreenResize';

export interface SubstituteProductPopUpProps {
  workOrderItem?: any;
  onCancel?: any;
}

export default function SubstitutePopUp(props: any) {
  const getInitialPickedIndexes = () => {
    let indexes: any[] = [];
    props?.workOrderItem?.bomProductSubstitutesDetails?.forEach(
      (item: any, index: any) => {
        if (
          item.isProductPicked ||
          !Utility.isEmpty(item?.warehouseInventoryData)
        ) {
          indexes.push(index);
        }
      }
    );
    return indexes;
  };

  const dispatch = useAppDispatch();
  const warehouseData = useAppSelector(selectWarehouse);

  const selectedProduct = props.workOrderItem;
  const [productRows, setProductRows] = useState<any[]>(
    props.workOrderItem.bomProductSubstitutesDetails
  );
  const [substitutes, setSubstitutes] = useState<any>();
  const [warehouseInventoryData, setWarehouseInventoryData] = useState<any>();
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1);

  // const [selectProductItems, setSelectProductItems] = useState<any[]>([]);

  const [selectedItem, setSelectedItem] = useState<any>();
  const [showSerialTracking, setShowSerialTracking] = useState(false);
  const [showBatchTracking, setShowBatchTracking] = useState(false);
  const [showMultipleWarehouse, setShowMultipleWarehouse] = useState(false);
  const [pickedProductsIndexes, setPickedProductsIndexes] = useState<any[]>(
    getInitialPickedIndexes() ?? []
  );
  const [gridType, setGridType] = useState('available');
  const [searchText, setSearchText] = useState<any>(undefined);
  const loading = false;
  const columnConfig = [
    {
      name: 'Product Code',
      key: 'documentSequenceCode',
      type: INPUT_TYPE.TEXT,
      width: 190,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Product name',
      key: 'productName',
      type: INPUT_TYPE.TEXT,
      width: 290,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'UOM',
      key: 'stockUomString',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Required Quantity',
      key: 'requiredQty',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      name: 'Alloted Quantity',
      key: 'allotedQty',
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    }
  ];
  const [selectedProducts, setSelectedProducts] = useState<any>([]);

  const substituteListContainerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useScreenResize(substituteListContainerRef);

  useEffect(() => {
    if (Utility.isEmpty(props?.workOrderItem?.bomProductSubstitutesDetails)) {
      props.onCancel?.();
      return;
    }

    callFetchProductAPI();
    callGetWarehouseInventoryData();
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(warehouseData?.content)) {
    }
  }, [warehouseData]);

  useEffect(() => {
    if (!Utility.isEmpty(warehouseInventoryData)) {
      setWarehouseDetailsToProducts(warehouseInventoryData);
    }
  }, [warehouseInventoryData]);

  useEffect(() => {
    if (!Utility.isEmpty(productRows)) {
      /* let selectedcopy = productRows.filter((item: any, index: any) =>
        pickedProductsIndexes?.includes(index)
      );
      setSelectProductItems(selectedcopy); */

      setSelectedProducts(productRows?.filter((item: any) => item?.selected));
    }
  }, [productRows, pickedProductsIndexes]);

  const getAvailableQty = (mappedResponse: any, productItem: any) => {
    const availableQty =
      mappedResponse?.[productItem.productCode ?? productItem.productId]
        ?.availableQuantity ?? 0;
    const reservedQty =
      mappedResponse?.[productItem.productCode ?? productItem.productId]
        ?.reservedQuantity ?? 0;

    const qty = availableQty - reservedQty;

    return qty ?? 0;
  };

  const callFetchProductAPI = () => {
    let productIds = selectedProduct?.bomProductSubstitutesDetails?.map(
      (item: any) => {
        return {
          productCode: props.isEditMode
            ? item.productCode ?? item.productId
            : item.productId,
          quantity: calculateRequiredQuantity()
        };
      }
    );
    MRPProductsService.getProductShortInfo(productIds)
      .then((response: any) => {
        let mappedResponse: any = {};
        response.forEach((item: any) => {
          mappedResponse[`${item.pid}`] = item;
        });
        let copyProductRows = [
          ...productRows.map((productItem: any, index: number) => {
            const substituteItemFromWorkOrderItem =
              props?.workOrderItem?.bomProductSubstitutesDetails?.find(
                (substituteItem: any) => {
                  return substituteItem.productId === productItem.productId;
                }
              );
            let productItemCopy = {
              ...productItem,
              productItemIndex: index,
              productId: productItem.productCode ?? productItem.productId,
              productName:
                mappedResponse?.[
                  productItem.productCode ?? productItem.productId
                ]?.name || '',
              availableQuantity: getAvailableQty(mappedResponse, productItem),
              productQuantity: productItem?.productQuantity ?? 0,
              stockUomString:
                Utility.getUOMForStockUOMId(productItem?.stockUom)?.name ?? '',
              requiredQty: calculateRequiredQuantity() || 0,
              allotedQty: getAllotedQty(substituteItemFromWorkOrderItem) ?? 0,
              selected:
                substituteItemFromWorkOrderItem?.selected ??
                substituteItemFromWorkOrderItem?.isProductPicked ??
                false // isProductPicked is used from API response, selected is used from GRID keys
            };

            return productItemCopy;
          })
        ];
        setProductRows(copyProductRows);
        setSubstitutes(mappedResponse);
      })
      .catch((err: any) => {
        console.log(err, 'product fetch failed');
      });
  };

  const setWarehouseDetailsToProducts = (response: any) => {
    let firstWarehouse: any;
    if (!Utility.isEmpty(response)) {
      firstWarehouse = response[0];
    } else {
      return;
    }
    let copyProductRows = [...productRows];
    copyProductRows = copyProductRows.map((productRowItem: any) => {
      let copyProductItem = { ...productRowItem };
      copyProductItem.warehouse = firstWarehouse;
      let warehouseInventory = response?.find(
        (item: any) => item.code === firstWarehouse?.code
      );
      copyProductItem.availableQuantity =
        (warehouseInventory?.productAvailableQuantity?.[
          copyProductItem?.productId
        ] || 0) -
        (warehouseInventory?.productReservedQuantity?.[
          copyProductItem?.productId
        ] || 0);
      return copyProductItem;
    });
    setProductRows(copyProductRows);
  };

  const callGetWarehouseInventoryData = () => {
    let productIds = productRows?.map((item: any) => item.productId);
    ProductService.fetchWarehouseProductsByID(productIds)
      .then((response: any) => {
        setWarehouseInventoryData(response?.warehouses);
        // setWarehouseDetailsToProducts(response?.warehouses);
      })
      .catch((err) => {
        console.log(err, 'product fetch failed');
      });
  };

  const calculateReservedQuantitiesData = (workOrderItem: any): any[] => {
    const originalWorkOrderItem =
      props.existingWorkOrderItem?.bomProductSubstitutesDetails?.find(
        (wo: any) => wo?.productId === workOrderItem?.productId
      );
    let reservedQuantities: any[] = [];
    const inventoryData =
      workOrderItem?.advancedTracking === ADVANCE_TRACKING.NONE
        ? calculateNoneTrackedReservedQuantities(
            workOrderItem,
            originalWorkOrderItem
          )
        : calculateAdvancedTrackedReservedQuantities(
            workOrderItem,
            originalWorkOrderItem
          );
    const updatedWorkOrderItem = {
      ...workOrderItem,
      itemName: {
        pid: workOrderItem.productId,
        inventory: { availableQuantity: workOrderItem.availableQuantity },
        advancedTracking: workOrderItem.advancedTracking
      }
    };
    reservedQuantities = inventoryData.map((inventoryItem: any) =>
      mapInventoryToReservedQtyData(inventoryItem, updatedWorkOrderItem)
    );
    return reservedQuantities;
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row pop-header-drag-handle">
          <DKLabel
            // text={'Substitute Product'}
            text={selectedProduct?.itemName?.name}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto gap-2">
          <DKButton
            title={'Close'}
            className="bg-white border-m"
            onClick={() => {
              props.onCancel();
            }}
          />
          <DKButton
            className={'bg-button text-white'}
            title={'Save'}
            onClick={() => {
              let copySelectedProduct = { ...selectedProduct };
              let assignedSubstitutes: any[] = [];
              productRows.forEach((productRow: any, productRowIndex: any) => {
                let productObject = substitutes?.[productRow?.productId];

                productRow = {
                  ...productRow,
                  productQuantity: productRow.productQuantity ?? 0,
                  isProductPicked: productRow?.selected
                };

                delete productRow.selected;

                if (!productRow.advancedTracking) {
                  productRow.advancedTracking = productObject?.advancedTracking;
                }
                if (!productRow.costPerUnit) {
                  productRow.costPerUnit = productObject?.inventoryPrice ?? 0;
                }
                if (!productRow.inventoryPrice) {
                  productRow.inventoryPrice =
                    productObject?.inventoryPrice ?? 0;
                }

                if (!Utility.isEmpty(productRow.warehouseInventoryData)) {
                  assignedSubstitutes.push({
                    ...productRow,
                    advancedTracking: productObject?.advancedTracking,
                    inventoryPrice: productObject?.inventoryPrice,
                    costPerUnit: productObject?.inventoryPrice,
                    documentSequenceCode: productObject?.documentSequenceCode,
                    productCode: productObject?.pid,
                    productQuantity: calculateRequiredQuantity(),
                    sourceWarehouseCode: '',
                    warehouseInventoryData: productRow.warehouseInventoryData,
                    reservedQuantitiesData:
                      calculateReservedQuantitiesData(productRow)
                  });
                } else {
                  assignedSubstitutes.push(productRow);
                }
              });
              copySelectedProduct.bomProductSubstitutesDetails =
                assignedSubstitutes;
              props?.onSuccess(copySelectedProduct);
            }}
          />
        </div>
      </div>
    );
  };

  const getTitleValue = (title: string, value: any) => {
    return (
      <div className="column parent-width gap-2">
        <DKLabel className="fw-m text-app-color" text={title} />
        <DKLabel text={value} />
      </div>
    );
  };

  const calculateRequiredQuantity = () => {
    let requiredQuantity = selectedProduct?.requiredQty;
    let totalAlloted =
      selectedProduct?.warehouseInventoryData?.reduce(
        (prev: number, current: any) => {
          return prev + Number(current.quantity);
        },
        0
      ) || 0;
    return Number(requiredQuantity - totalAlloted) || 0;
  };

  const calculateRequiredQuantityForAssignment = () => {
    let requiredQuantity = selectedProduct?.requiredQty;
    let totalAlloted =
      selectedProduct?.warehouseInventoryData?.reduce(
        (prev: number, current: any) => {
          return prev + Number(current.quantity);
        },
        0
      ) || 0;
    let allWarehouseinventoryData = productRows?.reduce(
      (prev: any[], current: any) => {
        if (selectedItem.productId === current.productId) {
          return [...prev];
        } else {
          return [...prev, ...(current?.warehouseInventoryData || [])];
        }
      },
      []
    );
    let totalAssignmetSubstitutes = allWarehouseinventoryData?.reduce(
      (prev: number, current: any) => Number(prev) + Number(current?.quantity),
      0
    );
    return (
      Number(requiredQuantity - totalAlloted - totalAssignmetSubstitutes) || 0
    );
  };

  const calculateAssignedQuantity = (item: any, mainIndex?: number) => {
    let assigend =
      item?.warehouseInventoryData?.reduce(
        (prevAssQty: number, currentWarehouse: any) => {
          return Number(prevAssQty) + Number(currentWarehouse.quantity);
        },
        0
      ) || 0;
    return assigend;
  };

  const calculateTotalAssignedQuantity = () => {
    let assignedQuantity = productRows.reduce((prev: number, current: any) => {
      let assQuantity = 0;
      if (!Utility.isEmpty(current?.warehouseInventoryData)) {
        assQuantity = current?.warehouseInventoryData?.reduce(
          (prevAssQty: number, currentWarehouse: any) => {
            return Number(prevAssQty) + Number(currentWarehouse.quantity);
          },
          0
        );
      }
      return Number(prev) + Number(assQuantity);
    }, 0);
    return assignedQuantity;
  };

  const getProductDetailView = () => {
    return (
      <div className="row justify-content-evenly">
        {getTitleValue(
          'Product Code',
          selectedProduct?.itemName?.documentSequenceCode
        )}
        {getTitleValue('Product Name', selectedProduct?.itemName?.name)}
        {getTitleValue('Required Quantity', calculateRequiredQuantity())}
        {getTitleValue('Assigned Quantity', calculateTotalAssignedQuantity())}
      </div>
    );
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const loadBatchTrackingProducts = async (code: any) => {
    showLoader();
    try {
      const data = await dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
      if (data) {
        removeLoader();
      }
    } catch (err) {
      removeLoader();
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const setSerialTracking = (item: any) => {
    setSelectedItem(item);
    loadSerialTrackingProducts(item?.productId);
    setShowSerialTracking(true);
  };

  const setBatchTracking = (item: any) => {
    setSelectedItem(item);
    loadBatchTrackingProducts(item?.productId);
    setShowBatchTracking(true);
  };

  const setNoneTracking = (item: any) => {
    loadProductInventoryById([item?.productId]);
    setSelectedItem(item);
    setShowMultipleWarehouse(true);
    // loadSerialTrackingProducts(item?.itemName?.pid);
    // (true);
  };

  const getFlatArrayOfSerialSelections = (obj: any, type: ADVANCE_TRACKING) => {
    let mapped: any[];
    if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
      mapped = obj?.map((item: any) => {
        return item?.advancedTrackingData?.map((lineItem: any) => {
          return {
            ...item,
            ...lineItem,
            warehouseName: item.warehouseName,
            warehouseCode: item.warehouseCode
          };
        });
      });
    } else {
      mapped = obj?.map((item: any) => {
        return item?.advancedTrackingData;
      });
    }

    let flatArray = mapped?.flat() || [];
    return flatArray;
  };

  const createProductItemForTracking = (type: ADVANCE_TRACKING) => {
    let selectedProduct = substitutes?.[selectedItem?.productId];
    return {
      product: selectedProduct,
      productCode: selectedItem?.productId,
      documentSequenceCode: selectedProduct?.documentSequenceCode,
      requiredQuantity: calculateRequiredQuantityForAssignment(),
      productQuantity: calculateRequiredQuantityForAssignment(),
      advancedTracking: selectedProduct?.advancedTracking,
      advancedTrackingFulfilmentData: getFlatArrayOfSerialSelections(
        selectedItem?.warehouseInventoryData,
        type
      ),
      isQuickCommit: false
    };
  };

  const getDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehouseData &&
      warehouseData.content &&
      warehouseData.content.filter(
        (item: any) => item.active && !item.rejected
      );

    let selectedWarehouse = activeWarehouses?.find(
      (warehouse: any) => warehouse.primary === true
    );
    return selectedWarehouse?.code;
  };
  const getExistingDataForSelectedProduct = () => {
    return props.existingWorkOrderItem?.bomProductSubstitutesDetails?.find(
      (wo: any) =>
        wo?.productId === productRows[selectedProductIndex]?.productId
    );
  };
  const getFulfillmentPopUp = () => {
    return (
      <>
        {showSerialTracking && (
          <SerialTrackedAssignment
            isMRP={true}
            itemDetails={createProductItemForTracking(ADVANCE_TRACKING.SERIAL)}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={getDefaultActiveWarehouse()}
            existingWarehouseInventoryData={deepClone(
              getExistingDataForSelectedProduct()?.warehouseInventoryData || []
            )}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props?.targetWarehouse
                : null
            }
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfill: any
            ) => {
              let copyProductItems: any[] = [...productRows];
              let editItem = copyProductItems[selectedProductIndex];
              editItem.isQuickCommit = isQuickCommit;
              let pickedProductsArr = [...pickedProductsIndexes];
              if (!pickedProductsArr?.includes(selectedProductIndex)) {
                pickedProductsArr.push(selectedProductIndex);
              }
              setPickedProductsIndexes(pickedProductsArr);
              editItem.warehouseInventoryData = data?.map((item: any) => ({
                ...item,
                quantity: item?.qtyToFulfil ?? 0
              }));
              copyProductItems[selectedProductIndex] = editItem;
              setProductRows(copyProductItems);
              setShowSerialTracking(false);
            }}
            onClose={() => {
              setShowSerialTracking(false);
            }}
          ></SerialTrackedAssignment>
        )}
        {showBatchTracking && (
          <BatchTrackingAssignment
            isMrpFlow={true}
            itemDetails={createProductItemForTracking(ADVANCE_TRACKING.BATCH)}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={getDefaultActiveWarehouse()}
            existingWarehouseInventoryData={deepClone(
              getExistingDataForSelectedProduct()?.warehouseInventoryData || []
            )}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props?.targetWarehouse
                : null
            }
            onBatchSave={(data: any, quantityToFulfill: any) => {
              let copyProductItems: any[] = [...productRows];
              let editItem = copyProductItems[selectedProductIndex];
              let pickedProductsArr = [...pickedProductsIndexes];
              if (!pickedProductsArr?.includes(selectedProductIndex)) {
                pickedProductsArr.push(selectedProductIndex);
              }
              setPickedProductsIndexes(pickedProductsArr);
              editItem.warehouseInventoryData = data?.map((item: any) => ({
                ...item,
                quantity: item?.qtyToFulfil ?? 0
              }));
              copyProductItems[selectedProductIndex] = editItem;
              setProductRows(copyProductItems);
              setShowBatchTracking(false);
            }}
            onClose={() => {
              setShowBatchTracking(false);
            }}
          ></BatchTrackingAssignment>
        )}
        {showMultipleWarehouse && (
          <NoneTrackedAssignment
            details={{
              ...createProductItemForTracking(ADVANCE_TRACKING.NONE),
              parentQuantityToFulfill: calculateRequiredQuantityForAssignment(),
              pendingQuantity: calculateRequiredQuantityForAssignment(),
              warehouseInventoryData:
                productRows[selectedProductIndex]?.warehouseInventoryData ?? []
            }}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={getDefaultActiveWarehouse()}
            existingWarehouseInventoryData={deepClone(
              getExistingDataForSelectedProduct()?.warehouseInventoryData || []
            )}
            taggedWarehouse={
              Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()
                ? props?.targetWarehouse
                : null
            }
            onSave={(data: any) => {
              if (data && data.length > 0) {
                let copyProductItems: any[] = [...productRows];
                let editItem = copyProductItems[selectedProductIndex];
                let warehouseInventoryData = data.map(
                  mapDataFromNoneTrackPopup
                );
                let pickedProductsArr = [...pickedProductsIndexes];
                if (!pickedProductsArr?.includes(selectedProductIndex)) {
                  pickedProductsArr.push(selectedProductIndex);
                }
                setPickedProductsIndexes(pickedProductsArr);
                editItem.warehouseInventoryData = warehouseInventoryData;
                copyProductItems[selectedProductIndex] = editItem;
                setProductRows(copyProductItems);
                setShowMultipleWarehouse(false);
              }
            }}
            onCancel={() => {
              setShowMultipleWarehouse(false);
            }}
          ></NoneTrackedAssignment>
        )}
      </>
    );
  };

  const onTrackingActionClick = (product: any, index: number) => {
    let selectedProductObject = substitutes?.[product.productId];
    if (
      selectedProductObject?.type === PRODUCT_TYPE.TRACKED ||
      selectedProductObject?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    ) {
      if (
        calculateTotalAssignedQuantity() < calculateRequiredQuantity() ||
        calculateAssignedQuantity(product, index) > 0
      ) {
        setSelectedProductIndex(index);
        switch (selectedProductObject?.advancedTracking) {
          case ADVANCE_TRACKING.BATCH:
            setBatchTracking(product);
            break;
          case ADVANCE_TRACKING.SERIAL:
            setSerialTracking(product);
            break;
          case ADVANCE_TRACKING.NONE:
            setNoneTracking(product);
            break;
        }
      } else {
        showAlert('Warning', 'Already assigned required quantity.');
      }
    }
  };

  /*
  const getAllocatedTitle = (item: any, inndex: number) => {
    let totalAlloted = item?.warehouseInventoryData?.reduce(
      (prev: number, current: any) => {
        return prev + current.quantity;
      },
      0
    );
    return `(${totalAlloted || 0}/${calculateRequiredQuantity() || 0})`;
  };

  const getChipBGColor = (item: any, index: any, isSelected: boolean) => {
    if (pickedProductsIndexes?.includes(index) || isSelected) {
      return 'bg-chip-blue border-blue text-blue';
    } else {
      return 'bg-white';
    }
  };

  const validateBothQuantities = () => {
    if (calculateTotalAssignedQuantity() === calculateRequiredQuantity()) {
      showAlert('Warning', 'Already assigned required quantity.');
      return true;
    }
    return false;
  };

  const getProductChips = (isSelected = false) => {
    let records = isSelected ? selectProductItems : productRows;
    return (
      <div className="row flex-wrap gap-2 bg-gray1 p-2 border-radius-s overflow-scroll hide-scroll-bar">
        {records?.map((item: any, index: number) => {
          const substituteHTML = `<span class="fs-s text-gray">(${
            item?.documentSequenceCode ?? ''
          })</span>`;
          return (
            <div
              className={`row cursor-hand p-h-s p-v-xs border-radius-s gap-2 width-auto ${getChipBGColor(
                item,
                index,
                isSelected
              )}`}
              style={{ paddingRight: !isSelected ? 0 : 8 }}
              onClick={() => {
                if (validateBothQuantities() || isSelected) {
                  return;
                }
                let pickedProductsArr = [...pickedProductsIndexes];
                if (!pickedProductsArr?.includes(index)) {
                  pickedProductsArr.push(index);
                } else {
                  if (!Utility.isEmpty(item?.warehouseInventoryData)) {
                    return;
                  }
                  const foundIndex = pickedProductsArr?.findIndex(
                    (itemIndex: any) => itemIndex === index
                  );
                  pickedProductsArr.splice(foundIndex, 1);
                }
                setPickedProductsIndexes(pickedProductsArr);
              }}
            >
              <DKLabel text={`${item.productName} ${substituteHTML}`} />

              <DKLabel text={getAllocatedTitle(item, index)} />
              {!isSelected && (
                <DKButton
                  className="p-0"
                  title={''}
                  icon={
                    calculateAssignedQuantity(item, index) > 0 ||
                    item?.isQuickCommit
                      ? ic_barcode_green
                      : ic_barcode_red
                  }
                  onClick={(e: any) => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }

                    if (
                      props?.isEditMode &&
                      pickedProductsIndexes.length !== 0 &&
                      !pickedProductsIndexes?.includes(index)
                    ) {
                      showAlert(
                        'Please note',
                        'You can only allocate quantities from selected substitutes, kindly click on the product chips to select.'
                      );
                    } else if (
                      props?.isEditMode &&
                      pickedProductsIndexes?.includes(index)
                    ) {
                      onTrackingActionClick(item, index);
                    } else if (!props?.isEditMode) {
                      onTrackingActionClick(item, index);
                    } else {
                      onTrackingActionClick(item, index);
                    }
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };
  */

  ////////////////getSubstituteProductGrid - start/////////////////////////
  const getAllotedQty = (itemRow: any) => {
    let qty =
      itemRow?.warehouseInventoryData?.reduce((prev: number, current: any) => {
        return prev + current.quantity;
      }, 0) ?? 0;

    return qty;
  };

  const getSubstituteProductGrid = () => {
    const getTypeField = () => {
      return (
        <div
          className="row width-auto bg-gray1 border-radius-s border-m p-h-s p-v-xs"
          style={{ gap: 10 }}
        >
          <DKButton
            title={['Available']}
            className={
              gridType === 'available'
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            onClick={() => {
              setGridType('available');
            }}
          />
          <DKButton
            className={
              gridType === 'selected'
                ? 'bg-white border rounded-sm shadow-s-1 fw-m'
                : ''
            }
            style={{
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10
            }}
            title={`Picked (${selectedProducts?.length || 0})`}
            onClick={() => {
              setGridType('selected');
            }}
          />
        </div>
      );
    };

    const getPullThroughProductsView = () => {
      let height = window.innerHeight - 50;

      const onSearch = (searchText: string) => {
        setSearchText(searchText);
      };

      const getFilteredSubstituteProducts = (key: any) => {
        const lowerCasesSearchText = searchText?.toLowerCase();
        let filteredProducts = searchText
          ? [...key]?.filter(
              (productItem: any) =>
                productItem?.productName
                  ?.toLowerCase()
                  ?.includes(lowerCasesSearchText) ||
                productItem?.productId
                  ?.toLowerCase()
                  ?.includes(lowerCasesSearchText) ||
                productItem?.documentSequenceCode
                  ?.toLowerCase()
                  ?.includes(lowerCasesSearchText)
            )
          : [...key];
        return filteredProducts;
      };

      const getGridRows = (type: string) => {
        if (type === 'available') {
          return getFilteredSubstituteProducts(productRows);
        } else {
          return getFilteredSubstituteProducts(selectedProducts);
        }
      };

      const onRowSelected = (selectedRow: any, isAllSelected: boolean) => {
        let copyProductRows = [...productRows];
        copyProductRows = copyProductRows?.map((item: any, index: number) => {
          if (
            Utility.isNotEmpty(selectedRow) &&
            item?.productId === selectedRow?.rowData?.productId
          ) {
            item = {
              ...item,
              selected: selectedRow?.rowData?.selected
            };
          }

          //all rows selected/de-selected
          if (Utility.isEmpty(selectedRow)) {
            item = {
              ...item,
              selected: Utility.isNotEmpty(item?.warehouseInventoryData)
                ? true
                : isAllSelected
            };
          }
          return item;
        });

        setProductRows(copyProductRows);
      };

      const getIsAllRowSelected = () => {
        return gridType === 'available'
          ? productRows?.every((item: any) => item?.selected)
          : selectedProducts?.length > 0
          ? selectedProducts?.every((item: any) => item?.selected ?? false)
          : false;
      };

      return (
        <div
          className="column parent-width overflow-scroll hide-scroll-bar"
          style={{ maxHeight: height }}
        >
          <DKDataGrid
            title={''}
            filterTableName={'ProductsTablePopup'}
            needShadow={false}
            needBorder={true}
            allowRowEdit={true}
            allowColumnEdit={false}
            allowRowAdd={false}
            allowColumnAdd={false}
            allowColumnDelete={false}
            allowColumnShift={false}
            allowColumnSort={false}
            allowSearch={false}
            buttons={[]}
            needTrailingColumn={true}
            allowDataExport={true}
            columns={[
              ...columnConfig,
              {
                id: 'action',
                key: 'action',
                name: 'Allocation',
                type: INPUT_TYPE.BUTTON,
                width: 150,
                options: []
              }
            ]}
            rows={deepClone(getGridRows(gridType))?.map(
              (itemRow: any, index: number) => {
                itemRow = {
                  ...itemRow,
                  allotedQty: getAllotedQty(itemRow),
                  rowButtons:
                    itemRow?.selected &&
                    (!props.workOrderItem?.itemName?.level ||
                      props.workOrderItem?.itemName?.level === 1)
                      ? [
                          {
                            title: '',
                            className: 'p-0',
                            icon:
                              calculateAssignedQuantity(
                                itemRow,
                                itemRow?.productItemIndex
                              ) > 0 || itemRow?.isQuickCommit
                                ? ic_barcode_green
                                : ic_barcode_red,
                            onClick: (data: any) => {
                              onTrackingActionClick(
                                itemRow,
                                itemRow?.productItemIndex
                              );
                            }
                          }
                        ]
                      : []
                };
                return itemRow;
              }
            )}
            // currentPage={gridType === 'available' ? currentPage : 1}
            // totalPageCount={gridType === 'available' ? totalPages : 1}
            // onPrevPageTapped={() => {
            //   onPagination(currentPage - 1);
            // }}
            // onNextPageTapped={() => {
            //   onPagination(currentPage + 1);
            // }}
            onSearch={onSearch}
            onPaginationClick={(requestedPageNumber: number) => {}}
            allowBulkOperation={true}
            onRowUpdate={(data: any) => {}}
            onRowSelect={(selectedRow: any) => {
              if (
                Utility.isNotEmpty(
                  selectedRow?.rowData?.warehouseInventoryData
                ) &&
                !selectedRow?.rowData?.selected
              ) {
                showAlert('Warning', 'Already assigned required quantity.');
                return;
              }
              onRowSelected(selectedRow, false);
            }}
            isAllRowSelected={getIsAllRowSelected()}
            onAllRowSelect={(data: any) => {
              if (gridType === 'selected' && selectedProducts?.length === 0) {
                return;
              }
              onRowSelected(null, !getIsAllRowSelected());
            }}
            width={width}
          />
        </div>
      );
    };

    return (
      <div className="column parent-size overflow-scroll hide-scroll-bar">
        <div className="row justify-content-between">
          <div className="row justify-content-start gap-2">
            {getTypeField()}
            {loading && <DKSpinner />}
          </div>
          <div
            className="row"
            style={{
              width: 250
            }}
          >
            <DKInput
              className="column"
              type={INPUT_TYPE.TEXT}
              value={searchText}
              placeholder={
                props.title ? 'Search product...' : 'Search Substitutes...'
              }
              onChange={(searchText: any) => {
                setSearchText(searchText);
              }}
            />
          </div>
        </div>
        {getPullThroughProductsView()}
        {/* {getPaginationView()} */}
        {/* {showExpandedDetails()} */}
      </div>
    );
  };
  ////////////////getSubstituteProductGrid - end/////////////////////////

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            maxWidth: '90%',
            maxHeight: '98%',
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-width p-4 gap-2 overflow-y-scroll hide-scroll-bar">
            {getProductDetailView()}
            <DKLabel
              className="fs-m fw-m text-black mt-m"
              text={'Assign/Pick from available substitutes'}
            />
            {getSubstituteProductGrid()}
            {/* {getProductChips()} */}
            {/* {pickedProductsIndexes?.length > 0 && (
              <div className="column parent-width gap-2 mt-2">
                <div className="row gap-1 ">
                  <span
                    className="p-s border-radius-s fw-m cursor-hand"
                    style={{
                      backgroundColor: 'rgb(235, 243, 255)'
                    }}
                    onClick={() => {
                      setShowSelectedExpanded(!showSelectedExpanded);
                    }}
                  >
                    {pickedProductsIndexes?.length} substitutes
                  </span>
                  <span>selected</span>
                  <DKLabel
                    text="(Click the product chips to choose the product for substitution allocation.)"
                    className="fs-s fw-b"
                  />
                </div>
                {showSelectedExpanded && getProductChips(true)}
              </div>
            )} */}
          </div>
        </div>
        {getFulfillmentPopUp()}
      </div>
    </DynamicPopupWrapper>
  );
}
