import { LocalizedKeys } from './Localization';

export const usLocalizedKeys: any = {
  quote: 'estimate',
  Quote: 'Estimate',
  quotes: 'estimates',
  cheque: 'check',
  quotation: 'estimate',
  'Direct Fulfillment of Quotation': 'Direct Fulfillment of Estimate',
  organisation: 'organization',
  'Fulfill this Quote in full before converting in to a Full Invoice':
    'Fulfill this Estimate in full before converting in to a Full Invoice'
};
