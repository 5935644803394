import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ERROR } from '../../Constants/Constant';
import DashboardService from '../../Services/Dashboard';
import { RootState } from '../Store';

export interface DashboardState {
  //   data: any;
  sellDashboard: any;
  buyDashboard: any;
  agedReceivable: any;
  cashFlow: any;
  bankAccount: any;
  pnl: any;
  banks: any;
  sellDashboardFilter: any;
  buyDashboardFilter: any;
  agedReceivableDashboardFilter: any;
  pnlDashboardFilter: any;
  cashFlowFilter: any;
}

const initialState: DashboardState = {
  //   data: {} as any,
  sellDashboard: {} as any,
  buyDashboard: {} as any,
  agedReceivable: {} as any,
  cashFlow: {} as any,
  bankAccount: {} as any,
  pnl: {} as any,
  banks: {} as any,
  sellDashboardFilter: {} as any,
  buyDashboardFilter: {} as any,
  agedReceivableDashboardFilter: {} as any,
  pnlDashboardFilter: {} as any,
  cashFlowFilter: {} as any
};

export const setCashflowConfig = createAsyncThunk(
  'cashFlowFilter',
  async (data: any) => {
    return { config: data };
  }
);

export const setSellFilterConfig = createAsyncThunk(
  'sellFilter',
  async (data: any) => {
    return { config: data };
  }
);

export const setBuyFilterConfig = createAsyncThunk(
  'buyFilter',
  async (data: any) => {
    return { config: data };
  }
);
export const setAgedReceivableFilterConfig = createAsyncThunk(
  'agedReceivableFilter',
  async (data: any) => {
    return { config: data };
  }
);
export const setPnlFilterConfig = createAsyncThunk(
  'pnlFilter',
  async (data: any) => {
    return { config: data };
  }
);

export const getSellDashboard = createAsyncThunk('dashboardSell', async () => {
  const response = await DashboardService.getBuySellDashboard();
  return response;
});
export const getBuyDashboard = createAsyncThunk('dashboardBuy', async () => {
  const response = await DashboardService.getBuySellDashboard();
  return response;
});
export const getAgedReceivable = createAsyncThunk(
  'dashbordAgedReceivables',
  async () => {
    try {
      const response = await DashboardService.getAgedReceivable();
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const getCashFlow = createAsyncThunk('dashboardCashFlows', async () => {
  try {
    const response = await DashboardService.getCashFlow();
    return response;
  } catch (error) {
    return error;
  }
});
export const getBankAccount = createAsyncThunk('dashboardBank', async () => {
  const response = await DashboardService.getBankAccount();
  return response;
});
export const getPNL = createAsyncThunk('dashboardPnl', async () => {
  try {
    const response = await DashboardService.getPNL();
    return response;
  } catch (error) {
    return error;
  }
});

export const fetchDashboardBanksList = createAsyncThunk(
  'fetchBanks',
  async () => {
    try {
      const response = await DashboardService.fetchDashboardBanksList();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const DashboardSlice = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSellDashboard.pending, (state) => {})
      .addCase(getSellDashboard.fulfilled, (state, action) => {
        state.sellDashboard = action.payload;
      })
      .addCase(getBuyDashboard.pending, (state) => {})
      .addCase(getBuyDashboard.fulfilled, (state, action) => {
        state.buyDashboard = action.payload;
      })
      .addCase(getAgedReceivable.pending, (state) => {})
      .addCase(getAgedReceivable.fulfilled, (state, action) => {
        state.agedReceivable = action.payload;
      })
      .addCase(getCashFlow.pending, (state) => {})
      .addCase(getCashFlow.fulfilled, (state, action) => {
        state.cashFlow = action.payload;
      })
      .addCase(getBankAccount.pending, (state) => {})
      .addCase(getBankAccount.fulfilled, (state, action) => {
        state.bankAccount = action.payload;
      })
      .addCase(getPNL.pending, (state) => {})
      .addCase(getPNL.fulfilled, (state, action) => {
        state.pnl = action.payload;
      })
      .addCase(setSellFilterConfig.pending, (state) => {})
      .addCase(setSellFilterConfig.fulfilled, (state, action) => {
        state.sellDashboardFilter = action.payload;
      })
      .addCase(setCashflowConfig.pending, (state) => {})
      .addCase(setCashflowConfig.fulfilled, (state, action) => {
        state.cashFlowFilter = action.payload;
      })
      .addCase(setBuyFilterConfig.pending, (state) => {})
      .addCase(setBuyFilterConfig.fulfilled, (state, action) => {
        state.buyDashboardFilter = action.payload;
      })
      .addCase(setAgedReceivableFilterConfig.pending, (state) => {})
      .addCase(setAgedReceivableFilterConfig.fulfilled, (state, action) => {
        state.agedReceivableDashboardFilter = action.payload;
      })
      .addCase(setPnlFilterConfig.pending, (state) => {})
      .addCase(setPnlFilterConfig.fulfilled, (state, action) => {
        state.pnlDashboardFilter = action.payload;
      })
      .addCase(fetchDashboardBanksList.pending, (state) => {})
      .addCase(fetchDashboardBanksList.fulfilled, (state, action) => {
        state.banks = action.payload;
      });
  }
});

export const selectSellDashboard = (state: RootState) =>
  state.dashboards.sellDashboard;
export const selectBuyDashboard = (state: RootState) =>
  state.dashboards.buyDashboard;
export const selectAgedReceivable = (state: RootState) =>
  state.dashboards.agedReceivable;
export const selectCashFlow = (state: RootState) => state.dashboards.cashFlow;
export const selectBankAccount = (state: RootState) =>
  state.dashboards.bankAccount;
export const selectPnl = (state: RootState) => state.dashboards.pnl;
export const selectDashboardBanks = (state: RootState) =>
  state.dashboards.banks;
export const selectSellFilterConfig = (state: RootState) =>
  state.dashboards.sellDashboardFilter;
export const selectBuyFilterConfig = (state: RootState) =>
  state.dashboards.buyDashboardFilter;
export const selectAgedFilterConfig = (state: RootState) =>
  state.dashboards.agedReceivableDashboardFilter;
export const selectPnlFilterConfig = (state: RootState) =>
  state.dashboards.pnlDashboardFilter;
export const selectCashflowConfig = (state: RootState) =>
  state.dashboards.cashFlowFilter;

export default DashboardSlice.reducer;
