import { TableColumnPopupType } from "../Constants/Constants"
import { getLocalisedText } from "../Translate/LanguageManager"

export default class AdditionalChargeParser {
    static getAdditionalCharge(dataFromAPI, returnData) {
        var additionalFooterData = []
        if (dataFromAPI.additionalCharges !== undefined && dataFromAPI.additionalCharges !== null) {
            if (dataFromAPI.additionalCharges.globalDiscounts && dataFromAPI.additionalCharges.globalDiscounts.length > 0) {
                dataFromAPI.additionalCharges.globalDiscounts.forEach(element => {
                    additionalFooterData.push(this.setFooterObject(TableColumnPopupType.globalDiscount, element.name, element.amount, 'parent'));
                });
            } else {
                if (dataFromAPI.additionalCharges.globalDiscount !== undefined && dataFromAPI.additionalCharges.globalDiscount !== null) {
                    if (dataFromAPI.additionalCharges.globalDiscount.amount > 0) {
                        var name = getLocalisedText('pre_tax')
                        if (!dataFromAPI.additionalCharges.globalDiscount.isSubTotalOnly) {
                            name = getLocalisedText('post_tax')
                        }

                        let label = getLocalisedText('global_discount') + ' (' + name + ')'
                        if (dataFromAPI.additionalCharges.globalDiscount.isPercent) {
                            label = label + ' (' + dataFromAPI.additionalCharges.globalDiscount.percent + '%) '
                            additionalFooterData.push(this.setFooterObject(TableColumnPopupType.globalDiscount, label, dataFromAPI.additionalCharges.globalDiscount.amount, 'parent', true))
                        }
                        else {
                            additionalFooterData.push(this.setFooterObject(TableColumnPopupType.globalDiscount, label, dataFromAPI.additionalCharges.globalDiscount.amount, 'parent', true))
                        }
                    }
                }
            }

            if (dataFromAPI.additionalCharges.additionalChargesDetails !== undefined && dataFromAPI.additionalCharges.additionalChargesDetails !== null) {
                if (dataFromAPI.additionalCharges.additionalChargesDetails.length > 0) {
                    dataFromAPI.additionalCharges.additionalChargesDetails.forEach(element => {
                        var taxLabel = element.additionalCharge
                        var taxCodeLabel = element.additionalCharge

                        additionalFooterData.push(this.setFooterObject(element.additionalCharge, taxLabel, element.chargeAmount, 'parent'))

                        var taxList = ['igst', 'cgst', 'sgst']
                        taxList.forEach(taxName => {
                            var inTax = this.getAdditionalChargeTax(element, taxName)
                            if (inTax !== undefined) {
                                additionalFooterData.push(inTax)
                            }
                        });

                        if (element.taxAmount > 0) {
                            let addtionalChargeTax = element.addtionalChargeTax ?? ''

                            if (addtionalChargeTax === '') {
                                addtionalChargeTax = getLocalisedText('tax')
                            }
                            taxCodeLabel = taxLabel + ' (' + addtionalChargeTax + ')'
                            additionalFooterData.push(this.setFooterObject(taxCodeLabel, taxCodeLabel, element.taxAmount, 'parent_tax'))
                        }
                    });
                }
            }
        }

        if (additionalFooterData.length === 1) {
            if (additionalFooterData[0].taxId === TableColumnPopupType.globalDiscount) {
                if (additionalFooterData[0].taxAmount === 0) {
                    additionalFooterData = []
                }
            }
        }

        returnData.additionalCharge = additionalFooterData
        return returnData
    }

    static setFooterObject(id, label, amount, taxType, isDefault = false) {
        return {
            "taxId": id,
            "taxName": label,
            "taxAmount": amount,
            "taxType": taxType,
            "isDefault": isDefault,
        }
    }

    static getAdditionalChargeTax(additionalCharges, taxObject) {
        var item = undefined
        if (additionalCharges[taxObject] !== undefined && additionalCharges[taxObject] !== null) {
            if (additionalCharges[taxObject] !== 0) {
                var taxLabel = additionalCharges.additionalCharge + ' (' + taxObject.toUpperCase() + ')'
                item = this.setFooterObject(additionalCharges.additionalCharge + '_' + taxObject, taxLabel, additionalCharges[taxObject], 'gst')
                item.hsnOrSacCode = additionalCharges.hsnOrSacCode ?? ''
                item.indiaTaxType = taxObject
                item.chargeAmount = additionalCharges.chargeAmount ?? 0
                item.isPercent = additionalCharges.isPercent ?? false
                item.percent = additionalCharges.percent ?? ''
                item.taxRate = additionalCharges.taxRate ?? 0
            }
        }
        return item
    }
}

