import http from './Interceptor';
import ApiConstants from '../Constants/ApiConstants';


class AiAPICallService {
    static async tryAi(payload: any) {
      const endpoint='try-ai';
      return await http.post(`${ApiConstants.URL.AI.API}`+endpoint, payload, {
        headers:{
          'x-access-token': ApiConstants.ACCESS_TOKEN!!
        },
      })
        .then((response: any) => {
          return Promise.resolve(response);
        })
        .catch((error: any) => {
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
        console.error('Error config:', error.config);
        return Promise.reject(error);
        });  
    }
}

export default AiAPICallService;


