import { Store } from '../../../../Redux/Store';
import NumberFormatService from '../../../../Services/NumberFormat';
import Utility from '../../../../Utility/Utility';

export const replaceLabelsForLiabilitiesAndAssets = (label: string) => {
  // Rename asset label as per requirements
  if (label === 'Non Current Asset') {
    label = 'Long Term Asset';
  }
  if (label === 'Non Current Liabilities') {
    label = 'Long Term Liabilities';
  }

  return label;
};

export const getAmountBlockWithCurrency = (
  amount: number,
  currency?: string
) => {
  let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  if (amount >= 0) {
    return `${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    }`;
  } else {
    return `(${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    })`;
  }
};

export const getAmountWithPercent = (amount: number) => {
  return `${NumberFormatService.getNumber(amount)}%`;
}

export const getParsedChildPNLDetails = (
  obj: any,
  margin: number,
  reportData: any
) => {
  let totalValues: any = {
    expanded: {},
    collapsed: {},
    accountCode: obj['code'],
    details: {}
  };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = {
    expanded: false,
    totalValues: totalValues,
    expandedOnce: false
  };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin + 10,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: '',
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'left'
      }
    });
    row.children.push({
      cells: [],
      children: [],
      expanded: false,
      expandedOnce: false
    });
  }
  for (let i = 0; i < 5; i++) {
    let hasChild = false;
    if (obj['itemDto'] !== null) {
      hasChild = true;
    }
    if (obj['itemDto']?.length === 0) {
      hasChild = false;
    }
    if (i === 3) {
      let title = hasChild
        ? totalValues.collapsed[`Period_1_withChild`]
        : totalValues.expanded[`Period_1`];
      row.cells.push({
        title: hasChild
          ? getAmountBlockWithCurrency(
              totalValues.collapsed[`Period_1_withChild`]
            )
          : getAmountBlockWithCurrency(totalValues.expanded[`Period_1`]),
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right',
          color: !isNaN(title) && title < 0 ? 'red' : 'black'
        }
      });
    } else {
      // row.cells.push({
      //   title: ' ',
      //   style: { fontWeight: 'normal', paddingLeft: margin, textAlign: 'left' }
      // });
      row.cells.push({
        title: '',
        style: {
          fontWeight: 'normal',
          paddingLeft: margin,
          textAlign: 'right'
        }
      });
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLDetails(item, margin + 15, reportData)
      );
    });
  }

  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedChildPNLPeriod = (
  obj: any,
  margin: number,
  reportData: any,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    if (period === 'Period_2') {
      obj['periodMap'][period] =
        obj['periodMap']['Period_3'] - obj['periodMap']['Period_1'];
      obj['periodMap'][`${period}_withChild`] =
        obj['periodMap'][`Period_3_withChild`] -
        obj['periodMap'][`Period_1_withChild`];
    }
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    if (period === 'Period_2') {
      obj['periodMap'][period] =
        obj['periodMap']['Period_3'] - obj['periodMap']['Period_1'];
    }
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }

  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLPeriod(item, margin + 15, reportData, currency)
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNL(item, margin + 15, reportData, currency)
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedSortedChildPNL = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  currency?: string
) => {
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(obj['periodMap'][period], currency)
        : getAmountBlockWithCurrency(0, currency),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNL(
          item,
          margin + 15,
          reportData,
          direction,
          currency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  return row;
};

export const getParsedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      }
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  row.children = row.children.sort(function (a: any, b: any) {
    if (a.cells.length) {
      return a.cells[0]?.title?.localeCompare(b.cells[0]?.title);
    }
  });

  return row;
};

export const getParsedSortedChildPNLInBothCurrency = (
  obj: any,
  margin: number,
  reportData: any,
  direction: string,
  baseCurrency: string,
  primaryCurrency: string
) => {
  const getCurrency = (period: string) => {
    let col = parseInt(period.split('_')[1]);
    return col & 1 ? baseCurrency : primaryCurrency;
  };
  let totalValues: any = { expanded: {}, collapsed: {} };
  for (const period in reportData['periodName']) {
    totalValues.expanded[period] = obj['periodMap'][period];
    totalValues.collapsed[`${period}_withChild`] =
      obj['periodMap'][`${period}_withChild`];
  }
  let row: any = { expanded: true, totalValues: totalValues };
  row.cells = [];
  row.children = [];
  const text = obj.name;
  row.cells.push({
    title: text,
    style: {
      fontWeight: 'normal',
      paddingLeft: margin,
      textAlign: 'left'
    }
  });

  for (const period in reportData['periodName']) {
    row.cells.push({
      title: obj['periodMap'][period]
        ? getAmountBlockWithCurrency(
            obj['periodMap'][period],
            getCurrency(period)
          )
        : getAmountBlockWithCurrency(0, getCurrency(period)),
      style: {
        fontWeight: 'normal',
        paddingLeft: margin,
        textAlign: 'right',
        color: obj['periodMap'][period]
          ? Number(obj['periodMap'][period]) < 0
            ? 'red'
            : 'black'
          : 'black'
      },
      amount: !isNaN(obj['periodMap'][`${period}_withChild`])
        ? obj['periodMap'][`${period}_withChild`]
        : obj['periodMap'][period]
    });

    if (!isNaN(row.cells.title) && Number(row.cells.title) < 0) {
      row.cells.style = { ...row.cells.style, color: 'red' };
    }
  }
  if (obj['itemDto'] !== null) {
    obj['itemDto'].forEach((item: any, index: number) => {
      row.children.push(
        getParsedSortedChildPNLInBothCurrency(
          item,
          margin + 15,
          reportData,
          direction,
          baseCurrency,
          primaryCurrency
        )
      );
    });
  }
  if (direction === 'ASC') {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return a.cells[1].amount - b.cells[1]?.amount;
      }
    });
  } else {
    row.children = row.children.sort(function (a: any, b: any) {
      if (a.cells.length) {
        return b.cells[1].amount - a.cells[1]?.amount;
      }
    });
  }

  return row;
};

export const getAmountBlockWithCurrencyForRevRec = (
  amount: number,
  currency?: string
) => {
  let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
  if (amount >= 0) {
    return (
      `${Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      )}` +
      ' ' +
      `${NumberFormatService.getNumber(Math.abs(amount))}`
    );
  } else {
    return `(${
      Utility.getCurrencySymbolFromCode(
        currency ? currency : tenantInfo.currency
      ) + NumberFormatService.getNumber(Math.abs(amount))
    })`;
  }
};
