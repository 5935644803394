import { addDays, subDays } from 'date-fns';
import {
  DKIcon,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useRef } from 'react';
import ApiConstants from '../../../../../Constants/ApiConstants';
import { BOOKS_DATE_FORMAT, DOC_TYPE } from '../../../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';
import usePreviousValue from '../../../../../Hooks/usePreviousValue';
import { useAppSelector } from '../../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../../Redux/Slices/AuthSlice';
import { selectSalesOrder } from '../../../../../Redux/Slices/SalesOrderSlice';
import { selectWarehouse } from '../../../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../../../Services/DateFormat';
import { IWorkOrder } from '../../../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../../../Services/NumberFormat';
import SalesOrderService, {
  ISalesOrder,
  SalesOrderAPIConfig
} from '../../../../../Services/SalesOrder';
import WarehouseManagementHelper, {
  WAREHOUSE_TYPE
} from '../../../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../../../Utility/Utility';
import no_product_image from '../../../Assets/no_product_icon.png';
import { WORK_ORDER_STATUS } from '../../../Constants/MRPColumnConfigs';
import { INPUT_TITLE_STYLE } from '../../../Constants/UIHelper';
import {
  WorkOrderHelper,
  checkTargetWarehouseReadStatus,
  fetchProductDetailsAndUpdateWOItemsAndOperations,
  getConsolidatedSalesOrder,
  getFilteredInvoices,
  getFilteredSO,
  getSOWithCommonProduct,
  getStatusColumnConfig,
  isPlannedQtyReadOnly,
  isTrackingInfoAvailable,
  validateSelectedInvoiceForInterLinkedWO,
  validateSelectedSOForInterLinkedWO
} from '../../WorkOrderHelper';
import WorkOrderSequenceFormat from './WorkOrderSequenceFormat';

// Icons
import ic_bom_allocate_green from '../../../../../Assets/Icons/ic_bom_allocate_green.svg';
import ic_bom_allocate_red from '../../../../../Assets/Icons/ic_bom_allocate_red.svg';
import { Invoice } from '../../../../../Models/Invoice';
import { selectInvoices } from '../../../../../Redux/Slices/InvoicesSlice';
import { selectIsWOAdhocEnable } from '../../../../../Redux/Slices/MRP/SettingsSlice';
import ContactService, {
  ContactAPIConfig
} from '../../../../../Services/Contact';
import InvoiceService, {
  InvoiceAPIConfig
} from '../../../../../Services/Invoice';
import JobCardService from '../../../../../Services/MRP/JobCard';
import WarehouseService, {
  defaultConfig
} from '../../../../../Services/Warehouse';
import { daysBetweenDatesWithoutTime } from '../../../../../SharedComponents/gantt-chart/common/GanttUtility';
import { woSliceStateKeys } from '../../AddWorkOrderSlice';

interface WorkOrderDetailsProps {
  workOrder: IWorkOrder;
  activeTabIndex: number;
  oldPlannedQtyInState: number;
  isEditMode: boolean;
  isReadOnlyMode: boolean;
  canValidate: boolean;
  invalidPlannedStartDate: boolean;
  invalidPlannedEndDate: boolean;
  showCompleteWorkOrderPopup: boolean;
  showWarehouseInventoryPopup: boolean;
  focusedField: string | null;
  workOrderProducts: any[];
  jobCardList: any[];
  onUpdateWorkOrderKeys: (updatedWO: any) => void;
  onSetActiveTabIndex: (updatedIndex: number) => void;
  onCommonStateUpdate: (key: woSliceStateKeys, value: any) => void;
  onForceUpdateJobCardList: () => void;
  productImageDetails?: string[];
  salesOrder?: ISalesOrder | ISalesOrder[] | null;
  salesInvoice?: Invoice | null;
  onSalesOrderUpdate?: (updatedSO: any, resetToOldSelection?: boolean) => void;
  onSalesInvoiceUpdate?: (
    updatedSI: any,
    resetToOldSelection?: boolean
  ) => void;
  isCopyMode?: boolean; // Flag to indicate if the work order is being copied
}

const WorkOrderDetails = (props: WorkOrderDetailsProps) => {
  // Selectors
  const tenantInfo = useAppSelector(activeTenantInfo);
  const salesOrdersData = useAppSelector(selectSalesOrder);
  const salesInvoicesData = useAppSelector(selectInvoices);
  const warehouseData = useAppSelector(selectWarehouse);
  const isAdhocEnabled = useAppSelector(selectIsWOAdhocEnable);

  //constants
  const salesOrder = !Array.isArray(props?.salesOrder)
    ? props?.salesOrder
    : getConsolidatedSalesOrder(
        props?.salesOrder,
        props?.workOrder?.product?.productId
      );

  // Ref
  const woTempManufacturingQty = useRef<number | string>(
    props.workOrder?.manufactureQuantity ?? ''
  );
  const woTempPlannedDates = useRef<any>({});

  const productDescription =
    (props.isEditMode
      ? props.workOrder?.product?.description
      : props.workOrder?.description) || '';
  const showSaleOrderDropdown = Utility.isNotEmpty(salesOrder?.salesOrderCode);
  const showSalesInvoiceDropdown = Utility.isNotEmpty(
    props.salesInvoice?.salesInvoiceCode
  );
  const showFieldsOnStatusComplete =
    props?.workOrder?.status === WORK_ORDER_STATUS.COMPLETED;
  const usePrevPlannedQty: any = usePreviousValue(
    props.workOrder?.manufactureQuantity ?? 0
  );

  const alertForReadOnlyDocument = () => {
    showAlert(
      'Warning !!!',
      "You can't change the planned qty. Looks like your material is allocated or job card status is changed."
    );
  };

  const getBomNumberView = () => {
    const selectedBOM = !isAdhocEnabled
      ? props.workOrder?.selectedBom?.name ?? '-'
      : '-';
    return (
      <DKInput
        titleStyle={INPUT_TITLE_STYLE}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={false}
        title="BOM Meta Name"
        value={selectedBOM || ''}
        readOnly={true}
      />
    );
  };

  const getContactNameView = () => {
    let selectedContact;
    if (Utility.isNotEmpty(props.workOrder?.contactsArr)) {
      selectedContact = props.workOrder?.contactsArr;
    }
    let isReadOnly = false;

    if (props.workOrder?.status === WORK_ORDER_STATUS.COMPLETED) {
      isReadOnly = true;
    }
    let convertedDoc = props.workOrder?.workOrderSourceDetails?.find(
      (ele: any) =>
        ele.workOrderSource === DOC_TYPE.SALES_ORDER ||
        ele.workOrderSource === DOC_TYPE.INVOICE
    );
    if (Utility.isNotEmpty(convertedDoc)) {
      isReadOnly = true;
    }
    if (Utility.isNotEmpty(props.workOrder?.linkedDocuments)) {
      isReadOnly = true;
    }

    return (
      <div className="row  align-items-end">
        <DKInput
          titleStyle={INPUT_TITLE_STYLE}
          className={'parent-width'}
          value={selectedContact}
          formatter={(obj: any) => {
            return getContactName(obj) ?? '-';
          }}
          title="Contact Name"
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          canValidate={false}
          readOnly={isReadOnly}
          onChange={(value: any) => {
            let woKeysToUpdate: any = {};
            woKeysToUpdate.contactsArr = [value];
            props.onUpdateWorkOrderKeys(woKeysToUpdate);
          }}
          dropdownConfig={{
            className: '',
            style: {},
            allowSearch: true,
            searchApiConfig: {
              getUrl: (val: any) => {
                const config: ContactAPIConfig = {
                  ...ContactService.apiConfig,
                  Page: 0,
                  SearchTerm: val,
                  Limit: 20,
                  IncludeOpeningAmounts: false,
                  IncludeOweAmounts: false,
                  Query: 'status=active'
                };
                ContactService.apiConfig = config;
                return ContactService.getContactsApiUrl();
              },
              dataParser: (response: any) => {
                let data: any[] = response?.content || [];
                // data.unshift({ name: 'All', code: 'ALL' });
                return data;
              },
              debounceTime: 300
            },
            searchableKey: 'name',
            data: [],
            renderer: (index: any, obj: any) => {
              return <DKLabel text={`${obj.name}`} />;
            },
            onSelect: (index: any, value: any) => {}
          }}
        />
      </div>
    );
  };

  const onChangeSalesOrder = async (obj: ISalesOrder) => {
    const salesOrder = deepClone(obj);
    await validateSelectedSOForInterLinkedWO(
      salesOrder,
      () => updateSalesOrderOnSelection(salesOrder),
      () => resetSalesOrderSelection()
    );
  };

  const onChangeSalesInvoice = async (obj: Invoice) => {
    const invoice = deepClone(obj);
    await validateSelectedInvoiceForInterLinkedWO(
      invoice,
      () => updateSalesInvoiceOnSelection(invoice),
      () => resetSalesInvoiceSelection()
    );
  };

  const updateSalesOrderOnSelection = (salesOrder: ISalesOrder) => {
    props.onSetActiveTabIndex(0);
    salesOrder.salesOrderDate = DateFormatService.getDateFromStr(
      salesOrder.salesOrderDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    salesOrder.salesOrderDueDate = DateFormatService.getDateFromStr(
      salesOrder.salesOrderDueDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (props.onSalesOrderUpdate) {
      props.onSalesOrderUpdate(salesOrder);
    }
  };

  const updateSalesInvoiceOnSelection = (invoice: Invoice) => {
    props.onSetActiveTabIndex(0);
    invoice.salesInvoiceDate = DateFormatService.getDateFromStr(
      invoice.salesInvoiceDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    invoice.salesInvoiceDueDate = DateFormatService.getDateFromStr(
      invoice.salesInvoiceDueDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (props.onSalesInvoiceUpdate) {
      props.onSalesInvoiceUpdate(invoice);
    }
  };

  const resetSalesInvoiceSelection = () => {
    if (props.onSalesInvoiceUpdate) {
      props.onSalesInvoiceUpdate(props.salesInvoice, true);
    }
  };

  const resetSalesOrderSelection = () => {
    if (props.onSalesOrderUpdate) {
      props.onSalesOrderUpdate(salesOrder, true);
    }
  };

  /** Date Input Helpers & Events *********************
   * **************************************************
   * **************************************************
   */
  const handleJCDateChanges = (updatedDate: Date) => {
    if (props.jobCardList?.length > 0) {
      let diffBetweenOldAndNewDates = daysBetweenDatesWithoutTime(
        woTempPlannedDates.current?.startDate,
        updatedDate
      );
      diffBetweenOldAndNewDates = Math.round(diffBetweenOldAndNewDates);

      let differenceBetwnExisitingDate = daysBetweenDatesWithoutTime(
        updatedDate,
        woTempPlannedDates.current?.endDate
      );

      let differenceBetwnStartDateAndDeliveryDate = daysBetweenDatesWithoutTime(
        updatedDate,
        woTempPlannedDates.current?.deliveryDate
      );

      if (differenceBetwnExisitingDate <= 0) {
        props.onUpdateWorkOrderKeys({
          plannedEndDate: updatedDate
        });
      }

      if (differenceBetwnStartDateAndDeliveryDate <= 0) {
        props.onUpdateWorkOrderKeys({
          deliveryDate: updatedDate
        });
      }

      let attrbReqPayload: any = {};
      props.jobCardList?.map((jcItem: any) => {
        const differenceBetwnDates =
          daysBetweenDatesWithoutTime(
            new Date(jcItem?.plannedStartDate),
            new Date(jcItem?.plannedEndDate)
          ) ?? 0;
        if (diffBetweenOldAndNewDates > 0) {
          const start_date = addDays(
            new Date(jcItem?.plannedStartDate),
            diffBetweenOldAndNewDates
          );
          const end_date = addDays(new Date(start_date), differenceBetwnDates);
          jcItem = {
            ...jcItem,
            plannedStartDate: start_date,
            plannedEndDate: end_date
          };

          attrbReqPayload[jcItem.id] = {
            plannedStartDate: DateFormatService.getDateStrFromDate(
              new Date(start_date),
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ),
            plannedEndDate: DateFormatService.getDateStrFromDate(
              new Date(end_date),
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          };
        } else {
          const start_date = subDays(
            new Date(jcItem?.plannedStartDate),
            Math.abs(diffBetweenOldAndNewDates)
          );
          const end_date = addDays(new Date(start_date), differenceBetwnDates);
          jcItem = {
            ...jcItem,
            plannedStartDate: start_date,
            plannedEndDate: end_date
          };

          attrbReqPayload[jcItem.id] = {
            plannedStartDate: DateFormatService.getDateStrFromDate(
              new Date(start_date),
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            ),
            plannedEndDate: DateFormatService.getDateStrFromDate(
              new Date(end_date),
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          };
        }

        return jcItem;
      });

      updateJCAttributes(attrbReqPayload);
    }
  };

  const handleJCDurationChanges = () => {
    const oldPlannedQty: number = props.oldPlannedQtyInState;
    let attrbReqPayload: any = {};
    let allJobCardsMappedArr: any[] = [];
    let newEstimatedTime = 0;
    if (props.jobCardList?.length > 0) {
      props.jobCardList?.map((jcItem: any) => {
        const perJCPlannedTime = jcItem?.plannedTime / oldPlannedQty;
        const newPlannedTime =
          Number(woTempManufacturingQty.current || 0) * perJCPlannedTime;
        newEstimatedTime += newPlannedTime;
        if (Utility.isEmpty(jcItem?.jobcardDependency?.jobcardDependencyList)) {
          const start_date = new Date(jcItem?.plannedStartDate);
          const end_date = DateFormatService.addMinutes(
            start_date,
            newPlannedTime
          );
          attrbReqPayload[jcItem.id] = {
            plannedStartDate: start_date,
            plannedEndDate: end_date,
            plannedTime: newPlannedTime
          };
          allJobCardsMappedArr.push({
            plannedStartDate: start_date,
            plannedEndDate: end_date,
            id: jcItem?.id,
            jobCardCode: jcItem?.jobCardCode
          });
        } else {
          const dependentCodeOfCurrentJC =
            jcItem?.jobcardDependency?.jobcardDependencyList?.[0];
          const dependOnFound = allJobCardsMappedArr?.find(
            (jc: any) => jc.jobCardCode === dependentCodeOfCurrentJC
          );
          const start_date = new Date(dependOnFound?.plannedEndDate);
          const end_date = DateFormatService.addMinutes(
            start_date,
            newPlannedTime
          );
          attrbReqPayload[jcItem.id] = {
            plannedStartDate: start_date,
            plannedEndDate: end_date,
            plannedTime: newPlannedTime
          };
          allJobCardsMappedArr.push({
            plannedStartDate: start_date,
            plannedEndDate: end_date,
            id: jcItem?.id,
            jobCardCode: jcItem?.jobCardCode
          });
        }
        return jcItem;
      });
    }
    props.onCommonStateUpdate(
      'oldPlannedQtyInState',
      woTempManufacturingQty.current
    );
    props.onUpdateWorkOrderKeys({
      estimatedTime: newEstimatedTime
    });
    updateJCAttributes(attrbReqPayload);
  };

  const onChangePlannedStartDate = (date: any) => {
    let updatedWorkOrder = { ...props.workOrder };
    woTempPlannedDates.current = {
      startDate: updatedWorkOrder.plannedStartDate,
      endDate: updatedWorkOrder.plannedEndDate,
      deliveryDate: updatedWorkOrder.deliveryDate
    };
    if (props.isEditMode && updatedWorkOrder.oldPlannedStartDate !== date) {
      getAlertOnWorkOrderDateOrQtyChange(true, date);
    }

    // TODO: handle multiple calls for getAlertOnWorkOrderDateOrQtyChange
    // props.isEditMode && getAlertOnWorkOrderDateOrQtyChange(true);
    props.onUpdateWorkOrderKeys({
      plannedStartDate: date
    });
  };

  const onChangePlannedEndDate = (date: any) => {
    let updatedWorkOrder = { ...props.workOrder };
    woTempPlannedDates.current = {
      startDate: updatedWorkOrder.plannedStartDate,
      endDate: updatedWorkOrder.plannedEndDate,
      deliveryDate: updatedWorkOrder.deliveryDate
    };
    // setArePlannedDatesUpdated(true);
    props.onUpdateWorkOrderKeys({
      plannedEndDate: date
    });
  };

  const onChangeDeliveryDate = (date: Date) => {
    const isWOinProgress = WorkOrderHelper.isPlannedStartDateReadOnly(
      props.workOrder
    );
    const isDeliveryDateInvalid =
      date <
      (isWOinProgress
        ? props.workOrder?.actualStartDate
        : props.workOrder?.plannedStartDate);

    if (isDeliveryDateInvalid) {
      showAlert(
        'Invalid Delivery Date',
        'Delivery date cannot be less than planned date.'
      );
      props.onUpdateWorkOrderKeys({
        deliveryDate: props.workOrder?.deliveryDate
      });
      return;
    }
    props.onUpdateWorkOrderKeys({
      deliveryDate: date
    });
  };

  /** Actual & Planned Qty Input Helpers & Events *****
   * **************************************************
   * **************************************************
   */
  const onBlurActualQuantityInput = () => {
    if (
      !props.isReadOnlyMode &&
      props.workOrder?.product?.advancedTracking !== ADVANCE_TRACKING.NONE &&
      Number(props.workOrder.manufactureQuantity) !==
        Number(props.workOrder.actualQuantity)
    ) {
      showAlert(
        'Oops!',
        'Please update material track details according to provided actual quantity.',
        [
          {
            title: 'Ok',
            className: 'bg-button text-white border-m ',
            onClick: () => {
              props.onCommonStateUpdate('showCompleteWorkOrderPopup', false);
              props.onCommonStateUpdate('showWarehouseInventoryPopup', true);
            }
          }
        ]
      );
      return;
    }
  };

  const onChangeActualQuantity = (value: any) => {
    let actualYield = 0;
    let copyOfWorkOrder = { ...props.workOrder };
    if (isNaN(value) || value <= 0) {
      value = 0;
    }
    if (value !== 0) {
      actualYield = (value / (copyOfWorkOrder.manufactureQuantity || 1)) * 100;
    }
    copyOfWorkOrder.actualYield = actualYield;
    props.onUpdateWorkOrderKeys({
      actualQuantity: value,
      actualYield: actualYield
    });
  };

  const getDisplayValueForPlannedQtyInput = () =>
    props.focusedField === 'plannedQty'
      ? woTempManufacturingQty.current || ''
      : NumberFormatService.getNumber(
          props.workOrder?.manufactureQuantity || 1
        );

  const onChangePlannedQtyInput = (value: any) => {
    if (
      isPlannedQtyReadOnly(
        props.workOrder,
        salesOrder,
        props.salesInvoice,
        props.isEditMode
      )
    ) {
      alertForReadOnlyDocument();
      return;
    }

    woTempManufacturingQty.current = value;
  };

  const onFocusPlannedQtyInput = () => {
    woTempManufacturingQty.current = props.workOrder.manufactureQuantity || '';
    props.onCommonStateUpdate('focusedField', 'plannedQty');
  };

  const onBlurPlannedQtyInput = async () => {
    const isManufactureQuantityUnchanged =
      Number(props.workOrder.manufactureQuantity) ===
      Number(woTempManufacturingQty.current || 1);
    if (
      isManufactureQuantityUnchanged ||
      isPlannedQtyReadOnly(
        props.workOrder,
        salesOrder,
        props.salesInvoice,
        props.isEditMode
      )
    ) {
      props.onCommonStateUpdate('focusedField', null);
      return;
    }

    try {
      showLoader('Updating work order...');
      let copyOfWorkOrder: IWorkOrder = {
        ...props.workOrder,
        resetAllocation: false /** @todo Need to discuss why is it reqd? */,
        manufactureQuantity: Number(woTempManufacturingQty.current || 1),
        actualQuantity: Number(woTempManufacturingQty.current || 1)
      };
      copyOfWorkOrder = await fetchProductDetailsAndUpdateWOItemsAndOperations(
        copyOfWorkOrder,
        usePrevPlannedQty,
        props.isEditMode
      );
      props.onUpdateWorkOrderKeys({
        resetAllocation: copyOfWorkOrder.resetAllocation,
        manufactureQuantity: copyOfWorkOrder.manufactureQuantity,
        actualQuantity: copyOfWorkOrder.manufactureQuantity,
        workOrderItems: copyOfWorkOrder.workOrderItems,
        workOrderOperations: copyOfWorkOrder.workOrderOperations
      });

      // Changed old 'value' to 'copyOfWorkOrder.manufactureQuantity' while refactoring
      if (
        props.isEditMode &&
        props.oldPlannedQtyInState !== copyOfWorkOrder.manufactureQuantity
      ) {
        getAlertOnWorkOrderDateOrQtyChange(false);
      }

      props.onCommonStateUpdate('focusedField', null);
      removeLoader();
    } catch (err) {
      removeLoader();
      props.onCommonStateUpdate('focusedField', null);
    }
  };

  const updateJCAttributes = (payload: any) => {
    JobCardService.bulkPartialDataUpdateJobCard(payload)
      .then((res: any) => {
        props.onForceUpdateJobCardList();
      })
      .catch((err: any) => {});
  };

  const getAlertOnWorkOrderDateOrQtyChange = (
    isDateUpdated: boolean,
    updatedDate?: Date
  ) => {
    let buttons = [
      {
        title: 'No',
        className: 'bg-gray2 border-m ',
        onClick: () => {
          let woKeysToUpdate: any = {};
          if (isDateUpdated) {
            woKeysToUpdate.plannedStartDate =
              woTempPlannedDates.current?.startDate;
            woKeysToUpdate.plannedEndDate = woTempPlannedDates.current?.endDate;
          }
          props.onUpdateWorkOrderKeys(woKeysToUpdate);
        }
      },
      {
        title: 'Yes',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (!isDateUpdated) {
            handleJCDurationChanges();
          } else if (updatedDate) {
            handleJCDateChanges(updatedDate);
          }
        }
      }
    ];
    showAlert(
      'Attention!',
      'Modifying the dates/qty on the current Work Order may also update the dates/duration on related job cards. You need to save the current WO to save changes. Are you sure you want to proceed and update the job card dates as well? ',
      buttons
    );
  };

  /** Target Warehouse Input Helpers & Events *********
   * **************************************************
   * **************************************************
   */
  const onChangeTargetWarehouseInput = (obj: any) => {
    let keysToUpdate: any = {};
    let copyOfWorkOrder = { ...props.workOrder };
    copyOfWorkOrder.targetWarehouse = obj;
    keysToUpdate.targetWarehouse = obj;
    let qty = 0;
    if (copyOfWorkOrder?.product?.advancedTracking === ADVANCE_TRACKING.NONE) {
      qty = WarehouseManagementHelper.isRRBEnabledForWarehouse(
        copyOfWorkOrder.targetWarehouse
      )
        ? copyOfWorkOrder.actualQuantity ?? 0
        : copyOfWorkOrder.actualQuantity ?? 0;
    }
    if (WarehouseManagementHelper.isRRBEnabledForWarehouse(obj)) {
      qty = 0;
    }
    let warehouseInventoryObject = {
      warehouseCode: obj.code,
      warehuoseName: obj.name,
      quantity: qty
    };
    copyOfWorkOrder.warehouseInventoryData = warehouseInventoryObject
      ? [warehouseInventoryObject]
      : [];
    keysToUpdate.warehouseInventoryData =
      copyOfWorkOrder.warehouseInventoryData;
    if (Utility.isRRBTaggingEnabled() || Utility.isWarehouseTaggingEnabled()) {
      // reset warehouseInventoryData if warehouse tagging is enabled
      copyOfWorkOrder.workOrderItems = copyOfWorkOrder?.workOrderItems?.map(
        (item: any) => {
          return {
            ...item,
            warehouseInventoryData: []
          };
        }
      );
      keysToUpdate.workOrderItems = copyOfWorkOrder.workOrderItems;
    }
    keysToUpdate.advancedTrackingData = [];
    props.onUpdateWorkOrderKeys(keysToUpdate);
  };

  const getContactName = (obj: any) => {
    let cNames: any = '';
    cNames = props?.workOrder?.contactsArr
      ?.map((contactObj: any) => contactObj?.name)
      ?.join(', ');
    return cNames;
  };

  const getSalesOrderCodes = (obj: any) => {
    if (props?.isEditMode) {
      let soCodes = '';
      soCodes =
        props?.workOrder?.workOrderSourceDetails
          ?.filter(
            (item: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) =>
                  t.salesOrderSequenceCode === item.salesOrderSequenceCode
              )
          )
          ?.map((item: any) => {
            if (item?.workOrderSource === DOC_TYPE.SALES_ORDER) {
              return item?.salesOrderSequenceCode;
            }
          })
          ?.join(', ') ?? '';
      return soCodes;
    }
    if (Array.isArray(props?.salesOrder)) {
      let soCodes = '';
      soCodes = getSOWithCommonProduct(
        props?.salesOrder,
        props?.workOrder?.productCode ?? ''
      )
        ?.filter(
          (item: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (t: any) => t.documentSequenceCode === item.documentSequenceCode
            )
        )
        ?.map((order: ISalesOrder) => order?.documentSequenceCode)
        ?.join(', ');
      return soCodes;
    } else {
      return obj.documentSequenceCode;
    }
  };

  const getSOCustomerNumbers = () => {
    if (props?.isEditMode) {
      let soCodes = '';
      soCodes =
        props?.workOrder?.workOrderSourceDetails
          ?.filter(
            (item: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) =>
                  t.salesOrderCustomerOrderNumber ===
                  item.salesOrderCustomerOrderNumber
              )
          )
          ?.map((item: any) => {
            if (item?.workOrderSource === DOC_TYPE.SALES_ORDER) {
              return item?.salesOrderCustomerOrderNumber;
            }
          })
          ?.join(', ') ?? '';
      return soCodes;
    }
    if (Array.isArray(props?.salesOrder)) {
      let soCodes = '';
      soCodes = getSOWithCommonProduct(
        props?.salesOrder,
        props?.workOrder?.productCode ?? ''
      )
        ?.map((order: ISalesOrder) => order?.customerOrderNumber)
        ?.join(', ');
      return soCodes;
    } else {
      return props?.salesOrder?.customerOrderNumber;
    }
  };

  /**
   * Main renderer
   */
  return (
    <div
      className="column"
      style={{
        width: props.isEditMode ? '40%' : '100%',
        height: 'auto',
        maxHeight: 705
      }}
    >
      <div
        className="column parent-height bg-white border-m border-radius-m p-l parent-width hide-scroll-bar"
        style={{ overflow: 'auto' }}
      >
        <DKLabel
          text="Work Order Details"
          className="fw-m mb-r text-app-color"
        />
        <div className="column parent-width hide-scroll-bar">
          <div className="row">
            <div
              className="column parent-width align-items-center border-radius-m"
              style={{ overflow: 'hidden' }}
            >
              <img
                src={props?.workOrder?.product?.images?.[0] || no_product_image}
                alt={props?.workOrder?.product?.images?.[0] || no_product_image}
                className="border-radius-m width-auto"
                style={{
                  height: 200,
                  opacity: props?.workOrder?.product?.images?.[0] ? 1 : 0.1,
                  objectFit: 'cover'
                }}
              />
            </div>
            <div className="column parent-width ml-m">
              <WorkOrderSequenceFormat
                key={`WO-SEQUENCE-FORMAT-${
                  props.workOrder?.selectedBom?.id ??
                  props.workOrder?.productCode
                }`}
                isEditMode={props.isEditMode}
                workOrder={{ ...props.workOrder }}
                onSequenceFormatChange={(updatedWO: any) => {
                  props.onUpdateWorkOrderKeys({
                    sequenceFormat: updatedWO.sequenceFormat,
                    documentSequenceCode: updatedWO.documentSequenceCode
                  });
                }}
              />

              {/* SO selector */}
              {salesOrder && showSaleOrderDropdown && (
                <DKInput
                  type={INPUT_TYPE.DROPDOWN}
                  title={'Sales Order'}
                  placeholder={'Select a Sales Order'}
                  titleStyle={INPUT_TITLE_STYLE}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  readOnly={
                    (props.isEditMode || !Utility.isEmpty(props?.salesOrder)) &&
                    !props.isCopyMode
                  }
                  value={salesOrder}
                  formatter={(obj: any) => {
                    return getSalesOrderCodes(obj);
                  }}
                  required={false}
                  canValidate={props.canValidate}
                  disabled={
                    props.isEditMode || !Utility.isEmpty(props?.salesOrder)
                  }
                  onChange={onChangeSalesOrder}
                  dropdownConfig={{
                    title: '',
                    allowSearch: true,
                    searchableKey: 'documentSequenceCode',
                    className: 'shadow-m width-auto',
                    searchApiConfig: {
                      getUrl: (searchValue: any) => {
                        const config: SalesOrderAPIConfig = {
                          ...SalesOrderService.apiConfig,
                          SearchTerm: searchValue,
                          Limit: 25,
                          Page: 0
                        };
                        SalesOrderService.apiConfig = config;
                        return (
                          ApiConstants.URL.BASE +
                          SalesOrderService.salesOrderEndpoint()
                        );
                      },
                      dataParser: (data: any) => {
                        return getFilteredSO(data?.content) ?? [];
                      }
                    },
                    data: getFilteredSO(salesOrdersData?.content) ?? [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.documentSequenceCode}`} />;
                    }
                  }}
                />
              )}

              {props.salesInvoice && showSalesInvoiceDropdown && (
                <DKInput
                  type={INPUT_TYPE.DROPDOWN}
                  title={'Sales Invoice'}
                  placeholder={'Select a Sales Invoice'}
                  titleStyle={INPUT_TITLE_STYLE}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  readOnly={props.isEditMode && !props.isCopyMode}
                  value={props.salesInvoice}
                  formatter={(obj: any) => {
                    return obj.documentSequenceCode;
                  }}
                  required={false}
                  canValidate={props.canValidate}
                  disabled={props.isEditMode}
                  onChange={onChangeSalesInvoice}
                  dropdownConfig={{
                    title: '',
                    allowSearch: true,
                    searchableKey: 'documentSequenceCode',
                    className: 'shadow-m width-auto',
                    searchApiConfig: {
                      getUrl: (searchValue: any) => {
                        const config: InvoiceAPIConfig = {
                          ...InvoiceService.apiConfig,
                          SearchTerm: searchValue,
                          Limit: 25,
                          Page: 0
                        };
                        InvoiceService.apiConfig = config;
                        return (
                          ApiConstants.URL.BASE +
                          InvoiceService.salesInvoiceEndpoint()
                        );
                      },
                      dataParser: (data: any) => {
                        return getFilteredInvoices(data?.content) ?? [];
                      }
                    },
                    data: getFilteredInvoices(salesInvoicesData?.content) ?? [],
                    renderer: (index: any, obj: any) => {
                      return <DKLabel text={`${obj.documentSequenceCode}`} />;
                    }
                  }}
                />
              )}

              {!(salesOrder && showSaleOrderDropdown) &&
                !(props.salesInvoice && showSalesInvoiceDropdown) && (
                  <DKInput
                    titleStyle={INPUT_TITLE_STYLE}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                    title="Sales Order/Invoice Number"
                    value={'NA'}
                    readOnly={true}
                  />
                )}
              {/* SO selector ends */}

              {getContactNameView()}

              {/* SO Customer Order No. */}
              {!Utility.isEmpty(
                props.workOrder?.workOrderSourceDetails?.[0]
                  ?.salesOrderCustomerOrderNumber
              ) && (
                <DKInput
                  titleStyle={INPUT_TITLE_STYLE}
                  required={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  title={`${salesOrder ? 'SO' : 'Invoice'} Customer Order No.`}
                  type={INPUT_TYPE.TEXT}
                  readOnly={true}
                  value={getSOCustomerNumbers()}
                />
              )}
              {/* SO Customer Order No. ends */}

              {getBomNumberView()}

              {/* Status */}
              {Utility.isEmpty(
                props.workOrder?.workOrderSourceDetails?.[0]
                  ?.salesOrderCustomerOrderNumber
              ) && (
                <DKInput
                  titleStyle={INPUT_TITLE_STYLE}
                  required={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  title="Status"
                  type={INPUT_TYPE.TEXT}
                  readOnly={true}
                  value={getStatusColumnConfig(props.workOrder).name}
                />
              )}
              {/* Status ends */}
            </div>
          </div>

          <div className="row mt-m align-items-start">
            {/* Planned Start Date */}
            <div className="column parent-width">
              <DKInput
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title="Planned Start Date"
                value={props.workOrder?.plannedStartDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                type={INPUT_TYPE.DATE}
                onChange={onChangePlannedStartDate}
                readOnly={
                  (props.isReadOnlyMode ||
                    WorkOrderHelper.isPlannedStartDateReadOnly(
                      props.workOrder
                    )) &&
                  !props.isCopyMode
                }
              />
              {props.invalidPlannedStartDate && (
                <div
                  className="fs-r text-align-left text-red mt-xs"
                  style={{ fontSize: '11px' }}
                >
                  Planned date should not be before financial date.
                </div>
              )}
            </div>
            {/* Planned Start Date ends*/}

            {/* Planned End Date */}
            <div className="column parent-width ml-m">
              <DKInput
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                className=""
                title="Planned End Date"
                value={props.workOrder?.plannedEndDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                type={INPUT_TYPE.DATE}
                onChange={onChangePlannedEndDate}
                readOnly={props.isReadOnlyMode}
              />
              {props.invalidPlannedEndDate && (
                <div
                  className="fs-r text-align-left text-red mt-xs"
                  style={{ fontSize: '11px' }}
                >
                  Planned end date should be greater.
                </div>
              )}
            </div>
            {/* Planned End Date ends*/}
          </div>

          {showFieldsOnStatusComplete && (
            <div className="row mt-m ">
              <DKInput
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                className=""
                title="Actual Start Date"
                value={props.workOrder?.actualStartDate}
                type={INPUT_TYPE.DATE}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                onChange={(date: any) => {
                  props.onUpdateWorkOrderKeys({
                    actualStartDate: date
                  });
                }}
                readOnly={props.isReadOnlyMode}
              />
              <DKInput
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                title="Actual End Date"
                className="ml-m"
                value={props.workOrder?.actualEndDate}
                dateFormat={convertBooksDateFormatToUILibraryFormat(
                  tenantInfo.dateFormat
                )}
                type={INPUT_TYPE.DATE}
                onChange={(date: any) => {
                  props.onUpdateWorkOrderKeys({
                    actualEndDate: date
                  });
                }}
                readOnly={props.isReadOnlyMode}
              />
            </div>
          )}

          <div className="row mt-m ">
            {showFieldsOnStatusComplete && (
              <DKInput
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                textAlign={props.showCompleteWorkOrderPopup ? 'right' : 'left'}
                title="Actual Quantity"
                type={INPUT_TYPE.NUMBER}
                required={true}
                canValidate={props.canValidate}
                value={props.workOrder?.actualQuantity}
                onBlur={onBlurActualQuantityInput}
                onChange={onChangeActualQuantity}
                className="mr-m"
                readOnly={props.isReadOnlyMode}
              />
            )}

            <DKInput
              titleStyle={INPUT_TITLE_STYLE}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title="Delivery Date"
              value={props.workOrder?.deliveryDate}
              dateFormat={convertBooksDateFormatToUILibraryFormat(
                tenantInfo.dateFormat
              )}
              type={INPUT_TYPE.DATE}
              onChange={onChangeDeliveryDate}
              readOnly={props.isReadOnlyMode}
            />
            {!showFieldsOnStatusComplete && (
              <DKInput
                title={`Planned Qty (${
                  Utility.getUOMForStockUOMId(
                    props.workOrder?.product?.stockUom
                  )?.name
                })`}
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.NUMBER}
                required={true}
                className="ml-m"
                canValidate={props.canValidate}
                readOnly={
                  props.isReadOnlyMode ||
                  isPlannedQtyReadOnly(
                    props.workOrder,
                    salesOrder,
                    props.salesInvoice,
                    props.isEditMode
                  )
                }
                onFocus={onFocusPlannedQtyInput}
                value={getDisplayValueForPlannedQtyInput()}
                onBlur={onBlurPlannedQtyInput}
                onChange={onChangePlannedQtyInput}
              />
            )}
          </div>

          <div className="row mt-m align-items-end">
            <DKInput
              titleStyle={INPUT_TITLE_STYLE}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              title="Target Warehouse"
              required={true}
              canValidate={props.canValidate}
              value={props.workOrder?.targetWarehouse?.name}
              type={INPUT_TYPE.DROPDOWN}
              dropdownConfig={{
                title: '',
                allowSearch: true,
                style: { width: 250 },
                className: 'shadow-m width-auto',
                searchApiConfig: {
                  getUrl: (search: string) =>
                    WarehouseService.getWarehouseEndPoint({
                      ...defaultConfig,
                      SearchTerm: search || ''
                    }),
                  dataParser: (response: any) => {
                    return (response?.content || [])
                      .filter(
                        (wareHouse: any) =>
                          wareHouse?.active &&
                          wareHouse.warehouseType === WAREHOUSE_TYPE.NONE
                      )
                      .sort((a: any, b: any) => b?.primary - a?.primary);
                  }
                },
                data: (warehouseData?.content || [])
                  .filter(
                    (wareHouse: any) =>
                      wareHouse?.active &&
                      wareHouse.warehouseType === WAREHOUSE_TYPE.NONE
                  )
                  .sort((a: any, b: any) => b?.primary - a?.primary),
                onSelect: (index: number, value: any) => {},
                onClose: () => {
                  // setShowTargetWarehouseList(!showTargetWarehouseList);
                },
                renderer: (index: any, obj: any) => {
                  return <div className="row parent-width">{obj.name}</div>;
                }
              }}
              onChange={onChangeTargetWarehouseInput}
              className=""
              readOnly={checkTargetWarehouseReadStatus(
                props.workOrder,
                props.isEditMode,
                props.isReadOnlyMode
              )}
            />
            {(WarehouseManagementHelper.isRRBEnabledForWarehouse(
              props.workOrder?.targetWarehouse
            ) ||
              props.workOrder?.product?.advancedTracking !==
                ADVANCE_TRACKING.NONE) &&
              !props.isReadOnlyMode && (
                <DKIcon
                  src={
                    isTrackingInfoAvailable(props.workOrder)
                      ? ic_bom_allocate_green
                      : ic_bom_allocate_red
                  }
                  title={''}
                  className={`ic-r flex align-items-center cursor-hand ml-s pb-1`}
                  onClick={() => {
                    props.onCommonStateUpdate(
                      'showWarehouseInventoryPopup',
                      !props.showWarehouseInventoryPopup
                    );
                  }}
                />
              )}
          </div>
          {!Utility.isEmpty(productDescription) && (
            <div className="row mt-m">
              <DKInput
                className=""
                title="Product Description"
                type={INPUT_TYPE.LONG_TEXT}
                value={productDescription}
                required={false}
                onChange={(value: any) => {}}
                readOnly={true}
                titleStyle={INPUT_TITLE_STYLE}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkOrderDetails;
