import { getLocalisedText } from "../../Translate/LanguageManager";
import NumberUtility from "../../Utilities/NumberUtility";
import {
  getIsSelectTotalColumn,
  getSortedLineItems,
  getTablePadding,
} from "../../DKUILibrary/dktable/TableUtility";
import Utility, { getDateString, getVW } from "../../Utilities/Utility";
import { ItemColumn } from "../../DKUILibrary/dktable/ItemColumn";
import { ColumnStyle } from "../../DKUILibrary/dktable/ColumnStyle";
import {
  FONT_SIZE,
  PRINT_SCALE,
  TableDimensionType,
} from "../../Constants/Constants";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import { ProductTableGroupHeaderColumn } from "../../DKUILibrary/dktable/product/ProductTableGroupHeaderColumn";
import MachineScheduleSOPItem from "./MachineScheduleSOPItem";
import MachineScheduleMaterialItem from "./MachineScheduleMaterialItem";

export default class MachineSchedulerHelper {
  static groupHeaderIndexList: number[];

  static getData(documentData: any, type: "Material" | "SOP") {
    var lineItems: string[][] = [];
    var dummyData = [
      [
        "F-001",
        getLocalisedText("item_name"),
        getLocalisedText("item_description"),
        "N/A",
        "1",
        "1",
        "",
      ],
      [
        "F-002",
        getLocalisedText("item_name"),
        getLocalisedText("item_description"),
        "N/A",
        "1",
        "1",
        "",
      ],
      [
        "F-003",
        getLocalisedText("item_name"),
        getLocalisedText("item_description"),
        "N/A",
        "1",
        "1",
        "",
      ],
    ];
    if (documentData === undefined || documentData === null) {
      return dummyData;
    } else {
      var currency = NumberUtility.getCurrency(documentData);
      if(type === "SOP" && (documentData.sopLineItems === undefined || documentData.sopLineItems === null)){
        return dummyData;
      }
      if(type === "Material" && (documentData.machineMaterialItems === undefined || documentData.machineMaterialItems === null)){
        return dummyData;
      }
      if(type === "SOP" && documentData.sopLineItems.length === 0){
        return [];
      }
      if(type === "Material" && documentData.machineMaterialItems.length === 0){
        return [];
      }
      var sortedItems = getSortedLineItems(
        type === "SOP"
          ? documentData.sopLineItems
          : documentData.machineMaterialItems
      );
      var groupItems = this.getGroupData(
        sortedItems,
        documentData,
        currency,
        type
      );

      groupItems.forEach((element: any) => {
        lineItems.push(element);
      });

      return lineItems;
    }
  }

  static parseData(dataFromAPI: any, templateDataToReturn: any) {
    let data = { ...templateDataToReturn };
    // getDateString(new Date(startDateTimeStamp), templateDataToReturn.dateFormat)
    if (
      dataFromAPI.scheduleInfo?.scheduleResponse !== null &&
      dataFromAPI.scheduleInfo?.scheduleResponse !== undefined
    ) {
      data.schedule_start_date = getDateString(
        new Date(dataFromAPI.scheduleInfo?.scheduleResponse?.startDate),
        templateDataToReturn.dateFormat
      );
      data.schedule_end_date = getDateString(
        new Date(dataFromAPI.scheduleInfo?.scheduleResponse?.endDate)
      );
      data.technician_name =dataFromAPI.scheduleInfo?.scheduleResponse?.assignedUserName;
      data.machine_name = dataFromAPI.scheduleInfo?.scheduleResponse?.machine?.name;
      data.schedule_id = dataFromAPI.scheduleInfo?.scheduleResponse?.id;
      let scheduleSOp =
        dataFromAPI.scheduleInfo?.schedulerResponse.schedules[0];
      let machineSopList = dataFromAPI.scheduleInfo?.scheduleResponse?.machine?.sop || [];
      let scheduleSop = scheduleSOp?.sop || [];
      data.sopLineItems =
      machineSopList.length > 0
          ? machineSopList?.map((sop: string) => {
              return {
                name: sop,
                status: scheduleSop?.includes(sop) ? "Completed" : "Not Started",
              };
            })
          : [];
          data.machineMaterialItems =
          dataFromAPI.scheduleInfo?.materialConsumption?.items?.map((material:any, index:number) => {
            if (!material) {
              console.warn("Skipping material item at index", index, "due to missing data");
              return null;}
        
            return {
              ...material,
              lineNumber: index + 1,
              code: material.productCode,
              description: material.description,
              uom: material.uom,
              unitPrice: material.unitPrice,
              amount: material.amount,
              remark: material.remark,
              quantity: material.quantity,
            };
          }) || [];
        
    }
    return { ...data, ...templateDataToReturn };
  }

  static getItemColumn(
    data: any,
    tableStyle: any,
    isStock: boolean = false,
    rawData: any,
    subType: "Material" | "SOP"
  ) {
    var itemColumnList: ItemColumn[][] = [];
    if (data === undefined) {
      return itemColumnList;
    }
    if (data.length === 0) {
      return itemColumnList;
    }
    var isLastRowIndex = data.length - 1;
    data.forEach((column: any[], index: number) => {
      var rowList: ItemColumn[] = [];
      if (isLastRowIndex === index && getIsSelectTotalColumn()) {
        
        column.forEach((element) => {
          var newItemColumn = this.getDefaultItemColumn(
            tableStyle,
            index,
            isStock,
            rawData.printScale
          );
          newItemColumn.value = element;
          newItemColumn.isTotalColumn = true;
          rowList.push(newItemColumn);
        });
      } else {
        column?.forEach((element) => {
          var newItemColumn = this.getDefaultItemColumn(
            tableStyle,
            index,
            isStock,
            rawData.printScale
          );
          newItemColumn.value = element;
          rowList.push(newItemColumn);
        });
      }

      itemColumnList.push(rowList);
    });
    return itemColumnList;
  }

  static getDefaultItemColumn(
    tableStyle: any,
    index: number,
    isStock: boolean,
    printScale: number = PRINT_SCALE
  ) {
    var newItemColumn = new ItemColumn();
    newItemColumn.type = "string";
    newItemColumn.style = new ColumnStyle();
    if (tableStyle.itemBackgroundColorIsAlternate) {
      newItemColumn.style.backgroundColor =
        index % 2 !== 0 ? "transparent" : tableStyle.itemBackgroundColor;
    } else {
      newItemColumn.style.backgroundColor = tableStyle.itemBackgroundColor;
    }
    newItemColumn.style.borderColor = tableStyle.itemBorderColor;
    newItemColumn.style.borderStyle = "solid";
    newItemColumn.style.borderWidth = getVW(
      Utility.convertToPrintScale(1, printScale)
    );
    newItemColumn.style.paddingTop = isStock
      ? getVW(Utility.convertToPrintScale(2.5, printScale))
      : getTablePadding(
          getVW(Utility.convertToPrintScale(13, printScale)),
          TableDimensionType.PADDING_VERTICAL
        );
    newItemColumn.style.paddingBottom = isStock
      ? getVW(Utility.convertToPrintScale(2.5, printScale))
      : getTablePadding(
          getVW(Utility.convertToPrintScale(12.6, printScale)),
          TableDimensionType.PADDING_VERTICAL
        );
    newItemColumn.style.paddingLeft = isStock
      ? getVW(Utility.convertToPrintScale(2.5, printScale))
      : getTablePadding(
          getVW(Utility.convertToPrintScale(11, printScale)),
          TableDimensionType.PADDING_HORIZONTAL
        );
    newItemColumn.style.paddingRight = isStock
      ? getVW(Utility.convertToPrintScale(2.5, printScale))
      : getTablePadding(
          getVW(Utility.convertToPrintScale(11, printScale)),
          TableDimensionType.PADDING_HORIZONTAL
        );

    if (isStock) {
      newItemColumn.style.fontSize = FONT_SIZE.xSmall;
    } else {
      newItemColumn.style.fontSize = Utility.getIsReceiptPaperSize()
        ? FONT_SIZE.large
        : FONT_SIZE.regular;
    }
    return newItemColumn;
  }

  static getFooterRow(data: any, tableColumnOptions: any, tableStyle: any) {
    return [];
  }

  static getAttributeString(customFields: any) {
    var label = "";
    if (customFields !== undefined && customFields !== null) {
      if (customFields.length > 0) {
        customFields.forEach((element: any, index: number) => {
          label = label + element.label + " - " + element.value;
          if (index < customFields.length - 1) {
            label = label + ", ";
          }
        });
      }
    }
    return label;
  }
  static getGroupData(
    items: any,
    documentData: any,
    currency: string,
    subType: "Material" | "SOP"
  ) {
    var lineItems: string[][] = [];
    var newProductGrouping = TemplateSettingsManager.getProductGrouping();
    var grouping = "none";
    if (newProductGrouping !== undefined && newProductGrouping !== null) {
      if (newProductGrouping.selection !== undefined) {
        grouping = newProductGrouping.selection;
      }
    }
    if (grouping === "none") {
      items.forEach((element: any) => {
        if (subType === "Material") {
          lineItems.push(
            new MachineScheduleMaterialItem(
              element,
              documentData,
              currency
            ).toString()
          );
        } else if (subType === "SOP") {
          lineItems.push(
            new MachineScheduleSOPItem(
              element,
              documentData,
              currency
            ).toString()
          );
        }
      });
      return lineItems;
    }
    var attributes: string[] = [];
    this.groupHeaderIndexList = [];
    items.forEach((element: any) => {
      var label = "";
      if (element.customFields !== undefined && element.customFields !== null) {
        label = this.getAttributeString(element.customFields);
      }
      if (!attributes.includes(label)) {
        attributes.push(label);
      }
    });

    attributes = attributes.sort((a: string, b: string) => a.localeCompare(b));
    //split the item based om attr
    var attributesGroupedItems: any[] = [];
    attributes.forEach((element: any) => {
      var groupedItems: any[] = [];
      items.forEach((item: any) => {
        if (this.getAttributeString(item.customFields) === element) {
          groupedItems.push(item);
        }
      });
      if (element === "") {
        element = "none";
      }
      var val = {
        key: element,
        list: groupedItems,
      };
      attributesGroupedItems.push(val);
    });

    attributesGroupedItems.forEach((element) => {
      if (element.list !== undefined && element.list !== null) {
        if (element.list.length > 0) {
          this.groupHeaderIndexList.push(lineItems.length);
          lineItems.push(
            new ProductTableGroupHeaderColumn(element.key).toString()
          );
        }
        element.list.forEach((element: any) => {
          if (subType === "Material") {
            lineItems.push(
              new MachineScheduleMaterialItem(
                element,
                documentData,
                currency
              ).toString()
            );
          } else if (subType === "SOP") {
            lineItems.push(
              new MachineScheduleSOPItem(
                element,
                documentData,
                currency
              ).toString()
            );
          }
        });
      }
    });
    return lineItems;
  }
}
