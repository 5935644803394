import React, { Component } from "react";
import "../../index.css";
import {
  BARCODE_FONT_SIZE,
  BARCODE_FORMAT,
  FONT_SIZE,
} from "../../Constants/Constants";
import { OwnerType } from "../../Helper/AddressParser";
import ComponentManager from "../../Manager/ComponentManager";
import { getLocalisedText } from "../../Translate/LanguageManager";
import Barcode from "react-barcode";
import Utility, {
  getFontSizeClass,
  getPageHeight,
  getTitleCase,
  getVW,
} from "../../Utilities/Utility";
import "./css/Templates.css";
import DocumentManager from "../../Manager/DocumentManager";
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";

export default class WorkOrderTemplate1 extends Component {
  arabicHeaderWidth = "85%";
  spacingRatio = 0.25;
  borderWidth = 2;

  barcodeInitialState = {
    format: BARCODE_FORMAT.CODE_128,
    renderer: "svg",
    width: 2,
    height: 100,
    displayValue: true,
    fontOptions: "",
    font: "ui-sans-serif",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: BARCODE_FONT_SIZE,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 10,
    value: "",
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this.setDummyData(this.props.data),
      barcodeSetting: this.getBarcodeSetting(this.props.data),
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      data: this.setDummyData(nextProp.data),
      barcodeSetting: this.getBarcodeSetting(nextProp.data),
    });
  }

  setDummyData(data) {
    var newData = data;

    newData.leftTableList = [];
    newData.rightTableList = [];

    var documentDetailList = Utility.getDocumentDetailsList(data);

    var newDocumentDetailList = documentDetailList.map((element) => {
      var value = "";
      if (element.key !== undefined) {
        value = data[element.key];
      } else if (element.customFieldValue !== undefined) {
        value = element.customFieldValue;
      }
      var item = {
        label: element.label,
        value,
      };
      return item;
    });

    if (newDocumentDetailList.length > 0) {
      var leftCount = Math.floor(newDocumentDetailList.length / 2);
      newData.leftTableList = newDocumentDetailList.slice(0, leftCount);
      newData.rightTableList = newDocumentDetailList.slice(leftCount);
    }

    // newData.leftTableList = [
    //     { label: 'Sales Order Number', value: '' },
    //     { label: 'Work Order Number', value: '' },
    //     { label: 'Shoe Name', value: 'Made Plus - Skiff' },
    //     { label: 'Shoe Last', value: 'MP02' },
    // ]

    // newData.rightTableList = [
    //     { label: 'Box: #', value: '' },
    //     { label: 'Date:', value: '' },
    //     { label: 'Size', value: 'Made Plus - Skiff' },
    //     { label: 'SKU# ', value: '1012201-001' },
    //     { label: 'Color Way ', value: 'Blk Beauty / Blk Beauty / Poinsettia' },
    // ]

    newData.notes = "<br/><br/><br/><br/><br/>";

    // newData.barcodeList = [
    //     { label: 'Bar Code Knit Upper', value: '' },
    //     { label: 'Bar Code Order Prep', value: '' },
    //     { label: 'Bar Code Stitching', value: '1231231' },
    //     { label: 'Bar Code Bonding', value: '1012201-001' },
    //     { label: 'Bar Code Finishing', value: '1231231' },
    //     { label: 'Bar Code Shipping', value: '1231231' },
    // ]
    return newData;
  }

  getBarcodeSetting(data) {
    var barcodeSettings = this.barcodeInitialState;

    if (
      data.tenantBarcodeSettings !== undefined &&
      data.tenantBarcodeSettings !== null
    ) {
      let tenantBarcodeSettings = data.tenantBarcodeSettings;

      if (
        tenantBarcodeSettings.height !== null &&
        tenantBarcodeSettings.height !== undefined
      ) {
        barcodeSettings.height = Number(tenantBarcodeSettings.height);
      }
      if (
        tenantBarcodeSettings.barcodeType !== null &&
        tenantBarcodeSettings.barcodeType !== undefined
      ) {
        barcodeSettings.format = tenantBarcodeSettings.barcodeType;
      }
      if (
        tenantBarcodeSettings.barcodeType !== null &&
        tenantBarcodeSettings.barcodeType !== undefined
      ) {
        barcodeSettings.format = tenantBarcodeSettings.barcodeType;
      }
      if (
        tenantBarcodeSettings.barcodeTopMargin !== null &&
        tenantBarcodeSettings.barcodeTopMargin !== undefined
      ) {
        barcodeSettings.marginTop = tenantBarcodeSettings.barcodeTopMargin;
      }
      if (
        tenantBarcodeSettings.barcodeLeftMargin !== null &&
        tenantBarcodeSettings.barcodeLeftMargin !== undefined
      ) {
        barcodeSettings.marginLeft = tenantBarcodeSettings.barcodeLeftMargin;
      }
      if (
        tenantBarcodeSettings.barcodeLabelHeight !== null &&
        tenantBarcodeSettings.barcodeLabelHeight !== undefined
      ) {
        barcodeSettings.barcodeLabelHeight = Number(
          tenantBarcodeSettings.barcodeLabelHeight
        );
      }
      if (
        tenantBarcodeSettings.generateBarcodeforJobcardAlongWithOperator !==
          null &&
        tenantBarcodeSettings.generateBarcodeforJobcardAlongWithOperator !==
          undefined
      ) {
        barcodeSettings.displayValue = Boolean(
          tenantBarcodeSettings.generateBarcodeforJobcardAlongWithOperator
        );
      }
    }

    return barcodeSettings;
  }

  getDocumentType() {
    return (
      <div
        className="RowDiv pl-s pt-s pb-s parent-width"
        style={{
          alignItems: "center",
          flexDirection: Utility.getIsArabicLang() ? "row-reverse" : "row",
          width: "100%",
        }}
      >
        <text
          className={"docFontStyleBold" + getFontSizeClass()}
          style={{
            ...Utility.getFontStyleProp(
              { fontSize: FONT_SIZE.large },
              this.state.data.printScale
            ),
          }}
        >
          {getLocalisedText(this.state.data.documentType)}
        </text>
      </div>
    );
  }

  getCompanyHeader() {
    var documentTitle = TemplateSettingsManager.getWorkOrderDocumentTitle() ?? ''

    return (
      <tr>
        <td
          colSpan={2}
          style={{
            border: "2px solid black",
            borderWidth: getVW(
              Utility.convertToPrintScale(
                this.borderWidth,
                this.state.data.printScale
              )
            ),
            borderCollapse: "collapse",
          }}
        >
          <div
            className="RowDiv parent-width"
            style={{
              padding: getVW(
                Utility.convertToPrintScale(
                  10 * this.spacingRatio,
                  this.state.data.printScale
                )
              ),
            }}
          >
            {ComponentManager.getLogoVisibility(
              this.state.data.showCompanyLogo,
              this.state.data.isReadOnlyMode,
              this.state.data.logo
            ) &&
              ComponentManager.getLogo(
                this.state.data.logo,
                Utility.convertToPrintScale(25, this.state.data.printScale),
                {
                  position: "relative",
                }
              )}
            {this.state.data.showCompanyLogo &&
              ComponentManager.addHorizontalSpace(
                Utility.convertToPrintScale(10, this.state.data.printScale)
              )}
            <text
              className={"fontStyle" + getFontSizeClass()}
              style={{
                ...Utility.getFontStyleProp(
                  { fontSize: FONT_SIZE.regular },
                  this.state.data.printScale
                ),
              }}
            >
              {documentTitle}
            </text>
          </div>
        </td>
      </tr>
    );
  }

  renderTableView(dataArray, isShowCompanyHeader) {
    return (
      <table
        style={{
          alignItems: "flex-start",
          justifyContent: "space-between",
          backgroundColor: "white",
          border: "1px solid black",
          borderWidth: getVW(
            Utility.convertToPrintScale(
              this.borderWidth,
              this.state.data.printScale
            )
          ),
          paddingTop: getVW(5 * this.spacingRatio),
          paddingBottom: getVW(5 * this.spacingRatio),
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {isShowCompanyHeader && this.getCompanyHeader()}
          {this.renderTable(dataArray)}
        </tbody>
      </table>
    );
  }

  renderTable(dataArray) {
    if (dataArray !== undefined && dataArray !== null) {
      if (dataArray.length > 0) {
        return dataArray.map((element) => {
          return this.renderTableRow(element);
        });
      }
    }
  }

  renderTableRow(item) {
    var headerStyle = {
      backgroundColor: "rgb(230, 230, 230)",
      color: this.state.data.themeColor,
    };
    return (
      <tr>
        {this.renderTableColumn(item.label, headerStyle, " : ")}
        {this.renderTableColumn(item.value, {})}
      </tr>
    );
  }

  renderTableColumn(value, style, additionalText) {
    return (
      <td
        style={{
          ...Utility.getFontStyleProp(
            { fontSize: FONT_SIZE.regular },
            this.state.data.printScale
          ),
          ...style,
          textAlign: "center",
          border: "2px solid black",
          borderWidth: getVW(
            Utility.convertToPrintScale(
              this.borderWidth,
              this.state.data.printScale
            )
          ),
          borderCollapse: "collapse",
        }}
      >
        {value}
        {additionalText}
      </td>
    );
  }

  renderTopSection() {
    return (
      <div className="RowDiv align-items-start">
        {this.renderTableView(this.state.data.leftTableList, true)}
        {ComponentManager.addHorizontalSpace(20)}
        {this.renderTableView(this.state.data.rightTableList, false)}
      </div>
    );
  }

  renderDocumentTable() {
    return (
      <div className="RowDiv align-items-start parent-width">
        <div className="ColumnDiv parent-width">
          {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
          {this.getEditableDocumentTable()}
          {ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
        </div>
      </div>
    );
  }

  getEditableDocumentTable() {
    return ComponentManager.getDKDocumentTable(
      this.props.tableColumn,
      this.getTableStyle(),
      this.props.data,
      this.props.tableClicked,
      this.props.stockTableClicked,
      this.props.onUpdateDataCallback,
      this.props.paymentTableClicked,
      this.props.additionalTableCallback
    );
  }

  getTableStyle() {
    let style = DocumentManager.getTableDefaultStyle();
    style.headerTextColor = this.state.data.themeColor;
    style.footerTextColor = this.state.data.themeColor;
    style.headerBorderColor = "black";
    style.itemBorderColor = "black";
    style.headerBackgroundColor = "rgb(230, 230, 230)";
    style.headerBorderBottomWidth = getVW(
      Utility.convertToPrintScale(2, this.state.data.printScale)
    );
    return style;
  }

  getCustomFieldTableStyle() {
    let style = {}
    style.columnCount = 4
    style.columnWidths = Utility.getIsArabicLang() ? ['25%', '25%', '25%', '25%'] : ['25%', '25%', '25%', '25%']
    return style;
  }

    
  renderCommentSection() {
    return (
      <div
        className="RowDiv"
        style={{
          width: "-webkit-fill-available",
          paddingTop: getVW(5),
          paddingBottom: getVW(5),
        }}
      >
        <div
          className="ColumnDiv parent-width"
          style={{
            paddingBottom: getVW(5),
            border: "2px solid",
            borderWidth: getVW(
              Utility.convertToPrintScale(
                this.borderWidth,
                this.state.data.printScale
              )
            ),
          }}
        >
          <div
            className="RowDiv parent-width"
            style={{ borderBottom: "2px solid" }}
          >
            <text
              className={"docFontStyleBold" + getFontSizeClass()}
              style={{
                paddingLeft: getVW(10),
                paddingRight: getVW(10),
                paddingBottom: getVW(5),
                color: this.state.data.themeColor,
                ...Utility.getFontStyleProp(
                  { fontSize: FONT_SIZE.regular, ownerType: OwnerType.tenant },
                  this.state.data.printScale
                ),
              }}
            >
              {Utility.getNotesTitle(getLocalisedText("comments"))}
            </text>
          </div>
          <div
            className={"docFontStyle" + getFontSizeClass()}
            style={{
              width: "100%",
              ...Utility.getFontStyleProp(
                { fontSize: FONT_SIZE.regular, ownerType: OwnerType.tenant },
                this.state.data.printScale
              ),
            }}
            dangerouslySetInnerHTML={{
              __html: getLocalisedText(this.state.data.notes),
            }}
          ></div>
        </div>
      </div>
    );
  }

  renderBarcodeListSection() {
    if (
      this.state.data.barcodeList === undefined ||
      this.state.data.barcodeList === null
    ) {
      return <></>;
    }

    if (this.state.data.barcodeList.length === 0) {
      return <></>;
    }

    var leftCount = Math.floor(this.state.data.barcodeList.length / 2);
    var leftColumnList = this.state.data.barcodeList.slice(0, leftCount + 1);
    var rightColumnList = this.state.data.barcodeList.slice(leftCount + 1);

    var isPaddingNeeded = this.state.data.barcodeList.length > 2;

    return (
      <div
        className="RowDiv align-items-start parent-width"
        style={{
          // paddingTop: getVW(5),
          paddingBottom: getVW(5),
        }}
      >
        {this.renderBarcodeColumn(leftColumnList)}
        {isPaddingNeeded &&
          ComponentManager.addHorizontalSpace(
            Utility.convertToPrintScale(20, this.state.data.printScale)
          )}
        {this.renderBarcodeColumn(rightColumnList)}
      </div>
    );
  }

  renderBarcodeColumn(barcodeList) {
    if (barcodeList.length > 0) {
      var columnData = barcodeList.map((item) => {
        return (
          <div
            className="RowDiv parent-width"
            style={{
              paddingTop: getVW(5),
            }}
          >
            {this.renderBarcodeView(item)}
          </div>
        );
      });

      return <div className="ColumnDiv parent-width">{columnData}</div>;
    }
    return <></>;
  }

  getMaxHeight() {
    var height = 70;
    let resizeValue = TemplateSettingsManager.getBarcodeSettings(
      "resizeValue",
      0
    );

    if (resizeValue !== undefined && resizeValue !== null) {
      if (resizeValue > 0) {
        height += resizeValue / 14;
      }
    }
    return height;
  }

  getBarcodeSize() {
    var height = 350;
    let resizeValue = TemplateSettingsManager.getBarcodeSettings(
      "resizeValue",
      0
    );

    if (resizeValue !== undefined && resizeValue !== null) {
      height += resizeValue;
    }
    return height;
  }

  renderBarcodeView(item) {
    let barcodeViewDivClassName = "RowDiv docFontStyle"
    if (!TemplateSettingsManager.getWorkOrderShowBarcode()) {
      barcodeViewDivClassName = "ColumnDiv docFontStyle"
    }

    let barcodeViewDivHeight = getVW(
      Utility.convertToPrintScale(
        this.getMaxHeight(),
        this.state.data.printScale
      )
    )

    if (TemplateSettingsManager.getWorkOrderShowInstruction()) {
      barcodeViewDivHeight = undefined
    }

    return (
      <div
        className="RowDiv parent-width"
        style={{
          paddingTop: getVW(5 * this.spacingRatio),
          paddingBottom: getVW(5 * this.spacingRatio),
          border: "2px solid",
          borderWidth: getVW(
            Utility.convertToPrintScale(
              this.borderWidth,
              this.state.data.printScale
            )
          ),
        }}
      >
        <div
          className="ColumnDiv parent-width"
          style={{
            paddingBottom: getVW(5),
          }}
        >
          <div
            className="RowDiv parent-width"
            style={{
              borderBottom: "1px solid rgb(0,0,0,.5)",
              backgroundColor: "rgb(230, 230, 230)",
              justifyContent: "center",
            }}
          >
            <text
              className={"docFontStyleBold" + getFontSizeClass()}
              style={{
                paddingLeft: getVW(10),
                paddingRight: getVW(10),
                paddingBottom: getVW(5),
                alignSelf: "center",
                color: this.state.data.themeColor,
                ...Utility.getFontStyleProp(
                  { fontSize: FONT_SIZE.regular, ownerType: OwnerType.tenant },
                  this.state.data.printScale
                ),
              }}
            >
              {item.label}
            </text>
          </div>
          <div
            className={barcodeViewDivClassName + getFontSizeClass()}
            style={{
              height: barcodeViewDivHeight,
              alignSelf: TemplateSettingsManager.getWorkOrderShowBarcode() ? "center" : undefined,
            }}
          >
            {item.instructions !== "" && TemplateSettingsManager.getWorkOrderShowInstruction() && this.renderInstruction(item)}
            {item.value !== "" && TemplateSettingsManager.getWorkOrderShowBarcode() && this.renderBarcode(item)}
            {item.instructions !== "" && TemplateSettingsManager.getWorkOrderShowBarcode() && TemplateSettingsManager.getWorkOrderShowInstruction() && this.renderInstructionEmptyContainer(item)}
          </div>
        </div>
      </div>
    );
  }

  renderInstruction = (item) => {
    let decodeString = ""

    if(item.instructions !== "") {
      decodeString = atob(item.instructions)
      decodeString = decodeURIComponent(decodeString)
    }
    
    return (
      <div className={"ColumnDiv"}
        style={{
          flex: 1,
          justifyContent: 'center',
          paddingLeft: getVW(5),
        }}
        dangerouslySetInnerHTML={{ __html: decodeString }}
      />
    )
  }

  renderInstructionEmptyContainer = (item) => {
    return (
      <div className="RowDiv"
        style={{
          flex: 1,
        }}
      />
    )
  }

  renderBarcode = (item) => {
    let setting = this.state.barcodeSetting;

    return (
      <div
        className="RowDiv"
        id="barcode-svg"
        // className="position-absolute"
        style={{ top: setting.marginTop, flex: 1, justifyContent: 'center'}}
      >
        <div className="ColumnDiv" style={{
          alignItems: 'center',
          ...Utility.getFontStyleProp(
            { fontSize: FONT_SIZE.small, ownerType: OwnerType.tenant },
            this.state.data.printScale
          ),
        }}>
          <Barcode
            value={item.value}
            width={1.2}
            // height={getVW(setting.height)}
            height={getVW(this.getBarcodeSize())}
            format={BARCODE_FORMAT[setting.format]}
            displayValue={setting.displayValue}
            fontOptions={setting.fontOptions}
            font={setting.font}
            textAlign={setting.textAlign}
            textPosition={setting.textPosition}
            textMargin={setting.textMargin}
            fontSize={setting.fontSize}
            background={setting.background}
            lineColor={setting.lineColor}
            margin={setting.margin}
            marginBottom={setting.marginBottom}
            marginRight={setting.marginRight}
          />
          {item.status !== '' && TemplateSettingsManager.getWorkOrderShowJobCardStatus() && getTitleCase(item.status)}
          {item.bottomLabel !== '' && item.bottomLabel}
        </div>
      </div>
    );
  };

  renderAdHocMessage = () => {
    return (
      this.props.data?.tenantInfo?.additionalSettings?.ADHOC_BOM?.enable ===
        true && (
        <div className="RowDiv align-items-start parent-width">
          <text className="text-left">
            {getLocalisedText('adhoc_items_message')}
          </text>
        </div>
      )
    );
  };

  getIsShowTaggedBinWarehouse = (position) => {
    const isShow = TemplateSettingsManager.getWorkOrderShowTaggedBinWarehouse(position)

    const taggedBinWarehouseInfo = this.props.data?.taggedBinWarehouseInfo ?? undefined

    if (taggedBinWarehouseInfo !== undefined && taggedBinWarehouseInfo !== null) {
      const barcode = taggedBinWarehouseInfo.barcode ?? undefined
      
      if(barcode !== '' && isShow) {
        return true
      }
    } 

    return false
  }

  renderTaggedBinWarehouse = (position) => {
    let showTaggedBinWarehouse = this.getIsShowTaggedBinWarehouse(position)

    if(!showTaggedBinWarehouse) {
      return <></>
    }

    const barcode = this.props.data?.taggedBinWarehouseInfo?.barcode ?? ''

    const binName = this.props.data?.taggedBinWarehouseInfo?.name ?? ''
    const rowName = this.props.data?.taggedBinWarehouseInfo?.rowName ?? ''
    const rackName = this.props.data?.taggedBinWarehouseInfo?.rackName ?? ''
    const label = TemplateSettingsManager.getWorkOrderTaggedBinWarehouseTitle() ?? ''
    let bottomLabel = ''
    if (rowName !== '') {
      bottomLabel = rowName + '/'
    }
    if (rackName !== '') {
      bottomLabel = bottomLabel + rackName + '/'
    }
    if (binName !== '') {
      bottomLabel = bottomLabel + binName
    }

    let item = {
      label: label !== '' ? label : '  ',
      value: barcode,
      instructions: '',
      status: '',
      bottomLabel: bottomLabel,
    }

    return <div style={{
      paddingTop: position === 'bottom' ? getVW(5 * this.spacingRatio) : undefined,
    }}>
      {this.renderBarcodeView(item)}
    </div>
  }

  render() {
    return (
      <div
        className="DocumentHolder ColumnDiv"
        style={{ minHeight: getPageHeight(this.state.data.printScale) }}
      >
        <div
          className="parent-width"
          style={{ width: "100%", paddingBottom: getVW(30) }}
        >
          {this.renderTaggedBinWarehouse('top')}
          {this.getDocumentType()}
          {this.renderTopSection()}
          {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
          <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'} isThemeColor isShowGrid />
          {
            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
            ComponentManager.addVerticalSpace(20 * this.spacingRatio)
          }
          {
            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
            <>
              <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
            </>
          }
          {
            TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
            ComponentManager.addVerticalSpace(20 * this.spacingRatio)
          }
          {this.renderDocumentTable()}
          {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(20 * this.spacingRatio)}
          <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} isThemeColor isShowGrid />
          {this.renderCommentSection()}
          {this.renderBarcodeListSection()}
          {this.renderTaggedBinWarehouse('bottom')}
          {this.renderAdHocMessage()}
        </div>
      </div>
    );
  }
}
