import ApiConstants from '../Constants/ApiConstants';
import {
  APP_NAME,
  BUY_BILL,
  EMAIL_PAYLOAD,
  INVOICE,
  ORDER,
  PRINT_PAYLOAD_KEYS,
  SALES_ORDER,
  PURCHASE_REQUEST,
  PURCHASE_REQUEST_FOR_QUOTES
} from '../Constants/Constant';
import { ISavedMailTemplate } from '../Models/Interfaces';
import { PrintDocument } from '../Models/PrintDocument';
import { getPrecedingURLForEmailBody } from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

class EmailService {
  static getEmailSettings(): Promise<any> {
    const endPoint = ApiConstants.URL.EMAIL.SMTP_SETTINGS;
    return http.get(endPoint);
  }
  static deleteEmailSettings(): Promise<any> {
    const endPoint = ApiConstants.URL.EMAIL.SMTP_SETTINGS;
    return http.delete(endPoint);
  }

  static getSavedEmailTemplate(documentType: any): Promise<ISavedMailTemplate> {
    const endPoint = ApiConstants.URL.EMAIL.GET_CUSTOM_TEMPLATE(
      documentType,
      APP_NAME
    );
    return http.get(endPoint);
  }

  static setTemplate(payload: any) {
    const endPoint = ApiConstants.URL.EMAIL.ADD_CUSTOM_TEMPLATE;
    return http.post(endPoint, payload);
  }

  static sendEmail(
    category: any,
    documentClone: any,
    tenantDetails: any,
    email: any,
    moduleCategory: any,
    sendEmailPayload: any,
    templateId?: string,
    currencyDetails?: any,
    isCustomTemplate?: boolean
  ) {
    const firstEmail = email.bcc?.split(',')?.[0];
    const bccEmailsWithoutFirstEmail = email.bcc
      ?.split(',')
      ?.filter((item: any) => item !== firstEmail)
      ?.join(',');
    let emailRequest = {
      [EMAIL_PAYLOAD.application]: APP_NAME,
      [EMAIL_PAYLOAD.category]: category,
      [EMAIL_PAYLOAD.body]: email.body,
      [EMAIL_PAYLOAD.to]:
        moduleCategory !== PURCHASE_REQUEST_FOR_QUOTES ? email.to : firstEmail,
      [EMAIL_PAYLOAD.subject]: email.subject,
      [EMAIL_PAYLOAD.replyTo]: email.replyTo,
      [EMAIL_PAYLOAD.senderName]: email.senderName,
      [EMAIL_PAYLOAD.bcc]: bccEmailsWithoutFirstEmail,
      [EMAIL_PAYLOAD.cc]: email.cc
    };

    if (moduleCategory !== PURCHASE_REQUEST_FOR_QUOTES) {
      emailRequest = {
        ...emailRequest,
        exportDocumentRequest: {
          ...this.getPrintObject(
            documentClone,
            tenantDetails,
            moduleCategory,
            templateId,
            currencyDetails,
            isCustomTemplate
          ),
          skipDocumentTemplateMapping: true
        }
      };
    }

    sendEmailPayload.sendEmailRequest = emailRequest;

    if (moduleCategory === PURCHASE_REQUEST_FOR_QUOTES) {
      sendEmailPayload = {
        ...sendEmailPayload,
        rfqCode: documentClone?.rfqCode,
        rfqFormUrl: getPrecedingURLForEmailBody(documentClone?.rfqCode)
      };
    }

    let endPointUrl;
    if (
      moduleCategory === ORDER ||
      moduleCategory === BUY_BILL ||
      moduleCategory === PURCHASE_REQUEST
    ) {
      endPointUrl = ApiConstants.URL.EMAIL.SEND_EMAIL_PURCHASE;
    } else if (moduleCategory === SALES_ORDER) {
      endPointUrl = ApiConstants.URL.EMAIL.SEND_EMAIL_SO;
    } else if (moduleCategory === PURCHASE_REQUEST_FOR_QUOTES) {
      endPointUrl = ApiConstants.URL.EMAIL.SEND_EMAIL_RFQ;
    } else {
      endPointUrl = ApiConstants.URL.EMAIL.SEND_EMAIL_SALE;
    }

    return http.post(endPointUrl, sendEmailPayload);
  }

  static getPrintObject(
    document: any,
    _tenantDetails: any,
    _moduleName: any,
    templateId?: string,
    currencyDetails?: any,
    isCustomTemplate = false
  ) {
    let invoicePayLoad = new PrintDocument(document, _tenantDetails) as any;
    if (_moduleName === INVOICE) {
      invoicePayLoad[PRINT_PAYLOAD_KEYS.invoiceNumber] =
        document.documentSequenceCode;
      invoicePayLoad.setTransactions(document);
    } else if (_moduleName === SALES_ORDER) {
      invoicePayLoad[PRINT_PAYLOAD_KEYS.salesOrderNumber] =
        document.documentSequenceCode;
    } else if (_moduleName === ORDER) {
      invoicePayLoad[PRINT_PAYLOAD_KEYS.orderNo] =
        document.documentSequenceCode;
    } else if (_moduleName === BUY_BILL) {
      invoicePayLoad[PRINT_PAYLOAD_KEYS.BillNo] = document.documentCode;
      invoicePayLoad.setTransactionsForBill(document);
    } else {
      invoicePayLoad[PRINT_PAYLOAD_KEYS.quoteNumber] =
        document.documentSequenceCode;
    }
    const newCurrencyDetails = {
      currency: currencyDetails.currencyCode,
      currencySymbol: currencyDetails.currencySymbol,
      currencyName: currencyDetails.currencyName
    };
    const newPayload = {
      ...invoicePayLoad,
      dueAmount: document.dueAmount,
      ...newCurrencyDetails
    };
    return {
      headers: [newPayload],
      documentNumber: document.documentSequenceCode,
      [PRINT_PAYLOAD_KEYS.module_name]: 'ERP',
      [PRINT_PAYLOAD_KEYS.category_name]: _moduleName,
      [PRINT_PAYLOAD_KEYS.template_id]: templateId ? templateId : null,
      [PRINT_PAYLOAD_KEYS.IS_CUSTOM_TEMPLATE]: isCustomTemplate,
      [PRINT_PAYLOAD_KEYS.DOCUMENT_TYPE_KEY]: document.documentType,
      [PRINT_PAYLOAD_KEYS.DOCUMENT_CODE_KEY]: invoicePayLoad.BillNo
    };
  }
  static testSMTPEmail(payload: any) {
    const endPoint = ApiConstants.URL.EMAIL.SMTP_SETTINGS_TEST_MAIL;
    return http2.post(endPoint, payload).catch((error) => {
      return Promise.reject(error);
    });
  }
  static saveSMTPDetails(payload: any) {
    const endPoint = ApiConstants.URL.EMAIL.SMTP_SETTINGS;
    return http.post(endPoint, payload).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default EmailService;
