import React, { Component } from 'react';

import Lottie from 'react-lottie';
import animationData from './AI_Animation_1.json';

/*
PROPS
- width
- height
*/

class AIAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData
    };

    return (
      <Lottie
        options={defaultOptions}
        height={this.props.width}
        width={this.props.height}
      />
    );
  }
}

export default AIAnimation;
