// Import createSlice from redux toolkit
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  API_STATUS,
  CUSTOM_NUMBER_INPUT_MODULES
} from '../../Constants/Constant';
import CustomNumberFormatService from '../../Services/CustomNumberFormat';
import { RootState } from '../Store';
import { generateSequenceCodeWithPrefixSuffix } from '../../SharedComponents/CustomNumberFormat/CustomNumber';

export interface ICustomNumberFormatStored {
  text: string;
  module: CUSTOM_NUMBER_INPUT_MODULES;
  application: 'ERP';
  tenantId: number;
  prefix: string;
  prefixSeparator: string;
  sequenceLength: number;
  nextCode: number;
  sequenceCode: string;
  isDefault: boolean;
  isDeleted: boolean;
  status: number;
  id: string;
}

type ICustomNumberFormatResponse = Omit<ICustomNumberFormatStored, 'text'>;

export interface CustomNumberFormatState {
  contactSequence: ICustomNumberFormatStored[];
  productSequence: ICustomNumberFormatStored[];
  accountSequence: ICustomNumberFormatStored[];
  quotesSequence: ICustomNumberFormatStored[];
  salesOrderSequence: ICustomNumberFormatStored[];
  invoiceSequence: ICustomNumberFormatStored[];
  billsSequence: ICustomNumberFormatStored[];
  ordersSequence: ICustomNumberFormatStored[];
  requisitionSequence: ICustomNumberFormatStored[];
  fixedAssetSequence: ICustomNumberFormatStored[];
  machineSequence: ICustomNumberFormatStored[];
  supplierquotesSequence: ICustomNumberFormatStored[];
  jobWorkOutSequence: ICustomNumberFormatStored[];
  creditNoteSequence: ICustomNumberFormatStored[];
  debitNodeSequence: ICustomNumberFormatStored[];
  buildAssemblySequence: ICustomNumberFormatStored[];
  journalEntrySequence: ICustomNumberFormatStored[];
  sellSequence: ICustomNumberFormatStored[];
  buySequence: ICustomNumberFormatStored[];
  workOrderSequence: ICustomNumberFormatStored[];
  productionPlan: ICustomNumberFormatStored[];
  jobCardSequence: ICustomNumberFormatStored[];
  securityGateEntrySequence: ICustomNumberFormatStored[];
  purchaseRequestQuotesSequence: ICustomNumberFormatStored[];
  fullQuote: ICustomNumberFormatResponse[];
  fullSalesOrder: ICustomNumberFormatResponse[];
  fullInvoice: ICustomNumberFormatResponse[];
  fullOrder: ICustomNumberFormatResponse[];
  fullRequisition: ICustomNumberFormatResponse[];
  fullFixedAsset: ICustomNumberFormatResponse[];
  fullSupplierquotes: ICustomNumberFormatResponse[];
  fullBill: ICustomNumberFormatResponse[];
  status: API_STATUS;
  isLoading: boolean;
}

type StoredModuleFormatKeys =
  | 'contactSequence'
  | 'productSequence'
  | 'accountSequence'
  | 'quotesSequence'
  | 'salesOrderSequence'
  | 'invoiceSequence'
  | 'billsSequence'
  | 'ordersSequence'
  | 'requisitionSequence'
  | 'fixedAssetSequence'
  | 'supplierquotesSequence'
  | 'jobWorkOutSequence'
  | 'creditNoteSequence'
  | 'debitNodeSequence'
  | 'buildAssemblySequence'
  | 'journalEntrySequence'
  | 'sellSequence'
  | 'buySequence'
  | 'workOrderSequence'
  | 'productionPlan'
  | 'securityGateEntrySequence'
  | 'jobCardSequence';

// initialized initial state of custom number format
const initialState: CustomNumberFormatState = {
  contactSequence: [],
  productSequence: [],
  accountSequence: [],
  quotesSequence: [],
  salesOrderSequence: [],
  invoiceSequence: [],
  billsSequence: [],
  ordersSequence: [],
  jobWorkOutSequence: [],
  requisitionSequence: [],
  fixedAssetSequence: [],
  machineSequence: [],
  supplierquotesSequence: [],
  creditNoteSequence: [],
  debitNodeSequence: [],
  buildAssemblySequence: [],
  journalEntrySequence: [],
  sellSequence: [],
  buySequence: [],
  workOrderSequence: [],
  productionPlan: [],
  jobCardSequence: [],
  purchaseRequestQuotesSequence: [],

  fullQuote: [],
  fullSalesOrder: [],
  fullInvoice: [],
  fullOrder: [],
  fullRequisition: [],
  fullFixedAsset: [],
  fullSupplierquotes: [],
  fullBill: [],
  securityGateEntrySequence: [],
  status: API_STATUS.IDLE,
  isLoading: false
};

export const fetchCustomNumberFormatForAll = createAsyncThunk(
  'fetch_custom_number_formats',
  async (useSeparateSequenceFormat: boolean) => {
    let QUOTES_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let SALES_ORDER_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let INVOICES_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let BILLS_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let ORDER_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let REQUISITION_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let FIXED_ASSET_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] =
      [];
    let PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE: ICustomNumberFormatResponse[] =
      [];
    let CONTACT_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let PRODUCT_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let ACCOUNT_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let CREDIT_NOTE_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let DEBIT_NOTE_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] =
      [];
    let SELL_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let BUY_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let JOURNAL_ENTRY_SEQUENCE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let WORK_ORDER_SEQUENCE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let JOB_CARD_SEQUENCE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let PRODUCTION_PLAN_SEQUENCE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let JOB_WORK_OUT_SEQUENCE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let MACHINE_SEQUENCE_CODE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let SECURITY_ENTRY_GATE_RESPONSE: ICustomNumberFormatResponse[] = [];
    let response: any =
      await CustomNumberFormatService.getCustomNumberFormatAll([
        CUSTOM_NUMBER_INPUT_MODULES.SELL,
        CUSTOM_NUMBER_INPUT_MODULES.BUY,
        CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT,
        CUSTOM_NUMBER_INPUT_MODULES.FIXED_ASSET,
        CUSTOM_NUMBER_INPUT_MODULES.INVOICE,
        CUSTOM_NUMBER_INPUT_MODULES.JOB_CARD,
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT,
        CUSTOM_NUMBER_INPUT_MODULES.BILL,
        CUSTOM_NUMBER_INPUT_MODULES.BUILD_ASSEMBLY,
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT,
        CUSTOM_NUMBER_INPUT_MODULES.CREDIT_NOTE,
        CUSTOM_NUMBER_INPUT_MODULES.DEBIT_NOTE,
        CUSTOM_NUMBER_INPUT_MODULES.EXPENSE_BILL,
        CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY,
        CUSTOM_NUMBER_INPUT_MODULES.OPERATOR,
        CUSTOM_NUMBER_INPUT_MODULES.WORK_ORDER,
        CUSTOM_NUMBER_INPUT_MODULES.SUPPLIER_QUOTE,
        CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER,
        CUSTOM_NUMBER_INPUT_MODULES.RFQ,
        CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST_FOR_QUOTE,
        CUSTOM_NUMBER_INPUT_MODULES.REQUISITION,
        CUSTOM_NUMBER_INPUT_MODULES.PRODUCT,
        CUSTOM_NUMBER_INPUT_MODULES.PRODUCTION_PLAN,
        CUSTOM_NUMBER_INPUT_MODULES.QUOTE,
        CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST,
        CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER,
        CUSTOM_NUMBER_INPUT_MODULES.SECURITY_ENTRY_GATE,
        CUSTOM_NUMBER_INPUT_MODULES.MACHINE
      ]);

    response?.sequenceFormats.forEach((data: ICustomNumberFormatResponse) => {
      switch (data.module) {
        case CUSTOM_NUMBER_INPUT_MODULES.CONTACT:
          CONTACT_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.PRODUCT:
          PRODUCT_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT:
          ACCOUNT_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.CREDIT_NOTE:
          CREDIT_NOTE_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.DEBIT_NOTE:
          DEBIT_NOTE_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.BUILD_ASSEMBLY:
          BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY:
          JOURNAL_ENTRY_SEQUENCE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.WORK_ORDER:
          WORK_ORDER_SEQUENCE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.JOB_CARD:
          JOB_CARD_SEQUENCE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.PRODUCTION_PLAN:
          PRODUCTION_PLAN_SEQUENCE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.SELL:
          SELL_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.BUY:
          BUY_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT:
          JOB_WORK_OUT_SEQUENCE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.QUOTE:
          QUOTES_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER:
          SALES_ORDER_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.INVOICE:
          INVOICES_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.BILL:
          BILLS_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER:
          ORDER_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST:
          REQUISITION_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.FIXED_ASSET:
          FIXED_ASSET_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.MACHINE:
          MACHINE_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.SUPPLIER_QUOTE:
          SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.SECURITY_ENTRY_GATE:
          SECURITY_ENTRY_GATE_RESPONSE.push(data);
          break;
        case CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST_FOR_QUOTE:
          PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE.push(data);
          break;
      }
    });

    return {
      CONTACT_SEQUENCE_CODE_RESPONSE,
      PRODUCT_SEQUENCE_CODE_RESPONSE,
      ACCOUNT_SEQUENCE_CODE_RESPONSE,
      QUOTES_SEQUENCE_CODE_RESPONSE,
      SALES_ORDER_SEQUENCE_CODE_RESPONSE,
      INVOICES_SEQUENCE_CODE_RESPONSE,
      BILLS_SEQUENCE_CODE_RESPONSE,
      ORDER_SEQUENCE_CODE_RESPONSE,
      REQUISITION_SEQUENCE_CODE_RESPONSE,
      FIXED_ASSET_SEQUENCE_CODE_RESPONSE,
      SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE,
      SECURITY_ENTRY_GATE_RESPONSE,
      CREDIT_NOTE_SEQUENCE_CODE_RESPONSE,
      DEBIT_NOTE_SEQUENCE_CODE_RESPONSE,
      BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE,
      SELL_SEQUENCE_CODE_RESPONSE,
      BUY_SEQUENCE_CODE_RESPONSE,
      JOURNAL_ENTRY_SEQUENCE_RESPONSE,
      WORK_ORDER_SEQUENCE_RESPONSE,
      PRODUCTION_PLAN_SEQUENCE_RESPONSE,
      JOB_CARD_SEQUENCE_RESPONSE,
      JOB_WORK_OUT_SEQUENCE_RESPONSE,
      MACHINE_SEQUENCE_CODE_RESPONSE,
      PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE
    };
  }
);

export const fetchContactCustomNumberFormat = createAsyncThunk(
  'fetch_contact_custom_number_formats',
  async () => {
    let CONTACT_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.CONTACT
      );
    return {
      CONTACT_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchProductCustomNumberFormat = createAsyncThunk(
  'fetch_product_custom_number_formats',
  async () => {
    let PRODUCT_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.PRODUCT
      );
    return {
      PRODUCT_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchAccountCustomNumberFormat = createAsyncThunk(
  'fetch_account_custom_number_formats',
  async () => {
    let ACCOUNT_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.ACCOUNT
      );
    return {
      ACCOUNT_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchBuyDocumentsCustomNumberFormat = createAsyncThunk(
  'fetch_buy_documents_custom_number_formats',
  async () => {
    let BUY_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      BUY_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchSellDocumentsCustomNumberFormat = createAsyncThunk(
  'fetch_sell_documents_custom_number_formats',
  async () => {
    let SELL_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      SELL_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchQuotesCustomNumberFormat = createAsyncThunk(
  'fetch_quotes_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let QUOTES_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.QUOTE
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      QUOTES_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchSalesOrderCustomNumberFormat = createAsyncThunk(
  'fetch_sales_order_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let SALES_ORDER_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.SALES_ORDER
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      SALES_ORDER_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchInvoiceCustomNumberFormat = createAsyncThunk(
  'fetch_invoice_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let INVOICES_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.INVOICE
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      INVOICES_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchBillsCustomNumberFormat = createAsyncThunk(
  'fetch_bills_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let BILLS_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.BILL
          : CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      BILLS_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchRequisitionCustomNumberFormat = createAsyncThunk(
  'fetch_requisition_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let REQUISITION_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST
          : CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      REQUISITION_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchFixedAssetCustomNumberFormat = createAsyncThunk(
  'fetch_fixed_asset_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let FIXED_ASSET_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.FIXED_ASSET
          : CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      FIXED_ASSET_SEQUENCE_CODE_RESPONSE
    };
  }
);
export const fetchMachineCustomNumberFormat = createAsyncThunk(
  'fetch_machine_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let MACHINE_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.MACHINE
          : CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      MACHINE_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchSupplierquoteCustomNumberFormat = createAsyncThunk(
  'fetch_supplierQuote_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.SUPPLIER_QUOTE
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE
    };
  }
);
export const fetchPurchaseRequestForQuoteCustomNumberFormat = createAsyncThunk(
  'fetch_supplierQuote_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_REQUEST_FOR_QUOTE
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE
    };
  }
);
export const fetchSeCurityGateEntryNumberFormat = createAsyncThunk(
  'fetch_security_entry_gate_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let SECURITY_ENTRY_GATE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.SECURITY_ENTRY_GATE
          : CUSTOM_NUMBER_INPUT_MODULES.SELL
      );
    return {
      SECURITY_ENTRY_GATE_RESPONSE
    };
  }
);

export const fetchOrdersCustomNumberFormat = createAsyncThunk(
  'fetch_orders_custom_number_formats',
  async (useSeparateSequenceFormat?: boolean) => {
    let ORDER_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        useSeparateSequenceFormat
          ? CUSTOM_NUMBER_INPUT_MODULES.PURCHASE_ORDER
          : CUSTOM_NUMBER_INPUT_MODULES.BUY
      );
    return {
      ORDER_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchCreditNotesCustomNumberFormat = createAsyncThunk(
  'fetch_credit_notes_custom_number_formats',
  async () => {
    let CREDIT_NOTE_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.CREDIT_NOTE
      );
    return {
      CREDIT_NOTE_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchDebitNotesCustomNumberFormat = createAsyncThunk(
  'fetch_debit_notes_custom_number_formats',
  async () => {
    let DEBIT_NOTE_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.DEBIT_NOTE
      );
    return {
      DEBIT_NOTE_SEQUENCE_CODE_RESPONSE
    };
  }
);
export const fetchBuildAssemblyCustomNumberFormat = createAsyncThunk(
  'fetch_build_assembly_custom_number_formats',
  async () => {
    let BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.BUILD_ASSEMBLY
      );
    return {
      BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE
    };
  }
);
export const fetchJournalEntryCustomNumberFormat = createAsyncThunk(
  'fetch_journal_entry_custom_number_formats',
  async () => {
    let JOURNAL_ENTRY_SEQUENCE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY
      );
    return {
      JOURNAL_ENTRY_SEQUENCE_RESPONSE
    };
  }
);

export const fetchWorkOrderCustomNumberFormat = createAsyncThunk(
  'fetch_work_order_custom_number_formats',
  async () => {
    let WORK_ORDER_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.WORK_ORDER
      );
    return {
      WORK_ORDER_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchJobCardCustomNumberFormat = createAsyncThunk(
  'fetch_job_card_custom_number_formats',
  async () => {
    let JOB_CARD_SEQUENCE_CODE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.JOB_CARD
      );
    return {
      JOB_CARD_SEQUENCE_CODE_RESPONSE
    };
  }
);

export const fetchProductionPlanCustomNumberFormat = createAsyncThunk(
  'fetch_production_plan_custom_number_formats',
  async () => {
    let PRODUCTION_PLAN_SEQUENCE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.PRODUCTION_PLAN
      );
    return {
      PRODUCTION_PLAN_SEQUENCE_RESPONSE
    };
  }
);

export const fetchJobWorkOutCustomNumberFormat = createAsyncThunk(
  'fetch_job_work_out_custom_number_formats',
  async () => {
    let JOB_WORK_OUT_SEQUENCE_RESPONSE: any =
      await CustomNumberFormatService.getCustomNumberFormat(
        CUSTOM_NUMBER_INPUT_MODULES.JOB_WORK_OUT
      );
    return {
      JOB_WORK_OUT_SEQUENCE_RESPONSE
    };
  }
);

const getSequenceFormatTextFromResponse = (
  data: ICustomNumberFormatResponse
) => {
  return data.sequenceCode?.includes(data.prefix)
    ? data.sequenceCode
    : generateSequenceCodeWithPrefixSuffix(data);
};

// Creating slice for custom number format
export const CustomNumberFormat = createSlice({
  name: 'custom_number_format',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomNumberFormatForAll.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchCustomNumberFormatForAll.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data = action.payload;

        for (const property in data) {
          switch (property) {
            case 'CONTACT_SEQUENCE_CODE_RESPONSE':
              state.contactSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.contactSequence.push(payload);
              });
              break;
            case 'PRODUCT_SEQUENCE_CODE_RESPONSE':
              state.productSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.productSequence.push(payload);
              });
              break;
            case 'ACCOUNT_SEQUENCE_CODE_RESPONSE':
              state.accountSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.accountSequence.push(payload);
              });
              break;
            case 'QUOTES_SEQUENCE_CODE_RESPONSE':
              state.quotesSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.quotesSequence.push(payload);
              });
              state.fullQuote = data[property];
              break;
            case 'SALES_ORDER_SEQUENCE_CODE_RESPONSE':
              state.salesOrderSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.salesOrderSequence.push(payload);
              });
              state.fullSalesOrder = data[property];
              break;
            case 'INVOICES_SEQUENCE_CODE_RESPONSE':
              state.invoiceSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.invoiceSequence.push(payload);
              });
              state.fullInvoice = data[property];
              break;
            case 'BILLS_SEQUENCE_CODE_RESPONSE':
              state.billsSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.billsSequence.push(payload);
              });
              state.fullBill = data[property];
              break;
            case 'ORDER_SEQUENCE_CODE_RESPONSE':
              state.ordersSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.ordersSequence.push(payload);
              });
              state.fullOrder = data[property];
              break;
            case 'REQUISITION_SEQUENCE_CODE_RESPONSE':
              state.requisitionSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.requisitionSequence.push(payload);
              });
              state.fullRequisition = data[property];
              break;
            case 'FIXED_ASSET_SEQUENCE_CODE_RESPONSE':
              state.fixedAssetSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.fixedAssetSequence.push(payload);
              });
              state.fullFixedAsset = data[property];
              break;
            case 'MACHINE_SEQUENCE_CODE_RESPONSE':
              state.machineSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.machineSequence.push(payload);
              });
              break;
            case 'SUPPLIER_QUOTE_SEQUENCE_CODE_RESPONSE':
              state.supplierquotesSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: data.sequenceCode,
                  ...data
                };
                state.supplierquotesSequence.push(payload);
              });
              state.fullSupplierquotes = data[property];
              break;
            case 'CREDIT_NOTE_SEQUENCE_CODE_RESPONSE':
              state.creditNoteSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.creditNoteSequence.push(payload);
              });
              break;
            case 'DEBIT_NOTE_SEQUENCE_CODE_RESPONSE':
              state.debitNodeSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.debitNodeSequence.push(payload);
              });
              break;
            case 'BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE':
              state.buildAssemblySequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.buildAssemblySequence.push(payload);
              });
              break;
            case 'JOURNAL_ENTRY_SEQUENCE_RESPONSE':
              state.journalEntrySequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.journalEntrySequence.push(payload);
              });
              break;
            case 'SELL_SEQUENCE_CODE_RESPONSE':
              state.sellSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.sellSequence.push(payload);
              });
              break;
            case 'BUY_SEQUENCE_CODE_RESPONSE':
              state.buySequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.buySequence.push(payload);
              });
              break;
            case 'WORK_ORDER_SEQUENCE_RESPONSE':
              state.workOrderSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.workOrderSequence.push(payload);
              });
              break;
            case 'JOB_CARD_SEQUENCE_RESPONSE':
              state.jobCardSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.jobCardSequence.push(payload);
              });
              break;
            case 'PRODUCTION_PLAN_SEQUENCE_RESPONSE':
              state.productionPlan = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.productionPlan.push(payload);
              });
              break;
            case 'JOB_WORK_OUT_SEQUENCE_RESPONSE':
              state.jobWorkOutSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.jobWorkOutSequence.push(payload);
              });
              break;
            case 'SECURITY_ENTRY_GATE_RESPONSE':
              state.securityGateEntrySequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: getSequenceFormatTextFromResponse(data),
                  ...data
                };
                state.securityGateEntrySequence.push(payload);
              });
              break;
            case 'PURCHASE_REQUEST_FOR_QUOTE_CODE_RESPONSE':
              state.purchaseRequestQuotesSequence = [];
              data[property].forEach((data) => {
                let payload = {
                  text: data.sequenceCode,
                  ...data
                };
                state.purchaseRequestQuotesSequence.push(payload);
              });
              break;
          }
        }
      })
      .addCase(fetchContactCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchContactCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.contactSequence = [];

        data.CONTACT_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.contactSequence.push(payload);
          }
        );
      })
      .addCase(fetchProductCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchProductCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.productSequence = [];

        data.PRODUCT_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.productSequence.push(payload);
          }
        );
      })
      .addCase(fetchAccountCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchAccountCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.accountSequence = [];

        data.ACCOUNT_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.accountSequence.push(payload);
          }
        );
      })
      .addCase(fetchSellDocumentsCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchSellDocumentsCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.sellSequence = [];

          data.SELL_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.sellSequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchBuyDocumentsCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchBuyDocumentsCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.buySequence = [];

          data.BUY_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.buySequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchQuotesCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchQuotesCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.quotesSequence = [];

        data.QUOTES_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.quotesSequence.push(payload);
          }
        );
      })
      .addCase(fetchSalesOrderCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchSalesOrderCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.salesOrderSequence = [];

        data.SALES_ORDER_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.salesOrderSequence.push(payload);
          }
        );
      })
      .addCase(fetchInvoiceCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchInvoiceCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.invoiceSequence = [];

        data.INVOICES_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.invoiceSequence.push(payload);
          }
        );
      })
      .addCase(fetchBillsCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchBillsCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.billsSequence = [];

        data.BILLS_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.billsSequence.push(payload);
          }
        );
      })
      .addCase(fetchOrdersCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchOrdersCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.ordersSequence = [];

        data.ORDER_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.ordersSequence.push(payload);
          }
        );
      })
      .addCase(fetchRequisitionCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchRequisitionCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.requisitionSequence = [];

          data.REQUISITION_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.requisitionSequence.push(payload);
            }
          );
        }
      )

      .addCase(fetchFixedAssetCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchFixedAssetCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.fixedAssetSequence = [];

        data.FIXED_ASSET_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.fixedAssetSequence.push(payload);
          }
        );
      })
      .addCase(fetchMachineCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchMachineCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.machineSequence = [];

        data.MACHINE_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.machineSequence.push(payload);
          }
        );
      })
      .addCase(fetchSupplierquoteCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchSupplierquoteCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.supplierquotesSequence = [];

          data.SUPPLIER_QUOTES_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: data.sequenceCode,
                ...data
              };
              state.supplierquotesSequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchSeCurityGateEntryNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchSeCurityGateEntryNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.securityGateEntrySequence = [];

          data.SECURITY_ENTRY_GATE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.securityGateEntrySequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchJobWorkOutCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchJobWorkOutCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.jobWorkOutSequence = [];
        data.JOB_WORK_OUT_SEQUENCE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.jobWorkOutSequence.push(payload);
          }
        );
      })
      .addCase(fetchCreditNotesCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchCreditNotesCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.creditNoteSequence = [];

          data.CREDIT_NOTE_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.creditNoteSequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchDebitNotesCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchDebitNotesCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.debitNodeSequence = [];

        data.DEBIT_NOTE_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.debitNodeSequence.push(payload);
          }
        );
      })
      .addCase(fetchJournalEntryCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchJournalEntryCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.journalEntrySequence = [];
          data.JOURNAL_ENTRY_SEQUENCE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.journalEntrySequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchBuildAssemblyCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchBuildAssemblyCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.productionPlan = [];

          data.BUILD_ASSEMBLY_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.buildAssemblySequence.push(payload);
            }
          );
        }
      )
      .addCase(fetchProductionPlanCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(
        fetchProductionPlanCustomNumberFormat.fulfilled,
        (state, action) => {
          state.status = API_STATUS.IDLE;
          let data: any = action.payload;
          state.productionPlan = [];

          data.PRODUCTION_PLAN_SEQUENCE_RESPONSE.sequenceFormats.forEach(
            (data: any) => {
              let payload = {
                text: getSequenceFormatTextFromResponse(data),
                ...data
              };
              state.productionPlan.push(payload);
            }
          );
        }
      )
      .addCase(fetchWorkOrderCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchWorkOrderCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data: any = action.payload;
        state.workOrderSequence = [];

        data.WORK_ORDER_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: any) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.workOrderSequence.push(payload);
          }
        );
      })
      .addCase(fetchJobCardCustomNumberFormat.pending, (state) => {
        state.status = API_STATUS.LOADING;
      })
      .addCase(fetchJobCardCustomNumberFormat.fulfilled, (state, action) => {
        state.status = API_STATUS.IDLE;
        let data = action.payload;
        state.jobCardSequence = [];

        data.JOB_CARD_SEQUENCE_CODE_RESPONSE.sequenceFormats.forEach(
          (data: ICustomNumberFormatResponse) => {
            let payload = {
              text: getSequenceFormatTextFromResponse(data),
              ...data
            };
            state.jobCardSequence.push(payload);
          }
        );
      });
  }
});

export const selectCustomNumberFormatByModule =
  (module: any) => (state: RootState) => {
    let formatList: ICustomNumberFormatStored[] = [];

    Object.keys(state.customNumberFormat).some((key) => {
      const data: ICustomNumberFormatStored[] | undefined =
        state.customNumberFormat[
          key as keyof Pick<CustomNumberFormatState, StoredModuleFormatKeys>
        ];

      if (!data || !Array.isArray(data)) return false;

      if (data[0]?.module === module) {
        formatList = data;
        return true;
      }

      return false;
    });

    formatList = formatList.filter(
      (format) => format.status && !format.isDeleted
    );

    return formatList;
  };

// Export custom number format data to display
export const selectContactCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.contactSequence;

export const selectProductCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.productSequence;

export const selectAccountCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.accountSequence;

export const selectQuoteCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.quotesSequence;

export const selectFullQuoteCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullQuote;

export const selectSalesOrderCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.salesOrderSequence;

export const selectFullSalesOrderCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullSalesOrder;

export const selectFullInvoiceCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullInvoice;

export const selectFullBillCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullBill;

export const selectFullOrderCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullOrder;

export const selectFullRequisitionCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullRequisition;

export const selectFullFixedAssetCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fullFixedAsset;
export const selectFullSupllierquotesCustomNumbersFormats = (
  state: RootState
) => state.customNumberFormat.fullSupplierquotes;
export const selectInvoiceCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.invoiceSequence;

export const selectBillCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.billsSequence;
export const selectJournalEntryCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.journalEntrySequence;

export const selectOrderCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.ordersSequence;

export const selectRequisitionCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.requisitionSequence;

export const selectFixedAssetCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.fixedAssetSequence;

export const selectSupplierquotesCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.supplierquotesSequence;
export const selectCreditNoteCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.creditNoteSequence;

export const selectDebitNodeCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.debitNodeSequence;

export const selectBuildAssemblyCustomNumbersFormats = (state: RootState) =>
  state.customNumberFormat.buildAssemblySequence;

export const selectSellCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.sellSequence;

export const selectBuyCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.buySequence;

export const selectWorkOrderCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.workOrderSequence;

export const selectJobCardCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.jobCardSequence;

export const selectProductionPlanCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.productionPlan;

export const selectJobWorkOutCustomNumberFormats = (state: RootState) =>
  state.customNumberFormat.jobWorkOutSequence;

export const selectPurchaseRequestForQuotesCustomNumberFormats = (
  state: RootState
) => state.customNumberFormat.purchaseRequestQuotesSequence;

// Export custom number format slice reducer
export default CustomNumberFormat.reducer;
