import {
  DKButton,
  DKDataGrid,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  DOCUMENT_TYPE,
  DOC_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import ProductService from '../../../Services/Product';
import SourceDestinationWarehouseManagementRRB from '../../../SharedComponents/WarehouseManagement/SourceDestinationWarehouseManagementRRB';
import Utility, { deepClone } from '../../../Utility/Utility';
import WarehouseService from '../../../Services/Warehouse';
import {
  fetchSerialTrackingProductsJWO,
  selectSerialTrackingProductJWO
} from '../../../Redux/Slices/SerialTrackingProductJWOSlice';
import { t } from 'i18next';

export default function StockTransferProductWarehouseManagement(props: any) {
  const [details, setDetails] = useState<any>(props.details);
  const [module, setModule] = useState<any>(props.module);
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [transferQuantity, setTransferQuantity] = useState(0);
  const productCode = props.details?.pid ?? props.details?.productCode ?? '';
  // const productInventoryWarehouseById = useAppSelector(
  //   selectWarehouseProductByID
  // );
  const productInventoryWarehouseById = props?.selectedWarehouseProductsData;
  const allWarehouseData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );

  const [productWarehouse, setProductWarehouse] = useState<any>([]);
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState<any>(
    []
  );
  const [defaultWarehouseData, setDefaultWarehouseData] = useState<any>({});

  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [totalUsedQuantity, setTotalUsedQuantity] = useState(0);

  const [sourceRRB, setSourceRRB] = useState<any>({
    srcRowCode: null,
    srcRackCode: null,
    srcBinCode: null
  });

  const [destinationRRB, setDestinationRRB] = useState<any>({
    destRowCode: null,
    destRackCode: null,
    destBinCode: null
  });
  const [warehouseProduceByIdWithDTO, setWarehouseProduceByIdWithDTO] =
    useState<any[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [stockTransferItem, setStockTransferItem] = useState(
    props.stockTransferData
  );
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
  const [isUpdateQty, setIsUpdateQty] = useState<any>(true);
  const [jwoWarehouseFetched, setJwoWarehouseFetched] = useState<any>();
  const dispatch = useAppDispatch();
  const selectProductJWOList = useAppSelector(selectSerialTrackingProductJWO);
  useEffect(() => {
    fetchProductDetailsByID(productCode);
    loadJWOWH();
  }, []);
  useEffect(() => {
    if (!Utility.isEmpty(productWarehouse)) {
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        getJWDestinationWarehouse();
      }
    }
  }, [productWarehouse]);

  useEffect(() => {
    if (!checkWarehouseSelected(sourceWarehouse?.code, 0, sourceWarehouse)) {
      let item = [...warehouseData];
      let tmpWarehouse: any = productWarehouse.filter(
        (warehouse: any) => warehouse.code === sourceWarehouse.code
      );
      let cpTempWarehouse: any = deepClone(tmpWarehouse);
      let filterWarehouseWithDTO: any = warehouseProduceByIdWithDTO.find(
        (warehouse: any) => warehouse.id === cpTempWarehouse?.[0]?.id
      );

      if (!Utility.isEmpty(filterWarehouseWithDTO)) {
        cpTempWarehouse[0].rowRackBinProductAvailableQuantityDtos =
          filterWarehouseWithDTO.rowRackBinProductAvailableQuantityDtos;
      }

      if (cpTempWarehouse && cpTempWarehouse.length > 0) {
        const availableQty = getProductAvailableQuantityOnWarehouseSelection(
          cpTempWarehouse,
          details,
          sourceWarehouse?.RRB
        );

        item[0] = {
          availableQty: Utility.roundingOff(
            availableQty,
            QTY_ROUNDOFF_PRECISION
          ),
          warehouseCode: sourceWarehouse,
          quantity: isUpdateQty
            ? 0
            : stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.quantity || 0
        };
        setIsUpdateQty(true);
        setWarehouseData((prevState: any[]) => item);
        setDefaultWarehouseData({
          ...defaultWarehouseData,
          [cpTempWarehouse[0].code]:
            cpTempWarehouse[0].productAvailableQuantity[details?.pid]
        });
      }
    }
  }, [sourceRRB]);

  const loadJWOWH = async () => {
    let findJWOWarehouse = allWarehouseData?.content?.find(
      (warehouse: any) => warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
    );

    if (Utility.isEmpty(findJWOWarehouse)) {
      const jwoWH = await WarehouseService.getJWOWarehouses();
      if (Utility.isNotEmpty(jwoWH)) {
        setJwoWarehouseFetched(jwoWH);
      }
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      props.stockTransferDocumentSeqCodes &&
      props.stockTransferDocumentSeqCodes.length !== 0
    ) {
      dispatch(
        fetchSerialTrackingProductsJWO(props.stockTransferDocumentSeqCodes)
      );
    }
  };

  const fetchProductDetailsByID = (productIds: any) => {
    setIsLoadingData(true);
    ProductService.fetchWarehouseProductsByIDWithRejectedWh([productIds])
      .then((response: any) => {
        setWarehouseProduceByIdWithDTO(response?.warehouses);
        setIsLoadingData(false);
      })
      .catch((err: any) => {
        setIsLoadingData(false);
      });
  };

  const getActiveWarehouses = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      if (!Utility.isEmpty(props.targetWarehouse)) {
        return (
          productWarehouse.filter(
            (pw: any) => pw.id !== props.targetWarehouse.id
          ) || []
        );
      } else {
        return productWarehouse;
      }
    } else {
      return [];
    }
  };

  const getDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = allWarehouseData?.content?.filter(
        (warehouse: any) =>
          warehouse.warehouseType !== 'REJECTED' && warehouse.active
      );
      //   let warehouseList = allWarehouseData?.content?.filter(
      //     (warehouse: any) =>
      //       warehouse.id !==
      //         (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '') &&
      //       warehouse.warehouseType !== 'REJECTED' &&
      //       warehouse.active
      //   );
      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;
        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      return warehouseList && warehouseList.length > 0 ? warehouseList : [];
    } else {
      return [];
    }
  };

  const getJWDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = productInventoryWarehouseById.filter(
        (warehouse: any) =>
          warehouse.id !==
          (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '')
      );
      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;
        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      if (warehouseList && warehouseList.length > 0) {
        if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          let jwoWarehouse = warehouseList?.find(
            (warehouse: any) =>
              warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
          );
          if (!Utility.isEmpty(jwoWarehouse)) {
            setDestinationWarehouse(jwoWarehouse);
          }
        }
      }
    }
  };

  const localColumnConfig = [
    {
      name: 'Available Qty',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableQty',
      id: 'availableQty',
      datasource: [],
      key: 'availableQty',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 180
    },
    {
      name: 'Quantity',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'quantity',
      id: 'quantity',
      datasource: [],
      key: 'quantity',
      lineItem: false,
      type: INPUT_TYPE.NUMBER,
      width: 180
    }
  ];

  useEffect(() => {
    let productWarehouseData;
    if (module === MODULE_TYPE.SELL) {
      productWarehouseData =
        warehouseProduceByIdWithDTO?.filter(
          (warehouse: any) => warehouse?.productAvailableQuantity?.[details.pid]
        ) || [];
    }
    setProductWarehouse(productWarehouseData);
    if (!Utility.isEmpty(props.targetWarehouse)) {
      if (!Utility.isEmpty(productInventoryWarehouseById)) {
        if (productInventoryWarehouseById) {
          let warehouseIdWithInventory: any[] = [];
          productInventoryWarehouseById.forEach((piw: any) => {
            if (
              piw.productAvailableQuantity &&
              piw.productAvailableQuantity[details.pid] &&
              piw.productAvailableQuantity[details.pid] > 0
            ) {
              //get list of warehouse where product is available
              warehouseIdWithInventory.push(piw.id);
            }
          });
          if (warehouseIdWithInventory.length > 0) {
            //remove targetwarehouse from list
            let x = warehouseIdWithInventory.filter(
              (w: any) => w.id !== props.targetWarehouse.id
            );
            if (x && x.length > 0) {
              //set target warehouse if there are inventory in other warehouse
              let tmp = productInventoryWarehouseById.filter(
                (w: any) => w.id === props.targetWarehouse.id
              );
              if (tmp && tmp.length > 0) {
                let tmpWarehouse = [...warehouseData];
                if (!Utility.isEmpty(tmpWarehouse)) {
                  let x = { ...tmpWarehouse[0] };
                  x.targetWarehouse = tmp[0];
                  tmpWarehouse[0] = x;
                  setWarehouseData(tmpWarehouse);
                }

                setDestinationWarehouse(tmp[0]);
                setReadOnlyTarget(true);
              }
            }
          }
        }
      }
    }
  }, [warehouseProduceByIdWithDTO]);

  useEffect(() => {
    let defaultProductWarehouseData;
    defaultProductWarehouseData = productWarehouse.find(
      (warehouse: any) => warehouse.primary
    );

    if (!Utility.isEmpty(defaultProductWarehouseData)) {
      setDefaultProductWarehouse(defaultProductWarehouseData);
    }

    if (
      module === MODULE_TYPE.SELL &&
      !Utility.isEmpty(productWarehouse) &&
      !Utility.isEmpty(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
      )
    ) {
      setIsUpdateQty(false);

      let srcWh = productInventoryWarehouseById?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcWarehouseCode
      );
      let destWh = getDestinationWarehouse()?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destWarehouseCode
      );
      let filteredRacksDataForSourceWarehouse: any[] =
        srcWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRowCode
      ) {
        filteredRacksDataForSourceWarehouse = srcWh?.warehouseRackInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredBinDataForSourceWarehouse: any[] =
        srcWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRackCode
      ) {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rackCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
        );
      } else {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredRacksDataForDestinationWarehouse: any[] =
        destWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRowCode
      ) {
        filteredRacksDataForDestinationWarehouse =
          destWh?.warehouseRackInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      let filteredBinDataForDestinationWarehouse: any[] =
        destWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRackCode
      ) {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rackCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode
          );
      } else {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      srcWh = {
        ...srcWh,
        srcRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRowCode ?? null,
        srcRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRackCode ?? null,
        srcBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcBinCode ?? null,
        filteredRackInfos: filteredRacksDataForSourceWarehouse,
        filteredBinInfos: filteredBinDataForSourceWarehouse
      };
      destWh = {
        ...destWh,
        destRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRowCode ?? null,
        destRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRackCode ?? null,
        destBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destBinCode ?? null,
        filteredRackInfos: filteredRacksDataForDestinationWarehouse,
        filteredBinInfos: filteredBinDataForDestinationWarehouse
      };
      setSourceWarehouse(srcWh);
      setDestinationWarehouse(destWh);
      setSourceRRB({
        ...srcWh,
        srcRowCode: srcWh?.srcRowCode ?? null,
        srcRackCode: srcWh?.srcRackCode ?? null,
        srcBinCode: srcWh?.srcBinCode ?? null
      });
      setDestinationRRB({
        ...destWh,
        destRowCode: destWh?.destRowCode ?? null,
        destRackCode: destWh?.destRackCode ?? null,
        destBinCode: destWh?.destBinCode ?? null
      });
      const availableQty = getProductAvailableQuantityOnWarehouseSelection(
        getActiveWarehouses(),
        details,
        srcWh?.RRB
      );
      let item = {
        warehouseCode: srcWh,
        availableQty: Utility.roundingOff(availableQty, QTY_ROUNDOFF_PRECISION),
        quantity:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.quantity || 0
      };
      setTransferQuantity(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.quantity || 0
      );
      setWarehouseData([item]);
    }
  }, [productWarehouse]);

  useEffect(() => {
    let defaultWarehouseLocalData;

    defaultWarehouseLocalData = {
      [defaultProductWarehouse]: productWarehouse.find(
        (warehouse: any) => warehouse.primary
      )?.productAvailableQuantity[details?.pid]
    };

    setDefaultWarehouseData(defaultWarehouseLocalData);
  }, [productWarehouse, defaultProductWarehouse]);

  useEffect(() => {
    let initialQuantity = warehouseData.reduce(function (acc: any, val: any) {
      return acc + parseFloat(val.quantity);
    }, 0);

    let quantity = isNaN(initialQuantity) ? 0 : initialQuantity;

    setTotalUsedQuantity(quantity);
  }, [warehouseData, totalUsedQuantity]);

  const validateData = () => {
    if (Utility.isEmpty(sourceWarehouse)) {
      showAlert('Error', 'Please select source warehouse');
      return false;
    }
    if (Utility.isEmpty(destinationWarehouse)) {
      showAlert('Error', 'Please select target warehouse');
      return false;
    }
    if (Number(transferQuantity) <= 0) {
      showAlert('Error', 'Please enter transfer quantity');
      return false;
    }

    if (
      !isNaN(Number(details.requiredQuantity)) &&
      Number(details.requiredQuantity) !== Number(transferQuantity)
    ) {
      showAlert(
        'Error',
        'Quantity can not be more or less than required quantity'
      );
      return false;
    }
    let wareHouseData = allWarehouseData?.content?.find(
      (warehouse: any) => warehouse?.code === sourceWarehouse.code
    );
    if (
      (!Utility.isEmpty(wareHouseData?.warehouseRowInfos) &&
        Utility.isEmpty(sourceRRB?.srcRowCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseRackInfos) &&
        Utility.isEmpty(sourceRRB?.srcRackCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseBinInfos) &&
        Utility.isEmpty(sourceRRB?.srcBinCode))
    ) {
      showAlert(
        'Warning!',
        'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
      );
      return false;
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      selectProductJWOList &&
      selectProductJWOList.length !== 0
    ) {
      const isExist = selectProductJWOList.find(
        (availSerial: any) =>
          availSerial.warehouseCode === destinationWarehouse.code
      );
      if (!isExist) {
        showAlert('Warning!', t('JOB_WORK_OUT.SAME_WAREHOUSE_ERROR'));
        return false;
      }
    }
    return true;
  };

  const saveData = () => {
    if (validateData()) {
      let warehouseDetail = {
        srcWarehouseCode: sourceWarehouse.code,
        destWarehouseCode: destinationWarehouse.code,
        destBinCode: destinationRRB.destBinCode ?? null,
        destRackCode: destinationRRB.destRackCode ?? null,
        destRowCode: destinationRRB.destRowCode ?? null,
        srcBinCode: sourceRRB?.srcBinCode ?? null,
        srcRackCode: sourceRRB?.srcRackCode ?? null,
        srcRowCode: sourceRRB?.srcRowCode ?? null,
        quantity: transferQuantity,
        advancedTrackingType: 'NONE',
        productCode: details.pid
      };
      let data: any = {};
      data['stockTransferWarehouseInventoryData'] = warehouseDetail;
      props.onSave(data);
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto gap-2">
          <DKLabel
            text={
              props.state === 'STOCK_ISSUE' ? 'Stock Issue' : 'Stock Transfer'
            }
            className="fw-m fs-l"
          />
          {isLoadingData && <DKSpinner />}
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <DKButton
            title={'Save'}
            className="bg-button text-white"
            onClick={() => {
              saveData();
            }}
          />
        </div>
      </div>
    );
  };

  const checkWarehouseSelected = (code: any, index: any, value: any) => {
    const filtedWarehousedata = warehouseData.filter(
      (warehouse: any) => warehouse.warehouseCode === code
    );
    if (
      filtedWarehousedata.length > 0 &&
      value.length > 0 &&
      warehouseData.length > 1
    ) {
      showAlert('Error', 'Warehouse is already selected');
      let item = [...warehouseData];
      item[index] = {
        warehouseCode: '',
        availableQty: 0,
        quantity: 0
      };
      setWarehouseData((prevState: any[]) => item);
      return true;
    }
    return false;
  };

  const getHeaderSection = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s mt-5">
        <div className="column width-auto ">
          <DKLabel
            text="Product Name"
            className="fs-r pl-2 font-medium text-blue"
          />
          <DKLabel text={details?.name} className="fs-r pl-2" />
        </div>
        <div className="column width-auto">
          <DKLabel
            text="Product Code"
            className="fs-r pl-2 font-medium text-blue"
          />
          <DKLabel text={details?.documentSequenceCode} className="fs-r pl-2" />
        </div>
        <div className="column width-auto">
          <DKLabel
            text="Total Available Quantity"
            className="fs-r pl-2 font-medium text-blue"
          />
          <DKLabel
            text={Utility.roundingOff(
              getProductAvailableQuantity(details),
              QTY_ROUNDOFF_PRECISION
            )}
            className="fs-r pl-2"
          />
        </div>
        {details.requiredQuantity !== undefined &&
          details.requiredQuantity >= 0 && (
            <div className="column width-auto">
              <DKLabel
                text="Required Quantity"
                className="fs-r pl-2 font-medium text-blue"
              />
              <DKLabel
                text={Utility.roundingOff(
                  details.requiredQuantity,
                  QTY_ROUNDOFF_PRECISION
                )}
                className="fs-r pl-2"
              />
            </div>
          )}
      </div>
    );
  };

  const getProductAvailableQuantityOnWarehouseSelection = (
    wareHouses: any,
    product: any,
    RRBdata?: any
  ) => {
    let reserveQty = 0;
    wareHouses?.forEach((ele: any) => {
      reserveQty += ele.productReservedQuantity[product?.pid]
        ? ele.productReservedQuantity[product?.pid]
        : 0;
    });
    if (
      !Utility.isEmpty(RRBdata) &&
      !Utility.isEmpty(wareHouses?.[0]?.rowRackBinProductAvailableQuantityDtos)
    ) {
      let totalAvailableQuantity = 0;
      let availableRRBQuantities =
        wareHouses?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
          (warehouseData: any) => {
            return (
              (!RRBdata?.source?.row?.value?.code ||
                warehouseData.rowCode ===
                  (RRBdata?.source?.row?.value?.code || RRBdata?.srcRowCode)) &&
              (!RRBdata?.source?.rack?.value?.code ||
                warehouseData.rackCode ===
                  (RRBdata?.source?.rack?.value?.code ||
                    RRBdata?.srcRackCode)) &&
              (!RRBdata?.source?.bin?.value?.code ||
                warehouseData.binCode ===
                  (RRBdata?.source?.bin?.value?.code || RRBdata?.srcBinCode))
            );
          }
        );
      if (availableRRBQuantities?.length) {
        availableRRBQuantities.map((rrbQuantity: any) => {
          totalAvailableQuantity += rrbQuantity?.availableQuantity;
        });
      }
      return totalAvailableQuantity - reserveQty;
    }
    if (
      (RRBdata?.source?.row?.value?.code ||
        RRBdata?.source?.rack?.value?.code ||
        RRBdata?.source?.bin?.value?.code) &&
      Utility.isEmpty(wareHouses?.[0]?.rowRackBinProductAvailableQuantityDtos)
    ) {
      return 0;
    }
    return product
      ? wareHouses[0].productAvailableQuantity[product?.pid] - reserveQty
      : 0;
  };

  const getProductAvailableQuantity = (product: any) => {
    let reserveQty = 0,
      availableQty = 0;
    productInventoryWarehouseById?.forEach((ele: any) => {
      reserveQty += ele.productReservedQuantity[product?.pid]
        ? ele.productReservedQuantity[product?.pid]
        : 0;
      availableQty += ele.productAvailableQuantity[product?.pid]
        ? ele.productAvailableQuantity[product?.pid]
        : 0;
    });

    let productQty = availableQty ?? 0;
    return productQty === 0 ? 0 : productQty - reserveQty;
  };

  const onRowUpdate = (data: any) => {
    switch (data?.columnKey) {
      case 'quantity':
        const { quantity, availableQty } = data.rowData;
        let quantityToUpdate = 0;
        if (quantity > availableQty) {
          showAlert(
            'Error',
            'Transfer quantity cannot be more than available quantity'
          );
        } else {
          if (!isNaN(quantity)) {
            quantityToUpdate = Utility.roundingOff(
              quantity,
              QTY_ROUNDOFF_PRECISION
            );
          }
        }
        let copyOfWHData = [...warehouseData];
        copyOfWHData[0].quantity = quantityToUpdate;
        setWarehouseData(copyOfWHData);
        setTransferQuantity(quantityToUpdate);
        break;
      default:
        break;
    }
  };

  const getLineItemGrid = () => {
    return (
      <div className="p-h-xl mt-3">
        <DKDataGrid
          width={545}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={true}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={!Utility.isEmpty(props.selectedRecord) ? false : true}
          columns={localColumnConfig}
          rows={warehouseData}
          onRowUpdate={onRowUpdate}
          onRowOpenClick={undefined}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowBulkOperation={false}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 600,
          width: '100%',
          maxHeight: '95%',
          height: 400,
          padding: 0,
          paddingBottom: 0
        }}
      >
        {getHeader()}
        {getHeaderSection()}
        <div className="p-h-xl parent-width">
          <SourceDestinationWarehouseManagementRRB
            document={props?.document ?? ''}
            warehouseProduceByIdWithDTO={warehouseProduceByIdWithDTO}
            sourceWarehouseData={getActiveWarehouses()}
            targetWarehouseData={getDestinationWarehouse()}
            sourceWarehouse={sourceWarehouse}
            destinationWarehouse={destinationWarehouse}
            onSave={(data: any) => {
              setSourceRRB({
                ...sourceRRB,
                srcRowCode: data?.srcRowCode ?? null,
                srcRackCode: data?.srcRackCode ?? null,
                srcBinCode: data?.srcBinCode ?? null
              });
              setDestinationRRB({
                ...destinationRRB,
                destRowCode: data?.destRowCode ?? null,
                destRackCode: data?.destRackCode ?? null,
                destBinCode: data?.destBinCode ?? null
              });
            }}
            onSourceWarehouseChange={(data: any) => {
              if (data) {
                setSourceWarehouse(data);
                if (!readOnlyTarget) {
                  if (props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER) {
                    setDestinationWarehouse({});
                  }
                }
              }
            }}
            onTargetWarehouseChange={(data: any) => {
              if (data) {
                setDestinationWarehouse(data);
              }
            }}
          />
        </div>
        {getLineItemGrid()}
      </div>
    </div>
  );
}
