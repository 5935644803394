import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';
import { CoaPayload } from '../Models/Coa';
import { CoaPayloadStatus } from '../Models/Coa';
import { UpdateCoaPayload } from '../Models/Interfaces';
import { saveAs } from 'file-saver';
import { showAlert } from 'deskera-ui-library';
import { EXPORT_ERROR } from '../Constants/Constant';
//import { saveAs } from "file-saver/src/FileSaver";

export interface CoaAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: any;
  QueryParam?: any;
  Format?: string;
  Module?: string;
}

export const defaultConfig: CoaAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  SortDir: 'desc',
  Sort: 'code',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: '',
  QueryParam: ''
};

export interface TypeConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
}

const typeConfig: TypeConfig = {
  SearchTerm: '',
  Limit: 500,
  Page: 0,
  SortDir: 'asc',
  Sort: 'name',
  SKIP_REQUEST_INTERCEPTOR: true
};
class CoaService {
  static apiConfig: CoaAPIConfig;
  static typeApiConfig: TypeConfig;

  static getCOAEndPoint = () => {
    let queryString: string = '?';
    if (Utility.isEmpty(CoaService.apiConfig)) {
      CoaService.apiConfig = defaultConfig;
    }
    queryString += `search=${CoaService.apiConfig.SearchTerm || ''}`;
    if (CoaService.apiConfig.SKIP_REQUEST_INTERCEPTOR)
      queryString += `&SKIP_REQUEST_INTERCEPTOR=${CoaService.apiConfig.SKIP_REQUEST_INTERCEPTOR}`;
    if (CoaService.apiConfig.Limit)
      queryString += `&limit=${CoaService.apiConfig.Limit}`;
    if (CoaService.apiConfig.Page)
      queryString += `&page=${CoaService.apiConfig.Page}`;
    if (CoaService.apiConfig.Sort)
      queryString += `&sort=${CoaService.apiConfig.Sort}`;
    if (CoaService.apiConfig.SortDir)
      queryString += `&sortDir=${CoaService.apiConfig.SortDir}`;
    if (CoaService.apiConfig.Query)
      queryString += `&query=${CoaService.apiConfig.Query}`;
    if (CoaService.apiConfig.QueryParam)
      queryString += `${CoaService.apiConfig.QueryParam}`;

    return ApiConstants.URL.COA.FETCH_COA + queryString;
  };
  static getCOA() {
    return http.get(CoaService.getCOAEndPoint()).catch((err: any) => {
      console.error('Error loading COA: ', err);
      return Promise.reject(err);
    });
  }
  static deleteCoa(id: number) {
    try {
      return http.delete(`${ApiConstants.URL.COA.DELETE_COA(id)}`);
    } catch (err) {
      console.error('Error deleting COA: ', err);
      return Promise.reject(err);
    }
  }

  static updateStatus(payload: UpdateCoaPayload) {
    const queryString = `?status=${payload.status}`;
    const finalEndpoint = ApiConstants.URL.COA.UPDATE_STATUS + queryString;
    try {
      return http.put(finalEndpoint, payload.accountCode);
    } catch (err) {
      console.error('Error changing status of account: ', err);
      return Promise.reject(err);
    }
  }

  static bulkUpdateStatus(payload: UpdateCoaPayload) {
    const queryString = `?status=${payload.status}`;
    const finalEndpoint = ApiConstants.URL.COA.BULK_STATUS_UPDATE + queryString;
    try {
      return http.put(finalEndpoint, payload.accountCode);
    } catch (err) {
      console.error('Error changing status of account: ', err);
      return Promise.reject(err);
    }
  }

  static createCoa(payload: CoaPayload) {
    return http
      .post(`${ApiConstants.URL.COA.CREATE_COA_IND}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to post status: ', err);
        return Promise.reject(err);
      });
  }

  static getAccountType(payload?: any) {
    if (Utility.isEmpty(this.typeApiConfig)) {
      this.typeApiConfig = typeConfig;
    }
    const queryString: string = `?limit=${this.typeApiConfig.Limit}&sort=${this.typeApiConfig.Sort}&sortDir=${this.typeApiConfig.SortDir}&SKIP_REQUEST_INTERCEPTOR=${this.typeApiConfig.SKIP_REQUEST_INTERCEPTOR}&search=${this.typeApiConfig.SearchTerm}`;
    const finalEndpoint: string = ApiConstants.URL.COA.TYPE_COA + queryString;
    return http2.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error while trying to get account: ', err);
      return Promise.reject(err);
    });
  }

  static updateCoa(payload: CoaPayload, id: number) {
    return http
      .put(`${ApiConstants.URL.COA.UPDATE_COA(id)}`, payload)
      .catch((err: any) => {
        console.error('Error while trying to update coa details: ', err);
        return Promise.reject(err);
      });
  }

  static export(format: string, module: string) {
    const fileType = format;
    const fileName = `${module.toLowerCase()}.${fileType.toLowerCase()}`;
    let finalEndpoint = `exim/export?format=${format}&module=${module}&search=${this.typeApiConfig.SearchTerm}`;

    if (CoaService.apiConfig.Query)
      finalEndpoint += `&query=${CoaService.apiConfig.Query}`;
    if (CoaService.apiConfig.QueryParam)
      finalEndpoint += `${CoaService.apiConfig.QueryParam}`;
    if (CoaService.apiConfig.Query || CoaService.apiConfig.QueryParam)
      finalEndpoint += `&limit=10000`;

    return http
      .get(finalEndpoint, {
        responseType: 'blob'
      })
      .then((res: any) => {
        const reader = new FileReader();
        reader.onload = function(event) {
          let blobData = String(event?.target?.result);
          try{
            showAlert('Alert', JSON.parse(blobData).exportMessage);
          }catch(e){
            saveAs(res,fileName)
          }
        };
        reader.readAsText(res);        
      })
      .catch((err: any) => {
        console.error('Error while trying to get account: ', err);
        if (
          err?.status === EXPORT_ERROR.EXPORT_LOCK_CODE ||
          err?.code === EXPORT_ERROR.EXPORT_LOCK_CODE
        ) {
          showAlert('Error', EXPORT_ERROR.EXPORT_LOCK_WARNING_MSG);
        } else {
          return Promise.reject(err);
        }
      });
  }

  static getMultiLevelSubTypes(code: any) {
    const finalEndpoint: string =
      ApiConstants.URL.COA.FETCH_MULTI_LEVEL_SUBTYPES(code);
    return http
      .get(`${finalEndpoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static addNewSubType(payload: any) {
    const finalEndPoint = ApiConstants.URL.COA.SAVE_MULTI_LEVEL_SUBTYPE;
    return http
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static editSubtType(payload: any, id: any) {
    const finalEndPoint = `${ApiConstants.URL.COA.EDIT_MULTI_LEVEL_SUBTYPE(
      id
    )}`;
    return http
      .put(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteSubtType(data: any, accountId: any) {
    const finalEndPoint = `${ApiConstants.URL.COA.DELTE_MULTI_LEVEL_SUBTYPE(
      data.id,
      accountId
    )}`;
    return http
      .delete(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getParentTypes(codes: any) {
    let finalEndPoint: string = `${ApiConstants.URL.COA.FETCH_MULTU_LEVEL_PARENT_LIST}`;
    if (!Utility.isEmpty(codes.accountGroupCode)) {
      finalEndPoint = `${ApiConstants.URL.COA.FETCH_MULTU_LEVEL_PARENT_LIST}/?accountGroupCode=${codes.accountGroupCode}`;
    }
    if (!Utility.isEmpty(codes.accountSubGroupCode)) {
      finalEndPoint = `${ApiConstants.URL.COA.FETCH_MULTU_LEVEL_PARENT_LIST}/?accountGroupCode=${codes.accountGroupCode}&accountSubGroupCode=${codes.accountSubGroupCode}`;
    }
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static accountUsageCheck(payload: any) {
    const finalEndPoint = ApiConstants.URL.COA.IS_ACC_USED;
    return http2
      .post(`${finalEndPoint}`, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static async checkBudgetLimit(payload: any): Promise<any> {
    const finalEndPoint = ApiConstants.URL.COA.BUDGET_LIMIT_CHECK;
    try {
      const resp = await http2.post(`${finalEndPoint}`, payload);
      return resp;
    } catch (err) {
      return `Error: ${err}`;
    }
  }
}

export default CoaService;
