import { useEffect, useState } from 'react';
import {
  DKDataGrid,
  DKButton,
  DKIcons,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showAlert,
  TOAST_TYPE,
  showToast
} from 'deskera-ui-library';
import { useTranslation } from 'react-i18next';
import {
  ADD_PREFERRED_VENDOR_COLUMN_CONFIG,
  getCFColumnConfig,
  getCFColumnKey
} from './PreferredVendorHelper';
import { COLUMN_CODE } from '../../Constants/TableConstants';
import Utility from '../../Utility/Utility';
import ProductService from '../../Services/Product';
import { MODULES_NAME, STATUS_TYPE } from '../../Constants/Constant';
import CustomFieldService from '../../Services/CustomField';
import { DynamicPopupWrapper } from '../../SharedComponents/PopupWrapper';

function AddPreferredVendors({
  productId,
  productName,
  productDescription,
  currency,
  decimalScale,
  preferredVendorDetails,
  onCancel,
  onSave
}: any) {
  const { t } = useTranslation();
  const COLUMN = COLUMN_CODE.PREFERRED_VENDOR;

  const [width, setWidth] = useState(0);
  const [columns, setColumns] = useState<any[]>(
    ADD_PREFERRED_VENDOR_COLUMN_CONFIG
  );
  const [preferredVendors, setPreferredVendors] = useState<any[]>([]);
  const [updating, setUpdating] = useState(true);

  useEffect(() => {
    setPreferredVendorCustomColumns();

    if (preferredVendorDetails?.length > 0) {
      const vendors = preferredVendorDetails.map((v: any) => {
        const vendor = prepareRowData(v);
        return getRowData(vendor);
      });

      setPreferredVendors(vendors);
    } else {
      addVendor(preferredVendors);
    }
  }, []);

  useEffect(() => {
    updateEmptyVendorsWithCFDefaultValue(columns);
  }, [columns]);

  const setGridWidth = (nodeElement: any) => {
    if (nodeElement) {
      const width = nodeElement.getBoundingClientRect().width;
      setWidth(width - 38);
      setUpdating(false);
    }
  };

  const prepareRowData = (vendor: any) => {
    const customFields: any = {};

    if (Array.isArray(vendor.customField)) {
      vendor.customField.forEach((customField: any) => {
        customFields[getCFColumnKey(customField)] = customField.value;
      });
    }

    return {
      ...vendor,
      ...customFields,
      supplierCode: {
        code: vendor.supplierCode,
        name: vendor.contactName
      }
    };
  };

  const setPreferredVendorCustomColumns = async () => {
    const customColumns: any[] = [];
    try {
      const params = {
        status: STATUS_TYPE.ACTIVE,
        module: MODULES_NAME.PREFERRED_VENDOR,
        limit: 1000
      };

      const response = await CustomFieldService.getCustomFields(params, true);
      if (!Utility.isEmpty(response) && Array.isArray(response.content)) {
        response.content.forEach((customField: any) => {
          customColumns.push(getCFColumnConfig(customField));
        });
      }
    } catch (error) {
      showAlert(
        `${t('PREFERRED_VENDOR.ERROR')}!`,
        t('PREFERRED_VENDOR.CF_FETCH_FAILED')
      );
    }

    if (customColumns.length > 0) {
      setColumns([...columns, ...customColumns]);
    }
  };

  const getColumns = (columns: any[]) => {
    const action = {
      id: COLUMN.ACTION,
      key: COLUMN.ACTION,
      name: t('PREFERRED_VENDOR.ACTION'),
      type: INPUT_TYPE.BUTTON,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      options: []
    };

    return [...columns, action];
  };

  const getRows = (preferredVendors: any[]) => {
    if (Array.isArray(preferredVendors)) {
      return preferredVendors.map((vendor) => getRowData(vendor));
    }
    return [];
  };

  const getRowData = (vendor: any) => {
    const rowData = {
      ...vendor,
      currency: currency,
      rowContextMenu: getRowContextMenu()
    };

    rowData.invalidFields = getInvalidFields(rowData);
    return rowData;
  };

  const getRowContextMenu = () => {
    const buttons = [
      {
        title: t('CONFIRMATION_POPUP.DELETE'),
        icon: DKIcons.ic_delete,
        className: 'p-0',
        onClick: ({ rowIndex }: any) => {
          removeVendor(rowIndex);
        }
      }
    ];
    return buttons;
  };

  const isEmpty = (value: any) => {
    if (value === null || value === undefined) {
      return true;
    } else if (typeof value === 'string' && String(value).trim() === '') {
      return true;
    }
    return false;
  };

  const getInvalidFields = (vendor: any) => {
    const invalidFields: string[] = [];
    columns.forEach((column) => {
      if (column.required && isEmpty(vendor[column.key])) {
        invalidFields.push(column.key);
      }
    });
    return invalidFields;
  };

  const updateEmptyVendorsWithCFDefaultValue = (columns: any[]) => {
    setPreferredVendors((prevVendors: any[]) => {
      const vendors = [...prevVendors];
      vendors.forEach((vendor) => {
        if (vendor.isEmptyRow) {
          columns.forEach((column) => {
            if (!Utility.isEmpty(column.customField)) {
              vendor[column.key] = column.customField.defaultValue ?? '';
            }
          });
        }
      });

      return vendors;
    });
  };

  const addVendor = (preferredVendors: any[]) => {
    const emptyRow: any = {
      isEmptyRow: true
    };

    columns.forEach((column) => {
      switch (column.key) {
        case COLUMN.SUPPLIER_PART_NAME:
          emptyRow[column.key] = productName;
          break;
        case COLUMN.SUPPLIER_DESCRIPTION:
          emptyRow[column.key] = productDescription;
          break;
        case COLUMN.LAST_PRICE:
        case COLUMN.AVG_PRICE:
        case COLUMN.SUPPLIER_PRICE:
          emptyRow[column.key] = 0;
          break;
        default:
          if (!Utility.isEmpty(column.customField)) {
            emptyRow[column.key] = column.customField.defaultValue ?? '';
          } else {
            emptyRow[column.key] = '';
          }
          break;
      }
    });

    setPreferredVendors([...preferredVendors, emptyRow]);
  };

  const removeVendor = (rowIndex: number) => {
    const vendors = [...preferredVendors];
    vendors.splice(rowIndex, 1);
    setPreferredVendors(vendors);
  };

  const removeAllVendor = (preferredVendors: any[]) => {
    if (preferredVendors?.length > 0) {
      setPreferredVendors([]);
    }
  };

  const onRowUpdate = (rowIndex: number, rowData: any, columnKey: string) => {
    const vendors = [...preferredVendors];
    const vendor = vendors[rowIndex];
    const value = rowData[columnKey];

    vendor.isEmptyRow = false;

    switch (columnKey) {
      case COLUMN.LEAD_TIME:
      case COLUMN.LAST_PRICE:
      case COLUMN.SUPPLIER_PRICE:
      case COLUMN.AVG_PRICE:
        vendor[columnKey] = Utility.roundOff(value, decimalScale);
        break;
      case COLUMN.SUPPLIER_CODE:
        const idx = vendors.findIndex(
          (v) => v.supplierCode.code === value.code
        );
        if (idx === -1) {
          vendor[columnKey] = value;
        } else {
          vendor[columnKey] = '';
          showToast(
            `${value.name} ${t('PREFERRED_VENDOR.DUPLICATE_ADD_MSG')}`,
            TOAST_TYPE.WARNING
          );
        }
        break;
      default:
        vendor[columnKey] = value;
        break;
    }

    vendors[rowIndex] = vendor;
    if (!isEmpty(productId) && COLUMN.SUPPLIER_CODE === columnKey) {
      updatePrices(rowIndex, vendors);
    }
    setPreferredVendors(vendors);
  };

  const updatePrices = async (rowIndex: number, preferredVendors: any[]) => {
    const vendors = [...preferredVendors];
    if (!isEmpty(vendors[rowIndex][COLUMN.SUPPLIER_CODE])) {
      vendors[rowIndex][COLUMN.LAST_PRICE] = 0;
      vendors[rowIndex][COLUMN.AVG_PRICE] = 0;

      try {
        const response = await ProductService.getProductPriceByVendor(
          vendors[rowIndex][COLUMN.SUPPLIER_CODE].code,
          productId
        );

        if (!Utility.isEmpty(response)) {
          vendors[rowIndex][COLUMN.LAST_PRICE] = response.average_price;
          vendors[rowIndex][COLUMN.AVG_PRICE] = response.last_price;
        }
      } catch (error) {
        showAlert(
          `${t('PREFERRED_VENDOR.ERROR')}!`,
          t('PREFERRED_VENDOR.PRICE_FETCH_FAILED')
        );
      }

      setPreferredVendors(vendors);
    }
  };

  const onSaveClick = () => {
    const vendors = preferredVendors.map((vendor) => {
      return {
        ...getRowData(vendor),
        columns: columns
      };
    });

    const idx = vendors.findIndex(
      (vendor: any) => vendor.invalidFields.length > 0
    );

    if (idx !== -1) {
      showToast(t('PREFERRED_VENDOR.FILL_MANDATORY'), TOAST_TYPE.WARNING);
      return;
    }

    onSave(vendors);
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background">
        <div
          ref={setGridWidth}
          className="popup-window"
          style={{
            width: '75%',
            minWidth: '75%',
            height: '80%',
            minHeight: '80%',
            padding: 0,
            paddingBottom: 0,
            overflow: 'hiddens'
          }}
        >
          <div className="row justify-content-between p-h-r p-v-s bg-gray1">
            <div className="row pop-header-drag-handle">
              <DKLabel
                text={t('PREFERRED_VENDOR.ADD_PREFERRED_VENDORS')}
                className="fw-m fs-l"
              />
              {updating && <DKSpinner iconClassName="ml-s ic-s-2" />}
            </div>
            <div className="row width-auto">
              <DKButton
                title={t('PREFERRED_VENDOR.CANCEL')}
                className="bg-white border-m mr-r"
                onClick={() => {
                  onCancel();
                }}
              />
              <DKButton
                title={t('PREFERRED_VENDOR.SAVE')}
                className="bg-button text-white"
                onClick={() => {
                  onSaveClick();
                }}
              />
            </div>
          </div>
          {!updating && (
            <div className="column parent-width p-h-l pt-m">
              <DKDataGrid
                needShadow={false}
                needColumnIcons={true}
                needBorder={true}
                needTrailingColumn={false}
                allowBulkOperation={false}
                allowColumnSort={false}
                allowColumnShift={false}
                filterData={[]}
                allowColumnDelete={false}
                allowRowEdit={true}
                allowColumnEdit={false}
                allowFilter={false}
                allowColumnAdd={false}
                allowBottomRowAdd={false}
                allowSearch={false}
                allowShare={false}
                rows={getRows(preferredVendors)}
                columns={getColumns(columns)}
                onRowUpdate={({ rowIndex, rowData, columnKey }: any) => {
                  onRowUpdate(rowIndex, rowData, columnKey);
                }}
                width={width}
              />
              <div className="row">
                <DKButton
                  title={`+ ${t('PREFERRED_VENDOR.ADD_VENDOR')}`}
                  className="text-blue fw-m p-0"
                  style={{
                    marginTop: -7,
                    zIndex: 1,
                    paddingLeft: 0
                  }}
                  onClick={() => addVendor(preferredVendors)}
                />
                <DKButton
                  title={`- ${t('PREFERRED_VENDOR.REMOVE_ALL')}`}
                  className="text-blue fw-m p-0"
                  style={{
                    marginTop: -7,
                    zIndex: 1,
                    paddingLeft: 20
                  }}
                  disabled={preferredVendors?.length === 0}
                  onClick={() => removeAllVendor(preferredVendors)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}

export default AddPreferredVendors;
