import React, { Component } from "react";
import "../../index.css";
import "./css/Templates.css";
import ComponentManager from "../../Manager/ComponentManager";
import {COLOR_LIGHTGRAY, COLOR_LIGHTGRAY2, FONT_SIZE} from '../../Constants/Constants';
import DocumentManager from "../../Manager/DocumentManager";
import Utility, { getFontSizeClass, getIsFulfillment, getIsGoodsReceipt, getPageHeight, getVW} from '../../Utilities/Utility';
import {getLocalisedText} from "../../Translate/LanguageManager";
import DKEInvoiceSection, { eInvoiceSectionPosition } from "../../DKUILibrary/DKEInvoiceSection";
import DKCustomFieldSection from "../../DKUILibrary/DKCustomFieldSection";
import DKSignatureSection from "../../DKUILibrary/DKSignatureSection";
import { AddressType } from "../../Helper/AddressParser";
import { HTMLTag } from "../../Utilities/HTMLTag";
import TopSectionAboveTableUtility from "../../Utilities/TopSectionAboveTableUtility";
import DKComponentListTable from "../../DKUILibrary/DKComponentListTable";
import DKApprovalSection from "../../DKUILibrary/DKApprovalSection";

export default class DocumentTemplate10 extends Component {
    arabicHeaderWidth = '50%'
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    addCompanyNameAndDocTypeLabels() {
        return (
            <div className="ColumnDiv" style={{ width: '100%' }}>
                <div style={{width: '100%', height: getVW(Utility.convertToPrintScale(30, this.state.data.printScale)), backgroundColor: this.state.data.themeColor }}/>
                <div className="RowDiv" style={{ alignItems: 'center'}}>
                    <div className="RowDiv" style={{justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <div className="RowDiv" style={{  alignItems: 'flex-start'}}>
                            {ComponentManager.addHorizontalSpace(10)}
                            {ComponentManager.getLogoVisibility(this.state.data.showCompanyLogo, this.state.data.isReadOnlyMode, this.state.data.logo) && ComponentManager.getLogo(this.state.data.logo, Utility.convertToPrintScale(80, this.state.data.printScale), { position: 'static', alignItems: 'flex-start', left: getVW(10)})}
                            {ComponentManager.addHorizontalSpace(10)}
                            <div className="ColumnDiv"
                                style={{
                                    width: Utility.getIsArabicLang() ? this.arabicHeaderWidth : '65%',
                                    alignItems: Utility.getIsArabicLang() ? 'flex-end' : 'flex-start'
                                }}>
                                {this.state.data.showCompanyName && <text className={"docFontStyleBold" + getFontSizeClass()}
                                    style={{
                                        ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.medium }, this.state.data.printScale)
                                    }}>
                                    {getLocalisedText(Utility.getCompanyName(this.state.data))}
                                </text>}
                                { this.state.data.showFrom && ComponentManager.getDescriptionSection (
                                    "",
                                    Utility.getCompanyAddress(this.state.data),
                                    "55%",
                                    'black',
                                    false,
                                    '#000000',
                                    getVW(6),
                                    Utility.getOwnerType(this.state.data, AddressType.from),
                                    this.state.data.printScale
                                ) }
                            </div>
                        </div>
                        <div
                            className="ColumnDiv"
                            style={{ width: "40%", alignItems: 'center', marginRight: getVW(20) }}
                        >
                            <text className={"docFontStyleBold" + getFontSizeClass()}
                                style={{
                                    marginTop: getVW(15),
                                    color: 'gray',
                                    alignSelf: Utility.getIsArabicLang() ? 'flex-end' : 'auto',
                                    ...Utility.getFontStyleProp({ fontSize: FONT_SIZE.xMedium }, this.state.data.printScale)
                                }}>
                                { getLocalisedText(this.state.data.documentType).toUpperCase() }
                            </text>
                            {ComponentManager.addVerticalSpace(10)}
                            {this.getDocumentDetailsSection()}
                        </div>
                    </div>
                </div>
                {ComponentManager.addVerticalSpace(10)}
            </div>
        );
    }

    getDocumentDetailsSection() {
        var documentDetailList = Utility.getDocumentDetailsList(this.state.data)
        return documentDetailList.map((element, index) => {
            var value = ''
            if (element.key !== undefined) {
                value = this.state.data[element.key]
            }
            else if (element.customFieldValue !== undefined) {
                value = element.customFieldValue
            }

            return <>
                {index !== 0 && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' }) }
                {index !== 0 && ComponentManager.addVerticalSpace(10)}
                {ComponentManager.getRowValueSection(
                    element.label,
                    value,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.state.data.printScale
                )}
                {index === documentDetailList.length -1 && ComponentManager.addDivider({ backgroundColor: 'lightgray', height: getVW(1), width: '100%' })}
            </>
        });
    }

    // addTermsNConditionsView() {
    //     return (
    //         <div className="ColumnDiv" style={{ position: "absolute", bottom: getVW(60), width: '100%', justifyContent: 'center', alignItems: "flex-start"  }}>
    //
    //             {(this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) &&
    //             ComponentManager.getDescriptionSection(
    //                 "Terms & Condition",
    //                 this.state.data.termsAndCondition,
    //                 "30%",
    //             )}
    //             {((this.state.data.showTermsAndCondition && this.state.data.termsAndCondition) && (this.state.data.showNotes && this.state.data.notes)) && ComponentManager.addVerticalSpace(20)}
    //             {(this.state.data.showNotes && this.state.data.notes) &&
    //             ComponentManager.getDescriptionSection(
    //                 "Note",
    //                 this.state.data.notes,
    //                 "30%",
    //             )}
    //         </div>
    //     )
    // }

    addCompanyAndInvoiceDetails() {
        return (
            <div className="RowDiv" style={{ backgroundColor: '#F3F3F3'}}>
                {this.addCompanyNameAndDocTypeLabels()}
            </div>
        )
    }

    addAddressesField() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    width: '100%'
                }}
            >
                {this.state.data.showClientBillToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.billToTitle,
                    Utility.getClientBillToAddress(this.state.data),
                    "100%",
                    'black',
                    true,
                    'black',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.billTo),
                    this.state.data.printScale
                )}
                {(this.state.data.showClientBillToAddress && this.state.data.showClientShipToAddress) && <div style={{ width: "10%" }} />}
                {this.state.data.showClientShipToAddress && ComponentManager.getDescriptionSection(
                    this.state.data.shipToTitle,
                    this.state.data.clientShipToAddress,
                    "100%",
                    'black',
                    true,
                    'black',
                    getVW(6),
                    Utility.getOwnerType(this.state.data, AddressType.shipTo),
                    this.state.data.printScale
                )}
            </div>
        )
    }
    getSourceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getSourceOfSupply()}

            </div>
        )
    }

    getSourceOfSupply() {
        return <div className='RowDiv' style={{ width: '100%' }}>
            <div className='ColumnDiv' style={{
                width: '52%'
            }}>
                {Utility.getIsShowSourceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('source_of_supply'),
                    this.state.data.sourceOfSupply,
                    "100%",
                   'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowDestinationOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('destination_of_supply'),
                    this.state.data.destinationOfSupply,
                    "100%",
                   'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
          </div>

        </div>;
    }

    render() {
        return (
            <div className="DocumentHolder" style={{ minHeight: getPageHeight(this.state.data.printScale)}}>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.top}
                />
                {this.addCompanyAndInvoiceDetails()}
                {ComponentManager.addVerticalSpace(40)}
                {this.addAddressesField()}
                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && ComponentManager.addVerticalSpace(20)}
                {(Utility.getIsShowSourceOfSupply(this.state.data) || Utility.getIsShowDestinationOfSupply(this.state.data)) && this.getSourceOfSupplyInfoSection()}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {Utility.getIsShowPlaceOfSupply(this.state.data) && this.getPlaceOfSupplyInfoSection()}
                {Utility.getIsContainsCustomField('top') && ComponentManager.addVerticalSpace(25)}
                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'top'}/>
                {ComponentManager.addVerticalSpace(25)}
                {
                    TopSectionAboveTableUtility.getIsTopSectionAboveTableIsVisible() &&
                    <>
                        <div style={{ ...TopSectionAboveTableUtility.getTopSectionWithTableFontStyleProp(undefined, this.state.data.printScale) }}
                            dangerouslySetInnerHTML={{ __html: TopSectionAboveTableUtility.getHtmlString(this.state.data) }} />
                        {ComponentManager.addVerticalSpace(5)}
                    </>
                }
                <div className="ColumnDiv" style={{width: '100%'}}>
                    {this.getProductTable()}
                    {Utility.getIsShowAccountProductTable(this.state.data) && ComponentManager.addVerticalSpace(25)}
                    {this.getEditableDocumentTable()}
                </div>
                {Utility.getIsShowExchangeRate(this.state.data) && ComponentManager.addVerticalSpace(20)}
                {Utility.getIsShowExchangeRate(this.state.data) && this.getExchangeRateTable(this.getTableStyle(), this.state.data)}
                {Utility.getIsContainsCustomField('bottom') && ComponentManager.addVerticalSpace(25)}
                <DKCustomFieldSection data={this.state.data} onCustomFieldClick={this.props.onCustomFieldClick} style={this.getCustomFieldTableStyle()} customFieldPosition={'bottom'} />
                {Utility.getIsIncludesBomProductTable() && ComponentManager.addVerticalSpace(15)}
                {Utility.getIsIncludesBomProductTable() && <DKComponentListTable data={this.state.data} />}
                {Utility.getIsShowApprovalSection() &&
                    <div className='parent-width' style={{ width: '100%', position: 'absolute', bottom: getVW(16) }}>
                        <DKApprovalSection data={this.state.data} style={this.getTableStyle()} isThemeColor />
                    </div>
                }
                <div id={HTMLTag.SIGNATURE} className="break-inside-avoid">
                    {ComponentManager.addVerticalSpace(30)}
                    {this.state.data.showSignature && ComponentManager.getSignature(this.state.data.signature, this.state.data.printScale)}
                </div>
                <div className='parent-width' style={{ width: '100%', position: 'absolute', bottom: getVW(16) }}>
                    {(getIsFulfillment(this.state.data.type) || getIsGoodsReceipt(this.state.data.type)) && <DKSignatureSection data={this.state.data} onSignatureSectionClicked={this.props.tableClicked} />}
                </div>
                {/*{this.addTermsNConditionsView()}*/}
                <div style={{width: '100%', height: getVW(Utility.convertToPrintScale(30, this.state.data.printScale)), backgroundColor: this.state.data.themeColor, position: "absolute", bottom: 0}}/>
                <DKEInvoiceSection
                    data={this.state.data}
                    position={eInvoiceSectionPosition.bottom}
                />
                {this.state.data.showEInvoiceSection && ComponentManager.addVerticalSpace(40)}
            </div>
        );
    }

    getEditableDocumentTable() {
        return ComponentManager.getDKDocumentTable(
            this.props.tableColumn,
            this.getTableStyle(),
            this.props.data,
            this.props.tableClicked,
            this.props.stockTableClicked,
            this.props.onUpdateDataCallback,
            this.props.paymentTableClicked,
            this.props.additionalTableCallback,
        )
    }

    getProductTable() {
        if(Utility.getIsShowAccountProductTable(this.state.data)) {
            return ComponentManager.getDKProductTable(
                Utility.getTableColumnForAccountProduct(),
                this.getTableStyle(),
                this.props.data,
                this.props.tableClicked,
                this.props.stockTableClicked,
                this.props.onUpdateDataCallback,
                this.props.paymentTableClicked,
                this.props.additionalTableCallback,
            )
        }
    }

    getTableStyle() {
        let style = DocumentManager.getTableDefaultStyle();
        style.headerBackgroundColor = this.state.data.themeColor;
        style.headerBorderColor = this.state.data.themeColor;
        style.headerTextColor = "white";
        style.itemBorderColor = COLOR_LIGHTGRAY2;
        style.itemBackgroundColor = COLOR_LIGHTGRAY;
        style.itemBackgroundColorIsAlternate = true;
        style.footerBackgroundColor = this.state.data.themeColor+'33';
        style.footerBorderColor = 'lightgray';
        style.footerTextColor = "black";
        style.subTotalBorderColor = 'lightgray';
        return style;
    }

    getCustomFieldTableStyle() {
        let style = {}
        style.columnCount = 2
        style.columnWidths = Utility.getIsArabicLang() ? ['47.5%', '52.5%'] : ['52.5%', '47.5%']
        return style;
    }

    getPlaceOfSupplyInfoSection() {
        return (
            <div
                className="RowDiv"
                style={{
                    alignItems: "flex-start",
                    justifyContent: 'space-between',
                    backgroundColor: "white",
                }}
            >
                {this.getPlaceOfSupply()}

            </div>
        )
    }
    getPlaceOfSupply() {
        return <div className='RowDiv' style={{ width: '70%' }}>
            <div className='ColumnDiv' style={{
                width: '40%'
            }}>
                {Utility.getIsShowPlaceOfSupply(this.state.data) && ComponentManager.getDescriptionSection(
                    getLocalisedText('place_of_supply'),
                    this.state.data.placeOfSupply,
                    "100%",
                    'black',
                    false,
                    '#000000',
                    getVW(6),
                    true,
                    this.state.data.printScale
                )}
            </div>

        </div>;
    }

    getExchangeRateTable(tableStyle, data) {
        return (
            <div id={HTMLTag.EXCHANGE_RATE} className="RowDiv" style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "45%" }}>
                    {ComponentManager.getExchangeRateTable(tableStyle, data)}
                </div>
            </div>
        );
    }
}
