import { useEffect, useState, useRef } from 'react';
import { DKLabel } from 'deskera-ui-library';
import { WALKTHROUGH_STATUS_KEY } from '../../Constants/Constant';
import Utility from '../../Utility/Utility';
import RouteManager from '../../Managers/RouteManager';
import { Walkthrough } from './DashboardWalkthrough';
import { useAppDispatch } from '../../Redux/Hooks';
import { setWalkthroughEvents } from '../../Redux/Slices/CommonDataSlice';

interface WalkthroughProps {
  data: Walkthrough[];
}
const WalkthroughTooltip = (props: WalkthroughProps) => {
  const persistedWalkthroughStatus =
    localStorage.getItem(WALKTHROUGH_STATUS_KEY) !== null
      ? JSON.parse(localStorage.getItem(WALKTHROUGH_STATUS_KEY) as string)
      : null;

  const [walkthroughData, setWalkthroughData] = useState(props.data);
  const [displayWalkthrough, setDisplayWalkthrough] = useState(
    persistedWalkthroughStatus !== null
      ? persistedWalkthroughStatus?.display
      : false
  );
  const [walkthroughStep, setWalkthroughStep] = useState(0);
  const walkthroughStepRef = useRef(0);
  walkthroughStepRef.current = walkthroughStep;
  const [tooltipPosition, setTooltipPosition] = useState<any>();
  const dispatch = useAppDispatch();
  const tooltipRef = useRef<any>();

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  useEffect(() => {
    const currentStepObj = props.data[walkthroughStepRef.current];
    if (!currentStepObj.tooltipInCenter) {
      setTooltipPosition({ ...getTooltipPosition(currentStepObj) });
    }
    dispatch(setWalkthroughEvents(currentStepObj.events));
  }, [walkthroughStep]);

  const onWindowResize = () => {
    const currentStepObj = props.data[walkthroughStepRef.current];
    if (!currentStepObj.tooltipInCenter) {
      setTooltipPosition({ ...getTooltipPosition(currentStepObj) });
    }
  };

  const getTooltipPosition = (item: any) => {
    let stepMarker: Element | null = document
      .getElementsByClassName(item.lookupClassName)
      ?.item(0);
    if (item.scrollToElement) {
      const doc_forms = document.getElementsByClassName('document-form');
      if (doc_forms?.length) {
        let doc_form: Element;
        doc_form = doc_forms.item(0) as Element;
        // doc_form.proto
        if (doc_form) {
          const stepMarkerBottom = stepMarker?.getBoundingClientRect()
            ?.bottom as number;
          doc_form.scrollTo(0, stepMarkerBottom + 5);
          stepMarker = document
            .getElementsByClassName(item.lookupClassName)
            ?.item(0);
        }
      }
    }
    if (stepMarker && !item.tooltipInCenter) {
      const tooltipWidth = tooltipRef.current?.getBoundingClientRect()?.width;
      const tooltipHeight = tooltipRef.current?.getBoundingClientRect()?.height;
      const stepMarkerLeft = stepMarker?.getBoundingClientRect()?.left;
      const stepMarkerRight = stepMarker?.getBoundingClientRect()?.right;
      const stepMarkerTop = stepMarker?.getBoundingClientRect()?.top;
      const stepMarkerBottom = stepMarker?.getBoundingClientRect()?.bottom;
      const stepMarkerHeight = stepMarker?.getBoundingClientRect()?.height;
      const stepMarkerWidth = stepMarker?.getBoundingClientRect()?.width;

      let tooltipLeft = 0;
      let tooltipTop = 0;
      if (item.tooltipDirection === 'left') {
        tooltipLeft = stepMarkerLeft - tooltipWidth - 10;
        if (item.arrowPos === 'top') {
          tooltipTop = stepMarkerTop - 2;
        } else if (item.arrowPos === 'bottom') {
          tooltipTop =
            stepMarkerBottom - tooltipHeight - stepMarkerHeight / 2 + 20;
        } else {
          tooltipTop = stepMarkerTop - tooltipHeight / 2 + stepMarkerHeight / 2;
        }
      } else if (item.tooltipDirection === 'right') {
        tooltipLeft = stepMarkerRight + 10;
        if (item.arrowPos === 'top') {
          tooltipTop = stepMarkerTop - 2;
        } else if (item.arrowPos === 'bottom') {
          tooltipTop =
            stepMarkerBottom - tooltipHeight - stepMarkerHeight / 2 + 20;
        } else {
          tooltipTop = stepMarkerTop - tooltipHeight / 2 + stepMarkerHeight / 2;
        }
      } else if (item.tooltipDirection === 'top') {
        tooltipTop = stepMarkerTop - tooltipHeight - 5;
        if (item.arrowPos === 'left') {
          tooltipLeft = stepMarkerLeft;
        } else if (item.arrowPos === 'right') {
          tooltipLeft = stepMarkerRight - tooltipWidth;
        } else {
          tooltipLeft = stepMarkerLeft - tooltipWidth / 2 + stepMarkerWidth / 2;
        }
      } else if (item.tooltipDirection === 'bottom') {
        tooltipTop = stepMarkerBottom + 5;
        if (item.arrowPos === 'left') {
          tooltipLeft = stepMarkerLeft;
        } else if (item.arrowPos === 'right') {
          tooltipLeft = stepMarkerRight - tooltipWidth;
        } else {
          tooltipLeft = stepMarkerLeft - tooltipWidth / 2 + stepMarkerWidth / 2;
        }
      }
      return {
        left: tooltipLeft,
        top: tooltipTop
      };
    }
  };

  const getTooltip = (item: any) => {
    let calculatedStyle: any = {};
    if (item.tooltipInCenter) {
      calculatedStyle = {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999
      };
    } else {
      const itemPos = tooltipPosition;
      // console.log('itemPos: ', itemPos);
      if (!Utility.isEmpty(itemPos)) {
        calculatedStyle = {
          left: itemPos.left,
          top: itemPos.top,
          zIndex: 999
        };
      }
    }
    return (
      <div
        ref={tooltipRef}
        className={`walkthrough-tooltip ${item.tooltipDirection} on`}
        style={calculatedStyle}
      >
        <div className={`tooltip-arrow ${item.arrowPos}`}></div>
        <div className="tooltip-content">
          {item.title.length !== 0 && (
            <DKLabel text={item.title} className="col fw-m fs-l" />
          )}
          <DKLabel text={item.msg} className="mt-s" />
          <div
            className="row justify-content-between"
            style={{
              position: 'absolute',
              left: 0,
              bottom: 20,
              padding: '0 20px'
            }}
          >
            <button
              className="text-white border-m border-radius-s p-v-xxs p-h-s"
              style={{ borderColor: '#fff' }}
              onClick={() => {
                if (item.title === 'Finished Tour') {
                  setDisplayWalkthrough(false);
                  dispatch(setWalkthroughEvents([]));
                  let persistedWalkthroughStatus =
                    localStorage.getItem(WALKTHROUGH_STATUS_KEY) !== null
                      ? JSON.parse(
                          localStorage.getItem(WALKTHROUGH_STATUS_KEY) as string
                        )
                      : null;
                  if (persistedWalkthroughStatus) {
                    persistedWalkthroughStatus = {
                      ...persistedWalkthroughStatus,
                      display: false,
                      finished: true
                    };
                    Utility.setPersistentValue(
                      WALKTHROUGH_STATUS_KEY,
                      JSON.stringify(persistedWalkthroughStatus)
                    );
                  }
                } else {
                  setWalkthroughStep(walkthroughStep + 1);
                }
                if (item.navigateToPage !== '') {
                  RouteManager.navigateToPage(item.navigateToPage);
                }
              }}
            >
              {item.btnTitle}
            </button>
            {walkthroughStep !== props.data?.length - 1 && (
              <button
                className="text-underline text-white"
                onClick={() => {
                  setDisplayWalkthrough(false);
                  dispatch(setWalkthroughEvents([]));
                  let persistedWalkthroughStatus =
                    localStorage.getItem(WALKTHROUGH_STATUS_KEY) !== null
                      ? JSON.parse(
                          localStorage.getItem(WALKTHROUGH_STATUS_KEY) as string
                        )
                      : null;
                  if (persistedWalkthroughStatus) {
                    persistedWalkthroughStatus = {
                      ...persistedWalkthroughStatus,
                      display: false,
                      skipped: true
                    };
                    Utility.setPersistentValue(
                      WALKTHROUGH_STATUS_KEY,
                      JSON.stringify(persistedWalkthroughStatus)
                    );
                  }
                }}
              >
                Skip
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {displayWalkthrough && (
        <div className="transparent-background-light">
          {getTooltip(walkthroughData[walkthroughStep])}
        </div>
      )}
    </div>
  );
};

export default WalkthroughTooltip;
