import { Store } from '../../Redux/Store';
import {
  DKButton,
  DKIcon,
  DKIcons,
  DKListPicker,
  showAlert,
  showToast
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import AuthService from '../../Services/Auth';
import IamService from '../../Services/iam';
import AppManager from '../../Managers/AppManager';
import Utility from '../../Utility/Utility';
import { MOBILE_APP_ACTIONS } from '../../Constants/Constant';
import { fetchAllTenantsInfo } from '../../Redux/Slices/AuthSlice';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';

export default function TabletToolbar(props: any) {
  const [tenantList, setTenantList] = useState<any>([]);
  const [showTenantList, setShowTenantList] = useState(false);

  const supportedCountries = ['US', 'IN', 'SG', 'MY', 'UK', 'IL'];

  useEffect(() => {
    Store.dispatch(fetchAllTenantsInfo()).then((res: any) => {
      let response = res?.payload || [];
      response = response.filter((item: any) => {
        if (supportedCountries.includes(item.countryLookUpCode)) return item;
      });
      setTenantList(response);
    });
  }, []);

  useEffect(() => {
    AppManager.handleMobileAppMessageListener(onMobileAppMessageReceived, true);

    return () => {
      AppManager.handleMobileAppMessageListener(
        onMobileAppMessageReceived,
        false
      );
    };
  });

  const onMobileAppMessageReceived = (event: any) => {
    const data = event?.data?.productAppScannedData;
    if (!Utility.isEmpty(data)) {
      parseUniversalBarcodeData(data);
    }
  };

  const parseUniversalBarcodeData = (data: any) => {
    let product = data.data;
    switch (data.type) {
      case 'STOCK_ADJUSTMENT':
        RouteManager.navigateToPage(
          PAGE_ROUTES.STOCK_ADJUSTMENT,
          JSON.stringify(data)
        );
        break;
      case 'STOCK_TRANSFER':
        RouteManager.navigateToPage(
          PAGE_ROUTES.STOCK_TRANSFER,
          JSON.stringify(data)
        );
        break;
      case 'PRODUCT':
        RouteManager.navigateToPage(PAGE_ROUTES.PRODUCTS, JSON.stringify(data));
        break;
    }
  };

  function handleTenantSelection(newSelectedTenantDetails: any) {
    if (newSelectedTenantDetails.tenantId !== AuthService.getUserTenantID()) {
      IamService.switchTenant({
        tenantId: newSelectedTenantDetails.tenantId
      }).then(
        (res) => {
          switchTenantDelegator(newSelectedTenantDetails.tenantId);
          AppManager.refreshApp();
        },
        (err) => {}
      );
    } else {
      showToast('Current Organisation');
    }
    setShowTenantList(false);
  }

  const getFirstLetterOfTenant = (title: string) => {
    return title?.charAt(0);
  };

  const switchTenantDelegator = (tenantId: string) => {
    Utility.postMobileAppActions(
      MOBILE_APP_ACTIONS.SWITCH_TENANT + ':' + tenantId
    );
  };

  const appBarcodeActionDelegator = () => {
    Utility.postMobileAppActions(MOBILE_APP_ACTIONS.APP_BARCODE_SCANNER);
  };

  const appAIBotActionDelegator = () => {
    Utility.postMobileAppActions(MOBILE_APP_ACTIONS.APP_AI_BOT);
  };

  const logoutActionDelegator = () => {
    let buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray2',
        onClick: () => {}
      },
      {
        title: 'Log out',
        className: 'bg-red text-white ml-r',
        onClick: () => {
          Utility.postMobileAppActions(MOBILE_APP_ACTIONS.LOGOUT);
        }
      }
    ];
    showAlert('Log out', 'Are you sure want to log out?', buttons);
  };

  return (
    <div
      className="parent-width row justify-content-between mob-p-h-xl p-v-s shadow-s border-b-gray z-index-3"
      style={{ backgroundColor: '#2C1F47' }}
    >
      <div className="cursor-hand row">
        {
          window.location.href.includes('tablet-dashboard') ? (
            <text
              onClick={() => setShowTenantList(!showTenantList)}
              className={'bg-white text-black border-gray font-bold pt-m'}
              style={{
                width: 36,
                height: 36,
                borderRadius: 18,
                borderWidth: 0.5,
                fontSize: 22,
                padding: 8
              }}
            >
              {getFirstLetterOfTenant(
                Store.getState().authInfo?.currentTenantInfo?.data.name
              )}
            </text>
          ) : (
            <div
              onClick={() => {
                window.history.back();
              }}
            >
              <DKIcon src={DKIcons.white.ic_arrow_left} className={'ic-s-2'} />
              {/*<text>Log Out</text>*/}
            </div>
          )
          //   (
          //   <text
          //     onClick={() => {
          //       window.history.back();
          //     }}
          //     className={'text-white'}
          //     style={{ fontSize: 30 }}
          //   >
          //     &#8592;
          //   </text>
          // )
        }
        <DKButton
          onClick={() =>
            window.location.href.includes('tablet-dashboard') &&
            setShowTenantList(!showTenantList)
          }
          title={
            !window.location.href.includes('tablet-dashboard')
              ? Store.getState().authInfo?.currentTenantInfo?.data.name
              : showTenantList
              ? Store.getState().authInfo?.currentTenantInfo?.data.name + '  ▲'
              : Store.getState().authInfo?.currentTenantInfo?.data.name + '  ▼'
          }
          className="font-semibold text-white"
          style={{ fontSize: 16 }}
        />
        {/*<DKIcons icon={DKIcons.ic_arrow_down} />*/}
      </div>
      {window.location.href.includes('tablet-dashboard') && (
        <div
          className={`bg-white`}
          style={{
            width: 36,
            height: 36,
            borderRadius: 18,
            borderWidth: 0.5,
            padding: 7
          }}
          onClick={() => {
            appBarcodeActionDelegator();
          }}
        >
          <DKIcon src={DKIcons.ic_barcode} className={'ic-s-2'} />
          {/*<text>Log Out</text>*/}
        </div>
      )}
      {window.location.href.includes('tablet-dashboard') && (
      <div
        className={`bg-white`}
        style={{
          width: 36,
          height: 36,
          borderRadius: 18,
          borderWidth: 0.5,
          padding: 7,
          marginLeft: 16
        }}
        onClick={() => {
          appAIBotActionDelegator();
        }}
      >
        <DKIcon src={DKIcons.ic_webhooks} className={'ic-s-2'} />
        {/*<text>Log Out</text>*/}
      </div>
    )}
      {showTenantList ? (
        <DKListPicker
          data={tenantList.map((tenant: any) => tenant.tenantName)}
          className="position-absolute z-index-6 border-m shadow-l hide-scroll-bar"
          style={{
            top: '45px',
            left: '2%',
            width: 'max-content'
          }}
          onSelect={(index: any, selectedName: any) =>
            handleTenantSelection(tenantList[index])
          }
          onClose={() => setShowTenantList(false)}
        />
      ) : null}
    </div>
  );
}
