import { showToast, TOAST_TYPE } from 'deskera-ui-library';
import ApiConstants from '../Constants/ApiConstants';
import { FulfillmentPayload } from '../Models/Fulfillment';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface FulfillmentAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
}

const defaultConfig: FulfillmentAPIConfig = {
  SearchTerm: '',
  Limit: 100,
  Page: 0
};

class FulfillmentService {
  static apiConfig: FulfillmentAPIConfig;

  static saveFulfillment(payload: FulfillmentPayload) {
    return http2
      .post(`${ApiConstants.URL.FULFILLMENT.SAVE_FULFILLMENT}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static saveBulkFulfillment(payload: any) {
    return http2
      .post(`${ApiConstants.URL.FULFILLMENT.SAVE_BULK_FULFILLMENT}`, payload)
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static getFulfillmentRecords(documentCode: any, documentType: any) {
    let endpoint: string =
      ApiConstants.URL.FULFILLMENT.GET_FULFILLMENT_RECORDS(documentCode);
    return http
      .get(`${endpoint}?documentType=${documentType}&checkLinkedDocs=true`)
      .catch((err: any) => {
        showToast('Unable to get fulfillment records', TOAST_TYPE.ERROR);
        return Promise.reject(err);
      });
  }
  static getInvoiceBulkFulfillmentDetails(documentCodes: any) {
    return http
      .post(
        `${ApiConstants.URL.FULFILLMENT.GET_INVOICE_BULK_FULFILLMENT_DETAILS}`,
        documentCodes
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }
  static getQuoteBulkFulfillmentDetails(documentCodes: any) {
    return http
      .post(
        `${ApiConstants.URL.FULFILLMENT.GET_QUOTE_BULK_FULFILLMENT_DETAILS}`,
        documentCodes
      )
      .catch((err: any) => {
        console.error('Error creating payment: ', err);
        return Promise.reject(err);
      });
  }

  static deleteInvoiceFulfillment(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.FULFILLMENT.DELETE_INVOICE_FULFILLMENT(
        data.fulfillmentCode,
        data.code,
        data.isBulk
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting PRICE LIST: ', err);
      return Promise.reject(err);
    }
  }

  static deleteSalesOrderFulfillment(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.FULFILLMENT.DELETE_SALES_ORDER_FULFILLMENT(
        data.fulfillmentCode,
        data.code,
        encodeURIComponent(data.documentSequenceCode),
        data.isBulk
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting fulfillment: ', err);
      return Promise.reject(err);
    }
  }

  static deleteQuoteFulfillment(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.FULFILLMENT.DELETE_QUOTE_FULFILLMENT(
        data.fulfillmentCode,
        data.code,
        encodeURIComponent(data.documentSequenceCode),
        data.isBulk
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting PRICE LIST: ', err);
      return Promise.reject(err);
    }
  }
  static deleteSalesOrderPendingFulfillment(data: any) {
    const finalEndpoint: string =
      ApiConstants.URL.FULFILLMENT.DELETE_SALES_ORDER_PENDING_FULFILLMENT(
        data.fulfillmentCode
      );
    try {
      return http.delete(`${finalEndpoint}`);
    } catch (err) {
      console.error('Error deleting pending fulfillment: ', err);
      return Promise.reject(err);
    }
  }
}

export default FulfillmentService;
