import {
  DKButton,
  DKCheckMark,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKLine,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_barcode_green from '../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../Assets/Icons/ic_barcode_red.svg';
import {
  BOOKS_DATE_FORMAT,
  MODULE_TYPE,
  PRODUCE_PRODUCT_TYPE,
  STATUS_TYPE,
  WIP_CONSUMPTION_TYPES
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import useQCConfirm from '../../../Hooks/useQCConfirm';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchAdvancedTrackingData } from '../../../Redux/Slices/AdvancedTrackingDataSlice';
import { fetchSerialTrackingProducts } from '../../../Redux/Slices/SerialTrackingSlice';
import { fetchProductInventoryByID } from '../../../Redux/Slices/WarehouseProductSlice';
import { selectWarehouse } from '../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../Services/DateFormat';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import CommonQualityCheckService from '../../../Services/QualityCheck';
import WarehouseService, {
  WarehouseAPIConfig
} from '../../../Services/Warehouse';
import BatchTrackingReceive from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingReceive';
import SerialTrackedReceive from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedReceive';
import NoneTrackedReceive from '../../../SharedComponents/WarehouseManagement/NoneTrackedReceive';
import Utility, { deepClone } from '../../../Utility/Utility';
import { WORK_ORDER_STATUS } from '../Constants/MRPColumnConfigs';
import ConsumeProduceHelper from '../WorkOrder/InnerComponents/ConsumptionProduction/ConsumeProduceHelper';
import ProcessManufacturingService from '../../../Services/MRP/ProcessManufacturing';
import { selectBatchSerialCustomFields } from '../../../Redux/Slices/CommonDataSlice';
import { fetchBatchTrackingProducts } from '../../../Redux/Slices/BatchTrackingSlice';

const ProductionPopup = (props: any) => {
  //common states
  const [updating, setUpdating] = useState(false);
  const [saveApiLoading, setSaveApiLoading] = useState(false);

  //production states
  const [productionColumnConfigs, setProductionColumnConfigs] = useState(
    ConsumeProduceHelper.ENTER_CONSUMPTION_COLUMNS?.filter((col: any) => {
      return col.id !== 'wastageQty' && col.id !== 'consumptionQty';
    })
  );
  const [productionLineItems, setProductionLineItems] = useState<any[]>([]);

  //scrap/byproduct states
  const [scrapColumnConfigs, setScrapColumnConfigs] = useState(
    ConsumeProduceHelper.ENTER_SCRAP_BYPRODUCT_COLUMNS
  );
  const [scrapLineItems, setScrapLineItems] = useState<any[]>([]);

  //fulfillment states
  const [showSerialTracking, setShowSerialTracking] = useState(false);
  const [showBatchTracking, setShowBatchTracking] = useState(false);
  const [showMultipleWarehouse, setShowMultipleWarehouse] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<any>(-1);
  const [isWastageFulfillmentFlow, setIsWastageFulfillmentFlow] = useState('');
  const [isScrapRowPresent, setIsScrapRowPresent] = useState(false);
  const [includeByProductCost, setIncludeByProductCost] = useState(false);
  const [isAutoConsumeResponseFetched, setIsAutoConsumeResponseFetched] =
    useState(!props?.autoConsume ? true : false);

  //constant
  const diableBtn = props.workOrderItems.status === WORK_ORDER_STATUS.COMPLETED;

  //selectors
  const { qcConfirm } = useQCConfirm();
  const dispatch: any = useAppDispatch();
  const warehouseData = useAppSelector(selectWarehouse);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);

  //effects
  useEffect(() => {
    fetchProductionHistoryDetails(
      getHistoryApiCallParams(WIP_CONSUMPTION_TYPES.PRODUCTION)
    );

    let scrapRows = props.workOrderItems?.workOrderItems;

    scrapRows = scrapRows?.filter((row: any) => {
      return row.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE;
    });

    if (!Utility.isEmpty(scrapRows)) {
      setIsScrapRowPresent(true);
    }

    // let productCodeArr = scrapRows?.map((item: any) => {
    //   return item.itemName?.productId;
    // });
    // fetchScrapByProductHistoryDetails(productCodeArr);

    updateConsumptionConfig();
    updateScrapColumnConfig();

    if (props?.autoConsume) {
      updateAutoConsume();
    }
  }, []);

  //////////////////////////// Save Start ///////////////////////////////////

  const isValidated = (rows: any) => {
    let rowsCopy: any = [...rows];

    const found = rowsCopy?.find((row: any) => {
      return Utility.isEmpty(row.warehouseInventoryData);
    });
    return Utility.isEmpty(found);
  };

  const onSave = () => {
    let produceLineItems = [...productionLineItems];
    let scrapByProductLineItems = [...scrapLineItems];
    let onlyScrapRows = [];
    let onlyByProductRows: any[] = [];

    try {
      produceLineItems = produceLineItems
        ?.filter((item: any) => {
          return !('id' in item);
        })
        ?.map((item: any) => {
          const qty =
            item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
              return Number(acc) + Number(obj?.quantity ?? 0);
            }, 0) ?? 0;
          return {
            costPerUnit: item?.inventoryPrice ?? 0,
            uomCostPerUnit: item?.inventoryPrice ?? 0,
            // createdDate: new Date(),
            documentDate: DateFormatService.getDateStrFromDate(
              item.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCode: item.productCode,
            quantity: qty,
            uomQuantity: qty,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            type: WIP_CONSUMPTION_TYPES.PRODUCTION,
            warehouseInventoryData: item.warehouseInventoryData?.map(
              (whData: any) => {
                const { warehouse, row, rack, bin, ...newWHData } = whData;
                return {
                  ...newWHData,
                  uomQuantity: newWHData?.quantity,
                  qtyToFulfilUom: newWHData?.qtyToFulfil,
                  warehouseCode:
                    whData?.warehouseCode ?? whData?.warehouse?.code,
                  warehouseName:
                    whData?.warehouseName ?? whData?.warehouse?.name,
                  advancedTrackingType: item?.advancedTracking,
                  advancedTrackingData: newWHData?.advancedTrackingData?.map(
                    (advData: any) => {
                      return {
                        ...advData,
                        qtyToFulfilUom: advData?.qtyToFulfil
                      };
                    }
                  )
                };
              }
            ),
            workOrderCode: props.workOrderItems?.workOrderCode,
            workOrderId: props.workOrderItems?.id,
            includeByProductCost: includeByProductCost
          };
        });

      onlyScrapRows = scrapByProductLineItems
        ?.filter((item: any) => {
          return (
            !('id' in item) &&
            item.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP
          );
        })
        ?.map((item: any) => {
          const qty =
            item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
              return Number(acc) + Number(obj?.quantity ?? 0);
            }, 0) ?? 0;
          return {
            costPerUnit: item?.costPerUnit ?? 0,
            uomCostPerUnit: item?.costPerUnit ?? 0,
            // createdDate: new Date(),
            documentDate: DateFormatService.getDateStrFromDate(
              item.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCode: item.productCode,
            quantity: qty,
            uomQuantity: qty,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            type: WIP_CONSUMPTION_TYPES.SCRAP,
            warehouseInventoryData: item.warehouseInventoryData?.map(
              (whData: any) => {
                const { warehouse, row, rack, bin, ...newWHData } = whData;
                return {
                  ...newWHData,
                  uomQuantity: newWHData?.quantity,
                  qtyToFulfilUom: newWHData?.qtyToFulfil,
                  warehouseCode:
                    whData?.warehouseCode ?? whData?.warehouse?.code,
                  warehouseName:
                    whData?.warehouseName ?? whData?.warehouse?.name,
                  advancedTrackingType: item?.advancedTracking,
                  advancedTrackingData: whData?.advancedTrackingData?.map(
                    (adData: any) => {
                      return {
                        ...adData,
                        costPerUnit: item?.costPerUnit ?? 0,
                        qtyToFulfilUom: adData?.qtyToFulfil
                      };
                    }
                  )
                };
              }
            ),
            workOrderCode: props.workOrderItems?.workOrderCode,
            workOrderId: props.workOrderItems?.id
          };
        });

      onlyByProductRows = scrapByProductLineItems
        ?.filter((item: any) => {
          return (
            !('id' in item) &&
            item.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
          );
        })
        ?.map((item: any) => {
          const qty =
            item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
              return Number(acc) + Number(obj?.quantity ?? 0);
            }, 0) ?? 0;
          return {
            costPerUnit: item?.costPerUnit ?? 0,
            uomCostPerUnit: item?.costPerUnit ?? 0,
            // createdDate: DateFormatService.getDateStrFromDate(new Date()),
            documentDate: DateFormatService.getDateStrFromDate(
              item.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCode: item.productCode,
            quantity: qty,
            uomQuantity: qty,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            type: WIP_CONSUMPTION_TYPES.CO_PRODUCT,
            warehouseInventoryData: item.warehouseInventoryData?.map(
              (whData: any) => {
                const { warehouse, row, rack, bin, ...newWHData } = whData;
                return {
                  ...newWHData,
                  uomQuantity: newWHData?.quantity,
                  qtyToFulfilUom: newWHData?.qtyToFulfil,
                  warehouseCode:
                    whData?.warehouseCode ?? whData?.warehouse?.code,
                  warehouseName:
                    whData?.warehouseName ?? whData?.warehouse?.name,
                  advancedTrackingType: item?.advancedTracking,
                  advancedTrackingData: whData?.advancedTrackingData?.map(
                    (adData: any) => {
                      return {
                        ...adData,
                        costPerUnit: item?.costPerUnit ?? 0,
                        qtyToFulfilUom: adData?.qtyToFulfil
                      };
                    }
                  )
                };
              }
            ),
            workOrderCode: props.workOrderItems?.workOrderCode,
            workOrderId: props.workOrderItems?.id
          };
        });

      let bothRows = onlyScrapRows.concat(onlyByProductRows);
      produceLineItems = produceLineItems.concat(bothRows);
    } catch (err) {
      /**
       * In case production entry not completed/Ivalid data prepared
       */
      return;
    }

    if (Utility.isEmpty(produceLineItems)) {
      showAlert(
        'Please note!',
        'Your information is already up to date and no further action is required...'
      );
      return;
    }

    let validate = isValidated(produceLineItems);

    if (!validate) {
      return;
    }

    setSaveApiLoading(true);
    CommonQualityCheckService.getTemplateUsageByProduct([
      props.selectedProduct?.productCode
    ])
      .then(async (res: any) => {
        if (res?.[0]?.used && Utility.isMRP()) {
          const isConfirmed = await qcConfirm(
            `Would you like to perform a quanlity check on the produced goods?`,
            `QC Flow`
          );

          if (isConfirmed === null) {
            setSaveApiLoading(false);
            return;
          }

          if (isConfirmed) {
            //updated payload
            for (let i = 0; i < produceLineItems.length; i++) {
              if (produceLineItems[i].type === 'PRODUCTION') {
                produceLineItems[i].isQcEnabled = true;
                produceLineItems[i].qcStatus = 'PENDING';
              }
            }
            saveProduction(produceLineItems);
            return;
          }
          saveProduction(produceLineItems);
        } else {
          saveProduction(produceLineItems);
        }
      })
      .catch((err: any) => {});
  };

  const saveProduction = (produceLineItems: any) => {
    WorkOrderService.saveConsumption(produceLineItems)
      .then((res: any) => {
        setSaveApiLoading(false);
        props.onSuccess();
      })
      .catch((err: any) => {
        if (
          err?.message ===
          'validation failed: production record require respective consumption records'
        ) {
          showAlert(
            'Error!!! Please Note',
            'Production record requires respective consumption records'
          );
        }
        setSaveApiLoading(false);
      });
  };

  //////////////////////////// Save End ///////////////////////////////////

  //////////////////////////// Component Methods Start ///////////////////////////////////

  const fetchProductionHistoryDetails = (config: any) => {
    setUpdating(true);
    WorkOrderService.apiConfigForConsumption = config;
    WorkOrderService.fetchConsumptionHistory()
      .then((res: any) => {
        setUpdating(false);
        let newRes = res?.content?.map((item: any) => {
          return {
            ...item,
            quantity: Utility.isNotEmpty(item?.documentUOMSchemaDefinition)
              ? item?.uomQuantity
              : item?.quantity,
            uom: props.selectedProduct.uom,
            stockUom: props.selectedProduct.stockUom,
            documentUOMSchemaDefinition:
              props?.selectedProduct?.documentUOMSchemaDefinition,
            productName: props.selectedProduct.productName,
            productCodeToDisplayOnly:
              props?.selectedProduct?.productCodeToDisplayOnly,
            advancedTracking: props.selectedProduct.advancedTracking,
            editable: false,
            expanded: false,
            documentDate: DateFormatService.getDateFromStr(
              item?.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            createdDate: new Date(item?.dateStamp),
            qcStatus: Utility.convertInTitleCase(item?.qcStatus?.toLowerCase()),
            warehouseInventoryData:
              item?.warehouseInventoryData?.map((itemWHData: any) => {
                return {
                  ...itemWHData,
                  quantity: itemWHData?.uomQuantity ?? itemWHData?.quantity
                };
              }) ?? [],
            requiredQty: props?.selectedProduct?.requiredQty
          };
        });
        setProductionLineItems(newRes ?? []);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  // const fetchScrapByProductHistoryDetails = (productCodeArr: any) => {
  //   let codes = productCodeArr?.join(',');

  //   if (Utility.isEmpty(codes)) {
  //     return;
  //   }

  //   let finalEndpoint = ApiConstants.URL.MRP.WORK_ORDER.WO_CONSUMPTION_HISTORY;
  //   const queryParams = {
  //     SearchTerm: '',
  //     Limit: 25,
  //     Page: 0,
  //     Sort: 'createdOn',
  //     SortDir: 'DESC',
  //     Query: `workOrderCode=${props.workOrderItems?.workOrderCode}`,
  //     ProductCodes: codes
  //   };
  //   let query = '';
  //   query += `?limit=${queryParams.Limit}&page=${queryParams.Page}&productCodes=${queryParams.ProductCodes}&sort=${queryParams.Sort}&sortDir=${queryParams.SortDir}`;
  //   if (queryParams.Query) query += `&query=${queryParams.Query}`;
  //   finalEndpoint = finalEndpoint + query;

  //   setUpdating(true);
  //   http
  //     .get(finalEndpoint)
  //     .then((response: any) => {
  //       setUpdating(false);
  //       let newRes = response?.content?.map((item: any) => {
  //         if (item.type === 'SCRAP') {
  //           return {
  //             ...item,
  //             uom: getProductDetailsForScrapGrid(
  //               props.workOrderItems?.workOrderItems,
  //               item.productCode,
  //               'uom'
  //             ),
  //             stockUom: getProductDetailsForScrapGrid(
  //               props.workOrderItems?.workOrderItems,
  //               item.productCode,
  //               'stockUom'
  //             ),
  //             productName: getProductDetailsForScrapGrid(
  //               props.workOrderItems?.workOrderItems,
  //               item.productCode,
  //               'productName'
  //             ),
  //             advancedTracking: getProductDetailsForScrapGrid(
  //               props.workOrderItems?.workOrderItems,
  //               item.productCode,
  //               'advancedTracking'
  //             ),
  //             editable: false
  //           };
  //         } else {
  //           return {
  //             ...item
  //           };
  //         }
  //       });
  //       setScrapLineItems(newRes ?? []);
  //     })
  //     .catch((err: any) => {
  //       setUpdating(false);
  //       console.error('Error fetching work order production history: ', err);
  //     });
  // };

  const getHistoryApiCallParams = (type: any) => {
    return {
      SearchTerm: '',
      Limit: 25,
      Page: 0,
      Sort: 'createdOn',
      SortDir: 'DESC',
      Query: `workOrderCode=${props.workOrderItems?.workOrderCode},productCode=${props.selectedProduct?.productCode},type=${type}`
    };
  };

  const updateConsumptionConfig = () => {
    let config = [...productionColumnConfigs];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouse':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.warehouse?.name ?? '';
          };
          conf.dropdownConfig.data = warehouseData?.content?.filter(
            (wareHouse: any) =>
              wareHouse?.active && wareHouse.warehouseType !== 'REJECTED'
          );
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const config: WarehouseAPIConfig = {
              ...WarehouseService.apiConfig,
              SearchTerm: search,
              Limit: 20,
              Page: 0
            };
            WarehouseService.apiConfig = config;
            return WarehouseService.getWarehouseEndPoint();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return (
              response?.content?.filter(
                (wareHouse: any) => wareHouse?.active && !wareHouse.rejected
              ) ?? []
            );
          };
          return;

        case 'quantity':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(obj.rowData.quantity);
          };
          break;

        case 'documentDate':
          conf.name = 'Production Date';
          break;

        default:
          break;
      }
    });

    setProductionColumnConfigs([...config]);
  };

  const updateScrapColumnConfig = () => {
    let config = [...scrapColumnConfigs];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'productName':
          let scrapRows = props.workOrderItems?.workOrderItems;

          scrapRows = scrapRows?.filter((row: any) => {
            return row.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE;
          });

          conf.formatter = (obj: any) => {
            return obj?.rowData?.productName ?? '';
          };
          conf.dropdownConfig.data = scrapRows ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.itemName?.name ?? '';
          };
          return;

        case 'quantity':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(obj.rowData.quantity);
          };
          conf.name = 'Production';
          break;

        case 'costPerUnit':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(obj.rowData.costPerUnit);
          };
          break;

        case 'documentDate':
          conf.name = 'Production Date';
          break;
        default:
          break;
      }
    });

    setScrapColumnConfigs([...config]);
  };

  const updateAutoConsume = () => {
    ProcessManufacturingService.autoConsumption(
      props.workOrderItems.workOrderCode
    )
      .then((res: any) => {
        setIsAutoConsumeResponseFetched(true);
      })
      .catch((err: any) => {
        setIsAutoConsumeResponseFetched(true);
      });
  };

  //////////////////////////// Component Methods End ///////////////////////////////////

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-tr rounded-tl">
        <div className="row width-auto">
          <DKLabel text={`WIP Production`} className="fw-m fs-l" />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !saveApiLoading && props.onCancel();
            }}
          />

          <div
            className={`row border-radius-m mr-r ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                if (
                  props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
                  !saveApiLoading
                ) {
                  onSave();
                }
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getQtyDetailsView = () => {
    const SummarySection = (props: any) => {
      return (
        <div
          className="column parent-width border-radius-m shadow-s border-ms p-l bg-white border-box mobile-full-grid-width mobile-mb"
          style={{
            backgroundColor: '#edf3fb',
            border: '1.5px solid #91b5e9'
          }}
        >
          <div className="row justify-content-between">
            <DKLabel text={props.title} className="fw-b fs-l" />
          </div>
          <div className="row justify-content-between parent-width" style={{}}>
            {!Utility.isEmpty(props.data1) && (
              <div className="column">
                <DKLabel
                  text={props.data1.title}
                  className="fs-m fw-m text-gray"
                />
                <div className="row">
                  <DKLabel
                    text={props.data1.subTitle}
                    className={'fs-l mt-m fw-b'}
                  />
                  <DKLabel
                    text={props.data1.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
                {/* <DKLabel
                text={props.footer}
                className="mt-r "
                style={{ color: 'rgb(26, 173, 93)' }}
              /> */}
              </div>
            )}
            {!Utility.isEmpty(props.data1) && (
              <div
                className="bg-blue opacity-4"
                style={{ width: 1, height: 70, marginRight: '2%' }}
              />
            )}
            {!Utility.isEmpty(props.data2) && (
              <div
                className={`${
                  !Utility.isEmpty(props.data1)
                    ? 'column align-items-center'
                    : ''
                }`}
              >
                <DKLabel
                  text={props.data2.title}
                  className="text-green fs-m fw-m"
                />
                <div className="row">
                  <DKLabel
                    text={props.data2.subTitle}
                    className="fs-l mt-m fw-b"
                  />
                  <DKLabel
                    text={props.data2.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
              </div>
            )}
            {!Utility.isEmpty(props.data2) && (
              <div
                className="bg-blue opacity-4"
                style={{ width: 1, height: 70 }}
              />
            )}
            {!Utility.isEmpty(props.data3) && (
              <div className="column align-items-end">
                <DKLabel
                  text={props.data3.title}
                  className={`${
                    props.data3.color ? props.data3.color : 'text-orange'
                  } fs-m fw-m`}
                />
                <div className="row">
                  <DKLabel
                    text={props.data3.subTitle}
                    className="fs-l mt-m fw-b"
                  />
                  <DKLabel
                    text={props.data3.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };
    return (
      <div className="mb-m" style={{ width: '50%' }}>
        <SummarySection
          isRightGapRequired={false}
          title=""
          data1={null}
          data2={{
            title: 'Produced Qty',
            subTitle: `${NumberFormatService.getNumber(
              ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                productionLineItems
              )
            )}`,
            unit: props.selectedProduct?.uom ?? ''
          }}
          data3={{
            title: `${'Scrap/ByProduct'} Qty`,
            subTitle: NumberFormatService.getNumber(
              ConsumeProduceHelper.getWastageQtyCount(scrapLineItems)
            ),
            unit: props.selectedProduct?.uom ?? ''
          }}
          footer={''}
        />
      </div>
    );
  };

  //////////////////////////// Production Grid Start ///////////////////////////////////

  const getRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    btns.push({
      icon: getFulfillmentAssigned(lineItem)
        ? ic_barcode_green
        : ic_barcode_red,
      className: 'p-0 underline text-blue',
      onClick: (data: any, index: any) => {
        onFulfillmentClick(data, 'CONSUME_PRODUCE');
      }
    });

    if (lineItem.editable) {
      btns.push({
        icon: DKIcons.ic_delete,
        className: 'p-0 underline text-blue',
        onClick: (data: any) => {
          const updatedState = [...productionLineItems];
          updatedState.splice(data?.rowIndex, 1);
          if (ConsumeProduceHelper.containsNewHistoryObject(updatedState)) {
            setScrapLineItems([]);
          }
          setProductionLineItems([...updatedState]);
        }
      });
    }

    return btns;
  };

  const onProductionRowUpdate = (data: any) => {
    if (!data.rowData?.editable) {
      return;
    }

    let lineItems = [...productionLineItems];
    let updatedScrapItems = [...scrapLineItems];

    // for production only
    if (data.columnKey === 'quantity') {
      let qty = data.rowData['quantity'];
      lineItems[data.rowIndex]['quantity'] =
        Utility.roundOffToTenantDecimalScale(qty);

      updatedScrapItems = updatedScrapItems?.map((scItem: any) => {
        scItem = {
          ...scItem,
          quantity: Utility.roundOffToTenantDecimalScale(
            scItem.componentProductUnitQty * qty
          )
        };
        return scItem;
      });
    }

    if (data.columnKey === 'documentDate') {
      lineItems[data.rowIndex]['documentDate'] = new Date(
        data.rowData['documentDate']
      );
    }

    setScrapLineItems([...updatedScrapItems]);
    setProductionLineItems([...lineItems]);
  };

  const getExpandableView = (expandableLineItem: any) => {
    if (Utility.isEmpty(expandableLineItem)) {
      return (
        <div className="row parent-size justify-content-center p-m">
          No scrap/by-product history available
        </div>
      );
    } else {
      return (
        <div className="p-m">
          <DKDataGrid
            title={`Scrap/By-Product History`}
            needShadow={false}
            needColumnIcons={false}
            needBorder={true}
            needTrailingColumn={false}
            allowBulkOperation={false}
            allowColumnSort={false}
            filterData={[]}
            allowColumnDelete={false}
            allowRowEdit={true}
            allowColumnEdit={false}
            allowFilter={false}
            allowColumnAdd={false}
            allowBottomRowAdd={false}
            allowSearch={false}
            allowShare={false}
            width={1300}
            rows={[...deepClone(expandableLineItem)]?.map((lineItem: any) => {
              return {
                ...lineItem,
                productName: ConsumeProduceHelper.getProductDetailsForScrapGrid(
                  props?.workOrderItems?.workOrderItems,
                  lineItem?.productCode,
                  'productName',
                  true
                ),
                productCodeToDisplayOnly:
                  ConsumeProduceHelper.getProductDetailsForScrapGrid(
                    props?.workOrderItems?.workOrderItems,
                    lineItem?.productCode,
                    'productCodeToDisplayOnly',
                    true
                  ),
                advancedTracking:
                  ConsumeProduceHelper.getProductDetailsForScrapGrid(
                    props?.workOrderItems?.workOrderItems,
                    lineItem?.productCode,
                    'advancedTracking',
                    true
                  ),
                uom: ConsumeProduceHelper.getProductDetailsForScrapGrid(
                  props?.workOrderItems?.workOrderItems,
                  lineItem?.productCode,
                  'uom',
                  true
                ),
                stockUom: ConsumeProduceHelper.getProductDetailsForScrapGrid(
                  props?.workOrderItems?.workOrderItems,
                  lineItem?.productCode,
                  'stockUom',
                  true
                ),
                documentDate: DateFormatService.getDateFromStr(
                  lineItem?.documentDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                createdDate: new Date(lineItem?.dateStamp),
                allowRowEdit: false,
                rowButtons: getScrapRowButtons({
                  ...lineItem,
                  advancedTracking:
                    ConsumeProduceHelper.getProductDetailsForScrapGrid(
                      props?.workOrderItems?.workOrderItems,
                      lineItem?.productCode,
                      'advancedTracking',
                      true
                    )
                })
              };
            })}
            columns={[
              ...scrapColumnConfigs,
              {
                id: 'action',
                key: 'action',
                name: 'Actions',
                type: INPUT_TYPE.BUTTON,
                width: 350,
                options: []
              }
            ]}
            onRowUpdate={(data: any) => {
              // onProductionRowUpdate(data);
            }}
            onRowClick={(data: any) => {
              // setSelectedItem(data?.rowData);
            }}
          />
        </div>
      );
    }
  };

  const getProductionGrid = () => {
    return !Utility.isEmpty(productionLineItems) ? (
      <DKDataGrid
        title={`Production History`}
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...deepClone(productionLineItems)]?.map((lineItem: any) => {
          return {
            ...lineItem,
            rowButtons: getRowButtons(lineItem),
            allowRowEdit: lineItem?.editable,
            expandableView: getExpandableView(
              lineItem?.childScrapCoproductItems ?? []
            ),
            invalidFields: lineItem.quantity ? [] : ['quantity']
          };
        })}
        columns={[
          ...productionColumnConfigs,
          {
            id: 'action',
            key: 'action',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            width: 160,
            options: []
          }
        ]}
        onRowUpdate={(data: any) => {
          onProductionRowUpdate(data);
        }}
        onRowClick={(data: any) => {
          setSelectedItem(data?.rowData);
        }}
        allowExpand={true}
        onRowExpand={(data: any) => {
          let index = data.rowIndex;
          let copy = [...productionLineItems];
          copy[index].expanded = !copy[index].expanded;
          setProductionLineItems(copy);
        }}
      />
    ) : (
      <div className="column mt-4 parent-width align-items-center justify-content-center bg-gray1 border border-radius-m p-4 gap-4">
        <div className="column parent-width align-items-center">
          <DKLabel text={`No production added yet.`} className=" mt-s" />
          <DKLabel
            text={`Please add a production to continue.`}
            className="text-gray mt-s"
          />
        </div>
        <DKButton
          title={`Add New Production`}
          className={`text-white fw-m ${
            props.selectedProduct.allocatedQty !==
              ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                productionLineItems
              ) &&
            props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
            !updating
              ? 'bg-app'
              : 'bg-gray'
          }`}
          onClick={() => {
            if (
              props.selectedProduct.allocatedQty !==
                ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                  productionLineItems
                ) &&
              props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
              !updating
            ) {
              checkForWIPProductQTY();
            }
          }}
          disabled={!isAutoConsumeResponseFetched}
        />
      </div>
    );
  };

  const checkForWIPProductQTY = () => {
    showLoader();
    WorkOrderService.fetchProducibleQty(props.workOrderItems.workOrderCode)
      .then((result) => {
        removeLoader();
        addNewProduction(result);
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const addNewProduction = (qty = 1) => {
    if (
      props.selectedProduct.allocatedQty !==
      ConsumeProduceHelper.getConsumedOrProducedQtyCount(productionLineItems)
    ) {
      let updatedProductionItems = [...productionLineItems];

      updatedProductionItems = [
        ...updatedProductionItems,
        {
          productName: props?.selectedProduct?.productName ?? '',
          productCode: props?.selectedProduct?.productCode,
          productCodeToDisplayOnly:
            props?.selectedProduct?.productCodeToDisplayOnly,
          documentDate: new Date(),
          warehouse: null,
          quantity: qty,
          uom: props.selectedProduct.uom,
          stockUom: props.selectedProduct.stockUom,
          documentUOMSchemaDefinition:
            props.selectedProduct?.documentUOMSchemaDefinition,
          createdDate: new Date(),
          advancedTracking: props.selectedProduct.advancedTracking,
          editable: true,
          inventoryPrice: ConsumeProduceHelper.getProductDetailsForScrapGrid(
            props?.workOrderItems?.workOrderItems,
            props?.selectedProduct?.productCode,
            'inventoryPrice',
            false
          ),
          expanded: false
        }
      ];

      let scrapRows = props.workOrderItems?.workOrderItems;

      scrapRows = scrapRows?.filter((row: any) => {
        return row.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE;
      });

      let newScrapRows: any = [];
      scrapRows?.forEach((scRow: any) => {
        newScrapRows.push({
          productName: scRow?.itemName?.name,
          productCode: scRow?.itemName?.productId,
          productCodeToDisplayOnly: scRow?.itemName?.documentSequenceCode,
          documentDate: new Date(),
          warehouse: null,
          quantity: scRow?.componentProductUnitQty,
          componentProductUnitQty: scRow?.componentProductUnitQty,
          uom:
            Utility.getUOMForStockUOMId(scRow?.itemName?.stockUom)?.name ?? '',
          stockUom: scRow?.itemName?.stockUom,
          documentUOMSchemaDefinition:
            scRow?.itemName?.documentUOMSchemaDefinition,
          createdDate: new Date(),
          editable: true,
          costPerUnit: 0,
          advancedTracking: scRow?.itemName?.advancedTracking,
          produceProductType: scRow?.produceProductType
        });
      });

      setProductionLineItems(updatedProductionItems);
      setScrapLineItems(newScrapRows);
    }
  };

  const getAddNewProductionBtn = () => {
    return ConsumeProduceHelper.containsNewHistoryObject(
      productionLineItems
    ) ? (
      <DKButton
        title={`+ Add Production`}
        onClick={() => {
          if (!diableBtn) {
            addNewProduction();
          }
        }}
        className={`fw-m position-relative ${
          !diableBtn ? 'text-blue' : 'text-gray'
        }`}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          marginTop: -10
        }}
      />
    ) : null;
  };

  //////////////////////////// Production Grid End ///////////////////////////////////

  //////////////////////////// Scrap/ByProduct Grid Start ///////////////////////////////////

  const getScrapRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    btns.push({
      icon: getFulfillmentAssigned(lineItem)
        ? ic_barcode_green
        : ic_barcode_red,
      className: 'p-0 underline text-blue',
      onClick: (data: any, index: any) => {
        onFulfillmentClick(data, 'SCRAP');
      }
    });

    if (lineItem.editable) {
      btns.push({
        icon: DKIcons.ic_delete,
        className: 'p-0 underline text-blue',
        onClick: (data: any) => {
          const updatedState = [...scrapLineItems];
          updatedState.splice(data?.rowIndex, 1);
          setScrapLineItems([...updatedState]);
        }
      });
    }

    return btns;
  };

  const onScrapRowUpdate = (data: any) => {
    let lineItems = [...scrapLineItems];

    if (data.columnKey === 'productName') {
      lineItems[data.rowIndex]['uom'] = Utility.getUOMForStockUOMId(
        data.rowData?.productName?.stockUom
      )?.name;
      lineItems[data.rowIndex]['stockUom'] =
        data.rowData?.productName?.stockUom;
      lineItems[data.rowIndex]['productCode'] =
        data.rowData?.productName?.itemName?.productId;
      lineItems[data.rowIndex]['productName'] =
        data.rowData?.productName?.itemName?.name;
      lineItems[data.rowIndex]['advancedTracking'] =
        data.rowData?.productName?.itemName?.advancedTracking;
      lineItems[data.rowIndex]['produceProductType'] =
        data.rowData?.productName?.produceProductType;
    }

    if (data.columnKey === 'quantity') {
      lineItems[data.rowIndex]['quantity'] =
        Utility.roundOffToTenantDecimalScale(data.rowData['quantity']);
    }

    if (data.columnKey === 'documentDate') {
      lineItems[data.rowIndex]['documentDate'] = new Date(
        data.rowData['documentDate']
      );
    }

    if (data.columnKey === 'costPerUnit') {
      lineItems[data.rowIndex]['costPerUnit'] = data.rowData['costPerUnit'];
    }

    setScrapLineItems([...lineItems]);
  };

  const getScrapByProductGrid = () => {
    return !Utility.isEmpty(scrapLineItems) ? (
      <div className="column parent-width">
        <div className="row justify-content-between">
          <DKLabel text="Scrap/ByProduct History" className="fw-b fs-m" />
          <DKCheckMark
            title="Include cost in FG"
            className="z-index-6"
            isSelected={includeByProductCost}
            onClick={() => {
              setIncludeByProductCost(!includeByProductCost);
            }}
            style={{ width: 150 }}
          />
        </div>
        <DKDataGrid
          title={``}
          needShadow={false}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={false}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          rows={[...deepClone(scrapLineItems)]?.map((lineItem: any) => {
            return {
              ...lineItem,
              allowRowEdit: lineItem?.editable,
              rowButtons: getScrapRowButtons(lineItem)
            };
          })}
          columns={[
            ...scrapColumnConfigs,
            {
              id: 'action',
              key: 'action',
              name: 'Actions',
              type: INPUT_TYPE.BUTTON,
              width: 350,
              options: []
            }
          ]}
          onRowUpdate={(data: any) => {
            onScrapRowUpdate(data);
          }}
          onRowClick={() => {}}
        />
      </div>
    ) : (
      <div className="column mt-4 parent-width align-items-center justify-content-center bg-gray1 border border-radius-m p-4 gap-4">
        <div className="column parent-width align-items-center">
          <DKLabel text={'No scrap/by-products added yet.'} className=" mt-s" />
          <DKLabel
            text={
              'Please add a new production to add scrap/byproducts continue.'
            }
            className="text-gray mt-s"
          />
        </div>
        {!ConsumeProduceHelper.containsNewHistoryObject(productionLineItems) &&
          props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED && (
            <DKButton
              title="Add new scrap"
              className="text-white fw-m bg-app"
              onClick={() => {
                addScrapBtn();
              }}
            />
          )}
      </div>
    );
  };

  const getAddNewScrapBtn = () => {
    return !ConsumeProduceHelper.containsNewHistoryObject(
      productionLineItems
    ) ? (
      <DKButton
        title={`+ Add Scrap/ByProduct`}
        onClick={() => {
          if (!diableBtn) {
            addScrapBtn();
          }
        }}
        className={`fw-m position-relative ${
          !diableBtn ? 'text-blue' : 'text-gray'
        }`}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          marginTop: -10
        }}
      />
    ) : null;
  };

  const addScrapBtn = () => {
    setScrapLineItems([
      ...scrapLineItems,
      {
        productName: null,
        productCode: '',
        documentDate: new Date(),
        warehouse: null,
        quantity: 0,
        uom: '',
        createdDate: new Date(),
        editable: true,
        costPerUnit: 0
      }
    ]);
  };

  //////////////////////////// Scrap/ByProduct Grid End ///////////////////////////////////

  //////////////////////////// Fulfillment Grid Start ///////////////////////////////////

  const isBatchItemAssigned = (item: any) => {
    const totalItem =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return (
      Number(item.quantity) !== 0 && Number(totalItem) === Number(item.quantity)
    );
  };

  const isSerialItemAssigned = (item: any) => {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: any, b: any) => (a += parseFloat(b['quantity'])),
          0
        )) ||
      0;
    return (
      Number(item.quantity) !== 0 &&
      Number(totalQuantity) === Number(item?.quantity)
    );
  };

  const isNoneTrackedItemAssigned = (item: any) => {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return (
      Number(item.quantity) !== 0 &&
      Number(item.quantity) === Number(totalQuantity)
    );
  };

  const getFulfillmentAssigned = (lineItem: any) => {
    if (lineItem.advancedTracking === ADVANCE_TRACKING.BATCH) {
      return isBatchItemAssigned(lineItem);
    }

    if (lineItem.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      return isSerialItemAssigned(lineItem);
    }

    if (lineItem.advancedTracking === ADVANCE_TRACKING.NONE) {
      return isNoneTrackedItemAssigned(lineItem);
    }
  };

  const onFulfillmentClick = (data: any, flowType: any) => {
    setIsWastageFulfillmentFlow(flowType);
    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.BATCH) {
      setBatchTracking(data?.rowData, data?.rowIndex);
    }

    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      setSerialTracking(data?.rowData, data?.rowIndex);
    }

    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.NONE) {
      setNoneTracking(data?.rowData, data?.rowIndex);
    }
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const setSerialTracking = (item: any, index: any) => {
    setSelectedItem(item);
    loadSerialTrackingProducts(item.productCode);
    setSelectedIndex(index);
    setShowSerialTracking(true);
  };

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const setNoneTracking = (item: any, index: any) => {
    loadProductInventoryById([item?.productCode]);
    setSelectedItem(item);
    setSelectedIndex(index);
    setShowMultipleWarehouse(true);
  };

  const setBatchTracking = (item: any, index: any) => {
    setSelectedItem(item);
    loadBatchTrackingData(item?.productCode);
    setSelectedIndex(index);
  };

  const loadBatchTrackingData = async (code: any) => {
    showLoader();
    try {
      let isBatchSerialsCustomFieldsAvailable =
        batchSerialCFfromStore?.content?.filter(
          (ele: any) => ele.status === STATUS_TYPE.ACTIVE
        );
      if (Utility.isEmpty(isBatchSerialsCustomFieldsAvailable)) {
        dispatch(fetchAdvancedTrackingData(code));
      } else {
        dispatch(fetchAdvancedTrackingData(code));
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false,
          checkBatchSerialCF: true
        });
      }
      removeLoader();
      setShowBatchTracking(true);
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
      removeLoader();
    }
  };

  const getFlatArrayOfSerialSelections = (obj: any, type: ADVANCE_TRACKING) => {
    let mapped: any[];
    if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
      mapped = obj?.map((item: any) => {
        return item?.advancedTrackingData?.map((lineItem: any) => {
          return {
            ...lineItem,
            warehouse: {
              ...item,
              name: item.warehouseName,
              code: item.warehouseCode
            }, // For serial tracked edit mode
            warehouseName: item.warehouseName,
            warehouseCode: item.warehouseCode,
            batchSize: lineItem.qtyToFulfil
          };
        });
      });
    } else {
      mapped = obj?.map((item: any) => {
        return item?.advancedTrackingData;
      });
    }

    let flatArray = mapped?.flat() || [];
    return flatArray;
  };

  const createProductItemForTracking = (type: ADVANCE_TRACKING) => {
    return {
      product: {
        name: selectedItem?.productName,
        stockUom: selectedItem?.stockUom,
        availableQuantity: 1,
        documentSequenceCode: selectedItem?.productCodeToDisplayOnly
      },
      productCode: selectedItem?.productCode,
      requiredQuantity: selectedItem.quantity,
      quantityRequired: selectedItem.quantity,
      productQuantity: selectedItem.quantity,
      advancedTracking: selectedItem.advancedTracking,
      advancedTrackingFulfilmentData: getFlatArrayOfSerialSelections(
        selectedItem?.warehouseInventoryData ?? [],
        type
      ),
      advancedTrackingMetaData: getFlatArrayOfSerialSelections(
        selectedItem?.warehouseInventoryData ?? [],
        type
      ),
      isQuickCommit: false,
      documentUOMSchemaDefinition: selectedItem?.documentUOMSchemaDefinition,
      parentQuantityToFulfill: selectedItem.requiredQty,
      pendingQuantity: selectedItem.requiredQty
    };
  };

  /* Commenting unused
  const getDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehouseData &&
      warehouseData?.content &&
      warehouseData?.content.filter(
        (item: any) => item.active && !item.rejected
      );

    let selectedWarehouse = activeWarehouses?.find(
      (warehouse: any) => warehouse.primary === true
    );
    return selectedWarehouse?.code;
  }; */

  const parseWarehouseInventoryData = (
    warehouseInventoryData: any,
    trackingType: ADVANCE_TRACKING
  ) => {
    if (trackingType === ADVANCE_TRACKING.NONE) {
      return warehouseInventoryData;
    }

    let result: any = [];
    if (trackingType === ADVANCE_TRACKING.SERIAL) {
      warehouseInventoryData?.forEach((warehouseInventoryObject: any) => {
        warehouseInventoryObject?.advancedTrackingData?.forEach(
          (advanceTrackingObj: any) => {
            let object = {
              ...warehouseInventoryObject,
              quantity: advanceTrackingObj.qtyToFulfil,
              advancedTrackingData: [advanceTrackingObj],
              rackCode: advanceTrackingObj?.rack?.code ?? null,
              rowCode: advanceTrackingObj?.row?.code ?? null,
              binCode: advanceTrackingObj?.bin?.code ?? null
            };
            result.push(object);
          }
        );
      });
    } else if (trackingType === ADVANCE_TRACKING.BATCH) {
      warehouseInventoryData?.forEach((warehouseInventoryObject: any) => {
        warehouseInventoryObject?.advancedTrackingData?.forEach(
          (advanceTrackingObj: any) => {
            let object = {
              ...warehouseInventoryObject,
              advancedTrackingData: [advanceTrackingObj],
              rackCode: advanceTrackingObj?.rack?.code ?? null,
              rowCode: advanceTrackingObj?.row?.code ?? null,
              binCode: advanceTrackingObj?.bin?.code ?? null
            };
            result.push(object);
          }
        );
      });
    }

    return result;
  };

  const getFulfillmentPopUp = () => {
    const setWarehouseInventoryData = (warehouseInventoryData: any) => {
      if (isWastageFulfillmentFlow === 'CONSUME_PRODUCE') {
        let lineItems = [...productionLineItems];
        lineItems[selectedIndex]['warehouseInventoryData'] =
          warehouseInventoryData;
        setProductionLineItems([...lineItems]);
      }

      if (isWastageFulfillmentFlow === 'SCRAP') {
        let lineItems = [...scrapLineItems];
        lineItems[selectedIndex]['warehouseInventoryData'] =
          warehouseInventoryData;
        setScrapLineItems([...lineItems]);
      }
    };

    // let filteredData = null;
    // if (!props.selectedProduct?.isSubstitute) {
    //   filteredData = props.workOrderItems?.workOrderItems?.find((item: any) => {
    //     return item.itemName.productId === props.selectedProduct?.productCode;
    //   });
    // } else {
    //   // console.log(
    //   //   'props.selectedProduct',
    //   //   props.selectedProduct?.bomProductSubstitutesDetails
    //   // );
    //   // filteredData = props.selectedProduct?.bomProductSubstitutesDetails;
    // }

    return (
      <>
        {showSerialTracking && (
          <SerialTrackedReceive
            isMrp={true}
            itemDetails={createProductItemForTracking(ADVANCE_TRACKING.SERIAL)}
            module={MODULE_TYPE.BUY}
            filterBatchData={false}
            selectedItem={selectedItem}
            filteredWarehouseInventoryData={null}
            disableBtn={'id' in selectedItem}
            targetWarehouse={
              Utility.isRRBTaggingEnabled() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.workOrderItems?.targetWarehouse?.code
                : ''
            }
            taggedWarehouse={
              Utility.isRRBTaggingEnabled()
                ? props.workOrderItems?.targetWarehouse
                : ''
            }
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfill: any
            ) => {
              const codesSet: any = new Set(
                data.map((item: any) => item.warehouseCode)
              );
              const uniquesWarehouseCodes = [...codesSet];

              const warehouseInventoryData = uniquesWarehouseCodes?.map(
                (warehouseCode: any) => {
                  let advancedTrackingData = data?.filter(
                    (object: any) => object.warehouseCode === warehouseCode
                  );
                  advancedTrackingData = advancedTrackingData?.map(
                    (atd: any) => {
                      return {
                        ...atd,
                        qtyToFulfil: atd.batchSize
                      };
                    }
                  );

                  let totalQuantity = advancedTrackingData.reduce(
                    (prev: number, current: any) => {
                      return Number(prev) + Number(current.batchSize);
                    },
                    0
                  );
                  return {
                    advancedTrackingData: advancedTrackingData,
                    quantity: totalQuantity,
                    warehouseCode: warehouseCode,
                    warehouseName: advancedTrackingData?.[0].warehouseName
                  };
                }
              );

              const updatedWarehouseInventoryData = parseWarehouseInventoryData(
                warehouseInventoryData,
                ADVANCE_TRACKING.SERIAL
              );

              setWarehouseInventoryData(updatedWarehouseInventoryData);
              setShowSerialTracking(false);
            }}
            onClose={() => {
              setShowSerialTracking(false);
            }}
          ></SerialTrackedReceive>
        )}
        {showBatchTracking && (
          <BatchTrackingReceive
            isMrpFlow={true}
            itemDetails={createProductItemForTracking(ADVANCE_TRACKING.BATCH)}
            module={MODULE_TYPE.BUY}
            filterBatchData={false}
            targetWarehouse={props.workOrderItems?.targetWarehouse?.code}
            taggedWarehouse={
              Utility.isRRBTaggingEnabled()
                ? props.workOrderItems?.targetWarehouse
                : ''
            }
            filteredWarehouseInventoryData={null}
            disableBtn={'id' in selectedItem}
            onBatchSave={(data: any, quantityToFulfill: any) => {
              const codesSet: any = new Set(
                data.map((item: any) => item.warehouseCode)
              );
              const uniquesWarehouseCodes = [...codesSet];

              const warehouseInventoryData = uniquesWarehouseCodes?.map(
                (warehouseCode: any) => {
                  let advancedTrackingData = data?.filter(
                    (object: any) => object.warehouseCode === warehouseCode
                  );

                  let totalQuantity = 0;
                  advancedTrackingData = advancedTrackingData?.map(
                    (atd: any) => {
                      return {
                        ...atd,
                        qtyToFulfil: atd.batchSize
                      };
                    }
                  );
                  totalQuantity = advancedTrackingData.reduce(
                    (prev: number, current: any) => {
                      return Number(prev) + Number(current.batchSize);
                    },
                    0
                  );

                  return {
                    advancedTrackingData: advancedTrackingData,
                    quantity: totalQuantity,
                    warehouseCode: warehouseCode,
                    warehouseName: advancedTrackingData?.[0].warehouseName
                  };
                }
              );

              const updatedWarehouseInventoryData = parseWarehouseInventoryData(
                warehouseInventoryData,
                ADVANCE_TRACKING.BATCH
              );

              setWarehouseInventoryData(updatedWarehouseInventoryData);
              setShowBatchTracking(false);
            }}
            onClose={() => {
              setShowBatchTracking(false);
            }}
          ></BatchTrackingReceive>
        )}
        {showMultipleWarehouse && (
          <NoneTrackedReceive
            details={{
              ...createProductItemForTracking(ADVANCE_TRACKING.NONE),
              parentQuantityToFulfill: selectedItem.quantity,
              pendingQuantity: selectedItem.quantity,
              quantityRequired: selectedItem.quantity,
              quantityFulfilled: selectedItem.quantity,
              warehouseInventoryData: selectedItem?.warehouseInventoryData ?? []
            }}
            isMrpFlow={true}
            filterNormalData={false}
            filteredWarehouseInventoryData={null}
            selectedItem={selectedItem}
            module={MODULE_TYPE.BUY}
            targetWarehouse={
              Utility.isRRBTaggingEnabled() ||
              Utility.isWarehouseTaggingEnabled()
                ? props.workOrderItems?.targetWarehouse?.code
                : ''
            }
            taggedWarehouse={
              Utility.isRRBTaggingEnabled()
                ? props.workOrderItems?.targetWarehouse
                : ''
            }
            onSave={(data: any) => {
              if (data && data.length > 0) {
                let warehouseInventoryData = data?.map((obj: any) => {
                  return {
                    ...obj,
                    rowCode: obj?.row?.code,
                    rackCode: obj?.rack?.code,
                    binCode: obj?.bin?.code,
                    advancedTrackingData: [],
                    quantity: obj.quantity,
                    warehouseCode: obj.warehouseCode,
                    warehouseName: obj.warehouseName,
                    costPerUnit: selectedItem.inventoryPrice
                  };
                });
                setWarehouseInventoryData(warehouseInventoryData);
              }
              setShowMultipleWarehouse(false);
            }}
            onCancel={() => {
              setShowMultipleWarehouse(false);
            }}
          />
        )}
      </>
    );
  };

  //////////////////////////// Fulfillment Grid End ///////////////////////////////////

  return (
    <div className="transparent-background" style={{ zIndex: 9998 }}>
      <div
        className="popup-window"
        style={{
          maxWidth: '98%',
          width: 1300,
          maxHeight: '93%',
          padding: 0,
          overflow: 'visible'
        }}
      >
        {getHeader()}
        <div className="column parent-width p-h-r p-v-r overflow-scroll">
          {getQtyDetailsView()}
          {/* production grid */}
          {getProductionGrid()}
          {!Utility.isEmpty(productionLineItems) && getAddNewProductionBtn()}
          <DKLine
            style={{ height: 1, marginBottom: 12 }}
            className="opacity-80"
          />
          {/* scrap by/product grid (with production) */}
          {isScrapRowPresent && getScrapByProductGrid()}
          {!Utility.isEmpty(scrapLineItems) &&
            isScrapRowPresent &&
            getAddNewScrapBtn()}
        </div>
      </div>
      {getFulfillmentPopUp()}
    </div>
  );
};

export default ProductionPopup;
