import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DKButton,
  DKCheckMark,
  DKListPicker,
  DKIcon,
  DKIcons,
  DKSpinner,
  showToast,
  TOAST_TYPE,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  showLoader,
  removeLoader
} from 'deskera-ui-library';
import {
  APP_NAME,
  BOOKS_DATE_FORMAT,
  CONTACT_CODE_FUTURE_PLACEHOLDER,
  COUNTRY_CODES,
  DESIGNER_TEMPLATE,
  DESIGNER_THERMAL_TEMPLATE,
  DOCUMENT_AMOUNT_FUTURE_PLACEHOLDER,
  DOCUMENT_CODE_FUTURE_PLACEHOLDER,
  DOCUMENT_DESIGNER,
  DOC_TYPE,
  DOC_TYPE_TO_MODULE_MAP,
  EMAIL_PATTERN,
  EMAIL_PAYLOAD,
  INPUT_VIEW_DIRECTION,
  PAYMENT_LINK_FUTURE_PLACEHOLDER,
  POPUP_CALLBACKS_TYPE,
  REPORTS,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY
} from '../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo, userInfo } from '../../Redux/Slices/AuthSlice';
import {
  fetchTemplatePrintInfo,
  getSelectedPrintTemplate,
  selectCurrency,
  selectCustomPrintTemplates,
  selectTemplatePrintInfo,
  setSelectedPrintTemplate,
  setTemplatePrintInfo
} from '../../Redux/Slices/CommonDataSlice';
import EmailService from '../../Services/Email';
import Utility, { getPrecedingURLForEmailBody } from '../../Utility/Utility';
import CustomInput from '../CustomInput/CustomInput';
import MultiEmailInputField from './MultiEmailInputField';
import Select from 'react-select';
import { CommonSelectStyle } from '../../Constants/ReactSelectDropDownStyles';
import { CallBackPayloadType } from '../../Models/Interfaces';
import GenericService from '../../Services/GenericService';
import {
  CUSTOM_TYPE_OPTION_RECURRING,
  TYPE_OPTION_RECURRING
} from '../../Models/RecurringDocument';
import DateFormatService from '../../Services/DateFormat';
import { getUpdatedInvoiceObject } from '../../Components/Invoices/InvoiceHelper';
import { getUpdatedBillObject } from '../../Components/Bills/BillHelper';
import { getUpdatedPurchaseOrderObject } from '../../Components/PurchaseOrders/PurchaseOrderHelper';
import { getUpdatedQuoteObject } from '../../Components/Quotations/QuoteHelper';
import {
  getUpdatedPurchareRequestObjectForEmail,
  getUpdatedRFQObjectForEmail
} from '../../Components/Requisitions/RequisitionHelper';
import { Store } from '../../Redux/Store';
import { getUpdatedSalesOrderObject } from '../../Components/SalesOrders/SalesOrderHelper';
import TenantService from '../../Services/Tenant';
import PrintService from '../../Services/Print';
import { ItemLandedCost } from '../../Constants/RoutingGuardPath';
import { productAuConfig } from '../FormUtil/FormUtilConstants';
import ApiConstants from '../../Constants/ApiConstants';
interface EmailComposerProps {
  documentType: string;
  document: any;
  placeholderConfig?: any;
  printUUID?: string;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  isRecurringDocFlow?: boolean;
  currentStep?: any;
  emailRef?: any;
  onAutoSendChange?: any;
  recurringData?: any;
  onDocDesignerOpen?: (isOpened: boolean) => void;
  closePopup?: any;
  disableUserInput?: boolean;
}

interface inputObj {
  value?: any;
  hasError?: boolean;
  errorMessage?: string;
  defaultValue?: any;
  hidden?: boolean;
}

export interface IEmailFormState {
  senderName?: inputObj;
  toFromSOAReport?: inputObj;
  ccTo?: inputObj;
  bcc?: inputObj;
  toNotFromSOAReport?: inputObj;
  replyTo?: inputObj;
  subject?: inputObj;
  content?: inputObj;
  applyForFuture?: inputObj;
  sendCopyChecked?: inputObj;
}

export default function EmailComposer(props: EmailComposerProps) {
  const dispatch = useAppDispatch();
  const tenantDetails = useAppSelector(activeTenantInfo);
  const userDetails = useAppSelector(userInfo);
  const currencies = useAppSelector(selectCurrency);
  const customTemplateList = useAppSelector(selectCustomPrintTemplates);
  const selectedTemplate = useAppSelector(getSelectedPrintTemplate);

  const [emailFormState, setEmailFormState] = useState<IEmailFormState>({});
  const [currencyDetails, setCurrencyDetails] = useState(
    currencies.filter(
      (currency) => currency.currencyCode === tenantDetails.currency
    )[0]
  );
  const [fromSOAReport, setFromSOAReport] = useState(false);
  const [showPlaceHolderConfig, setShowPlaceHolderConfig] =
    useState<boolean>(false);
  const [isLoadingEmailTemplate, setIsLoadingEmailTemplate] = useState(false);
  const [paymentLink, setPaymentLink] = useState<string>('');

  const templatePrintInfo = useAppSelector(selectTemplatePrintInfo);

  const senderNameRef = useRef<any>();
  const subjectRef = useRef<any>(null);
  const emailContentRef = useRef<any>(null);

  const [currentFocusedPayloadKey, setCurrentFocusedPayloadKey] =
    useState<string>('');
  const [caretPos, setCaretPos] = useState<any>(undefined);
  const [showPeepolDetails, setShowPeepolDetails] = useState<boolean>(false);
  const [isSettingDefault, setIsSettingDefault] = useState(false);
  const [showDefOptions, setShowDefOptions] = useState<boolean>(false);
  const [smtpSettings, setSmtpSettings] = useState<any>(null);
  const [emailTemplate, setEmailTemplate] = useState<any>(null);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.EMAIL_DOCUMENT,
        data: () => {
          sendEmail();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.AUTO_SEND_RECURRING_DOCUMENT,
        data: () => {
          updateAutoSendForRecurring();
        }
      });
    }
  };
  useEffect(() => {
    registerInteractions();
  }, [props.currentStep]);

  useEffect(() => {
    setDefaultState();
  }, [showPeepolDetails]);

  useEffect(() => {
    if (templatePrintInfo?.paymentLink) {
      setPaymentLink(templatePrintInfo?.paymentLink);
      setDefaultState();
    }
  }, [templatePrintInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantDetails)) {
      if (
        tenantDetails?.country === COUNTRY_CODES.SG &&
        tenantDetails?.peppolOptIn
      ) {
        let uen = props?.document?.contactDto?.uen;
        TenantService.checkValidUENNuber(uen)
          .then((res: any) => {
            if (!res) {
              setShowPeepolDetails(true);
            }
          })
          .catch((err: any) => {
            setShowPeepolDetails(true);
          });
      }
    }
  }, [tenantDetails]);

  const findCaretPos = (element: any) => {
    if (element?.selectionStart || element?.selectionStart === '0') {
      setCaretPos(element.selectionStart);
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    let elementRef = null;
    switch (currentFocusedPayloadKey) {
      case EMAIL_PAYLOAD.senderName:
        elementRef = senderNameRef.current;
        break;
      case EMAIL_PAYLOAD.subject:
        elementRef = subjectRef.current;
        break;
      case EMAIL_PAYLOAD.textArea:
        elementRef = emailContentRef.current;
        break;
      default:
        break;
    }
    findCaretPos(elementRef);
  }, [currentFocusedPayloadKey]);

  const getUpdatedValue = (option: string, formValue: string) => {
    const insertText = '{' + option + '} ';
    const firstPart = formValue.substring(0, caretPos);
    const lastPart = formValue.substring(caretPos, formValue.length);
    const para = firstPart + insertText + lastPart;
    return para;
  };
  const setOption = (option: string) => {
    if (option !== '') {
      switch (currentFocusedPayloadKey) {
        case EMAIL_PAYLOAD.senderName:
          const updatedSenderName = getUpdatedValue(
            option,
            emailFormState.senderName?.defaultValue
          );
          senderNameRef.current.value = updatedSenderName;
          senderNameRef.current.selectionEnd = caretPos + option.length + 3;
          findCaretPos(senderNameRef.current);
          setEmailFormState((prevState) => {
            return {
              ...prevState,
              senderName: {
                ...prevState.senderName,
                defaultValue: updatedSenderName,
                value: updatedSenderName
              }
            };
          });
          break;
        case EMAIL_PAYLOAD.subject:
          const updatedSubject = getUpdatedValue(
            option,
            emailFormState.subject?.defaultValue
          );
          subjectRef.current.value = updatedSubject;
          subjectRef.current.selectionEnd = caretPos + option.length + 3;
          findCaretPos(subjectRef.current);
          setEmailFormState((prevState) => {
            return {
              ...prevState,
              subject: {
                ...prevState.subject,
                defaultValue: updatedSubject,
                value: updatedSubject
              }
            };
          });

          break;
        case EMAIL_PAYLOAD.textArea:
          const updatedEmailContent = getUpdatedValue(
            option,
            emailFormState.content?.value
          );
          emailContentRef.current.value = updatedEmailContent;
          emailContentRef.current.focus();
          emailContentRef.current.selectionEnd = caretPos + option.length + 3;
          findCaretPos(emailContentRef.current);
          setEmailFormState((prevState) => {
            return {
              ...prevState,
              content: {
                ...prevState.content,
                value: updatedEmailContent
              }
            };
          });
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    const getEmailSettings = async () => {
      const type = props.document.documentType as DOC_TYPE;
      const allowedTypes = [
        DOC_TYPE.INVOICE,
        DOC_TYPE.SALES_ORDER,
        DOC_TYPE.QUOTE,
        DOC_TYPE.ORDER,
        DOC_TYPE.BILL,
        DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES
      ];
      if (allowedTypes.indexOf(type) === -1) {
        return;
      }

      try {
        let response = await EmailService.getEmailSettings();
        setSmtpSettings(response);
      } catch (err) {
        console.error('error loading email settings: ', err);
      }
    };

    const fetchTemplate = async () => {
      setIsLoadingEmailTemplate(true);
      try {
        const savedTemplate = await EmailService.getSavedEmailTemplate(
          props.documentType
        );
        setEmailTemplate(savedTemplate);
        setIsLoadingEmailTemplate(false);
      } catch (err) {
        console.error('error loading save email template: ', err);
        setDefaultState();
        setIsLoadingEmailTemplate(false);
      }
    };

    if (!Utility.isEmpty(props.placeholderConfig)) {
      getEmailSettings();
      fetchTemplate();
    }

    if (
      props.documentType === REPORTS.STATEMENT_OF_ACCOUNTS ||
      props.documentType === REPORTS.SOA
    ) {
      setFromSOAReport(true);
    }
  }, []);

  useEffect(() => {
    const applyEmailSettings = async () => {
      const type = props.document.documentType as DOC_TYPE;
      const allowedTypes = [
        DOC_TYPE.INVOICE,
        DOC_TYPE.SALES_ORDER,
        DOC_TYPE.QUOTE,
        DOC_TYPE.ORDER,
        DOC_TYPE.BILL,
        DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES
      ];
      if (allowedTypes.indexOf(type) === -1) {
        return;
      }

      try {
        if (!Utility.isEmpty(smtpSettings)) {
          let senderName = !Utility.isEmpty(smtpSettings.senderName)
            ? smtpSettings.senderName
            : emailFormState.senderName?.value;
          let emailAddress = !Utility.isEmpty(smtpSettings.emailAddress)
            ? smtpSettings.emailAddress
            : emailFormState.replyTo?.value;
          setEmailFormState((prevState) => {
            return {
              ...prevState,
              senderName: {
                ...prevState.senderName,
                value: senderName,
                defaultValue: senderName
              },
              replyTo: {
                ...prevState.replyTo,
                value: emailAddress,
                defaultValue: emailAddress
              }
            };
          });
        }
      } catch (err) {
        console.error('error applying email settings: ', err);
      }
    };

    const applyTemplate = async () => {
      try {
        if (!Utility.isEmpty(emailTemplate)) {
          const sendACopy = emailTemplate.mailMyself ? true : false;
          let messageBody = decodeURIComponent(emailTemplate.body);
          let subject = emailTemplate.subject;
          const documentSequenceCode = props.document.documentSequenceCode;

          messageBody = messageBody.replace(
            new RegExp(DOCUMENT_CODE_FUTURE_PLACEHOLDER, 'gi'),
            documentSequenceCode
          );
          subject = subject.replace(
            new RegExp(DOCUMENT_CODE_FUTURE_PLACEHOLDER, 'gi'),
            documentSequenceCode
          );

          let contactName = props.document.contact
            ? props.document.contact.name
            : props.document.contactName;
          messageBody = messageBody.replace(
            new RegExp(CONTACT_CODE_FUTURE_PLACEHOLDER, 'gi'),
            contactName ? contactName : ''
          );

          // currency amount formatting remaining
          const documentAmount = props.document.currency
            ? props.document.currency + ` ` + props.document.totalAmount
            : '';
          messageBody = messageBody.replace(
            new RegExp(DOCUMENT_AMOUNT_FUTURE_PLACEHOLDER, 'gi'),
            documentAmount
          );

          if (paymentLink) {
            if (
              new RegExp(PAYMENT_LINK_FUTURE_PLACEHOLDER, 'gi').test(
                messageBody
              )
            ) {
              messageBody = messageBody.replace(
                new RegExp(PAYMENT_LINK_FUTURE_PLACEHOLDER, 'gi'),
                paymentLink
              );
            } else {
              messageBody =
                messageBody + '\n\nPayment Link: ' + paymentLink + '\n';
            }
          } else {
            messageBody = messageBody.replace(
              new RegExp(PAYMENT_LINK_FUTURE_PLACEHOLDER, 'gi'),
              ''
            );
            messageBody = messageBody.replace(
              new RegExp('\nPayment Link:', 'gi'),
              ''
            );
          }

          let ccValues = emailTemplate?.cc?.length
            ? emailTemplate?.cc?.split(',')
            : [];

          let toEmailIds = props.isRecurringDocFlow
            ? props.document.contactDto.emailId
            : props.document && props.document.contactDto
            ? props.document.contactDto.emailId
              ? getAllEmails()
              : []
            : [];

          setEmailFormState((prevState) => {
            return {
              ...prevState,
              senderName: {
                ...prevState.senderName,
                defaultValue: emailTemplate.senderName,
                value: emailTemplate.senderName
              },
              toNotFromSOAReport: {
                ...prevState.toNotFromSOAReport,
                defaultValue: toEmailIds,
                hasError: toEmailIds.length === 0,
                hidden: fromSOAReport,
                value: toEmailIds,
                errorMessage:
                  toEmailIds.length === 0 ? 'Email id is required' : ''
              },
              subject: {
                ...prevState.subject,
                defaultValue: subject,
                value: subject
              },
              content: {
                ...prevState.content,
                value: messageBody,
                hasError: !messageBody.length
              },
              replyTo: {
                ...prevState.replyTo,
                defaultValue: emailTemplate.replyTo,
                value: emailTemplate.replyTo
              },
              applyForFuture: {
                ...prevState.applyForFuture,
                value: true
              },
              sendCopyChecked: {
                ...prevState.applyForFuture,
                value: sendACopy
              },
              ccTo: {
                ...prevState.ccTo,
                value: ccValues
              }
            };
          });
        }
      } catch (err) {
        console.error('error applying email template: ', err);
        setDefaultState();
      }
    };

    if (!Utility.isEmpty(props.placeholderConfig)) {
      applyEmailSettings();
      applyTemplate();
    }
  }, [emailTemplate, smtpSettings, paymentLink]);

  const setDefaultTemplate = () => {
    setIsSettingDefault(true);
    PrintService.setDefaultTemplate(selectedTemplate.id).then(
      (data: any) => {
        removeLoader();
        setShowDefOptions(false);
        dispatch(setTemplatePrintInfo(null));
        getTemplatePrintInfo();
      },
      (err: any) => {
        removeLoader();
        setShowDefOptions(false);
        setIsSettingDefault(false);
        console.log('Error setting default template: ', err);
      }
    );
  };

  const getTemplatePrintInfo = async () => {
    let payload: any;
    if (
      props.documentType === DOC_TYPE.CHEQUE &&
      props.document.documentCode?.length
    ) {
      payload = {
        category: props.documentType,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.PRINT,
        paymentDocumentCodes: props.document.documentCode
      };
    } else {
      payload = {
        category:
          props.documentType === DOC_TYPE.MAKE_PAYMENT ||
          props.documentType === DOC_TYPE.RECEIVE_PAYMENT ||
          props.documentType === DOC_TYPE.PREPAYMENT ||
          props.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT
            ? TEMPLATE_CATEGORY.PAYMENT
            : props.documentType,
        contactCode: props.document?.contactCode
          ? props.document?.contactCode
          : '',
        documentCode: props.document?.documentCode,
        documentType: PrintService.getTemplateNameType(
          props.documentType.toString().toLowerCase()
        ),
        event: TemplateMappingEvent.EMAIL,
        parentDocumentType: null,
        parentDocumentCode: null
      };
    }
    try {
      await dispatch(fetchTemplatePrintInfo(payload));
    } catch (err) {
      console.error('error loding template list: ', err);
    }
  };

  const getSubject = () => {
    switch (props.documentType) {
      case TEMPLATE_CATEGORY.INVOICE:
        return props.isRecurringDocFlow &&
          props.document?.contactDto?.emailSubject
          ? props.document.contactDto.emailSubject
          : `Invoice ${props.document.documentSequenceCode} from ${tenantDetails.name}`;
      case TEMPLATE_CATEGORY.SALES_ORDER:
        return `Sales Order ${props.document.documentSequenceCode} from ${tenantDetails.name}`;
      case TEMPLATE_CATEGORY.QUOTE:
        return `Quote ${props.document.documentSequenceCode} from ${tenantDetails.name}`;
      case TEMPLATE_CATEGORY.BILLS:
        return `${tenantDetails.name} - Bill ${props.document.documentSequenceCode}`;
      case TEMPLATE_CATEGORY.ORDER:
        return `${tenantDetails.name} - Purchase Order ${props.document.documentSequenceCode}`;
      case TEMPLATE_CATEGORY.REQUISITION:
        return `${tenantDetails.name} - Request For Quotation for Requsition Number - ${props.document.purchaseRequestCode}`;
      case DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES:
        return `Request for quotation from ${tenantDetails.name} (${
          tenantDetails?.address?.city ?? ''
        }, ${tenantDetails?.address?.state ?? ''}, ${
          tenantDetails?.address?.country ?? ''
        })`;
      default:
        break;
    }
  };

  const getPeppolString = () => {
    return `Join InvoiceNow - a nationwide E-invoicing method to enjoy smoother Invoicing, faster payments and a better way to save the environment. More at  <a href="https://www.imda.gov.sg/invoicenow">https://www.imda.gov.sg/invoicenow</a>`;
  };

  const getHTML = () => {
    let type;
    let paymentLink = '';
    const productList = props?.document?.requestForQuotationItems
      ?.map((item: any) => item?.product?.name)
      ?.join(', ');
    switch (props.documentType) {
      case TEMPLATE_CATEGORY.INVOICE:
      case TEMPLATE_CATEGORY.SALES_ORDER:
      case TEMPLATE_CATEGORY.QUOTE:
        if (props.documentType === TEMPLATE_CATEGORY.INVOICE) {
          type = 'Invoice';
          if (!Utility.isEmpty(templatePrintInfo?.paymentLink)) {
            paymentLink = `Payment Link: ${templatePrintInfo?.paymentLink}\n`;
          }
        } else if (props.documentType === TEMPLATE_CATEGORY.SALES_ORDER) {
          type = 'Sales Order';
        } else {
          type = 'Quote';
        }
        let htmlForEmail = `Hello,
Thank you for reaching out to us. Please find the
${type} [${props.document.documentSequenceCode}] attached with this mail, in response to your inquiry.

Kindly review the same and contact us for any further queries or details.

${type} No: ${props.document.documentSequenceCode}
Send By: ${tenantDetails?.name}
Amount: ${props.document.currency} ${props.document.totalAmount}
${paymentLink}
`;

        htmlForEmail += `${showPeepolDetails ? getPeppolString() : ''}`;

        htmlForEmail += `We look forward to doing business with you.

Thanks & Regards,
[${tenantDetails?.name}]`;

        return htmlForEmail;
      case TEMPLATE_CATEGORY.BILLS:
      case TEMPLATE_CATEGORY.ORDER:
        let companyDetails: any;
        if (tenantDetails.address.address1) {
          companyDetails = `${tenantDetails.address.address1},
${tenantDetails.address.city}, ${tenantDetails.address.state},
${tenantDetails.address.country}, ${tenantDetails.address.postalCode}`;
        } else {
          companyDetails = tenantDetails.address.country;
        }
        if (props.documentType === TEMPLATE_CATEGORY.BILLS) {
          type = 'Bill';
        } else {
          type = 'Purchase Order';
        }
        const htmlContent = `Dear Supplier,
Please find the attached ${type} [${
          props.document.documentSequenceCode
        }] that lists all items that we wish to purchase from your company.
If you have any questions regarding this ${type}, please contact us.
  
${type} No: ${props.document.documentSequenceCode}
Send By: ${tenantDetails.name}
Amount: ${props.document.currency || props.document.currencyCode} ${
          props.document.totalAmount
        }
  
We look forward to doing business with you.
Thanks & Regards,
${tenantDetails.name}
  
${companyDetails}
    `;
        return htmlContent;
        break;
      case TEMPLATE_CATEGORY.REQUISITION:
        const rfqbody = props.document.items.map((data: any) => {
          let d = {
            productName: data.product.name,
            quantity: data.productQuantity
          };
          return d;
        });
        const htmlRFQContent = `Dear Supplier  ,  \n 
        Please fill the Quotation by clicking on   below Link
       
        Link : \n 
        *Remark : Link will be Auto Generated
        
        Send By: ${tenantDetails.name}
        
        
We look forward to doing business with you.
Thanks & Regards,
${tenantDetails.name}`;

        return htmlRFQContent;
        break;

      case TEMPLATE_CATEGORY.PURCHASE_REQUEST_FOR_QUOTES:
        const htmlBodyContent = `Hi {vendorName},

${
  tenantDetails?.name ?? ''
} has requested you to share quote on the below listed products/services. If you are interested to submit your quote, please click on the shared link and enter price details.

${productList}
  
If you are interested in submitting your quote, please click on this - {link}


Thank you

Team ${tenantDetails.name}
    `;
        return htmlBodyContent;
        break;

      default:
        break;
    }
  };

  const getAllEmails = () => {
    let emailIdsFromAddress = [];
    emailIdsFromAddress.push(props?.document?.billTo?.emailId);
    emailIdsFromAddress.push(props?.document?.shipTo?.emailId);
    if (Utility.isEmptyObject(props.document?.contactDto?.otherEmails)) {
      return [props.document.contactDto.emailId, ...emailIdsFromAddress];
    } else {
      let emailIds = props.document?.contactDto?.otherEmails?.split(',');
      if (!Utility.isEmptyObject(props.document?.contactDto?.emailId)) {
        emailIds = [
          [props.document?.contactDto?.emailId],
          ...emailIds,
          ...emailIdsFromAddress
        ];
      }
      return emailIds;
    }
  };

  const setDefaultState = () => {
    let toEmailIds = props.document.emails
      ? props.document.emails
      : props.isRecurringDocFlow
      ? props.document.contactDto.emailId
      : props.document && props.document.contactDto
      ? props.document.contactDto.emailId
        ? getAllEmails()
        : []
      : [];
    let replyToEmailId =
      props.isRecurringDocFlow && props.document?.contactDto?.replyToEmailId
        ? props.document.contactDto.replyToEmailId
        : tenantDetails.contacts.email;
    let senderName =
      props.isRecurringDocFlow && props.document?.contactDto?.emailSenderName
        ? props.document.contactDto.emailSenderName
        : tenantDetails.name;
    const subject = getSubject();
    const content =
      props.isRecurringDocFlow && props.document?.contactDto?.emailBody
        ? props.document.contactDto.emailBody
        : getHTML();
    let bccEmails = [];

    if (props?.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES) {
      bccEmails = props?.document?.email;
    }

    if (!Utility.isEmpty(userDetails[0]) && !props.isRecurringDocFlow) {
      senderName = userDetails[0].firstName + ' ' + userDetails[0].lastName;
    }

    if (props.isRecurringDocFlow) {
      senderName =
        props.document &&
        props.document.contactDto &&
        props.document.contactDto.emailSenderName;
    }

    let updatedEmailFormState: IEmailFormState = {
      senderName: {
        defaultValue: senderName,
        value: senderName,
        hidden:
          !props.placeholderConfig ||
          props?.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES,
        hasError: Utility.isEmpty(senderName),
        errorMessage: Utility.isEmpty(senderName)
          ? 'Sender name is required'
          : ''
      },
      toNotFromSOAReport: {
        defaultValue: toEmailIds,
        hasError: toEmailIds.length === 0,
        hidden:
          fromSOAReport ||
          props?.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES,
        value: toEmailIds,
        errorMessage: toEmailIds.length === 0 ? 'Email id is required' : ''
      },
      replyTo: {
        defaultValue: replyToEmailId,
        hasError: Utility.isEmpty(replyToEmailId),
        hidden: !props.placeholderConfig && fromSOAReport,
        value: replyToEmailId,
        errorMessage: Utility.isEmpty(replyToEmailId)
          ? 'Reply-to email is required'
          : ''
      },
      subject: {
        defaultValue: subject,
        hasError: Utility.isEmpty(subject),
        hidden: false,
        value: subject,
        errorMessage: Utility.isEmpty(subject)
          ? 'Email subject is required'
          : ''
      },
      content: {
        defaultValue: content,
        hasError: Utility.isEmpty(content),
        hidden: false,
        value: content,
        errorMessage: Utility.isEmpty(content) ? 'Email body is required' : ''
      },
      ccTo: {
        defaultValue: [],
        hasError: false,
        hidden: props?.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES,
        errorMessage: '',
        value: []
      },
      bcc: {
        defaultValue: [],
        hasError: Utility.isEmpty(bccEmails),
        hidden: props?.documentType !== DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES,
        errorMessage: 'This field is required',
        value: bccEmails
      }
    };
    setEmailFormState((prevState) => {
      return {
        ...prevState,
        ...updatedEmailFormState
      };
    });
  };

  const closeEmailPopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  // Save email template
  const saveTemplate = async () => {
    if (emailFormState.applyForFuture?.value) {
      let emailContent = emailFormState.content?.value;
      let subject = emailFormState.subject?.value;
      if (props.document && props.document.documentSequenceCode) {
        emailContent = emailContent.replace(
          new RegExp(props.document.documentSequenceCode, 'gi'),
          DOCUMENT_CODE_FUTURE_PLACEHOLDER
        );

        // currency amount formatting remaining

        const currencyCode = props.document.currencyCode
          ? props.document.currencyCode
          : props.document.currency;
        const documentAmount = currencyCode + ` ` + props.document.totalAmount;
        emailContent = emailContent.replace(
          new RegExp(documentAmount, 'gi'),
          DOCUMENT_AMOUNT_FUTURE_PLACEHOLDER
        );
        if (paymentLink) {
          emailContent = emailContent.replace(
            new RegExp(paymentLink, 'gi'),
            PAYMENT_LINK_FUTURE_PLACEHOLDER
          );
        }

        subject = subject.replace(
          new RegExp(props.document.documentSequenceCode, 'gi'),
          DOCUMENT_CODE_FUTURE_PLACEHOLDER
        );

        let contactName = props.document.contact
          ? props.document.contact.name
          : props.document.contactName;
        if (contactName) {
          emailContent = emailContent.replace(
            new RegExp(contactName, 'gi'),
            CONTACT_CODE_FUTURE_PLACEHOLDER
          );
        }

        let encodedContent = emailContent.split('\r\n').join('<br/>');
        emailContent = encodeURIComponent(encodedContent);
        let payload = {
          category: props.documentType,
          country: tenantDetails.country,
          description: props.documentType,
          mailTemplate: {
            body: emailContent.trim(),
            replyTo: emailFormState.replyTo?.value.trim(),
            subject: subject,
            senderName: emailFormState.senderName?.value.trim(),
            mailMyself: emailFormState.sendCopyChecked?.value,
            cc: emailFormState.ccTo?.value?.join(',')
          },
          module: APP_NAME,
          name: APP_NAME + '_' + props.documentType
        };

        try {
          const response = await EmailService.setTemplate(payload);
          closeEmailPopup();
        } catch (err) {
          console.error('error saving email template: ', err);
          closeEmailPopup();
        }
      }
    } else {
      closeEmailPopup();
    }
  };

  const showDefaultButton = () => {
    const loadedTemplate = customTemplateList?.find(
      (template: any) => template.id === selectedTemplate?.id
    );
    let isContactSet = false;
    if (
      templatePrintInfo &&
      templatePrintInfo['defaultTemplateMapping']?.contactCode
    ) {
      isContactSet = true;
    }
    return (
      selectedTemplate?.id !== DESIGNER_TEMPLATE.id &&
      selectedTemplate?.id !== DESIGNER_THERMAL_TEMPLATE.id &&
      ((!isContactSet &&
        !Utility.isEmpty(loadedTemplate) &&
        customTemplateList?.length &&
        !loadedTemplate?.defaultTemplate) ||
        (isContactSet &&
          customTemplateList?.length &&
          templatePrintInfo['defaultTemplateMapping']?.templateId !==
            selectedTemplate?.id))
    );
  };

  const updateAutoSendForRecurring = () => {
    const recurrData = props.recurringData;

    let payload: any = {
      active: true,
      customTemplate: true,
      documentCode:
        recurrData.document.documentCode || recurrData.document.jeCode,
      documentType: recurrData.documentType,
      emailBody: emailFormState?.content?.value,
      emailReplyTo: emailFormState?.replyTo?.value,
      emailSenderName: emailFormState?.senderName?.value,
      emailSubject: emailFormState?.subject?.value,
      emailTemplateId: selectedTemplate.id,
      emailTo: emailFormState?.toNotFromSOAReport?.value.join(','),
      endingDate:
        recurrData.endDate && !recurrData.isInfinite
          ? DateFormatService.getDateStrFromDate(
              recurrData.endDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : null,
      firstDocumentDate: recurrData.startDate
        ? DateFormatService.getDateStrFromDate(
            recurrData.startDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          )
        : null,
      infinite: recurrData.isInfinite,
      insertPaymentLink: false,
      interval: recurrData.interval.value.value,
      sendEmailNotification: true,
      timeZoneOffset: Utility.getTimeZoneOffsetValue()
    };

    if (recurrData.isCustomInterval) {
      payload = {
        ...payload,
        customPeriod: recurrData?.customIntervalPeriod?.value,
        customInterval: recurrData.customInterval.value.value
      };
      if (
        typeof payload.customPeriod === 'undefined' ||
        payload.customPeriod === null ||
        payload.customPeriod === ''
      ) {
        showToast('Custom period is required', TOAST_TYPE.FAILURE);
        return;
      }
    }

    GenericService.saveRecurringDocument(payload)
      .then((res) => {
        showToast(
          'Recurring rule for document has been saved successfully.',
          TOAST_TYPE.SUCCESS
        );
        props.closePopup(DOC_TYPE.INVOICE);
      })
      .catch((err) => {
        showToast(
          'An error occurred while saving rucurring rule.',
          TOAST_TYPE.SUCCESS
        );
        props.closePopup(DOC_TYPE.INVOICE);
        console.log(err);
      });
  };
  const sendMultipleRFQEmail = async (
    docType: any,
    returnValue: any,
    tenantDetails: any,
    email: any,

    sendEmailPayload: any,
    tmpselectedTemplateId: any,
    currencyDetails: any
  ) => {
    let toList = email.to.split(',');

    toList.map((to: any, index: any) => {
      const SupplierData = props.document.supplierData.find(
        (emailData: any) => {
          return emailData.supplierEmail === to;
        }
      );

      let documentData = props.document.rfqCodeList.find((rfqCode: any) => {
        return rfqCode.vendorCode === SupplierData.supplier.code;
      });

      const url = ` https://local.deskera.xyz:8443/${ApiConstants.URL.RFQ.PUBLIC_FORM_URL(
        documentData.purchaseRequestForQuotesCode
      )}`;
      var OpenLink = 'Open The Link';
      const link = OpenLink.link(url);

      let emailBody = `Dear Supplier  ,  \n 
         Please fill the Quotation by clicking on   below Link
         <a href="https://local.deskera.xyz:8443/${ApiConstants.URL.RFQ.PUBLIC_FORM_URL(
           documentData.purchaseRequestForQuotesCode
         )}">Open The Link<a/>
      Send By: ${tenantDetails.name}
          
          
      We look forward to doing business with you.
      Thanks & Regards,
      ${tenantDetails.name} `;

      if (!Utility.isEmpty(props.placeholderConfig)) {
        let modifiedEmailContent = emailBody.split('\n').join('<br/>');
        emailBody = encodeURIComponent(modifiedEmailContent);
      }

      email.to = to;
      email.body = emailBody;
      const response = EmailService.sendEmail(
        docType,
        returnValue,
        tenantDetails,
        email,
        //  DOC_TYPE_TO_MODULE_MAP[props.document.documentType],
        'ORDER',
        sendEmailPayload,
        tmpselectedTemplateId,
        currencyDetails,
        true
      );
      showToast('Email sent successfully', TOAST_TYPE.SUCCESS);
      saveTemplate();
    });
  };
  // Called from parent component
  const sendEmail = async () => {
    let bcc = '';
    if (
      emailFormState.sendCopyChecked?.value &&
      emailFormState.replyTo?.value
    ) {
      // bcc = tenantDetails.contacts.email;
      bcc =
        Store.getState().authInfo?.userInfo?.data[0]?.email ||
        tenantDetails.contacts.email;
    }

    if (props.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES) {
      bcc = emailFormState.bcc?.value?.join(',');
    }

    let emailBody = '';
    if (!Utility.isEmpty(props.placeholderConfig)) {
      emailBody = emailFormState.content?.value;
      let modifiedEmailContent = emailBody.split('\n').join('<br/>');
      emailBody = encodeURIComponent(modifiedEmailContent);
    }

    let to: string[] = !Utility.isEmpty(props.placeholderConfig)
      ? emailFormState.toNotFromSOAReport?.value
      : emailFormState.toFromSOAReport?.value;
    if (
      (!to?.length && !emailFormState?.toNotFromSOAReport?.hidden) ||
      !emailFormState.subject?.value.length ||
      !emailBody.length ||
      !emailFormState.replyTo?.value ||
      !emailFormState.senderName?.value
    ) {
      return;
    }

    if (
      props.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES &&
      emailFormState.bcc?.value.length === 0
    ) {
      return;
    }

    let cc: string[] = !Utility.isEmpty(props.placeholderConfig)
      ? emailFormState.ccTo?.value
      : emailFormState.ccTo?.value;

    const email = {
      to: to?.join(','),
      subject: emailFormState.subject?.value,
      body: emailBody,
      replyTo: emailFormState.replyTo?.value,
      senderName: emailFormState.senderName?.value,
      bcc: bcc,
      cc: cc?.join(',')
    };

    if (!fromSOAReport) {
      let sendEmailPayload: any = { isSendPaymentLink: false };
      let returnValue = props.document;
      switch (props.documentType) {
        case TEMPLATE_CATEGORY.INVOICE:
          returnValue = getUpdatedInvoiceObject(props.document);
          break;
        case TEMPLATE_CATEGORY.SALES_ORDER:
          returnValue = getUpdatedSalesOrderObject(props.document);
          break;
        case TEMPLATE_CATEGORY.BILLS:
          returnValue = getUpdatedBillObject(props.document);
          break;
        case TEMPLATE_CATEGORY.ORDER:
          returnValue = getUpdatedPurchaseOrderObject(props.document);
          break;
        case TEMPLATE_CATEGORY.QUOTE:
          returnValue = getUpdatedQuoteObject(props.document);
          break;
        case TEMPLATE_CATEGORY.REQUISITION:
          returnValue = getUpdatedPurchareRequestObjectForEmail(props.document);
          break;
        case DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES:
          returnValue = getUpdatedRFQObjectForEmail(props.document);
      }

      try {
        //need to change once get api
        let tmpselectedTemplate = selectedTemplate;
        let docType = props.documentType;
        if (props.documentType === TEMPLATE_CATEGORY.REQUISITION) {
          tmpselectedTemplate = {
            id: '0',
            templateName: 'Document Designer Template',
            appName: 'ERP',
            templateData: ''
          };
          let docType = 'ORDER';

          sendMultipleRFQEmail(
            docType,
            returnValue,
            tenantDetails,
            email,
            sendEmailPayload,
            tmpselectedTemplate.id,
            currencyDetails
          );
        } else {
          const response = await EmailService.sendEmail(
            docType,
            returnValue,
            tenantDetails,
            email,
            DOC_TYPE_TO_MODULE_MAP[props.document.documentType],
            sendEmailPayload,
            tmpselectedTemplate?.id,
            currencyDetails,
            true
          );

          showToast('Email sent successfully', TOAST_TYPE.SUCCESS);
          saveTemplate();
        }
      } catch (err) {
        console.error('error sending email: ', err);
        showToast('Error sending email', TOAST_TYPE.ERROR);
      }
    } else {
      console.log('Sending SOA document not supported yet..');
    }
  };

  const dispatchSelectedTemplate = (template: any) => {
    dispatch(setSelectedPrintTemplate(template));
  };

  const getUpdatedOptions = () => {
    if (customTemplateList) {
      const designerTemplateIndex = customTemplateList.findIndex(
        (x: any) => x.id === 0
      );
      if (designerTemplateIndex !== -1) {
        return [...customTemplateList];
      }
      return [DESIGNER_TEMPLATE, ...customTemplateList];
    }
    return [];
  };

  const getSelectedMultiInputFieldValue = () => {
    return emailFormState.toNotFromSOAReport?.value;
  };

  const showOptions = () => {
    let isContactSet = false;
    if (
      templatePrintInfo &&
      templatePrintInfo['defaultTemplateMapping']?.contactCode
    ) {
      isContactSet = true;
    }
    return (
      <DKListPicker
        data={
          isContactSet
            ? [`Set for Contact`]
            : [`Set for Contact`, `Set for Document`]
        }
        className="shadow-m absolute z-10"
        style={{ right: 0, top: -10, width: 150 }}
        onSelect={(index: any, value: any) => {
          if (index === 1) {
            showLoader();
            setDefaultTemplate();
          } else {
            let thermalPrint = false;
            if (selectedTemplate.id === DESIGNER_THERMAL_TEMPLATE.id) {
              thermalPrint = true;
            }
            let waterMarkOption = '';
            // if (
            //   isInvoiceDocument() &&
            //   !isBulkDocumentPrint() &&
            //   wmarkOption?.length
            // ) {
            //   waterMarkOption = wmarkOption.join(',');
            // }
            showLoader();
            // PrintService.printCustomTemplateInvoice(
            //   props.printUUID ? props.printUUID : '',
            //   selectedTemplate.id,
            //   false,
            //   thermalPrint ? DESIGNER_THERMAL_TEMPLATE.pageFormat : '',
            //   waterMarkOption
            // )
            //   .then((res: any) => {
            //     dispatch(setTemplatePrintInfo(null));
            //     getTemplatePrintInfo();
            //     removeLoader();
            //   })
            //   .catch((err: any) => {
            //     removeLoader();
            //     console.error(err);
            //   });
            setShowDefOptions(false);
            let payload: any = {
              contactCode: props.document?.contactCode
                ? props.document?.contactCode
                : '',
              documentType: PrintService.getTemplateNameType(
                props.documentType.toString().toLowerCase()
              ),
              event: TemplateMappingEvent.EMAIL,
              templateId: selectedTemplate.id,
              custom: true
            };

            PrintService.setContactTemplate(payload)
              .then((res: any) => {
                dispatch(setTemplatePrintInfo(null));
                getTemplatePrintInfo();
                removeLoader();
              })
              .catch((err) => {
                removeLoader();
                console.error(err);
                console.log(err);
              });
          }
        }}
        onClose={() => {
          setShowDefOptions(false);
        }}
      />
    );
  };

  const updateEmailFormState = (
    fieldName: keyof IEmailFormState,
    inputValue: string[],
    hasError: boolean,
    isMandatory: boolean,
    placeHolder: string
  ) => {
    return (prevState: IEmailFormState) => {
      return {
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          value: hasError ? [...prevState[fieldName]?.value] : inputValue,
          hasError:
            props?.documentType === DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES &&
            inputValue.length === 0
              ? true
              : hasError,
          errorMessage:
            isMandatory && inputValue.length === 0
              ? `${placeHolder} is required`
              : ''
        }
      };
    };
  };

  const handleComponentSelections = (e: any) => {
    const selection = window.getSelection();
    let range = selection?.rangeCount ? selection?.getRangeAt(0) : null;
    const textBox = emailContentRef.current;

    if (range && textBox) {
      range = e.target.selectionStart;
    } else {
      range = null;
    }
    setCaretPos(range);
  };

  return (
    <>
      {props.isRecurringDocFlow && <div ref={props.emailRef}></div>}
      {isLoadingEmailTemplate && (
        <div className="flex w-full h-full items-center justify-center font-semibold">
          <DKSpinner
            iconClassName="ic-s-2"
            className="ml-m"
            title="loading email content"
          />
        </div>
      )}
      {!Utility.isEmpty(emailFormState) && !isLoadingEmailTemplate && (
        <div className="text-sm p-2 h-full overflow-y-auto">
          {!emailFormState.senderName?.hidden && (
            <CustomInput
              ref={senderNameRef}
              hasError={emailFormState.senderName?.defaultValue === ''}
              errorMsg={
                emailFormState.senderName?.defaultValue === ''
                  ? 'Sender Name is required'
                  : ''
              }
              style={{ background: '#f4f4f6' }}
              onFocus={(event: any) => {
                senderNameRef.current?.focus();
                if (currentFocusedPayloadKey === EMAIL_PAYLOAD.senderName) {
                  findCaretPos(senderNameRef.current);
                } else {
                  setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.senderName);
                }
              }}
              onClick={(event) => {
                senderNameRef.current?.focus();
                if (currentFocusedPayloadKey === EMAIL_PAYLOAD.senderName) {
                  findCaretPos(senderNameRef.current);
                } else {
                  setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.senderName);
                }
              }}
              onChange={(event: any) => {
                setEmailFormState((prevState) => {
                  return {
                    ...prevState,
                    senderName: {
                      ...prevState.senderName,
                      defaultValue: event.target?.value,
                      value: event.target?.value,
                      errorMessage:
                        event.target?.value === ''
                          ? 'Sender Name is required'
                          : ''
                    }
                  };
                });
              }}
              defaultValue={emailFormState.senderName?.defaultValue}
              placeholder={'Sender Name'}
              isRequired={true}
              labelName="Sender Name"
            />
          )}

          {!emailFormState.toNotFromSOAReport?.hidden && (
            <div className="flex flex-col">
              <label className="flex flex-row text-gray-500">To</label>
              <div className="flex-1 w-full box-border">
                <MultiEmailInputField
                  onValueChange={(value: string[], hasError: boolean) => {
                    setEmailFormState(
                      updateEmailFormState(
                        'toNotFromSOAReport',
                        value,
                        hasError,
                        true,
                        'Email id'
                      )
                    );
                  }}
                  value={
                    props.isRecurringDocFlow
                      ? getSelectedMultiInputFieldValue()
                      : emailFormState.toNotFromSOAReport?.value
                  }
                  placeHolder="Email id"
                  isMandatory={true}
                />
              </div>
              {emailFormState.toNotFromSOAReport?.hasError && (
                <div className="text-xs mx-1 mt-1 text-red-600">
                  {emailFormState.toNotFromSOAReport?.errorMessage}
                </div>
              )}
            </div>
          )}

          {/* CC field */}
          {!emailFormState.ccTo?.hidden && (
            <div className="flex flex-col mt-m">
              <label className="flex flex-row text-gray-500">Cc</label>
              <div className="flex-1 w-full box-border">
                <MultiEmailInputField
                  onValueChange={(value: string[], hasError: boolean) => {
                    setEmailFormState(
                      updateEmailFormState('ccTo', value, hasError, false, 'Cc')
                    );
                  }}
                  value={
                    props.isRecurringDocFlow
                      ? getSelectedMultiInputFieldValue()
                      : emailFormState.ccTo?.value
                  }
                  placeHolder="Cc"
                  isMandatory={false}
                />
              </div>
              {emailFormState.ccTo?.hasError && (
                <div className="text-xs mx-1 mt-1 text-red-600">
                  {emailFormState.ccTo?.errorMessage}
                </div>
              )}
            </div>
          )}

          {/* BCC field */}
          {!emailFormState.bcc?.hidden && (
            <div className="flex flex-col mt-m">
              <label className="flex flex-row text-gray-500">Bcc</label>
              <div className="flex-1 w-full box-border">
                <MultiEmailInputField
                  onValueChange={(value: string[], hasError: boolean) => {
                    setEmailFormState(
                      updateEmailFormState('bcc', value, hasError, true, 'bcc')
                    );
                  }}
                  value={emailFormState?.bcc?.value}
                  placeHolder="Bcc"
                  isMandatory={true}
                  disableUserInput={props?.disableUserInput}
                />
              </div>
              {emailFormState.bcc?.hasError && (
                <div className="text-xs mx-1 mt-1 text-red-600">
                  {emailFormState.bcc?.errorMessage}
                </div>
              )}
            </div>
          )}

          {!emailFormState.replyTo?.hidden && (
            <CustomInput
              hasError={
                Utility.isEmpty(emailFormState.replyTo?.defaultValue) ||
                !EMAIL_PATTERN.test(emailFormState.replyTo?.defaultValue)
              }
              style={{ background: '#f4f4f6' }}
              errorMsg={emailFormState.replyTo?.errorMessage}
              onChange={(event) => {
                setEmailFormState((prevState) => {
                  return {
                    ...prevState,
                    replyTo: {
                      ...prevState.replyTo,
                      defaultValue: event.target?.value,
                      value: event.target?.value,
                      errorMessage:
                        event.target?.value === ''
                          ? 'Reply-to email is required'
                          : !EMAIL_PATTERN.test(event.target?.value)
                          ? 'Reply-to email is invalid'
                          : ''
                    }
                  };
                });
              }}
              defaultValue={emailFormState.replyTo?.defaultValue}
              placeholder={'Reply To'}
              isRequired={true}
              labelName="Reply To"
            />
          )}

          {!emailFormState.subject?.hidden && (
            <CustomInput
              hasError={Utility.isEmpty(emailFormState.subject?.defaultValue)}
              ref={subjectRef}
              style={{ background: '#f4f4f6' }}
              errorMsg={emailFormState.subject?.errorMessage}
              onFocus={(event: any) => {
                subjectRef.current?.focus();
                if (currentFocusedPayloadKey === EMAIL_PAYLOAD.subject) {
                  findCaretPos(subjectRef.current);
                } else {
                  setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.subject);
                }
              }}
              onClick={(event) => {
                subjectRef.current?.focus();
                if (currentFocusedPayloadKey === EMAIL_PAYLOAD.subject) {
                  findCaretPos(subjectRef.current);
                } else {
                  setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.subject);
                }
              }}
              onChange={(event) => {
                setEmailFormState((prevState) => {
                  return {
                    ...prevState,
                    subject: {
                      ...prevState.subject,
                      defaultValue: event.target?.value,
                      value: event.target?.value,
                      errorMessage:
                        event.target?.value === ''
                          ? 'Email subject is required'
                          : ''
                    }
                  };
                });
              }}
              defaultValue={emailFormState.subject?.defaultValue}
              placeholder={'Subject'}
              isRequired={true}
              labelName="Subject"
            />
          )}

          {!Utility.isEmpty(props.placeholderConfig) &&
            props?.documentType !== DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES && (
              <div
                className="flex flex-row items-center justify-between mb-2"
                style={{ height: 68 }}
              >
                <div className="column position-relative">
                  <DKButton
                    title={'Placeholder'}
                    icon={DKIcons.ic_plus}
                    className="ic-s transform fs-r"
                    onClick={() => {
                      if (!showPlaceHolderConfig) {
                        setShowPlaceHolderConfig((prevValue) => !prevValue);
                      }
                    }}
                  ></DKButton>
                  {showPlaceHolderConfig && (
                    <DKListPicker
                      onClose={() => {
                        setShowPlaceHolderConfig(false);
                      }}
                      className="absolute shadow-m border z-index-2"
                      style={{
                        top: 30,
                        left: 0,
                        width: 150,
                        transform: 'translate(100% -50%)'
                      }}
                      data={props.placeholderConfig.map((x: any) => x.text)}
                      onSelect={(index: number, value: string) => {
                        setOption(props.placeholderConfig[index].action);
                        setShowPlaceHolderConfig(false);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ width: 320 }}
                  className={showDefaultButton() ? 'mt-9' : 'mt-0'}
                >
                  {props.printUUID && customTemplateList && (
                    <div className="column ">
                      <div className="row">
                        <DKLabel
                          text={'Design Template   '}
                          style={{ width: 150 }}
                        />
                        <DKInput
                          className={''}
                          readOnly={Utility.isEmpty(selectedTemplate)}
                          value={selectedTemplate}
                          type={INPUT_TYPE.DROPDOWN}
                          displayKey="templateName"
                          required={false}
                          canValidate={false}
                          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                          onChange={(template: any) => {
                            if (template.id !== selectedTemplate.id) {
                              dispatchSelectedTemplate(template);
                            }
                          }}
                          dropdownConfig={{
                            className: '',
                            style: {},
                            allowSearch: false,
                            searchableKey: 'templateName',
                            canEdit: false,
                            canDelete: false,
                            data: getUpdatedOptions(),
                            renderer: (index: number, obj: any) => {
                              return (
                                <div className="column parent-width">
                                  <DKLabel
                                    style={{ wordBreak: 'break-word' }}
                                    text={obj.templateName}
                                  />
                                  {obj?.defaultTemplate && (
                                    <DKLabel
                                      text="Default"
                                      className="text-gray fs-s fw-m"
                                    />
                                  )}
                                </div>
                              );
                            },
                            button: {
                              title: 'Edit Template',
                              icon: DKIcons.white.ic_edit,
                              className: 'bg-button text-white',
                              onClick: () => {
                                if (props.printUUID) {
                                  const printExternalAppUrl = [
                                    process.env.REACT_APP_DOC_DESIGNER_URL,
                                    props.printUUID
                                  ].join('/');
                                  if (props.onDocDesignerOpen) {
                                    props.onDocDesignerOpen(true);
                                  }
                                  window.open(printExternalAppUrl, '_blank');
                                }
                              }
                            }
                          }}
                        />
                      </div>
                      <div className="row align-items-end justify-content-end position-relative">
                        {showDefaultButton() && (
                          <DKButton
                            title={'Set as default'}
                            className={`fw-m text-blue text-underline`}
                            // disabled={isSettingDefault}
                            onClick={() => {
                              // setDefaultTemplate();
                              setShowDefOptions(true);
                            }}
                          />
                        )}
                        {showDefOptions && (
                          <div className="position-absolute">
                            {showOptions()}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

          {!emailFormState.content?.hidden && (
            <div className="flex flex-col mt-l">
              <textarea
                ref={emailContentRef}
                style={{ height: 300, resize: 'none', background: '#f4f4f6' }}
                placeholder="Email body.."
                className={`border rounded border-gray-300 w-full text-sm font-normal p-2 outline-none${
                  emailFormState.content?.hasError
                    ? 'focus:outline-none focus:ring-2 focus:ring-red-500 border-red-400'
                    : ''
                }`}
                value={emailFormState.content?.value}
                onFocus={(event) => {
                  emailContentRef.current?.focus();
                  if (currentFocusedPayloadKey === EMAIL_PAYLOAD.textArea) {
                    findCaretPos(emailContentRef.current);
                  } else {
                    setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.textArea);
                  }
                }}
                onClick={(event) => {
                  emailContentRef.current?.focus();
                  if (currentFocusedPayloadKey === EMAIL_PAYLOAD.textArea) {
                    findCaretPos(emailContentRef.current);
                  } else {
                    setCurrentFocusedPayloadKey(EMAIL_PAYLOAD.textArea);
                  }
                }}
                onChange={(event) => {
                  setEmailFormState((prevState) => {
                    return {
                      ...prevState,
                      content: {
                        ...prevState.content,
                        value: event.target?.value,
                        hasError: Utility.isEmpty(event.target?.value),
                        errorMessage: Utility.isEmpty(event.target?.value)
                          ? 'Email body is required'
                          : ''
                      }
                    };
                  });
                }}
                contentEditable="true"
                onSelect={handleComponentSelections}
              ></textarea>
              {emailFormState.content?.hasError && (
                <div className="text-xs mx-1 mt-1 text-red-600">
                  {emailFormState.content?.errorMessage}
                </div>
              )}
            </div>
          )}

          <div className="flex flex-row items-center justify-between my-3">
            {!props.isRecurringDocFlow && (
              <DKCheckMark
                title="Apply to future emails"
                isSelected={emailFormState.applyForFuture?.value}
                className="text-black"
                onClick={() => {
                  setEmailFormState((prevState) => {
                    return {
                      ...prevState,
                      applyForFuture: {
                        ...prevState.applyForFuture,
                        value: !emailFormState.applyForFuture?.value
                      }
                    };
                  });
                }}
              />
            )}
            <DKButton
              title="Reset"
              className="bg-app text-white"
              onClick={() => setDefaultState()}
            />
          </div>
          <div className="flex flex-row w-11/12 my-3">
            <DKCheckMark
              title={`Send a copy to myself at <i>${
                Store.getState().authInfo?.userInfo?.data[0]?.email
              }</i>`}
              isSelected={emailFormState.sendCopyChecked?.value}
              className="text-black"
              onClick={() => {
                setEmailFormState((prevState) => {
                  return {
                    ...prevState,
                    sendCopyChecked: {
                      ...prevState.sendCopyChecked,
                      value: !emailFormState.sendCopyChecked?.value
                    }
                  };
                });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
