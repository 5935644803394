import ApiConstants from '../Constants/ApiConstants';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../Constants/Enum';
import { BulkDeleteResponse } from '../Models/Interfaces';
import { Quote } from '../Models/Quote';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import http2 from './Interceptor2';

export interface SupplierQuoteAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: any;
  QueryParam?: any;
  CustomField?: any;
}

export const defaultConfig: SupplierQuoteAPIConfig = {
  SearchTerm: '',
  Limit: 25,
  Page: 0,
  Query: '',
  QueryParam: ''
};

class SupplierQuoteService {
  static apiConfig: SupplierQuoteAPIConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.SUPPLIERQUOTE;
  static abortController: any = null;

  static getSupplierQuoteByPage() {
    Utility.cancelRequest(SupplierQuoteService.abortController);
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }

    const queryString: string = `?fetchAttachmentDetails=true&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}&search=${this.apiConfig.SearchTerm ? encodeURIComponent(this.apiConfig.SearchTerm) : ''}&query=${
      this.apiConfig.Query ? this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;

    const finalEndpoint: string =
      ApiConstants.URL.SUPPLIER_QUOTES.FETCH_SPQ + queryString;

    SupplierQuoteService.abortController = Utility.createAbortController();

    return http
      .get(`${finalEndpoint}`, {
        signal: SupplierQuoteService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createSupplierQuotes(supplierQoutePayload: any) {
    return http2
      .post(
        `${ApiConstants.URL.SUPPLIER_QUOTES.CREATE_SPQ}`,
        supplierQoutePayload
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getSupplierQuotesById(id: any) {
    return http
      .get(`${ApiConstants.URL.SUPPLIER_QUOTES.FETCH_SPQ_BY_ID(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static deleteSupplierQuote(id: number) {
    return http2
      .delete(`${ApiConstants.URL.SUPPLIER_QUOTES.DELETE_SPQ(id)}`)
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }
}

export default SupplierQuoteService;
