import React, { useEffect, useRef, useState } from 'react';
import {
  TOAST_TYPE,
  DKLabel,
  DKInput,
  INPUT_TYPE,
  Toggle,
  DKRadioButton,
  DKCheckMark,
  DKButton,
  DKIcon,
  showAlert,
  DKLine,
  DKDataGrid,
  DKIcons,
  showToast,
  DKListPicker2
} from 'deskera-ui-library';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  initialOrganisationProfileFormState,
  OrganisationFormPayload,
  OrganisationProfileFormProps,
  OrganisationProfileFormState,
  ORGANISATION_FORM_FIELD_NAME,
  ORGANISATION_COMPLIANCE_FIELD_NAME,
  ORGANISATION_PROFILE_FIELDS_NAME,
  ORGANISATION_FORM_DATE_FIELDS,
  OrgAddressInfo,
  ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME,
  UK_FLAT_RATE_SCHEME_COLS
} from '../../../Models/OrganisationProfile';
import Select, { OptionsType } from 'react-select';
import {
  AddressType,
  BtnType,
  CallBackPayloadType,
  CountryInfo,
  GSTSubmissionCheckPayload,
  PopupClickActionType,
  ReactSelectOptionsType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  CIN_REGEX_PATTERN,
  COUNTRY_CODES,
  DATE_FORMAT,
  DATE_FORMAT_LIST,
  DOCUMENT_MODE,
  EMAIL_PATTERN,
  GSTIN_REGEX_PATTERN,
  INPUT_VIEW_DIRECTION,
  IRP_CRED_VALID_GSTIN,
  MODULES_NAME,
  NEW_SETTINGS_SECTION,
  NUMBER_FORMAT_LIST,
  ORG_BUSINESS_TYPE,
  PAN_REGEX_PATTERN,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchCurrencyWithExchangeRate,
  selectCurrency,
  selectCurrencyListWithExchangeRate,
  selectShowSettings,
  selectTaxes,
  setSelectedSetting
} from '../../../Redux/Slices/CommonDataSlice';
import {
  GSTEfilingAuthInfo,
  activeTenantInfo,
  appCustomizationInfo,
  eWayAuthInfo,
  fetchAppCustomizationInfo,
  fetchContactInfo,
  fetchCurrentTenantInfo,
  fetchEWayBillAuthInfo,
  fetchGSTEFilingAuthInfo,
  selectContactInfo,
  subscriptionInfo,
  activeTenantInfoForBookkeeper, fetchEInvoiceUserName, eInvoiceUserName
} from '../../../Redux/Slices/AuthSlice';
import {
  COUNTRIES_LIST,
  EXEMPTION_CRITERIA_MY,
  INDIAN_STATES_MOCK_DATA,
  UK_VAT_SCHEME,
  USstateMockData
} from '../../../Constants/StaticData';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone,
  formatNPWPNumber,
  getCapitalized
} from '../../../Utility/Utility';
import TenantService from '../../../Services/Tenant';
import { useTranslation } from 'react-i18next';
import DateFormatService from '../../../Services/DateFormat';
import {
  COMPOSITE_SCHEME_OPTION,
  GST_BUSINESS_TYPE,
  ORGANISATION_FORM_TAB,
  PLANS
} from '../../../Constants/Enum';
import PopupWrapper, {
  DynamicPopupWrapper
} from '../../../SharedComponents/PopupWrapper';
import { IRPCredPopup } from './IRPCredPopup';
import { OtpPopup } from './OtpPopup';
import {
  selectContactsColumnConfig,
  selectContactsColumnConfigTableId,
  selectLiabilities
} from '../../../Redux/Slices/ContactsSlice';
import EInvoiceService from '../../../Services/EInvoice';
import ic_delete from '../../../Assets/Icons/ic_delete.png';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import TableService from '../../../Services/Table';
import { TableManager } from '../../../Managers/TableManager';
import { REMOTE_CONFIG_TABLES } from '../../../Constants/TableConstants';
import { addProductColumnConfig } from '../../../Redux/Slices/ProductsSlice';
import { addInvoiceColumnConfig } from '../../../Redux/Slices/InvoicesSlice';
import { addBillColumnConfig } from '../../../Redux/Slices/BillsSlice';
import { addOrderColumnConfig } from '../../../Redux/Slices/PurchaseOrdersSlice';
import { addQuoteColumnConfig } from '../../../Redux/Slices/QuotesSlice';
import PeppolRegistrationProcess from '../../../SharedComponents/PeppolRegistrationProcess';
import EWayBillLoginPopUp from './EWayBillLoginPopUp';
import RouteManager, { PAGE_ROUTES } from '../../../Managers/RouteManager';
import AgencyForm from '../../AgencyForm/AgencyForm';
import { removeDraft, selectDrafts } from '../../../Redux/Slices/DraftsSlice';
import { addSalesOrderColumnConfig } from '../../../Redux/Slices/SalesOrderSlice';
import {
  isTabletView,
  isViewportLarge
} from '../../../Utility/ViewportSizeUtils';
import AppManager from '../../../Managers/AppManager';
import { localizedText } from '../../../Services/Localization/Localization';
import { selectedAccounts } from '../../../Redux/Slices/AccountsSlice';
import { fetchEInvoiceAuthInfo } from '../../../Redux/Slices/AuthSlice';
import NumberFormatService from '../../../Services/NumberFormat';
import MTDSignUpConfirmPopUp from '../../NewOrgForm/MTDSignUpConfirmPopUp';
import { selectWithHoldTax } from '../../../Redux/Slices/WHTSlice';
import AddAgency from '../Agencies/AddAgency';
import { fetchAgencies } from '../../../Redux/Slices/AgenciesSlice';
import CommonStateListPicker from '../../../SharedComponents/DocumentForm/CommonStateListPicker';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { CustomField } from '../../../Models/CustomField';
import { TimezoneList } from '../../../Constants/TimeZoneList';
import { requisitionColumnConfig } from '../../../Redux/Slices/RequisitionSlice';

const OrganisationProfileForm: React.FC<OrganisationProfileFormProps> = (
  props
) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [organisationState, setOrganisationState] =
    useState<OrganisationProfileFormState>(
      deepClone(initialOrganisationProfileFormState)
    );
  const [showPeppolRegistration, setShowPeppolReigstation] = useState(false);

  // UK Compliance grid states
  const [ukComplianceVatSchemeColumns, setUKComplianceVatSchemeColumns] =
    useState(UK_FLAT_RATE_SCHEME_COLS);
  const [ukComplianceVatSchemeRows, setUKComplianceVatSchemeRows] = useState<
    any[]
  >([]);
  const [showAgencyForm, setShowAgencyForm] = useState<boolean>(false);

  type OrganisationReactSelectOption = ReactSelectOptionsType<string, string>;
  type ReactOptionType = ReactSelectOptionsType<string, string>;
  const multiCurrencyList = useAppSelector(selectCurrency);
  const multiCurrencyExchangeList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const tenantDetails = useAppSelector(activeTenantInfo);
  const payableAccounts = useAppSelector(selectLiabilities);
  const systemDimensionsData = useAppSelector(selectDimensions);
  const draftsData = useAppSelector(selectDrafts);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const accountList = useAppSelector(selectedAccounts);
  const taxList = useAppSelector(selectTaxes);
  const whTaxes = useAppSelector(selectWithHoldTax);
  const columnConfig = useAppSelector(selectContactsColumnConfig);
  const columnConfigTableId = useAppSelector(selectContactsColumnConfigTableId);
  const alternateTenantDetails = useAppSelector(activeTenantInfoForBookkeeper);
  const countryCode: string = tenantDetails.country;

  const indianStatesList = INDIAN_STATES_MOCK_DATA;
  const vatSchemesList = UK_VAT_SCHEME;
  const countryList = COUNTRIES_LIST;
  const [isActionBtnDisabled, setIsActionBtnDisabled] =
    useState<boolean>(false);
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateContact: 'click' }
  };
  const popupBtnConfigForEInvoice: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.IRP_CREDENTIAL
    }
  ];
  const popupBtnConfigForGSTOtp: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.GST_E_FILING
    }
  ];
  const popupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];
  const orgProfileRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const contactInfoData = useAppSelector(selectContactInfo);
  const [contactInfo, setContactInfo] = useState<any>();
  const data = useAppSelector(appCustomizationInfo);
  const [module, setModule] = useState<any>({ ewayBill: true });
  const eWayAuthInfoData = useAppSelector(eWayAuthInfo);
  const eInvoiceUserNameData = useAppSelector(eInvoiceUserName);
  const authGstInfo = useAppSelector(GSTEfilingAuthInfo);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [isGsteFillingEnable, setIsGsteFillingEnable] = useState(false);
  const [gstEfillUserName, setGstEfillUserName] = useState('');
  const [isMutliCurrencyChecked, setIsMutliCurrencyChecked] = useState(false);
  const showSettingsPopup = useAppSelector(selectShowSettings);

  useEffect(() => {
    dispatch(fetchGSTEFilingAuthInfo({}));
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    setContactInfo(contactInfoData);
  }, [contactInfoData]);

  useEffect(() => {
    const updatedState = organisationState;
    const {
      compliance,
      finance,
      addressInfo,
      organisation,
      additionalSettings
    } = updatedState;
    const usaStateOptions: OrganisationReactSelectOption[] = [];

    USstateMockData.forEach((state) => {
      const option: OrganisationReactSelectOption = {
        label: state.name,
        value: state.code,
        searchableKey: `${state.name} ${state.code}`,
        code: state.code
      };
      usaStateOptions.push(option);
    });
    if (tenantDetails.nexusStates) {
      const states: string[] = tenantDetails.nexusStates.split(',');
      const nexusStates: OrganisationReactSelectOption[] = [];
      states.forEach((state) => {
        const usaState = usaStateOptions.find(
          (option: any) => option.value === state
        );
        if (usaState) {
          nexusStates.push(usaState);
        }
      });
      compliance.taxCollectionStates.value = nexusStates;
    }
    setOrganisationState({ ...updatedState });
  }, [tenantDetails]);

  useEffect(() => {
    const updatedState = organisationState;
    const {
      compliance,
      finance,
      addressInfo,
      organisation,
      additionalSettings
    } = updatedState;
    const dateFormatOptions: OrganisationReactSelectOption[] = [];
    const businessTypeOptions: OrganisationReactSelectOption[] = [];
    const roundingMethodOptions: OrganisationReactSelectOption[] = [];
    const multicurrencyOptions: OrganisationReactSelectOption[] = [];
    const numberFormatOptions: OrganisationReactSelectOption[] = [];
    const decimalScaleOptions: OrganisationReactSelectOption[] = [];
    const indianStateOptions: OrganisationReactSelectOption[] = [];
    const vatSchemeOptions: OrganisationReactSelectOption[] = [];
    const usaStateOptions: OrganisationReactSelectOption[] = [];
    const countryOptions: OrganisationReactSelectOption[] = [];
    const locationOptions: OrganisationReactSelectOption[] = [];
    const classOptions: OrganisationReactSelectOption[] = [];
    const timezoneOptions: OrganisationReactSelectOption[] = [];
    const addressInfoNew = addressInfo[0];
    const countryCode: string = tenantDetails.country;
    DATE_FORMAT_LIST.forEach((dateFormat) => {
      const option: OrganisationReactSelectOption = {
        label: dateFormat.format,
        value: dateFormat.serverFormat
      };
      dateFormatOptions.push(option);
    });

    ORG_BUSINESS_TYPE.filter(
      (type) =>
        tenantDetails.country === 'IN' ||
        (tenantDetails.country !== 'IN' &&
          type.id !== 'PRIVATE_LIMITED_COMPANY')
    ).forEach((type) => {
      if (type?.name?.toLowerCase()?.includes('organization')) {
        type = {
          ...type,
          name: type.name?.replace(
            'Organization',
            getCapitalized(localizedText('organisation'))
          )
        };
      }
      const option: OrganisationReactSelectOption = {
        label: type.name,
        value: type.id
      };
      businessTypeOptions.push(option);
    });

    multiCurrencyList.forEach((currency: any) => {
      const option: OrganisationReactSelectOption = {
        label: currency.currencyName,
        value: currency.currencyCode
      };
      multicurrencyOptions.push(option);
    });

    NUMBER_FORMAT_LIST.forEach((format) => {
      const option: OrganisationReactSelectOption = {
        label: format.value,
        value: format.id
      };
      numberFormatOptions.push(option);
    });

    TimezoneList.forEach((format) => {
      const option: OrganisationReactSelectOption = {
        label: format.label,
        value: format.tzCode
      };
      timezoneOptions.push(option);
    });

    Array.from(Array(5).keys()).forEach((x) => {
      if (x > 1) {
        const option: OrganisationReactSelectOption = {
          label: x.toString(),
          value: x.toString(),
          isDisabled: Number(x) < Number(tenantDetails.decimalScale)
        };
        decimalScaleOptions.push(option);
      }
    });

    indianStatesList.forEach((state) => {
      const option: OrganisationReactSelectOption = {
        label: state.name,
        value: state.code,
        code: state.stateCode,
        searchableKey: `${state.name} ${state.stateCode}`
      };
      indianStateOptions.push(option);
    });

    vatSchemesList.forEach((scheme) => {
      const option: OrganisationReactSelectOption = {
        label: scheme.name,
        value: scheme.code
      };
      vatSchemeOptions.push(option);
    });

    USstateMockData.forEach((state) => {
      const option: OrganisationReactSelectOption = {
        label: state.name,
        value: state.code,
        searchableKey: `${state.name} ${state.code}`,
        code: state.code
      };
      usaStateOptions.push(option);
    });

    countryList.forEach((country) => {
      const option: OrganisationReactSelectOption = {
        label: country.name,
        value: country.code
      };
      countryOptions.push(option);
    });

    //  populating default options

    // multicurrency Options
    finance.multicurrencyList.options = multicurrencyOptions;

    // date format Options
    organisation.dateFormat.options = dateFormatOptions;

    // business type Options
    organisation.businessType.options = businessTypeOptions;

    // number format Options
    finance.numberFormat.options = numberFormatOptions;

    // decimal scale Options
    finance.decimalPlace.options = decimalScaleOptions;

    // decimal scale Options
    finance.timezone.options = timezoneOptions;

    // country Options
    addressInfoNew.country.options = countryOptions;

    // gst Registered default date
    compliance.gstRegisteredDate.date = Utility.formatDate(
      new Date(),
      DATE_FORMAT.DD_MM_YYYY
    );

    if (tenantDetails) {
      const tenantDateFormat: string = tenantDetails.dateFormat
        ? tenantDetails.dateFormat.toUpperCase()
        : '';
      const tenantBusinessType: any =
        tenantDetails?.additionalSettings?.BUSINESS_TYPE;
      const tenantDecimalScale: number = tenantDetails.decimalScale;
      const bookBeginningStartDate: string =
        tenantDetails.bookBeginningStartDate;
      const financialStartDate: string = tenantDetails.financialStartDate;
      const reportingCurrency: string = tenantDetails.currency;
      const isMulitcurrencyEnabled: boolean =
        tenantDetails.multicurrencyEnabled;
      const defaultDateFormat = dateFormatOptions.find(
        (dateFormat) => dateFormat.label === tenantDateFormat
      );
      const defaultBusinessType = businessTypeOptions.find(
        (type) => type.label === tenantBusinessType?.label
      );
      const numberFormat = numberFormatOptions.find(
        (format) => format.value === tenantDetails.numberFormat
      );
      const timeZoneFormat = timezoneOptions.find(
        (format) => format.value === tenantDetails.timezone
      );
      const defaultDecimalScale = decimalScaleOptions.find(
        (X) => X.value === tenantDecimalScale.toString()
      );

      // name field
      organisation.name.defaultValue = tenantDetails.name;
      organisation.name.value = tenantDetails.name;
      organisation.name.isDisabled = true;

      // date format field
      if (defaultDateFormat) {
        organisation.dateFormat.defaultValue = defaultDateFormat;
        organisation.dateFormat.value = defaultDateFormat;
      }

      // business type field
      if (defaultBusinessType) {
        organisation.businessType.defaultValue = defaultBusinessType;
        organisation.businessType.value = defaultBusinessType;
      }

      // number format field
      if (numberFormat) {
        finance.numberFormat.defaultValue = numberFormat;
        finance.numberFormat.value = numberFormat;
      }

      // number format field
      if (timeZoneFormat) {
        finance.timezone.defaultValue = timeZoneFormat;
        finance.timezone.value = timeZoneFormat;
      }

      // decimal scale field
      if (defaultDecimalScale) {
        finance.decimalPlace.defaultValue = defaultDecimalScale;
        finance.decimalPlace.value = defaultDecimalScale;
      }

      // multicurrency field
      if (isMulitcurrencyEnabled) {
        finance.multicurrencyCheck.isDisabled = true;
        finance.multicurrencyCheck.isChecked = true;
      }

      // country
      const defaultCountry = countryOptions.find(
        (country) => country.value === tenantDetails.address.country
      );
      if (defaultCountry) {
        addressInfoNew.country.value = defaultCountry;
      }

      // books beginning start date field
      finance.booksBeginningDate.value = bookBeginningStartDate;
      finance.booksBeginningDate.isDisabled = true;

      // financial start date field
      finance.financialStartDate.value = financialStartDate;
      finance.financialStartDate.isDisabled = true;

      // reporting currency field
      finance.reportingCurrency.value = reportingCurrency;
      finance.reportingCurrency.isDisabled = true;

      /* INDIAN COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.IN) {
        compliance.gstRegistered.hidden = false;
        compliance.tcsRegistered.hidden = false;
        compliance.gstin.hidden = false;
        compliance.gstRegisteredDate.hidden = false;
        compliance.tanNumber.hidden = false;
        compliance.panNumber.hidden = false;
        compliance.cinNumber.hidden = false;
        compliance.interestRate.hidden = false;
        compliance.enableInvoice.hidden = false;
        compliance.gstEFiling.hidden = false;
        compliance.gstUsername.hidden = false;

        // is customer gstRegistered ?
        if (tenantDetails.gstRegistered) {
          // gst toggle
          compliance.gstRegistered.isChecked = true;
          // make state mandatory
          addressInfoNew.state.isMandatory = true;

          // make gstin field visible and populate value
          compliance.gstin.value = tenantDetails.gstin;

          // make gstRegisteredDate field visible and populate value
          if (tenantDetails.gstRegistrationDate) {
            compliance.gstRegisteredDate.date =
              tenantDetails.gstRegistrationDate;
          }

          // enableReverseCharge
          if (tenantDetails?.enableReverseCharge) {
            compliance.reverseCharge.isChecked = true;
          }

          // registeredToCompositionScheme
          if (tenantDetails?.registeredToCompositionScheme) {
            compliance.isCompositeScheme.isChecked = true;

            // compositeSchemeOption
            if (tenantDetails?.compositionSchemeType) {
              compliance.compositeSchemeOption.value =
                tenantDetails.compositionSchemeType;
            }
          }

          // trackDigitalServices
          if (tenantDetails?.trackDigitalServices) {
            compliance.digitalService.isChecked = true;
          }

          // sezOrImportExport
          if (tenantDetails?.sezOrImportExport) {
            compliance.sezOverseaTrading.isChecked = true;
          }

          // E-Invoice Now
          if (tenantDetails?.indiaEinvoice) {
            compliance.enableInvoice.isChecked = true;
          }
        }

        // is TCS registered ?
        if (tenantDetails?.tcsEnabled) {
          compliance.tcsRegistered.isChecked = true;
        }

        // tanNumber
        if (tenantDetails?.tanNumberIndia) {
          compliance.tanNumber.value = tenantDetails.tanNumberIndia;
        }

        // panNumber
        if (tenantDetails?.panIndia) {
          compliance.panNumber.value = tenantDetails.panIndia;
        }

        // cinNumber
        if (tenantDetails?.cinIndia) {
          compliance.cinNumber.value = tenantDetails.cinIndia;
        }

        // interest rate
        if (tenantDetails?.tdsPaymentInterestRateIndia) {
          compliance.interestRate.value =
            tenantDetails.tdsPaymentInterestRateIndia;
        }

        // Gst-E-Filing
        if (
          tenantDetails?.indiaGstr &&
          tenantDetails?.indiaGstr !== null &&
          tenantDetails?.indiaGstr
        ) {
          setIsGsteFillingEnable(true);
        } else {
          setIsGsteFillingEnable(false);
        }

        if (tenantDetails?.indiaGstr) {
          compliance.gstEFiling.isChecked = true;
          // indiaGstrUsername
          if (tenantDetails?.indiaGstrUsername) {
            compliance.gstUsername.value = tenantDetails.indiaGstrUsername;
          }
        }
      }

      /* ISRAEL COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.IL) {
        compliance.ilDeductionFileId.hidden = false;
        compliance.ilEngagementType.hidden = false;
        compliance.ilIncomeTaxNo.hidden = false;
        compliance.ilLegalName.hidden = false;
        compliance.ilNationalInsuranceNo.hidden = false;
        compliance.ilParentSubsidiaryName.hidden = false;
        compliance.ilReturnEmail.hidden = false;
        compliance.ilVatRegistrationNo.hidden = false;
        compliance.ilVatCode.hidden = false;
        compliance.ilWithholdingTaxAccount.hidden = false;

        const accList: any[] =
          !Utility.isEmpty(accountList) && accountList?.content?.length
            ? accountList?.content
            : [];
        const ilWithholdingTaxAccountOptions = accList?.map((acc: any) => {
          const option: OrganisationReactSelectOption = {
            label: acc.name,
            value: acc.accountCode
          };
          return option;
        });

        const whtTypeList: any[] =
          !Utility.isEmpty(whTaxes) && whTaxes?.length ? whTaxes : [];
        const ilWhyTypeOptions = whtTypeList?.map((acc: any) => {
          const option: OrganisationReactSelectOption = {
            label: acc.sectionName,
            value: acc.tdsId
          };
          return option;
        });
        compliance.ilWithholdingTaxAccount.options =
          ilWithholdingTaxAccountOptions;

        let selectedAcc: any = { label: '', value: '' };
        if (
          ilWithholdingTaxAccountOptions?.length &&
          tenantDetails.ilWithholdingTaxAccount
        ) {
          selectedAcc = ilWithholdingTaxAccountOptions.find(
            (opt: any) => opt.value === tenantDetails.ilWithholdingTaxAccount
          );
        }
        compliance.ilWithholdingTaxAccount.value = selectedAcc;

        let selectedWhtType: any = { label: '', value: '' };
        compliance.ilWhtType.options = ilWhyTypeOptions ?? [];
        compliance.ilWhtType.value = selectedWhtType;

        const tList =
          !Utility.isEmpty(taxList) && taxList?.length ? taxList : [];
        const ilVatCodeOptions = tList?.map((tax: any) => {
          const option: OrganisationReactSelectOption = {
            label: tax.name,
            value: tax.taxCode
          };
          return option;
        });
        compliance.ilVatCode.options = ilVatCodeOptions;

        let selectedVatCode: any = { label: '', value: '' };
        if (ilVatCodeOptions.length && tenantDetails.ilVatCode) {
          selectedVatCode = ilVatCodeOptions.find(
            (opt: any) => opt.value === tenantDetails.ilVatCode
          );
        }
        compliance.ilVatCode.value = selectedVatCode;

        if (tenantDetails.ilDeductionFileId) {
          compliance.ilDeductionFileId.value = tenantDetails.ilDeductionFileId;
        }
        if (tenantDetails.ilEngagementType) {
          compliance.ilEngagementType.value = tenantDetails.ilEngagementType;
        }
        if (tenantDetails.ilIncomeTaxNo) {
          compliance.ilIncomeTaxNo.value = tenantDetails.ilIncomeTaxNo;
        }
        if (tenantDetails.ilLegalName) {
          compliance.ilLegalName.value = tenantDetails.ilLegalName;
        }
        if (tenantDetails.ilNationalInsuranceNo) {
          compliance.ilNationalInsuranceNo.value =
            tenantDetails.ilNationalInsuranceNo;
        }
        if (tenantDetails.ilParentSubsidiaryName) {
          compliance.ilParentSubsidiaryName.value =
            tenantDetails.ilParentSubsidiaryName;
        }
        if (tenantDetails.ilReturnEmail) {
          compliance.ilReturnEmail.value = tenantDetails.ilReturnEmail;
        }
        if (tenantDetails.ilVatRegistrationNo) {
          compliance.ilVatRegistrationNo.value =
            tenantDetails.ilVatRegistrationNo;
        }
      }

      /* SINGAPORE COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.SG) {
        compliance.gstNo.hidden = false;
        compliance.uen.hidden = false;
        compliance.optInInvoiceNow.hidden = false;
        addressInfoNew.country.isMandatory = true;
        compliance.peppolMarketingOptIn.hidden = false;
        // gst Number
        if (tenantDetails.gstNo) {
          compliance.gstNo.value = tenantDetails.gstNo;
        }

        // uen number
        if (tenantDetails.uenNo) {
          compliance.uen.value = tenantDetails.uenNo;
        }
        // peppolOptIn
        if (tenantDetails.peppolOptIn) {
          compliance.optInInvoiceNow.isChecked = true;
          compliance.peppolId.isMandatory = true;
          compliance.uen.isMandatory = true;
          compliance.peppolId.hidden = false;
          compliance.vendorId.hidden = false;

          // peppol ID
          if (tenantDetails.peppolId) {
            compliance.peppolId.value = tenantDetails.peppolId;
          }

          // vendor Id
          if (tenantDetails.vendorId) {
            compliance.vendorId.value = tenantDetails.vendorId;
          }
        }
        if (tenantDetails?.peppolMarketingOptIn == true) {
          compliance.peppolMarketingOptIn.isChecked = true;
        }
      }

      /* USA COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.US) {
        compliance.donTCollectTax.hidden = false;
        compliance.taxIdNumber.hidden = false;
        addressInfoNew.state.options = usaStateOptions;
        compliance.taxCollectionStates.options = usaStateOptions;

        if (tenantDetails.complianceEnabled) {
          compliance.donTCollectTax.isChecked = false;
          compliance.taxCollectionStates.hidden = false;

          addressInfoNew.state.isMandatory = true;
          addressInfoNew.address.isMandatory = true;
          addressInfoNew.country.isMandatory = true;
          addressInfoNew.postalCode.isMandatory = true;
          compliance.taxIdNumber.isMandatory = true;

          if (tenantDetails.nexusStates) {
            const states: string[] = tenantDetails.nexusStates.split(',');
            const nexusStates: OrganisationReactSelectOption[] = [];
            states.forEach((state) => {
              const usaState = usaStateOptions.find(
                (option) => option.value === state
              );
              if (usaState) {
                nexusStates.push(usaState);
              }
            });
            compliance.taxCollectionStates.value = nexusStates;
          }
        }
        //tax id number
        if (tenantDetails.gstNo) {
          compliance.taxIdNumber.value = tenantDetails.gstNo;
        }
      }

      /* INDONESIA COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.ID) {
        // populating payableAccount Options
        const payableAccountsOptions: OrganisationReactSelectOption[] = [];
        payableAccounts.forEach((account: any) => {
          if (account.status === STATUS_TYPE.ACTIVE) {
            let option: OrganisationReactSelectOption = {
              label: account.name,
              value: account.code
            };
            payableAccountsOptions.push(option);
          }
        });

        compliance.isExciseDuty.hidden = false;
        compliance.npwpNumber.hidden = false;
        compliance.exciseAccount.options = payableAccountsOptions;
        compliance.exciseAccount.value = payableAccountsOptions[0];

        // isExciseDuty
        if (tenantDetails.exciseApplicable) {
          compliance.exciseAccount.hidden = false;
          compliance.isExciseDuty.isChecked = true;
          const exciseAccount = payableAccountsOptions.find(
            (account) => account.value === tenantDetails.exciseAccount
          );
          if (exciseAccount) {
            compliance.exciseAccount.value = exciseAccount;
          }
        }

        // NPWP Number
        if (tenantDetails.npwpNo) {
          compliance.npwpNumber.value = formatNPWPNumber(tenantDetails.npwpNo);
        }
      }

      /* MALAYSIA COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.MY) {
        compliance.optEInvoice.hidden = false;
        compliance.gstNumberMy.hidden = false;
        compliance.businessRegistrationNumber.hidden = false;
        compliance.serviceTaxNumber.hidden = false;
        compliance.salesTaxNumber.hidden = false;
        compliance.isTaxExempt.hidden = false;
        compliance.expiryDate.date = Utility.formatDate(
          new Date(),
          DATE_FORMAT.DD_MM_YYYY
        );

        const exemptionCriteriaOptions = EXEMPTION_CRITERIA_MY.map(
          (criteria) => {
            const option: OrganisationReactSelectOption = {
              label: criteria.name,
              value: criteria.exemptionId
            };
            return option;
          }
        );
        compliance.exemptCriteria.options = exemptionCriteriaOptions;
        compliance.exemptCriteria.value = exemptionCriteriaOptions[0];

        // businessRegistrationNumber
        if (tenantDetails.businessRegistrationNo) {
          compliance.businessRegistrationNumber.value =
            tenantDetails.businessRegistrationNo;
        }

        // serviceTaxNumber
        if (tenantDetails.serviceTaxRegistrationNo) {
          compliance.serviceTaxNumber.value =
            tenantDetails.serviceTaxRegistrationNo;
        }

        // salesTaxNumber
        if (tenantDetails.salesTaxRegistrationNo) {
          compliance.salesTaxNumber.value =
            tenantDetails.salesTaxRegistrationNo;
        }

        // isTaxExempt
        if (tenantDetails.exemption) {
          compliance.isTaxExempt.isChecked = true;
          compliance.exemptNumber.hidden = false;
          compliance.exemptNumber.isMandatory = true;
          compliance.expiryDate.hidden = false;
          compliance.isExemptCertificate.hidden = false;

          // exemptionNumber
          if (tenantDetails.exemptionNo) {
            compliance.exemptNumber.value = tenantDetails.exemptionNo;
          }

          // expiryDate
          if (tenantDetails.expiryDate) {
            compliance.expiryDate.date = tenantDetails.expiryDate;
          }

          // exemptionCerificate
          if (tenantDetails.exemptionCerificate) {
            compliance.isExemptCertificate.isChecked = true;
            compliance.exemptCriteria.hidden = false;

            // exemptionCriteria reason
            const exemptionCriteria = exemptionCriteriaOptions.find(
              (option) => option.value === tenantDetails.exemptionCriteria
            );
            if (exemptionCriteria) {
              compliance.exemptCriteria.value = exemptionCriteria;
            }
          }
        }

        compliance.gstNumberMy.value = alternateTenantDetails?.gstNo
          ? alternateTenantDetails?.gstNo
          : '';
        compliance.peppolIdMy.value = alternateTenantDetails?.peppolId
          ? alternateTenantDetails?.peppolId
          : '';
          compliance.optEInvoice.isChecked = alternateTenantDetails?.peppolOptIn
          ? alternateTenantDetails?.peppolOptIn
          : false;
        if (compliance.optEInvoice.isChecked){
          compliance.peppolIdMy.hidden = false;
          compliance.peppolIdMy.isMandatory = true;
        }
      }

      /* UAE COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.AE) {
        compliance.vatNumber.hidden = false;
        compliance.isDesignatedZoneBusiness.hidden = false;
        compliance.isContactsOutsideUAE.hidden = false;
        compliance.isExciseDutyUAE.hidden = false;

        // populating payableAccount Options
        const payableAccountsOptions: OrganisationReactSelectOption[] = [];
        payableAccounts.forEach((account: any) => {
          if (account.status === STATUS_TYPE.ACTIVE) {
            let option: OrganisationReactSelectOption = {
              label: account.name,
              value: account.code
            };
            payableAccountsOptions.push(option);
          }
        });

        compliance.taxOptionForContactOutsideUAE.options =
          payableAccountsOptions;
        compliance.exciseAccountUAE.options = payableAccountsOptions;
        compliance.taxOptionForContactOutsideUAE.value =
          payableAccountsOptions[0];
        compliance.exciseAccountUAE.value = payableAccountsOptions[0];

        // VAT Number
        if (tenantDetails.vatNoUAE) {
          compliance.vatNumber.value = tenantDetails.vatNoUAE;
        }

        // isDesignatedZoneBusiness
        if (tenantDetails.isDesignatedZone) {
          compliance.isDesignatedZoneBusiness.isChecked = true;
        }

        // contact outside UAE
        if (tenantDetails.outsideContact) {
          compliance.isContactsOutsideUAE.isChecked = true;
          compliance.taxOptionForContactOutsideUAE.hidden = false;
          const taxOptionForContactOutsideUAE = payableAccountsOptions.find(
            (account) => account.value === tenantDetails.rcmAccount
          );
          // taxOptionForContactOutsideUAE
          if (taxOptionForContactOutsideUAE) {
            compliance.taxOptionForContactOutsideUAE.value =
              taxOptionForContactOutsideUAE;
          }
        }

        // Activate Excise
        if (tenantDetails.exciseApplicable) {
          compliance.isExciseDutyUAE.isChecked = true;
          compliance.exciseAccountUAE.hidden = false;
          const exciseDutyOptionUAE = payableAccountsOptions.find(
            (account) => account.value === tenantDetails.exciseAccount
          );
          // exciseDutyOptionUAE
          if (exciseDutyOptionUAE) {
            compliance.exciseAccountUAE.value = exciseDutyOptionUAE;
          }
        }
      }

      /* Philippines COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.PH) {
        compliance.vatNumberPH.hidden = false;
        compliance.vatNumberPH.value = tenantDetails.vatNoPhilippines;
      }

      /* Canada COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.CA) {
        compliance.gstNumberCA.hidden = false;
        compliance.businessNumber.hidden = false;
        compliance.gstNumberCA.value = tenantDetails.gstNumber;
        compliance.businessNumber.value = tenantDetails.canadianBusinessNo;
      }

      /* UK COMPLIANCE FIELDS */
      if (tenantDetails?.country === COUNTRY_CODES.UK) {
        compliance.ukBusinessRegistrationNumber.hidden = false;
        compliance.ukBusinessRegistrationNumber.value =
          tenantDetails?.businessRegistrationNoUK;

        compliance.vatIdentificationNumber.hidden = false;
        compliance.vatIdentificationNumber.value =
          tenantDetails?.vatIdentificationNumberUK;

        compliance.vatScheme.hidden = false;
        compliance.vatScheme.options = vatSchemeOptions;
        compliance.vatScheme.value = vatSchemeOptions.find((vso: any) => {
          return vso.value === tenantDetails?.vatSchemeUK;
        }) ?? { label: UK_VAT_SCHEME[0].name, value: UK_VAT_SCHEME[0].code };

        compliance.makingTaxDigital.hidden = false;

        let rows =
          tenantDetails?.flatRatePercentageListUK?.map(
            (flatRateItem: any, index: number) => {
              return {
                ...flatRateItem,
                applyFrom: DateFormatService.getDateFromStr(
                  flatRateItem?.applyFrom,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                index: index + 1
              };
            }
          ) ?? [];

        setUKComplianceVatSchemeRows([...rows]);
      }

      if (addressInfoNew) {
        // default country based on tenant
        const defaultCountryOption = countryOptions.find(
          (option) => option.value === countryCode
        );
        if (defaultCountryOption?.value === COUNTRY_CODES.IN) {
          addressInfoNew.state.options = indianStateOptions;
          addressInfoNew.state.value = { label: '', value: '' };
        }
        addressInfoNew.country = {
          ...addressInfoNew.country,
          options: [...countryOptions],
          defaultValue: defaultCountryOption,
          value: defaultCountryOption
            ? defaultCountryOption
            : { label: 'India', value: COUNTRY_CODES.IN }
        };

        // based on tenant info
        addressInfoNew.countryNumberOptions.options = [...countryList];
        const phoneNumberFormatBasedOnTenant = countryList.find(
          (country) => country.code === countryCode
        );
        if (phoneNumberFormatBasedOnTenant) {
          addressInfoNew.countryNumberOptions.value =
            phoneNumberFormatBasedOnTenant;
        }

        updatedState.addressInfo[0] = addressInfoNew;

        if (
          tenantDetails?.billingAddresses ||
          tenantDetails?.shippingAddresses ||
          tenantDetails?.ilReturnAddress
        ) {
          let tmpBillAddress: any = {};
          let tmpShipAddress: any = {};
          if (tenantDetails.billingAddresses) {
            tmpBillAddress = [...tenantDetails.billingAddresses];
            // tmpBillAddress = tmpBillAddress.reverse();
          }
          if (tenantDetails.shippingAddresses) {
            tmpShipAddress = [...tenantDetails.shippingAddresses];
            // tmpShipAddress = tmpShipAddress.reverse();
          }
          const activeBillingAddressIndex: number =
            tmpBillAddress?.findIndex((address: any) => address.preferred) | 0;
          const activeShippingAddressIndex: number =
            tmpShipAddress?.findIndex((address: any) => address.preferred) | 0;
          const activeReturnAddressIndex: number =
            tenantDetails?.ilReturnAddress?.findIndex(
              (address: any) => address.preferred
            ) | 0;

          updatedState.activeBillingAddressIndex = activeBillingAddressIndex;
          updatedState.activeShippingAddressIndex = activeShippingAddressIndex;
          updatedState.activeReturnAddressIndex = activeReturnAddressIndex;
          const billingAddressList: OrgAddressInfo[] = [];
          let addressList: any[] = [];
          if (tenantDetails?.billingAddresses?.length > 0) {
            addressList = tenantDetails?.billingAddresses;
          } else if (tenantDetails?.ilReturnAddress?.length > 0) {
            addressList = tenantDetails?.ilReturnAddress;
          } else {
            addressList = tenantDetails?.shippingAddresses;
          }
          addressList?.forEach((addressData: any) => {
            let billingAddress: OrgAddressInfo = deepClone(
              updatedState.addressInfo[0]
            );
            // address
            if (addressData.contactName) {
              billingAddress.contactName.value = addressData.contactName;
            }

            if (addressData.address1) {
              billingAddress.address.value = addressData.address1;
            }

            if (addressData.address2) {
              billingAddress.address2.value = addressData.address2;
            }

            // state
            let selectedIndianState = indianStateOptions.find(
              (option) => option.label === addressData.state
            );

            let selectedAmericanState = usaStateOptions.find(
              (option) => option.label === addressData.state
            );

            const getOptions = () => {
              switch (addressData.country) {
                case COUNTRY_CODES.IN:
                  return indianStateOptions;

                case COUNTRY_CODES.US:
                  return usaStateOptions;

                default:
                  return [];
              }
            };

            if (selectedIndianState) {
              billingAddress.state = {
                ...billingAddress.state,
                options: [...indianStateOptions],
                defaultValue: selectedIndianState,
                value: selectedIndianState
              };
            } else if (selectedAmericanState) {
              billingAddress.state = {
                ...billingAddress.state,
                options: [...usaStateOptions],
                defaultValue: selectedAmericanState,
                value: selectedAmericanState
              };
            } else {
              billingAddress.state = {
                ...billingAddress.state,
                options: getOptions(),
                defaultValue: {
                  label: addressData.state,
                  value: addressData.state
                },
                value: {
                  label: addressData.state,
                  value: addressData.state
                }
              };
            }

            // country
            if (addressData.country) {
              let selectedOption = countryOptions.find(
                (option) => option.value === addressData.country
              );

              billingAddress.country = {
                ...billingAddress.country,
                options: [...countryOptions],
                defaultValue: selectedOption,
                value: selectedOption
                  ? selectedOption
                  : { label: 'India', value: COUNTRY_CODES.IN }
              };
            }

            // city

            if (addressData.city) {
              if (addressData.country === COUNTRY_CODES.IN) {
                const cityOptions =
                  INDIAN_STATES_MOCK_DATA?.find(
                    (state) => state.stateCode === selectedIndianState?.value
                  )?.cities || [];
                billingAddress.city.options = cityOptions?.map((city) => ({
                  label: city,
                  value: city
                }));

                billingAddress.city.value = {
                  label: addressData.city,
                  value: addressData.city
                };
              } else {
                billingAddress.city.value = {
                  value: addressData.city,
                  label: addressData.city
                };
              }
            }

            // postal code
            if (addressData.postalCode) {
              billingAddress.postalCode.value = addressData.postalCode;
            }

            // address contact number
            if (updatedState.addressInfo) {
              // based on props
              if (addressData?.mobileNumber) {
                const contactNumberDetails = parsePhoneNumberFromString(
                  addressData.mobileNumber
                );
                if (contactNumberDetails) {
                  if (contactNumberDetails?.country) {
                    const phoneNumberFormatBasedOnProps = countryList.find(
                      (country) => country.code === contactNumberDetails.country
                    );

                    if (phoneNumberFormatBasedOnProps) {
                      billingAddress.countryNumberOptions.value =
                        phoneNumberFormatBasedOnProps;
                      billingAddress.contactNumber.value =
                        contactNumberDetails.nationalNumber;
                    }
                  }
                  if (addressData.mobileNumber?.startsWith('+1758')) {
                    billingAddress.countryNumberOptions.value =
                      countryList.find(
                        (country: any) => country.code === 'LC'
                      ) || null;
                  }
                  if (addressData.mobileNumber?.startsWith('+1')) {
                    billingAddress.countryNumberOptions.value =
                      countryList.find(
                        (country: any) => country.code === 'US'
                      ) || null;
                  }
                  if (addressData.mobileNumber?.startsWith('+7')) {
                    billingAddress.countryNumberOptions.value =
                      countryList.find(
                        (country: any) => country.code === 'TO'
                      ) || null;
                  }
                  billingAddress.contactNumber.value =
                    contactNumberDetails.nationalNumber;
                } else {
                  const phoneNumberFormatBasedOnProps: any = countryList.find(
                    (country) => country.code === countryCode
                  );
                  if (phoneNumberFormatBasedOnProps) {
                    billingAddress.countryNumberOptions.value =
                      phoneNumberFormatBasedOnProps;
                    billingAddress.contactNumber.value =
                      addressData.mobileNumber;
                  }
                }
              }
            }

            // email
            if (billingAddress) {
              if (addressData?.emailId) {
                const emailId: string = addressData.emailId;
                billingAddress.email.value = emailId;
              }
            }

            // address custom fields
            billingAddress.customFields = addressData?.customFields ?? [];

            billingAddressList.push(billingAddress);
          });
          updatedState.addressInfo = billingAddressList;
        }
      }

      if (additionalSettings && tenantDetails?.additionalSettings) {
        const locations = [
          'Business',
          'Department',
          'Division',
          'Location',
          'Property',
          'Store',
          'Territory'
        ];
        // const classes = [
        //   'One to entire transaction',
        //   'One to each row in transaction'
        // ];
        const classes: any = [
          {
            key: 'TRANSACTION',
            value: 'One to entire transaction'
          },
          {
            key: 'ROW',
            value: 'One to each row in transaction'
          }
        ];
        const roundingOffList: any = [
          {
            key: 'ROUND_OFF',
            value: 'Default Round Off'
          },
          {
            key: 'ROUND_UP',
            value: 'Round Up'
          },
          {
            key: 'ROUND_DOWN',
            value: 'Round Down'
          }
        ];
        locations.forEach((location) => {
          const option: OrganisationReactSelectOption = {
            label: location,
            value: location
          };
          locationOptions.push(option);
        });
        classes.forEach((classItem: any) => {
          const option: OrganisationReactSelectOption = {
            label: classItem.value,
            value: classItem.key
          };
          classOptions.push(option);
        });

        roundingOffList.forEach((type: any) => {
          const option: OrganisationReactSelectOption = {
            label: type.value,
            value: type.key
          };
          roundingMethodOptions.push(option);
        });

        additionalSettings.LOCATION.locationLabel.options = locationOptions;
        additionalSettings.LOCATION.locationLabel.value = {
          label: tenantDetails?.additionalSettings?.LOCATION?.locationLabel,
          value: tenantDetails?.additionalSettings?.LOCATION?.locationLabel
        };
        additionalSettings.LOCATION.trackLocation.isChecked =
          tenantDetails?.additionalSettings?.LOCATION?.trackLocation;
        additionalSettings.LOCATION.mandatory.isChecked =
          tenantDetails?.additionalSettings?.LOCATION?.mandatory;

        additionalSettings.CLASS.trackClasses.isChecked =
          tenantDetails?.additionalSettings?.CLASS?.trackClasses;
        additionalSettings.CLASS.mandatory.isChecked =
          tenantDetails?.additionalSettings?.CLASS?.mandatory;
        additionalSettings.CLASS.warnOnClassNotSelected.isChecked =
          tenantDetails?.additionalSettings?.CLASS?.warnOnClassNotSelected;

        additionalSettings.CLASS.assignClasses.options = classOptions;
        additionalSettings.CLASS.assignClasses.value = {
          label:
            tenantDetails?.additionalSettings?.CLASS?.assignClasses?.toUpperCase() ===
            'TRANSACTION'
              ? classes[0]?.value
              : classes[1]?.value,
          value: tenantDetails?.additionalSettings?.CLASS?.assignClasses
        };
        additionalSettings.ROUND_OFF.autoRoundOff =
          tenantDetails?.additionalSettings?.ROUND_OFF?.autoRoundOff;
        additionalSettings.ROUND_OFF.roundingMethod.options =
          roundingMethodOptions;
        additionalSettings.ROUND_OFF.roundingMethod.value = {
          label:
            tenantDetails?.additionalSettings?.ROUND_OFF?.roundingMethod?.toUpperCase() ===
            'ROUND_OFF'
              ? roundingOffList[0]?.value
              : tenantDetails?.additionalSettings?.ROUND_OFF?.roundingMethod?.toUpperCase() ===
                'ROUND_UP'
              ? roundingOffList[1]?.value
              : tenantDetails?.additionalSettings?.ROUND_OFF?.roundingMethod?.toUpperCase() ===
                'ROUND_DOWN'
              ? roundingOffList[2]?.value
              : roundingOffList[0]?.value,
          value: tenantDetails?.additionalSettings?.ROUND_OFF?.roundingMethod
        };
      }
    }

    if (subscriptionDetails?.PlanName?.toLowerCase() === PLANS.STARTUP) {
      updatedState.finance.multicurrencyList.hidden = true;
    }

    // activeTab
    updatedState.activeTab = props.activeTab;

    updateConfig();
    setOrganisationState({ ...updatedState });
    dispatch(fetchContactInfo());

    return () => {
      // cleanup
    };
  }, []);

  useEffect(() => {
    setEwayBillData();
  }, [data]);

  useEffect(() => {
    const updatedState = organisationState;
    const { compliance } = updatedState;

    if (!Utility.isEmpty(authGstInfo) && authGstInfo?.auth_token !== null) {
      compliance.gstEFiling.isChecked = true;
      compliance.gstUsername.value = authGstInfo.userName;
      setGstEfillUserName(authGstInfo?.userName);
    } else {
      compliance.gstEFiling.isChecked = false;
      compliance.gstUsername.value = authGstInfo.userName;
    }

    setOrganisationState(updatedState);
  }, [authGstInfo]);

  useEffect(() => {
    if (Utility.isEmpty(eWayAuthInfoData)) {
      dispatch(fetchEWayBillAuthInfo({}));
    }
    if (Utility.isEmpty(eInvoiceUserNameData)) {
      dispatch(fetchEInvoiceUserName());
    }
  }, []);

  const setEwayBillData = () => {
    const currState = module;
    if (data && data.hiddenApps) {
      var str = data.hiddenApps;
      var str_array = str.split(',');
      for (var i = 0; i < str_array.length; i++) {
        str_array[i] = str_array[i].replace(/^\s*/, '').replace(/\s*$/, '');
        if (str_array[i] === 'E_WAY') {
          currState.ewayBill = false;
        }
      }
    }
    setModule({ ...currState });
  };

  useEffect(() => {
    registerInteractions();
  });

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.UPDATE_ORGANISATION,
      data: () => {
        showAlert(
          'Warning',
          'Changes in the compliance settings may impact existing transactions, if any. Do you want to save changes?',
          [
            {
              title: 'Cancel',
              className: 'bg-gray1 border-m mr-r',
              onClick: () => {}
            },
            {
              title: 'Save',
              className: 'bg-button text-white',
              onClick: () => {
                updateOrgSetting();
              }
            }
          ]
        );
      }
    });
  };

  const updateConfig = () => {
    let config = [...ukComplianceVatSchemeColumns];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'flatRatePercentage':
          conf.width = 220;
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(
              obj?.rowData?.flatRatePercentage ?? 0
            );
          };
          return;
        case 'applyFrom':
          conf.width = 220;
          conf.formatter = (obj: any) => {
            return DateFormatService.getDateStrFromDate(
              obj?.rowData?.applyFrom
            );
          };
          return;
        default:
          break;
      }
    });

    setUKComplianceVatSchemeColumns([...config]);
  };

  const formUpdated = (
    value: string,
    fieldName: ORGANISATION_FORM_FIELD_NAME,
    index: number = 0
  ) => {
    const updatedState = organisationState;
    const addressSection: OrgAddressInfo = deepClone(
      updatedState['addressInfo'][index]
    );
    switch (fieldName) {
      case ORGANISATION_FORM_FIELD_NAME.CONTACT_NAME:
        addressSection.contactName.hasError = false;
        if (!value && addressSection.contactName.isMandatory) {
          addressSection.contactName.hasError = true;
          addressSection.contactName.errorMsg = 'Contact Name is mandatory';
        }
        addressSection.contactName.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.MOBILE_NUMBER:
        addressSection.contactNumber.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.EMAIL:
        if (addressSection.email) addressSection.email.hasError = false;
        if (addressSection.email && !Utility.isValidEmail(value)) {
          addressSection.email.hasError = true;
          addressSection.email.errorMsg = 'Email is not valid';
        }
        if (addressSection.email) addressSection.email.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.ADDRESS:
        addressSection.address.hasError = false;
        if (!value && addressSection.address.isMandatory) {
          addressSection.address.hasError = true;
          addressSection.address.errorMsg = 'Address is mandatory';
        }
        addressSection.address.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.ADDRESS2:
        addressSection.address2.hasError = false;
        if (!value && addressSection.address2.isMandatory) {
          addressSection.address2.hasError = true;
          addressSection.address2.errorMsg = 'Address is mandatory';
        }
        addressSection.address2.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.INPUT_FIELD_CITY:
        addressSection.city.value = { label: value, value: value };
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.INPUT_FIELD_STATE:
        addressSection.state.value = { label: value, value: value };
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.POSTAL_CODE:
        addressSection.postalCode.hasError = false;
        if (!value && addressSection.postalCode.isMandatory) {
          addressSection.postalCode.hasError = true;
          addressSection.postalCode.errorMsg = 'Postal Code is mandatory';
        }
        addressSection.postalCode.value = value;
        updatedState['addressInfo'][index] = addressSection;
        break;
      case ORGANISATION_FORM_FIELD_NAME.GSTIN:
        const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
        updatedState.compliance.gstin.hasError = false;
        if (!gstRegex.test(value) && value !== IRP_CRED_VALID_GSTIN) {
          updatedState.compliance.gstin.hasError = true;
          updatedState.compliance.gstin.errorMsg = 'GSTIN is not valid';
        }
        updatedState.compliance.gstin.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.TAN_NUMBER:
        updatedState.compliance.tanNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.PAN_NUMBER:
        if (updatedState.compliance.panNumber) {
          updatedState.compliance.panNumber.hasError = false;
          updatedState.compliance.panNumber.errorMsg = '';
        }
        const panRegex = new RegExp(PAN_REGEX_PATTERN);
        updatedState.compliance.panNumber.hasError = false;
        if (updatedState.compliance.panNumber && !panRegex.test(value)) {
          updatedState.compliance.panNumber.hasError = true;
          updatedState.compliance.panNumber.errorMsg = 'PAN is not valid';
        }
        updatedState.compliance.panNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.CIN_NUMBER:
        if (updatedState.compliance.cinNumber) {
          updatedState.compliance.cinNumber.hasError = false;
          updatedState.compliance.cinNumber.errorMsg = '';
        }
        updatedState.compliance.cinNumber.value = value;
        const cinRegex = new RegExp(CIN_REGEX_PATTERN);
        updatedState.compliance.cinNumber.hasError = false;
        if (updatedState.compliance.cinNumber && !cinRegex.test(value)) {
          updatedState.compliance.cinNumber.hasError = true;
          updatedState.compliance.cinNumber.errorMsg = 'CIN is not valid';
        }
        updatedState.compliance.cinNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.INTEREST_RATE:
        updatedState.compliance.interestRate.hasError = false;
        if (isNaN(Number(value))) {
          updatedState.compliance.interestRate.hasError = true;
          updatedState.compliance.interestRate.errorMsg =
            'Interest Rate is not valid';
        }
        updatedState.compliance.interestRate.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.GST_E_FILING_USERNAME:
        updatedState.compliance.gstUsername.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.UEN_NUMBER:
        updatedState.compliance.uen.value = value;
        updatedState.compliance.uen.hasError = false;
        if (value.toString().trim().length === 0) {
          updatedState.compliance.uen.hasError = true;
          updatedState.compliance.uen.errorMsg =
            'UEN cannot start with whitespace';
        }
        // trigger peppol validation
        triggerPeppolIdValidation(
          updatedState,
          updatedState.compliance.peppolId.value.toString()
        );
        break;
      case ORGANISATION_FORM_FIELD_NAME.PEPPOL_ID:
        updatedState.compliance.peppolId.value = value;
        updatedState.compliance.peppolId.hasError = false;
        triggerPeppolIdValidation(updatedState, value.toString());
        break;
      case ORGANISATION_FORM_FIELD_NAME.PEPPOL_ID_MY:
        updatedState.compliance.peppolIdMy.value = value;
        updatedState.compliance.peppolIdMy.hasError = false;
        break;
      case ORGANISATION_FORM_FIELD_NAME.GST_NO:
        updatedState.compliance.gstNo.value = value;
        updatedState.compliance.gstNo.hasError = false;
        if (value.toString().trim().length === 0) {
          updatedState.compliance.gstNo.hasError = true;
          updatedState.compliance.gstNo.errorMsg = 'GST No is required';
        } else if (value.toString().trim().length > 15) {
          updatedState.compliance.gstNo.hasError = true;
          updatedState.compliance.gstNo.errorMsg = 'Max 15 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.GST_NO_MY:
        updatedState.compliance.gstNumberMy.value = value;
        updatedState.compliance.gstNumberMy.hasError = false;
        if (value.toString().trim().length === 0) {
          updatedState.compliance.gstNumberMy.hasError = true;
          updatedState.compliance.gstNumberMy.errorMsg = 'GST No is required';
        } else if (value.toString().trim().length > 15) {
          updatedState.compliance.gstNumberMy.hasError = true;
          updatedState.compliance.gstNumberMy.errorMsg =
            'Max 15 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.VENDOR_ID:
        updatedState.compliance.vendorId.value = value;
        updatedState.compliance.vendorId.hasError = false;
        if (value.toString() === '') {
          updatedState.compliance.vendorId.hasError = true;
          updatedState.compliance.vendorId.errorMsg = 'Incorrect Vendor Id';
        }

        break;
      case ORGANISATION_FORM_FIELD_NAME.TAX_ID_NUMBER:
        updatedState.compliance.taxIdNumber.value = value;
        updatedState.compliance.taxIdNumber.hasError = false;
        if (
          updatedState.compliance.taxIdNumber.value.toString().trim().length !==
          9
        ) {
          updatedState.compliance.taxIdNumber.hasError = true;
          updatedState.compliance.taxIdNumber.errorMsg =
            'EIN number must be 9 characters';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.NPWP_NUMBER:
        let num = value.toString().replace(/[^0-9]/g, '');
        if (num.length <= 15) {
          updatedState.compliance.npwpNumber.value = formatNPWPNumber(value);
          updatedState.compliance.npwpNumber.hasError = false;
          if (num.length < 15) {
            updatedState.compliance.npwpNumber.hasError = true;
            updatedState.compliance.npwpNumber.errorMsg =
              'NPWP Number must be 15 digits long';
          }
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.BUSINESS_REGISTRATION_NUMBER:
        updatedState.compliance.businessRegistrationNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.UK_BUSINESS_REGISTRATION_NUMBER:
        if (value.length > 20) {
          showToast('Max 20 characters are allowed', TOAST_TYPE.FAILURE);
        } else {
          updatedState.compliance.ukBusinessRegistrationNumber.value = value;
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.VAT_IDENTIFICATION_NUMBER:
        if (value.length > 20) {
          showToast('Max 20 characters are allowed', TOAST_TYPE.FAILURE);
        } else {
          updatedState.compliance.vatIdentificationNumber.value = value;
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.SALES_TAX_NUMBER:
        updatedState.compliance.salesTaxNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.SERVICE_TAX_NUMBER:
        updatedState.compliance.serviceTaxNumber.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.EXEMPTION_NUMBER:
        updatedState.compliance.exemptNumber.value = value;
        updatedState.compliance.exemptNumber.hasError = false;
        if (!value) {
          updatedState.compliance.exemptNumber.hasError = true;
          updatedState.compliance.exemptNumber.errorMsg =
            'Exemption Number is required';
        } else if (value.toString().length > 25) {
          updatedState.compliance.exemptNumber.hasError = true;
          updatedState.compliance.exemptNumber.errorMsg =
            'Max 25 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.VAT_NUMBER:
        updatedState.compliance.vatNumber.hasError = false;
        updatedState.compliance.vatNumber.value = value;
        if (isNaN(Number(value))) {
          updatedState.compliance.vatNumber.hasError = true;
          updatedState.compliance.vatNumber.errorMsg = 'Only Numbers Allowed';
        } else if (value.toString().trim().length > 15) {
          updatedState.compliance.vatNumber.hasError = true;
          updatedState.compliance.vatNumber.errorMsg = 'Max 15 Digits Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.VAT_NUMBER_PH:
        updatedState.compliance.vatNumberPH.hasError = false;
        updatedState.compliance.vatNumberPH.value = value;
        if (value.toString().trim().length > 15) {
          updatedState.compliance.vatNumberPH.hasError = true;
          updatedState.compliance.vatNumberPH.errorMsg =
            'Max 15 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.GST_NUMBER_CA:
        updatedState.compliance.gstNumberCA.hasError = false;
        updatedState.compliance.gstNumberCA.value = value;
        if (value.toString().trim().length > 20) {
          updatedState.compliance.gstNumberCA.hasError = true;
          updatedState.compliance.gstNumberCA.errorMsg =
            'Max 20 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.BUSINESS_NUMBER:
        updatedState.compliance.businessNumber.hasError = false;
        updatedState.compliance.businessNumber.value = value;
        if (value.toString().trim().length > 20) {
          updatedState.compliance.businessNumber.hasError = true;
          updatedState.compliance.businessNumber.errorMsg =
            'Max 20 Characters Allowed';
        }
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_DEDUCTION_FILE_ID:
        updatedState.compliance.ilDeductionFileId.hasError = false;
        updatedState.compliance.ilDeductionFileId.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_INCOME_TAX_NO:
        updatedState.compliance.ilIncomeTaxNo.hasError = false;
        updatedState.compliance.ilIncomeTaxNo.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_ENGAGEMENT_TYPE:
        updatedState.compliance.ilEngagementType.hasError = false;
        updatedState.compliance.ilEngagementType.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_LEGAL_NAME:
        updatedState.compliance.ilLegalName.hasError = false;
        updatedState.compliance.ilLegalName.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_NATIONAL_INSURANCE_NO:
        updatedState.compliance.ilNationalInsuranceNo.hasError = false;
        updatedState.compliance.ilNationalInsuranceNo.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_PARENT_SUBSIDIARY_NAME:
        updatedState.compliance.ilParentSubsidiaryName.hasError = false;
        updatedState.compliance.ilParentSubsidiaryName.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_VAT_REGISTRATION_NO:
        updatedState.compliance.ilVatRegistrationNo.hasError = false;
        updatedState.compliance.ilVatRegistrationNo.value = value;
        break;
      case ORGANISATION_FORM_FIELD_NAME.IL_RETURN_EMAIL:
        updatedState.compliance.ilReturnEmail.hasError = false;
        updatedState.compliance.ilReturnEmail.value = value;
        if (!EMAIL_PATTERN.test(value.toString().trim())) {
          updatedState.compliance.ilReturnEmail.hasError = true;
          updatedState.compliance.ilReturnEmail.errorMsg = 'Invalid email id';
        }
        break;
      default:
        break;
    }
    setOrganisationState({ ...updatedState });
  };

  const multiCurrencySelected = (options: number[]) => {
    const updatedState = organisationState;
    updatedState.finance.multicurrencyList.value = options;
    setOrganisationState({ ...updatedState });
  };

  const multiUsaStates = (
    options: OptionsType<ReactSelectOptionsType<string, string>>
  ) => {
    const updatedState = organisationState;
    updatedState.compliance.taxCollectionStates.value = options;
    setOrganisationState({ ...updatedState });
  };

  const optionSelected = (
    option: OrganisationReactSelectOption | null,
    fieldName:
      | ORGANISATION_FORM_FIELD_NAME
      | ORGANISATION_PROFILE_FIELDS_NAME
      | ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME,
    index: number = 0
  ) => {
    const updatedState = organisationState;
    const addressSection: OrgAddressInfo = deepClone(
      updatedState['addressInfo'][index]
    );
    if (option) {
      switch (fieldName) {
        case ORGANISATION_FORM_FIELD_NAME.IL_WITHHOLDING_TAX_ACCOUNT:
          updatedState.compliance.ilWithholdingTaxAccount.value = option;
          break;
        case ORGANISATION_FORM_FIELD_NAME.IL_WHT_TYPE:
          updatedState.compliance.ilWhtType.value = option;
          break;
        case ORGANISATION_FORM_FIELD_NAME.IL_VAT_CODE:
          updatedState.compliance.ilVatCode.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.DATE_FORMAT:
          updatedState.organisation.dateFormat.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.BUSINESS_TYPE:
          updatedState.organisation.businessType.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.DECIMAL_PLACE:
          if (Number(option.value) < tenantDetails.decimalScale) {
            const errorMsg = `Decimal place should be greater than or equal to ${tenantDetails.decimalScale}`;
            showAlert('Error!', errorMsg);
          } else {
            if (Number(option.value) !== tenantDetails.decimalScale) {
              showAlert(
                'Alert!',
                `Please note after changing decimal setting you will not able to revert to previous decimal setting`
              );
            }
            updatedState.finance.decimalPlace.value = option;
          }
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.NUMBER_FORMAT:
          updatedState.finance.numberFormat.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.TIMEZONE:
          updatedState.finance.timezone.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.DROPDOWN_CITY:
          addressSection.city.value = option;
          updatedState['addressInfo'][index] = addressSection;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.DROPDOWN_STATE:
          addressSection.state.value = option;
          addressSection.state.hasError = false;
          if (!option && addressSection.state.isMandatory) {
            addressSection.postalCode.hasError = true;
            addressSection.postalCode.errorMsg = 'State is mandatory';
          }
          const state = INDIAN_STATES_MOCK_DATA.find(
            (state) => state.name === option?.label
          );
          if (state) {
            const cityOptions = state.cities?.map((city) => ({
              label: city,
              value: city
            }));
            addressSection.city.options = cityOptions;
            addressSection.city.value = {
              label: cityOptions?.[1]?.label,
              value: cityOptions?.[1]?.value
            };
          }
          updatedState['addressInfo'][index] = addressSection;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.COUNTRY:
          if (option?.value === COUNTRY_CODES.IN) {
            const indianStatesOptions: ReactOptionType[] = indianStatesList.map(
              (state) => {
                const option: ReactOptionType = {
                  label: state.name,
                  value: state.code,
                  code: state.stateCode,
                  searchableKey: `${state.name} ${state.stateCode}`
                };
                return option;
              }
            );
            const cityOptions =
              INDIAN_STATES_MOCK_DATA?.find(
                (state) => state.code === indianStatesOptions[1]?.value
              )?.cities || [];
            addressSection.city.options = cityOptions?.map((city) => ({
              label: city,
              value: city
            }));

            addressSection.city.value = {
              label: addressSection.city.options?.[1]?.label,
              value: addressSection.city.options?.[1]?.value
            };
            addressSection.state = {
              ...addressSection.state,
              options: [...indianStatesOptions],
              value: indianStatesOptions[1]
            };
          } else if (option?.value === COUNTRY_CODES.US) {
            const americaStatesOptions: ReactOptionType[] = USstateMockData.map(
              (state) => {
                const option: ReactOptionType = {
                  label: state.name,
                  value: state.code,
                  searchableKey: `${state.name} ${state.code}`,
                  code: state.code
                };
                return option;
              }
            );
            addressSection.state = {
              ...addressSection.state,
              options: [...americaStatesOptions],
              value: americaStatesOptions[1]
            };
            addressSection.city.options = undefined;
            addressSection.city.value = { label: '', value: '' };
          } else {
            addressSection.city.options = undefined;
            addressSection.city.value = { label: '', value: '' };
            addressSection.state = {
              ...addressSection.state,
              options: undefined,
              value: { label: '', value: '' }
            };
          }
          if (option) {
            addressSection.country.value = option;
          }
          updatedState['addressInfo'][index] = addressSection;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.EXCISE_ACCOUNT:
          updatedState.compliance.exciseAccount.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.EXEMPTION_CRITERIA:
          updatedState.compliance.exemptCriteria.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.EXCISE_ACCOUNT_UAE:
          updatedState.compliance.exciseAccountUAE.value = option;
          break;
        case ORGANISATION_PROFILE_FIELDS_NAME.OUTSIDE_CONTACT:
          updatedState.compliance.taxOptionForContactOutsideUAE.value = option;
          break;
        case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.LOCATION_LABEL:
          updatedState.additionalSettings.LOCATION.locationLabel.value = option;
          break;
        case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.ASSIGN_CLASSES:
          updatedState.additionalSettings.CLASS.assignClasses.value = option;
          break;
        case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.ROUNDING_METHOD:
          updatedState.additionalSettings.ROUND_OFF.roundingMethod.value =
            option;
          break;
        case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.VAT_SCHEME:
          updatedState.compliance.vatScheme.value = option;

          if (ukComplianceVatSchemeRows.length === 0) {
            let rows: any = [...ukComplianceVatSchemeRows];
            const date = DateFormatService.getDateFromStr(
              tenantDetails.bookBeginningStartDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            );
            rows.push({
              flatRatePercentage: 11,
              applyFrom: date,
              index: ukComplianceVatSchemeRows?.length + 1
            });
            setUKComplianceVatSchemeRows([...rows]);
          }

          break;
        default:
          break;
      }
    }

    setOrganisationState({ ...updatedState });
  };

  const onEWayToggleChange = (checked: boolean, name: string) => {
    const state = module;

    if (name === 'E_WAY' && checked) {
      state.ewayBill = true;
    }
    if (name === 'E_WAY' && !checked) {
      state.ewayBill = false;
    }
    let finalString = '';
    if (!state.ewayBill) {
      finalString = finalString + 'E_WAY';
    }

    if (finalString.charAt(0) == ',') {
      finalString = finalString.substring(1);
    }

    const payload = {
      application: 'ERP',
      hiddenApps: finalString,
      isResetHiddenApps: 'true'
    };
    TenantService.postAppCustomization(payload)
      .then((response) => {
        dispatch(fetchAppCustomizationInfo());
      })
      .catch((error) => {
        console.error('Error', error);
      });
  };

  const toggleChanged = (
    value: boolean,
    fieldName: ORGANISATION_COMPLIANCE_FIELD_NAME
  ) => {
    const updatedState = organisationState;
    const { compliance, addressInfo } = updatedState;
    switch (fieldName) {
      case ORGANISATION_COMPLIANCE_FIELD_NAME.GST_REGISTERED:
        compliance.gstRegistered.isChecked = value;
        addressInfo[0].state.isMandatory = value;
        addressInfo[0].state.hasError = false;
        compliance.gstRegisteredDate.hidden = !value;
        compliance.gstin.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.ENABLE_INVOICE:
        const localStorage: Storage = window.localStorage;
        localStorage.removeItem('e-Invoice_data');
        compliance.enableInvoice.isChecked = value;
        compliance.enableInvoice.isApiCallSuccess = false;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.ENABLE_EWAY_BILL:
        compliance.enableEWayBill.isChecked = value;
        compliance.enableEWayBill.isApiCallSuccess = false;
        onEWayToggleChange(value, 'E_WAY');
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.GST_E_FILING:
        compliance.gstEFiling.isChecked = value;
        compliance.gstEFiling.isApiCallSuccess = false;
        compliance.gstUsername.isDisabled = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.TCS_REGISTERED:
        compliance.tcsRegistered.isChecked = value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.OPT_IN_INVOICE_NOW:
        compliance.optInInvoiceNow.isChecked = value;
        compliance.uen.isMandatory = value;
        compliance.peppolId.isMandatory = value;
        compliance.peppolId.hidden = !value;
        compliance.vendorId.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.OPT_E_INVOICE:
        compliance.optEInvoice.isChecked = value;
        compliance.peppolIdMy.isMandatory = value;
        compliance.peppolIdMy.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.DONT_COLLECT_TAX:
        compliance.donTCollectTax.isChecked = value;
        compliance.taxIdNumber.isMandatory = !value;
        addressInfo[0].state.isMandatory = !value;
        addressInfo[0].address.isMandatory = !value;
        addressInfo[0].country.isMandatory = !value;
        addressInfo[0].postalCode.isMandatory = !value;
        compliance.taxCollectionStates.hidden = value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.ACTIVATE_EXCISE_DUTY:
        compliance.isExciseDuty.isChecked = value;
        compliance.exciseAccount.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXEMPT_BUSINESS:
        compliance.isTaxExempt.isChecked = value;
        compliance.exemptNumber.hidden = !value;
        compliance.expiryDate.hidden = !value;
        compliance.isExemptCertificate.hidden = !value;
        compliance.exemptNumber.isMandatory = value;
        // by default exempt certificate will unchecked
        compliance.isExemptCertificate.isChecked = false;
        compliance.exemptCriteria.hidden = true;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXEMPT_CERTIFICATE:
        compliance.isExemptCertificate.isChecked = value;
        compliance.exemptCriteria.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.BUSINESS_IN_DESIGNATED_ZONE:
        compliance.isDesignatedZoneBusiness.isChecked = value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.IS_CONTACTS_OUTSIDE_UAE:
        compliance.isContactsOutsideUAE.isChecked = value;
        compliance.taxOptionForContactOutsideUAE.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXCISE_DUTY_UAE:
        compliance.isExciseDutyUAE.isChecked = value;
        compliance.exciseAccountUAE.hidden = !value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.MAKING_TAX_DIGITAL:
        compliance.makingTaxDigital.isChecked = value;
        break;
      case ORGANISATION_COMPLIANCE_FIELD_NAME.PEPPOL_MARKETING_OPT_IN:
        compliance.peppolMarketingOptIn.isChecked = value;
        break;
      default:
        break;
    }
    setOrganisationState({ ...updatedState });
  };

  const checkboxChanged = (value: boolean, fieldName: GST_BUSINESS_TYPE) => {
    const updatedState = organisationState;
    switch (fieldName) {
      case GST_BUSINESS_TYPE.DIGITAL_SERVICES:
        updatedState.compliance.digitalService.isChecked = value;
        break;
      case GST_BUSINESS_TYPE.SEZ_OVERSEAS_TRADING:
        if (value) {
          updatedState.compliance.isCompositeScheme.isChecked = false;
        }
        updatedState.compliance.sezOverseaTrading.isChecked = value;
        break;
      case GST_BUSINESS_TYPE.IS_COMPOSITE_SCHEME:
        updatedState.compliance.isCompositeScheme.isChecked = value;
        break;
      case GST_BUSINESS_TYPE.REVERSE_CHARGE:
        updatedState.compliance.reverseCharge.isChecked = value;
        break;
      default:
        break;
    }
    setOrganisationState({ ...updatedState });
  };

  const calendarDateChanged = (
    date: Date,
    fieldName: ORGANISATION_FORM_DATE_FIELDS
  ) => {
    const updatedState = organisationState;
    switch (fieldName) {
      case ORGANISATION_FORM_DATE_FIELDS.EXPIRY_DATE:
        updatedState.compliance.expiryDate.date = Utility.formatDate(
          date,
          DATE_FORMAT.DD_MM_YYYY
        );
        break;
      case ORGANISATION_FORM_DATE_FIELDS.GST_REGISTRATION_DATE:
        updatedState.compliance.gstRegisteredDate.date = Utility.formatDate(
          date,
          DATE_FORMAT.DD_MM_YYYY
        );
        break;
      default:
        break;
    }
    setOrganisationState({ ...updatedState });
  };

  const selectCompositeSchemeOption = (
    optionValue: COMPOSITE_SCHEME_OPTION
  ) => {
    const updatedState = organisationState;
    updatedState.compliance.compositeSchemeOption.value = optionValue;
    setOrganisationState({ ...updatedState });
  };

  const multicurrencyToggle = (isChecked: boolean) => {
    const updatedState = organisationState;
    setIsMutliCurrencyChecked(isChecked);
    if (isChecked) {
      updatedState.finance.multicurrencyCheck.isChecked = true;
    } else {
      updatedState.finance.multicurrencyCheck.isChecked = false;
      updatedState.finance.multicurrencyList.value = [];
    }
    setOrganisationState({ ...updatedState });
  };

  const autoRoundOffToggle = (isChecked: boolean) => {
    let updatedState = organisationState;
    updatedState.additionalSettings.ROUND_OFF.autoRoundOff =
      !updatedState.additionalSettings.ROUND_OFF.autoRoundOff;
    if (updatedState.additionalSettings.ROUND_OFF.autoRoundOff) {
      updatedState.additionalSettings.ROUND_OFF.roundingMethod.value = {
        label: 'Default Round Off',
        value: 'ROUND_OFF'
      };
    }
    setOrganisationState({ ...updatedState });
  };

  const triggerPeppolIdValidation = (
    updatedState: OrganisationProfileFormState,
    value: string
  ) => {
    const isValidPeppolId = Utility.peppolIdValidator(
      value,
      updatedState.compliance.uen.value.toString()
    );
    if (isValidPeppolId.hasError) {
      updatedState.compliance.peppolId.hasError = true;
      updatedState.compliance.peppolId.errorMsg = isValidPeppolId.errorMsg;
    } else {
      updatedState.compliance.peppolId.hasError = false;
      updatedState.compliance.peppolId.errorMsg = isValidPeppolId.errorMsg;
    }
  };

  const formFieldValidation = (): boolean => {
    let formHasError: boolean = false;
    const updatedState = deepClone(organisationState);
    const { compliance, addressInfo, finance } = updatedState;
    // to track tab which is in error
    let activeTab: ORGANISATION_FORM_TAB = ORGANISATION_FORM_TAB.NONE;

    if (
      isMutliCurrencyChecked &&
      Utility.isEmpty(updatedState.finance.multicurrencyList.value)
    ) {
      formHasError = true;
      showAlert('Alert!!', 'Please select atleast one currency from drop down');
      if (activeTab === ORGANISATION_FORM_TAB.NONE) {
        activeTab = ORGANISATION_FORM_TAB.GENERAL_INFO;
      }
    }
    // decimal scale validation
    if (Number(finance.decimalPlace.value.value) < tenantDetails.decimalScale) {
      const errorMsg = `Decimal place should be greater than or equal to ${tenantDetails.decimalScale}`;
      showAlert('Error!', errorMsg);
      formHasError = true;

      if (activeTab === ORGANISATION_FORM_TAB.NONE) {
        activeTab = ORGANISATION_FORM_TAB.GENERAL_INFO;
      }
    }

    /* INDIAN COMPLIANCE*/

    // gst registered

    if (
      !compliance.gstRegistered.hidden &&
      compliance.gstRegistered.isChecked
    ) {
      // Temporary Commented for GSTIN Validation, made as same as Old Books Validation
      // const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
      // if (!gstRegex.test(compliance.gstin.value.toString())) {
      //   compliance.gstin.hasError = true;
      //   compliance.gstin.errorMsg = 'Not a valid GSTIN number';
      //   formHasError = true;

      //   if (activeTab === ORGANISATION_FORM_TAB.NONE) {
      //     activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
      //   }
      // }
      // Temporary Commented for GSTIN Validation, made as same as Old Books Validation

      if (compliance?.gstin?.value?.toString().trim().length !== 15) {
        compliance.gstin.hasError = true;
        compliance.gstin.errorMsg = 'Not a valid GSTIN number';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }

      if (
        addressInfo[0].state.isMandatory &&
        !addressInfo[0].state.value.label
      ) {
        addressInfo[0].state.hasError = true;
        addressInfo[0].state.errorMsg = 'State is Mandatory for GSTIN';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* SINGAPORE COMPLIANCE*/

    // opt in invoice now
    if (
      !compliance.optInInvoiceNow.hidden &&
      compliance.optInInvoiceNow.isChecked
    ) {
      compliance.uen.isMandatory = true;
      compliance.peppolId.isMandatory = true;
      //compliance.vendorId.isMandatory = true;
    }

    // uen number
    if (!compliance.uen.hidden && compliance.uen.isMandatory) {
      if (compliance.uen.value?.toString().trim().length === 0) {
        compliance.uen.hasError = true;
        compliance.uen.errorMsg = 'UEN cannot start with whitespace';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      } else if (compliance.uen.isMandatory && !compliance.uen.value) {
        compliance.uen.hasError = true;
        compliance.uen.errorMsg = 'UEN is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }
    // peppol id
    if (!compliance.peppolId.hidden) {
      if (compliance.peppolId.hasError || compliance.peppolId.value === '') {
        compliance.peppolId.hasError = true;
        compliance.peppolId.errorMsg = 'Incorrect Peppol ID';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }
    // if (!compliance.vendorId.hidden) {
    //   if (compliance.vendorId.hasError || compliance.vendorId.value === '') {
    //     compliance.vendorId.hasError = true;
    //     compliance.vendorId.errorMsg = 'Incorrect Vendor Id';
    //     formHasError = true;

    //     if (activeTab === ORGANISATION_FORM_TAB.NONE) {
    //       activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
    //     }
    //   }
    // }

    // gst Number
    if (!compliance.gstNo.hidden) {
      if (compliance.gstNo.value?.toString().trim().length > 15) {
        compliance.gstNo.hasError = true;
        compliance.gstNo.errorMsg = 'GST Number max allowed of 15 characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* USA COMPLIANCE*/

    // tax id number
    if (
      !compliance.donTCollectTax.hidden &&
      !compliance.donTCollectTax.isChecked
    ) {
      if (!compliance.taxIdNumber.value) {
        compliance.taxIdNumber.hasError = true;
        compliance.taxIdNumber.errorMsg = 'EIN is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      } else if (compliance.taxIdNumber.value.toString().trim().length !== 9) {
        compliance.taxIdNumber.hasError = true;
        compliance.taxIdNumber.errorMsg = 'EIN number must be 9 characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    } else {
      if (
        !Utility.isEmpty(compliance.taxIdNumber.value.toString()) &&
        compliance.taxIdNumber.value.toString().trim().length !== 9
      ) {
        compliance.taxIdNumber.hasError = true;
        compliance.taxIdNumber.errorMsg = 'EIN number must be 9 characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    //state, postalcode , address
    addressInfo.forEach((address) => {
      if (address.address.isMandatory && !address.address.value) {
        address.address.hasError = true;
        address.address.errorMsg = 'Address is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.ADDRESS_INFO;
        }
      }
      if (address.postalCode.isMandatory && !address.postalCode.value) {
        address.postalCode.hasError = true;
        address.postalCode.errorMsg = 'Postal Code is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.ADDRESS_INFO;
        }
      }
      if (address.state.isMandatory && !address.state.value.label) {
        address.state.hasError = true;
        address.state.errorMsg = 'State is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.ADDRESS_INFO;
        }
      }
      if (
        Utility.isNotEmpty(address.contactNumber.value) &&
        address.contactNumber.value?.toString()?.length > 15
      ) {
        address.contactNumber.hasError = true;
        address.contactNumber.errorMsg = 'Max length 15 digits';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.ADDRESS_INFO;
        }
      }
    });

    /* INDONESIA COMPLIANCE */
    //npwp number
    if (!compliance.npwpNumber.hidden && compliance.npwpNumber.value) {
      let num = compliance.npwpNumber.value.toString().replace(/[^0-9]/g, '');
      if (num.length !== 15) {
        compliance.npwpNumber.hasError = true;
        compliance.npwpNumber.errorMsg = 'NPWP Number must be 15 digits long';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* MALAYSIA COMPLIANCE */
    if (!compliance.exemptNumber.hidden) {
      if (!compliance.exemptNumber.value) {
        compliance.exemptNumber.hasError = true;
        compliance.exemptNumber.errorMsg = 'Exemption Number is Mandatory';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      } else if (compliance.exemptNumber.value.toString().trim().length > 25) {
        compliance.exemptNumber.hasError = true;
        compliance.exemptNumber.errorMsg =
          'Exemption Number cannot be greater than 25 characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* UAE COMPLIANCE */
    if (!compliance.vatNumber.hidden && compliance.vatNumber.value) {
      if (isNaN(Number(compliance.vatNumber.value))) {
        compliance.vatNumber.hasError = true;
        compliance.vatNumber.errorMsg = 'VAT Number should be a number';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      } else if (compliance.vatNumber.value.toString().trim().length > 15) {
        compliance.vatNumber.hasError = true;
        compliance.vatNumber.errorMsg =
          'VAT Number cannot be greater than 15 digits';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* PHILIPPINES COMPLIANCE */
    if (!compliance.vatNumberPH.hidden && compliance.vatNumberPH.value) {
      if (compliance.vatNumberPH.value.toString().trim().length > 15) {
        compliance.vatNumberPH.hasError = true;
        compliance.vatNumberPH.errorMsg =
          'VAT Number cannot be greater than 15 Characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /* Canada COMPLIANCE */
    if (!compliance.gstNumberCA.hidden && compliance.gstNumberCA.value) {
      if (compliance.gstNumberCA.value.toString().trim().length > 20) {
        compliance.gstNumberCA.hasError = true;
        compliance.gstNumberCA.errorMsg =
          'GST Number cannot be greater than 20 Characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    if (!compliance.businessNumber.hidden && compliance.businessNumber.value) {
      if (compliance.businessNumber.value.toString().trim().length > 20) {
        compliance.businessNumber.hasError = true;
        compliance.businessNumber.errorMsg =
          'Business Number cannot be greater than 20 Characters';
        formHasError = true;

        if (activeTab === ORGANISATION_FORM_TAB.NONE) {
          activeTab = ORGANISATION_FORM_TAB.COMPLIANCE;
        }
      }
    }

    /*********** UK COMPLIANCE *********/
    if (
      tenantDetails?.country === COUNTRY_CODES.UK &&
      compliance.vatScheme?.value?.value === UK_VAT_SCHEME[1].code
    ) {
      let { validateApplyDates, dateValidation } = validateApplyFormDate(
        ukComplianceVatSchemeRows
      );

      if (dateValidation) {
        setUKComplianceVatSchemeRows([...validateApplyDates]);
        formHasError = true;
      }
    }

    if (formHasError) {
      if (activeTab !== ORGANISATION_FORM_TAB.NONE) {
        updatedState.activeTab = activeTab;
      }
      setOrganisationState({ ...updatedState });
      return false;
    }
    return true;
  };

  const updateOrginfo = async (payload: OrganisationFormPayload) => {
    try {
      const contact = await TenantService.updateContactInfo(contactInfo);
      if (!Utility.isEmpty(contact)) {
        dispatch(fetchContactInfo());
      }
    } catch (error: any) {
      console.error('Failed to update contact info : ', error);
    }

    try {
      const tenant = await TenantService.updateOrgSetting(payload);
      if (!Utility.isEmpty(tenant)) {
        if (systemDimensionsData?.content?.length <= 2) {
          updateColumnConfigs();
        }
        dispatch(fetchCurrentTenantInfo());
        dispatch(fetchCurrencyWithExchangeRate());
        dispatch(fetchCategoryDimensions());
        props.passingInteraction({ type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP });
      }
    } catch (error: any) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
      });
      console.error('Failed to update tenant info: ', error);
    }
  };

  const validateApplyFormDate = (ukComplianceVatSchemeRows: any) => {
    let dateValidation: boolean = false;
    let flatRateDate: any = [];
    let rowData: any = [];

    ukComplianceVatSchemeRows?.map((vatSchemeItem: any) => {
      let invalidFields: any = [];
      if (
        flatRateDate.includes(new Date(vatSchemeItem?.applyFrom).toDateString())
      ) {
        dateValidation = true;
        invalidFields = ['applyFrom'];
      } else {
        invalidFields = [];
      }
      flatRateDate.push(vatSchemeItem?.applyFrom.toDateString());
      rowData.push({
        ...vatSchemeItem,
        invalidFields
      });
    });

    return { validateApplyDates: rowData, dateValidation };
  };

  const updateOrgSetting = () => {
    const isFormValid = formFieldValidation();
    if (!isFormValid) {
      return;
    }
    // disable save btn
    props.passingInteraction({
      type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
    });

    const {
      addressInfo,
      organisation,
      compliance,
      finance,
      additionalSettings
    } = organisationState;

    const addressInfoData: AddressType = {
      contactName: addressInfo[0].contactName?.value
        ? addressInfo[0].address.value.toString()
        : '',
      address1: addressInfo[0].address?.value
        ? addressInfo[0].address.value.toString()
        : '',
      postalCode: addressInfo[0].postalCode?.value
        ? addressInfo[0].postalCode.value.toString()
        : '',
      city: addressInfo[0].city?.value?.value
        ? addressInfo[0].city?.value?.value.toString()
        : '',
      country: addressInfo[0].country.value.value,
      state: addressInfo[0].state.value.label,
      preferred: true
    };

    const billingAddresses = organisationState.addressInfo.map(
      (addressData, index) => {
        const contactName: string = addressData.contactName.value
          ? addressData.contactName.value.toString().trim()
          : '';
        const address: string = addressData.address.value
          ? addressData.address.value.toString().trim()
          : '';
        const address2: string = addressData.address2.value
          ? addressData.address2.value.toString().trim()
          : '';
        const postalCode: string = addressData.postalCode.value
          ? addressData.postalCode.value.toString().trim()
          : '';
        const mobileCountryCode: string = addressData.countryNumberOptions.value
          ? addressData.countryNumberOptions.value.dial_code.toString().trim()
          : '';
        const contactNumber = `${mobileCountryCode}${addressData.contactNumber.value
          .toString()
          .trim()}`;
        const mobileNumber: string = addressData.contactNumber.value
          ? contactNumber
          : '';
        const emailId: string = addressData.email.value
          ? addressData.email.value.toString().trim()
          : '';
        const customFields: any[] = addressData.customFields ?? [];
        return {
          ...Utility.generateAddress(
            contactName,
            address,
            address2,
            addressData.city.value?.value?.toString(),
            addressData.state.value?.label?.toString(),
            addressData.country.value?.value?.toString(),
            postalCode,
            mobileCountryCode,
            mobileNumber,
            emailId
          ),
          preferred: index === organisationState.activeBillingAddressIndex,
          //   preferred: index === organisationState.activeBillingAddressIndex,
          customFields
        };
      }
    );

    const ilReturnAddress = organisationState.addressInfo.map(
      (addressData, index) => {
        const contactName: string = addressData.contactName.value
          ? addressData.contactName.value.toString().trim()
          : '';
        const address: string = addressData.address.value
          ? addressData.address.value.toString().trim()
          : '';
        const address2: string = addressData.address2.value
          ? addressData.address2.value.toString().trim()
          : '';
        const postalCode: string = addressData.postalCode.value
          ? addressData.postalCode.value.toString().trim()
          : '';
        const mobileCountryCode: string = addressData.countryNumberOptions.value
          ? addressData.countryNumberOptions.value.dial_code.toString().trim()
          : '';
        const contactNumber = `${mobileCountryCode}${addressData.contactNumber.value
          .toString()
          .trim()}`;
        const mobileNumber: string = addressData.contactNumber.value
          ? contactNumber
          : '';
        const emailId: string = addressData.email.value
          ? addressData.email.value.toString().trim()
          : '';
        const customFields: any[] = addressData.customFields ?? [];
        return {
          ...Utility.generateAddress(
            contactName,
            address,
            address2,
            addressData.city.value?.value?.toString(),
            addressData.state.value?.label?.toString(),
            addressData.country.value?.value?.toString(),
            postalCode,
            mobileCountryCode,
            mobileNumber,
            emailId
          ),
          preferred: index === organisationState.activeReturnAddressIndex,
          customFields
        };
      }
    );

    const shippingAddresses = organisationState.addressInfo.map(
      (addressData, index) => {
        const contactName: string = addressData.contactName.value
          ? addressData.contactName.value.toString().trim()
          : '';
        const address: string = addressData.address.value
          ? addressData.address.value.toString().trim()
          : '';
        const address2: string = addressData.address2.value
          ? addressData.address2.value.toString().trim()
          : '';
        const postalCode: string = addressData.postalCode.value
          ? addressData.postalCode.value.toString().trim()
          : '';
        const mobileCountryCode: string = addressData.countryNumberOptions.value
          ? addressData.countryNumberOptions.value.toString().trim()
          : '';
        const contactNumber = `${mobileCountryCode}${addressData.contactNumber.value
          .toString()
          .trim()}`;
        const mobileNumber: string = addressData.contactNumber.value
          ? contactNumber
          : '';
        const emailId: string = addressData.email.value
          ? addressData.email.value.toString().trim()
          : '';
        const customFields: any[] = addressData.customFields ?? [];
        return {
          ...Utility.generateAddress(
            contactName,
            address,
            address2,
            addressData.city.value?.value?.toString(),
            addressData.state.value?.label?.toString(),
            addressData.country.value?.value?.toString(),
            postalCode,
            mobileCountryCode,
            mobileNumber,
            emailId
          ),
          preferred: index === organisationState.activeShippingAddressIndex,
          customFields
        };
      }
    );

    let payload: OrganisationFormPayload = {
      address: addressInfoData,
      billingAddresses,
      shippingAddresses,
      // shippingAddresses: [addressInfoData],
      // billingAddresses: [addressInfoData],
      id: tenantDetails.id,
      currency: tenantDetails.currency,
      dateFormat: organisation.dateFormat.value.label,
      decimalScale: Number(finance.decimalPlace.value.value),
      multicurrency: tenantDetails.multicurrencyEnabled,
      multicurrencyEnabled: tenantDetails.multicurrencyEnabled,
      name: tenantDetails.name,
      numberFormat: finance.numberFormat.value.value,
      timezone: finance.timezone.value.value,
      additionalSettings: {
        CLASS: {
          trackClasses: additionalSettings.CLASS.trackClasses.isChecked,
          warnOnClassNotSelected:
            additionalSettings.CLASS.warnOnClassNotSelected.isChecked,
          assignClasses: additionalSettings.CLASS.assignClasses.value.value
            ? additionalSettings.CLASS.assignClasses.value.value
            : 'TRANSACTION',
          classCFId: tenantDetails?.additionalSettings?.CLASS?.classCFId,
          mandatory: additionalSettings?.CLASS?.mandatory?.isChecked
        },
        LOCATION: {
          trackLocation: additionalSettings?.LOCATION?.trackLocation?.isChecked,
          locationLabel: additionalSettings?.LOCATION?.locationLabel?.value
            ?.value
            ? additionalSettings?.LOCATION?.locationLabel?.value?.value
            : 'Location',
          locationCFId:
            tenantDetails?.additionalSettings?.LOCATION?.locationCFId,
          mandatory: additionalSettings?.LOCATION?.mandatory?.isChecked
        },
        BUSINESS_TYPE: organisation.businessType.value,
        ROUND_OFF: {
          autoRoundOff: additionalSettings?.ROUND_OFF?.autoRoundOff,
          roundingMethod:
            additionalSettings?.ROUND_OFF?.roundingMethod?.value?.value
        }
      }
    };

    const multicurrencyToActivateList: string[] = [tenantDetails.currency];
    if (
      finance.multicurrencyCheck.isChecked &&
      finance.multicurrencyList.value.length > 0
    ) {
      finance.multicurrencyList.value.forEach((optionIndex) => {
        if (finance.multicurrencyList.options) {
          const currencyCode: string =
            finance.multicurrencyList.options[optionIndex].value;
          multicurrencyToActivateList.push(currencyCode);
        }
      });
    }
    if (multicurrencyToActivateList.length > 1) {
      payload.currencyCodesToActivate = multicurrencyToActivateList;
      payload.multicurrency = true;
      payload.multicurrencyEnabled = true;
    }

    const {
      tanNumber,
      tcsRegistered,
      interestRate,
      gstEFiling,
      gstRegistered,
      gstRegisteredDate,
      gstUsername,
      gstin,
      digitalService,
      reverseCharge,
      isCompositeScheme,
      sezOverseaTrading,
      enableInvoice,
      compositeSchemeOption,
      panNumber,
      cinNumber
    } = compliance;

    /* INDIAN COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.IN) {
      payload = {
        ...payload,
        tanNumberIndia: tanNumber.value.toString(),
        tcsEnabled: tcsRegistered.isChecked,
        tdsPaymentInterestRateIndia: interestRate.value
          ? Number(interestRate.value)
          : 0,
        gstRegistered: gstRegistered.isChecked,
        panIndia: panNumber.hasError ? '' : panNumber.value.toString(),
        cinIndia: cinNumber.hasError ? '' : cinNumber.value.toString()
      };
      payload.indiaGstr = isGsteFillingEnable;

      // if GST-Registered is checked
      if (gstRegistered.isChecked) {
        payload.trackDigitalServices = digitalService.isChecked;
        payload.enableReverseCharge = reverseCharge.isChecked;
        payload.gstin = gstin.value.toString();
        payload.gstRegistrationDate = gstRegisteredDate.date;

        if (!sezOverseaTrading.isChecked) {
          payload.registeredToCompositionScheme = isCompositeScheme.isChecked;
          if (compositeSchemeOption.value !== COMPOSITE_SCHEME_OPTION.NONE) {
            payload.compositionSchemeType = compositeSchemeOption.value;
          }
        }

        if (sezOverseaTrading.isChecked) {
          payload.sezOrImportExport = true;
          payload.registeredToCompositionScheme = false;
        } else {
          payload.sezOrImportExport = false;
        }
      }

      // E-Invoice API call success
      if (enableInvoice.isApiCallSuccess) {
        payload.indiaEinvoice = true;
      }

      // GST E-filing registered
      if (gstEFiling.isApiCallSuccess) {
        payload.indiaGstr = true;
        payload.indiaGstrUsername = gstUsername.value.toString();
      }

      // if Invoice Now Disabled
      if (!enableInvoice.isChecked) {
        payload.indiaEinvoice = false;
        // payload.indiaGstr = false;     GST-EFILING NOT WORKING
        // payload.indiaGstrUsername = null;
      } else {
        payload.indiaEinvoice = true;
      }
    }

    /* SINGAPORE COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.SG) {
      payload.peppolOptIn = compliance.optInInvoiceNow.isChecked;
      payload.peppolMarketingOptIn = compliance.peppolMarketingOptIn.isChecked;
      // uen number

      payload.uenNo = compliance.uen.value?.toString();

      // gst number
      if (compliance.gstNo.value) {
        payload.gstNo = compliance.gstNo.value.toString();
      }
      if (compliance.optInInvoiceNow.isChecked) {
        // peppol id
        payload.peppolId = compliance.peppolId.value.toString();
        // vendor id
        payload.vendorId = compliance.uen.value?.toString();
      }
    }

    /* USA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.US) {
      // collects tax ?
      payload.complianceEnabled = !compliance.donTCollectTax.isChecked;
      // GST no
      payload.gstNo = compliance.taxIdNumber.value.toString();

      if (
        !compliance.donTCollectTax.isChecked &&
        compliance.taxCollectionStates.value.length > 0
      ) {
        // states to collect tax from
        const nexusStates = compliance.taxCollectionStates.value
          .map((state) => state.value)
          .join(',');
        payload.nexusStates = nexusStates;
      }
    }

    /* INDONESIA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.ID) {
      // has excise Duty
      payload.exciseApplicable = compliance.isExciseDuty.isChecked;
      // npwp number
      payload.npwpNo = compliance.npwpNumber.value
        ? compliance.npwpNumber.value.toString().replaceAll(/[^0-9]/g, '')
        : '';

      if (compliance.isExciseDuty.isChecked) {
        // excise account
        payload.exciseAccount = compliance.exciseAccount.value.value;
      }
    }

    /* MALAYSIA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.MY) {
      // business Registration Number
      payload.businessRegistrationNo = compliance.businessRegistrationNumber
        .value
        ? compliance.businessRegistrationNumber.value.toString()
        : '';
      // Sales Tax Registration Number
      payload.salesTaxRegistrationNo = compliance.salesTaxNumber.value
        ? compliance.salesTaxNumber.value.toString()
        : '';
      // Service Tax Registration Number
      payload.serviceTaxRegistrationNo = compliance.serviceTaxNumber.value
        ? compliance.serviceTaxNumber.value.toString()
        : '';
      // business exempted ?
      payload.exemption = compliance.isTaxExempt.isChecked;

      if (compliance.isTaxExempt.isChecked) {
        // exemption number
        payload.exemptionNo = compliance.exemptNumber.value.toString();
        // expiry date of exemption
        payload.expiryDate = compliance.expiryDate.date;
        // has exemption certificate ?
        payload.exemptionCerificate = compliance.isExemptCertificate.isChecked;

        if (compliance.isExemptCertificate.isChecked) {
          // exemption certificate option
          payload.exemptionCriteria = compliance.exemptCriteria.value.value;
        }
      }

      payload.gstNo = compliance.gstNumberMy?.value?.toString()
        ? compliance.gstNumberMy.value?.toString()
        : '';
      if (compliance.optEInvoice.isChecked) {
        payload.peppolOptIn = compliance.optEInvoice.isChecked;
        payload.peppolId = compliance.peppolIdMy.value?.toString()
          ? compliance.peppolIdMy.value?.toString()
          : '';
      }
    }

    /* UAE COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.AE) {
      // vat number
      payload.vatNoUAE = compliance.vatNumber.value
        ? compliance.vatNumber.value.toString()
        : '';
      payload.outsideContact = compliance.isContactsOutsideUAE.isChecked;
      payload.exciseApplicable = compliance.isExciseDutyUAE.isChecked;
      payload.isDesignatedZone = compliance.isDesignatedZoneBusiness.isChecked;

      // contact outside UAE
      if (compliance.isContactsOutsideUAE.isChecked) {
        payload.rcmAccount =
          compliance.taxOptionForContactOutsideUAE.value.value;
      }
      // excise Duty account
      if (compliance.isExciseDutyUAE.isChecked) {
        payload.exciseAccount = compliance.exciseAccountUAE.value.value;
      }
    }

    /* PHILIPPINES COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.PH) {
      payload.vatNoPhilippines = compliance.vatNumberPH.value.toString();
    }

    /* CANADA COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.CA) {
      payload.canadianBusinessNo = compliance.businessNumber.value.toString();
      payload.gstNumber = compliance.gstNumberCA.value.toString();
    }

    if (tenantDetails?.country === COUNTRY_CODES.IL) {
      payload.ilDeductionFileId = compliance.ilDeductionFileId.value
        .toString()
        .trim();
      payload.ilEngagementType = compliance.ilEngagementType.value
        .toString()
        .trim();
      payload.ilIncomeTaxNo = compliance.ilIncomeTaxNo.value.toString().trim();
      payload.ilLegalName = compliance.ilLegalName.value.toString().trim();
      payload.ilNationalInsuranceNo = compliance.ilNationalInsuranceNo.value
        .toString()
        .trim();
      payload.ilParentSubsidiaryName = compliance.ilParentSubsidiaryName.value
        .toString()
        .trim();
      payload.ilReturnEmail = compliance.ilReturnEmail.value.toString().trim();
      payload.ilVatCode = compliance.ilVatCode.value.value;
      payload.ilVatRegistrationNo = compliance.ilVatRegistrationNo.value
        .toString()
        .trim();
      payload.ilWithholdingTaxAccount =
        compliance.ilWithholdingTaxAccount.value.value;
      payload.ilReturnAddress = ilReturnAddress;
    }

    /* UK COMPLIANCE*/
    if (tenantDetails?.country === COUNTRY_CODES.UK) {
      payload.businessRegistrationNoUK =
        compliance.ukBusinessRegistrationNumber?.value?.toString();
      payload.vatIdentificationNumberUK =
        compliance.vatIdentificationNumber?.value?.toString();
      payload.vatSchemeUK = compliance.vatScheme?.value?.value;

      if (compliance.vatScheme?.value?.value === UK_VAT_SCHEME[1].code) {
        const date = DateFormatService.getFormattedDateString(
          tenantDetails.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        payload.flatRatePercentageListUK =
          ukComplianceVatSchemeRows?.map((vatSchemeItem: any) => {
            return {
              flatRatePercentage: vatSchemeItem?.flatRatePercentage ?? 0,
              applyFrom: vatSchemeItem?.applyFrom
                ? DateFormatService.getDateStrFromDate(
                    vatSchemeItem?.applyFrom,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )
                : date
            };
          }) ?? [];
      } else {
        payload.flatRatePercentageListUK = [];
      }
    }

    updateOrginfo(payload);
  };

  const updateColumnConfigs = () => {
    TableService.getAllViews().then(
      (data) => {
        setupColumConfigs(data);
        TableManager.populateViewMap(
          data.filter((data) => !data.default && data.columnsMetaData)
        );
      },
      (err) => {
        console.error('Error updating column configs: ', err);
      }
    );
  };

  const setupColumConfigs = (configs: any[]) => {
    configs.forEach((config) => {
      const columnsMetaData = config?.columnsMetaData;
      const tableId = config._id;
      switch (config.name) {
        case REMOTE_CONFIG_TABLES.PRODUCT:
          dispatch(
            addProductColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.INVOICE:
          dispatch(
            addInvoiceColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.SALES_ORDER:
          dispatch(
            addSalesOrderColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
        case REMOTE_CONFIG_TABLES.BILL:
          dispatch(addBillColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_ORDER:
          dispatch(addOrderColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.QUOTATION:
          dispatch(addQuoteColumnConfig({ tableId, config: columnsMetaData }));
          break;
        case REMOTE_CONFIG_TABLES.PURCHASE_REQUISITION:
          dispatch(
            requisitionColumnConfig({ tableId, config: columnsMetaData })
          );
          break;
      }
    });
  };

  const showIRPCredPopup = () => {
    const updatedState = organisationState;
    const gstRegex = new RegExp(GSTIN_REGEX_PATTERN);
    if (
      (!updatedState.compliance.gstRegistered.isChecked ||
        !gstRegex.test(updatedState.compliance.gstin.value.toString())) &&
      updatedState.compliance.gstin.value.toString() !== IRP_CRED_VALID_GSTIN
    ) {
      showAlert('Error!', 'Please Enter a valid GST Number', TOAST_TYPE.ERROR);
      return;
    }
    updatedState.compliance.enableInvoice.isDisabled = false;
    setOrganisationState({ ...updatedState });
  };

  const hideIRPCredPopup = () => {
    const updatedState = organisationState;
    updatedState.compliance.enableInvoice.isDisabled = true;
    setOrganisationState({ ...updatedState });
  };

  const catchClicks = (data: PopupClickActionType) => {
    const updatedState = organisationState;
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        updatedState.compliance.enableInvoice.isDisabled = true;
        updatedState.compliance.gstEFiling.isDisabled = true;
        setOrganisationState({ ...updatedState });
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CLICK_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current?.storeCallbacksRef.createIRPCredentials();
        break;
      case POPUP_CLICK_TYPE.GST_E_FILING:
        orgProfileRef.current?.storeCallbacksRef.verifyGSTEFiling();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    const updatedState = organisationState;
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        updatedState.compliance.enableInvoice.isDisabled = true;
        updatedState.compliance.gstEFiling.isDisabled = true;
        setOrganisationState({ ...updatedState });
        setIsActionBtnDisabled(false);
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL:
        orgProfileRef.current.storeCallbacksRef.createIRPCredentials =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.GST_E_FILING:
        orgProfileRef.current.storeCallbacksRef.verifyGSTEFiling =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.IRP_CREDENTIAL_SUCCESS:
        updatedState.compliance.enableInvoice.isApiCallSuccess = true;
        updatedState.compliance.enableInvoice.isDisabled = true;
        setOrganisationState({ ...updatedState });
        break;
      case POPUP_CALLBACKS_TYPE.GST_E_FILING_SUCCESS:
        updatedState.compliance.gstEFiling.isApiCallSuccess = true;
        updatedState.compliance.gstEFiling.isDisabled = true;
        setOrganisationState({ ...updatedState });
        break;

      case POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS:
        setIsActionBtnDisabled(true);
        break;
      default:
        break;
    }
  };

  const connectGSTSubmission = () => {
    const updatedState = organisationState;
    const { gstUsername, gstin, gstRegistered, gstEFiling } =
      updatedState.compliance;

    if (gstEfillUserName === gstUsername.value) {
      showAlert('Error', 'You are Trying to Connect Old User Name');
      return;
    }
    if (!gstUsername.value) {
      showAlert('Error!', 'Please Enter GST Username');
      return;
    } else if (!gstin.value || !gstRegistered.isChecked || gstin.hasError) {
      showAlert('Error!', 'Please Enter a valid GSTIN');
      return;
    }
    const payload: GSTSubmissionCheckPayload = {
      gstin: organisationState.compliance.gstin.value.toString(),
      username: organisationState.compliance.gstUsername.value.toString()
    };

    EInvoiceService.getOTP(payload)
      .then((data) => {
        if (data) {
          if (data['status_cd'] === '0') {
            showAlert('Error!', 'GST Submission Checking Failed');
          } else {
            gstEFiling.isDisabled = false;
            gstEFiling.isApiCallSuccess = false;
            setOrganisationState({ ...updatedState });
          }
        }
      })
      .catch((err) => {
        showAlert(
          'Error!',
          'Something went wrong, please make sure your gstin and username are correct'
        );
        console.log(err, 'error');
      });
  };

  const sdkDropdown = (
    title: string,
    value: string,
    dropdownOptions: OrganisationReactSelectOption[] | undefined,
    fieldName:
      | ORGANISATION_FORM_FIELD_NAME
      | ORGANISATION_PROFILE_FIELDS_NAME
      | ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME,
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL,
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    isSearchable: boolean = false,
    searchableKey: string = 'label',
    index: number = 0,
    bgColor: string = '',
    titleColor: string = ''
  ) => {
    return (
      <DKInput
        value={value}
        title={title}
        direction={direction}
        formatter={(obj: any) => {
          return obj;
        }}
        type={INPUT_TYPE.DROPDOWN}
        required={isMandatory}
        canValidate={hasError}
        onChange={(value: any) => {
          optionSelected(value, fieldName, index);
        }}
        valueStyle={{
          backgroundColor: bgColor ? 'white' : ''
        }}
        titleStyle={{
          color: titleColor
        }}
        errorMessage={errMsg}
        dropdownConfig={{
          allowSearch: isSearchable,
          searchableKey: searchableKey,
          data: dropdownOptions,
          renderer: (index: any, obj: any) => {
            return (
              <div className="flex flex-row w-full justify-content-between">
                <DKLabel
                  style={{ fontSize: '13px' }}
                  className="text-base border-radius-s"
                  text={obj.label}
                />
                {fieldName ===
                  ORGANISATION_PROFILE_FIELDS_NAME.DROPDOWN_STATE && (
                  <DKLabel
                    style={{ fontSize: '13px' }}
                    className="text-base border-radius-s opacity-50"
                    text={obj?.value ?? ''}
                  />
                )}
              </div>
            );
          }
        }}
      />
    );
  };

  const sdkInput = (
    title: string,
    value: string | number,
    placeholder: string,
    fieldName: ORGANISATION_FORM_FIELD_NAME,
    direction: string = INPUT_VIEW_DIRECTION.HORIZONTAL,
    className: string = '',
    isMandatory: boolean = false,
    hasError: boolean = false,
    errMsg: string = '',
    readonly: boolean = false,
    index: number = 0,
    bgColor: string = '',
    titleColor: string = ''
  ) => {
    return (
      <DKInput
        className={className}
        title={title}
        required={isMandatory}
        placeholder={placeholder}
        type={INPUT_TYPE.TEXT}
        direction={direction}
        value={value}
        onChange={(event: string) => formUpdated(event, fieldName, index)}
        onBlur={(event: any) => {
          if (title === 'UEN' && placeholder === 'UEN') {
            // TenantService.checkValidUENNuber(event.target.value)
            //   .then((response: any) => {
            //     if (
            //       response &&
            //       !organisationState.compliance.optInInvoiceNow.isChecked
            //     ) {
            //       let buttons = [
            //         {
            //           title: 'No',
            //           className: 'bg-gray2 border-m ',
            //           onClick: () => {}
            //         },
            //         {
            //           title: 'Yes',
            //           className: 'bg-button text-white ml-r',
            //           onClick: () => {
            //             let updatedOrganisationState = organisationState;
            //             updatedOrganisationState.compliance.optInInvoiceNow.isChecked =
            //               true;
            //             updatedOrganisationState.compliance.peppolId.hidden =
            //               false;
            //             updatedOrganisationState.compliance.vendorId.hidden =
            //               false;
            //             updatedOrganisationState.compliance.peppolId.value = `0195:sguen${event.target.value}`;
            //             updatedOrganisationState.compliance.peppolId.hasError =
            //               false;
            //             setOrganisationState({ ...updatedOrganisationState });
            //           }
            //         }
            //       ];
            //       showAlert(
            //         'UEN Registered with Peppol',
            //         'We found a PEPPOL ID for this UEN. Do you want to update it here?',
            //         buttons
            //       );
            //     } else if (
            //       response &&
            //       organisationState.compliance.optInInvoiceNow.isChecked
            //     ) {
            //       let updatedOrganisationState = organisationState;
            //       updatedOrganisationState.compliance.optInInvoiceNow.isChecked =
            //         true;
            //       updatedOrganisationState.compliance.peppolId.hidden = false;
            //       updatedOrganisationState.compliance.vendorId.hidden = false;
            //       updatedOrganisationState.compliance.peppolId.value = `0195:sguen${event.target.value}`;
            //       updatedOrganisationState.compliance.peppolId.hasError = false;
            //       setOrganisationState({ ...updatedOrganisationState });
            //     }
            //   })
            //   .catch((error: any) => {
            //     if (
            //       error &&
            //       !error.data &&
            //       organisationState.compliance.optInInvoiceNow.isChecked
            //     ) {
            //       let updatedOrganisationState = organisationState;
            //       updatedOrganisationState.compliance.optInInvoiceNow.isChecked =
            //         true;
            //       updatedOrganisationState.compliance.peppolId.value = ``;
            //       updatedOrganisationState.compliance.peppolId.hasError = true;
            //       setOrganisationState({ ...updatedOrganisationState });
            //     }
            //   });
          }
        }}
        readOnly={readonly}
        canValidate={hasError}
        validator={(value: string) => {
          return !hasError;
        }}
        titleStyle={{
          color: titleColor
        }}
        valueStyle={{
          backgroundColor: bgColor ? 'white' : ''
        }}
        errorMessage={errMsg}
      />
    );
  };

  const addNewAddress = () => {
    const updatedState = organisationState;
    const newAddress = deepClone(updatedState.addressInfo[0]);
    // based on tenant info
    const phoneNumberFormatBasedOnTenant = countryList.find(
      (country) => country.code === countryCode
    );
    newAddress.contactName.value = '';
    newAddress.address.value = '';
    newAddress.address2.value = '';
    newAddress.postalCode.value = '';
    newAddress.city.value = { label: '', value: '' };
    newAddress.state.value = { label: '', value: '' };
    newAddress.contactNumber.value = '';
    newAddress.countryNumberOptions.value =
      phoneNumberFormatBasedOnTenant ?? null;
    newAddress.email.value = '';
    updatedState.activeBillingAddressIndex =
      updatedState.activeBillingAddressIndex + 1;
    updatedState.activeShippingAddressIndex =
      updatedState.activeShippingAddressIndex + 1;
    updatedState.addressInfo.unshift(newAddress);
    setOrganisationState({ ...updatedState });
  };

  const removeAddress = (index: number) => {
    const updatedState = organisationState;
    if (updatedState.addressInfo.length === 1) {
      return;
    }
    if (
      updatedState.activeBillingAddressIndex === index ||
      updatedState.activeShippingAddressIndex === index
    ) {
      updatedState.activeBillingAddressIndex = index - 1;
      updatedState.activeShippingAddressIndex = index - 1;
    }

    updatedState.addressInfo.splice(index, 1);
    if (updatedState.addressInfo.length === 1) {
      updatedState.activeBillingAddressIndex = 0;
      updatedState.activeShippingAddressIndex = 0;
    } else if (
      updatedState.activeBillingAddressIndex >=
        updatedState.addressInfo.length ||
      updatedState.activeShippingAddressIndex >= updatedState.addressInfo.length
    ) {
      updatedState.activeBillingAddressIndex =
        updatedState.addressInfo.length - 1;
      updatedState.activeShippingAddressIndex =
        updatedState.addressInfo.length - 1;
    }
    setOrganisationState({ ...updatedState });
  };

  const setDefaultAddress = (index: number, addressType: string) => {
    const updatedState = organisationState;
    if (addressType === BOOKS_ADDRESS_TYPES.BILLING_ADDRESS) {
      updatedState.activeBillingAddressIndex = index;
    } else if (addressType === BOOKS_ADDRESS_TYPES.RETURN_ADDRESS) {
      updatedState.activeReturnAddressIndex = index;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      updatedState.activeShippingAddressIndex = index;
    }
    setOrganisationState({ ...updatedState });
  };

  const changeActiveTab = (tab: ORGANISATION_FORM_TAB) => {
    const updatedState = organisationState;
    updatedState.activeTab = tab;
    setOrganisationState({ ...updatedState });
  };

  const redirectTo = (route: string) => {
    RouteManager.navigateToPage(route);
  };

  const redirectToAddAgecies = () => {
    //redirectTo(PAGE_ROUTES.AGENCIES);
    // props.passingInteraction({
    //   type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
    // });
    setShowAgencyForm(true);
    // if (draftsData && draftsData.length > 0) {
    //   draftsData.forEach((draft: any) => {
    //     if (draft.isMaximized && draft.isCenterAlign) {
    //       dispatch(removeDraft(draft.id));
    //     }
    //   });
    // }
  };

  const contactNumberBtn = (
    isAddressFieldContact: boolean = false,
    indexToUpdate: number
  ) => {
    let mainValueKey =
      organisationState.addressInfo[indexToUpdate].countryNumberOptions.value;
    if (isAddressFieldContact) {
      mainValueKey =
        organisationState.addressInfo[indexToUpdate].countryNumberOptions.value;
    }
    if (mainValueKey) {
      const { currencyCode, dial_code } = mainValueKey;
      return (
        <div
          style={{
            padding: '5px 10px',
            borderRadius: '4px',
            backgroundColor: 'rgb(235, 235, 235)',
            border: '1px solid rgb(235, 235, 235)',
            cursor: 'pointer'
          }}
          className="mr-s"
          onClick={() => {
            showHidePhoneNumberOptions(isAddressFieldContact, indexToUpdate);
          }}
        >
          {/* <div className="parent-width"> */}
          <div
            className="currency-select-trigger"
            style={{ margin: 0, paddingTop: '1px' }}
          >
            <div
              className="row align-items-center justify-content-center currency-dropdown-flag"
              style={{ margin: 0 }}
            >
              <span
                className={`currency-dropdown-selector-flag flag ${currencyCode}`}
                style={{ margin: 0 }}
              ></span>
            </div>
            <div className="currency-dropdown-code">{dial_code}</div>
          </div>
          {/* </div> */}
        </div>
      );
    }
    return <div></div>;
  };

  const phoneNumberOption = (phoneNumberDetails: CountryInfo) => {
    return (
      <div className="parent-width">
        <div className="currency-select-trigger">
          <div className="currency-dropdown-flag">
            <span
              className={`currency-dropdown-selector-flag flag ${phoneNumberDetails.currencyCode}`}
            ></span>
          </div>
          <div className="currency-dropdown-code">
            {`${phoneNumberDetails.name} ${phoneNumberDetails.dial_code}`}
          </div>
        </div>
      </div>
    );
  };

  const changePhoneNumberType = (
    value: CountryInfo,
    isAddressFieldContact: boolean = false,
    indexToUpdate: number = 0
  ) => {
    const updatedState = organisationState;

    if (isAddressFieldContact) {
      updatedState.addressInfo[indexToUpdate].countryNumberOptions.value =
        value;
      updatedState.addressInfo[
        indexToUpdate
      ].countryNumberOptions.showDropdown = false;
    }

    setOrganisationState({ ...updatedState });
  };

  const showHidePhoneNumberOptions = (
    isAddressFieldContact: boolean = false,
    indexToUpdate: number
  ) => {
    const updatedState = organisationState;
    if (isAddressFieldContact) {
      updatedState.addressInfo[
        indexToUpdate
      ].countryNumberOptions.showDropdown =
        !updatedState.addressInfo[indexToUpdate].countryNumberOptions
          .showDropdown;
    }

    setOrganisationState({ ...updatedState });
  };

  const addressCustomFieldUpdated = (
    cfList: CustomField[],
    indexToUpdate: number
  ) => {
    const updatedState = organisationState;

    updatedState.addressInfo[indexToUpdate].customFields = cfList;
    setOrganisationState({ ...updatedState });
  };

  const getHeaderSegment = () => {
    return (
      <div className="parent-width">
        <div
          className="row bg-gray1 border-radius-s align-items-start p-h-s"
          style={{ gap: 30, backgroundColor: 'rgb(250, 250, 250)' }}
        >
          <div
            className="cursor-hand"
            onClick={() => changeActiveTab(ORGANISATION_FORM_TAB.GENERAL_INFO)}
          >
            <DKLabel
              text={'General Info'}
              className={
                organisationState.activeTab ===
                ORGANISATION_FORM_TAB.GENERAL_INFO
                  ? 'fw-b text-app-color'
                  : ''
              }
            />
            {organisationState.activeTab ===
              ORGANISATION_FORM_TAB.GENERAL_INFO && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>

          <div
            className="cursor-hand"
            onClick={() => changeActiveTab(ORGANISATION_FORM_TAB.COMPLIANCE)}
          >
            <DKLabel
              text={'Compliance'}
              className={
                organisationState.activeTab === ORGANISATION_FORM_TAB.COMPLIANCE
                  ? 'fw-b text-app-color'
                  : ''
              }
            />
            {organisationState.activeTab ===
              ORGANISATION_FORM_TAB.COMPLIANCE && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>

          <div
            className="cursor-hand"
            onClick={() => changeActiveTab(ORGANISATION_FORM_TAB.ADDRESS_INFO)}
          >
            <DKLabel
              text={'Addresses'}
              className={
                organisationState.activeTab ===
                ORGANISATION_FORM_TAB.ADDRESS_INFO
                  ? 'fw-b text-app-color'
                  : ''
              }
            />
            {organisationState.activeTab ===
              ORGANISATION_FORM_TAB.ADDRESS_INFO && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
          <div
            className="cursor-hand"
            onClick={() => changeActiveTab(ORGANISATION_FORM_TAB.CATEGORIES)}
          >
            <DKLabel
              text={'Categories'}
              className={
                organisationState.activeTab === ORGANISATION_FORM_TAB.CATEGORIES
                  ? 'fw-b text-app-color'
                  : ''
              }
            />
            {organisationState.activeTab ===
              ORGANISATION_FORM_TAB.CATEGORIES && (
              <div
                className="parent-width bg-app mt-r"
                style={{ height: 2.5 }}
              />
            )}
          </div>
        </div>
        <DKLine />
      </div>
    );
  };

  const getOrganisationView = (
    <div className="w-full column">
      <DKLabel
        className="parent-width fw-m fs-m text-app-color"
        text={`${getCapitalized(localizedText('organisation'))}`}
      />
      <div className="column parent-width">
        <DKInput
          className="parent-width"
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          title={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.NAME`
          )}
          placeholder={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.HELLO_THERE`
          )}
          value={organisationState.organisation.name.value.toString()}
          type={INPUT_TYPE.TEXT}
          readOnly={organisationState.organisation.name.isDisabled}
          onChange={(text: any) => {}}
        />
        <DKInput
          className="parent-width mt-2"
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          title={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.TAX_RESIDENCY`
          )}
          value={tenantDetails.currency ? tenantDetails.currency : 'INR'}
          type={INPUT_TYPE.TEXT}
          readOnly={true}
          required={false}
          onChange={(text: any) => {}}
        />

        {organisationState.organisation.dateFormat.options && (
          <div className={'row row-responsive mt-2'}>
            {sdkDropdown(
              t(
                `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.DATE_FORMAT`
              ),
              organisationState.organisation.dateFormat.value.label,
              organisationState.organisation.dateFormat.options,
              ORGANISATION_PROFILE_FIELDS_NAME.DATE_FORMAT
            )}
          </div>
        )}

        <div className={'row row-responsive mt-2'}>
          {sdkDropdown(
            'Business Type',
            organisationState.organisation.businessType.value.label,
            organisationState.organisation.businessType.options,
            ORGANISATION_PROFILE_FIELDS_NAME.BUSINESS_TYPE
          )}
        </div>

        <div className={'row row-responsive mt-2'}>
          <DKInput
            title="Email"
            value={contactInfo?.email}
            onChange={(text: string) => {
              setContactInfo({ ...contactInfo, email: text });
            }}
            required={false}
          />
        </div>
        <div className={'row row-responsive mt-2'}>
          <DKInput
            title="Phone"
            value={contactInfo?.phone}
            type={INPUT_TYPE.NUMBER}
            onChange={(text: string) => {
              setContactInfo({ ...contactInfo, phone: text });
            }}
            required={false}
          />
        </div>
        <div className={'row row-responsive mt-2'}>
          <DKInput
            title="Fax"
            value={contactInfo?.fax}
            onChange={(text: string) => {
              setContactInfo({ ...contactInfo, fax: text });
            }}
            required={false}
          />
        </div>
        <div className={'row row-responsive mt-2'}>
          <DKInput
            title="Website"
            value={contactInfo?.website}
            onChange={(text: string) => {
              setContactInfo({ ...contactInfo, website: text });
            }}
            required={false}
          />
        </div>
      </div>
    </div>
  );

  const [showEWayBillLoginPortal, setShowEWayBillLoginPortal] = useState(false);

  const getFinanceView = (
    <div className="column parent-width mt-l">
      <DKLabel
        className="parent-width fw-m fs-m text-app-color"
        text={t(
          `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.FINANCE`
        )}
      />
      <div className="column parent-width">
        <DKInput
          className="parent-width"
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          title={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.FINANCIAL_START_DATE`
          )}
          placeholder={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.HELLO_THERE`
          )}
          value={
            !Utility.isEmpty(organisationState.finance.financialStartDate.value)
              ? DateFormatService.getFormattedDateString(
                  organisationState.finance.financialStartDate.value.toString(),
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              : ''
          }
          type={INPUT_TYPE.TEXT}
          readOnly={organisationState.finance.financialStartDate.isDisabled}
          onChange={(text: any) => {}}
        />
        <DKInput
          className="parent-width mt-2"
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          title={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.BOOK_BEGINNING_DATE`
          )}
          value={
            !Utility.isEmpty(organisationState.finance.booksBeginningDate.value)
              ? DateFormatService.getFormattedDateString(
                  organisationState.finance.booksBeginningDate.value.toString(),
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              : ''
          }
          type={INPUT_TYPE.TEXT}
          readOnly={organisationState.finance.booksBeginningDate.isDisabled}
          onChange={(text: any) => {}}
        />
        <DKInput
          className="parent-width mt-2"
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          title={t(
            `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.REPORTING_CURRENCY`
          )}
          value={organisationState.finance.reportingCurrency.value.toString()}
          type={INPUT_TYPE.TEXT}
          readOnly={organisationState.finance.reportingCurrency.isDisabled}
          onChange={(text: any) => {}}
        />

        {organisationState.finance.decimalPlace.options && (
          <div className={'row row-responsive mt-2'}>
            {sdkDropdown(
              t(
                `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.DECIMAL_PLACE`
              ),
              organisationState.finance.decimalPlace.value.label,
              organisationState.finance.decimalPlace.options,
              ORGANISATION_PROFILE_FIELDS_NAME.DECIMAL_PLACE
            )}
          </div>
        )}
        {organisationState.finance.timezone.options && (
          <div className={'row row-responsive mt-2'}>
            {sdkDropdown(
              'Timezone',
              organisationState.finance.timezone.value.label,
              organisationState.finance.timezone.options,
              ORGANISATION_PROFILE_FIELDS_NAME.TIMEZONE
            )}
          </div>
        )}
        {organisationState.finance.numberFormat.options && (
          <div className={'row row-responsive mt-2'}>
            {sdkDropdown(
              t(
                `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.NUMBER_FORMAT`
              ),
              organisationState.finance.numberFormat.value.label,
              organisationState.finance.numberFormat.options,
              ORGANISATION_PROFILE_FIELDS_NAME.NUMBER_FORMAT
            )}
          </div>
        )}
        <div className="row parent-width justify-between items-center mt-m mb-s">
          <DKLabel className="parent-width" text="Multi-Currency" />
          <div
            className="row justify-content-end mobile-mt-s "
            style={{ width: '100%' }}
          >
            <div style={{ width: !isDesktop ? '100%' : '300px' }}>
              <Toggle
                className="box-content"
                onChange={() => {
                  if (organisationState.finance.multicurrencyList.hidden) {
                    showAlert(
                      'Warning',
                      'This feature is not available in your current plan, please upgrade to use.',
                      [
                        {
                          title: 'Cancel',
                          className: 'border-m bg-gray1',
                          onClick: () => {}
                        },
                        {
                          title: 'Upgrade Now',
                          className: 'bg-button text-white ml-m',
                          onClick: () => {
                            window.open(
                              process.env.REACT_APP_URL_GO + `billing`,
                              '_blank'
                            );
                          }
                        }
                      ]
                    );
                    return;
                  }
                  if (
                    !organisationState.finance.multicurrencyCheck.isDisabled
                  ) {
                    multicurrencyToggle(
                      !organisationState.finance.multicurrencyCheck.isChecked
                    );
                  }
                }}
                isOn={organisationState.finance.multicurrencyCheck.isChecked}
              />
            </div>
          </div>
        </div>
        <div className="row parent-width justify-between items-center mt-m mb-l">
          <DKLabel
            className="parent-width"
            text={t(
              `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.AUTO_ROUND_OFF`
            )}
          />
          <div className="row justify-content-end" style={{ width: '100%' }}>
            <div style={{ width: !isDesktop ? '100%' : '300px' }}>
              <Toggle
                className="box-content"
                isOn={
                  organisationState.additionalSettings &&
                  organisationState.additionalSettings.ROUND_OFF &&
                  organisationState.additionalSettings.ROUND_OFF.autoRoundOff
                    ? organisationState.additionalSettings.ROUND_OFF
                        .autoRoundOff
                    : false
                }
                onChange={() => {
                  autoRoundOffToggle(
                    organisationState.additionalSettings.ROUND_OFF.autoRoundOff
                  );
                }}
              />
            </div>
          </div>
        </div>
        {(organisationState.additionalSettings &&
        organisationState.additionalSettings.ROUND_OFF &&
        organisationState.additionalSettings.ROUND_OFF.autoRoundOff
          ? organisationState.additionalSettings.ROUND_OFF.autoRoundOff
          : false) && (
          <div className={'row row-responsive mt-2'}>
            {sdkDropdown(
              t(
                `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.ROUNDING_METHOD`
              ),
              organisationState.additionalSettings.ROUND_OFF.roundingMethod
                .value.label,
              organisationState.additionalSettings.ROUND_OFF.roundingMethod
                .options,
              ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.ROUNDING_METHOD
            )}
          </div>
        )}
        {!organisationState.finance.multicurrencyList.hidden &&
          organisationState.finance.multicurrencyList.options &&
          organisationState.finance.multicurrencyCheck.isChecked &&
          !organisationState.finance.multicurrencyCheck.isDisabled && (
            <div className=" parent-width justify-between items-center">
              <div>
                <DKInput
                  className={'mt-m mobile-mt-s'}
                  value={organisationState.finance.multicurrencyList.value}
                  displayKey="label"
                  title="Select Currency"
                  type={INPUT_TYPE.DROPDOWN}
                  required={isMutliCurrencyChecked}
                  canValidate={true}
                  onChange={(value: number[]) => {
                    multiCurrencySelected(value);
                  }}
                  dropdownConfig={{
                    className: '',
                    title: 'MultiCurrency',
                    style: {},
                    allowSearch: true,
                    searchableKey: 'label',
                    multiSelect: true,
                    selectedIndexes: [],
                    checkMarkColor: 'bg-blue',
                    data: organisationState.finance.multicurrencyList.options,
                    renderer: (index: number, obj: any) => {
                      return <DKLabel text={obj.label} />;
                    },
                    onSelect: (index: number, value: any) => {},
                    onClear: () => {}
                  }}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );

  const getComplianceSection = () => {
    /* Compliance fields destructured*/
    const {
      enableEWayBill,
      gstEFiling,
      gstNo,
      gstNumberMy,
      gstRegistered,
      gstRegisteredDate,
      gstUsername,
      gstin,
      enableInvoice,
      exciseAccount,
      exemptCriteria,
      exemptNumber,
      expiryDate,
      digitalService,
      serviceTaxNumber,
      sezOverseaTrading,
      tanNumber,
      taxCollectionStates,
      taxIdNumber,
      tcsRegistered,
      isCompositeScheme,
      isExciseDuty,
      isExemptCertificate,
      isTaxExempt,
      interestRate,
      reverseCharge,
      optInInvoiceNow,
      optEInvoice,
      peppolMarketingOptIn,
      uen,
      peppolId,
      peppolIdMy,
      vendorId,
      donTCollectTax,
      npwpNumber,
      businessRegistrationNumber,
      compositeSchemeOption,
      salesTaxNumber,
      vatNumber,
      isDesignatedZoneBusiness,
      isContactsOutsideUAE,
      taxOptionForContactOutsideUAE,
      exciseAccountUAE,
      isExciseDutyUAE,
      vatNumberPH,
      gstNumberCA,
      businessNumber,
      panNumber,
      cinNumber,
      ukBusinessRegistrationNumber,
      vatIdentificationNumber,
      vatScheme,
      makingTaxDigital,
      ilDeductionFileId,
      ilEngagementType,
      ilIncomeTaxNo,
      ilLegalName,
      ilNationalInsuranceNo,
      ilParentSubsidiaryName,
      ilReturnEmail,
      ilVatCode,
      ilVatRegistrationNo,
      ilWithholdingTaxAccount,
      ilWhtType
    } = organisationState.compliance;
    let eInvoiceData: any = localStorage.getItem('e-Invoice_data');
    return (
      <div className="column parent-width">
        <DKLabel
          className="parent-width fw-m fs-m text-app-color mb-r"
          text={'Compliance Settings'}
        />
        <div className="column parent-width">
          {/* ISRAEL Compliance Fields */}
          {!ilDeductionFileId.hidden && (
            <div className="row justify-content-between align-items-start flex-wrap mobile-mt-m">
              <div className="web-width-48">
                {sdkInput(
                  'Deduction File Id',
                  ilDeductionFileId.value,
                  'Deduction File Id',
                  ORGANISATION_FORM_FIELD_NAME.IL_DEDUCTION_FILE_ID,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilDeductionFileId.isMandatory,
                  ilDeductionFileId.hasError,
                  ilDeductionFileId.errorMsg
                )}
              </div>
              <div className="web-width-48 mobile-mt-s">
                {sdkInput(
                  'Engagement Type',
                  ilEngagementType.value,
                  'Engagement Type',
                  ORGANISATION_FORM_FIELD_NAME.IL_ENGAGEMENT_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilEngagementType.isMandatory,
                  ilEngagementType.hasError,
                  ilEngagementType.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'Income Tax No',
                  ilIncomeTaxNo.value,
                  'Income Tax No',
                  ORGANISATION_FORM_FIELD_NAME.IL_INCOME_TAX_NO,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilIncomeTaxNo.isMandatory,
                  ilIncomeTaxNo.hasError,
                  ilIncomeTaxNo.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'Legal Name',
                  ilLegalName.value,
                  'Legal Name',
                  ORGANISATION_FORM_FIELD_NAME.IL_LEGAL_NAME,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilLegalName.isMandatory,
                  ilLegalName.hasError,
                  ilLegalName.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkDropdown(
                  'Withholding Tax Account',
                  ilWithholdingTaxAccount?.value?.label,
                  ilWithholdingTaxAccount.options,
                  ORGANISATION_FORM_FIELD_NAME.IL_WITHHOLDING_TAX_ACCOUNT,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  ilWithholdingTaxAccount.isMandatory,
                  ilWithholdingTaxAccount.hasError,
                  ilWithholdingTaxAccount.errorMsg,
                  true
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkDropdown(
                  'Vat Code',
                  ilVatCode.value.label,
                  ilVatCode.options,
                  ORGANISATION_FORM_FIELD_NAME.IL_VAT_CODE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  ilVatCode.isMandatory,
                  ilVatCode.hasError,
                  ilVatCode.errorMsg,
                  true
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'National Insurance No',
                  ilNationalInsuranceNo.value,
                  'National Insurance No',
                  ORGANISATION_FORM_FIELD_NAME.IL_NATIONAL_INSURANCE_NO,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilNationalInsuranceNo.isMandatory,
                  ilNationalInsuranceNo.hasError,
                  ilNationalInsuranceNo.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'Parent Subsidiary Name',
                  ilParentSubsidiaryName.value,
                  'Parent Subsidiary Name',
                  ORGANISATION_FORM_FIELD_NAME.IL_PARENT_SUBSIDIARY_NAME,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilParentSubsidiaryName.isMandatory,
                  ilParentSubsidiaryName.hasError,
                  ilParentSubsidiaryName.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'Return Email',
                  ilReturnEmail.value,
                  'Return Email',
                  ORGANISATION_FORM_FIELD_NAME.IL_RETURN_EMAIL,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilReturnEmail.isMandatory,
                  ilReturnEmail.hasError,
                  ilReturnEmail.errorMsg
                )}
              </div>
              <div className="web-width-48 mt-m mobile-mt-s">
                {sdkInput(
                  'Vat Registration No',
                  ilVatRegistrationNo.value,
                  'Vat Registration No',
                  ORGANISATION_FORM_FIELD_NAME.IL_VAT_REGISTRATION_NO,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  ilVatRegistrationNo.isMandatory,
                  ilVatRegistrationNo.hasError,
                  ilVatRegistrationNo.errorMsg
                )}
              </div>
              {/* Temporary Commented */}
              {/* <div className="web-width-48 mt-m mobile-mt-s">
                {sdkDropdown(
                  'WHT Type',
                  ilWhtType.value.label,
                  ilWhtType.options,
                  ORGANISATION_FORM_FIELD_NAME.IL_WHT_TYPE,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  ilWhtType.isMandatory,
                  ilWhtType.hasError,
                  ilWhtType.errorMsg,
                  true
                )}
              </div> */}
              {/* Temporary Commented */}
            </div>
          )}
          {/* INDIAN Compliance Fields */}
          {!gstRegistered.hidden && (
            <div className="row mb-s">
              <DKLabel
                text={'Is your business registered for GST?'}
                className="w-64"
              />
              <Toggle
                onChange={() =>
                  toggleChanged(
                    !gstRegistered.isChecked,
                    ORGANISATION_COMPLIANCE_FIELD_NAME.GST_REGISTERED
                  )
                }
                className="box-content"
                isOn={gstRegistered.isChecked}
              />
            </div>
          )}
          <div className="row justify-content-between flex-wrap">
            {!gstin.hidden && gstRegistered.isChecked && (
              <div className="web-width-48">
                {sdkInput(
                  'GSTIN Number',
                  gstin.value,
                  'GSTIN Number',
                  ORGANISATION_FORM_FIELD_NAME.GSTIN,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'web-ml-l',
                  gstin.isMandatory,
                  gstin.hasError,
                  gstin.errorMsg
                )}
              </div>
            )}
            {!gstRegisteredDate.hidden && gstRegistered.isChecked && (
              <div className="web-width-48 mobile-mt-m">
                <DKInput
                  title="GST Registered On"
                  value={DateFormatService.getDateFromStr(
                    gstRegisteredDate.date,
                    BOOKS_DATE_FORMAT['DD-MM-YYYY']
                  )}
                  type={INPUT_TYPE.DATE}
                  onChange={(value: Date) =>
                    calendarDateChanged(
                      value,
                      ORGANISATION_FORM_DATE_FIELDS.GST_REGISTRATION_DATE
                    )
                  }
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  dateFormat={convertBooksDateFormatToUILibraryFormat(
                    tenantDetails.dateFormat
                  )}
                />
              </div>
            )}
          </div>
          {gstRegistered.isChecked && (
            <div>
              <DKCheckMark
                className="text-black mt-xl"
                title={'Is involved in SEZ/Overseas Trading?'}
                isSelected={sezOverseaTrading.isChecked}
                onClick={() =>
                  checkboxChanged(
                    !sezOverseaTrading.isChecked,
                    GST_BUSINESS_TYPE.SEZ_OVERSEAS_TRADING
                  )
                }
              />
              <DKCheckMark
                className="text-black mt-r"
                title={'Digital services for overseas customers'}
                isSelected={digitalService.isChecked}
                onClick={() =>
                  checkboxChanged(
                    !digitalService.isChecked,
                    GST_BUSINESS_TYPE.DIGITAL_SERVICES
                  )
                }
              />
              <DKCheckMark
                className={
                  sezOverseaTrading.isChecked
                    ? 'text-gray mt-r'
                    : 'text-black mt-r'
                }
                title={'Is Registered for Composition Scheme?'}
                isSelected={isCompositeScheme.isChecked}
                disabled={sezOverseaTrading.isChecked}
                onClick={() =>
                  checkboxChanged(
                    !isCompositeScheme.isChecked,
                    GST_BUSINESS_TYPE.IS_COMPOSITE_SCHEME
                  )
                }
              />
            </div>
          )}
          {!tcsRegistered.hidden && (
            <div className="row m-v-r">
              <DKLabel
                text={'Is your business registered for TCS?'}
                className="w-64"
              />
              <Toggle
                className="box-content"
                onChange={() =>
                  toggleChanged(
                    !tcsRegistered.isChecked,
                    ORGANISATION_COMPLIANCE_FIELD_NAME.TCS_REGISTERED
                  )
                }
                isOn={tcsRegistered.isChecked}
              />
            </div>
          )}
          {!enableInvoice.hidden && !isTabletView() && (
            <div className="row m-v-m">
              <DKLabel text={'Enable eInvoicing'} />
              <Toggle
                onChange={() =>
                  toggleChanged(
                    !enableInvoice.isChecked,
                    ORGANISATION_COMPLIANCE_FIELD_NAME.ENABLE_INVOICE
                  )
                }
                isOn={enableInvoice.isChecked}
                className="m-h-xl box-content"
              />
              {enableInvoice.isChecked && <DKButton
                title={
                  Utility.isEmpty(eInvoiceData)
                    ? 'Opt in for e-Invoicing'
                    : 'Update e-Invoicing credentials'
                }
                className={
                  enableInvoice.isChecked
                    ? 'bg-app text-white fs-xm'
                    : 'border-m fs-xm'
                }
                onClick={() => showIRPCredPopup()}
                disabled={!enableInvoice.isChecked}
                style={{ padding: 7 }}
              />}
            </div>
          )}
          {!tanNumber.hidden && (
            <div className="parent-width m-v-m">
              <DKLabel
                className="parent-width fw-m fs-xm text-app-color"
                text={'TDS Details'}
              />
              <div className="row justify-content-between flex-wrap mobile-mt-m">
                <div className="web-width-48">
                  {sdkInput(
                    'TAN Number',
                    tanNumber.value,
                    'TAN Number',
                    ORGANISATION_FORM_FIELD_NAME.TAN_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'web-ml-m',
                    tanNumber.isMandatory,
                    tanNumber.hasError,
                    tanNumber.errorMsg
                  )}
                </div>
                <div className="web-width-48 mobile-mt-s">
                  {sdkInput(
                    'Interest Rate',
                    interestRate.value,
                    'Interest Rate',
                    ORGANISATION_FORM_FIELD_NAME.INTEREST_RATE,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'web-ml-m',
                    interestRate.isMandatory,
                    interestRate.hasError,
                    interestRate.errorMsg
                  )}
                </div>
                <div className="web-width-48 mobile-mt-s">
                  {sdkInput(
                    'PAN Number',
                    panNumber.value,
                    'PAN Number',
                    ORGANISATION_FORM_FIELD_NAME.PAN_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'web-ml-m',
                    panNumber.isMandatory,
                    panNumber.hasError,
                    panNumber.errorMsg
                  )}
                </div>

                <div className="web-width-48 mobile-mt-s">
                  {sdkInput(
                    'CIN Number',
                    cinNumber.value,
                    'CIN Number',
                    ORGANISATION_FORM_FIELD_NAME.CIN_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'web-ml-m',
                    cinNumber.isMandatory,
                    cinNumber.hasError,
                    cinNumber.errorMsg
                  )}
                </div>
              </div>
            </div>
          )}
          {!gstEFiling.hidden && !isTabletView() && (
            <div className="parent-width mt-l">
              <DKLabel
                className="parent-width fw-m fs-xm text-app-color"
                text={'GST e-Filing'}
              />
              <div className="parent-width">
                <div className="row justify-content-start align-items-center">
                  <DKLabel text={'Enable GST e-Filing'} />
                  <Toggle
                    onChange={() => {
                      setIsGsteFillingEnable(!isGsteFillingEnable);
                    }}
                    isOn={isGsteFillingEnable}
                    className="m-h-l box-content"
                  />
                </div>
                {isGsteFillingEnable && (
                  <div>
                    <div className="row justify-content-start align-items-end mt-l">
                      <div>
                        <DKButton
                          title={
                            gstEFiling.isChecked ? 'Connected' : 'Connect now'
                          }
                          className={
                            !gstEFiling.isChecked
                              ? 'bg-app text-white fs-xm'
                              : 'border-m fs-xm'
                          }
                          onClick={() => connectGSTSubmission()}
                          disabled={gstEFiling.isChecked}
                          style={{ padding: 7 }}
                        />
                      </div>
                      <div style={{ width: '30%' }}>
                        {sdkInput(
                          'Username',
                          gstUsername.value,
                          'Username',
                          ORGANISATION_FORM_FIELD_NAME.GST_E_FILING_USERNAME,
                          INPUT_VIEW_DIRECTION.VERTICAL,
                          'ml-m',
                          gstUsername.isMandatory,
                          gstUsername.hasError,
                          gstUsername.errorMsg,
                          gstEFiling.isChecked
                          // gstUsername.isDisabled
                        )}
                      </div>
                    </div>
                    <div className="row justify-content-start align-items-end mt-s">
                      <div>
                        <DKButton
                          title={'Update your GST User Name'}
                          className={'text-app-color fs-xm'}
                          onClick={() => {
                            if (gstEFiling.isChecked) {
                              toggleChanged(
                                !gstEFiling.isChecked,
                                ORGANISATION_COMPLIANCE_FIELD_NAME.GST_E_FILING
                              );
                            }
                          }}
                          disabled={
                            isGsteFillingEnable && !gstEFiling.isChecked
                          }
                          style={{ padding: 0 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!Utility.isEmpty(gstin.value) &&
            !gstin.hasError &&
            !isTabletView() && (
              <div className="parent-width mt-10">
                <DKLabel
                  className="parent-width fw-m fs-xm text-app-color"
                  text={'E-Way Bill'}
                />
                <div className="mt-m parent-width align-items-center w-auto">
                  <div className="row width-auto justify-content-start align-items-center">
                    <DKLabel
                      style={{
                        whiteSpace: 'nowrap'
                      }}
                      text={'Enable E-Way Bill'}
                    />
                    <Toggle
                      onChange={() => {
                        toggleChanged(
                          !enableEWayBill.isChecked,
                          ORGANISATION_COMPLIANCE_FIELD_NAME.ENABLE_EWAY_BILL
                        );
                      }}
                      isOn={module.ewayBill}
                      className="m-h-l box-content"
                    />
                  </div>
                  {module.ewayBill && (
                    <div className="row justify-content-start align-items-end mt-s">
                      <div>
                        <DKButton
                          title={
                            Utility.isEmpty(eWayAuthInfoData)
                              ? 'Click here to connect your GST account'
                              : 'Update your GST account credentials'
                          }
                          className={
                            module.ewayBill
                              ? ' text-app-color fs-xm'
                              : 'border-m fs-xm'
                          }
                          onClick={() => setShowEWayBillLoginPortal(true)}
                          disabled={!module.ewayBill}
                          style={{ padding: 0 }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {gstRegistered.isChecked &&
            isCompositeScheme.isChecked &&
            !isTabletView() && (
              <div className="parent-width mt-l">
                <DKLabel
                  className="parent-width fw-m fs-m text-app-color mb-m"
                  text={'Registered Composition Scheme'}
                />
                <DKRadioButton
                  title="1% (For Traders and Manufacturers)"
                  isSelected={
                    compositeSchemeOption.value ===
                    COMPOSITE_SCHEME_OPTION.ONE_PERCENT
                  }
                  onClick={() =>
                    selectCompositeSchemeOption(
                      COMPOSITE_SCHEME_OPTION.ONE_PERCENT
                    )
                  }
                  className="mb-s"
                />
                <DKRadioButton
                  title="2% (For Manufacturers - GSTN has lowered the rate for manufacturers to 1%)"
                  isSelected={
                    compositeSchemeOption.value ===
                    COMPOSITE_SCHEME_OPTION.TWO_PERCENT
                  }
                  onClick={() =>
                    selectCompositeSchemeOption(
                      COMPOSITE_SCHEME_OPTION.TWO_PERCENT
                    )
                  }
                  className="mb-s"
                />
                <DKRadioButton
                  title=" 5% (For Restaurant Sector) "
                  isSelected={
                    compositeSchemeOption.value ===
                    COMPOSITE_SCHEME_OPTION.FIVE_PERCENT
                  }
                  onClick={() =>
                    selectCompositeSchemeOption(
                      COMPOSITE_SCHEME_OPTION.FIVE_PERCENT
                    )
                  }
                  className="mb-s"
                />
                <DKRadioButton
                  title="6% (For Suppliers of Services or Mixed Suppliers)"
                  isSelected={
                    compositeSchemeOption.value ===
                    COMPOSITE_SCHEME_OPTION.SIX_PERCENT
                  }
                  onClick={() =>
                    selectCompositeSchemeOption(
                      COMPOSITE_SCHEME_OPTION.SIX_PERCENT
                    )
                  }
                  className="mb-s"
                />
              </div>
            )}
          {/* SG Compliance Fields */}
          <div className="parent-width">
            {!optInInvoiceNow.hidden && (
              <div className="row justify-content-start align-items-center mb-m">
                <DKLabel text={'Opt-In for InvoiceNow'} />
                <Toggle
                  onChange={() =>
                    toggleChanged(
                      !optInInvoiceNow.isChecked,
                      ORGANISATION_COMPLIANCE_FIELD_NAME.OPT_IN_INVOICE_NOW
                    )
                  }
                  isOn={optInInvoiceNow.isChecked}
                  className="m-h-l box-content"
                />
                <div
                  onClick={() => setShowPeppolReigstation(true)}
                  className="underline text-blue cursor-pointer"
                >
                  Registration Instruction
                </div>
              </div>
            )}
            <div className="row row-responsive parent-width gap-2">
              {!uen.hidden &&
                sdkInput(
                  'UEN',
                  uen.value,
                  'UEN',
                  ORGANISATION_FORM_FIELD_NAME.UEN_NUMBER,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  '',
                  uen.isMandatory,
                  uen.hasError,
                  uen.errorMsg
                )}

              {!gstNo.hidden &&
                sdkInput(
                  'GST Number',
                  gstNo.value,
                  'GST Number',
                  ORGANISATION_FORM_FIELD_NAME.GST_NO,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'parent-width',
                  gstNo.isMandatory,
                  gstNo.hasError,
                  gstNo.errorMsg
                )}
            </div>
            <div className="row row-responsive parent-width gap-2">
              {!peppolId.hidden &&
                sdkInput(
                  'PEPPOL ID',
                  peppolId.value,
                  'PEPPOL ID',
                  ORGANISATION_FORM_FIELD_NAME.PEPPOL_ID,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'mt-2',
                  peppolId.isMandatory,
                  peppolId.hasError,
                  peppolId.errorMsg
                )}

              {/* {!vendorId.hidden &&
                sdkInput(
                  'Vendor ID',
                  vendorId.value,
                  'Vendor ID',
                  ORGANISATION_FORM_FIELD_NAME.VENDOR_ID,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'parent-width mt-2',
                  vendorId.isMandatory,
                  vendorId.hasError,
                  vendorId.errorMsg
                )} */}
              <div className="parent-width"></div>
            </div>
            {!peppolMarketingOptIn.hidden && (
              <div className="row justify-content-start mt-2 align-items-center mb-m">
                <DKLabel
                  text={
                    'I give consent to send/ receive e-Documents via SG Peppol network'
                  }
                />
                <Toggle
                  onChange={() =>
                    toggleChanged(
                      !peppolMarketingOptIn.isChecked,
                      ORGANISATION_COMPLIANCE_FIELD_NAME.PEPPOL_MARKETING_OPT_IN
                    )
                  }
                  isOn={peppolMarketingOptIn.isChecked}
                  className="m-h-l box-content"
                />
              </div>
            )}
          </div>
          {/* USA Compliance Fields */}
          <div className="parent-width">
            <div className="parent-width row justify-content-start align-items-center">
              {!donTCollectTax.hidden && (
                <div className="row justify-content-start align-items-center">
                  <DKLabel text={"I don't collect tax"} />
                  <Toggle
                    onChange={() =>
                      toggleChanged(
                        !donTCollectTax.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.DONT_COLLECT_TAX
                      )
                    }
                    isOn={donTCollectTax.isChecked}
                    className="m-h-l box-content"
                  />
                </div>
              )}
              <div className="parent-width w-1/2">
                {!taxIdNumber.hidden &&
                  sdkInput(
                    'EIN',
                    taxIdNumber.value,
                    'EIN',
                    ORGANISATION_FORM_FIELD_NAME.TAX_ID_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'parent-width mb-m',
                    taxIdNumber.isMandatory,
                    taxIdNumber.hasError,
                    taxIdNumber.errorMsg
                  )}
              </div>
            </div>
            {!taxCollectionStates.hidden &&
              !Utility.isEmpty(taxCollectionStates.options) && (
                <div className="row row-responsive justify-content-start mt-s">
                  <DKButton
                    title={'Add Agencies'}
                    className={'fw-m text-blue'}
                    onClick={() => {
                      redirectToAddAgecies();
                    }}
                    style={{ padding: 5 }}
                  />
                </div>
              )}
            {/* {!taxCollectionStates.hidden &&
              !Utility.isEmpty(taxCollectionStates.options) && (
                <div className="row parent-width justify-between items-center mt-m">
                  <DKLabel
                    className="parent-width"
                    text={'States where you collect tax'}
                  />
                  <div className="parent-width">
                    <Select
                      styles={formUtilComponentProductStyle}
                      options={taxCollectionStates.options}
                      value={taxCollectionStates.value}
                      placeholder="USA States"
                      isSearchable={true}
                      isMulti={true}
                      onChange={(value) => multiUsaStates(value)}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>
              )} */}
          </div>
          {/* Indonesia Compliance Fields */}
          <div className="parent-width">
            <div className="parent-width row justify-content-start align-items-center">
              {!isExciseDuty.hidden && (
                <div className="row justify-content-start align-items-center">
                  <DKLabel text={'Activate Excise'} />
                  <Toggle
                    onChange={() =>
                      toggleChanged(
                        !isExciseDuty.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.ACTIVATE_EXCISE_DUTY
                      )
                    }
                    isOn={isExciseDuty.isChecked}
                    className="m-h-l box-content"
                  />
                </div>
              )}
              <div className="parent-width">
                {!npwpNumber.hidden &&
                  sdkInput(
                    'NPWP Number',
                    npwpNumber.value,
                    'NPWP Number',
                    ORGANISATION_FORM_FIELD_NAME.NPWP_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'parent-width padding-bottom-20',
                    npwpNumber.isMandatory,
                    npwpNumber.hasError,
                    npwpNumber.errorMsg
                  )}
              </div>
            </div>
            {!exciseAccount.hidden &&
              !Utility.isEmpty(exciseAccount.options) && (
                <div className="parent-width mt-m">
                  {sdkDropdown(
                    'Account for Excise',
                    exciseAccount.value.label,
                    exciseAccount.options,
                    ORGANISATION_PROFILE_FIELDS_NAME.EXCISE_ACCOUNT,
                    INPUT_VIEW_DIRECTION.HORIZONTAL,
                    exciseAccount.isMandatory,
                    exciseAccount.hasError,
                    exciseAccount.errorMsg,
                    false
                  )}
                </div>
              )}
          </div>
          {/* Malaysia Compliance Fields */}
          {/* <div className="parent-width">
            <div className="parent-width">
              {!businessRegistrationNumber.hidden &&
                sdkInput(
                  'Business Registration No',
                  businessRegistrationNumber.value,
                  'Business Registration No',
                  ORGANISATION_FORM_FIELD_NAME.BUSINESS_REGISTRATION_NUMBER,
                  INPUT_VIEW_DIRECTION.VERTICAL
                )}
            </div>
            <div className="parent-width row row-responsive mobile-mt-m justify-content-start align-items-center">
              <div className="parent-width mr-m">
                {!salesTaxNumber.hidden &&
                  sdkInput(
                    'Sales Tax Registration No',
                    salesTaxNumber.value,
                    'Sales Tax Registration No',
                    ORGANISATION_FORM_FIELD_NAME.SALES_TAX_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL
                  )}
              </div>
              <div className="parent-width mobile-mt-m">
                {!serviceTaxNumber.hidden &&
                  sdkInput(
                    'Service Tax Registration Number',
                    serviceTaxNumber.value,
                    'Service Tax Registration Number',
                    ORGANISATION_FORM_FIELD_NAME.SERVICE_TAX_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL
                  )}
              </div>
            </div>
            {!isTaxExempt.hidden && (
              <div className="row justify-content-start align-items-center m-v-m">
                <DKLabel text={'Is your business registered for Exemption?'} />
                <Toggle
                  onChange={() =>
                    toggleChanged(
                      !isTaxExempt.isChecked,
                      ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXEMPT_BUSINESS
                    )
                  }
                  isOn={isTaxExempt.isChecked}
                  className="m-h-l box-content"
                />
              </div>
            )}
            <div className="parent-width row row-responsive justify-content-start align-items-center">
              <div className="parent-width mr-m">
                {!exemptNumber.hidden &&
                  sdkInput(
                    'Exemption Number',
                    exemptNumber.value,
                    'Exemption Number',
                    ORGANISATION_FORM_FIELD_NAME.EXEMPTION_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    exemptNumber.isMandatory,
                    exemptNumber.hasError,
                    exemptNumber.errorMsg
                  )}
              </div>
              <div className="parent-width mobile-mt-m">
                {!expiryDate.hidden && (
                  <DKInput
                    title="Expiry Date"
                    value={DateFormatService.getDateFromStr(
                      expiryDate.date,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )}
                    type={INPUT_TYPE.DATE}
                    onChange={(value: Date) =>
                      calendarDateChanged(
                        value,
                        ORGANISATION_FORM_DATE_FIELDS.EXPIRY_DATE
                      )
                    }
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    required={false}
                    dateFormat={convertBooksDateFormatToUILibraryFormat(
                      tenantDetails.dateFormat
                    )}
                  />
                )}
              </div>
            </div>

            {!isExemptCertificate.hidden && (
              <div className="row justify-content-start align-items-center m-v-m">
                <DKLabel text={'Is Exemption certificate available?'} />
                <Toggle
                  onChange={() =>
                    toggleChanged(
                      !isExemptCertificate.isChecked,
                      ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXEMPT_CERTIFICATE
                    )
                  }
                  isOn={isExemptCertificate.isChecked}
                  className="m-h-l box-content"
                />
              </div>
            )}

            {!exemptCriteria.hidden &&
              !Utility.isEmpty(exemptCriteria.options) && (
                <div className="parent-width mt-m">
                  {sdkDropdown(
                    'Exemption Criteria',
                    exemptCriteria.value.label,
                    exemptCriteria.options,
                    ORGANISATION_PROFILE_FIELDS_NAME.EXEMPTION_CRITERIA,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    false
                  )}
                </div>
              )}
          </div> */}
          <div className="parent-width">
            {!optEInvoice?.hidden && (
              <div className="row justify-content-start align-items-center mb-m">
                <DKLabel text={'Opt-In for E-Invoice'} />
                <Toggle
                  onChange={() =>
                    toggleChanged(
                      !optEInvoice.isChecked,
                      ORGANISATION_COMPLIANCE_FIELD_NAME.OPT_E_INVOICE
                    )
                  }
                  isOn={optEInvoice.isChecked}
                  className="m-h-l box-content"
                />
                <div
                  onClick={() => setShowPeppolReigstation(true)}
                  className="underline text-blue cursor-pointer"
                >
                  Registration Instruction
                </div>
              </div>
            )}
            <div className="row row-responsive parent-width gap-2">
              {!gstNumberMy?.hidden &&
                sdkInput(
                  'GST Number',
                  gstNumberMy.value,
                  'GST Number',
                  ORGANISATION_FORM_FIELD_NAME.GST_NO_MY,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'parent-width',
                  gstNumberMy.isMandatory,
                  gstNumberMy.hasError,
                  gstNumberMy.errorMsg
                )}
            </div>
            <div className="row row-responsive parent-width gap-2">
              {!peppolIdMy?.hidden &&
                sdkInput(
                  'PEPPOL ID',
                  peppolIdMy.value,
                  'PEPPOL ID',
                  ORGANISATION_FORM_FIELD_NAME.PEPPOL_ID_MY,
                  INPUT_VIEW_DIRECTION.VERTICAL,
                  'mt-2',
                  peppolIdMy.isMandatory,
                  peppolIdMy.hasError,
                  peppolIdMy.errorMsg
                )}
            </div>
          </div>
          {/* UAE Compliance Fields */}
          <div className="parent-width">
            <div className="parent-width row justify-content-start align-items-end">
              {!vatNumber.hidden && (
                <div className="parent-width mr-m">
                  {sdkInput(
                    'VAT Number',
                    vatNumber.value,
                    'VAT Number',
                    ORGANISATION_FORM_FIELD_NAME.VAT_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    vatNumber.isMandatory,
                    vatNumber.hasError,
                    vatNumber.errorMsg
                  )}
                </div>
              )}
              {!isDesignatedZoneBusiness.hidden && (
                <div className="parent-width">
                  <DKCheckMark
                    title={'Business located in a Designated Zone ?'}
                    isSelected={isDesignatedZoneBusiness.isChecked}
                    className="text-grey mb-2"
                    onClick={() =>
                      toggleChanged(
                        !isDesignatedZoneBusiness.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.BUSINESS_IN_DESIGNATED_ZONE
                      )
                    }
                  />
                </div>
              )}
            </div>

            <div className="parent-width row justify-content-start align-items-center">
              {!isContactsOutsideUAE.hidden && (
                <div className="row justify-content-start align-items-center  mt-m">
                  <DKLabel text={'Trade with contacts outside UAE'} />
                  <Toggle
                    onChange={() =>
                      toggleChanged(
                        !isContactsOutsideUAE.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.IS_CONTACTS_OUTSIDE_UAE
                      )
                    }
                    isOn={isContactsOutsideUAE.isChecked}
                    className="m-h-l"
                  />
                </div>
              )}
              {!taxOptionForContactOutsideUAE.hidden &&
                !Utility.isEmpty(taxOptionForContactOutsideUAE.options) && (
                  <div className="parent-width mt-m">
                    {sdkDropdown(
                      '',
                      taxOptionForContactOutsideUAE.value.label,
                      taxOptionForContactOutsideUAE.options,
                      ORGANISATION_PROFILE_FIELDS_NAME.OUTSIDE_CONTACT,
                      INPUT_VIEW_DIRECTION.VERTICAL
                    )}
                  </div>
                )}
            </div>

            <div className="parent-width row justify-content-start align-items-center">
              {!isExciseDutyUAE.hidden && (
                <div className="row justify-content-start align-items-center mt-m">
                  <DKLabel text={'Activate Excise'} />
                  <Toggle
                    onChange={() =>
                      toggleChanged(
                        !isExciseDutyUAE.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.IS_EXCISE_DUTY_UAE
                      )
                    }
                    isOn={isExciseDutyUAE.isChecked}
                    className="m-h-l"
                  />
                </div>
              )}
              {!exciseAccountUAE.hidden &&
                !Utility.isEmpty(exciseAccountUAE.options) && (
                  <div className="parent-width mt-m">
                    {sdkDropdown(
                      '',
                      exciseAccountUAE.value.label,
                      exciseAccountUAE.options,
                      ORGANISATION_PROFILE_FIELDS_NAME.EXCISE_ACCOUNT_UAE,
                      INPUT_VIEW_DIRECTION.VERTICAL
                    )}
                  </div>
                )}
            </div>
          </div>
          {/* Philippines Compliance Fields */}
          {!vatNumberPH.hidden && (
            <div className="width-80">
              {sdkInput(
                'VAT Number',
                vatNumberPH.value,
                'VAT Number',
                ORGANISATION_FORM_FIELD_NAME.VAT_NUMBER_PH,
                INPUT_VIEW_DIRECTION.VERTICAL,
                '',
                vatNumberPH.isMandatory,
                vatNumberPH.hasError,
                vatNumberPH.errorMsg
              )}
            </div>
          )}
          {/* Canada Compliance Fields */}
          <div className="parent-width">
            <div className="parent-width row justify-content-start align-items-end">
              {!gstNumberCA.hidden && (
                <div className="parent-width mr-m">
                  {sdkInput(
                    'GST Number',
                    gstNumberCA.value,
                    'GST Number',
                    ORGANISATION_FORM_FIELD_NAME.GST_NUMBER_CA,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    gstNumberCA.isMandatory,
                    gstNumberCA.hasError,
                    gstNumberCA.errorMsg
                  )}
                </div>
              )}
              {!businessNumber.hidden && (
                <div className="parent-width mr-m">
                  {sdkInput(
                    'Business Number',
                    businessNumber.value,
                    'Business Number',
                    ORGANISATION_FORM_FIELD_NAME.BUSINESS_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    businessNumber.isMandatory,
                    businessNumber.hasError,
                    businessNumber.errorMsg
                  )}
                </div>
              )}
            </div>
          </div>
          {/* UK Compliance Fields */}
          <div className="parent-width">
            <div className="parent-width row justify-content-start align-items-end">
              {!ukBusinessRegistrationNumber.hidden && (
                <div className="parent-width mr-m">
                  {sdkInput(
                    'Business Registration No',
                    ukBusinessRegistrationNumber.value,
                    'Business Registration No',
                    ORGANISATION_FORM_FIELD_NAME.UK_BUSINESS_REGISTRATION_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    ukBusinessRegistrationNumber.isMandatory,
                    ukBusinessRegistrationNumber.hasError,
                    ukBusinessRegistrationNumber.errorMsg
                  )}
                </div>
              )}
              {!vatIdentificationNumber.hidden && (
                <div className="parent-width mr-m">
                  {sdkInput(
                    'VAT Identification Number',
                    vatIdentificationNumber.value,
                    'VAT Identification Number',
                    ORGANISATION_FORM_FIELD_NAME.VAT_IDENTIFICATION_NUMBER,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    vatIdentificationNumber.isMandatory,
                    vatIdentificationNumber.hasError,
                    vatIdentificationNumber.errorMsg
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="parent-width mt-r">
            <div className="parent-width row justify-content-start align-items-end">
              <div className="parent-width mr-m">
                {!vatScheme.hidden &&
                  sdkDropdown(
                    'VAT Scheme',
                    vatScheme.value.label,
                    vatScheme.options,
                    ORGANISATION_PROFILE_FIELDS_NAME.VAT_SCHEME,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    vatScheme.isMandatory,
                    vatScheme.hasError,
                    vatScheme.errorMsg,
                    false
                  )}
              </div>
              {!makingTaxDigital.hidden && (
                <div className="row justify-content-start align-items-center mb-m mr-m">
                  {/* <Toggle
                    onChange={() =>
                      toggleChanged(
                        !makingTaxDigital.isChecked,
                        ORGANISATION_COMPLIANCE_FIELD_NAME.MAKING_TAX_DIGITAL
                      )
                    }
                    isOn={makingTaxDigital.isChecked}
                    className="mr-r"
                  />
                  <DKLabel text={'MTD (Making Tax Digital)'} /> */}
                </div>
              )}
            </div>
          </div>
          {vatScheme.value.value === UK_VAT_SCHEME[1].code && (
            <div className="parent-width mt-r">
              <DKDataGrid
                needShadow={false}
                needColumnIcons={false}
                needBorder={true}
                needTrailingColumn={false}
                allowBulkOperation={false}
                allowColumnSort={false}
                filterData={[]}
                allowColumnDelete={false}
                allowRowEdit={true}
                allowColumnEdit={false}
                allowFilter={false}
                allowColumnAdd={false}
                allowBottomRowAdd={false}
                allowSearch={false}
                allowShare={false}
                rows={ukComplianceVatSchemeRows ?? []}
                columns={[
                  ...ukComplianceVatSchemeColumns,
                  {
                    id: 'action',
                    key: 'action',
                    name: '',
                    type: INPUT_TYPE.BUTTON,
                    width: 150,
                    options:
                      ukComplianceVatSchemeRows?.length > 1
                        ? [
                            {
                              icon: DKIcons.ic_delete,
                              className: ' p-0',
                              onClick: (data: any) => {
                                let rows = [...ukComplianceVatSchemeRows];
                                rows.splice(data?.rowIndex, 1);
                                setUKComplianceVatSchemeRows([...rows]);
                              }
                            }
                          ]
                        : []
                  }
                ]}
                onRowUpdate={(data: any) => {
                  let rows = [...ukComplianceVatSchemeRows];

                  const INDEX = data?.rowIndex + 1;
                  let updatedObj = rows?.findIndex(
                    (obj) => obj.index === INDEX
                  );

                  const date = new Date(data?.rowData?.applyFrom?.toString());
                  rows[updatedObj].applyFrom = date;

                  let { validateApplyDates, dateValidation } =
                    validateApplyFormDate(rows);

                  setUKComplianceVatSchemeRows([...validateApplyDates]);
                  updateConfig();
                }}
                onRowClick={() => {}}
              />
              <DKButton
                title={`+ Add Rate`}
                onClick={() => {
                  let rows: any = [...ukComplianceVatSchemeRows];
                  const date = DateFormatService.getDateFromStr(
                    tenantDetails.bookBeginningStartDate,
                    BOOKS_DATE_FORMAT['YYYY-MM-DD']
                  );
                  rows.push({
                    flatRatePercentage: 11,
                    applyFrom: date,
                    index: ukComplianceVatSchemeRows?.length + 1
                  });
                  setUKComplianceVatSchemeRows([...rows]);
                }}
                className={`fw-m text-blue`}
                style={{
                  paddingTop: 0,
                  paddingLeft: 0,
                  width: 100,
                  marginBottom: 12,
                  marginTop: -10,
                  zIndex: 1
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const getAddressView = () => {
    return (
      <div className="parent-width">
        <div className="w-max pb-r">
          <DKButton
            title={'+ Add Another Address'}
            className={'fw-m text-blue mt-s'}
            onClick={addNewAddress}
          />
        </div>
        {organisationState.addressInfo.map((addressData, index) => {
          return (
            <div className="column w-full bg-gray0 border-m border-radius-s p-m mb-xl">
              {/* address count and delete icon */}
              <div className="row justify-content-between">
                <DKLabel
                  text={`Address ${
                    organisationState.addressInfo.length > 1
                      ? organisationState.addressInfo.length - index
                      : ''
                  }`}
                  className="fw-b"
                />
                {organisationState.addressInfo.length > 1 && (
                  <div onClick={() => removeAddress(index)}>
                    <DKIcon
                      src={ic_delete}
                      className="ic-s cursor-hand opacity-5"
                    />
                  </div>
                )}
              </div>
              <div className="row row-responsive flex-wrap justify-content-between gap-2 mb-s">
                {/* contact name */}
                <div style={{ width: '100%' }}>
                  {sdkInput(
                    'Contact Name',
                    addressData.contactName.value,
                    '',
                    ORGANISATION_FORM_FIELD_NAME.CONTACT_NAME,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    addressData.contactName.isMandatory,
                    addressData.contactName.hasError,
                    addressData.contactName.errorMsg,
                    false,
                    index,
                    'white',
                    'gray'
                  )}
                </div>

                {/* address */}
                <div className="web-width-48 mobile-mt-m">
                  {sdkInput(
                    'Address 1',
                    addressData.address.value,
                    '',
                    ORGANISATION_FORM_FIELD_NAME.ADDRESS,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    addressData.address.isMandatory,
                    addressData.address.hasError,
                    addressData.address.errorMsg,
                    false,
                    index,
                    'white',
                    'gray'
                  )}
                </div>

                {/* address 2 */}
                <div className="web-width-48 mobile-mt-m">
                  {sdkInput(
                    'Address 2',
                    addressData.address2.value,
                    '',
                    ORGANISATION_FORM_FIELD_NAME.ADDRESS2,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    '',
                    addressData.address2.isMandatory,
                    addressData.address2.hasError,
                    addressData.address2.errorMsg,
                    false,
                    index,
                    'white',
                    'gray'
                  )}
                </div>
                {/* City dropdown only for IND */}
                {!Utility.isEmpty(addressData.city.options) ? (
                  <div className="mt-m web-width-48">
                    {sdkDropdown(
                      t(
                        `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.CITY`
                      ),
                      addressData.city.value.label,
                      addressData.city.options,
                      ORGANISATION_PROFILE_FIELDS_NAME.DROPDOWN_CITY,
                      INPUT_VIEW_DIRECTION.VERTICAL,
                      addressData.state.isMandatory,
                      addressData.state.hasError,
                      addressData.state.errorMsg,
                      true,
                      'label',
                      index,
                      'white',
                      'gray'
                    )}
                  </div>
                ) : (
                  <div className="mt-m web-width-48">
                    {sdkInput(
                      t(
                        `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.CITY`
                      ),
                      addressData.city.value.label,
                      '',
                      ORGANISATION_FORM_FIELD_NAME.INPUT_FIELD_CITY,
                      INPUT_VIEW_DIRECTION.VERTICAL,
                      'parent-width',
                      addressData.city.isMandatory,
                      addressData.city.hasError,
                      addressData.city.errorMsg,
                      false,
                      index,
                      'white',
                      'gray'
                    )}
                  </div>
                )}
                {/* postal code */}
                <div className="mt-m web-width-48">
                  {sdkInput(
                    t(
                      `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.POSTAL_CODE`
                    ),
                    addressData.postalCode.value,
                    '',
                    ORGANISATION_FORM_FIELD_NAME.POSTAL_CODE,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    'parent-width',
                    addressData.postalCode.isMandatory,
                    addressData.postalCode.hasError,
                    addressData.postalCode.errorMsg,
                    false,
                    index,
                    'white',
                    'gray'
                  )}
                </div>

                {/* State dropdown only for IND and USA */}
                {!Utility.isEmpty(addressData.state.options) ? (
                  <div className="mt-m web-width-48">
                    <CommonStateListPicker
                      title={t(
                        `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.STATE`
                      )}
                      value={addressData.state.value.label}
                      dropdownOptions={addressData.state.options}
                      direction={INPUT_VIEW_DIRECTION.VERTICAL}
                      isMandatory={addressData.state.isMandatory}
                      hasError={addressData.state.hasError}
                      errMsg={addressData.state.errorMsg}
                      isSearchable={true}
                      className={''}
                      searchableKey={'searchableKey'}
                      index={index}
                      bgColor={'white'}
                      onChange={(value: any) => {
                        optionSelected(
                          value,
                          ORGANISATION_PROFILE_FIELDS_NAME.DROPDOWN_STATE,
                          index
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div className="web-width-48">
                    {sdkInput(
                      t(
                        `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.STATE`
                      ),
                      addressData.state.value.value,
                      '',
                      ORGANISATION_FORM_FIELD_NAME.INPUT_FIELD_STATE,
                      INPUT_VIEW_DIRECTION.VERTICAL,
                      'parent-width mt-m',
                      addressData.state.isMandatory,
                      addressData.state.hasError,
                      addressData.state.errorMsg,
                      false,
                      index,
                      'white',
                      'gray'
                    )}
                  </div>
                )}

                {/* country */}
                <div className="mt-m web-width-48">
                  {sdkDropdown(
                    t(
                      `SETTINGS.ORGANIZATION_PROFILE.ORGANISATION_PROFILE_FORM.COUNTRY`
                    ),
                    addressData.country.value.label,
                    addressData.country.options,
                    ORGANISATION_PROFILE_FIELDS_NAME.COUNTRY,
                    INPUT_VIEW_DIRECTION.VERTICAL,
                    addressData.country.isMandatory,
                    addressData.country.hasError,
                    addressData.country.errorMsg,
                    true,
                    'label',
                    index,
                    'white',
                    'gray'
                  )}
                </div>

                {/* phone number */}
                <div className="web-width-48 mt-l">
                  <div className="column">
                    <div style={{}}>
                      <DKLabel
                        text="Phone Number"
                        className="row-auto-width mb-xs dkinput-header-section mt-r mr-l text-gray"
                      />
                    </div>
                    <div
                      className="row parent-width parent-height "
                      style={{ width: '100%' }}
                    >
                      <div style={{}}>
                        <div className="position-relative">
                          {contactNumberBtn(true, index)}
                          {organisationState.addressInfo[index]
                            .countryNumberOptions.showDropdown && (
                            <DKListPicker2
                              title="Phone Number"
                              data={
                                organisationState.addressInfo[index]
                                  .countryNumberOptions.options
                              }
                              style={{
                                width: 280
                              }}
                              allowSearch={true}
                              searchableKey="name"
                              className="position-absolute z-index-3 shadow-m"
                              onSelect={(indexSelect: number, value: any) => {
                                changePhoneNumberType(value, true, index);
                              }}
                              onClose={() => {
                                showHidePhoneNumberOptions(true, index);
                              }}
                              renderer={(index: number, obj: any) => {
                                return (
                                  <div
                                    className="flex flex-row w-full justify-content-between"
                                    style={{ width: '15rem' }}
                                  >
                                    {phoneNumberOption(obj)}
                                  </div>
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <DKInput
                        title=""
                        canValidate={
                          organisationState.addressInfo[index].contactNumber
                            .isMandatory
                        }
                        errorMessage={
                          organisationState.addressInfo[index].contactNumber
                            .errorMsg
                        }
                        validator={(value: string) => {
                          return !organisationState.addressInfo[index]
                            .contactNumber.hasError;
                        }}
                        valueStyle={{
                          backgroundColor: 'white'
                        }}
                        titleStyle={{
                          color: 'gray'
                        }}
                        required={
                          organisationState.addressInfo[index].contactNumber
                            .isMandatory
                        }
                        value={
                          organisationState.addressInfo[index].contactNumber
                            .value
                        }
                        placeholder=""
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        onChange={(value: string) => {
                          formUpdated(
                            value,
                            ORGANISATION_FORM_FIELD_NAME.MOBILE_NUMBER,
                            index
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className="web-width-48 mt-l">
                  <DKInput
                    title="Email Address"
                    canValidate={
                      organisationState.addressInfo[index].email.hasError
                    }
                    errorMessage={
                      organisationState.addressInfo[index].email.errorMsg
                    }
                    validator={(value: string) => {
                      return !organisationState.addressInfo[index].email
                        .hasError;
                    }}
                    valueStyle={{
                      backgroundColor: 'white'
                    }}
                    titleStyle={{
                      color: 'gray'
                    }}
                    required={false}
                    value={organisationState.addressInfo[index].email.value}
                    placeholder=""
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    onChange={(value: string) =>
                      formUpdated(
                        value,
                        ORGANISATION_FORM_FIELD_NAME.EMAIL,
                        index
                      )
                    }
                  />
                </div>

                {/* address custom fields */}
                <div className="column parent-width mb-l mt-s">
                  <CustomFieldsHolder
                    documentMode={DOCUMENT_MODE.NEW}
                    moduleName={MODULES_NAME.CONTACT_ADDRESS}
                    customFieldsList={
                      organisationState.addressInfo[index]?.customFields
                        ? organisationState.addressInfo[index]?.customFields
                        : []
                    }
                    columnConfig={columnConfig}
                    columnConfigTableId={columnConfigTableId}
                    onUpdate={(list) => addressCustomFieldUpdated(list, index)}
                    hideAddfieldButton={true}
                  />
                </div>
              </div>
              <div className="row row-responsive parent-width gap-2 align-items-start"></div>
              <div className="parent-width">
                {organisationState.addressInfo.length > 0 && (
                  <div className="row items-center p-m parent-width ">
                    <div className="row">
                      <DKRadioButton
                        title={
                          organisationState.activeShippingAddressIndex === index
                            ? 'Current Shipping Address'
                            : 'Set as Current Shipping Address'
                        }
                        isSelected={
                          organisationState.activeShippingAddressIndex === index
                        }
                        color="bg-app"
                        className=""
                        onClick={() => {
                          setDefaultAddress(
                            index,
                            BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS
                          );
                        }}
                      />
                    </div>
                    <div className="row">
                      <DKRadioButton
                        title={
                          organisationState.activeBillingAddressIndex === index
                            ? 'Current Billing Address'
                            : 'Set as Current Billing Address'
                        }
                        isSelected={
                          organisationState.activeBillingAddressIndex === index
                        }
                        color="bg-app"
                        className=""
                        onClick={() => {
                          setDefaultAddress(
                            index,
                            BOOKS_ADDRESS_TYPES.BILLING_ADDRESS
                          );
                        }}
                      />
                    </div>
                    {tenantDetails?.country === COUNTRY_CODES.IL && (
                      <div className="row">
                        <DKRadioButton
                          title={
                            organisationState.activeReturnAddressIndex === index
                              ? 'Current Return Address'
                              : 'Set as Current Return Address'
                          }
                          isSelected={
                            organisationState.activeReturnAddressIndex === index
                          }
                          color="bg-app"
                          className=""
                          onClick={() => {
                            setDefaultAddress(
                              index,
                              BOOKS_ADDRESS_TYPES.RETURN_ADDRESS
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleAdditionalToggle = (
    value: boolean,
    fieldName: ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME
  ) => {
    const updatedState = organisationState;
    const { additionalSettings } = updatedState;

    switch (fieldName) {
      case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.TRACK_CLASSES:
        additionalSettings.CLASS.trackClasses.isChecked = value;
        additionalSettings.CLASS.trackClasses.isApiCallSuccess = false;
        break;
      case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.TRACK_LOCATION:
        additionalSettings.LOCATION.trackLocation.isChecked = value;
        additionalSettings.LOCATION.trackLocation.isApiCallSuccess = false;
        break;
      case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.MANDATORY_CLASSES:
        additionalSettings.CLASS.mandatory.isChecked = value;
        additionalSettings.CLASS.mandatory.isApiCallSuccess = false;
        break;
      case ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.MANDATORY_LOCATIONS:
        additionalSettings.LOCATION.mandatory.isChecked = value;
        additionalSettings.LOCATION.mandatory.isApiCallSuccess = false;
        break;
    }

    setOrganisationState({ ...updatedState });
  };

  const getCategories = () => {
    const { additionalSettings } = organisationState;
    return (
      <div className="parent-width">
        <div className="column w-full">
          <DKLabel
            className="parent-width fw-m fs-m text-app-color"
            text="Classes"
          />
          <div className="row row-responsive parent-width">
            <div className="column parent-width mb-l">
              {!additionalSettings.CLASS.trackClasses.hidden && (
                <div className="row mb-s">
                  <DKLabel
                    text={'Track classes'}
                    className="dkinput-header-section mr-l"
                  />
                  <Toggle
                    onChange={() =>
                      handleAdditionalToggle(
                        !additionalSettings.CLASS.trackClasses.isChecked,
                        ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.TRACK_CLASSES
                      )
                    }
                    className="box-content"
                    isOn={additionalSettings.CLASS.trackClasses.isChecked}
                  />
                </div>
              )}
              {additionalSettings.CLASS.trackClasses.isChecked && (
                <div className="row mb-s">
                  <DKLabel
                    text={'Is mandatory'}
                    className="dkinput-header-section mr-l"
                  />
                  <Toggle
                    onChange={() =>
                      handleAdditionalToggle(
                        !additionalSettings.CLASS.mandatory.isChecked,
                        ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.MANDATORY_CLASSES
                      )
                    }
                    className="box-content"
                    isOn={additionalSettings.CLASS.mandatory.isChecked}
                  />
                </div>
              )}
              {additionalSettings?.CLASS?.trackClasses.isChecked && (
                <div className="row row-responsive">
                  {sdkDropdown(
                    'Assign classes',
                    additionalSettings.CLASS.assignClasses.value.label,
                    additionalSettings.CLASS.assignClasses.options,
                    ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.ASSIGN_CLASSES
                  )}
                </div>
              )}

              {additionalSettings?.CLASS?.trackClasses.isChecked &&
                tenantDetails?.additionalSettings?.CLASS?.trackClasses && (
                  <div className="row row-responsive justify-content-end mt-s">
                    <DKButton
                      title={'Go To Classes'}
                      className={'fw-m text-blue'}
                      onClick={() => {
                        dispatch(
                          setSelectedSetting(NEW_SETTINGS_SECTION.CLASSES)
                        );
                        props.closePopup && props.closePopup();
                      }}
                      style={{ padding: 5 }}
                    />
                  </div>
                )}

              <DKLabel
                className="parent-width fw-m fs-m text-app-color mt-m"
                text="Locations"
              />
              {!additionalSettings.LOCATION.trackLocation.hidden && (
                <div className="row mb-s">
                  <DKLabel
                    text={'Track locations'}
                    className="dkinput-header-section mr-l"
                  />
                  <Toggle
                    onChange={() =>
                      handleAdditionalToggle(
                        !additionalSettings.LOCATION.trackLocation.isChecked,
                        ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.TRACK_LOCATION
                      )
                    }
                    className="box-content"
                    isOn={additionalSettings.LOCATION.trackLocation.isChecked}
                  />
                </div>
              )}
              {additionalSettings.LOCATION.trackLocation.isChecked && (
                <div className="row mb-s">
                  <DKLabel
                    text={'Is mandatory'}
                    className="dkinput-header-section mr-l"
                  />
                  <Toggle
                    onChange={() =>
                      handleAdditionalToggle(
                        !additionalSettings.LOCATION.mandatory.isChecked,
                        ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.MANDATORY_LOCATIONS
                      )
                    }
                    className="box-content"
                    isOn={additionalSettings.LOCATION.mandatory.isChecked}
                  />
                </div>
              )}
              {additionalSettings.LOCATION.trackLocation.isChecked && (
                <div className="row row-responsive">
                  {sdkDropdown(
                    'Location label',
                    additionalSettings.LOCATION.locationLabel.value.label,
                    additionalSettings.LOCATION.locationLabel.options,
                    ORGANISATION_ADDITIONAL_SETTINGS_FIELD_NAME.LOCATION_LABEL
                  )}
                </div>
              )}

              {additionalSettings.LOCATION.trackLocation.isChecked &&
                tenantDetails?.additionalSettings.LOCATION.trackLocation && (
                  <div className="row row-responsive justify-content-end mt-s">
                    <DKButton
                      title={'Go To Locations'}
                      className={'fw-m text-blue'}
                      onClick={() => {
                        dispatch(
                          setSelectedSetting(NEW_SETTINGS_SECTION.LOCATIONS)
                        );
                        props.closePopup && props.closePopup();
                      }}
                      style={{ padding: 5 }}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="column parent-width">
      {getHeaderSegment()}
      <div className="column p-h-s p-v-r parent-width">
        {organisationState.activeTab === ORGANISATION_FORM_TAB.GENERAL_INFO && (
          <div className="parent-width">
            {getOrganisationView}
            {getFinanceView}
          </div>
        )}
        {showEWayBillLoginPortal && (
          <EWayBillLoginPopUp
            onClose={() => {
              setShowEWayBillLoginPortal(false);
            }}
            onCancel={() => {
              setShowEWayBillLoginPortal(false);
            }}
          />
        )}
        {organisationState.activeTab === ORGANISATION_FORM_TAB.COMPLIANCE &&
          getComplianceSection()}
        {organisationState.activeTab === ORGANISATION_FORM_TAB.CATEGORIES &&
          tenantDetails?.additionalSettings &&
          getCategories()}
        {organisationState.activeTab === ORGANISATION_FORM_TAB.ADDRESS_INFO &&
          getAddressView()}
      </div>

      {!organisationState.compliance.enableInvoice.isDisabled && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Enter your IRP Credentials'}
          btnList={popupBtnConfigForEInvoice}
          width={'35%'}
          maxHeight={'95%'}
          isActionBtnDisabled={isActionBtnDisabled}
        >
          <IRPCredPopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            gstIn={organisationState.compliance.gstin.value.toString()}
            onCancel={hideIRPCredPopup}
          />
        </PopupWrapper>
      )}

      {!organisationState.compliance.gstEFiling.isDisabled && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Enter OTP'}
          btnList={popupBtnConfigForGSTOtp}
          width={'30%'}
          height={'40%'}
        >
          <OtpPopup
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            gstIn={organisationState.compliance.gstin.value.toString()}
            gstUsername={organisationState.compliance.gstUsername.value}
          />
        </PopupWrapper>
      )}
      {showPeppolRegistration && (
        <PeppolRegistrationProcess
          onCancel={() => setShowPeppolReigstation(false)}
        />
      )}

      {showAgencyForm && (
        <AddAgency
          data={null}
          onCancel={() => {
            setShowAgencyForm(false);
            //setPayloadDataAgency('');
            dispatch(fetchAgencies());
            dispatch(fetchCurrentTenantInfo());
          }}
        />
      )}

      {!organisationState.compliance.makingTaxDigital.hidden &&
        organisationState.compliance.makingTaxDigital.isChecked && (
          <MTDSignUpConfirmPopUp
            onCancel={() => {
              toggleChanged(
                !organisationState.compliance.makingTaxDigital.isChecked,
                ORGANISATION_COMPLIANCE_FIELD_NAME.MAKING_TAX_DIGITAL
              );
            }}
          />
        )}
    </div>
  );
};

export default OrganisationProfileForm;
